import { Button } from '@praxie/shared'
import CloseIcon from 'assets/images/icons/cross.svg?react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { type TTokenInputValue, TokenInputBox } from 'components/tokenInputBox/tokenInputBox'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import { type TRestApiBlockError } from '../../../restApiClientBlock.types'
import './custom-header-item.scss'

const HEADER_INPUT_MAX_LENGTH = 10000

export type TCustomHeader = {
  [EFieldKeys.KEY]: TTokenInputValue[]
  [EFieldKeys.VALUE]: TTokenInputValue[]
}

type TProps = {
  block: IWorkflowBlock
  header: TCustomHeader
  errors?: TRestApiBlockError
  updateBlockMeta: (key: EFieldKeys, fieldValue: TTokenInputValue[]) => void
  handleInputChange: (fieldName: string, item: Partial<IWorkflowDropdownValue>) => void
  handleReset: (fieldName: string) => void
  handleHeaderDelete: () => void
}

export const CustomHeaderItem = ({
  block,
  header,
  errors,
  updateBlockMeta,
  handleReset,
  handleInputChange,
  handleHeaderDelete
}: TProps) => {
  const errorMessage = errors?.[EFieldKeys.KEY] || errors?.[EFieldKeys.VALUE]

  return (
    <div className="wf-rest-api-block-custom-header-item">
      <div className="header-data">
        <div className="input-fields">
          <TokenInputBox
            block={block}
            fieldMeta={header as IWorkflowBlock['meta']}
            bodyFieldKey={EFieldKeys.KEY}
            tokenFieldKey={EFieldKeys.STRING_INPUT}
            maxLength={HEADER_INPUT_MAX_LENGTH}
            tokenInputPlaceholder="Enter key"
            error={errors?.[EFieldKeys.KEY]}
            handleInputChange={handleInputChange}
            handleReset={handleReset}
            updateBlockMeta={updateBlockMeta}
            shouldPreventEnterAction
          />
          <TokenInputBox
            block={block}
            fieldMeta={header as IWorkflowBlock['meta']}
            bodyFieldKey={EFieldKeys.VALUE}
            tokenFieldKey={EFieldKeys.STRING_INPUT}
            maxLength={HEADER_INPUT_MAX_LENGTH}
            tokenInputPlaceholder="Enter value"
            error={errors?.[EFieldKeys.VALUE]}
            handleInputChange={handleInputChange}
            handleReset={handleReset}
            updateBlockMeta={updateBlockMeta}
            shouldPreventEnterAction
          />
        </div>
        {!!errorMessage && <span className="error-message">{errorMessage}</span>}
      </div>
      <Button appearance="secondary3" className="delete-header-btn" onClick={handleHeaderDelete}>
        <CloseIcon title="Delete" className="delete-icon" />
      </Button>
    </div>
  )
}
