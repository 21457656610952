import React, { Component } from 'react'

import ToggleFlagBlock from '../../../components/workflowBuilder/toggleFlagBlocks/ToggleFlagBlock'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

export default class ToggleFlagContainer extends Component {
  constructor(props) {
    super(props)

    this.handleReset = this.handleReset.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.toggleRadioFields = this.toggleRadioFields.bind(this)
  }

  handleInputChange(item) {
    const { block } = this.props

    const fieldName = EFieldKeys.SOURCE_INPUT

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset() {
    this.handleInputChange({ id: null })
  }

  updateWorkflowBlock(data) {
    const { block } = this.props

    const meta = block.meta || {}

    this.props.updateWorkflowBlock({
      id: block.id,
      data: {
        meta: {
          ...meta,
          ...data
        },
        error: {}
      }
    })
  }

  toggleRadioFields() {
    const {
      block: { meta },
      radioFieldKey
    } = this.props

    this.updateWorkflowBlock({
      [radioFieldKey]: !meta[radioFieldKey]
    })
  }

  render() {
    const {
      block,
      inputType,
      radioFieldKey,
      radioFieldLabels,
      inputFieldTitle,
      inputFieldPlaceholder
    } = this.props

    return (
      <ToggleFlagBlock
        block={block}
        inputType={inputType}
        radioFieldKey={radioFieldKey}
        inputFieldTitle={inputFieldTitle}
        inputFieldPlaceholder={inputFieldPlaceholder}
        radioFieldLabels={radioFieldLabels}
        handleReset={this.handleReset}
        handleInputChange={this.handleInputChange}
        toggleRadioFields={this.toggleRadioFields}
      />
    )
  }
}
