import { Component } from 'react'
import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { getSuggestionsType } from 'helpers/workflowBuilder/workflowBuilderHelpers'
import { PLAIN_STRING, STRING_INPUT } from 'helpers/workflowFindBlock/workflowFindBloksHelpers'
import { getSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import { getSuggestions } from 'api/filtersAPI'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import FormField from 'components/common/FormField'
import ReactSelect from 'components/fields/ReactSelect'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import AutosuggestInput from 'components/common/AutosuggestInput'

class FindBlock extends Component {
  onOutputBlur = value => {
    this.updateBlockMeta(EFieldKeys.NAME, value)
  }

  onReset = fieldName => {
    this.handleInputChange(fieldName, { id: null })
  }

  changeSelectOption = (propName, { value }) => {
    const { block } = this.props

    if (block.meta[propName] !== value) {
      this.onReset(EFieldKeys.STRING_INPUT)
    }

    this.updateBlockMeta(propName, value)
  }

  handleInputChange = (fieldName, item) => {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })

    if (fieldName === EFieldKeys.SOURCE_INPUT) return

    // clear plain string name when input changing
    this.updateBlockMeta(EFieldKeys.NAME, '')
  }

  updateBlockMeta = (key, fieldValue) => {
    const { updateWorkflowBlock, block } = this.props

    const isFieldValueShouldUpdate = !fieldValue || block.meta[key] !== fieldValue
    if (!block.meta || isFieldValueShouldUpdate) {
      const meta = { ...block.meta, [key]: fieldValue }
      updateWorkflowBlock({ id: block.id, data: { meta, error: {} } })
    }
  }

  loadSuggestions = query => {
    const {
      block: { output }
    } = this.props
    const outputType = output[0].type

    const { tenantId } = this.props

    return getSuggestions({
      type: getSuggestionsType(outputType),
      tenantId,
      query
    }).then(response => response.data.slice(0, 5))
  }

  render() {
    const {
      findRequest,
      findFieldPlaceholder,
      findSelectLabel,
      findFieldLabel,
      findOptions,
      findOptionsList,
      inputFieldLabel,
      inputFieldPlaceholder,
      inputType,
      block,
      maxLength
    } = this.props
    const { error, id, input, meta } = block
    const type = meta[EFieldKeys.TYPE]
    const inputValue = input && input[EFieldKeys.SOURCE_INPUT]
    const outputValue = meta && meta[EFieldKeys.NAME]
    const isDisabled = !input[EFieldKeys.SOURCE_INPUT]

    const isStringInputShown = STRING_INPUT === type
    const isCustomTextShown = PLAIN_STRING === type

    return (
      <div className="workflow-actions-content">
        <BlockInputContainer type={inputType} blockId={id} value={input[EFieldKeys.SOURCE_INPUT]}>
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              title={inputFieldLabel}
              placeholder={inputFieldPlaceholder}
              items={items}
              value={selectedItem}
              type={inputType}
              searchItems={searchItems}
              error={error[EFieldKeys.SOURCE_INPUT]}
              supportHistory
              onSelect={option => this.handleInputChange(EFieldKeys.SOURCE_INPUT, option)}
              onReset={() => this.onReset(EFieldKeys.SOURCE_INPUT)}
            />
          )}
        </BlockInputContainer>
        <div className="rename-options-block">
          <FormField label={findSelectLabel} id="way-of-find-widget">
            <ReactSelect
              className="way-of-find"
              size="large"
              value={getSelectedOptionByKey(type, findOptions)}
              options={findOptionsList}
              isDisabled={isDisabled}
              menuPortalEnabled
              onChange={option => this.changeSelectOption(EFieldKeys.TYPE, option)}
            />
          </FormField>
          {isStringInputShown && (
            <BlockInputContainer
              type={OBJECT_TYPES.STRING}
              blockId={id}
              value={input[EFieldKeys.STRING_INPUT]}
            >
              {({ selectedItem, items, searchItems }) => (
                <AnyLevelDropdown
                  title={messages.STRING_LEVEL_INPUT}
                  placeholder={messages.STRING_LEVEL_INPUT_PLACEHOLDER}
                  items={items}
                  searchItems={searchItems}
                  value={selectedItem}
                  type={OBJECT_TYPES.STRING}
                  disabled={isDisabled}
                  error={error[EFieldKeys.STRING_INPUT]}
                  supportHistory
                  onSelect={option => this.handleInputChange(EFieldKeys.STRING_INPUT, option)}
                  onReset={() => this.onReset(EFieldKeys.STRING_INPUT)}
                />
              )}
            </BlockInputContainer>
          )}
          {isCustomTextShown && (
            <FormField label={findFieldLabel} error={error[EFieldKeys.NAME]} id="find-plain-string">
              <AutosuggestInput
                request={findRequest}
                disabled={!inputValue}
                placeholder={findFieldPlaceholder}
                maxLength={maxLength}
                value={outputValue}
                handleClickOutside={() => {}}
                loadSuggestions={this.loadSuggestions}
                // this prop is required for onClickOutside WrapperComponent,
                // otherwise we'll see a bunch of uncaught errors
                onBlur={this.onOutputBlur}
              />
            </FormField>
          )}
        </div>
      </div>
    )
  }
}

export default FindBlock
