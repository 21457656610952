import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { getFieldNamesItems } from 'helpers/workflowBuilder/inputOperations'
import {
  type IWorkflowBlock,
  type IWorkflowScenarioTemplate
} from 'features/workflow/workflow.types'

export const getSelectOptions = (scenarios: IWorkflowScenarioTemplate[]) =>
  scenarios.map(scenario => ({ ...scenario, label: scenario.title }))

export const getAvailableFileName = (block: IWorkflowBlock) => {
  const fileMeta = block.meta[EFieldKeys.FILE_INPUT] as Array<string | null>

  return Object.keys(block.input).find(key => {
    const isCorrectKey = key.startsWith(EFieldKeys.FILE_INPUT)
    const isEmptyInput = !block.input[key]

    if (fileMeta.includes(key)) return false

    return isCorrectKey && isEmptyInput
  })
}

const validateFieldInputs = (block: IWorkflowBlock, field: EFieldKeys) => {
  const fieldNames = getFieldNamesItems(field, block.inputDefinition)

  return fieldNames.reduce((errors, fieldName) => {
    const isEmptyInput = !block.input[fieldName]?.length

    return isEmptyInput ? { ...errors, [fieldName]: messages.emptyField } : errors
  }, {})
}

const validateFileInputs = (block: IWorkflowBlock) => {
  const fileFields = block.meta[EFieldKeys.FILE_INPUT] as string[] | undefined

  return fileFields?.reduce((errors, fieldName) => {
    const isEmptyInput = !block.input[fieldName]

    return isEmptyInput ? { ...errors, [fieldName]: messages.emptyField } : errors
  }, {})
}

const validateCredentials = (credentialFields: Array<string | null>) => {
  const credentialErrors = credentialFields.reduce((errors, credential, index) => {
    return credential ? errors : { ...errors, [index]: messages.emptyField }
  }, {})

  return Object.values(credentialErrors).length ? credentialErrors : null
}

export const validateSendPromptToAIBlock = ({ block }: { block: IWorkflowBlock }) => {
  let error = {
    ...validateFieldInputs(block, EFieldKeys.STRING_INPUT),
    ...validateFieldInputs(block, EFieldKeys.SOURCE_INPUT),
    ...validateFileInputs(block)
  }

  const credentialFields = block.meta[EFieldKeys.CREDENTIAL_IDS] as Array<string | null> | undefined

  if (credentialFields?.length) {
    error = { ...error, [EFieldKeys.CREDENTIAL_IDS]: validateCredentials(credentialFields) }
  }

  if (!block.meta[EFieldKeys.TEMPLATE_ID]) {
    error = { ...error, [EFieldKeys.TEMPLATE_ID]: messages.emptyField }
  }

  return { error }
}

export const metaToStaticConnectionMapper = ({
  block,
  inputName
}: {
  block: IWorkflowBlock
  inputName: EFieldKeys
}) => {
  if (inputName === EFieldKeys.CREDENTIAL_IDS) {
    return block.meta[inputName] ?? []
  }

  return block.meta[inputName] ?? null
}
