import React, { useRef, useState, useMemo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ArrowIcon from 'assets/images/icons/ic_arrow_dr_black.svg?react'
import CloseIcon from 'assets/images/icons/ic_cross_8ea3b1.svg?react'
import messages from 'constants/messages'
import { Menu } from 'components/common/menu/menu'
import DotsSpinner from 'components/spinners/DotsSpinner'
import { debounce } from 'helpers/common/timeoutHelpers'
import { SelectedDropdownState, CardsList } from './SmartLinkDropdownOptions'
import 'scss/cardbuilder/smart-link-dropdown.scss'

const SEARCH_TIMEOUT = 300

const ToggleInput = props => {
  const { value, placeholder, selectedLink, disabled, onChange, onToggle, onClear } = props

  const inputRef = useRef(null)

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const openToggle = () => {
    onToggle(true)
  }

  const closeToggle = () => {
    onToggle(false)
  }

  const handleClear = () => {
    onClear()

    setTimeout(focusInput, 0)
  }

  if (selectedLink) {
    return (
      <div className="smart-link-dropdown-selected-link" onClick={openToggle}>
        {selectedLink.loading ? (
          <DotsSpinner color="dark" size="sm" position="start" />
        ) : (
          <SelectedDropdownState
            name={selectedLink.cardName || selectedLink.name}
            boardName={selectedLink.boardName}
            tenantName={selectedLink.tenantName}
          />
        )}
        <ArrowIcon className="dropdown-arrow" />
        <CloseIcon className="icon-close" onClick={handleClear} />
      </div>
    )
  }

  return (
    <div className="smart-link-dropdown-toggle" onClick={focusInput}>
      <input
        ref={inputRef}
        className="search-field"
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={openToggle}
        onBlur={closeToggle}
        disabled={disabled}
      />
      <ArrowIcon className="dropdown-arrow" />
    </div>
  )
}

const SmartLinkDropdown = props => {
  const {
    items,
    placeholder,
    selectedLink,
    noItemsText,
    disabled,
    loading,
    as: List,
    selectLink,
    onSearch
  } = props

  const anchorElRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const [search, setSearch] = useState('')

  const debouncedSearch = useMemo(() => {
    return debounce(onSearch, SEARCH_TIMEOUT)
  }, [onSearch])

  const toggleDropdown = state => {
    if (isOpen !== state) {
      setIsOpen(state)

      if (state) {
        onSearch('')
      }
    }
  }

  const openDropdown = () => {
    toggleDropdown(true)
  }

  const closeDropdown = () => {
    toggleDropdown(false)
  }

  const onInputChange = event => {
    const value = event.target.value

    if (value !== search) {
      setSearch(value)
      openDropdown()
      debouncedSearch(value)
    }
  }

  const onSelect = link => {
    selectLink(link)

    closeDropdown()
    setSearch('')
  }

  const onClear = () => {
    selectLink(null)
  }

  return (
    <div
      ref={anchorElRef}
      id="smart-link-dropdown"
      className={classNames('smart-link-dropdown', { open: isOpen, disabled })}
    >
      <ToggleInput
        value={search}
        placeholder={placeholder}
        selectedLink={selectedLink}
        disabled={disabled}
        onChange={onInputChange}
        onToggle={toggleDropdown}
        onClear={onClear}
      />
      <Menu
        className={classNames('smart-link-dropdown-menu', { overlap: selectedLink })}
        open={isOpen}
        onClose={closeDropdown}
        anchorEl={anchorElRef.current}
        disableAutoFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'left'
        }}
      >
        {loading && <DotsSpinner color="dark" size="lg" customClass="card-search-loader" />}
        {!items.length && !loading && <div className="empty-state">{noItemsText}</div>}
        {items.length > 0 && !loading && <List items={items} onSelect={onSelect} />}
      </Menu>
    </div>
  )
}

SmartLinkDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  noItemsText: PropTypes.string,
  placeholder: PropTypes.string,
  as: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  selectedLink: PropTypes.object,
  selectLink: PropTypes.func,
  onSearch: PropTypes.func
}

SmartLinkDropdown.defaultProps = {
  items: [],
  noItemsText: messages.NO_CARDS_FOUND,
  placeholder: messages.SELECT_CARD_TO_LINK,
  as: CardsList,
  disabled: false,
  loading: false,
  selectedLink: null,
  selectLink: () => {},
  onSearch: () => {}
}

export default SmartLinkDropdown
