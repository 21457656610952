import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { getSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import {
  AUTHOR_FOR_COMMENT_OPTIONS_LIST,
  AUTHOR_FOR_COMMENT_OPTIONS
} from 'helpers/workflowBuilder/workflowBuilderUIHelpers'
import { COMMENT_BODY_MAX_LENGTH } from 'helpers/workflowAddCommentBlock/workflowAddCommentBlockHelpers'
import FormField from 'components/common/FormField'
import ReactSelect from 'components/fields/ReactSelect'
import { TokenInputBox } from 'components/tokenInputBox/tokenInputBox'
import 'scss/workflowBuilder/addCommentBlock/add-comment-options.scss'

const AddCardCommentOptions = ({
  type,
  block: { input, error },
  block,
  handleInputChange,
  changeSelectOption,
  updateBlockMeta,
  handleReset
}) => {
  const isDisabled = !input[EFieldKeys.SOURCE_INPUT]

  return (
    <div className="comment-card-options">
      <FormField label={messages.AUTHOR_OF_THE_COMMENT} id="add-comment-card-input">
        <ReactSelect
          className="comment-author"
          size="large"
          value={getSelectedOptionByKey(type, AUTHOR_FOR_COMMENT_OPTIONS)}
          options={AUTHOR_FOR_COMMENT_OPTIONS_LIST}
          isDisabled={isDisabled}
          menuPortalEnabled
          onChange={option => changeSelectOption(EFieldKeys.TYPE, option)}
        />
      </FormField>
      <TokenInputBox
        block={block}
        fieldMeta={block.meta}
        handleInputChange={handleInputChange}
        handleReset={handleReset}
        updateBlockMeta={updateBlockMeta}
        bodyFieldKey={EFieldKeys.TEXT}
        tokenFieldKey={EFieldKeys.STRING_INPUT}
        maxLength={COMMENT_BODY_MAX_LENGTH}
        tokenInputBoxLabel={messages.COMMENT_BOX_LABEL}
        error={error[EFieldKeys.TEXT]}
        isDisabled={isDisabled}
        tokenInputBoxHintText={messages.COMMENT_BOX_HINT_TEXT}
        hasHint
      />
    </div>
  )
}

export default AddCardCommentOptions
