import { useCallback } from 'react'
import { Button } from '@praxie/shared'
import { produce } from 'immer'
import PlusIcon from 'assets/images/icons/plus-rounded.svg?react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { CryptoService } from 'services/common/crypto.service'
import { Tooltip } from 'components/common/tooltip/tooltip'
import { type TTokenInputValue } from 'components/tokenInputBox/tokenInputBox'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import { type TRestApiBlockError } from '../../restApiClientBlock.types'
import { type TCustomHeader, CustomHeaderItem } from './customHeaderItem/customHeaderItem'
import './custom-headers.scss'

const MAX_HEADERS_NUMBER = 30

type TProps = {
  block: IWorkflowBlock
  updateWorkflowBlockMeta: (meta: Partial<IWorkflowBlock['meta']>) => void
  handleInputChange: (fieldName: string, item: Partial<IWorkflowDropdownValue>) => void
  handleReset: (fieldName: string) => void
}

export const CustomHeaders = ({
  block,
  updateWorkflowBlockMeta,
  handleInputChange,
  handleReset
}: TProps) => {
  const updateBlockMeta = useCallback(
    (fieldName: EFieldKeys, fieldValue: TTokenInputValue[], index: number) => {
      const prevHeaders = block.meta[EFieldKeys.HEADERS] as TCustomHeader[]

      const updatedHeaders = produce(prevHeaders, draft => {
        const header = draft[index]

        if (header) {
          header[fieldName as EFieldKeys.KEY | EFieldKeys.VALUE] = fieldValue
        }
      })

      const initialHash = CryptoService.hashObject(prevHeaders)
      const updatedHash = CryptoService.hashObject(updatedHeaders)

      if (initialHash !== updatedHash) {
        updateWorkflowBlockMeta({ [EFieldKeys.HEADERS]: updatedHeaders })
      }
    },
    [block.meta, updateWorkflowBlockMeta]
  )

  const handleHeaderCreate = useCallback(() => {
    const headers = block.meta[EFieldKeys.HEADERS] as TCustomHeader[]
    const updatedHeaders = produce(headers, draft => {
      draft.push({ [EFieldKeys.KEY]: [], [EFieldKeys.VALUE]: [] })
    })

    updateWorkflowBlockMeta({ [EFieldKeys.HEADERS]: updatedHeaders })
  }, [block.meta, updateWorkflowBlockMeta])

  const handleHeaderDelete = useCallback(
    (index: number) => {
      const headers = block.meta[EFieldKeys.HEADERS] as TCustomHeader[]
      const updatedHeaders = produce(headers, draft => {
        const header = draft[index] as TCustomHeader

        Object.values(header).forEach(values => {
          values.forEach(item => {
            if (typeof item !== 'string' && item.fieldName) {
              handleReset(item.fieldName)
            }
          })
        })

        draft.splice(index, 1)
      })

      updateWorkflowBlockMeta({ [EFieldKeys.HEADERS]: updatedHeaders })
    },
    [block.meta, updateWorkflowBlockMeta, handleReset]
  )

  const customHeaders = block.meta[EFieldKeys.HEADERS] as TCustomHeader[]
  const isAddButtonDisabled = customHeaders.length >= MAX_HEADERS_NUMBER

  return (
    <div className="wf-rest-api-block-custom-headers">
      <span className="custom-headers-title">Headers</span>
      <div className="custom-headers-content">
        {!customHeaders.length ? (
          <div className="empty-state">
            <span>No headers yet</span>
            <Button
              appearance="secondary3"
              className="add-header-button"
              onClick={handleHeaderCreate}
            >
              <PlusIcon />
              Add header
            </Button>
          </div>
        ) : (
          <div className="header-list">
            {customHeaders.map((header, index: number) => {
              const headerErrors = (block.error[EFieldKeys.HEADERS] || []) as TRestApiBlockError[]

              return (
                <CustomHeaderItem
                  key={`${String(header.key)}-${String(header.value)}-${String(index)}`}
                  block={block}
                  header={header}
                  errors={headerErrors[index]}
                  updateBlockMeta={(key: EFieldKeys, fieldValue: TTokenInputValue[]) =>
                    updateBlockMeta(key, fieldValue, index)
                  }
                  handleHeaderDelete={() => handleHeaderDelete(index)}
                  handleInputChange={handleInputChange}
                  handleReset={handleReset}
                />
              )
            })}
            <div className="header-actions">
              <Tooltip
                title={isAddButtonDisabled ? 'Max 30 headers' : ''}
                placement="top"
                shouldWrapChildrenWithDiv
              >
                <Button
                  appearance="secondary3"
                  className="add-header-button"
                  disabled={isAddButtonDisabled}
                  onClick={handleHeaderCreate}
                >
                  <PlusIcon />
                  Add header
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
