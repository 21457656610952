// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.3.0
//   protoc               v3.19.1
// source: entitlement/v1/entitlement.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { type CallContext, type CallOptions } from "nice-grpc-common";
import { GetResponseType } from "../../common/api.pb";
import { Key, Scope } from "../../common/key.pb";
import { PageInfoWithTotal, PageOptions } from "../../common/page.pb";
import { Timestamp } from "../../google/protobuf/timestamp.pb";

export const protobufPackage = "upboard.io.entitlement.v1";

export enum EntitlementStatus {
  ENT_STATUS_UNKNOWN = 0,
  ENT_STATUS_ACTIVE = 1,
  ENT_STATUS_INACTIVE = 2,
  UNRECOGNIZED = -1,
}

export enum EntitlementType {
  ENT_TYPE_UNKNOWN = 0,
  ENT_TYPE_PROCESS_PRO = 1,
  ENT_TYPE_TEMPLATES_PRO = 2,
  ENT_TYPE_PREMIUM = 3,
  UNRECOGNIZED = -1,
}

export enum EntitlementEventType {
  ENT_EVENT_UNKNOWN = 0,
  ENT_EVENT_NEW = 1,
  ENT_EVENT_DEPLOY = 2,
  ENT_EVENT_CANCEL = 3,
  ENT_EVENT_REACTIVATE = 4,
  ENT_EVENT_RENEW = 5,
  ENT_EVENT_UPDATE = 6,
  UNRECOGNIZED = -1,
}

export enum MessageStatus {
  MESSAGE_STATUS_UNKNOWN = 0,
  MESSAGE_STATUS_ACCEPTED = 1,
  MESSAGE_STATUS_REJECTED = 2,
  MESSAGE_STATUS_ERROR = 3,
  UNRECOGNIZED = -1,
}

export interface Entitlement {
  /**
   * Common key for entitlement
   * When entitlement is created the scope will be empty
   */
  key: Key | undefined;
  appStoreIds: string[];
  customerId: string;
  customerEmail: string;
  name: string;
  description: string;
  type: EntitlementType;
  status: EntitlementStatus;
  stripeProductId: string;
  subscriptionId: string;
  deploymentAt: Date | undefined;
  expiredAt: Date | undefined;
  verifyLink: string;
  collectionIds: string[];
  isCancellationPending: boolean;
  trialPeriodEnd: Date | undefined;
}

export interface History {
  id: number;
  version: number;
  entitlementId: Key | undefined;
  status: EntitlementStatus;
  reason: string;
  userId: string;
  createdAt: Date | undefined;
  stripeProductId: string;
  subscriptionId: string;
  deploymentAt: Date | undefined;
  expiredAt: Date | undefined;
}

export interface CreateRequest {
  entitlements: Entitlement[];
}

export interface CreateResponse {
  keys: Key[];
}

export interface GetRequest {
  keys: Key[];
}

export interface GetResponse {
  results: GetResponseResult[];
}

export interface GetResponseResult {
  type: GetResponseType;
  entitlement: Entitlement | undefined;
  histories: History[];
}

export interface DeployRequest {
  keys: Key[];
}

export interface RenewItem {
  key: Key | undefined;
  userId: string;
  subscriptionId: string;
  expiredAt: Date | undefined;
}

export interface RenewRequest {
  renewItems: RenewItem[];
}

export interface ReActivateItem {
  key: Key | undefined;
  subscriptionId: string;
  expiredAt: Date | undefined;
}

export interface ReActivateRequest {
  reactivateItems: ReActivateItem[];
}

export interface CancelRequest {
  cancelItems: CancelItem[];
}

export interface CancelItem {
  key: Key | undefined;
  subscriptionId: string;
}

export interface CancelResponse {
}

export interface EmptyResponse {
}

export interface UpdateDescRequest {
  entitlements: Entitlement[];
}

export interface UpdateRequest {
  entitlements: Entitlement[];
}

export interface UpdateDescResponse {
  keys: Key[];
}

export interface UpdateResponse {
  keys: Key[];
}

export interface QueryRequest {
  scope: Scope | undefined;
  filter: QueryRequestFilter | undefined;
  page: PageOptions | undefined;
}

export enum QueryRequestOrderBy {
  OrderByCreatedAt = 0,
  OrderByDeploymentAt = 1,
  OrderByStatus = 2,
  OrderByExpiredAt = 3,
  UNRECOGNIZED = -1,
}

export enum QueryRequestOrderDirection {
  DirectionASC = 0,
  DirectionDESC = 1,
  UNRECOGNIZED = -1,
}

export interface QueryRequestFilter {
  status: EntitlementStatus;
  type: EntitlementType;
  organizationId: string;
  appStoreId: string;
  entitlementId: string;
  description: string;
  collectionId: string;
  orderBy: QueryRequestOrderBy;
  orderDirection: QueryRequestOrderDirection;
}

export interface QueryResponse {
  page: PageInfoWithTotal | undefined;
  entitlements: Entitlement[];
}

export interface ListenRequest {
  keyOnly?: ListenRequestKeyOnly | undefined;
}

export interface ListenRequestKeyOnly {
  keys: Key[];
}

export interface EntitlementEvent {
  key: Key | undefined;
  type: EntitlementStatus;
  event: EntitlementEventType;
  publishTimestamp: Date | undefined;
}

/** The client sends this back to let the server know the ent event has been persisted */
export interface EntEventResponse {
  key: Key | undefined;
  status: MessageStatus;
}

function createBaseEntitlement(): Entitlement {
  return {
    key: undefined,
    appStoreIds: [],
    customerId: "",
    customerEmail: "",
    name: "",
    description: "",
    type: 0,
    status: 0,
    stripeProductId: "",
    subscriptionId: "",
    deploymentAt: undefined,
    expiredAt: undefined,
    verifyLink: "",
    collectionIds: [],
    isCancellationPending: false,
    trialPeriodEnd: undefined,
  };
}

export const Entitlement: MessageFns<Entitlement> = {
  encode(message: Entitlement, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    for (const v of message.appStoreIds) {
      writer.uint32(18).string(v!);
    }
    if (message.customerId !== "") {
      writer.uint32(26).string(message.customerId);
    }
    if (message.customerEmail !== "") {
      writer.uint32(34).string(message.customerEmail);
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.type !== 0) {
      writer.uint32(56).int32(message.type);
    }
    if (message.status !== 0) {
      writer.uint32(64).int32(message.status);
    }
    if (message.stripeProductId !== "") {
      writer.uint32(74).string(message.stripeProductId);
    }
    if (message.subscriptionId !== "") {
      writer.uint32(82).string(message.subscriptionId);
    }
    if (message.deploymentAt !== undefined) {
      Timestamp.encode(toTimestamp(message.deploymentAt), writer.uint32(90).fork()).join();
    }
    if (message.expiredAt !== undefined) {
      Timestamp.encode(toTimestamp(message.expiredAt), writer.uint32(98).fork()).join();
    }
    if (message.verifyLink !== "") {
      writer.uint32(106).string(message.verifyLink);
    }
    for (const v of message.collectionIds) {
      writer.uint32(114).string(v!);
    }
    if (message.isCancellationPending !== false) {
      writer.uint32(120).bool(message.isCancellationPending);
    }
    if (message.trialPeriodEnd !== undefined) {
      Timestamp.encode(toTimestamp(message.trialPeriodEnd), writer.uint32(130).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Entitlement {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.appStoreIds.push(reader.string());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.customerId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.customerEmail = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.stripeProductId = reader.string();
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.subscriptionId = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.deploymentAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.expiredAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.verifyLink = reader.string();
          continue;
        }
        case 14: {
          if (tag !== 114) {
            break;
          }

          message.collectionIds.push(reader.string());
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.isCancellationPending = reader.bool();
          continue;
        }
        case 16: {
          if (tag !== 130) {
            break;
          }

          message.trialPeriodEnd = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Entitlement>, I>>(base?: I): Entitlement {
    return Entitlement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Entitlement>, I>>(object: I): Entitlement {
    const message = createBaseEntitlement();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.appStoreIds = object.appStoreIds?.map((e) => e) || [];
    message.customerId = object.customerId ?? "";
    message.customerEmail = object.customerEmail ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.type = object.type ?? 0;
    message.status = object.status ?? 0;
    message.stripeProductId = object.stripeProductId ?? "";
    message.subscriptionId = object.subscriptionId ?? "";
    message.deploymentAt = object.deploymentAt ?? undefined;
    message.expiredAt = object.expiredAt ?? undefined;
    message.verifyLink = object.verifyLink ?? "";
    message.collectionIds = object.collectionIds?.map((e) => e) || [];
    message.isCancellationPending = object.isCancellationPending ?? false;
    message.trialPeriodEnd = object.trialPeriodEnd ?? undefined;
    return message;
  },
};

function createBaseHistory(): History {
  return {
    id: 0,
    version: 0,
    entitlementId: undefined,
    status: 0,
    reason: "",
    userId: "",
    createdAt: undefined,
    stripeProductId: "",
    subscriptionId: "",
    deploymentAt: undefined,
    expiredAt: undefined,
  };
}

export const History: MessageFns<History> = {
  encode(message: History, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.entitlementId !== undefined) {
      Key.encode(message.entitlementId, writer.uint32(26).fork()).join();
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.reason !== "") {
      writer.uint32(42).string(message.reason);
    }
    if (message.userId !== "") {
      writer.uint32(50).string(message.userId);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(58).fork()).join();
    }
    if (message.stripeProductId !== "") {
      writer.uint32(66).string(message.stripeProductId);
    }
    if (message.subscriptionId !== "") {
      writer.uint32(74).string(message.subscriptionId);
    }
    if (message.deploymentAt !== undefined) {
      Timestamp.encode(toTimestamp(message.deploymentAt), writer.uint32(82).fork()).join();
    }
    if (message.expiredAt !== undefined) {
      Timestamp.encode(toTimestamp(message.expiredAt), writer.uint32(90).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): History {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64());
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.entitlementId = Key.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.reason = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.userId = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.stripeProductId = reader.string();
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.subscriptionId = reader.string();
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.deploymentAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.expiredAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<History>, I>>(base?: I): History {
    return History.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<History>, I>>(object: I): History {
    const message = createBaseHistory();
    message.id = object.id ?? 0;
    message.version = object.version ?? 0;
    message.entitlementId = (object.entitlementId !== undefined && object.entitlementId !== null)
      ? Key.fromPartial(object.entitlementId)
      : undefined;
    message.status = object.status ?? 0;
    message.reason = object.reason ?? "";
    message.userId = object.userId ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.stripeProductId = object.stripeProductId ?? "";
    message.subscriptionId = object.subscriptionId ?? "";
    message.deploymentAt = object.deploymentAt ?? undefined;
    message.expiredAt = object.expiredAt ?? undefined;
    return message;
  },
};

function createBaseCreateRequest(): CreateRequest {
  return { entitlements: [] };
}

export const CreateRequest: MessageFns<CreateRequest> = {
  encode(message: CreateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.entitlements) {
      Entitlement.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.entitlements.push(Entitlement.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateRequest>, I>>(base?: I): CreateRequest {
    return CreateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateRequest>, I>>(object: I): CreateRequest {
    const message = createBaseCreateRequest();
    message.entitlements = object.entitlements?.map((e) => Entitlement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateResponse(): CreateResponse {
  return { keys: [] };
}

export const CreateResponse: MessageFns<CreateResponse> = {
  encode(message: CreateResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.keys.push(Key.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateResponse>, I>>(base?: I): CreateResponse {
    return CreateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateResponse>, I>>(object: I): CreateResponse {
    const message = createBaseCreateResponse();
    message.keys = object.keys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetRequest(): GetRequest {
  return { keys: [] };
}

export const GetRequest: MessageFns<GetRequest> = {
  encode(message: GetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.keys.push(Key.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetRequest>, I>>(base?: I): GetRequest {
    return GetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetRequest>, I>>(object: I): GetRequest {
    const message = createBaseGetRequest();
    message.keys = object.keys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetResponse(): GetResponse {
  return { results: [] };
}

export const GetResponse: MessageFns<GetResponse> = {
  encode(message: GetResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.results) {
      GetResponseResult.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.results.push(GetResponseResult.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetResponse>, I>>(base?: I): GetResponse {
    return GetResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetResponse>, I>>(object: I): GetResponse {
    const message = createBaseGetResponse();
    message.results = object.results?.map((e) => GetResponseResult.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetResponseResult(): GetResponseResult {
  return { type: 0, entitlement: undefined, histories: [] };
}

export const GetResponseResult: MessageFns<GetResponseResult> = {
  encode(message: GetResponseResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.entitlement !== undefined) {
      Entitlement.encode(message.entitlement, writer.uint32(18).fork()).join();
    }
    for (const v of message.histories) {
      History.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetResponseResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetResponseResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.entitlement = Entitlement.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.histories.push(History.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetResponseResult>, I>>(base?: I): GetResponseResult {
    return GetResponseResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetResponseResult>, I>>(object: I): GetResponseResult {
    const message = createBaseGetResponseResult();
    message.type = object.type ?? 0;
    message.entitlement = (object.entitlement !== undefined && object.entitlement !== null)
      ? Entitlement.fromPartial(object.entitlement)
      : undefined;
    message.histories = object.histories?.map((e) => History.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeployRequest(): DeployRequest {
  return { keys: [] };
}

export const DeployRequest: MessageFns<DeployRequest> = {
  encode(message: DeployRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeployRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeployRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.keys.push(Key.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeployRequest>, I>>(base?: I): DeployRequest {
    return DeployRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeployRequest>, I>>(object: I): DeployRequest {
    const message = createBaseDeployRequest();
    message.keys = object.keys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRenewItem(): RenewItem {
  return { key: undefined, userId: "", subscriptionId: "", expiredAt: undefined };
}

export const RenewItem: MessageFns<RenewItem> = {
  encode(message: RenewItem, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.userId !== "") {
      writer.uint32(18).string(message.userId);
    }
    if (message.subscriptionId !== "") {
      writer.uint32(26).string(message.subscriptionId);
    }
    if (message.expiredAt !== undefined) {
      Timestamp.encode(toTimestamp(message.expiredAt), writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RenewItem {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenewItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.userId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.subscriptionId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.expiredAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RenewItem>, I>>(base?: I): RenewItem {
    return RenewItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RenewItem>, I>>(object: I): RenewItem {
    const message = createBaseRenewItem();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.userId = object.userId ?? "";
    message.subscriptionId = object.subscriptionId ?? "";
    message.expiredAt = object.expiredAt ?? undefined;
    return message;
  },
};

function createBaseRenewRequest(): RenewRequest {
  return { renewItems: [] };
}

export const RenewRequest: MessageFns<RenewRequest> = {
  encode(message: RenewRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.renewItems) {
      RenewItem.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RenewRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRenewRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.renewItems.push(RenewItem.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RenewRequest>, I>>(base?: I): RenewRequest {
    return RenewRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RenewRequest>, I>>(object: I): RenewRequest {
    const message = createBaseRenewRequest();
    message.renewItems = object.renewItems?.map((e) => RenewItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseReActivateItem(): ReActivateItem {
  return { key: undefined, subscriptionId: "", expiredAt: undefined };
}

export const ReActivateItem: MessageFns<ReActivateItem> = {
  encode(message: ReActivateItem, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.subscriptionId !== "") {
      writer.uint32(18).string(message.subscriptionId);
    }
    if (message.expiredAt !== undefined) {
      Timestamp.encode(toTimestamp(message.expiredAt), writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ReActivateItem {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReActivateItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.subscriptionId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.expiredAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ReActivateItem>, I>>(base?: I): ReActivateItem {
    return ReActivateItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReActivateItem>, I>>(object: I): ReActivateItem {
    const message = createBaseReActivateItem();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.subscriptionId = object.subscriptionId ?? "";
    message.expiredAt = object.expiredAt ?? undefined;
    return message;
  },
};

function createBaseReActivateRequest(): ReActivateRequest {
  return { reactivateItems: [] };
}

export const ReActivateRequest: MessageFns<ReActivateRequest> = {
  encode(message: ReActivateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.reactivateItems) {
      ReActivateItem.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ReActivateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReActivateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.reactivateItems.push(ReActivateItem.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ReActivateRequest>, I>>(base?: I): ReActivateRequest {
    return ReActivateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReActivateRequest>, I>>(object: I): ReActivateRequest {
    const message = createBaseReActivateRequest();
    message.reactivateItems = object.reactivateItems?.map((e) => ReActivateItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCancelRequest(): CancelRequest {
  return { cancelItems: [] };
}

export const CancelRequest: MessageFns<CancelRequest> = {
  encode(message: CancelRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.cancelItems) {
      CancelItem.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CancelRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.cancelItems.push(CancelItem.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CancelRequest>, I>>(base?: I): CancelRequest {
    return CancelRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelRequest>, I>>(object: I): CancelRequest {
    const message = createBaseCancelRequest();
    message.cancelItems = object.cancelItems?.map((e) => CancelItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCancelItem(): CancelItem {
  return { key: undefined, subscriptionId: "" };
}

export const CancelItem: MessageFns<CancelItem> = {
  encode(message: CancelItem, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.subscriptionId !== "") {
      writer.uint32(18).string(message.subscriptionId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CancelItem {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.subscriptionId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CancelItem>, I>>(base?: I): CancelItem {
    return CancelItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelItem>, I>>(object: I): CancelItem {
    const message = createBaseCancelItem();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.subscriptionId = object.subscriptionId ?? "";
    return message;
  },
};

function createBaseCancelResponse(): CancelResponse {
  return {};
}

export const CancelResponse: MessageFns<CancelResponse> = {
  encode(_: CancelResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CancelResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CancelResponse>, I>>(base?: I): CancelResponse {
    return CancelResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelResponse>, I>>(_: I): CancelResponse {
    const message = createBaseCancelResponse();
    return message;
  },
};

function createBaseEmptyResponse(): EmptyResponse {
  return {};
}

export const EmptyResponse: MessageFns<EmptyResponse> = {
  encode(_: EmptyResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): EmptyResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmptyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<EmptyResponse>, I>>(base?: I): EmptyResponse {
    return EmptyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmptyResponse>, I>>(_: I): EmptyResponse {
    const message = createBaseEmptyResponse();
    return message;
  },
};

function createBaseUpdateDescRequest(): UpdateDescRequest {
  return { entitlements: [] };
}

export const UpdateDescRequest: MessageFns<UpdateDescRequest> = {
  encode(message: UpdateDescRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.entitlements) {
      Entitlement.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateDescRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDescRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.entitlements.push(Entitlement.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDescRequest>, I>>(base?: I): UpdateDescRequest {
    return UpdateDescRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateDescRequest>, I>>(object: I): UpdateDescRequest {
    const message = createBaseUpdateDescRequest();
    message.entitlements = object.entitlements?.map((e) => Entitlement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateRequest(): UpdateRequest {
  return { entitlements: [] };
}

export const UpdateRequest: MessageFns<UpdateRequest> = {
  encode(message: UpdateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.entitlements) {
      Entitlement.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.entitlements.push(Entitlement.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateRequest>, I>>(base?: I): UpdateRequest {
    return UpdateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateRequest>, I>>(object: I): UpdateRequest {
    const message = createBaseUpdateRequest();
    message.entitlements = object.entitlements?.map((e) => Entitlement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateDescResponse(): UpdateDescResponse {
  return { keys: [] };
}

export const UpdateDescResponse: MessageFns<UpdateDescResponse> = {
  encode(message: UpdateDescResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateDescResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDescResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.keys.push(Key.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDescResponse>, I>>(base?: I): UpdateDescResponse {
    return UpdateDescResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateDescResponse>, I>>(object: I): UpdateDescResponse {
    const message = createBaseUpdateDescResponse();
    message.keys = object.keys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateResponse(): UpdateResponse {
  return { keys: [] };
}

export const UpdateResponse: MessageFns<UpdateResponse> = {
  encode(message: UpdateResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.keys.push(Key.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateResponse>, I>>(base?: I): UpdateResponse {
    return UpdateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateResponse>, I>>(object: I): UpdateResponse {
    const message = createBaseUpdateResponse();
    message.keys = object.keys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseQueryRequest(): QueryRequest {
  return { scope: undefined, filter: undefined, page: undefined };
}

export const QueryRequest: MessageFns<QueryRequest> = {
  encode(message: QueryRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scope !== undefined) {
      Scope.encode(message.scope, writer.uint32(10).fork()).join();
    }
    if (message.filter !== undefined) {
      QueryRequestFilter.encode(message.filter, writer.uint32(18).fork()).join();
    }
    if (message.page !== undefined) {
      PageOptions.encode(message.page, writer.uint32(90).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.scope = Scope.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.filter = QueryRequestFilter.decode(reader, reader.uint32());
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.page = PageOptions.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<QueryRequest>, I>>(base?: I): QueryRequest {
    return QueryRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryRequest>, I>>(object: I): QueryRequest {
    const message = createBaseQueryRequest();
    message.scope = (object.scope !== undefined && object.scope !== null) ? Scope.fromPartial(object.scope) : undefined;
    message.filter = (object.filter !== undefined && object.filter !== null)
      ? QueryRequestFilter.fromPartial(object.filter)
      : undefined;
    message.page = (object.page !== undefined && object.page !== null)
      ? PageOptions.fromPartial(object.page)
      : undefined;
    return message;
  },
};

function createBaseQueryRequestFilter(): QueryRequestFilter {
  return {
    status: 0,
    type: 0,
    organizationId: "",
    appStoreId: "",
    entitlementId: "",
    description: "",
    collectionId: "",
    orderBy: 0,
    orderDirection: 0,
  };
}

export const QueryRequestFilter: MessageFns<QueryRequestFilter> = {
  encode(message: QueryRequestFilter, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.organizationId !== "") {
      writer.uint32(26).string(message.organizationId);
    }
    if (message.appStoreId !== "") {
      writer.uint32(34).string(message.appStoreId);
    }
    if (message.entitlementId !== "") {
      writer.uint32(42).string(message.entitlementId);
    }
    if (message.description !== "") {
      writer.uint32(50).string(message.description);
    }
    if (message.collectionId !== "") {
      writer.uint32(58).string(message.collectionId);
    }
    if (message.orderBy !== 0) {
      writer.uint32(64).int32(message.orderBy);
    }
    if (message.orderDirection !== 0) {
      writer.uint32(72).int32(message.orderDirection);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryRequestFilter {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryRequestFilter();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.organizationId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.appStoreId = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.entitlementId = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.description = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.collectionId = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.orderBy = reader.int32() as any;
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.orderDirection = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<QueryRequestFilter>, I>>(base?: I): QueryRequestFilter {
    return QueryRequestFilter.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryRequestFilter>, I>>(object: I): QueryRequestFilter {
    const message = createBaseQueryRequestFilter();
    message.status = object.status ?? 0;
    message.type = object.type ?? 0;
    message.organizationId = object.organizationId ?? "";
    message.appStoreId = object.appStoreId ?? "";
    message.entitlementId = object.entitlementId ?? "";
    message.description = object.description ?? "";
    message.collectionId = object.collectionId ?? "";
    message.orderBy = object.orderBy ?? 0;
    message.orderDirection = object.orderDirection ?? 0;
    return message;
  },
};

function createBaseQueryResponse(): QueryResponse {
  return { page: undefined, entitlements: [] };
}

export const QueryResponse: MessageFns<QueryResponse> = {
  encode(message: QueryResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.page !== undefined) {
      PageInfoWithTotal.encode(message.page, writer.uint32(10).fork()).join();
    }
    for (const v of message.entitlements) {
      Entitlement.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.page = PageInfoWithTotal.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.entitlements.push(Entitlement.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<QueryResponse>, I>>(base?: I): QueryResponse {
    return QueryResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryResponse>, I>>(object: I): QueryResponse {
    const message = createBaseQueryResponse();
    message.page = (object.page !== undefined && object.page !== null)
      ? PageInfoWithTotal.fromPartial(object.page)
      : undefined;
    message.entitlements = object.entitlements?.map((e) => Entitlement.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListenRequest(): ListenRequest {
  return { keyOnly: undefined };
}

export const ListenRequest: MessageFns<ListenRequest> = {
  encode(message: ListenRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.keyOnly !== undefined) {
      ListenRequestKeyOnly.encode(message.keyOnly, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListenRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListenRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.keyOnly = ListenRequestKeyOnly.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListenRequest>, I>>(base?: I): ListenRequest {
    return ListenRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListenRequest>, I>>(object: I): ListenRequest {
    const message = createBaseListenRequest();
    message.keyOnly = (object.keyOnly !== undefined && object.keyOnly !== null)
      ? ListenRequestKeyOnly.fromPartial(object.keyOnly)
      : undefined;
    return message;
  },
};

function createBaseListenRequestKeyOnly(): ListenRequestKeyOnly {
  return { keys: [] };
}

export const ListenRequestKeyOnly: MessageFns<ListenRequestKeyOnly> = {
  encode(message: ListenRequestKeyOnly, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.keys) {
      Key.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListenRequestKeyOnly {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListenRequestKeyOnly();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.keys.push(Key.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListenRequestKeyOnly>, I>>(base?: I): ListenRequestKeyOnly {
    return ListenRequestKeyOnly.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListenRequestKeyOnly>, I>>(object: I): ListenRequestKeyOnly {
    const message = createBaseListenRequestKeyOnly();
    message.keys = object.keys?.map((e) => Key.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEntitlementEvent(): EntitlementEvent {
  return { key: undefined, type: 0, event: 0, publishTimestamp: undefined };
}

export const EntitlementEvent: MessageFns<EntitlementEvent> = {
  encode(message: EntitlementEvent, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.event !== 0) {
      writer.uint32(24).int32(message.event);
    }
    if (message.publishTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.publishTimestamp), writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): EntitlementEvent {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntitlementEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.event = reader.int32() as any;
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.publishTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<EntitlementEvent>, I>>(base?: I): EntitlementEvent {
    return EntitlementEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntitlementEvent>, I>>(object: I): EntitlementEvent {
    const message = createBaseEntitlementEvent();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.type = object.type ?? 0;
    message.event = object.event ?? 0;
    message.publishTimestamp = object.publishTimestamp ?? undefined;
    return message;
  },
};

function createBaseEntEventResponse(): EntEventResponse {
  return { key: undefined, status: 0 };
}

export const EntEventResponse: MessageFns<EntEventResponse> = {
  encode(message: EntEventResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): EntEventResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEntEventResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<EntEventResponse>, I>>(base?: I): EntEventResponse {
    return EntEventResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EntEventResponse>, I>>(object: I): EntEventResponse {
    const message = createBaseEntEventResponse();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.status = object.status ?? 0;
    return message;
  },
};

/** Service to handle entitlements */
export type EntitlementServiceDefinition = typeof EntitlementServiceDefinition;
export const EntitlementServiceDefinition = {
  name: "EntitlementService",
  fullName: "upboard.io.entitlement.v1.EntitlementService",
  methods: {
    /**
     * Atomic
     * Create Entitlement after buying and broadcast event ENT_STATUS_INACTIVE
     */
    create: {
      name: "Create",
      requestType: CreateRequest,
      requestStream: false,
      responseType: CreateResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              31,
              34,
              26,
              47,
              97,
              112,
              105,
              47,
              101,
              110,
              116,
              105,
              116,
              108,
              101,
              109,
              101,
              110,
              116,
              47,
              118,
              49,
              58,
              99,
              114,
              101,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /**
     * Atomic
     * Attach Entitlement to workspace and broadcast event ENT_STATUS_ACTIVE
     */
    deploy: {
      name: "Deploy",
      requestType: DeployRequest,
      requestStream: false,
      responseType: EmptyResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              31,
              34,
              26,
              47,
              97,
              112,
              105,
              47,
              101,
              110,
              116,
              105,
              116,
              108,
              101,
              109,
              101,
              110,
              116,
              47,
              118,
              49,
              58,
              100,
              101,
              112,
              108,
              111,
              121,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /**
     * Atomic
     * Cancel Entitlement and broadcast event ENT_STATUS_INACTIVE
     */
    cancel: {
      name: "Cancel",
      requestType: CancelRequest,
      requestStream: false,
      responseType: CancelResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              31,
              34,
              26,
              47,
              97,
              112,
              105,
              47,
              101,
              110,
              116,
              105,
              116,
              108,
              101,
              109,
              101,
              110,
              116,
              47,
              118,
              49,
              58,
              99,
              97,
              110,
              99,
              101,
              108,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /**
     * Atomic
     * Renew Entitlement and broadcast event ENT_STATUS_ACTIVE
     */
    renew: {
      name: "Renew",
      requestType: RenewRequest,
      requestStream: false,
      responseType: EmptyResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              30,
              34,
              25,
              47,
              97,
              112,
              105,
              47,
              101,
              110,
              116,
              105,
              116,
              108,
              101,
              109,
              101,
              110,
              116,
              47,
              118,
              49,
              58,
              114,
              101,
              110,
              101,
              119,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /**
     * Atomic
     * ReActivate Entitlement and broadcast event ENT_STATUS_ACTIVE
     */
    reActivate: {
      name: "ReActivate",
      requestType: ReActivateRequest,
      requestStream: false,
      responseType: EmptyResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              35,
              34,
              30,
              47,
              97,
              112,
              105,
              47,
              101,
              110,
              116,
              105,
              116,
              108,
              101,
              109,
              101,
              110,
              116,
              47,
              118,
              49,
              58,
              114,
              101,
              97,
              99,
              116,
              105,
              118,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** Get Entitlement */
    get: {
      name: "Get",
      requestType: GetRequest,
      requestStream: false,
      responseType: GetResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              28,
              34,
              23,
              47,
              97,
              112,
              105,
              47,
              101,
              110,
              116,
              105,
              116,
              108,
              101,
              109,
              101,
              110,
              116,
              47,
              118,
              49,
              58,
              103,
              101,
              116,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** Update Entitlement description */
    updateDescription: {
      name: "UpdateDescription",
      requestType: UpdateDescRequest,
      requestStream: false,
      responseType: UpdateDescResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              43,
              34,
              38,
              47,
              97,
              112,
              105,
              47,
              101,
              110,
              116,
              105,
              116,
              108,
              101,
              109,
              101,
              110,
              116,
              47,
              118,
              49,
              58,
              117,
              112,
              100,
              97,
              116,
              101,
              45,
              100,
              101,
              115,
              99,
              114,
              105,
              112,
              116,
              105,
              111,
              110,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** Update Entitlement */
    update: {
      name: "Update",
      requestType: UpdateRequest,
      requestStream: false,
      responseType: UpdateResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              31,
              34,
              26,
              47,
              97,
              112,
              105,
              47,
              101,
              110,
              116,
              105,
              116,
              108,
              101,
              109,
              101,
              110,
              116,
              47,
              118,
              49,
              58,
              117,
              112,
              100,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** Request entitlements */
    query: {
      name: "Query",
      requestType: QueryRequest,
      requestStream: false,
      responseType: QueryResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              30,
              34,
              25,
              47,
              97,
              112,
              105,
              47,
              101,
              110,
              116,
              105,
              116,
              108,
              101,
              109,
              101,
              110,
              116,
              47,
              118,
              49,
              58,
              113,
              117,
              101,
              114,
              121,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** broadcast entitlement events */
    listen: {
      name: "Listen",
      requestType: ListenRequest,
      requestStream: false,
      responseType: EntitlementEvent,
      responseStream: true,
      options: {},
    },
    entitlementEvents: {
      name: "EntitlementEvents",
      requestType: EntEventResponse,
      requestStream: true,
      responseType: EntitlementEvent,
      responseStream: true,
      options: {},
    },
  },
} as const;

export interface EntitlementServiceImplementation<CallContextExt = {}> {
  /**
   * Atomic
   * Create Entitlement after buying and broadcast event ENT_STATUS_INACTIVE
   */
  create(request: CreateRequest, context: CallContext & CallContextExt): Promise<DeepPartial<CreateResponse>>;
  /**
   * Atomic
   * Attach Entitlement to workspace and broadcast event ENT_STATUS_ACTIVE
   */
  deploy(request: DeployRequest, context: CallContext & CallContextExt): Promise<DeepPartial<EmptyResponse>>;
  /**
   * Atomic
   * Cancel Entitlement and broadcast event ENT_STATUS_INACTIVE
   */
  cancel(request: CancelRequest, context: CallContext & CallContextExt): Promise<DeepPartial<CancelResponse>>;
  /**
   * Atomic
   * Renew Entitlement and broadcast event ENT_STATUS_ACTIVE
   */
  renew(request: RenewRequest, context: CallContext & CallContextExt): Promise<DeepPartial<EmptyResponse>>;
  /**
   * Atomic
   * ReActivate Entitlement and broadcast event ENT_STATUS_ACTIVE
   */
  reActivate(request: ReActivateRequest, context: CallContext & CallContextExt): Promise<DeepPartial<EmptyResponse>>;
  /** Get Entitlement */
  get(request: GetRequest, context: CallContext & CallContextExt): Promise<DeepPartial<GetResponse>>;
  /** Update Entitlement description */
  updateDescription(
    request: UpdateDescRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<UpdateDescResponse>>;
  /** Update Entitlement */
  update(request: UpdateRequest, context: CallContext & CallContextExt): Promise<DeepPartial<UpdateResponse>>;
  /** Request entitlements */
  query(request: QueryRequest, context: CallContext & CallContextExt): Promise<DeepPartial<QueryResponse>>;
  /** broadcast entitlement events */
  listen(
    request: ListenRequest,
    context: CallContext & CallContextExt,
  ): ServerStreamingMethodResult<DeepPartial<EntitlementEvent>>;
  entitlementEvents(
    request: AsyncIterable<EntEventResponse>,
    context: CallContext & CallContextExt,
  ): ServerStreamingMethodResult<DeepPartial<EntitlementEvent>>;
}

export interface EntitlementServiceClient<CallOptionsExt = {}> {
  /**
   * Atomic
   * Create Entitlement after buying and broadcast event ENT_STATUS_INACTIVE
   */
  create(request: DeepPartial<CreateRequest>, options?: CallOptions & CallOptionsExt): Promise<CreateResponse>;
  /**
   * Atomic
   * Attach Entitlement to workspace and broadcast event ENT_STATUS_ACTIVE
   */
  deploy(request: DeepPartial<DeployRequest>, options?: CallOptions & CallOptionsExt): Promise<EmptyResponse>;
  /**
   * Atomic
   * Cancel Entitlement and broadcast event ENT_STATUS_INACTIVE
   */
  cancel(request: DeepPartial<CancelRequest>, options?: CallOptions & CallOptionsExt): Promise<CancelResponse>;
  /**
   * Atomic
   * Renew Entitlement and broadcast event ENT_STATUS_ACTIVE
   */
  renew(request: DeepPartial<RenewRequest>, options?: CallOptions & CallOptionsExt): Promise<EmptyResponse>;
  /**
   * Atomic
   * ReActivate Entitlement and broadcast event ENT_STATUS_ACTIVE
   */
  reActivate(request: DeepPartial<ReActivateRequest>, options?: CallOptions & CallOptionsExt): Promise<EmptyResponse>;
  /** Get Entitlement */
  get(request: DeepPartial<GetRequest>, options?: CallOptions & CallOptionsExt): Promise<GetResponse>;
  /** Update Entitlement description */
  updateDescription(
    request: DeepPartial<UpdateDescRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<UpdateDescResponse>;
  /** Update Entitlement */
  update(request: DeepPartial<UpdateRequest>, options?: CallOptions & CallOptionsExt): Promise<UpdateResponse>;
  /** Request entitlements */
  query(request: DeepPartial<QueryRequest>, options?: CallOptions & CallOptionsExt): Promise<QueryResponse>;
  /** broadcast entitlement events */
  listen(request: DeepPartial<ListenRequest>, options?: CallOptions & CallOptionsExt): AsyncIterable<EntitlementEvent>;
  entitlementEvents(
    request: AsyncIterable<DeepPartial<EntEventResponse>>,
    options?: CallOptions & CallOptionsExt,
  ): AsyncIterable<EntitlementEvent>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

export type ServerStreamingMethodResult<Response> = { [Symbol.asyncIterator](): AsyncIterator<Response, void> };

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
