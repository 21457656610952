import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useMount } from 'react-use'
import { produce } from 'immer'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import { changeWorkflowBlockInput, toggleAllChangesSaved, updateWorkflowBlock } from 'actions'
import { processGetPromptTemplate, processGetPromptTemplateList } from 'api/promptTemplateAPI'
import { generateBlockInputs } from 'helpers/workflowBuilder/inputOperations'
import {
  DEFAULT_WORKFLOW_SCENARIO_RETURN_FORMATS,
  WORKFLOW_SCENARIO_OUTPUT_FORMAT_MAP
} from 'features/workflow/workflow.constant'
import {
  type IWorkflowBlock,
  type IWorkflowDropdownValue,
  type IWorkflowScenarioTemplate
} from 'features/workflow/workflow.types'
import { BLOCKS_DEFINITION } from 'features/workflow/workflowBuilder/model/workflowBuilder.constants'
import { getAvailableFileName } from './sendPromptToAIBlock.helpers'

type TProps = {
  block: IWorkflowBlock
}

export const useSendPromptToAIBlock = ({ block }: TProps) => {
  const [scenarios, setScenarios] = useState<IWorkflowScenarioTemplate[]>([])
  const [currentScenario, setCurrentScenario] = useState<IWorkflowScenarioTemplate | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isFieldsLoading, setIsFieldsLoading] = useState(false)
  const [fieldsLoadingError, setFieldsLoadingError] = useState<string | null>(null)

  const dispatch = useDispatch()

  const updateWorkflowBlockInputDefinitions = useCallback(
    (template: IWorkflowScenarioTemplate) => {
      const inputDefinition = {
        ...BLOCKS_DEFINITION[block.type].inputDefinition,
        ...generateBlockInputs({
          prefix: EFieldKeys.STRING_INPUT,
          value: {
            inputName: EFieldKeys.STRING_INPUT,
            type: OBJECT_TYPES.STRING,
            canUpdateHistory: false,
            presetValue: false
          },
          inputCount: template.fields.length
        })
      }

      // @ts-expect-error: dispatch is not properly typed.
      dispatch(updateWorkflowBlock({ id: block.id, data: { inputDefinition, error: {} } }))
    },
    [dispatch, block.id, block.type]
  )

  const updateWorkflowBlockInputs = useCallback(
    (template: IWorkflowScenarioTemplate) => {
      const input = {
        ...BLOCKS_DEFINITION[block.type].input,
        ...generateBlockInputs({
          prefix: EFieldKeys.STRING_INPUT,
          value: null,
          inputCount: template.fields.length
        })
      }

      // @ts-expect-error: dispatch is not properly typed.
      dispatch(updateWorkflowBlock({ id: block.id, data: { input, error: {} } }))
    },
    [dispatch, block.id, block.type]
  )

  const updateWorkflowBlockMeta = useCallback(
    (newMeta: Partial<IWorkflowBlock['meta']>) => {
      const meta = { ...block.meta, ...newMeta }

      // @ts-expect-error: dispatch is not properly typed.
      dispatch(updateWorkflowBlock({ id: block.id, data: { meta, error: {} } }))
    },
    [dispatch, block.id, block.meta]
  )

  const getScenarios = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data } = (await processGetPromptTemplateList()) as {
        data: { promptTemplates: IWorkflowScenarioTemplate[]; status: number }
      }

      const outputType = block.output[0]?.type as keyof typeof WORKFLOW_SCENARIO_OUTPUT_FORMAT_MAP
      const format = WORKFLOW_SCENARIO_OUTPUT_FORMAT_MAP[outputType]

      const sortedScenarios = data.promptTemplates.reduce<IWorkflowScenarioTemplate[]>(
        (acc, scenario) => {
          const returns = scenario.returns ?? DEFAULT_WORKFLOW_SCENARIO_RETURN_FORMATS

          if (returns.includes(format)) {
            const index = acc.findIndex(item => item.title.localeCompare(scenario.title) > 0)

            if (index === -1) {
              acc.push(scenario)
            } else {
              acc.splice(index, 0, scenario)
            }
          }

          return acc
        },
        []
      )

      setScenarios(sortedScenarios)
    } catch {
      setScenarios([])
    } finally {
      setIsLoading(false)
    }
  }, [block.output])

  const getScenarioFields = useCallback(
    async (id: number) => {
      setIsFieldsLoading(true)
      setFieldsLoadingError(null)

      try {
        const { data } = (await processGetPromptTemplate({ id })) as { data?: { fields: string[] } }
        const fields = data?.fields

        if (!fields?.length) return

        setCurrentScenario(prevScenario => {
          const updatedScenario = { ...prevScenario, fields } as IWorkflowScenarioTemplate

          updateWorkflowBlockMeta({
            [EFieldKeys.TEMPLATE_ID]: updatedScenario.id,
            [EFieldKeys.TEMPLATE_OBJECT]: updatedScenario,
            [EFieldKeys.COLUMN_NUMBER]: updatedScenario.fields.length,
            [EFieldKeys.FILE_INPUT]: updatedScenario.attachments ? [null] : [],
            [EFieldKeys.CREDENTIAL_IDS]: updatedScenario.credentials ? [null] : []
          })

          updateWorkflowBlockInputDefinitions(updatedScenario)
          updateWorkflowBlockInputs(updatedScenario)

          return updatedScenario
        })
      } catch (error) {
        setFieldsLoadingError(error as string)
      } finally {
        setIsFieldsLoading(false)
      }
    },
    [updateWorkflowBlockInputDefinitions, updateWorkflowBlockInputs, updateWorkflowBlockMeta]
  )

  const handeScenarioOpen = useCallback(() => {
    if (isLoading || scenarios.length > 0) return

    void getScenarios()
  }, [getScenarios, isLoading, scenarios.length])

  const handeScenarioChange = useCallback(
    (_: unknown, newScenario: IWorkflowScenarioTemplate) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id, attachments, credentials, title, label, returns, value } = newScenario

      // To support backward compatibility
      const updatedScenario = {
        fields: [],
        id,
        attachments,
        title,
        label,
        returns,
        value,
        credentials
      }

      setCurrentScenario(updatedScenario)

      const updatedWorkflowBlockMeta = {
        [EFieldKeys.TEMPLATE_ID]: updatedScenario.id,
        [EFieldKeys.TEMPLATE_OBJECT]: updatedScenario,
        [EFieldKeys.COLUMN_NUMBER]: updatedScenario.fields.length,
        [EFieldKeys.FILE_INPUT]: updatedScenario.attachments ? [null] : [],
        [EFieldKeys.CREDENTIAL_IDS]: updatedScenario.credentials ? [null] : []
      }

      updateWorkflowBlockMeta(updatedWorkflowBlockMeta)
      updateWorkflowBlockInputDefinitions(updatedScenario)
      updateWorkflowBlockInputs(updatedScenario)

      void getScenarioFields(updatedScenario.id)
    },
    [
      getScenarioFields,
      updateWorkflowBlockInputDefinitions,
      updateWorkflowBlockInputs,
      updateWorkflowBlockMeta
    ]
  )

  const handleFieldSelect = useCallback(
    (fieldName: string, item?: Partial<IWorkflowDropdownValue> | null) => {
      dispatch(
        // @ts-expect-error: dispatch is not properly typed.
        changeWorkflowBlockInput({
          fieldName,
          blockId: block.id,
          outputId: item ? item.id : null,
          oldOutputId: block.input[fieldName] || null
        })
      )
    },
    [dispatch, block.id, block.input]
  )

  const handleFileInputAdd = useCallback(() => {
    const fileMeta = block.meta[EFieldKeys.FILE_INPUT] as Array<string | null>

    const fileName = getAvailableFileName(block)

    const updatedFileMeta = [...fileMeta, fileName]

    updateWorkflowBlockMeta({
      [EFieldKeys.FILE_INPUT]: updatedFileMeta,
      [EFieldKeys.FILE_INPUT_NUMBER]: updatedFileMeta.length
    })
  }, [block, updateWorkflowBlockMeta])

  const handleFileInputChange = useCallback(
    (index: number, item: Partial<IWorkflowDropdownValue>) => {
      const fileMeta = block.meta[EFieldKeys.FILE_INPUT] as Array<string | null>
      const isKeyExist = !item.type || fileMeta[index]?.startsWith(EFieldKeys.FILE_INPUT)
      const fieldName = fileMeta[index]

      if (isKeyExist && fieldName) {
        handleFieldSelect(fieldName, item)
        return
      }

      const updatedFieldName = getAvailableFileName(block)
      if (!updatedFieldName) {
        return
      }

      const updatedFileMeta = fileMeta.map((param, idx) =>
        idx === index ? updatedFieldName : param
      )

      updateWorkflowBlockMeta({
        [EFieldKeys.FILE_INPUT]: updatedFileMeta,
        [EFieldKeys.FILE_INPUT_NUMBER]: updatedFileMeta.length
      })
      handleFieldSelect(updatedFieldName, item)
    },
    [block, updateWorkflowBlockMeta, handleFieldSelect]
  )

  const handleFileInputDelete = useCallback(
    (index: number) => {
      const fileMeta = block.meta[EFieldKeys.FILE_INPUT] as Array<string | null>
      const fieldName = fileMeta[index]
      const updatedFileMeta = fileMeta.filter((_, idx) => index !== idx)

      updateWorkflowBlockMeta({
        [EFieldKeys.FILE_INPUT]: updatedFileMeta,
        [EFieldKeys.FILE_INPUT_NUMBER]: updatedFileMeta.length
      })

      if (fieldName) {
        handleFieldSelect(fieldName, null)
      }
    },
    [block.meta, handleFieldSelect, updateWorkflowBlockMeta]
  )

  const handleOutputSelect = useCallback(
    (item: Partial<IWorkflowDropdownValue> | null) => {
      handleFieldSelect(EFieldKeys.SOURCE_INPUT, item)
      updateWorkflowBlockMeta({ [EFieldKeys.SOURCE_INPUT]: item })
    },
    [handleFieldSelect, updateWorkflowBlockMeta]
  )

  const handleOutputReset = useCallback(() => handleOutputSelect(null), [handleOutputSelect])

  const handleScenarioRetry = useCallback(() => {
    if (!currentScenario?.id) return

    void getScenarioFields(currentScenario.id)
  }, [currentScenario, getScenarioFields])

  const handleCredentialAdd = useCallback(() => {
    const credentialsMeta = block.meta[EFieldKeys.CREDENTIAL_IDS] as Array<string | null>

    const updatedCredentials = produce(credentialsMeta, draft => {
      draft.push(null)
    })

    updateWorkflowBlockMeta({ [EFieldKeys.CREDENTIAL_IDS]: updatedCredentials })
  }, [block.meta, updateWorkflowBlockMeta])

  const handleCredentialChange = useCallback(
    (index: number, value: string) => {
      const credentials = block.meta[EFieldKeys.CREDENTIAL_IDS] as Array<string | null>

      const updatedCredentials = produce(credentials, draft => {
        draft[index] = value
      })

      updateWorkflowBlockMeta({ [EFieldKeys.CREDENTIAL_IDS]: updatedCredentials })
    },
    [block.meta, updateWorkflowBlockMeta]
  )

  const handleCredentialDelete = useCallback(
    (index: number) => {
      const credentials = block.meta[EFieldKeys.CREDENTIAL_IDS] as Array<string | null>

      const updatedCredentials = produce(credentials, draft => {
        draft.splice(index, 1)
      })

      updateWorkflowBlockMeta({ [EFieldKeys.CREDENTIAL_IDS]: updatedCredentials })
    },
    [block.meta, updateWorkflowBlockMeta]
  )

  useMount(() => {
    const template = block.meta[EFieldKeys.TEMPLATE_OBJECT] as IWorkflowScenarioTemplate | null

    if (template) {
      setCurrentScenario(template)
      updateWorkflowBlockInputDefinitions(template)
      dispatch(toggleAllChangesSaved(true))
    }

    void getScenarios()
  })

  return {
    scenarios,
    currentScenario,
    isLoading,
    isFieldsLoading,
    fieldsLoadingError,
    handeScenarioOpen,
    handeScenarioChange,
    handleFieldSelect,
    handleOutputSelect,
    handleOutputReset,
    handleScenarioRetry,
    handleFileInputAdd,
    handleFileInputDelete,
    handleFileInputChange,
    handleCredentialAdd,
    handleCredentialDelete,
    handleCredentialChange
  }
}
