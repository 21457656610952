export const DETAILED_VIEW_AREA_HEIGHT = 555
export const DETAILED_VIEW_AREA_WIDTH = 1145
export const DETAILED_VIEW_ASPECT_RATIO = DETAILED_VIEW_AREA_WIDTH / DETAILED_VIEW_AREA_HEIGHT
export const DETAILED_VIEW_SCROLL_SIZE = 20

export const DETAILED_VIEW_MODAL_SELECTOR = '.detailed-view-modal'
export const DETAILED_VIEW_MODAL_CONTAINER_SELECTOR = `${DETAILED_VIEW_MODAL_SELECTOR} .MuiPaper-root`
export const DETAILED_VIEW_CONTENT_WRAPPER = `${DETAILED_VIEW_MODAL_SELECTOR} .detailed-view-content-wrapper`
export const DETAILED_VIEW_AREA_SELECTOR = `${DETAILED_VIEW_MODAL_SELECTOR} .detailed-area`
export const DETAILED_VIEW_SCROLLABLE_AREA_SELECTOR = `${DETAILED_VIEW_MODAL_SELECTOR} .scrollable-area`
