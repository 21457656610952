import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Component, createRef } from 'react'
import { differenceInHours } from 'date-fns'
import { AnimatePresence, motion } from 'framer-motion'
import { matchPath, withRouter } from 'react-router-dom'
import { Quill } from '@praxie/react-quill'
import { connect } from 'react-redux'
import RotateIcon from 'assets/images/icons/ic_refresh.svg?react'
import interact from 'interactjs'
import { BulkDetailsRequest, DetailsRequest } from '_proto/dictionary/v1/dictionary.pb'
import { HIDE_ON_PDF_CLASSNAME } from 'constants/pdfConstants'
import { SentryService } from 'services/sentry.service'
import {
  DEFAULT_CARD_HEIGHT,
  getDownloadLink,
  sanitize,
  updateWidgetPositionWithSection,
  updateWidgetsDimensions
} from 'helpers/builderHelpers'
import { sortByPositions } from 'helpers/cardsPositionHelpers'
import { findAncestor, generateGUID } from 'helpers/common/commonHelpers'
import { checkRoute, navigateToLink, routesList } from 'helpers/routesHelpers'
import { handleGoBack } from 'helpers/widget/widgets.helpers'
import { flagsList, hasFlag } from 'helpers/widget/widgetDataHelpers'
import { createWidgetMenu, processUploadFile, updateWidgetName } from 'api/widgetAPI'
import { getQueryWidgetData } from 'api/tenantAPI'
import { TODAY_FORMULA, UPDATE_FREQUENCY_IN_HOURS } from 'constants/common'
import { PATHS } from 'constants/paths.constants'
import { getScriptFromWidget, runScript } from 'helpers/workflowExecution/workflowMetaHelpers'
import { getSourceCardFromLinked } from 'helpers/workflowExecution/workflowPayloadHelpers'
import { keyDownEventListener } from 'helpers/board/boardHelpers'
import { getCardsList } from 'api/bindCardAPI'
import { parseOptionsFromQuery } from 'helpers/widgetDataConvertorsHelprers'
import WidgetOverlay from 'components/widgets/WidgetOverlay'
import { INVITATION_MODAL_MODES } from 'helpers/invitationHelpers'
import { getCurrentBoard } from 'selectors/boardSelectors'
import { getIsDetailedViewModalReady } from 'selectors/detailedViewSelectors'
import { EFileUploaderFileTypes, FileUploaderGrpcService } from 'services/fileUploader.service'
import { createWidgetFileLink, getImageUrl, parseFileToWidget } from 'helpers/files/filesHelpers'
import { extensionsMap, GOOGLE, OFFICE } from 'constants/cardBuilder/widgetsConstants'
import { getBoardMenuBoards, getTeamRole } from 'selectors/profile.selectors'
import { updateThirdPartyWidget } from 'helpers/linkingResolution/thirdPartyUpdating'
import { EWidgetModes } from 'features/widgets/widgets.types'
import {
  MINIMAL_WIDGET_RESIZE_DOTS,
  WIDGET_OUTLINE_CLASSES,
  WIDGET_RESIZE_DOTS,
  WIDGET_RESIZE_THRESHOLD,
  WIDGETS_IN_SYSTEM
} from 'features/widgets/widgets.constants'
import { WidgetsRequireService } from 'features/widgets/widgetsRequire.service'
import { DictionariesGrpcService } from 'features/dictionaries/dictionaries.grpc.service'
import { generateWidgetPdf } from 'features/widgets/generatePDF/generatePDFHelper'
import { TeamService } from 'features/team/team.service'
import { useTeamStore } from 'features/team/team.store'
import { deriveMemoizedActiveTeamMemberList } from 'features/team/team.helpers'
import { IS_MOBILE_DEVICE } from 'helpers/userAgent.helpers'
import { getSelectedWidgetList, getWidgetList } from 'features/widgets/widgets.helpers'
import { DATA_SECTION_ATTR } from 'features/cards/cards.constants'
import { getCardScrollableArea } from 'features/cards/cards.helpers'
import { WidgetSnappingService } from 'components/cardbuilder/widgetSnapping/widgetSnapping.service'
import messages from '../../constants/messages'
import { TOGGLE_WIDGET_TOAST_ACTION_MESSAGE, WIDGET_UPDATE } from '../../constants/actionTypes'
import {
  setEditingWidget,
  setSelectedWidgets,
  clearWidgetSelection,
  saveChanges,
  toggleSmartLinkModal,
  toggleDataLinking,
  toggleChartsModal,
  toggleIssueCounterModal,
  setActiveWidgetUpdated,
  restrictAddedWidgetsInCardBuilder,
  bulkWidgetsUpdate
} from 'actions/builderActions'
import { toggleJiraConnectionModal } from 'actions/profileActions'
import {
  receiveUploadWidget,
  createWidgetsFromFiles,
  toggleGooglePicker,
  toggleOneDrivePicker,
  setUploadFilesWidgetData,
  toggleWidgetFullscreenModal
} from 'actions/widgetsActions'
import {
  restrictAddedWidgetsOnDetailedView,
  showToastMessage,
  toggleWelcomeCardPreview,
  widgetUpdateOnDetailedViewReceive
} from 'actions/boardActions'
import { startClickWorkflowExecution } from 'actions/clickWorkflowActions'
import { setRibbonCallback, setRibbonState } from 'actions/ribbonActions'
import { getFilterData } from 'actions/filtersActions'
import { toggleInvitationModal } from 'actions/teamAdministrationActions'
import { setPDFWidgetGeneration } from 'actions/detailedViewActions'

import WidgetPlaceholder, { getPlaceholderSize } from './WidgetPlaceholder'
import Interactive from '../common/Interactable'
import getState from '../../store/storeBindings/getState'
import '../../scss/widget.scss'

const backendUrl = import.meta.env.VITE_BACKEND_URL

const Link = Quill.import('formats/link')

Link.sanitize = sanitize

const VIEW_STATE = 'view'
const SELECTED_STATE = 'selected'
const EDITING_STATE = 'editing'

const LEFT_ARROW_CODE = 37
const UP_ARROW_CODE = 38
const RIGHT_ARROW_CODE = 39
const DOWN_ARROW_CODE = 40

const clearBrowserSelection = () => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty()
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges()
    }
  } else if (document.selection) {
    document.selection.empty()
  }
}

const downloadFile = (fileUrl, tenantId, boardId, extension) => {
  if (extensionsMap[extension] === OFFICE || extensionsMap[extension] === GOOGLE) {
    window.open(fileUrl, '_blank')
    return
  }

  let fileName = FileUploaderGrpcService.parseLink(fileUrl)

  try {
    fileName = decodeURIComponent(fileName)
  } catch {
    // if there is an error to decode url, it means that input parameter wasn't encoded
    // so we should work with initial value
    console.info('Link cannot be decoded')
  }

  const link = FileUploaderGrpcService.getDownloadFileLink(
    EFileUploaderFileTypes.WIDGET,
    fileName,
    {
      tenantID: tenantId,
      boardID: boardId
    }
  )

  window.open(link)
}

const FileDownloadButton = ({ fileUrl, tenantId, boardId, extension }) => {
  const getIconClassName = () => {
    const googleIcon = 'icon-google-download-8ea3b1'
    const msIcon = 'icon-office-download-8ea3b1'
    const _extensionsMap = {
      gdocs: googleIcon,
      gsheets: googleIcon,
      gslides: googleIcon,
      onedocs: msIcon,
      onesheets: msIcon,
      oneslides: msIcon
    }

    return _extensionsMap[extension] || 'up-font-ic-download'
  }

  return (
    <a
      data-html2canvas-ignore="true"
      className="file-download-button"
      data-file-download-button
      onClick={event => {
        event.stopPropagation()
        downloadFile(fileUrl, tenantId, boardId, extension)
      }}
    >
      <i className={`icon ${getIconClassName()}`} />
    </a>
  )
}

const canBeSelected = () => {
  const selection = window.getSelection()

  if (selection.type !== 'Range') {
    return true
  }

  const { focusNode } = selection
  if (!focusNode || (focusNode && !focusNode.classList)) {
    return false
  }

  const spreadsheetClipboardClassName = 'k-spreadsheet-clipboard'

  // if selection type is equal to 'range' and it's pointed to spreadsheet clipboard
  // we should allow to select widget
  return focusNode.classList.contains(spreadsheetClipboardClassName)
}

class WidgetItem extends Component {
  constructor(props) {
    super(props)

    // Has to be in the constructor to set up before mounting.
    this.setupInteractionOptions()
  }

  state = {
    widgetData: this.props.widgetData,
    widgetComponent: 'loading',
    isNewlyCreated: true,
    isComponentDone: false,
    canBeRendered: !this.props.isRenderLazily
  }

  widgetSizeRatio
  restrictedDirection = {
    direction: '',
    dx: 0,
    dy: 0
  }
  resizeWidgetCallback = null
  isInteracting = false
  widgetWrapper = createRef()
  lazyRenderingTimeout = null
  lazyRenderingObserver = null
  isWidgetDragging = false
  isWidgetResizing = false
  lastCardScrollTop = 0

  componentDidMount() {
    const widgetName = this.props.widgetData.widgetClassName

    this.loadWidget(widgetName).then(({ _, Component: _Component }) => {
      this.setState({ widgetComponent: _Component })
    })

    if (this.props.isRenderLazily) {
      this.setupLazyRendering()
    } else {
      this.handleWidgetRendering()
    }

    this.setWidgetFocus()
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { widgetData } = props
    const state = { widgetData }

    if (widgetData.newlyCreated) {
      delete widgetData.newlyCreated
      const isSetToEditingState =
        this.state.isNewlyCreated &&
        widgetData.editOnCreate &&
        this.getWidgetSelectedState(props) !== EDITING_STATE
      if (isSetToEditingState) {
        this.props.setSelectedWidgets([])
        this.props.setEditingWidget(widgetData.uuid)
      }
      state.isNewlyCreated = false
    }
    this.setState(state)
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { canBeRendered, isComponentDone, isNewlyCreated } = this.state

    const {
      widgetData,
      mode,
      editingWidget,
      selectedWidgets,
      highlightedWidgets,
      isEditingDisabledOnDetailed,
      isBoardCopying,
      isThumbnailView,
      rotatingWidgetUuid,
      isPDFGeneration,
      isDetailedViewModalReady
    } = this.props
    const { uuid, widgetClassName } = widgetData

    if (this.state.widgetComponent !== nextState.widgetComponent) return true

    if (window.isDragging) {
      return false
    }

    if (canBeRendered !== nextState.canBeRendered) {
      return true
    }

    // rerender component when appropriate widget bundle was downloaded and parsed
    if (!isComponentDone && nextState.isComponentDone) {
      return true
    }

    const isWidgetEditing = nextProps.editingWidget === uuid || editingWidget === uuid
    const isWidgetSelected =
      nextProps.selectedWidgets.includes(uuid) || selectedWidgets.includes(uuid)

    // this widget class uses another widgets to show inside.
    // So it should be updated each time
    const excludedWidgets = ['UploadFilesWidget']
    const isSameWidgetData = widgetData === nextProps.widgetData

    if ((mode === EWidgetModes.DETAILED && isThumbnailView) || mode === EWidgetModes.PREVIEW) {
      const shouldRerender = [excludedWidgets.includes(widgetClassName)].some(check => !!check)

      // widget should be rendered on thumbnail in case any thing above was changed,
      // even if widget data remains the same
      if (shouldRerender) {
        return true
      }

      // otherwise widget should be rendered only in case something changes in widget data
      return !isSameWidgetData
    }

    if (mode === EWidgetModes.EDIT) {
      let checks = [
        excludedWidgets.includes(widgetClassName),
        isWidgetEditing,
        isWidgetSelected,
        rotatingWidgetUuid === widgetData.uuid,
        isNewlyCreated !== nextState.isNewlyCreated
      ]

      // This is necessary to avoid rerendering for widgets that don't participate in snapping at all.
      const isWidgetInHighlightedList = nextProps.highlightedWidgets.includes(widgetData.uuid)
      const wasWidgetInHighlightedList = highlightedWidgets.includes(widgetData.uuid)

      if (!this.isWidgetInteracting && isWidgetInHighlightedList !== wasWidgetInHighlightedList) {
        checks = [...checks, highlightedWidgets !== nextProps.highlightedWidgets]
      }

      const shouldRerender = !!checks.some(check => !!check)
      // widget should be rendered in case any thing above was changed,
      // even if widget data remains the same
      if (shouldRerender) {
        return true
      }

      // otherwise widget should be rendered only in case something changes in widget data
      return !isSameWidgetData
    }

    if (mode === EWidgetModes.DETAILED) {
      const shouldRerender = [
        excludedWidgets.includes(widgetClassName),
        isWidgetEditing,
        isWidgetSelected,
        nextProps.isEditingDisabledOnDetailed !== isEditingDisabledOnDetailed,
        nextProps.isBoardCopying !== isBoardCopying,
        nextProps.isPDFGeneration !== isPDFGeneration,
        nextProps.isDetailedViewModalReady !== isDetailedViewModalReady
      ].some(check => !!check)

      // widget should be rendered on DV in case any thing above was changed,
      // even if widget data remains the same
      if (shouldRerender) {
        return true
      }

      // otherwise widget should be rendered only in case something changes in widget data
      return !isSameWidgetData
    }

    return true
  }

  componentDidUpdate() {
    this.setWidgetFocus()
  }

  componentWillUnmount() {
    this.destroyLazyRendering()
  }

  get isWidgetInteracting() {
    return this.isWidgetDragging || this.isWidgetResizing
  }

  get cardScrollTop() {
    return this.scrollContainer.scrollTop ?? 0
  }

  initializeScrollableArea = () => {
    this.scrollContainer = getCardScrollableArea()

    if (!this.scrollContainer) return

    this.lastCardScrollTop = this.cardScrollTop
    this.scrollContainer.addEventListener('scroll', this.handleWidgetsAutoscroll)
  }

  cleanupScrollableArea = () => {
    if (!this.scrollContainer) return

    this.scrollContainer.removeEventListener('scroll', this.handleWidgetsAutoscroll)
  }

  // To fix: https://leverxeu.atlassian.net/issues/EUPBOARD01-18783
  // Manually fire the onMove event when the container is auto-scrolling.
  handleWidgetsAutoscroll = () => {
    if (!this.isWidgetDragging) {
      this.lastCardScrollTop = this.cardScrollTop
      return
    }

    const cardHeight = this.props.currentCard.height || DEFAULT_CARD_HEIGHT

    const target = this.widgetWrapper.current.node
    const widgetHeight = target.offsetHeight

    const sectionIndex = parseFloat(target.dataset.section)
    const widgetTop = parseFloat(target.dataset.y)
    const widgetBottom = widgetTop + widgetHeight + sectionIndex * DEFAULT_CARD_HEIGHT

    const isWidgetWithinBounds = widgetBottom >= cardHeight

    if (isWidgetWithinBounds) return

    const currentScroll = this.cardScrollTop
    const dy = currentScroll - this.lastCardScrollTop

    this.lastCardScrollTop = this.cardScrollTop

    const scrollEvent = { dx: 0, dy, delta: { x: 0, y: dy }, shiftKey: false, target }

    this.draggableOptions.onmove(scrollEvent, false)
  }

  loadWidget = widgetName => {
    return WidgetsRequireService.loadWidget(widgetName, {
      FileDownloadButton: this.fileDownloadButton
    })
  }

  fileDownloadButton = ({ fileUrl, extension, tenantId, boardId }) => {
    const { widgetData } = this.props

    return (
      <FileDownloadButton
        extension={extension || widgetData.extension}
        tenantId={tenantId || widgetData.tenantId}
        boardId={boardId || widgetData.boardId}
        fileUrl={fileUrl}
      />
    )
  }

  onWidgetClick = () => {
    // setTimeout for link inside clickable div
    const callback = () => {
      const { uuid, flags } = this.props.widgetData

      this.toggleChartsModal()
      this.toggleIssueCounterModal()
      // set all widgets as deselected
      // and set current as editing
      if (hasFlag(flags, flagsList.hasEditMode)) {
        this.props.setSelectedWidgets([])
        this.props.setEditingWidget(uuid)
      }
    }
    if (this.props.mode === EWidgetModes.DETAILED) {
      setTimeout(callback, 0)
    } else {
      callback()
    }
  }

  onWidgetKeyDown = event => {
    if (event.target !== event.currentTarget) return

    const isSelected = this.getWidgetSelectedState(this.props) === SELECTED_STATE
    const keyCodes = [LEFT_ARROW_CODE, UP_ARROW_CODE, RIGHT_ARROW_CODE, DOWN_ARROW_CODE]
    const delta = event.shiftKey ? 10 : 1
    const cardContent = document.querySelector('#card-content-area')
    if (this.props.isMultiSelect && isSelected && keyCodes.indexOf(event.keyCode) !== -1) {
      event.preventDefault()
      const target = this.getWidgetWrapper()
      const x = parseFloat(target.getAttribute('data-x')) || 0
      const y = parseFloat(target.getAttribute('data-y')) || 0
      let dx = 0
      let dy = 0
      let isMoveWidget = false
      switch (event.keyCode) {
        case LEFT_ARROW_CODE:
          dx = -delta
          isMoveWidget = x + dx >= 0
          break
        case UP_ARROW_CODE:
          dy = -delta
          isMoveWidget = y + dy >= 0
          break
        case RIGHT_ARROW_CODE:
          dx = delta
          isMoveWidget = cardContent.offsetWidth >= x + dx + target.offsetWidth
          break
        case DOWN_ARROW_CODE:
          dy = delta
          isMoveWidget = cardContent.offsetHeight >= y + dy + target.offsetHeight
          break
        default:
          break
      }
      if (isMoveWidget) {
        this.props.moveMultiSelectRect({ target, event, dx, dy })
      }
    } else if (
      isSelected &&
      event.keyCode &&
      !this.props.rotatingWidgetUuid &&
      keyCodes.indexOf(event.keyCode) !== -1
    ) {
      event.preventDefault()
      const { target } = event
      const x = parseFloat(target.getAttribute('data-x')) || 0
      const y = parseFloat(target.getAttribute('data-y')) || 0
      const section = parseFloat(target.getAttribute(DATA_SECTION_ATTR)) || 0
      let isMoveWidget = false
      let dx = x
      let dy = y
      const sectionOffset = section * DEFAULT_CARD_HEIGHT
      switch (event.keyCode) {
        case LEFT_ARROW_CODE:
          dx = x - delta
          isMoveWidget = dx >= 0
          break
        case UP_ARROW_CODE:
          dy = y - delta
          isMoveWidget = dy + sectionOffset >= 0
          break
        case RIGHT_ARROW_CODE:
          dx = x + delta
          isMoveWidget = cardContent.offsetWidth >= dx + target.offsetWidth
          break
        case DOWN_ARROW_CODE:
          dy = y + delta
          isMoveWidget = cardContent.offsetHeight >= dy + sectionOffset + target.offsetHeight
          break
        default:
          break
      }
      if (isMoveWidget) {
        target.style.left = `${dx}px`
        target.setAttribute('data-x', dx)
        target.style.top = `${dy}px`
        target.setAttribute('data-y', dy)
      }
    }
  }

  onWidgetKeyUp = event => {
    if (event.target !== event.currentTarget) return

    const keyCodes = [LEFT_ARROW_CODE, UP_ARROW_CODE, RIGHT_ARROW_CODE, DOWN_ARROW_CODE]
    const isSelected = this.getWidgetSelectedState(this.props) === SELECTED_STATE
    if (keyCodes.indexOf(event.keyCode) !== -1 && this.props.isMultiSelect && isSelected) {
      this.props.endMoveMultiSelectRect()
    } else if (keyCodes.indexOf(event.keyCode) !== -1) {
      const { target } = event
      const x = parseFloat(target.getAttribute('data-x')) || 0
      const { y, section } = updateWidgetPositionWithSection({
        origY: parseFloat(target.getAttribute('data-y')) || 0,
        origSection: parseFloat(target.getAttribute(DATA_SECTION_ATTR)) || 0
      })
      if (
        this.props.isWidgetIntersectsWithHiddenSections({
          x,
          y: y + section * DEFAULT_CARD_HEIGHT,
          width: target.offsetWidth,
          height: target.offsetHeight,
          transform: target.style.transform
        })
      ) {
        this.props.returnWidgetToOriginal(target, this.props.widgetData)
        this.props.showToastMessage({
          text: messages.ACTION_IS_UNAVAILABLE,
          size: 'M'
        })
      } else {
        const widgetDataToSave = {
          ...this.props.widgetData,
          positionX: x,
          positionY: y,
          section,
          width: target.offsetWidth,
          height: target.offsetHeight,
          rotation: target.style.transform
        }
        this.props.updateWidgetsPosition([widgetDataToSave])
      }
    }
  }

  getWidgetWrapper = () => {
    const cardAuthoring = document.querySelector('.card-authoring')
    return cardAuthoring.querySelector('.widget-multiselect-wrapper')
  }

  setupInteractionOptions = () => {
    this.draggableOptions = {
      // to have ability to drag or resize widget within editing mode need to set
      // '.not-interactable-zone' class to element which must be editable
      ignoreFrom: '.not-interactable-zone',
      autoScroll: {
        container: getCardScrollableArea(),
        distance: 1,
        interval: 1,
        margin: 20
      },
      onmove: (event, isSnappingEnabled = true) => {
        if (this.props.rotatingWidgetUuid) {
          return
        }

        const isSelected = this.getWidgetSelectedState(this.props) === SELECTED_STATE
        if (this.props.isMultiSelect && isSelected) {
          this.restrictedDirection.dx += event.dx
          this.restrictedDirection.dy += event.dy
          const target = this.getWidgetWrapper()
          this.props.moveMultiSelectRect({
            target,
            event,
            dx: undefined,
            dy: undefined,
            restrictedDirection: this.restrictedDirection,
            isSnappingEnabled
          })
        } else {
          this.restrictedDirection.dx += event.dx
          this.restrictedDirection.dy += event.dy
          if (event.shiftKey) {
            this.restrictedDirection.direction = this.props.resolveConstrainMovement({
              event,
              widgetData: this.props.widgetData,
              restrictedDirection: this.restrictedDirection
            })
          } else {
            this.props.moveWidgetRect({
              target: event.target,
              dx: event.dx,
              dy: event.dy,
              event,
              isSnappingEnabled
            })
          }
        }
      },
      onend: event => {
        this.isWidgetDragging = false
        this.cleanupScrollableArea()

        const isSelected = this.getWidgetSelectedState(this.props) === SELECTED_STATE
        this.props.endMoveWidgetRect()
        if (this.props.isMultiSelect && isSelected) {
          this.props.endMoveMultiSelectRect()
        } else {
          const { target } = event
          const x = parseFloat(target.getAttribute('data-x')) || 0
          const { y, section } = updateWidgetPositionWithSection({
            origY: parseFloat(target.getAttribute('data-y')) || 0,
            origSection: parseFloat(target.getAttribute(DATA_SECTION_ATTR)) || 0
          })
          this.restrictedDirection = {
            direction: '',
            dx: 0,
            dy: 0
          }
          if (
            this.props.isWidgetIntersectsWithHiddenSections({
              x,
              y: y + section * DEFAULT_CARD_HEIGHT,
              width: target.offsetWidth,
              height: target.offsetHeight,
              transform: target.style.transform
            })
          ) {
            this.props.returnWidgetToOriginal(target, this.props.widgetData)
            this.props.showToastMessage({
              text: messages.ACTION_IS_UNAVAILABLE,
              size: 'M'
            })
          } else {
            const widgetDataToSave = {
              ...this.props.widgetData,
              positionX: x >= 0 ? x : 0,
              positionY: y,
              section: section >= 0 ? section : 0,
              width: target.offsetWidth,
              height: target.offsetHeight,
              rotation: target.style.transform
            }
            this.props.updateWidgetsPosition([widgetDataToSave])
          }
        }
      },
      onstart: event => {
        this.isWidgetDragging = true
        this.initializeScrollableArea()

        const isWidgetSelected = this.getWidgetSelectedState(this.props) === SELECTED_STATE

        const isMultiSelect = isWidgetSelected && this.props.isMultiSelect

        const selectedWidgets = isMultiSelect ? getSelectedWidgetList() : [event.target]

        this.props.startMoveWidgetRect(selectedWidgets, isMultiSelect)

        if (!isWidgetSelected) {
          this.props.activateWidget()
        }

        if (isMultiSelect) {
          this.props.onMultiSelectMoveStart()
        }
        this.isInteracting = true
      },
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: '.card-content',
          endOnly: true,
          elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
        })
      ]
    }

    this.resizableOptions = {
      edges: {
        // selectors for resizable elements
        left: '.left-resize,.top-left-resize,.bottom-left-resize',
        right: '.right-resize,.top-right-resize,.bottom-right-resize',
        bottom: '.bottom-resize,.bottom-right-resize,.bottom-left-resize',
        top: '.top-resize,.top-right-resize,.top-left-resize'
      },
      onmove: event => {
        // Sometimes intearactjs generates wrong values in event: { rect: {} } object
        // after "mouseup" event as a result there is unexpected huge resize effect
        // might be happened once a user release a mouse button.
        // In order to make an extra resize action after mouseup" intneractjs
        // manually triggers "onmove event" setting preEnd=true and wrong values
        // for delta width and height.

        const { preEnd } = event

        if (this.props.rotatingWidgetUuid || preEnd) {
          return
        }

        if (!event.shiftKey) {
          this.handleResize({
            event,
            newWidth: event.rect.width,
            newHeight: event.rect.height
          })
        } else {
          let newWidth = event.rect.width
          let newHeight = event.rect.height
          const { edges } = event
          const xIsPrimaryAxis = !!(edges.left || edges.right)

          // for saving aspect ratio
          if (xIsPrimaryAxis) {
            newHeight = newWidth / this.widgetSizeRatio
          } else {
            newWidth = newHeight * this.widgetSizeRatio
          }

          this.handleResize({
            event,
            newWidth,
            newHeight
          })
        }
      },
      onend: event => {
        this.isWidgetResizing = false

        WidgetSnappingService.resetSnappingState()

        const { target } = event
        const targetWidth = target.offsetWidth
        const targetHeight = target.offsetHeight
        const x = parseFloat(target.getAttribute('data-x')) || 0
        const { y, section } = updateWidgetPositionWithSection({
          origY: parseFloat(target.getAttribute('data-y')) || 0,
          origSection: parseFloat(target.getAttribute(DATA_SECTION_ATTR)) || 0
        })
        if (
          this.props.isWidgetIntersectsWithHiddenSections({
            x,
            y: y + section * DEFAULT_CARD_HEIGHT,
            width: targetWidth,
            height: targetHeight,
            transform: target.style.transform
          })
        ) {
          this.props.returnWidgetToOriginal(target, this.props.widgetData)
          this.props.showToastMessage({
            text: messages.ACTION_IS_UNAVAILABLE,
            size: 'M'
          })
          return
        }
        const isIntersection = this.props.getWidgetDeltaAuthoringArea({
          x,
          y: y + section * DEFAULT_CARD_HEIGHT,
          width: targetWidth,
          height: targetHeight,
          transform: target.style.transform,
          cardHeight: this.props.currentCard.height || DEFAULT_CARD_HEIGHT
        })
        // if widget intersects with authoring area - undo resize
        if (isIntersection.intersection) {
          this.props.returnWidgetToOriginal(target, this.props.widgetData)
          this.props.showToastMessage({
            text: messages.CANNOT_COMPLETE_ACTION,
            size: 'M'
          })
          return
        }
        let widgetDataToSave = {
          positionX: x >= 0 ? x + isIntersection.deltaX : 0,
          positionY: y + isIntersection.deltaY,
          section: section >= 0 ? section : 0,
          width: targetWidth,
          height: targetHeight,
          rotation: target.style.transform
        }
        if (this.resizeWidgetCallback) {
          const callbackResponse = this.resizeWidgetCallback(
            {
              width: targetWidth,
              height: targetHeight
            },
            'end'
          )
          if (callbackResponse) {
            widgetDataToSave = {
              ...widgetDataToSave,
              ...callbackResponse
            }
          }
        }
        this.props.updateWidgetsPosition([
          {
            ...this.props.widgetData,
            ...widgetDataToSave
          }
        ])
      },
      onstart: event => {
        this.isWidgetResizing = true

        const selectedWidgets = this.props.isMultiSelect ? getSelectedWidgetList() : [event.target]
        const allWidgets = getWidgetList()

        WidgetSnappingService.initSnappingState({
          selectedWidgets,
          allWidgets,
          actionType: 'resize'
        })

        if (event.shiftKey) {
          document.getSelection().removeAllRanges()
          const { target } = event
          const origWidth = target.offsetWidth
          const origHeight = target.offsetHeight
          this.widgetSizeRatio = this.widgetSizeRatio || origWidth / origHeight
        } else {
          this.widgetSizeRatio = undefined
        }

        const isSelected = this.getWidgetSelectedState(this.props) === SELECTED_STATE
        if (!this.props.isMultiSelect && !isSelected) {
          this.props.activateWidget()
        }
        this.isInteracting = true
      },
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: '.card-content',
          endOnly: true
        })
      ]
    }
  }

  setupLazyRendering() {
    const { mode } = this.props
    const { canBeRendered } = this.state

    // Skip for widgets on first section.
    if (!this.widgetWrapper.current || canBeRendered) {
      this.handleWidgetRendering()
      return
    }

    if (!('IntersectionObserver' in window)) {
      this.handleWidgetRendering()
      return
    }

    const observer = new IntersectionObserver(([entry]) => {
      const { isIntersecting } = entry

      if (isIntersecting) {
        // when widget appears on viewport
        this.lazyRenderingTimeout = setTimeout(() => {
          if (this.state.canBeRendered === false) {
            this.setState({ canBeRendered: true })
            this.handleWidgetRendering()
          }
          observer.disconnect()
        }, 700)
      } else if (this.lazyRenderingTimeout) {
        // when widget disappears on clear rendering timeout to not render widget
        clearTimeout(this.lazyRenderingTimeout)
      }
    })

    const node =
      mode === EWidgetModes.EDIT ? this.widgetWrapper.current.node : this.widgetWrapper.current

    observer.observe(node)

    this.lazyRenderingObserver = observer
  }

  setWidgetFocus() {
    const { mode, selectedWidgets, widgetData } = this.props
    if (mode === EWidgetModes.EDIT && selectedWidgets.indexOf(widgetData.uuid) !== -1) {
      const widget = this.widgetWrapper.current?.node
      if (!widget) return

      const { activeElement } = document
      const isInput = activeElement && activeElement.tagName === 'INPUT'
      // set 'forcing-focus' class for elements which need be focused
      const isForcedFocus = activeElement && activeElement.classList.contains('forcing-focus')
      if (widget && !isInput && !isForcedFocus) {
        const scrollableArea = document.querySelector('.scrollable-card-area')
        const y = scrollableArea.scrollTop
        widget.focus()
        if (scrollableArea.scrollTo) {
          scrollableArea.scrollTo(0, y)
        }
      }
    }
  }

  getWidgetSelectedState(props) {
    const {
      selectedWidgets,
      editingWidget,
      widgetData: { uuid }
    } = props

    if (selectedWidgets.indexOf(uuid) !== -1 && !props.isThumbnailView) {
      return SELECTED_STATE
    }
    if (editingWidget === uuid && !props.isThumbnailView) {
      return EDITING_STATE
    }
    return VIEW_STATE
  }

  setResizeWidgetCallback = resizeWidgetCallback => {
    this.resizeWidgetCallback = resizeWidgetCallback
  }

  setRibbonState = (payload = null) => {
    const { isMultiSelect } = this.props
    if (isMultiSelect) {
      return
    }

    const widgetSelectedState = this.getWidgetSelectedState(this.props)

    // skip setting widget state in case widget in view mode
    // to not corrupt ribbon data of another selected widget
    if (widgetSelectedState === VIEW_STATE) {
      return
    }

    if (this.isWidgetInteracting) return

    this.props.setRibbonState(payload)
  }

  setRibbonCallback = callback => {
    const { isMultiSelect, selectedWidgets, editingWidget } = this.props

    const isNothingSelected = !selectedWidgets.length && !editingWidget

    if (isMultiSelect || isNothingSelected) {
      return
    }

    const widgetSelectedState = this.getWidgetSelectedState(this.props)

    if (widgetSelectedState === VIEW_STATE) {
      return
    }

    if (this.isWidgetInteracting) return

    this.props.setRibbonCallback(callback)
  }

  getUserData = prop => {
    return this.props.user[prop]
  }

  getTeamMembersList = () => {
    const activeTeamId = useTeamStore.getState().id

    if (activeTeamId === this.props.tenantId) {
      return Promise.resolve({ data: useTeamStore.getState().getActiveMembers() })
    }

    let tenantId = this.props.tenantId

    if (this.props.currentCard.board.tenantId) {
      tenantId = this.props.currentCard.board.tenantId
    }

    return TeamService.getTeamMemberList(tenantId).then(({ data }) => {
      return { data: deriveMemoizedActiveTeamMemberList(data) }
    })
  }

  getCardNameByCardUuid(cardUuid) {
    const { cards = [] } = this.props
    const currentCard = cards.find(card => card.uuid === cardUuid) || {}
    return currentCard.name
  }

  getCardInfo = () => {
    const { currentCard, cardUuid, boardId, tenantId, widgetData, cardOwners } = this.props

    return {
      cardName: currentCard.name || this.getCardNameByCardUuid(cardUuid),
      boardName: currentCard.board.name,
      cardUuid: currentCard.uuid || cardUuid || widgetData.cardUuid,
      columnUuid: currentCard.columnUuid,
      boardId: currentCard.board.boardId || boardId,
      tenantId: currentCard.board.tenantId || tenantId,
      cardOwners: currentCard.owners || cardOwners
    }
  }

  toggleWidgetFullScreen = args => {
    const { state, blurWidget } = args
    this.props.toggleWidgetFullscreenModal(state)
    if (blurWidget) {
      this.props.setSelectedWidgets([])
      this.props.setEditingWidget('')
    }
  }

  getBoardCards = () => {
    const { currentCard, boardId, tenantId } = this.props

    const store = this.props.getState()()

    const isBoardView = checkRoute(window.location.pathname, routesList.board)

    const { cards } = store.board

    if (isBoardView && cards.length) {
      return Promise.resolve(cards.sort(sortByPositions))
    }
    return this.props
      .getCardsList({
        boardId: currentCard.board.boardId || boardId,
        tenantId: currentCard.board.tenantId || tenantId,
        expand: {}
      })
      .then(response => response.data.sort(sortByPositions))
  }

  getUploadedFiles = files => {
    const { user, currentCard } = this.props
    const { allowedCount } = this.restrictAddedWidgets(files)
    const { tenantId, boardId } = this.getCardInfo()
    return files.slice(0, allowedCount).map(file => ({
      file,
      type: 'file',
      loader: {
        name: file.name,
        size: file.size
      },
      tenantId,
      boardId,
      uuid: generateGUID(),
      // uploaded file doesn't have expanded user - needs to set email and username
      uploadedBy: { email: user.email, username: currentCard.board.tenantName }
    }))
  }

  handleResize = ({ event, newWidth, newHeight }) => {
    const { target } = event

    const section = parseFloat(target.getAttribute(DATA_SECTION_ATTR)) || 0

    // Restrict dimensions.
    const { minHeight, minWidth, maxHeight, maxWidth } = this.props.widgetData

    const checkIfInBounds = (size, minSize, maxSize) => {
      return (minSize ? size >= minSize : true) && (maxSize ? size <= maxSize : true)
    }

    const getTargetHeight = () => {
      if (!!maxHeight && newHeight > maxHeight) return maxHeight
      if (!!minHeight && newHeight < minHeight) return minHeight

      return newHeight
    }

    const getTargetWidth = () => {
      if (!!maxWidth && newWidth > maxWidth) return maxWidth
      if (!!minWidth && newWidth < minWidth) return minWidth

      return newWidth
    }

    let targetHeight = getTargetHeight()
    let targetWidth = getTargetWidth()

    // Translate when resizing from top or left edges.
    // Don't translate if restricted dimension reached
    const deltaY = checkIfInBounds(newHeight, minHeight, maxHeight) ? event.deltaRect.top : 0
    const deltaX = checkIfInBounds(newWidth, minWidth, maxWidth) ? event.deltaRect.left : 0

    let {
      currentRect: { left, top, height, width }
    } = WidgetSnappingService.getSnappingPosition({
      target,
      event,
      deltaX,
      deltaY
    })

    const {
      snappingData: { snappedPoints },
      isSnapped
    } = WidgetSnappingService.snappingState

    if (isSnapped) {
      const isVerticalSnapActive =
        (event.edges?.top && snappedPoints.top) || (event.edges?.bottom && snappedPoints.bottom)
      const isHorizontalSnapActive =
        (event.edges?.left && snappedPoints.left) || (event.edges?.right && snappedPoints.right)

      if (isVerticalSnapActive) {
        targetHeight = height
      }
      if (isHorizontalSnapActive) {
        targetWidth = width
      }
    }

    const objectBoundaries = {
      targetWidth,
      targetHeight,
      x: left,
      y: top,
      section
    }

    // If resizing causes it to leave the authoring area, exit early
    if (this.props.restrictLeavingAuthoringArea(objectBoundaries)) {
      return
    }

    target.style.width = `${targetWidth}px`
    target.style.height = `${targetHeight}px`

    target.style.left = `${left}px`
    target.style.top = `${top}px`

    target.setAttribute('data-x', left)
    target.setAttribute('data-y', top)

    if (this.resizeWidgetCallback) {
      this.resizeWidgetCallback(
        {
          width: targetWidth,
          height: targetHeight
        },
        'move'
      )
    }
  }

  toggleChartsModal = (isOpenedFromAnotherWidget = false, data = {}) => {
    const { widgetData, cardUuid, mode } = this.props

    if (IS_MOBILE_DEVICE) return

    const CHARTS_WITH_CUSTOM_MODAL = [
      WIDGETS_IN_SYSTEM.HeatmapChartWidget.name,
      WIDGETS_IN_SYSTEM.WaterfallChartWidget.name,
      WIDGETS_IN_SYSTEM.SpeedometerChartWidget.name
    ]

    if (CHARTS_WITH_CUSTOM_MODAL.includes(widgetData.widgetClassName)) return

    if (hasFlag(widgetData.flags, flagsList.isChart) || isOpenedFromAnotherWidget) {
      this.props.toggleChartsModal({
        state: true,
        type: 'new-chart',
        cardID: cardUuid,
        widgetID: widgetData.uuid || data.uuid,
        mode,
        data,
        isOpenedFromAnotherWidget
      })
    }
  }

  toggleIssueCounterModal = () => {
    const { cardUuid, widgetData, mode } = this.props

    if (IS_MOBILE_DEVICE) return

    if (widgetData.widgetClassName === 'JiraWidget') {
      this.props.toggleIssueCounterModal({
        state: true,
        cardID: cardUuid,
        widgetID: widgetData.uuid,
        mode
      })
    }
  }

  restrictAddedWidgets = newWidgets => {
    const { mode, cardUuid } = this.props
    if (mode === EWidgetModes.DETAILED) {
      return this.props.restrictAddedWidgetsOnDetailedView(newWidgets, cardUuid)
    }
    return this.props.restrictAddedWidgetsInCardBuilder(newWidgets)
  }

  handleWidgetRendering() {
    const {
      widgetData: { uuid, flags },
      widgetRendered
    } = this.props
    const hasDataToFetch = hasFlag(flags, flagsList.hasDataToFetch)
    if (hasDataToFetch) return
    widgetRendered(uuid)
  }

  catchWidgetError = () => {
    const {
      widgetData: { uuid },
      widgetRendered
    } = this.props

    widgetRendered(uuid)
  }

  destroyLazyRendering() {
    if (this.lazyRenderingObserver) {
      this.lazyRenderingObserver.disconnect()
      this.lazyRenderingObserver = null
    }
  }

  uploadFile = async data => processUploadFile(data)

  createWidgetsAfterFilesUploading = async data => {
    const {
      widgetData: { section, uuid }
    } = this.props
    const { tenantId, boardId } = this.getCardInfo()
    const createdWidgets = await this.props.createWidgetsFromFiles({ data, tenantId, boardId })
    this.props.createWidgetsAfterFilesUploading(
      createdWidgets.map(widget => ({
        ...widget,
        uploadFilesUuid: uuid,
        section
      }))
    )
  }

  createFileWidgetMenu = async file => {
    const { tenantId, boardId } = this.getCardInfo()
    const widget = parseFileToWidget({ file, tenantId, boardId })
    const { data } = await createWidgetMenu({ tenantId, boardId, data: widget })
    this.props.receiveUploadWidget(data)
    return data
  }

  setUploadFilesWidgetData = () => {
    const {
      widgetData: { uuid, cardUuid, boardId, tenantId },
      setUploadFilesWidgetData
    } = this.props

    const isBoardPage = !!matchPath(window.location.pathname, { path: PATHS.board.routerPath })

    setUploadFilesWidgetData({
      isUploadingFromDetailedView: isBoardPage,
      uploadFilesWidgetId: uuid,
      cardUuid,
      boardId,
      tenantId
    })
  }

  toggleGooglePicker = () => {
    this.setUploadFilesWidgetData()
    this.props.toggleGooglePicker(true)
  }

  toggleOneDrivePicker = () => {
    this.setUploadFilesWidgetData()
    this.props.toggleOneDrivePicker(true)
  }

  getUploadedFile = async data => {
    const file = FileUploaderGrpcService.parseFile(data)
    const { tenantId, boardId } = this.getCardInfo()
    const fileLink = FileUploaderGrpcService.getDownloadFileLink(
      EFileUploaderFileTypes.WIDGET,
      file.name,
      {
        tenantID: tenantId,
        boardID: boardId
      }
    )

    return { ...file, fileLink }
  }

  getUploadedImage = async data => {
    const file = FileUploaderGrpcService.parseFile(data)
    const { tenantId, boardId } = this.getCardInfo()
    const imageLink = createWidgetFileLink({ tenantId, boardId, name: file.name })

    return { ...file, imageLink }
  }

  createWidgetObject = (widgetID, widgetParams) => {
    if (this.props.updateWidget) {
      const { updateWidget, cardData } = this.props
      return updateWidget([{ ...this.state.widgetData, ...widgetParams }], cardData)
    }

    return Promise.reject()
  }

  updateWidgetNameOnDetailed = name => {
    const { widgetData } = this.props
    const { organizationId, tenantId, boardId, cardUuid, uuid: widgetId } = widgetData

    updateWidgetName(name, { organizationId, tenantId, boardId, cardUuid, widgetId })

    this.props.widgetUpdateOnDetailedViewReceive({
      data: [{ ...widgetData, widgetTitle: name }],
      cardID: cardUuid
    })
  }

  resizeToggle = event => {
    // to prevent widget selecting immediately after moving or resizing
    if (this.isInteracting) {
      this.isInteracting = false
      return
    }

    const {
      widgetData: { uuid, flags },
      isMultiSelect,
      editingWidget,
      selectedWidgets
    } = this.props
    const isMixedStates = hasFlag(flags, flagsList.hasMixedState)
    const widgetState = this.getWidgetSelectedState(this.props)
    const isCtrlKey = event.ctrlKey || event.metaKey || event.shiftKey
    const isNothingSelected = !selectedWidgets.length && !editingWidget

    if (!isCtrlKey && isMixedStates) {
      const isNonInteractableZone = findAncestor(event.target, '.not-interactable-zone')

      if (isNonInteractableZone) {
        if (widgetState !== EDITING_STATE) {
          this.props.setSelectedWidgets([])
          this.props.setEditingWidget(uuid)
        }
      } else if (isMultiSelect || isNothingSelected || canBeSelected()) {
        this.props.activateWidget(isCtrlKey)
      }
    } else if (widgetState !== EDITING_STATE) {
      // should prevent state changing while editing widget or if it is already selected
      if (isCtrlKey) {
        clearBrowserSelection()
      }
      this.props.activateWidget(isCtrlKey)
    }
  }

  rotatorMouseDown = () => {
    const {
      widgetData: { uuid }
    } = this.props

    this.props.setRotatingWidgetUuid(uuid)
  }

  changeWidgetParam = ({ param }) => {
    const _widgets = [
      {
        ...this.state.widgetData,
        ...param
      }
    ]
    const {
      currentCard: { height },
      mode
    } = this.props
    const cardContent = document.querySelector('.detailed-area')
    let cardHeight = height || DEFAULT_CARD_HEIGHT
    if (mode !== EWidgetModes.PREVIEW && cardContent) {
      cardHeight = cardContent?.offsetHeight
    }
    const updatedWidget = updateWidgetsDimensions(_widgets, cardHeight)[0]
    this.createWidgetObject('', updatedWidget)
  }

  deleteWidget = widgetUuid => {
    const { deleteWidget, widgetData } = this.props
    // flag could be used to not reset selection
    // if current widget deletes another one (not itself) selection should be saved
    const saveSelection = !!widgetUuid

    return deleteWidget(widgetUuid || widgetData.uuid, saveSelection)
  }

  resolveSmartLink = link => {
    const { cardUuid, boardId, tenantId, mode, welcomeCardData, toggleWelcomeCardPreview } =
      this.props

    // close welcome card if user clicks on a link in any widget placed on welcome card
    toggleWelcomeCardPreview({
      ...welcomeCardData,
      state: false
    })

    if (mode === EWidgetModes.DETAILED) {
      this.props.clearWidgetSelection(false)
    }

    navigateToLink({
      link,
      cardID: cardUuid,
      boardId,
      tenantId
    })
  }

  resolveOnClickScript = event => {
    const { isThumbnailView, isOnMainBoard, cardUuid, boardId, tenantId, mode, widgetData } =
      this.props
    if (isOnMainBoard) {
      // to not open detailed view when clicking from board view
      event.stopPropagation()
    }

    const store = this.props.getState()()

    const { cards } = store.board

    // WF should be triggered only from detailed view and board view
    const isDetailedView = mode === EWidgetModes.DETAILED && !isThumbnailView
    const isPreview = mode === EWidgetModes.PREVIEW && isOnMainBoard

    if (!isDetailedView && !isPreview) {
      return
    }

    const { workflowMeta } = widgetData

    if (workflowMeta.isWorkflowMode) {
      // in case click from linked card - needs to send request with ids of original card
      const sourceCard = getSourceCardFromLinked({ cards, cardUuid })
      const payload = sourceCard || { tenantId, boardId, cardUuid }

      this.props.startClickWorkflowExecution({
        ...payload,
        widgetUuid: widgetData.uuid,
        workflowMeta
      })
    } else {
      runScript({
        backendUrl,
        tenantId,
        boardId,
        cardUuid,
        script: getScriptFromWidget(widgetData)
      })
    }
  }

  showToastMessage = (text, size) => {
    this.props.showToastMessage({
      text,
      size
    })
  }

  toggleInvitationModal = () => {
    this.props.toggleInvitationModal({
      isShown: true,
      state: {
        mode: INVITATION_MODAL_MODES.CREATE_FROM_BOARD,
        fromTenantId: this.props.tenantId
      }
    })
  }

  bulkWidgetsUpdate = data => {
    // bulk widgets updates should be handled together
    // to store the changes in the one batch and be able to
    // manage with undo\redo as one update
    if (!this.props.bulkWidgetsUpdate) {
      return
    }
    this.props.setActiveWidgetUpdated(true)
    this.props.bulkWidgetsUpdate({
      ...this.state.widgetData,
      ...data
    })
  }

  showWidgetActionMessage = (text, size, onMessageContinue, delay, continueText) => {
    this.props.showToastMessage(
      {
        text,
        size,
        onMessageContinue,
        continueText
      },
      TOGGLE_WIDGET_TOAST_ACTION_MESSAGE,
      delay
    )
  }

  ungroupFilesFromUploadWidget = fileUuids => {
    const {
      currentCard,
      saveChanges,
      widgetData: { section }
    } = this.props
    const { tenantId } = currentCard.board
    const { boardId } = currentCard.board
    const cardUuid = currentCard.uuid

    // ungroup all files
    const groupedWidgets = currentCard.widgets.filter(
      widget => fileUuids.indexOf(widget.uuid) !== -1
    )
    if (!groupedWidgets.length) {
      return Promise.resolve()
    }

    groupedWidgets.forEach(widget => {
      widget.uploadFilesUuid = ''
      widget.section = section
    })

    const updatePayload = {
      type: WIDGET_UPDATE,
      data: groupedWidgets,
      tenantId,
      boardId,
      itemID: cardUuid
    }

    return saveChanges(updatePayload)
  }

  getUserPermission = ({ tenantId, boardId, permission }) => {
    const store = this.props.getState()()
    const boardMenu = getBoardMenuBoards(store)
    const board = boardMenu.find(item => item.tenantId === tenantId && item.boardId === boardId)
    if (!board) {
      return false
    }
    const {
      accessInfo: { permissions }
    } = board
    return !!permissions?.[permission]
  }

  updateThirdPartyWidget = async widget => {
    const { updateWidget, cardData } = this.props

    const resolvedWidget = await updateThirdPartyWidget(widget)

    if (resolvedWidget && updateWidget) {
      await updateWidget([resolvedWidget], cardData)
    }
  }

  // https://leverxeu.atlassian.net/browse/EUPBOARD01-13024
  // To have an ability to automatically update widget if today formula is present.
  updateWidgetWithTodayFormula = () => {
    const { widgetData, isThumbnailView, mode } = this.props
    const isDetailedView = mode === EWidgetModes.DETAILED && !isThumbnailView
    const isEditAllowed = this.getUserPermission({
      tenantId: widgetData.tenantId,
      boardId: widgetData.boardId,
      permission: 'updateWidget'
    })

    if (!isDetailedView || !isEditAllowed) return

    const targetStr =
      widgetData.widgetClassName === 'SpreadsheetWidget' ? widgetData.sheets : widgetData.rows

    const isTodayFormulaPresent = targetStr.replace(/\s/g, '').toUpperCase().includes(TODAY_FORMULA)

    if (isTodayFormulaPresent) {
      const differenceAfterLastUpdate = differenceInHours(new Date(), widgetData.updatedAt)

      if (differenceAfterLastUpdate > UPDATE_FREQUENCY_IN_HOURS) {
        this.createWidgetObject('')
      }
    }
  }

  fetchQueryWidgetOptions = async payload => {
    const { data } = await getQueryWidgetData(payload)

    const parsedOptions = parseOptionsFromQuery(data.data)

    return { options: parsedOptions, outputType: data.outputType }
  }

  fetchDictionary = async dictionaryId => {
    const request = DetailsRequest.create({ tenantId: this.props.tenantId, dictionaryId })
    return DictionariesGrpcService.fetchDictionary({ request })
  }

  fetchDictionaries = async dictionaryIds => {
    const request = BulkDetailsRequest.create({
      tenantId: this.props.tenantId,
      bulkIds: dictionaryIds
    })

    return DictionariesGrpcService.fetchDictionaries({ request })
  }

  render() {
    const {
      setGetSettingsComponentCallback,
      setActiveWidgetUpdated,
      hasRibbonSupport,
      widgetData,
      toggleJiraConnectionModal,
      isOnMainBoard,
      isMultiSelect,
      isThumbnailView,
      dateFormat,
      isEditingDisabledOnDetailed,
      isCardTemplate,
      isSnapshotPreview,
      toggleSmartLinkModal,
      toggleDataLinking,
      tenantId,
      organizationId,
      maxZIndex,
      toggleKeysListening,
      isDragging,
      isBoardCopying,
      getGroupedFiles,
      rotatingWidgetUuid,
      widgetRendered,
      isPDFGeneration,
      dataLinkingModalData,
      getFilterData,
      boardAppId,
      boardId,
      navItems,
      boardIds,
      boards,
      teamRole,
      editingWidget,
      isDetailedViewModalReady,
      highlightedWidgets
    } = this.props
    const { canBeRendered } = this.state
    // get widget state and set dragging and resizing properties
    const widgetSelectedState = this.getWidgetSelectedState(this.props)

    const isWidgetHighlighted = highlightedWidgets.includes(widgetData.uuid)

    const isMixedStates = hasFlag(widgetData.flags, flagsList.hasMixedState)
    const isDataConsumer = hasFlag(widgetData.flags, flagsList.isDataConsumer)
    const withoutAllFrames = hasFlag(widgetData.flags, flagsList.withoutAllFrames)
    const notClickable = hasFlag(widgetData.flags, flagsList.notClickable)
    const showProvidersListOnDetailed = hasFlag(
      widgetData.flags,
      flagsList.showProvidersListOnDetailed
    )

    const hasDataLinks = widgetData.dataLink && !!Object.keys(widgetData.dataLink).length

    const isTranslateOptions = !rotatingWidgetUuid
    const isCanTranslate =
      (widgetSelectedState === VIEW_STATE ||
        (isMixedStates && widgetSelectedState === EDITING_STATE) ||
        widgetSelectedState === SELECTED_STATE) &&
      isTranslateOptions

    const isEditableOnDetailed =
      !isEditingDisabledOnDetailed &&
      !widgetData.isLocked && // to lock widget by WF
      hasFlag(widgetData.flags, flagsList.isEditableOnDetailed)
    const hidePencilOnDetailed = hasFlag(widgetData.flags, flagsList.hidePencilOnDetailed)

    const isShownForward =
      widgetData.widgetClassName === 'TextWidget' &&
      !isMultiSelect &&
      widgetSelectedState === SELECTED_STATE
    const isInGroup = !!widgetData.groupIndex
    const isOutlineShown =
      WIDGET_OUTLINE_CLASSES.includes(widgetData.widgetClassName) && !isMultiSelect
    const hasDownloadUrl = widgetData.fileLink || widgetData.imgSrc
    const isDownloadIconShown =
      hasFlag(widgetData.flags, flagsList.hasDownloadLink) && hasDownloadUrl
    const hideWidgetPlaceholderOnDetailed = hasFlag(
      widgetData.flags,
      flagsList.hideWidgetPlaceholderOnDetailed
    )
    const downloadUrl = getDownloadLink(widgetData.fileLink || widgetData.imgSrc, backendUrl)

    const isClickAvailableOnDetailed =
      isEditableOnDetailed && isMixedStates && widgetSelectedState !== EDITING_STATE

    const isDoubleClickAvailableOnDetailed = isEditableOnDetailed && !isMixedStates

    const isPencilShownOnDetailed = isEditableOnDetailed && !hidePencilOnDetailed

    const isProvidersListShown = isDataConsumer && hasDataLinks && showProvidersListOnDetailed

    // bring widget to front if it's editing or should be in front
    const zIndex =
      widgetSelectedState === EDITING_STATE || isShownForward
        ? maxZIndex + 1
        : (widgetData.zIndex || 0) + 1

    const isMinimalResizePoints =
      widgetData.width < WIDGET_RESIZE_THRESHOLD || widgetData.height < WIDGET_RESIZE_THRESHOLD

    const resizeDots = isMinimalResizePoints ? MINIMAL_WIDGET_RESIZE_DOTS : WIDGET_RESIZE_DOTS

    const setRibbonCallback = hasRibbonSupport ? this.setRibbonCallback : null

    const Widget = this.state.widgetComponent

    const isWidgetBundleReady = Widget && Widget !== 'loading'

    switch (this.props.mode) {
      case EWidgetModes.PREVIEW:
        return (
          <div className="widget-resizable preview">
            {isWidgetBundleReady && canBeRendered && (
              <SentryService.ErrorBoundary
                fallback={<WidgetPlaceholder appearance="rendering-error" size="ordinary" />}
                onError={this.catchWidgetError}
              >
                <Widget
                  url={backendUrl}
                  frontendUrl={import.meta.env.VITE_FRONTEND_URL}
                  istioHost={window.istioHost}
                  getImageUrl={getImageUrl}
                  dateFormat={dateFormat}
                  mode={this.props.mode}
                  widgetSelectedState="view"
                  loadThirdPartyWidget={this.loadWidget}
                  createWidgetObject={this.createWidgetObject}
                  widgetData={widgetData}
                  openJiraConnectionModal={() => toggleJiraConnectionModal(true)}
                  getUserData={this.getUserData}
                  getTeamMembersList={this.getTeamMembersList}
                  getCardInfo={this.getCardInfo}
                  getBoardCards={this.getBoardCards}
                  tenantId={tenantId}
                  organizationId={organizationId}
                  setGetSettingsComponentCallback={setGetSettingsComponentCallback}
                  setEditingWidget={this.props.setEditingWidget}
                  setActiveWidgetUpdated={setActiveWidgetUpdated}
                  setSelectedWidgets={this.props.setSelectedWidgets}
                  isCardTemplate={isCardTemplate}
                  isThumbnailView={isThumbnailView}
                  resolveSmartLink={this.resolveSmartLink}
                  resolveOnClickScript={this.resolveOnClickScript}
                  getGroupedFiles={widgets => getGroupedFiles(widgetData.uuid, widgets)}
                  afterWidgetsWithDataRendered={() => widgetRendered(widgetData.uuid)}
                  keyDownEventListener={keyDownEventListener}
                  handleGoBack={() =>
                    handleGoBack({ boardAppId, tenantId, boardId, navItems, boardIds, boards })
                  }
                  fetchQueryWidgetData={this.fetchQueryWidgetOptions}
                  fetchDictionary={this.fetchDictionary}
                  fetchDictionaries={this.fetchDictionaries}
                  toggleInvitationModal={this.toggleInvitationModal}
                  isOnMainBoard={isOnMainBoard}
                  isRenderedOnSettingModal={this.props.isRenderedOnSettingModal}
                  teamRole={teamRole}
                />
              </SentryService.ErrorBoundary>
            )}
            {canBeRendered &&
              isDownloadIconShown &&
              isOnMainBoard &&
              this.fileDownloadButton({ fileUrl: downloadUrl })}
          </div>
        )
      case EWidgetModes.EDIT:
        return (
          <Interactive
            ref={this.widgetWrapper}
            resizable={isCanTranslate}
            draggable={isCanTranslate}
            resizableOptions={this.resizableOptions}
            draggableOptions={this.draggableOptions}
          >
            <div
              id={`id_${widgetData.uuid}`}
              tabIndex="0"
              className={classNames(`widget-resizable edit ${widgetSelectedState}-state`, {
                'in-group': isInGroup,
                'mixed-states': isMixedStates,
                'widget-outline': isOutlineShown,
                'without-all-frames': withoutAllFrames,
                'not-clickable': notClickable,
                'is-dragging': isDragging,
                highlighted: isWidgetHighlighted
              })}
              data-x={widgetData.positionX || 0}
              data-y={widgetData.positionY || 0}
              data-section={widgetData.section || 0}
              style={{
                top: widgetData.positionY || 0,
                left: widgetData.positionX || 0,
                width: widgetData.width || 0,
                height: widgetData.height || 0,
                transform: widgetData.rotation || 'rotate(0)',
                zIndex
              }}
              onDoubleClick={this.onWidgetClick}
              onKeyDown={widgetSelectedState === SELECTED_STATE ? this.onWidgetKeyDown : null}
              onKeyUp={widgetSelectedState === SELECTED_STATE ? this.onWidgetKeyUp : null}
              onClick={this.resizeToggle}
            >
              <AnimatePresence>
                <motion.div
                  key={isDragging ? 'true' : 'false'}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <div className="widget-rotator" onMouseDown={this.rotatorMouseDown}>
                    <RotateIcon />
                  </div>
                  {resizeDots.map(dot => (
                    <div key={dot} className={`widget-resizer ${dot}`} />
                  ))}
                </motion.div>
              </AnimatePresence>
              {!canBeRendered && (
                <WidgetPlaceholder
                  appearance="rendering"
                  size={getPlaceholderSize({
                    width: widgetData.width || 0,
                    height: widgetData.height || 0
                  })}
                />
              )}
              {!isWidgetBundleReady && canBeRendered && <WidgetPlaceholder appearance="loading" />}
              {isWidgetBundleReady && canBeRendered && (
                <SentryService.ErrorBoundary
                  fallback={<WidgetPlaceholder appearance="rendering-error" size="ordinary" />}
                  onError={this.catchWidgetError}
                >
                  <Widget
                    url={backendUrl}
                    frontendUrl={import.meta.env.VITE_FRONTEND_URL}
                    istioHost={window.istioHost}
                    getImageUrl={getImageUrl}
                    dateFormat={dateFormat}
                    mode={this.props.mode}
                    loadThirdPartyWidget={this.loadWidget}
                    createWidgetObject={this.createWidgetObject}
                    setGetWidgetRibbonCallback={setRibbonCallback}
                    setActiveWidgetState={this.setRibbonState}
                    setActiveWidgetUpdated={setActiveWidgetUpdated}
                    widgetData={widgetData}
                    changeWidgetParam={this.changeWidgetParam}
                    widgetSelectedState={widgetSelectedState}
                    setResizeWidgetCallback={this.setResizeWidgetCallback}
                    openJiraConnectionModal={() => toggleJiraConnectionModal(true)}
                    setEditingWidget={this.props.setEditingWidget}
                    setSelectedWidgets={this.props.setSelectedWidgets}
                    getUserData={this.getUserData}
                    getTeamMembersList={this.getTeamMembersList}
                    getCardInfo={this.getCardInfo}
                    getBoardCards={this.getBoardCards}
                    deleteWidget={this.deleteWidget}
                    tenantId={tenantId}
                    organizationId={organizationId}
                    isThumbnailView={isThumbnailView}
                    isCardTemplate={isCardTemplate}
                    toggleWidgetFullScreen={this.toggleWidgetFullScreen}
                    toggleSmartLinkModal={toggleSmartLinkModal}
                    toggleDataLinking={toggleDataLinking}
                    resolveSmartLink={this.resolveSmartLink}
                    resolveOnClickScript={this.resolveOnClickScript}
                    showToastMessage={this.showToastMessage}
                    showWidgetActionMessage={this.showWidgetActionMessage}
                    // to have ability to disable key events
                    // when popups opened from widgets
                    getFilterData={getFilterData}
                    keyDownEventListener={keyDownEventListener}
                    handleGoBack={() =>
                      handleGoBack({ boardAppId, tenantId, boardId, navItems, boardIds, boards })
                    }
                    toggleInvitationModal={this.toggleInvitationModal}
                    bulkWidgetsUpdate={this.bulkWidgetsUpdate}
                    uploadFile={this.uploadFile}
                    removeFile={this.removeFile}
                    getUploadedFile={this.getUploadedFile}
                    getUploadedImage={this.getUploadedImage}
                    createWidgetsAfterFilesUploading={this.createWidgetsAfterFilesUploading}
                    createFileWidgetMenu={this.createFileWidgetMenu}
                    getUserPermission={this.getUserPermission}
                    fetchQueryWidgetData={this.fetchQueryWidgetOptions}
                    fetchDictionary={this.fetchDictionary}
                    fetchDictionaries={this.fetchDictionaries}
                    generateWidgetPdf={generateWidgetPdf}
                    toggleChartsModal={data => this.toggleChartsModal(true, data)}
                    toggleGooglePicker={this.toggleGooglePicker}
                    toggleOneDrivePicker={this.toggleOneDrivePicker}
                    teamRole={teamRole}
                    toggleKeysListening={toggleKeysListening}
                    getGroupedFiles={widgets => getGroupedFiles(widgetData.uuid, widgets)}
                    getUploadedFiles={this.getUploadedFiles}
                    afterWidgetsWithDataRendered={() => widgetRendered(widgetData.uuid)}
                    ungroupFilesFromUploadWidget={this.ungroupFilesFromUploadWidget}
                    dataLinkingModalData={dataLinkingModalData}
                    onWidgetClick={this.onWidgetClick}
                  />
                </SentryService.ErrorBoundary>
              )}
            </div>
          </Interactive>
        )
      case EWidgetModes.DETAILED: {
        const isDetailedViewAutoScaleFinished = isThumbnailView || isDetailedViewModalReady
        const isReadyToRender =
          isWidgetBundleReady && isDetailedViewAutoScaleFinished && canBeRendered

        return (
          <>
            <div
              ref={this.widgetWrapper}
              id={`id_${widgetData.cardUuid}_${widgetData.uuid}`}
              className={classNames(`widget-resizable detailed ${widgetSelectedState}-state`, {
                editable: isEditableOnDetailed,
                locked: isBoardCopying || widgetData.isLocked,
                [HIDE_ON_PDF_CLASSNAME]: widgetData.shouldHideOnPDF
              })}
              data-x={widgetData.positionX || 0}
              data-y={widgetData.positionY || 0}
              style={{
                top: widgetData.positionY || 0,
                left: widgetData.positionX || 0,
                width: widgetData.width || 0,
                height: widgetData.height || 0,
                transform: widgetData.rotation || 'rotate(0)',
                zIndex
              }}
              onDoubleClick={isDoubleClickAvailableOnDetailed ? this.onWidgetClick : undefined}
              onTouchEnd={isClickAvailableOnDetailed ? this.onWidgetClick : undefined}
              // onClickCapture is used to avoid event bubbling prevention by children.
              onClickCapture={isClickAvailableOnDetailed ? this.onWidgetClick : undefined}
            >
              {!hideWidgetPlaceholderOnDetailed && (
                <>
                  {!canBeRendered && (
                    <WidgetPlaceholder
                      appearance="rendering"
                      size={getPlaceholderSize({
                        width: widgetData.width || 0,
                        height: widgetData.height || 0
                      })}
                    />
                  )}
                  {!isWidgetBundleReady && canBeRendered && (
                    <WidgetPlaceholder appearance="loading" />
                  )}
                </>
              )}
              {isReadyToRender && (
                <SentryService.ErrorBoundary
                  fallback={<WidgetPlaceholder appearance="rendering-error" size="ordinary" />}
                  onError={this.catchWidgetError}
                >
                  <Widget
                    url={backendUrl}
                    frontendUrl={import.meta.env.VITE_FRONTEND_URL}
                    istioHost={window.istioHost}
                    history={this.props.history}
                    getImageUrl={getImageUrl}
                    dateFormat={dateFormat}
                    mode={this.props.mode}
                    widgetID={widgetData.uuid}
                    loadThirdPartyWidget={this.loadWidget}
                    createWidgetObject={this.createWidgetObject}
                    updateWidgetNameOnDetailed={this.updateWidgetNameOnDetailed}
                    setGetWidgetRibbonCallback={setRibbonCallback}
                    setRibbonState={hasRibbonSupport ? this.setRibbonState : null}
                    widgetData={widgetData}
                    changeWidgetParam={this.changeWidgetParam}
                    widgetSelectedState={widgetSelectedState}
                    openJiraConnectionModal={() => toggleJiraConnectionModal(true)}
                    getUserData={this.getUserData}
                    getTeamMembersList={this.getTeamMembersList}
                    getCardInfo={this.getCardInfo}
                    getBoardCards={this.getBoardCards}
                    tenantId={tenantId}
                    organizationId={organizationId}
                    setEditingWidget={this.props.setEditingWidget}
                    setSelectedWidgets={this.props.setSelectedWidgets}
                    isCardTemplate={isCardTemplate}
                    isSnapshotPreview={isSnapshotPreview}
                    isThumbnailView={isThumbnailView}
                    toggleSmartLinkModal={toggleSmartLinkModal}
                    toggleDataLinking={toggleDataLinking}
                    toggleWidgetFullScreen={this.toggleWidgetFullScreen}
                    resolveSmartLink={this.resolveSmartLink}
                    resolveOnClickScript={this.resolveOnClickScript}
                    showToastMessage={this.showToastMessage}
                    showWidgetActionMessage={this.showWidgetActionMessage}
                    getGroupedFiles={widgets => getGroupedFiles(widgetData.uuid, widgets)}
                    deleteWidget={this.deleteWidget}
                    ungroupFilesFromUploadWidget={this.ungroupFilesFromUploadWidget}
                    getUploadedFiles={this.getUploadedFiles}
                    uploadFile={this.uploadFile}
                    removeFile={this.removeFile}
                    getUploadedFile={this.getUploadedFile}
                    getUploadedImage={this.getUploadedImage}
                    createWidgetsAfterFilesUploading={this.createWidgetsAfterFilesUploading}
                    locked={isEditingDisabledOnDetailed || isPDFGeneration || widgetData.isLocked}
                    getFilterData={getFilterData}
                    keyDownEventListener={keyDownEventListener}
                    isPDFGeneration={isPDFGeneration}
                    handleGoBack={() =>
                      handleGoBack({ boardAppId, tenantId, boardId, navItems, boardIds, boards })
                    }
                    toggleInvitationModal={this.toggleInvitationModal}
                    getUserPermission={this.getUserPermission}
                    updateThirdPartyWidget={this.updateThirdPartyWidget}
                    setActiveWidgetUpdated={setActiveWidgetUpdated}
                    updateWidgetWithTodayFormula={this.updateWidgetWithTodayFormula}
                    fetchQueryWidgetData={this.fetchQueryWidgetOptions}
                    fetchDictionary={this.fetchDictionary}
                    fetchDictionaries={this.fetchDictionaries}
                    generateWidgetPdf={data =>
                      generateWidgetPdf(data, this.props.setPDFWidgetGeneration)
                    }
                    setPDFWidgetGeneration={this.props.setPDFWidgetGeneration}
                    toggleChartsModal={data => this.toggleChartsModal(true, data)}
                    toggleGooglePicker={this.toggleGooglePicker}
                    toggleOneDrivePicker={this.toggleOneDrivePicker}
                    downloadFile={downloadFile}
                    teamRole={teamRole}
                    editingWidget={editingWidget}
                    createFileWidgetMenu={this.createFileWidgetMenu}
                    afterWidgetsWithDataRendered={() => widgetRendered(widgetData.uuid)}
                    onWidgetClick={this.onWidgetClick}
                  />
                </SentryService.ErrorBoundary>
              )}
              {canBeRendered &&
                isDownloadIconShown &&
                this.fileDownloadButton({ fileUrl: downloadUrl })}
            </div>
            {canBeRendered && (isPencilShownOnDetailed || isProvidersListShown) && (
              <WidgetOverlay
                isPencilShown={isPencilShownOnDetailed}
                isProvidersListShown={isProvidersListShown}
                widgetData={widgetData}
                onWidgetClick={this.onWidgetClick}
              />
            )}
          </>
        )
      }
      default:
        return null
    }
  }
}

const mapStateToProps = state => ({
  selectedWidgets: state.builder.selectedWidgets,
  highlightedWidgets: state.builder.highlightedWidgets,
  editingWidget: state.builder.editingWidget,
  user: state.profile.user,
  dateFormat: state.profile.teamSettings.dateFormat,
  currentCard: state.builder.currentCard,
  organizationId: state.profile.activeOrganization.organizationId,
  isBoardCopying: state.socket.isBoardCopying,
  welcomeCardData: state.board.welcomeCardData,
  dataLinkingModalData: state.builder.dataLinkingModalData,
  boardAppId: getCurrentBoard(state).appId,
  boardId: getCurrentBoard(state).boardId,
  navItems: state.app.currentApp?.navItems,
  boardIds: state.app.currentApp?.boardIds,
  boards: state.profile.boardMenu?.boards,
  cards: state.board?.cards,
  teamRole: getTeamRole(state),
  isDetailedViewModalReady: getIsDetailedViewModalReady(state)
})

const mapDispatchToProps = {
  setEditingWidget,
  setSelectedWidgets,
  clearWidgetSelection,
  toggleJiraConnectionModal,
  saveChanges,
  showToastMessage,
  toggleSmartLinkModal,
  toggleDataLinking,
  receiveUploadWidget,
  createWidgetsFromFiles,
  startClickWorkflowExecution,
  toggleWelcomeCardPreview,
  setRibbonCallback,
  setRibbonState,
  toggleChartsModal,
  toggleIssueCounterModal,
  toggleGooglePicker,
  toggleOneDrivePicker,
  setUploadFilesWidgetData,
  setActiveWidgetUpdated,
  restrictAddedWidgetsInCardBuilder,
  restrictAddedWidgetsOnDetailedView,
  toggleWidgetFullscreenModal,
  widgetUpdateOnDetailedViewReceive,
  getFilterData,
  getCardsList,
  getState,
  toggleInvitationModal,
  bulkWidgetsUpdate,
  setPDFWidgetGeneration
}

WidgetItem.propTypes = {
  isRenderLazily: PropTypes.bool,
  hasRibbonSupport: PropTypes.bool,
  // to mark widget placed on thumbnail
  isThumbnailView: PropTypes.bool,
  // Deprecated. Was used to mark widget placed on card template
  // (card in drawer and card in card builder left-hand menu)
  isCardTemplate: PropTypes.bool,
  // to mark widget placed on view for snapshot generation
  isSnapshotPreview: PropTypes.bool,
  // to mark board view widget placed on cards on board
  isOnMainBoard: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(EWidgetModes)).isRequired,
  widgetRendered: PropTypes.func
}

WidgetItem.defaultProps = {
  isRenderLazily: false,
  hasRibbonSupport: false,
  isThumbnailView: false,
  isCardTemplate: false,
  isSnapshotPreview: false,
  isOnMainBoard: false,
  widgetRendered: () => null
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WidgetItem))
