import React, { Component } from 'react'

import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

import BlockInputContainer from '../../../containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from '../../common/AnyLevelDropdown'
import RadioField from '../../common/RadioField'

import '../../../scss/workflowBuilder/toggleFlagBlocks/toggle-flag-block.scss'

class ToggleFlagBlock extends Component {
  shouldComponentUpdate(nextProps) {
    const { block } = nextProps

    return block !== this.props.block
  }

  render() {
    const {
      block: { id, input, error, meta },
      inputType,
      radioFieldKey,
      handleReset,
      handleInputChange,
      toggleRadioFields,
      radioFieldLabels,
      inputFieldTitle,
      inputFieldPlaceholder
    } = this.props

    const isRadioDisabled = !input[EFieldKeys.SOURCE_INPUT]

    return (
      <div className="toggle-flag-block">
        <BlockInputContainer type={inputType} blockId={id} value={input[EFieldKeys.SOURCE_INPUT]}>
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              supportHistory
              title={inputFieldTitle}
              placeholder={inputFieldPlaceholder}
              items={items}
              searchItems={searchItems}
              onSelect={handleInputChange}
              onReset={handleReset}
              value={selectedItem}
              type={inputType}
              error={error[EFieldKeys.SOURCE_INPUT]}
            />
          )}
        </BlockInputContainer>
        <div className="radio-field-wrapper">
          <RadioField
            id={`${id}-toggle-flag-block-0`}
            checked={meta[radioFieldKey]}
            label={radioFieldLabels[0]}
            onChange={toggleRadioFields}
            disabled={isRadioDisabled}
          />
          <RadioField
            id={`${id}-toggle-flag-block-1`}
            checked={!meta[radioFieldKey]}
            label={radioFieldLabels[1]}
            onChange={toggleRadioFields}
            disabled={isRadioDisabled}
          />
        </div>
      </div>
    )
  }
}

export default ToggleFlagBlock
