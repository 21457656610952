export enum ECredentialPasswordType {
  PASSWORD = 'PASSWORD',
  PRIVATE_KEY = 'PRIVATE_KEY'
}

export interface ICredentialData {
  id: string
  name: string
  login: string
  authenticationType: EAuthenticationType
  authenticationPayload: {
    password: string
  }
  createdAt: number
  createdBy: string
  passwordType?: ECredentialPasswordType
}

export interface ICredentialDataPayload {
  name: string
  login: string
  authenticationType: EAuthenticationType
  authenticationPayload: {
    password: string
  }
  passwordType: ECredentialPasswordType
}

export interface IEditCredentialDataPayload
  extends Omit<ICredentialDataPayload, 'authenticationPayload'> {
  credentialId: string
  authenticationPayload:
    | {
        password: string
      }
    | string
}

export interface ICredentialsListPayload {
  pageCursor: string
  sortBy: { field: ECredentialsColumnIds; order: ESortDirections }
}

export interface ICredentialAssociatedWidgetsPayload {
  pageCursor?: string
}

export enum EAuthenticationType {
  BASIC = 'Basic'
}

export type TCredentialsState = {
  credentialList: Array<ICredentialData>
  page: number
  pageCursor: string
  totalCount: number
  sortBy: ECredentialsColumnIds
  sortDirection: ESortDirections
  isCreateModalShown: boolean
  isDeleteModalShown: boolean
  isEditModalShown: boolean
  isLoading: boolean
  createCredential: (payload: ICredentialDataPayload) => Promise<unknown>
  editCredential: (payload: IEditCredentialDataPayload) => Promise<unknown>
  deleteCredential: (id: string) => Promise<unknown>
  getCredentials: (
    page: number,
    sortBy: ECredentialsColumnIds,
    sortDirection: ESortDirections
  ) => void
  getCredentialAssociatedWidgets: (credentialId: string, pageCursor?: string) => Promise<unknown>
  credentialModalData: ICredentialData | null
}

export enum ECredentialsReducerActionTypes {
  setPage = 'setPage',
  changeSort = 'changeSort',
  setIsLoading = 'setIsLoading',
  setIsCreateModalShown = 'setIsCreateModalShown',
  setCredentialsList = 'setCredentialsList',
  setIsEditModalShown = 'setIsEditModalShown',
  setIsDeleteModalShown = 'setIsDeleteModalShown'
}

export interface ICredentialsReducerAction {
  type: ECredentialsReducerActionTypes
  payload: Partial<TCredentialsState>
}

export enum ESortDirections {
  asc = 'asc',
  desc = 'desc'
}

export enum ECredentialsColumnIds {
  name = 'name',
  authenticationType = 'authenticationType',
  createdAt = 'createdAt'
}

export type TCredentialEditModalTab = {
  text: string
}

export type TAssociatedWidgetData = {
  widgetName: string
  teamName: string
  cardName: string
}
export enum TValidationField {
  name = 'name',
  password = 'password',
  passwordType = 'passwordType',
  login = 'login'
}

export type TCredentialSettingsError = {
  [key in TValidationField]: string
}

export interface IRequestError {
  statusCode: number
  errorCode: number
  message: string
}

export type TCredentialModalData = {
  name: string
  password?: string
  login: string
}

export type TAssociatedWidgetsResponse = {
  tenantId: string
  boardId: string
  cardId: string
  cardUuid: string
  widgetId: string
  organizationId: string
  credentialId: string
}

export type TAssociatedWidgetsRequest = {
  organizationId: TAssociatedWidgetsResponse['organizationId']
  tenantIds: Array<TAssociatedWidgetsResponse['tenantId']>
  boardIds: Array<TAssociatedWidgetsResponse['boardId']>
  cardIds: Array<TAssociatedWidgetsResponse['cardUuid']>
  widgetIds: Array<TAssociatedWidgetsResponse['widgetId']>
}

type TAssociatedWidget = {
  id: string
  widgetTitle: string
}
type TAssociatedWidgetsCard = {
  uuid: string
  name: string
  widgets: Array<TAssociatedWidget>
}

type TAssociatedWidgetsBoard = {
  id: string
  name: string
  cards: Array<TAssociatedWidgetsCard>
}
export type TAssociatedWidgetsDataResponse = {
  id: string
  name: string
  boards: Array<TAssociatedWidgetsBoard>
}
