import { useSelector } from 'react-redux'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { BACKGROUND_TRIGGER_EVENT_TYPES, OBJECT_TYPES } from 'constants/workflows'
import messages from 'constants/messages'
import { getWorkflowDefinition } from 'selectors/workflowBuilder.selectors'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import FormField from 'components/common/FormField'
import ReactSelect from 'components/fields/ReactSelect'
import { OpenCardOptions } from './openCardOptions/openCardOptions'
import 'scss/workflowBuilder/finishBlock/finish-block.scss'
import { FINISH_BLOCK_OPTIONS } from './finishBlock.constants'
import { EFinishBlockValues } from './finishBlock.types'
import { WebLinkField } from './webLinkField'

const dropdownConfig = {
  [EFinishBlockValues.DISPLAY_CARD]: {
    inputType: OBJECT_TYPES.CARD,
    title: messages.CARD_LEVEL_INPUT,
    placeholder: messages.CARD_LEVEL_INPUT_PLACEHOLDER
  },
  [EFinishBlockValues.DISPLAY_BOARD]: {
    inputType: OBJECT_TYPES.BOARD,
    title: messages.BOARD_LEVEL_INPUT,
    placeholder: messages.BOARD_LEVEL_INPUT_PLACEHOLDER
  }
}

export const FinishBlock = ({
  block: { id, input, meta, error },
  handleReset,
  handleInputChange,
  changeSelectOption,
  handleChangeBlockMetaValue
}) => {
  const { eventType } = useSelector(getWorkflowDefinition)

  const isBackgroundTrigger = BACKGROUND_TRIGGER_EVENT_TYPES.includes(eventType)

  const optionType = meta[EFieldKeys.TYPE]
  const cardMode = meta[EFieldKeys.CARD_MODE]
  const webLink = meta[EFieldKeys.WEB_LINK]

  const isDisplayObjectEnabled =
    optionType === EFinishBlockValues.DISPLAY_CARD ||
    optionType === EFinishBlockValues.DISPLAY_BOARD

  const { inputType, title, placeholder } = dropdownConfig[meta[EFieldKeys.TYPE]] || {}

  if (isBackgroundTrigger) {
    return (
      <div className="finish-block">
        <span className="background-trigger-message">
          This type of workflows run on the background. No additional actions are needed.
        </span>
      </div>
    )
  }

  const selectedOption =
    FINISH_BLOCK_OPTIONS.find(option => option.value === meta[EFieldKeys.TYPE]) ??
    FINISH_BLOCK_OPTIONS[0]

  return (
    <div className="finish-block">
      <FormField label={messages.DEFINE_FINAL_STEP_LABEL} id="final-step-behaviour">
        <ReactSelect
          className="final-step-behaviour"
          value={selectedOption}
          options={FINISH_BLOCK_OPTIONS}
          menuPortalEnabled
          menuShouldBlockScroll
          onChange={changeSelectOption}
        />
      </FormField>
      {isDisplayObjectEnabled && (
        <BlockInputContainer type={inputType} blockId={id} value={input[EFieldKeys.SOURCE_INPUT]}>
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              title={title}
              placeholder={placeholder}
              items={items}
              searchItems={searchItems}
              value={selectedItem}
              type={inputType}
              error={error[EFieldKeys.SOURCE_INPUT]}
              supportHistory
              onSelect={handleInputChange}
              onReset={handleReset}
            />
          )}
        </BlockInputContainer>
      )}
      {optionType === EFinishBlockValues.DISPLAY_CARD && (
        <OpenCardOptions
          value={cardMode}
          onChange={value => handleChangeBlockMetaValue(EFieldKeys.CARD_MODE, value)}
        />
      )}
      {optionType === EFinishBlockValues.OPEN_LINK && (
        <WebLinkField
          value={webLink}
          error={error[EFieldKeys.WEB_LINK]}
          onChange={value => handleChangeBlockMetaValue(EFieldKeys.WEB_LINK, value)}
        />
      )}
    </div>
  )
}
