import { EWFScheduledWeekdays } from './weekdaysSection.types'

export const WF_SCHEDULED_WEEKDAY_OPTIONS = [
  {
    label: 'S',
    value: EWFScheduledWeekdays.SUNDAY
  },
  {
    label: 'M',
    value: EWFScheduledWeekdays.MONDAY
  },
  {
    label: 'T',
    value: EWFScheduledWeekdays.TUESDAY
  },
  {
    label: 'W',
    value: EWFScheduledWeekdays.WEDNESDAY
  },
  {
    label: 'T',
    value: EWFScheduledWeekdays.THURSDAY
  },
  {
    label: 'F',
    value: EWFScheduledWeekdays.FRIDAY
  },
  {
    label: 'S',
    value: EWFScheduledWeekdays.SATURDAY
  }
]
