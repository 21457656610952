import React from 'react'
import 'scss/workflowBuilder/common/filter-selector-header.scss'

const FilterSelectorHeader = props => {
  const { title, description } = props
  return (
    <header className="filter-selector-header-info">
      <div className="filter-selector-header-title">{title}</div>
      <div className="filter-selector-header-desc">{description}</div>
    </header>
  )
}

export default FilterSelectorHeader
