import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { isOutputStopped } from 'helpers/workflowBuilder/outputsStoppersHelpers'
import { getUndefinedOutputName } from 'helpers/workflowBuilder/workflowBuilderUIHelpers'

class BlockInputContainer extends Component {
  state = {
    selectedItem: null,
    items: [],
    sourceItems: []
  }

  componentDidMount() {
    const { value, outputs, outputsNames, outputsHistory, outputsStoppers, workflowBlocksMap } =
      this.props

    this.updateState({
      value,
      outputs,
      outputsNames,
      outputsHistory,
      outputsStoppers,
      workflowBlocksMap
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value, outputs, outputsNames, outputsHistory, outputsStoppers, workflowBlocksMap } =
      nextProps

    this.updateState({
      value,
      outputs,
      outputsNames,
      outputsHistory,
      outputsStoppers,
      workflowBlocksMap
    })
  }

  updateItems(props) {
    const { value, outputs, outputsNames, outputsHistory, outputsStoppers, workflowBlocksMap } =
      props

    const items = outputs
      .reduce((acc, output) => {
        const currentBlockIndex = workflowBlocksMap[this.props.blockId]?.index
        const outputBlockIndex = workflowBlocksMap[output.blockId]?.index

        if (currentBlockIndex === undefined || !outputBlockIndex === undefined) return acc

        const isStopped = isOutputStopped({
          outputId: output.id,
          blockIndex: currentBlockIndex,
          workflowBlocksMap,
          outputsStoppers
        })

        const isFromPreviousBlock = outputBlockIndex < currentBlockIndex
        const isSelectedOutput = output.id === value

        // don't show selected output and outputs from blocks below current(inclusive)
        // also skip stopped outputs
        if (!isFromPreviousBlock || isSelectedOutput || isStopped) {
          return acc
        }

        acc.push(
          this.optionMapper({
            output,
            outputsNames,
            outputsHistory,
            workflowBlocksMap
          })
        )

        return acc
      }, [])
      .sort((a, b) => {
        const aBlockIndex = workflowBlocksMap[a.blockId]?.index || 0
        const bBlockIndex = workflowBlocksMap[b.blockId]?.index || 0

        return aBlockIndex - bBlockIndex
      })

    this.setState({ items, sourceItems: items })
  }

  updateSelectedItem(props) {
    const { value, outputs, outputsNames, outputsHistory, workflowBlocksMap } = props

    if (!value) {
      this.setState({ selectedItem: null })
      return
    }

    const selectedItem = outputs.find(item => item.id === value)

    this.setState({
      selectedItem: selectedItem
        ? this.optionMapper({
            output: selectedItem,
            outputsNames,
            outputsHistory,
            workflowBlocksMap
          })
        : null
    })
  }

  updateState(props) {
    this.updateItems(props)
    this.updateSelectedItem(props)
  }

  historyMapper({ outputId, workflowBlocksMap, outputsHistory }) {
    const { withHistory, blockId: currentBlockId } = this.props

    if (!withHistory) {
      return null
    }

    const currentBlock = workflowBlocksMap[currentBlockId]
    const history = outputsHistory[outputId] || []

    return history.reduce((acc, blockId) => {
      const block = workflowBlocksMap[blockId] || {}

      // for blocks which don't produce output and just modify input (canUpdateHistory flag)
      // needs to cut history to show only blocks placed above current
      if (block.index >= currentBlock?.index) {
        return acc
      }

      acc.push({
        index: block.index,
        block: block.name,
        customName: block.blockMetadata?.customName
      })

      return acc
    }, [])
  }

  optionMapper(args) {
    const { output, outputsNames, outputsHistory, workflowBlocksMap } = args

    const outputName = outputsNames[output.id] || {}
    return {
      ...output,
      isDefined: !!outputName.name,
      name: outputName.name || getUndefinedOutputName(output.type),
      history: this.historyMapper({
        outputId: output.id,
        workflowBlocksMap,
        outputsHistory
      })
    }
  }

  searchItems = search => {
    const { sourceItems } = this.state

    const items = sourceItems.slice()

    if (!search || !search.trim()) {
      this.setState({ items })
    }

    this.setState({
      items: items.filter(
        item => item.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
      )
    })
  }

  render() {
    const { children } = this.props
    const { items, selectedItem } = this.state
    const { searchItems } = this

    return children({ selectedItem, items, searchItems })
  }
}

const mapStateToProps = (state, props) => {
  const outputs = props.multiTypesSupported
    ? props.supportedTypes.reduce((accum, current) => {
        const currentOutput = state.workflow.outputs[current]
        return [...accum, ...currentOutput]
      }, [])
    : state.workflow.outputs[props.type]

  return {
    workflowBlocksMap: state.workflow.workflowBlocksMap,
    outputs,
    outputsNames: state.workflow.outputsNames,
    outputsStoppers: state.workflow.outputsStoppers,
    outputsHistory: state.workflow.outputsHistory
  }
}

BlockInputContainer.propTypes = {
  blockId: PropTypes.string.isRequired,
  withHistory: PropTypes.bool,

  multiTypesSupported: PropTypes.bool,

  type: PropTypes.string.isRequired, // used in mapStateToProps

  supportedTypes: PropTypes.array, // used in mapStateToProps
  value: PropTypes.string,

  children: PropTypes.func.isRequired,
  outputs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  workflowBlocksMap: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }).isRequired,
  outputsHistory: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  outputsStoppers: PropTypes.shape({
    [PropTypes.string]: PropTypes.string
  }).isRequired,
  outputsNames: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({ name: PropTypes.string })
  }).isRequired
}

BlockInputContainer.defaultProps = {
  withHistory: true,
  value: null,
  multiTypesSupported: false,
  supportedTypes: []
}

export default connect(mapStateToProps)(BlockInputContainer)
