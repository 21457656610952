import React from 'react'

import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'

import GetBlock from './GetBlock'

const GetColumn = props => {
  const { block, tenantId, updateWorkflowBlock, signedInUserId } = props

  return (
    <GetBlock
      block={block}
      tenantId={tenantId}
      signedInUserId={signedInUserId}
      inputFieldLabel={messages.GET_COLUMN}
      inputFieldPlaceholder={messages.GET_COLUMN_PLACEHOLDER}
      inputType={OBJECT_TYPES.COLUMN}
      updateWorkflowBlock={updateWorkflowBlock}
      noItemsFoundPlaceholder={messages.NO_COLUMNS_FOUND}
      noItemsPlaceholder={messages.NO_COLUMNS_IN_TEAM}
    />
  )
}

export default GetColumn
