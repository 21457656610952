import { motion } from 'framer-motion'
import ChevronIcon from 'assets/images/icons/chevron.svg?react'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import { getFieldNamesItems } from 'helpers/workflowBuilder/inputOperations'
import FormField from 'components/common/FormField'
import { AutocompleteSelect } from 'components/common/select/autocompleteSelect/autocompleteSelect'
import { TextField } from 'components/common/textField/textField'
import { type IWorkflowBlock } from 'features/workflow/workflow.types'
import { DropdownInput } from '../common/dropdownInput/dropdownInput'
import { SendPromptToAIBlockInputs } from './inputsSection/sendPromptToAIBlockInputs'
import { getSelectOptions } from './sendPromptToAIBlock.helpers'
import { useSendPromptToAIBlock } from './sendPromptToAIBlock.hooks'
import './send-prompt-to-ai-block.scss'

type TProps = {
  block: IWorkflowBlock
}

export const SendPromptToAIBlock = ({ block }: TProps) => {
  const scenarioError = block.error[EFieldKeys.TEMPLATE_ID] as string | null

  const {
    scenarios,
    currentScenario,
    isLoading,
    isFieldsLoading,
    fieldsLoadingError,
    handeScenarioOpen,
    handeScenarioChange,
    handleFieldSelect,
    handleOutputSelect,
    handleOutputReset,
    handleScenarioRetry,
    handleFileInputAdd,
    handleFileInputDelete,
    handleFileInputChange,
    handleCredentialAdd,
    handleCredentialDelete,
    handleCredentialChange
  } = useSendPromptToAIBlock({ block })

  const stringFieldNames = getFieldNamesItems(EFieldKeys.STRING_INPUT, block.inputDefinition)

  const shouldShowInputsSection =
    isFieldsLoading ||
    currentScenario?.attachments ||
    currentScenario?.credentials ||
    !!stringFieldNames.length

  return (
    <div className="send-prompt-to-ai-block">
      <FormField label="Select scenario" className="ai-scenario-field" error={scenarioError}>
        <AutocompleteSelect
          size={40}
          loading={isLoading}
          value={currentScenario}
          options={getSelectOptions(scenarios)}
          renderInput={params => (
            <TextField
              {...params}
              error={!!scenarioError}
              size={40}
              placeholder="Choose scenario"
            />
          )}
          popupIcon={<ChevronIcon className="arrow-icon" />}
          // @ts-expect-error handeScenarioChange is not properly typed
          onChange={handeScenarioChange}
          onOpen={handeScenarioOpen}
        />
      </FormField>
      {currentScenario && (
        <>
          {shouldShowInputsSection && (
            <motion.div
              key={isFieldsLoading ? 'true' : 'false'}
              className="inputs-section"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <SendPromptToAIBlockInputs
                block={block}
                currentScenario={currentScenario}
                isFieldsLoading={isFieldsLoading}
                fieldsLoadingError={fieldsLoadingError}
                handleFieldSelect={handleFieldSelect}
                handleScenarioRetry={handleScenarioRetry}
                handleFileInputAdd={handleFileInputAdd}
                handleFileInputDelete={handleFileInputDelete}
                handleFileInputChange={handleFileInputChange}
                handleCredentialAdd={handleCredentialAdd}
                handleCredentialChange={handleCredentialChange}
                handleCredentialDelete={handleCredentialDelete}
              />
            </motion.div>
          )}
          {!block.isCreateOutput && (
            <div className="widget-to-update-section">
              <p className="section-label">Set widget to update</p>
              <div className="source-input">
                <DropdownInput
                  block={block}
                  type={OBJECT_TYPES.WIDGET}
                  fieldName={EFieldKeys.SOURCE_INPUT}
                  title="Define widget for output"
                  placeholder={messages.TARGET_WIDGET_PLACEHOLDER}
                  onSelect={handleOutputSelect}
                  onReset={handleOutputReset}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
