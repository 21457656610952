import classNames from 'classnames'

const joinItemPath = path =>
  []
    .concat(
      ...path.map(item => [
        <p key={item.index} className="any-level-dropdown-item-history-block">
          {`${item.index + 1}. ${item.customName || item.block}`}
        </p>,
        <i key={`${item.index}-item`} className="icon workflow-block-icon icon-history-arrow" />
      ])
    )
    .slice(0, -1)

const CommonLevelItem = ({ item, onSelect, type, supportHistory }) => (
  <div className={`any-level-dropdown-item ${type}-item`} onMouseDown={() => onSelect(item)}>
    <p
      className={classNames('any-level-dropdown-item-title bold center', {
        'is-undefined': supportHistory && !item.isDefined
      })}
    >
      {item.name}
    </p>
    {item.history && (
      <div className="any-level-dropdown-item-history">{joinItemPath(item.history)}</div>
    )}
  </div>
)

export default CommonLevelItem
