import React, { Component } from 'react'

import messages from '../../../constants/messages'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from '../../../constants/workflows'

import BlockInputContainer from '../../../containers/workflowBuilder/common/BlockInputContainer'

import '../../../scss/workflowBuilder/hideCardSectionBlock/hide-card-section-block.scss'
import AnyLevelDropdown from '../../common/AnyLevelDropdown'
import Section from './Section'
import SectionsList from './SectionsList'

const MAX_SECTIONS_COUNT = 20

class HideCardSectionBlock extends Component {
  shouldComponentUpdate(nextProps) {
    const { block } = nextProps

    return block !== this.props.block
  }

  render() {
    const {
      block: { id, input, error, meta },
      handleInputChange,
      handleReset,
      addSection,
      deleteSection,
      changeSectionNumber,
      toggleSectionVisibility
    } = this.props

    const sections = meta[EFieldKeys.SECTIONS]

    const isDeleteShown = sections.length > 1
    const isAddSectionShown = sections.length < MAX_SECTIONS_COUNT

    const isSectionsDisabled = !input[EFieldKeys.SOURCE_INPUT]

    return (
      <div className="hide-card-section-block">
        <BlockInputContainer
          type={OBJECT_TYPES.CARD}
          blockId={id}
          value={input[EFieldKeys.SOURCE_INPUT]}
        >
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              supportHistory
              title={messages.CARD_LEVEL_INPUT}
              placeholder={messages.CARD_LEVEL_INPUT_PLACEHOLDER}
              items={items}
              searchItems={searchItems}
              onSelect={handleInputChange}
              onReset={handleReset}
              value={selectedItem}
              type={OBJECT_TYPES.CARD}
              error={error[EFieldKeys.SOURCE_INPUT]}
            />
          )}
        </BlockInputContainer>
        <SectionsList
          isAddSectionShown={isAddSectionShown}
          isSectionsDisabled={isSectionsDisabled}
          addSection={addSection}
        >
          {sections.map(section => (
            <Section
              blockId={id}
              isDeleteShown={isDeleteShown}
              isSectionsDisabled={isSectionsDisabled}
              key={section.uuid}
              section={section}
              deleteSection={deleteSection}
              changeSectionNumber={changeSectionNumber}
              toggleSectionVisibility={toggleSectionVisibility}
            />
          ))}
        </SectionsList>
      </div>
    )
  }
}

export default HideCardSectionBlock
