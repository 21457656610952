import React from 'react'

import PublishFeedNotificationContainer from '../../../containers/workflowBuilder/systemActions/PublishFeedNotificationContainer'

const PublishFeedNotification = props => {
  const { block, changeWorkflowBlockInput, updateWorkflowBlock, tenantId } = props

  return (
    <PublishFeedNotificationContainer
      block={block}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
      tenantId={tenantId}
    />
  )
}

export default PublishFeedNotification
