import { type Channel } from 'nice-grpc-web'
import {
  type ListTenantMenuItem,
  type UserAccessPublicClient,
  App,
  AppSearchResult,
  Board,
  BoardSearchResult,
  ListTenantAppBoardsRequest,
  ListTenantAppsRequest,
  ListTenantBoardsRequest,
  ListTenantMenusRequest,
  SearchAppsRequest,
  SearchBoardsRequest,
  UserAccessPublicDefinition
} from '_proto/useraccess/v1/public.pb'
import { type TTeamRole } from 'features/team/team.types'
import { GrpcService } from '../grpc.service'

export interface IUserAccessTenantItem extends ListTenantMenuItem {
  // Enforce specific role types instead of using a generic string
  tenantRole: TTeamRole
}

export interface IUserAccessAppItem extends App {}
export interface IUserAccessBoardItem extends Board {}

export interface IUserAccessBoardSearchItem extends BoardSearchResult {}
export interface IUserAccessAppSearchItem extends AppSearchResult {}

class _UserAccessService {
  constructor() {
    this._channel = GrpcService.createChannel(import.meta.env.VITE_USER_ACCESS_URL)
    this._client = GrpcService.createClient(UserAccessPublicDefinition, this._channel)
  }

  private readonly _QUERY_NAMESPACE = 'userAccess'
  private readonly _client: UserAccessPublicClient
  private readonly _channel: Channel

  getTenantListQueryKey = (organizationId: string) => [
    `${this._QUERY_NAMESPACE}:tenantList`,
    organizationId
  ]

  getTenantAppListQueryKey = (tenantId: string) => [
    `${this._QUERY_NAMESPACE}:tenantAppList`,
    tenantId
  ]

  getTenantBoardListQueryKey = (tenantId: string) => [
    `${this._QUERY_NAMESPACE}:tenantBoardList`,
    tenantId
  ]

  getTenantAppBoardListQueryKey = ({ tenantId, appId }: { tenantId: string; appId: string }) => [
    `${this._QUERY_NAMESPACE}:tenantAppBoardList`,
    tenantId,
    appId
  ]

  getSearchBoardsQueryKey = (query: string) => [`${this._QUERY_NAMESPACE}:searchBoards`, query]

  getSearchAppsQueryKey = (query: string) => [`${this._QUERY_NAMESPACE}:searchApps`, query]

  getTenantList = async () => {
    const request = ListTenantMenusRequest.create()

    const response = await this._client.listTenantMenus(request)

    return response.tenants as IUserAccessTenantItem[]
  }

  getTenantAppList = async ({
    tenantId,
    paginationToken
  }: {
    tenantId: string
    paginationToken?: string
  }) => {
    const request = ListTenantAppsRequest.create({ tenantId, paginationToken })

    return this._client.listTenantApps(request)
  }

  getTenantBoardList = async ({
    tenantId,
    paginationToken
  }: {
    tenantId: string
    paginationToken?: string
  }) => {
    const request = ListTenantBoardsRequest.create({ tenantId, paginationToken })

    return this._client.listTenantBoards(request)
  }

  getTenantAppBoardList = async ({
    tenantId,
    appId,
    paginationToken
  }: {
    tenantId: string
    appId: string
    paginationToken?: string
  }) => {
    const request = ListTenantAppBoardsRequest.create({
      tenantId,
      appId,
      paginationToken
    })

    return this._client.listTenantAppBoards(request)
  }

  searchApps = async ({ query, pageSize, paginationToken }: SearchAppsRequest) => {
    const request = SearchAppsRequest.create({ query, pageSize, paginationToken })

    return this._client.searchApps(request)
  }

  searchBoards = async ({ query, pageSize, paginationToken }: SearchBoardsRequest) => {
    const request = SearchBoardsRequest.create({ query, pageSize, paginationToken })

    return this._client.searchBoards(request)
  }
}

export const UserAccessService = new _UserAccessService()
