import { type ChangeEvent, type FocusEvent, useCallback, useState } from 'react'
import classNames from 'classnames'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { Tooltip } from 'components/common/tooltip/tooltip'
import PlainInput from 'components/fields/PlainInput'

type TProps = {
  formula: string
  error?: string | null
  updateBlockMeta: (data: Record<string, string>) => void
}

export const ConvertWidgetDataBlockFormulaInput = ({ formula, error, updateBlockMeta }: TProps) => {
  const [value, setValue] = useState(formula)

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value: _value } = event.target
    setValue(_value)
  }, [])

  const onBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const { value: _value } = event.target
      updateBlockMeta({ [EFieldKeys.FORMULA]: _value.trim() })
    },
    [updateBlockMeta]
  )

  return (
    <div
      className={classNames('formula-field form-field', {
        'has-error': error
      })}
    >
      <div className="form-field-label-container">
        <label className="form-field-label" htmlFor="convert-widget-data-formula">
          {messages.ENTER_FORMULA}
        </label>
        <Tooltip id="enter-formula-tooltip" placement="top" title={messages.ENTER_FORMULA_HINT}>
          <i className="up-font-ic-info-16 icon" />
        </Tooltip>
      </div>
      <PlainInput
        className="input-field"
        placeholder={messages.ENTER_FORMULA_PLACEHOLDER}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <span className="field-error">{error}</span>
    </div>
  )
}
