import { createPath } from 'history'
import { generatePath } from 'react-router-dom'
import { DeployRequest } from '_proto/entitlement/v1/entitlement.pb'
import {
  processSignInRequest,
  processSignUpRequest,
  processResetLinkRequest,
  processChangePasswordRequest,
  processUserSignOut
} from 'api/authAPI'
import {
  getAllOrganizations as getAllOrganizationsAction,
  processSwitchOrganization
} from 'api/organizationAPI'
import {
  SET_SIGNED_IN_EMAIL,
  SET_KEEP_LOGGED_IN,
  REQUEST_ALL_ORGANIZATIONS,
  RECEIVE_ALL_ORGANIZATIONS,
  RECEIVE_ALL_ORGANIZATIONS_ERROR,
  REQUEST_LOGIN,
  RECEIVE_LOGIN,
  RECEIVE_LOGIN_ERROR,
  SET_SIGNED_UP_EMAIL,
  REQUEST_REGISTRATION,
  RECEIVE_REGISTRATION,
  RECEIVE_REGISTRATION_ERROR,
  REQUEST_RESET_LINK,
  RECEIVE_RESET_LINK,
  RECEIVE_RESET_LINK_ERROR,
  REQUEST_CHANGE_PASSWORD,
  RECEIVE_CHANGE_PASSWORD,
  RECEIVE_CHANGE_PASSWORD_ERROR,
  TOGGLE_PASSWORD_CHANGED_MODAL
} from 'constants/actionTypes'
import messages from 'constants/messages'
import { PATHS } from 'constants/paths.constants'
import { TOGGLE_SESSION_EXPIRATION_NOTIFICATION } from 'containers/common/appContainer/hooks/useSessionExpirationNotification'
import { QueryClientService } from 'services/common/queryClient.service'
import { history } from 'services/common/history.service'
import { IS_MOBILE_DEVICE } from 'helpers/userAgent.helpers'
import { signInNavigation, buildSearchParamsString } from 'helpers/routesHelpers'
import { getSortedOrganizations } from 'helpers/organizationsHelpers'
import { EntitlementsGrpcService } from 'features/entitlements/entitlements.grpc.service'
import { useTeamStore } from 'features/team/team.store'
import {
  clearBoardCards,
  navigateAfterSignUp,
  showToastMessage,
  toggleDraftMessage
} from './boardActions'
import {
  setCurrentCard,
  setEditingWidget,
  setSelectedWidgets,
  toggleAutoSave,
  toggleUnsavedChangesFlag
} from './builderActions'
import {
  boardMenuReceive,
  clearDraft,
  clearOrganizations,
  clearTeamSettings,
  fetchWorkspaceSubscriptions,
  toggleIsOnBoardSettings
} from './profileActions'
import { clearSocketConnection } from './socketActions'
import { toggleAuthLoader } from './spinnerActions'
import { widgetClassesListReceive } from './widgetsActions'

export function setSignedInEmail(payload) {
  return { type: SET_SIGNED_IN_EMAIL, payload }
}

export function setKeepLoggedIn(payload) {
  return { type: SET_KEEP_LOGGED_IN, payload }
}

export function allOrganizationsRequestStart() {
  return { type: REQUEST_ALL_ORGANIZATIONS }
}

export function allOrganizationsReceived(payload) {
  return { type: RECEIVE_ALL_ORGANIZATIONS, payload }
}

export function receiveAllOrganizationsErrorMessage(payload) {
  return { type: RECEIVE_ALL_ORGANIZATIONS_ERROR, payload }
}

export function getAllOrganizations() {
  return dispatch => {
    dispatch(allOrganizationsRequestStart())
    return getAllOrganizationsAction()
      .then(response => {
        dispatch(allOrganizationsReceived(getSortedOrganizations(response.data)))
        return response.data
      })
      .catch(err => {
        dispatch(receiveAllOrganizationsErrorMessage(err))
      })
  }
}

// Sign in
export function signInRequestStart() {
  return { type: REQUEST_LOGIN }
}

export function signInReceive(payload) {
  return { type: RECEIVE_LOGIN, payload }
}

export function receiveSignInErrorMessage(payload) {
  return { type: RECEIVE_LOGIN_ERROR, payload }
}

export const deployEntitlement = (entitlementId, organizationId) => async dispatch => {
  const request = DeployRequest.create({
    keys: [{ id: entitlementId, scope: { organizationId } }]
  })

  await EntitlementsGrpcService.deployEntitlements({ request })

  dispatch(fetchWorkspaceSubscriptions())
}

export function signInRequest(payload) {
  return dispatch => {
    dispatch(signInRequestStart())
    dispatch(toggleAuthLoader(true))

    return processSignInRequest(payload)
      .then(response => {
        dispatch(signInReceive(response.data))
        dispatch(setSignedInEmail(payload.username))
        dispatch(setKeepLoggedIn(payload.keepLogged))

        const event = new CustomEvent(TOGGLE_SESSION_EXPIRATION_NOTIFICATION, {
          detail: { type: 'hide' }
        })

        document.dispatchEvent(event)

        if (!payload.verifyToken) {
          dispatch(getAllOrganizations())
            .then(organizations => {
              // if only one organization found, go to upBOARD
              const goToUpBoard = organizations.length === 1

              signInNavigation(goToUpBoard)

              const showUserSettings =
                goToUpBoard &&
                response.data.loginCounter === 1 &&
                !response.data.showEntitlementCoachMarks

              if (showUserSettings && !IS_MOBILE_DEVICE) {
                dispatch(toggleIsOnBoardSettings(true))
              }
            })
            .finally(() => dispatch(toggleAuthLoader(false)))
        } else {
          dispatch(toggleAuthLoader(false))
        }
      })
      .catch(err => {
        dispatch(toggleAuthLoader(false))
        if (err.errorCode === 417) {
          dispatch(
            receiveSignInErrorMessage({
              message: messages.NEED_EMAIL_CONFIRMATION
            })
          )
        } else if (err.errorCode >= 500) {
          dispatch(
            receiveSignInErrorMessage({
              message: messages.SOMETHING_WENT_WRONG_TRY_AGAIN
            })
          )
        } else {
          dispatch(receiveSignInErrorMessage(err))
        }
      })
  }
}

export function setSignedUpEmail(payload) {
  return { type: SET_SIGNED_UP_EMAIL, payload }
}

// Sign up
export function signUpRequestStart() {
  return { type: REQUEST_REGISTRATION }
}

export function signUpReceived() {
  return { type: RECEIVE_REGISTRATION }
}

export function receiveSignUpErrorMessage(payload) {
  return { type: RECEIVE_REGISTRATION_ERROR, payload }
}

export function signUpRequest(payload) {
  return dispatch => {
    dispatch(signUpRequestStart())
    return processSignUpRequest(payload)
      .then(({ data }) => {
        dispatch(signUpReceived())

        if (payload.shouldSkipEmailVerification) {
          const pathname = generatePath(PATHS.verify.routerPath, { token: data.token })
          const search = buildSearchParamsString({ entitlementId: payload.entitlementId })
          const path = createPath({ pathname, search })

          history.push(path)
        }

        // in case of invitation flow doesn't need to show success modal
        if (!payload.verifyToken && !payload.shouldSkipEmailVerification) {
          dispatch(setSignedUpEmail(payload.username))
          history.push(PATHS.signUpSuccess.url)
        }

        if (payload.verifyToken) {
          dispatch(navigateAfterSignUp())
        }
      })
      .catch(err => {
        if (err.errorCode === 409) {
          dispatch(receiveSignUpErrorMessage({ message: messages.USER_ALREADY_EXIST }))
        } else if (err.errorCode === 403) {
          if (err.message.includes('Entitlement')) {
            dispatch(receiveSignUpErrorMessage({ text: messages.INVALID_ENTITLEMENT_LINK }))
            dispatch(showToastMessage({ text: messages.INVALID_ENTITLEMENT_LINK }))
          } else {
            dispatch(
              receiveSignUpErrorMessage({
                message: messages.INVALID_REGISTRATION_KEY
              })
            )
          }
        } else if (err.errorCode === 410) {
          history.push(PATHS.invalidInvitation.url)
        } else if (err.errorCode >= 500) {
          dispatch(
            receiveSignUpErrorMessage({
              message: messages.SOMETHING_WENT_WRONG_TRY_AGAIN
            })
          )
        } else {
          dispatch(receiveSignUpErrorMessage(err))
        }
      })
  }
}

export function resetLinkStart() {
  return { type: REQUEST_RESET_LINK }
}

export function resetLinkReceived() {
  return { type: RECEIVE_RESET_LINK }
}

export function receiveResetLinkErrorMessage() {
  return { type: RECEIVE_RESET_LINK_ERROR }
}

export function resetLinkRequest(payload) {
  return dispatch => {
    dispatch(resetLinkStart())
    return processResetLinkRequest(payload)
      .then(() => {
        dispatch(resetLinkReceived())
      })
      .catch(() => dispatch(receiveResetLinkErrorMessage()))
  }
}

export function changePasswordStart() {
  return { type: REQUEST_CHANGE_PASSWORD }
}

export function changePasswordReceived() {
  return { type: RECEIVE_CHANGE_PASSWORD }
}

export function receiveChangePasswordErrorMessage() {
  return { type: RECEIVE_CHANGE_PASSWORD_ERROR }
}

export function togglePasswordChangedModal(payload) {
  return { type: TOGGLE_PASSWORD_CHANGED_MODAL, payload }
}

export function changePasswordRequest(payload) {
  return dispatch => {
    dispatch(changePasswordStart())
    return processChangePasswordRequest(payload)
      .then(() => {
        dispatch(changePasswordReceived())
        dispatch(togglePasswordChangedModal(true))
      })
      .catch(() => dispatch(receiveChangePasswordErrorMessage()))
  }
}

// clear some important data in the store after user was auto sign up
export function clearStoreAfterAutoSignOut() {
  return (dispatch, getState) => {
    const store = getState()
    const { autoSaveID, selectedWidgets, editingWidget, isUnsavedChanges, currentCard } =
      store.builder
    const { list } = store.widget
    const { boardMenu } = store.profile
    if (autoSaveID) {
      dispatch(toggleAutoSave({ isEnable: false, autoSaveID }))
    }
    if (currentCard.uuid) {
      dispatch(setCurrentCard({ board: {} }))
    }
    if (selectedWidgets.length) {
      dispatch(setSelectedWidgets([]))
    }
    if (editingWidget) {
      dispatch(setEditingWidget(''))
    }
    if (isUnsavedChanges) {
      dispatch(toggleUnsavedChangesFlag(false))
    }
    dispatch(clearBoardCards())
    if (list.length) {
      dispatch(widgetClassesListReceive([]))
    }
    localStorage.removeItem('upboard-card-copy-buffer')
    if (boardMenu.tenants.length || boardMenu.boards.length) {
      dispatch(clearOrganizations())
      dispatch(
        boardMenuReceive({
          tenants: [],
          boards: [],
          apps: []
        })
      )
    }
  }
}

export function userSignOut() {
  return dispatch => {
    dispatch(setCurrentCard({ board: {} }))
    dispatch(setSelectedWidgets([]))
    dispatch(setEditingWidget(''))
    dispatch(clearOrganizations())
    dispatch(clearTeamSettings())
    useTeamStore.getState().reset()
    dispatch(
      boardMenuReceive({
        tenants: [],
        boards: [],
        apps: []
      })
    )
    dispatch(toggleDraftMessage(false))
    dispatch(clearDraft())

    QueryClientService.queryClient.clear()

    return processUserSignOut().then(() => history.push(PATHS.auth.url))
  }
}

// when user wants to sign in to another org
export function goToSignIn() {
  return dispatch => {
    dispatch(clearSocketConnection(true))
    dispatch(toggleDraftMessage(false))
    dispatch(clearDraft())

    history.push(PATHS.auth.url)
  }
}

export function switchOrganization(payload) {
  return dispatch => {
    dispatch(clearSocketConnection(true))
    return processSwitchOrganization(payload)
      .then(() => {
        if (payload.reload) {
          window.location.reload()
        } else {
          window.location.replace(PATHS.homepage.url)
        }
      })
      .catch(err => console.error(err))
  }
}
