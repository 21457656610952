import React from 'react'
import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'
import SwitchStageWidgetContainer from '../../../containers/workflowBuilder/switchStageWidgetBlock/SwitchStageWidgetContainer'

const SwitchWidget = props => {
  const { block, updateWorkflowBlock, changeWorkflowBlockInput } = props
  return (
    <SwitchStageWidgetContainer
      inputFieldLabel={messages.WIDGET_LEVEL_INPUT}
      inputFieldPlaceholder={messages.WIDGET_LEVEL_INPUT_PLACEHOLDER}
      inputType={OBJECT_TYPES.WIDGET}
      outputFieldPlaceholder={messages.SELECT_STAGE_SWITCHING_WAY}
      block={block}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
    />
  )
}

export default SwitchWidget
