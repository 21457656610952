import RefreshIcon from 'assets/images/icons/ic_refresh.svg?react'
import TextButton from 'components/buttons/TextButton'
import DotsSpinner from 'components/spinners/DotsSpinner'
import {
  type IWorkflowBlock,
  type IWorkflowDropdownValue,
  type IWorkflowScenarioTemplate
} from 'features/workflow/workflow.types'
import { SendPromptToAIBlockCredentials } from './credentials/sendPromptToAIBlockCredentials'
import { SendPromptToAIBlockFileInputs } from './fileInputs/sendPromptToAIBlockFileInputs'
import { SendPromptToAIBlockStringInputs } from './stringInputs/sendPromptToAIBlockStringInputs'
import './send-prompt-to-ai-block-inputs.scss'

type TProps = {
  block: IWorkflowBlock
  currentScenario: IWorkflowScenarioTemplate | null
  isFieldsLoading: boolean
  fieldsLoadingError: string | null
  handleFieldSelect: (fieldName: string, item?: Partial<IWorkflowDropdownValue> | null) => void
  handleScenarioRetry: () => void
  handleFileInputAdd: () => void
  handleFileInputDelete: (index: number) => void
  handleFileInputChange: (index: number, item: Partial<IWorkflowDropdownValue>) => void
  handleCredentialAdd: () => void
  handleCredentialDelete: (index: number) => void
  handleCredentialChange: (index: number, item: string) => void
}

export const SendPromptToAIBlockInputs = ({
  block,
  currentScenario,
  isFieldsLoading,
  fieldsLoadingError,
  handleFieldSelect,
  handleScenarioRetry,
  handleFileInputAdd,
  handleFileInputDelete,
  handleFileInputChange,
  handleCredentialAdd,
  handleCredentialChange,
  handleCredentialDelete
}: TProps) => {
  if (isFieldsLoading) {
    return <DotsSpinner customClass="spinner" color="dark" size="sm" position="center" />
  }

  if (fieldsLoadingError) {
    return (
      <div className="scenario-loading-error">
        <h3 className="title">Something went wrong</h3>
        Please try to reload scenario
        <br />
        or choose another one
        <TextButton className="refresh-button" onClick={handleScenarioRetry}>
          <RefreshIcon />
          Reload scenario
        </TextButton>
      </div>
    )
  }

  return (
    <div className="workflow-send-prompt-to-ai-inputs">
      <p className="label">Input</p>
      <SendPromptToAIBlockStringInputs
        block={block}
        handleFieldSelect={handleFieldSelect}
        currentScenario={currentScenario}
      />
      {!!currentScenario?.attachments && (
        <SendPromptToAIBlockFileInputs
          block={block}
          handleFieldSelect={handleFieldSelect}
          handleFileInputAdd={handleFileInputAdd}
          handleFileInputDelete={handleFileInputDelete}
          handleFileInputChange={handleFileInputChange}
        />
      )}
      {!!currentScenario?.credentials && (
        <SendPromptToAIBlockCredentials
          block={block}
          handleCredentialAdd={handleCredentialAdd}
          handleCredentialChange={handleCredentialChange}
          handleCredentialDelete={handleCredentialDelete}
        />
      )}
    </div>
  )
}
