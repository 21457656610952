import { EWFScheduledMonths } from './monthsSection.types'

export const WF_SCHEDULED_MONTHS_OPTIONS = {
  [EWFScheduledMonths.JAN]: 'Jan',
  [EWFScheduledMonths.FEB]: 'Feb',
  [EWFScheduledMonths.MAR]: 'Mar',
  [EWFScheduledMonths.APR]: 'Apr',
  [EWFScheduledMonths.MAY]: 'May',
  [EWFScheduledMonths.JUN]: 'Jun',
  [EWFScheduledMonths.JUL]: 'Jul',
  [EWFScheduledMonths.AUG]: 'Aug',
  [EWFScheduledMonths.SEP]: 'Sep',
  [EWFScheduledMonths.OCT]: 'Oct',
  [EWFScheduledMonths.NOV]: 'Nov',
  [EWFScheduledMonths.DEC]: 'Dec'
}

export const WF_SCHEDULED_DEFAULT_MONTHS = Object.keys(WF_SCHEDULED_MONTHS_OPTIONS).map(Number)

export const WF_SCHEDULED_MONTHS_OPTION_LIST = Object.entries(WF_SCHEDULED_MONTHS_OPTIONS)
