import { useEffect } from 'react'
import { OPEN_CARD_MODES } from 'helpers/workflowExecution/workflowMetaHelpers'
import RadioField from 'components/common/RadioField'
import { OPEN_CARD_OPTIONS } from './openCardOptions.constants'
import './open-card-options.scss'

type TProps = {
  value: string
  onChange: (value: string) => void
}

export const OpenCardOptions = ({ value, onChange }: TProps) => {
  useEffect(() => {
    if (!value) {
      onChange(OPEN_CARD_MODES.sameView)
    }
  }, [value, onChange])

  return (
    <div className="wf-finish-block-open-card-options">
      {OPEN_CARD_OPTIONS.map(option => (
        <RadioField
          key={option.value}
          id={`final-step-open-card-${option.value}`}
          label={option.label}
          checked={value === option.value}
          onChange={() => onChange(option.value)}
        />
      ))}
    </div>
  )
}
