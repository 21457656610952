import { Component } from 'react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import CreateColumn from 'components/workflowBuilder/createBlocks/CreateColumn'

export default class CreateColumnContainer extends Component {
  state = {
    customName: this.props.block.meta[EFieldKeys.NAME]
  }

  changeCustomName = e => {
    this.setState({ customName: e.target.value })
  }

  blurCustomName = e => {
    const { value } = e.target
    this.updateBlockMeta(EFieldKeys.NAME, value.trim())
  }

  changeSelectOption = (propName, { value }) => {
    this.updateBlockMeta(propName, value)
  }

  updateBlockMeta = (key, fieldValue) => {
    const {
      updateWorkflowBlock,
      block: { id, meta }
    } = this.props

    const isFieldValueShouldUpdate = !fieldValue || meta[key] !== fieldValue
    if (!meta || isFieldValueShouldUpdate) {
      updateWorkflowBlock({
        id,
        data: {
          meta: { ...meta, [key]: fieldValue },
          error: {}
        }
      })
    }
  }

  handleInputChange = (fieldName, item) => {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })

    if (fieldName === EFieldKeys.SOURCE_INPUT) return

    // clear plain string name when input changing
    this.updateBlockMeta(EFieldKeys.NAME, '')
  }

  handleReset = fieldName => {
    this.handleInputChange(fieldName, { id: null })
  }

  render() {
    const { customName } = this.state
    const { block } = this.props
    const meta = block.meta || {}

    return (
      <CreateColumn
        type={meta[EFieldKeys.TYPE]}
        block={block}
        customName={customName}
        handleReset={this.handleReset}
        handleInputChange={this.handleInputChange}
        changeSelectOption={this.changeSelectOption}
        changeCustomName={this.changeCustomName}
        blurCustomName={this.blurCustomName}
      />
    )
  }
}
