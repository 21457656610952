export function getIdsObjectFromKey(key) {
  const object = {
    tenant: key.namespace
  }

  key.path.forEach((item, index, array) => {
    if (index % 2 === 0) {
      object[array[index].toLowerCase()] = array[index + 1]
    }
  })
  return object
}

export function generateGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0 // eslint-disable-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8 // eslint-disable-line no-mixed-operators, no-bitwise
    return v.toString(16)
  })
}

export function objectEquals(x, y) {
  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y
  }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) {
    return false
  }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) {
    return x === y
  }
  // if they are regexps, they should exactly refer to same one
  // (it is hard to better equality check on current ES)
  if (x instanceof RegExp) {
    return x === y
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true
  }
  if (Array.isArray(x) && x.length !== y.length) {
    return false
  }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) {
    return false
  }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) {
    return false
  }
  if (!(y instanceof Object)) {
    return false
  }

  // recursive object equality check
  const p = Object.keys(x)
  return Object.keys(y).every(i => p.indexOf(i) !== -1) && p.every(i => objectEquals(x[i], y[i]))
}

export function findAncestor(element, selector) {
  return element.closest(selector)
}

export function removeDuplicates(array, _fnCompare) {
  const result = []
  const fnCompare = _fnCompare || ((a, b) => a === b)
  array.forEach(a => {
    if (!result.filter(b => fnCompare(a, b)).length) {
      result.push(a)
    }
  })
  return result
}

export function getCapitalLetter(username) {
  let character = ''
  if (username) {
    character = username.slice(0, 1)
  }
  return character.toUpperCase()
}

export const getNumberedArray = number => [...new Array(number).keys()]

export const makeObjectFromArray = (array, getItemKey, valueProperty) =>
  array.reduce((acc, item) => {
    acc[getItemKey(item)] = valueProperty && item ? item[valueProperty] : item

    return acc
  }, {})

export const groupArrayByKey = (array, getItemKey) =>
  array.reduce((acc, item) => {
    const key = getItemKey(item)

    if (!acc[key]) {
      acc[key] = []
    }

    acc[key].push(item)

    return acc
  }, {})

export const isEmptyObject = object => {
  if (!object) {
    return true
  }

  return !Object.keys(object).length
}

export const chunkArray = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (_value, index) =>
    arr.slice(index * size, index * size + size)
  )

export const sortArrayByFieldAlphabetically = (a, b, field = 'name') => {
  const itemA = a[field] && a[field].toLowerCase()
  const itemB = b[field] && b[field].toLowerCase()
  if (itemA < itemB) {
    return -1
  }

  if (itemA > itemB) {
    return 1
  }

  return 0
}

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

/**
 * Defines the right word form suitable for number provided
 * @param {Number} number
 * @param {[String]} declensions
 * @returns {String}
 * @example
 * const result = declension(2, ['day', 'days'])
 * console.log(result) // 'days'
 */
export const declensionOf = (number, declensions) => {
  const absoluteN = Math.abs(number) % 100
  const n1 = absoluteN % 10

  if (number > 10 && number < 20) {
    return declensions[2] ? declensions[2] : declensions[1]
  }
  if (n1 > 1 && n1 < 5) {
    return declensions[1]
  }
  if (n1 === 1) {
    return declensions[0]
  }

  return declensions[2] ? declensions[2] : declensions[1]
}

export const compareBy = prop => (a, b) => {
  if (typeof a[prop] === 'string' || typeof b[prop] === 'string') {
    // Keep original order if not have prop
    return a[prop]?.localeCompare(b[prop]) || 0
  }

  return a[prop] - b[prop]
}

/**
 * Executes a function on the next tick.
 * @param {Function} fn
 * @returns void
 */
export const executeOnNextTick = fn => {
  setTimeout(fn, 0)
}
