import { batch } from 'react-redux'
import {
  resolveCard,
  updateCardDraft,
  cancelCardEditing,
  saveActiveCard,
  startCardEditing
} from 'api/cardAPI'
import { getRidOfData } from 'api/widgetAPI'
import { getCardsList } from 'api/bindCardAPI'
import {
  SELECT_WIDGET_PANEL,
  TOGGLE_EDIT_LOCKED_MODAL,
  TOGGLE_UNSAVED_CHANGES_FLAG,
  TOGGLE_SAVE_TO_BOARD_FLAG,
  SET_CURRENT_CARD,
  TOGGLE_UNSAVED_CHANGES_MODAL,
  TOGGLE_EMPTY_NAME_VALIDATION,
  REQUEST_CARD,
  RECEIVE_CARD,
  RECEIVE_CARD_ERROR,
  SET_SELECTED_WIDGETS,
  SET_EDITING_WIDGET,
  SET_ACTIVE_SECTION,
  SET_ACTIVE_WIDGET_UPDATED,
  UPDATE_RESOLVED_DRAFT_WIDGETS,
  RECEIVE_CARD_UPDATE,
  RECEIVE_WIDGET_CREATE,
  RECEIVE_WIDGET_DELETE,
  TOGGLE_CB_SERVER_ERROR,
  RECEIVE_WIDGET_UPDATE,
  AUTO_SAVE_START,
  AUTO_SAVE_END,
  SET_AUTOSAVE_INTERVAL_ID,
  SET_LEFT_MENU_CARDS,
  REPLACE_CARD_IN_LEFT_MENU,
  SET_ACTIVE_REQUEST,
  SET_ACTIVE_RECEIVE,
  SET_ACTIVE_RECEIVE_ERROR,
  START_EDIT_REQUEST,
  START_EDIT_RECEIVE,
  START_EDIT_RECEIVE_ERROR,
  CARD_UPDATE,
  WIDGET_CREATE,
  WIDGET_DELETE,
  WIDGET_UPDATE,
  CARD_CREATE,
  TOGGLE_POPUP_NOTIFICATION,
  TOGGLE_BUILDER_CREATE_MODE,
  TOGGLE_BOARD_VIEW_MODE,
  SET_WIDGETS_TO_BUFFER,
  SET_UNSAVED_CHANGES_CALLBACK,
  TOGGLE_CHARTS_MODAL,
  TOGGLE_ISSUE_COUNTER_MODAL,
  TOGGLE_DATA_LINKING,
  TOGGLE_SMART_LINK_MODAL,
  BULK_WIDGETS_UPDATE,
  SET_HIGHLIGHTED_WIDGETS,
  SET_HIGHLIGHTED_SECTION
} from 'constants/actionTypes'
import messages from 'constants/messages'
import { getPreviousChange } from 'helpers/board/boardHelpers'
import {
  DEFAULT_CARD_HEIGHT,
  getBoardViewArray,
  getChangesStack,
  getLastChangesOfCard,
  getRedoStack,
  getSectionsCountToAdd,
  getSelectedWidgets,
  getUndoStack,
  isWidgetIntersectsWithHiddenSections,
  markHistorySaved,
  markHistorySaving,
  revertHistorySaving,
  setDataToLocalStorage,
  updateWidgetsDimensions,
  addRequestToStack,
  callRequestFromStack
} from 'helpers/builderHelpers'
import { generateGUID } from 'helpers/common/commonHelpers'
import { isLinkedConsumer } from 'helpers/dataAnalyzer'
import {
  findErrorConstraints,
  isTextErrorMessage,
  isServerUnavailable
} from 'helpers/errorsHandlingHelpers'
import {
  navigateToBoard,
  navigateToAppView,
  navigateToTeamAdministration,
  navigateToOrganizationAdministration,
  navigateToCardBuilder
} from 'helpers/routesHelpers'
import {
  flagsList,
  getWidgetsDataByIds,
  hasCategory,
  hasFlag
} from 'helpers/widget/widgetDataHelpers'
import { updateSnapshotsOnCards } from 'helpers/board/boardOperations'
import {
  getIsDataLinkingResetNeed,
  updateCopiedWidgetDataLinking
} from 'helpers/dataLinkingHelpers'
import { resolveUnresolvedProviders } from 'helpers/linkingResolution/linkingResolution'
import {
  currentCardWidgetsSelector,
  currentCardSelector,
  bulkWidgetsUpdatePayloadSelector,
  selectedWidgetsSelector,
  currentCardHeightSelector
} from 'selectors/builderSelectors'
import { WIDGETS_IN_SYSTEM } from 'features/widgets/widgets.constants'
import { SimpleIDB } from '../store/indexDBSetup'
import { showToastMessage, toggleDraftMessage, restrictAddedWidgets } from './boardActions'
import { clearDraft } from './profileActions'
import { setRibbonState, setRibbonCallback } from './ribbonActions'
import { toggleUnsavedChangesSpinner } from './spinnerActions'
import { copyFiles } from './widgetsActions'

export function selectWidgetPanel(payload) {
  return { type: SELECT_WIDGET_PANEL, payload }
}

export function toggleEditLockedModal(payload) {
  return { type: TOGGLE_EDIT_LOCKED_MODAL, payload }
}

export function toggleUnsavedChangesFlag(payload) {
  return { type: TOGGLE_UNSAVED_CHANGES_FLAG, payload }
}

export function toggleSaveToBoardFlag(payload) {
  return { type: TOGGLE_SAVE_TO_BOARD_FLAG, payload }
}

export function setCurrentCard(payload) {
  return { type: SET_CURRENT_CARD, payload }
}

export function toggleUnsavedChangesModal(payload) {
  return { type: TOGGLE_UNSAVED_CHANGES_MODAL, payload }
}

export function toggleEmptyNameValidation(payload) {
  return { type: TOGGLE_EMPTY_NAME_VALIDATION, payload }
}

// Get card with widgets
export function cardRequestStart() {
  return { type: REQUEST_CARD }
}

export function cardReceive(payload) {
  return { type: RECEIVE_CARD, payload }
}

export function receiveCardErrorMessage(payload) {
  return { type: RECEIVE_CARD_ERROR, payload }
}

// Set selected widgets array
export const setSelectedWidgets = payload => (dispatch, getState) => {
  const {
    builder: { selectedWidgets }
  } = getState()

  // clear ribbon and it's state when editing widget is changed in order to make sure
  // widget would not work with incorrect ribbon/ribbon state
  const isMultiSelect = payload.length > 1
  if (isMultiSelect || (!isMultiSelect && selectedWidgets[0] !== payload[0])) {
    dispatch(setRibbonState({}))
    dispatch(setRibbonCallback(null))
  }

  dispatch({ type: SET_SELECTED_WIDGETS, payload })
}

export const addHighlightedWidget = widgetId => (dispatch, getState) => {
  const {
    builder: { highlightedWidgets }
  } = getState()

  const shouldUpdate = !highlightedWidgets.includes(widgetId)

  if (shouldUpdate) {
    dispatch({ type: SET_HIGHLIGHTED_WIDGETS, payload: [...highlightedWidgets, widgetId] })
  }
}

export const resetHighlightedWidgets = () => {
  return { type: SET_HIGHLIGHTED_WIDGETS, payload: [] }
}

export const setHighlightedSection = sectionNumber => (dispatch, getState) => {
  const {
    builder: { highlightedSection }
  } = getState()

  if (highlightedSection !== sectionNumber) {
    dispatch({ type: SET_HIGHLIGHTED_SECTION, payload: sectionNumber })
  }
}

// Set editing widget ID
export const setEditingWidget = payload => (dispatch, getState) => {
  const {
    builder: { editingWidget }
  } = getState()

  // clear ribbon and it's state when editing widget is changed in order to make sure
  // widget would not work with incorrect ribbon/ribbon state
  if (editingWidget !== payload) {
    dispatch(setRibbonState({}))
    dispatch(setRibbonCallback(null))
  }

  dispatch({ type: SET_EDITING_WIDGET, payload })
}

export function setActiveSection(payload) {
  return { type: SET_ACTIVE_SECTION, payload }
}

// Open card builder
export function openCardBuilder(payload) {
  return dispatch => {
    dispatch(toggleUnsavedChangesModal(false))
    navigateToCardBuilder({
      tenantId: payload.tenantId,
      boardId: payload.boardId,
      cardUuid: payload.cardID,
      isApp: payload.isApp
    })
    dispatch(setSelectedWidgets([]))
    dispatch(setEditingWidget(''))
    dispatch(setActiveSection(0))
  }
}

// needs only when autosave is triggering after field blur
// should be set in true when user start typing in field
// and should be set in false after autosave is triggered in onBlur callback
export const setActiveWidgetUpdated = payload => ({
  type: SET_ACTIVE_WIDGET_UPDATED,
  payload
})

// Should be used when user try to leave current card
export function leaveCurrentCard({ callback }) {
  return (dispatch, getState) => {
    const {
      builder: { isUnsavedChanges, activeWidgetWasUpdated }
    } = getState()

    if (isUnsavedChanges || activeWidgetWasUpdated) {
      dispatch(setEditingWidget(''))
      dispatch(setActiveWidgetUpdated(false))
      dispatch(toggleUnsavedChangesModal(true))
    } else {
      dispatch(callback)
    }
  }
}

export function clearBuilder() {
  return dispatch => {
    dispatch(setCurrentCard({ board: {} }))
    dispatch(setSelectedWidgets([]))
    dispatch(setEditingWidget(''))
    dispatch(setRibbonCallback(null))
  }
}

export function goToBoard(currentBoardIds) {
  return dispatch => {
    dispatch(toggleUnsavedChangesModal(false))
    navigateToBoard({
      tenantId: currentBoardIds.tenantId,
      boardId: currentBoardIds.boardId
    })
    dispatch(setCurrentCard({ board: {} }))
    dispatch(setSelectedWidgets([]))
    dispatch(setEditingWidget(''))
  }
}

export function goToApp(currentBoardIds) {
  return dispatch => {
    dispatch(toggleUnsavedChangesModal(false))
    navigateToAppView({
      tenantId: currentBoardIds.tenantId,
      boardId: currentBoardIds.boardId,
      cardID: currentBoardIds.cardID
    })
    dispatch(setCurrentCard({ board: {} }))
    dispatch(setSelectedWidgets([]))
    dispatch(setEditingWidget(''))
  }
}

export function goToTeamAdministration(payload) {
  return dispatch => {
    navigateToTeamAdministration(payload.tenantId, payload.tab)
    dispatch(toggleUnsavedChangesModal(false))
    dispatch(setCurrentCard({ board: {} }))
    dispatch(setSelectedWidgets([]))
    dispatch(setEditingWidget(''))
  }
}

export function goToOrganizationSettings(payload) {
  return dispatch => {
    navigateToOrganizationAdministration(payload.organizationId, payload.tab)
    dispatch(toggleUnsavedChangesModal(false))
    dispatch(setCurrentCard({ board: {} }))
    dispatch(setSelectedWidgets([]))
    dispatch(setEditingWidget(''))
  }
}

// widget linking resolution
export function updateResolvedDraftWidgets(payload) {
  return { type: UPDATE_RESOLVED_DRAFT_WIDGETS, payload }
}

export function resolveDraftWidgets(payload) {
  return (dispatch, getState) => {
    const {
      builder: { currentCard }
    } = getState()
    const widgets = currentCard.widgets || []

    if (!currentCard.uuid || !widgets.length) {
      return Promise.resolve()
    }

    const hasLinkedConsumers = widgets.some(isLinkedConsumer)

    if (!hasLinkedConsumers) {
      return Promise.resolve()
    }

    return resolveCard(payload).then(response => {
      const { widgets: _widgets } = response.data
      const consumers = _widgets.filter(isLinkedConsumer)
      dispatch(updateResolvedDraftWidgets(consumers))
    })
  }
}

// save card update
export function cardUpdateReceive(payload) {
  return { type: RECEIVE_CARD_UPDATE, payload }
}

function processCardUpdatePayload(payload) {
  const data = { ...payload.data }
  // needs to send only id of users to update card owners
  if (data.owners) {
    data.ownersIds = payload.data.owners
    delete data.owners
  }

  return data
}

// Widget create
export function widgetCreateReceive(payload) {
  return { type: RECEIVE_WIDGET_CREATE, payload }
}

export function createWidget(payload) {
  return (dispatch, getState) => {
    const state = getState()

    const shouldAddToBoardView = state.builder.boardViewAutoMode
    const boardViewArray = getBoardViewArray(state.builder.currentCard.widgets)

    const modifiedData = payload.data.map((item, index) => {
      const widgetBoardViewData = { showOnBoardView: false }
      if (shouldAddToBoardView && !hasFlag(item.flags, flagsList.dontHaveBoardView)) {
        widgetBoardViewData.boardViewY = boardViewArray.length
        widgetBoardViewData.showOnBoardView = true

        boardViewArray.push(item.uuid)
      }
      // for bulk create needs to set new z-indexes based on count of widgets
      const zIndex = state.builder.currentCard.widgets.length + index
      const newlyCreated = payload.newlyCreated
      return {
        ...widgetBoardViewData,
        zIndex,
        newlyCreated,
        // Mute all newly created widgets
        isMuted: true,
        ...item
      }
    })

    dispatch(widgetCreateReceive(modifiedData))

    // if this widget should not become edited or selected, ignore selection
    if (!payload.newlyCreated && !payload.ignoreSelection) {
      // to fix issue (EUPBOARD01-878) with rendering initial multiselect frame
      // instead of frame around pasted widgets
      const selectedWidgets = modifiedData.map(widget => widget.uuid)
      dispatch(setSelectedWidgets([]))
      dispatch(setEditingWidget(''))
      setTimeout(() => dispatch(setSelectedWidgets(selectedWidgets), 0))
    }
    return modifiedData
  }
}

// Widget delete
export function widgetDeleteReceive(payload) {
  return { type: RECEIVE_WIDGET_DELETE, payload }
}

export function deleteWidget(payload) {
  return dispatch => {
    dispatch(widgetDeleteReceive(payload.widgetIds))
    // flag could be used to not reset selection state of the widget
    if (!payload.saveSelection) {
      dispatch(setSelectedWidgets([]))
      dispatch(setEditingWidget(''))
    }
  }
}

export const toggleCardBuilderServerError = payload => (dispatch, getState) => {
  const { isServerError } = getState().builder

  // to skip firing of action in case nothing changed
  if (isServerError === payload) {
    return
  }

  dispatch({ type: TOGGLE_CB_SERVER_ERROR, payload })
}

// widget update
export function widgetUpdateReceive(payload) {
  return { type: RECEIVE_WIDGET_UPDATE, payload }
}

const isDraftPayloadValid = data => {
  const hasCardUpdates = !!data.card
  const hasWidgetsUpdates = Object.keys(data.widgets).length > 0

  return hasCardUpdates || hasWidgetsUpdates
}

// Mutates "widgets" with "rows" data coming from a 3rd-party service.
const persistThirdPartyWidgetsData = async (widgets, { tenantId } = {}) => {
  if (!tenantId) throw new Error('Tenant ID is missing in "getThirdPartyWidgetsData" call')
  if (!widgets?.length) throw new Error('Widgets are missing in "getThirdPartyWidgetsData" call')

  await Promise.all(
    widgets.map(async widget => {
      if (!widget.flags.isThirdParty || (widget.rows && widget.rows !== '[[]]')) {
        return Promise.resolve()
      }

      try {
        // Fetch 3rd-party widget data.
        const [widgetData] = await resolveUnresolvedProviders([{ widget }], tenantId)

        // Put the data to "rows".
        widget.rows = JSON.stringify(widgetData.data)

        return Promise.resolve()
      } catch {
        return Promise.resolve()
      }
    })
  )
}

export function persistLastChanges(payload) {
  return async (dispatch, getState) => {
    const { tenantId } = currentCardSelector(getState())

    const savedStack = await SimpleIDB.get('undoStack')
    const changesStack = getChangesStack(savedStack)
    const { cardUpdatePayload, widgetsDeletePayload, widgetsUpdatePayload, widgetsCreatePayload } =
      getLastChangesOfCard(changesStack)

    markHistorySaving()

    const data = {
      widgets: {}
    }

    // card update
    if (cardUpdatePayload.tenantId) {
      data.card = processCardUpdatePayload(cardUpdatePayload)
    }

    if (widgetsDeletePayload.length) {
      data.widgets.delete = widgetsDeletePayload.map(item => ({ uuid: item.uuid }))
    }

    if (widgetsUpdatePayload.length) {
      await persistThirdPartyWidgetsData(widgetsUpdatePayload, { tenantId })
      data.widgets.update = getRidOfData(widgetsUpdatePayload)
    }

    if (widgetsCreatePayload.length) {
      await persistThirdPartyWidgetsData(widgetsCreatePayload, { tenantId })
      data.widgets.create = getRidOfData(widgetsCreatePayload)
    }

    // if no data to update - don't send request
    if (!isDraftPayloadValid(data)) {
      // isNoChanges - way to prevent resolveDraftWidgets if no changes
      dispatch(toggleCardBuilderServerError(false))

      return Promise.resolve({ isNoChanges: true })
    }

    return updateCardDraft({ ...payload, data })
      .then(() => {
        markHistorySaved()
        dispatch(toggleCardBuilderServerError(false))
      })
      .catch(err => {
        revertHistorySaving()

        if (isServerUnavailable(err.errorCode)) {
          dispatch(toggleCardBuilderServerError(true))
        }
        const errorMessage = findErrorConstraints(err)

        if (errorMessage && err.errorCode && err.errorCode < 500) {
          dispatch(
            showToastMessage({
              text: isTextErrorMessage(errorMessage)
                ? errorMessage
                : messages.CARD_COULD_NOT_BE_SAVED,
              size: 'L'
            })
          )
        }

        return Promise.reject(err)
      })
  }
}

// Auto save
export function autoSaveStart() {
  return { type: AUTO_SAVE_START }
}

export function autoSaveEnd() {
  return { type: AUTO_SAVE_END }
}

export function toggleAutoSave({ isEnable = false, autoSaveID = null, payload }) {
  // if isEnable=== true we don't need autoSaveID
  return (dispatch, getState) => {
    if (isEnable) {
      const id = setInterval(() => {
        const {
          spinner: { isAutoSaveWorking }
        } = getState()

        if (isAutoSaveWorking) return

        dispatch(autoSaveStart())
        dispatch(persistLastChanges(payload))
          .then(response => {
            if (response && response.isNoChanges) {
              return null
            }
            return dispatch(
              resolveDraftWidgets({
                ...payload,
                userDraftIfExist: true,
                cardID: payload.itemID
              })
            )
          })
          .finally(() => dispatch(autoSaveEnd()))
          .catch(err => console.error(err))
      }, 10 * 1000)
      dispatch({ type: SET_AUTOSAVE_INTERVAL_ID, payload: id })
    } else {
      clearInterval(autoSaveID)
      dispatch({ type: SET_AUTOSAVE_INTERVAL_ID, payload: null })
    }
  }
}

// Clear storage
export function clearChangesStorage(payload) {
  return (dispatch, getState) => {
    const { autoSaveID, isUnsavedChanges } = getState().builder

    batch(() => {
      dispatch(toggleAutoSave({ isEnable: false, autoSaveID }))
      dispatch(setCurrentCard({ board: {} }))
      dispatch(toggleUnsavedChangesFlag(false))
      dispatch(toggleUnsavedChangesSpinner(true))
      dispatch(setSelectedWidgets([]))
      dispatch(setEditingWidget(''))
    })

    // clear undo & redo stacks, original card and toggle unsaved flag
    setDataToLocalStorage('', {})

    return (isUnsavedChanges ? cancelCardEditing(payload) : Promise.resolve()).catch(err => {
      if (isTextErrorMessage(err.message)) {
        dispatch(
          showToastMessage({
            text: err.message,
            size: 'M'
          })
        )
      }
    })
  }
}

export const setLeftMenuCards = payload => ({ type: SET_LEFT_MENU_CARDS, payload })

export const replaceCardInLeftMenu = payload => ({
  type: REPLACE_CARD_IN_LEFT_MENU,
  payload
})

export const fetchLeftMenuCards = payload => dispatch =>
  dispatch(getCardsList({ ...payload, expand: { lockOwner: true } }))
    .then(response => {
      const updatedCards = updateSnapshotsOnCards(response.data.filter(card => !card.isCol))

      dispatch(setLeftMenuCards(updatedCards))
    })
    .catch(err => console.error(err))

// Save card on board
export function setActiveRequest() {
  return { type: SET_ACTIVE_REQUEST }
}

export function setActiveReceive() {
  return { type: SET_ACTIVE_RECEIVE }
}

export function setActiveReceiveError() {
  return { type: SET_ACTIVE_RECEIVE_ERROR }
}

export function saveChangesStorage(payload) {
  return (dispatch, getState) => {
    const { autoSaveID, isUnsavedChanges } = getState().builder

    dispatch(toggleUnsavedChangesSpinner(true))
    dispatch(toggleAutoSave({ isEnable: false, autoSaveID }))
    dispatch(setActiveRequest())
    dispatch(setSelectedWidgets([]))
    dispatch(setEditingWidget(''))

    return (
      isUnsavedChanges
        ? dispatch(persistLastChanges(payload)).then(() => saveActiveCard(payload))
        : Promise.resolve()
    )
      .then(() => {
        dispatch(setActiveReceive())
        // set original card as current
        const state = getState()
        const { currentCard, currentWidgetPanelItem } = state.builder

        // replace card in left-hand menu with saved
        if (currentWidgetPanelItem === 0) {
          dispatch(replaceCardInLeftMenu(currentCard))
        }

        setDataToLocalStorage('', {})
        dispatch(toggleUnsavedChangesFlag(false))
      })
      .finally(() => dispatch(toggleUnsavedChangesSpinner(false)))
      .catch(err => {
        dispatch(setActiveReceiveError())

        if (err.errorCode !== 403) {
          // in case of error enable autosave to allow user continue work
          // and then save changes
          dispatch(toggleAutoSave({ isEnable: true, payload }))
        }

        if (isTextErrorMessage(err.message) && err.errorCode && err.errorCode < 500) {
          // https://leverxeu.atlassian.net/browse/EUPBOARD01-6138
          // special wording for case from this issue
          // such strange implementation because BE can't set separate error code
          const errMessagePart = 'Draft and active tenantId'

          const toastText = err.message.includes(errMessagePart)
            ? messages.PRESS_SAVE_CARD_AGAIN
            : err.message

          dispatch(
            showToastMessage({
              text: toastText,
              size: 'M'
            })
          )
        }

        if (isServerUnavailable(err.errorCode)) {
          dispatch(
            showToastMessage({
              text: messages.SAVE_ACTIVE_ERROR_MESSAGE,
              size: 'M'
            })
          )
        }

        if (err.errorCode === 403) {
          setDataToLocalStorage('', {})
          dispatch(toggleUnsavedChangesFlag(false))
        }
      })
  }
}

// Start edit (first change)
export function startEditRequest() {
  return { type: START_EDIT_REQUEST }
}

export function startEditReceive() {
  return { type: START_EDIT_RECEIVE }
}

export function startEditReceiveError() {
  return { type: START_EDIT_RECEIVE_ERROR }
}

const updateLastUpdateTime = payload => {
  if (payload.type === CARD_UPDATE || !payload.data) {
    return payload
  }

  const now = Date.now()

  return {
    ...payload,
    data: payload.data.map(widget => ({
      ...widget,
      lastUpdate: now
    }))
  }
}

// Autosave
// @param {boolean} pushToLocalStorage - if change should be pushed to local storage
export function updateItem(payload, isPushToLocalStorage) {
  return async dispatch => {
    let createdWidgets = []

    const operation = updateLastUpdateTime(payload)

    switch (operation.type) {
      case CARD_UPDATE:
        dispatch(cardUpdateReceive(operation))
        break
      case WIDGET_CREATE:
        createdWidgets = dispatch(createWidget(operation))
        break
      case WIDGET_DELETE:
        dispatch(deleteWidget(operation))
        break
      case WIDGET_UPDATE:
        dispatch(widgetUpdateReceive(operation.data))
        break
      default:
    }

    if (isPushToLocalStorage) {
      const draft = {
        ...operation,
        timestamp: operation.timestamp || 0
      }

      // should remember widget IDs after creation
      // should save changed widgets data
      if (operation.type === WIDGET_CREATE) {
        draft.widgetIds = operation.data.map(item => item.uuid)
        draft.data = JSON.parse(JSON.stringify(createdWidgets))
        // delete flag before saving to storage
        draft.data.forEach(widget => delete widget.newlyCreated)
      }
      addRequestToStack(draft)
      await callRequestFromStack()
    }
  }
}

// depends on type
// @param {boolean} isSaveActive - should be saved active after updating
export function saveChanges(payload) {
  return async (dispatch, getState) => {
    const state = getState()
    // to prevent any saving during startEdit request is sending
    if (state.spinner.isStartEditRequested) {
      return Promise.resolve()
    }

    dispatch(autoSaveStart())
    if (!state.builder.isUnsavedChanges) {
      dispatch(startEditRequest())
      dispatch(toggleUnsavedChangesSpinner(false))
      return startCardEditing(payload)
        .then(async () => {
          // close unsaved changes message if user starts editing of another card
          if (state.profile.user.hasDraft) {
            dispatch(toggleDraftMessage(false))
            dispatch(clearDraft())
          }
          const currentCard = state.builder.currentCard
          setDataToLocalStorage('', currentCard)
          dispatch(toggleUnsavedChangesFlag(true))

          // in case if it's card create action should just get draft without update
          if (payload.type !== CARD_CREATE) {
            dispatch(toggleAutoSave({ isEnable: true, payload }))

            await dispatch(updateItem(payload, true))

            // if it's needed to saveActive after update
            if (getState().builder.isSaveToBoard) {
              dispatch(saveChangesStorage(payload))
              dispatch(toggleSaveToBoardFlag(false))
            } else {
              dispatch(toggleUnsavedChangesSpinner(false))
            }
          }

          dispatch(startEditReceive())
        })
        .catch(err => {
          dispatch(startEditReceiveError())
          if (err.errorCode === 409) {
            dispatch(toggleEditLockedModal(true))
          } else if (isServerUnavailable(err.errorCode)) {
            dispatch(
              showToastMessage({
                text: messages.START_EDIT_ERROR_MESSAGE,
                size: 'M'
              })
            )
          } else if (isTextErrorMessage(err.message)) {
            dispatch(
              showToastMessage({
                text: err.message,
                size: 'M'
              })
            )
          }
        })
        .finally(() => {
          dispatch(autoSaveEnd())
        })
    }
    await dispatch(updateItem(payload, true))

    // if it's needed to saveActive after update
    if (getState().builder.isSaveToBoard) {
      dispatch(saveChangesStorage(payload))
      dispatch(toggleSaveToBoardFlag(false))
    }

    dispatch(autoSaveEnd())
    return Promise.resolve()
  }
}

export function undoAction() {
  return async dispatch => {
    const savedStack = await SimpleIDB.get('undoStack')
    const changesStack = getChangesStack(savedStack)
    const undoStack = getUndoStack(changesStack)
    const savedOriginalCard = await SimpleIDB.get('originalCard')
    const originalCard = JSON.parse(savedOriginalCard)
    if (undoStack.length) {
      const lastChange = undoStack.pop()
      // action that is undo should be marked as undid
      lastChange.undid = true
      // it is need for possibility to redo undid action
      lastChange.redid = false

      const previousChange = getPreviousChange(undoStack, lastChange, originalCard)
      // reversed state is stored for ability to save
      // to backend the previous state of early saved widget
      changesStack.push({
        ...previousChange,
        reversed: true,
        undid: false,
        saved: false,
        saving: false
      })
      setDataToLocalStorage(changesStack, null)
      if (previousChange) {
        dispatch(updateItem(previousChange, false))
        dispatch(setSelectedWidgets([]))
        dispatch(setEditingWidget(''))
      }
    }
  }
}

export function redoAction() {
  return async dispatch => {
    const savedStack = await SimpleIDB.get('undoStack')
    const changesStack = getChangesStack(savedStack)
    const redoStack = getRedoStack(changesStack)

    if (redoStack.length > 0) {
      // put the last change from redo to undo
      const nextChange = redoStack.pop()
      changesStack.push({
        ...nextChange,
        redid: true,
        undid: false,
        saved: false,
        saving: false
      })
      setDataToLocalStorage(changesStack, null)
      dispatch(updateItem(nextChange, false))
      dispatch(setSelectedWidgets([]))
      dispatch(setEditingWidget(''))
    }
  }
}

// toggle popup notification
export function togglePopupNotification(payload) {
  const initialState = {
    state: false,
    title: '',
    desc: ''
  }

  return { type: TOGGLE_POPUP_NOTIFICATION, payload: !payload ? initialState : payload }
}

export function cardBuilderCardRequest(payload, boardInfo, createMode) {
  return dispatch => {
    dispatch(cardRequestStart())
    return resolveCard(payload)
      .then(response => {
        const currentCard = response.data

        if (currentCard.isLinked) {
          throw new Error('isLinkedCard')
        }

        currentCard.board = boardInfo
        dispatch(cardReceive(currentCard))

        if (createMode) {
          dispatch(
            saveChanges({
              type: CARD_CREATE,
              tenantId: payload.tenantId,
              boardId: payload.boardId,
              itemID: currentCard.uuid
            })
          )
        }
        return response.data
      })
      .catch(err => {
        dispatch(receiveCardErrorMessage(err))

        if (err.message === 'isLinkedCard') {
          dispatch(
            togglePopupNotification({
              state: true,
              title: messages.CARD_IS_LINKED,
              desc: messages.CARD_IS_LINKED_DESC
            })
          )
        }

        dispatch(goToBoard(payload))
      })
  }
}

export function toggleBuilderCreateMode(payload) {
  return { type: TOGGLE_BUILDER_CREATE_MODE, payload }
}

// Enable or disable automatic mode of generation board view for a card during widget creating
export function toggleBoardViewMode(payload) {
  return { type: TOGGLE_BOARD_VIEW_MODE, payload }
}

export function setWidgetsToBuffer(payload) {
  return { type: SET_WIDGETS_TO_BUFFER, payload }
}

// Copy selected widgets
export function copySelectedWidgets() {
  return (dispatch, getState) => {
    const state = getState().builder
    const currentCard = state.currentCard
    const { selectedWidgets } = getSelectedWidgets(state.selectedWidgets, currentCard)
    const oldWidgetKey = {
      tenantId: currentCard.board.tenantId,
      boardId: currentCard.board.boardId,
      cardID: currentCard.uuid
    }

    if (selectedWidgets.length) {
      const copiedWidgets = getWidgetsDataByIds(state.currentCard.widgets, selectedWidgets, {
        oldWidgetKey
      })

      dispatch(setWidgetsToBuffer(JSON.parse(JSON.stringify(copiedWidgets))))
    }
  }
}

// Cut selected widgets
export function cutSelectedWidgets() {
  return (dispatch, getState) => {
    const state = getState().builder
    const selectedWidgets = state.selectedWidgets
    const currentCard = state.currentCard

    dispatch(copySelectedWidgets())
    dispatch(setSelectedWidgets([]))
    dispatch(setEditingWidget(''))

    if (selectedWidgets.length) {
      const payload = {
        type: WIDGET_DELETE,
        widgetIds: selectedWidgets,
        data: getWidgetsDataByIds(currentCard.widgets, selectedWidgets),
        tenantId: currentCard.board.tenantId,
        boardId: currentCard.board.boardId,
        itemID: currentCard.uuid
      }

      dispatch(saveChanges(payload))
    }
  }
}

export function addCardSection(count) {
  return (dispatch, getState) => {
    const {
      builder: { currentCard }
    } = getState()
    const height = currentCard.height || DEFAULT_CARD_HEIGHT
    const payload = {
      type: CARD_UPDATE, // request type
      data: {
        // what was changed
        height: height + DEFAULT_CARD_HEIGHT * count,
        isDividersShown: height === DEFAULT_CARD_HEIGHT || currentCard.isDividersShown
      },
      tenantId: currentCard.board.tenantId,
      boardId: currentCard.board.boardId,
      itemID: currentCard.uuid
    }
    return dispatch(saveChanges(payload))
  }
}

export function restrictAddedWidgetsInCardBuilder(newWidgets) {
  return (dispatch, getState) => {
    const widgets = currentCardWidgetsSelector(getState())
    return dispatch(restrictAddedWidgets(newWidgets, widgets))
  }
}

// Paste widgets from buffer to the card
export function pasteWidgetsFromBuffer() {
  return (dispatch, getState) => {
    const state = getState()
    const builder = state.builder
    const user = state.profile.user
    const currentCard = builder.currentCard

    const tenantId = currentCard.board.tenantId
    const boardId = currentCard.board.boardId
    const cardID = currentCard.uuid

    const groupIndexes = {}

    // if user copy widgets from one board to another needs to copy widget menu
    let needToCopyResources = false

    let disablePaste = false
    let sectionsToAdd = 0
    const minSection = builder.widgetsBuffer.reduce(
      (acc, item) => (item.section < acc ? item.section : acc),
      Infinity
    )
    const deltaSection = minSection - builder.activeSection
    // store map of old uuid -> new uuid
    const preservingMap = {}

    const { allowedCount } = dispatch(restrictAddedWidgetsInCardBuilder(builder.widgetsBuffer))
    const isLinkingResetNeed = getIsDataLinkingResetNeed(builder.widgetsBuffer)
    let widgetsBuffer = builder.widgetsBuffer
      .slice(0, allowedCount)
      .sort((a, b) => a.zIndex - b.zIndex)
      .map(widget => {
        const newWidget = {
          ...widget,
          section: widget.section - deltaSection,
          tenantId,
          boardId,
          cardUuid: cardID
        }
        const oldWidgetID = newWidget.uuid
        // needs to clear zIndex and board view index properties
        // from each copied widget to set new
        delete newWidget.zIndex
        delete newWidget.boardViewY
        delete newWidget.showOnBoardView

        // Remove link to a filter if it's from another team.
        if (newWidget.filterUuid && newWidget.oldWidgetKey.tenantId !== newWidget.tenantId) {
          delete newWidget.filterUuid
        }

        if (newWidget.widgetClassName === WIDGETS_IN_SYSTEM.DatasetWidget.name) {
          delete newWidget.fileMeta
          delete newWidget.isFirstUploadWidgetNameProvided
          newWidget.widgetTitle = 'Dataset'
        }

        newWidget.uuid = generateGUID()
        preservingMap[oldWidgetID] = newWidget.uuid

        // if in the widgets buffer there are widgets groups
        // we need to set new groupIndex to each group
        if (newWidget.groupIndex) {
          if (!groupIndexes[newWidget.groupIndex]) {
            groupIndexes[newWidget.groupIndex] = generateGUID()
          }
          newWidget.groupIndex = groupIndexes[newWidget.groupIndex]
        }

        // if copied widget was not on the first section on source card,
        // we need to check it coordinates on current card and reset if needed
        const cardHeight = currentCard.height || DEFAULT_CARD_HEIGHT
        let absolutePositionY = newWidget.positionY + newWidget.section * DEFAULT_CARD_HEIGHT
        const deltaHeight = absolutePositionY + newWidget.height - cardHeight

        // if widget is outside authoring area, will update its position and section
        if (deltaHeight > 0) {
          newWidget.section = builder.activeSection
          absolutePositionY = newWidget.positionY + newWidget.section * DEFAULT_CARD_HEIGHT
        }

        // check for intersection with hidden section
        disablePaste = isWidgetIntersectsWithHiddenSections({
          x: newWidget.positionX,
          y: absolutePositionY,
          width: newWidget.width,
          height: newWidget.height,
          transform: newWidget.rotation,
          hiddenSections: currentCard.hiddenSections || []
        })

        // get sections count which is needed to add
        const sectionsNeeded = getSectionsCountToAdd({
          y: absolutePositionY,
          height: newWidget.height,
          cardHeight
        })
        if (sectionsNeeded > sectionsToAdd) {
          sectionsToAdd = sectionsNeeded
        }

        if (
          (newWidget.oldWidgetKey &&
            boardId !== newWidget.oldWidgetKey.boardId &&
            hasCategory(newWidget.category, 'file')) ||
          isLinkingResetNeed
        ) {
          newWidget.oldWidgetKey.widgetID = oldWidgetID
          newWidget.oldWidgetKey.targetTenantID = tenantId
          newWidget.oldWidgetKey.targetBoardID = boardId
          if (!isLinkingResetNeed) {
            needToCopyResources = true
            // uploaded file doesn't have expanded user - needs to set email and username
            newWidget.uploadedBy = {
              email: user.email,
              username: currentCard.board.tenantName
            }
          }
        } else {
          delete newWidget.oldWidgetKey
        }

        if (newWidget.widgetClassName === WIDGETS_IN_SYSTEM.ProjectTaskWidget.name) {
          newWidget.newlyCreated = true
        }

        return newWidget
      })

    // if group of widget was copied and they are linked
    // to each other need to rewrite their data linking
    if (isLinkingResetNeed) {
      widgetsBuffer = updateCopiedWidgetDataLinking(widgetsBuffer)
    }

    if (!widgetsBuffer.length) {
      return
    }
    // preserve uploadFilesUuid for grouped files
    widgetsBuffer.forEach(widget => {
      if (widget.uploadFilesUuid && preservingMap[widget.uploadFilesUuid]) {
        widget.uploadFilesUuid = preservingMap[widget.uploadFilesUuid]
      }
    })

    if (disablePaste) {
      dispatch(
        showToastMessage({
          text: messages.ACTION_IS_UNAVAILABLE,
          size: 'M'
        })
      )
      return
    }

    if (needToCopyResources) {
      dispatch(copyFiles(widgetsBuffer))
    } else {
      const newlyCreated = widgetsBuffer.some(widget => widget.newlyCreated)

      const payload = {
        type: WIDGET_CREATE,
        data: widgetsBuffer,
        tenantId,
        boardId,
        itemID: cardID,
        newlyCreated
      }

      if (sectionsToAdd) {
        dispatch(addCardSection(sectionsToAdd)).then(() => dispatch(saveChanges(payload)))
      } else {
        dispatch(saveChanges(payload))
      }
    }
  }
}

// set callback for unsaved changes modal
export function setUnsavedChangesCallback(payload) {
  return { type: SET_UNSAVED_CHANGES_CALLBACK, payload }
}

// Toggle chart edit modal window
export function toggleChartsModal(payload) {
  return { type: TOGGLE_CHARTS_MODAL, payload }
}

// Toggle jira widget edit modal window
export function toggleIssueCounterModal(payload) {
  return { type: TOGGLE_ISSUE_COUNTER_MODAL, payload }
}

export function clearWidgetSelection(isClearSelection) {
  return (dispatch, getState) => {
    const editingWidget = getState().builder.editingWidget
    if (editingWidget) {
      dispatch(setEditingWidget(''))
    }
    if (isClearSelection) {
      dispatch(setSelectedWidgets([]))
    }
  }
}

// toggle data linking modal
export function toggleDataLinking(payload) {
  return { type: TOGGLE_DATA_LINKING, payload }
}

export function toggleSmartLinkModal(payload) {
  return { type: TOGGLE_SMART_LINK_MODAL, payload }
}

export const bulkWidgetsUpdate = widget => (dispatch, getState) => {
  const bulkWidgetsUpdatePayload = bulkWidgetsUpdatePayloadSelector(getState())
  const selectedWidgets = selectedWidgetsSelector(getState())
  const currentCard = currentCardSelector(getState())
  if (!selectedWidgets.length) {
    return
  }
  if (bulkWidgetsUpdatePayload.length < selectedWidgets.length - 1) {
    // if not all selected widgets were updated
    // just add updated widget to the storage to save later
    dispatch({ type: BULK_WIDGETS_UPDATE, payload: widget })
    return
  }
  // if all changes were collected widgets will be put in the one payload
  const widgets = [...bulkWidgetsUpdatePayload, widget]
  const cardHeight = currentCardHeightSelector(getState()) || DEFAULT_CARD_HEIGHT
  const payload = {
    type: WIDGET_UPDATE,
    data: updateWidgetsDimensions(widgets, cardHeight),
    tenantId: currentCard.board.tenantId,
    boardId: currentCard.board.boardId,
    itemID: currentCard.uuid
  }

  dispatch(saveChanges(payload)).then(() => dispatch(setActiveWidgetUpdated(false)))
  dispatch({ type: BULK_WIDGETS_UPDATE })
}
