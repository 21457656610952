import React from 'react'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

import CopyBlockContainer from '../../../containers/workflowBuilder/сopyBlocks/CopyBlockContainer'

import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'

const radioFieldAttr = {
  radioFieldKey: EFieldKeys.IS_LINK,
  radioFieldLeftLabel: messages.COPY_CARD,
  radioFieldRightLabel: messages.COPY_AS_CARD_LINK,
  radioFieldTooltip: messages.COPY_CARD_TOOLTIP
}

const inputSourceAttr = {
  inputSourceType: OBJECT_TYPES.CARD,
  inputSourceTitle: messages.CARD_LEVEL_INPUT,
  inputSourcePlaceholder: messages.CARD_LEVEL_INPUT_PLACEHOLDER
}

const inputTargetAttr = {
  inputTargetType: OBJECT_TYPES.BOARD,
  targetFieldKey: EFieldKeys.BOARD_INPUT,
  inputTargetTitle: messages.DESTINATION_BOARD_FOR_COPY,
  inputTargetPlaceholder: messages.DESTINATION_BOARD_FOR_COPY_PLACEHOLDER
}

const CopyCard = props => {
  const { block, updateWorkflowBlock, changeWorkflowBlockInput } = props

  return (
    <CopyBlockContainer
      block={block}
      inputSourceAttr={inputSourceAttr}
      inputTargetAttr={inputTargetAttr}
      radioFieldAttr={radioFieldAttr}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
    />
  )
}

export default CopyCard
