import { getUpdateWidgetDataPostfixText } from 'helpers/workflowWidgetDataBlock/workflowWidgetDataBlockHelpers'
import { EWorkflowBlockTypes } from '../../constants/workflowBuilder/blocksTypes'
import { OBJECT_TYPES, WF_USERS } from '../../constants/workflows'
import messages from '../../constants/messages'
import { getCardOwnerOutputPrefixText } from '../workflowCardOwnerBlocks/workflowCardOwnerBlocksHelpers'

export const OUTPUT_COMPONENT_STATES = {
  EMPTY: 'EMPTY',
  DEFINED: 'DEFINED',
  UNDEFINED: 'UNDEFINED'
}

export const AUTHOR_FOR_COMMENT_OPTIONS = {
  [WF_USERS.CURRENT_USER]: {
    value: WF_USERS.CURRENT_USER,
    label: messages.USER_WHO_TRIGGERED_WORKFLOW_LABEL
  },
  [WF_USERS.WORKSPACE_USER]: {
    value: WF_USERS.WORKSPACE_USER,
    label: messages.WORKSPACE_NAME_LABEL
  }
}

export const AUTHOR_FOR_COMMENT_OPTIONS_LIST = Object.values(AUTHOR_FOR_COMMENT_OPTIONS)

export const getUndefinedOutputName = type => {
  const wordings = {
    [OBJECT_TYPES.STRING]: messages.UNDEFINED_STRING,
    [OBJECT_TYPES.WIDGET]: messages.UNDEFINED_WIDGET,
    [OBJECT_TYPES.WIDGET_DATA]: messages.UNDEFINED_WIDGET_DATA,
    [OBJECT_TYPES.CARD]: messages.UNDEFINED_CARD,
    [OBJECT_TYPES.CARD_BUNDLE]: messages.UNDEFINED_CARD_BUNDLE,
    [OBJECT_TYPES.BOARD]: messages.UNDEFINED_BOARD,
    [OBJECT_TYPES.COLUMN]: messages.UNDEFINED_COLUMN,
    [OBJECT_TYPES.USER]: messages.UNDEFINED_USER,
    [OBJECT_TYPES.WIDGETS_BUNDLE]: messages.UNDEFINED_WIDGETS_BUNDLE,
    [OBJECT_TYPES.JSON]: messages.UNDEFINED_JSON,
    [OBJECT_TYPES.FILE]: messages.UNDEFINED_FILE
  }

  return wordings[type]
}

export const getBlockArrowColor = type => {
  const colors = {
    [OBJECT_TYPES.WIDGET]: '#d0a8f0',
    [OBJECT_TYPES.WIDGET_DATA]: '#d0a8f0',
    [OBJECT_TYPES.CARD]: '#ffcd34',
    [OBJECT_TYPES.CARD_BUNDLE]: '#ffcd34',
    [OBJECT_TYPES.COLUMN]: '#b5bfc7',
    [OBJECT_TYPES.USER]: '#ffb3f3',
    [OBJECT_TYPES.BOARD]: '#458fff',
    [OBJECT_TYPES.STRING]: '#44d69d',
    [OBJECT_TYPES.JSON]: '#44d69d',
    [OBJECT_TYPES.FILE]: '#44d69d',
    [OBJECT_TYPES.WIDGETS_BUNDLE]: '#d0a8f0'
  }

  return colors[type]
}

export const getOutputComponentName = ({ state, type, name }) => {
  if (state === OUTPUT_COMPONENT_STATES.EMPTY) {
    return messages.UNDEFINED_OUTPUT
  }

  return name || getUndefinedOutputName(type)
}

export const getOutputPrefix = (block, state) => {
  const prefixes = {
    [EWorkflowBlockTypes.HIDE_WIDGET]: {
      text: messages.HIDE_BLOCK_PREFIX,
      isAfter: false
    },
    [EWorkflowBlockTypes.HIDE_CARD_SECTION]: {
      text: 'Updated',
      isAfter: false
    },
    [EWorkflowBlockTypes.HIDE_CARD_ACTION]: {
      text: 'Updated',
      isAfter: false
    },
    [EWorkflowBlockTypes.HIDE_COLUMN]: {
      text: messages.HIDE_BLOCK_PREFIX,
      isAfter: false
    },
    [EWorkflowBlockTypes.HIDE_WIDGETS_BUNDLE_COMPONENT]: {
      text: messages.HIDE_BLOCK_PREFIX,
      isAfter: false
    },
    [EWorkflowBlockTypes.LOCK_WIDGET]: {
      text: messages.LOCK_WIDGET_BLOCK_PREFIX,
      isAfter: false
    },
    [EWorkflowBlockTypes.LOCK_WIDGETS_BUNDLE_COMPONENT]: {
      text: messages.LOCK_WIDGET_BLOCK_PREFIX,
      isAfter: false
    },
    [EWorkflowBlockTypes.MOVE_CARD]: {
      text: messages.MOVE_BLOCK_PREFIX,
      isAfter: false
    },
    [EWorkflowBlockTypes.MOVE_CARD_TO_BOARD]: {
      text: messages.MOVE_BLOCK_PREFIX,
      isAfter: false
    },
    [EWorkflowBlockTypes.MOVE_COLUMN]: {
      text: messages.MOVE_BLOCK_PREFIX,
      isAfter: false
    },
    [EWorkflowBlockTypes.RENAME_CARD]: {
      text: messages.RENAME_BLOCK_PREFIX,
      isAfter: false
    },
    [EWorkflowBlockTypes.RENAME_COLUMN]: {
      text: messages.RENAME_BLOCK_PREFIX,
      isAfter: false
    },
    [EWorkflowBlockTypes.SET_CARD_OWNER]: {
      text: state === OUTPUT_COMPONENT_STATES.DEFINED ? getCardOwnerOutputPrefixText(block) : null,
      isAfter: true
    },
    [EWorkflowBlockTypes.RENAME_BOARD]: {
      text: messages.RENAME_BLOCK_PREFIX,
      isAfter: false
    },
    [EWorkflowBlockTypes.SWITCH_STAGE_WIDGET]: {
      text: messages.WITH_STAGE_WORKFLOW,
      isAfter: true
    },
    [EWorkflowBlockTypes.ADD_CARD_COMMENT]: {
      text: messages.ADD_COMMENT_BLOCK_POSTFIX,
      isAfter: true
    },
    [EWorkflowBlockTypes.UPDATE_WIDGET_DATA]: {
      text: messages.UPDATED,
      isAfter: false
    }
  }

  return prefixes[block.type] || null
}

export const getOutputPostfix = block => {
  const prefixes = {
    [EWorkflowBlockTypes.UPDATE_WIDGET_DATA]: {
      text: getUpdateWidgetDataPostfixText(block)
    },
    [EWorkflowBlockTypes.SEND_EMAIL]: {
      text: messages.SEND_EMAIL_POSTFIX_OUTPUT
    }
  }

  return prefixes[block.type] || null
}

export const getEmptyOutputTypeText = blockType => {
  const texts = {
    [EWorkflowBlockTypes.DELETE_CARD]: messages.DELETE_CARD_OUTPUT,
    [EWorkflowBlockTypes.DELETE_CARD_BUNDLE]: messages.DELETE_CARD_BUNDLE_OUTPUT,
    [EWorkflowBlockTypes.DELETE_WIDGET]: messages.DELETE_WIDGET_OUTPUT,
    [EWorkflowBlockTypes.DELETE_WIDGETS_BUNDLE]: messages.DELETE_WIDGETS_BUNDLE_OUTPUT,
    [EWorkflowBlockTypes.DELETE_COLUMN]: messages.DELETE_COLUMN_OUTPUT,
    [EWorkflowBlockTypes.SEND_EMAIL]: messages.SEND_EMAIL_OUTPUT,
    [EWorkflowBlockTypes.PUBLISH_FEED_NOTIFICATION]: messages.PUBLISH_FEED_NOTIFICATION_OUTPUT
  }

  return texts[blockType] || null
}

export const getEmptyOutputTypeImage = blockType => {
  const images = {
    [EWorkflowBlockTypes.SEND_EMAIL]: messages.SEND_EMAIL_OUTPUT
  }

  return images[blockType] || false
}
