import { Component } from 'react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import SwitchWidgetBlock from 'components/workflowBuilder/switchStageWidgetBlocks/SwitchWidgetBlock'

export default class SwitchStageWidgetContainer extends Component {
  state = {
    stageName: this.props.block.meta[EFieldKeys.NAME]
  }

  handleReset = fieldName => {
    this.handleInputChange(fieldName, { id: null })
  }

  handleInputChange = (fieldName, item) => {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  changeStageName = event => {
    const stageName = event.target.value

    this.setState({ stageName })
  }

  blurColumnName = event => {
    const stageName = event.target.value.trim()
    this.updateBlockMeta(EFieldKeys.NAME, stageName)
  }

  updateBlockMeta = (key, fieldValue) => {
    const {
      updateWorkflowBlock,
      block: { id, meta }
    } = this.props

    if (meta[key] !== fieldValue) {
      updateWorkflowBlock({
        id,
        data: {
          meta: { ...meta, [key]: fieldValue },
          error: {}
        }
      })
    }
  }

  changeSelectOption = (propName, { value }) => {
    this.updateBlockMeta(propName, value)
  }

  render() {
    const { block, inputFieldLabel, inputFieldPlaceholder, inputType, outputFieldPlaceholder } =
      this.props
    const { stageName } = this.state
    const meta = block.meta || {}

    return (
      <SwitchWidgetBlock
        inputFieldLabel={inputFieldLabel}
        inputFieldPlaceholder={inputFieldPlaceholder}
        inputType={inputType}
        outputFieldPlaceholder={outputFieldPlaceholder}
        block={block}
        switchStageBy={meta[EFieldKeys.TYPE]}
        stageName={stageName}
        changeStageName={this.changeStageName}
        blurColumnName={this.blurColumnName}
        handleInputChange={this.handleInputChange}
        changeSelectOption={this.changeSelectOption}
        handleReset={this.handleReset}
      />
    )
  }
}
