import { findSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import PlainInput from 'components/fields/PlainInput'
import ReactSelect from 'components/fields/ReactSelect'
import 'scss/workflowBuilder/common/object-attribute-selector.scss'

const ObjectAttributeSelector = ({
  attribute,
  attributesOptions,
  params,
  paramsOptions,
  blurNumericField,
  changeNumericValue,
  onChangeAttribute,
  disabled,
  placeholder,
  error,
  showAttributeSelector = true
}) => (
  <div className="object-attribute-selector">
    {showAttributeSelector && (
      <ReactSelect
        className={`attribute-select open-up ${error && 'error-state'}`}
        size="large"
        value={findSelectedOptionByKey(attribute, attributesOptions)}
        options={attributesOptions}
        isDisabled={disabled}
        placeholder={placeholder}
        menuPortalEnabled
        onChange={onChangeAttribute}
      />
    )}
    {Object.keys(paramsOptions).map(key => (
      <PlainInput
        key={key}
        className="input-field param-input"
        placeholder={paramsOptions[key].name}
        maxLength={3}
        disabled={disabled}
        value={params && params[key]}
        onBlur={e => blurNumericField(e, key)}
        onChange={e => changeNumericValue(e, key)}
      />
    ))}
  </div>
)

export default ObjectAttributeSelector
