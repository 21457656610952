import { produce } from 'immer'
import { staticConnectionToMetaMapper as systemActionsMappers } from 'helpers/workflowSystemActions/systemActionsMappers'
import { staticConnectionToMetaMapper as moveCardBlockMappers } from 'helpers/workflowMoveCardBlock/moveCardBlockMappers'
import { staticConnectionToMetaMapper as hideCardSectionMappers } from 'helpers/workflowHideCardSectionBlock/workflowHideCardSectionBlockMappers'
import { staticConnectionToMetaMapper as defineStringBlocksMappers } from 'helpers/workflowDefineStringBlocks/defineStringBlocksMapper'
import { staticConnectionToMetaMapper as widgetDataBlocksMappers } from 'helpers/workflowWidgetDataBlock/workflowWidgetDataBlockMapper'
import { OBJECT_TYPES } from 'constants/workflows'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { EWorkflowBlockTypes } from 'constants/workflowBuilder/blocksTypes'
import { EFinishBlockValues } from 'components/workflowBuilder/finishBlocks/finishBlock.types'
import { BLOCKS_DEFINITION } from 'features/workflow/workflowBuilder/model/workflowBuilder.constants'
import { addBlockIndex, addBlockToMap } from 'helpers/workflowBuilder/blocksOperations'
import {
  addBlockToHistory,
  updateOutputsHistory
} from 'helpers/workflowBuilder/outputsHistoryOperations'
import {
  addDefaultOutputsNames,
  changeOutputNamesFromInput,
  changeOutputNamesFromMeta
} from 'helpers/workflowBuilder/outputsNamesHelpers'
import { addOutputs, getInitialOutputs } from 'helpers/workflowBuilder/outputsOperations'
import { addOutputStopper } from 'helpers/workflowBuilder/outputsStoppersHelpers'

const {
  MOVE_CARD,
  TRIGGER,
  SEND_EMAIL,
  HIDE_CARD_SECTION,
  DEFINE_STRING_FROM_BOARD,
  DEFINE_STRING_FROM_CARD,
  DEFINE_STRING_FROM_WIDGET,
  DEFINE_STRING_FROM_USER,
  DEFINE_STRING_FROM_COLUMN,
  DEFINE_STRING_FROM_JSON_PAYLOAD,
  PUBLISH_FEED_NOTIFICATION,
  GET_WIDGET_DATA,
  UPDATE_WIDGET_DATA,
  REST_API_CLIENT
} = EWorkflowBlockTypes

const mapInputDefinition = (component, defaultInputDefinition) => {
  if (component.component === EWorkflowBlockTypes.FINISH) {
    return produce(defaultInputDefinition, draft => {
      draft[EFieldKeys.SOURCE_INPUT].type =
        component.metadata.meta.type === EFinishBlockValues.DISPLAY_BOARD
          ? OBJECT_TYPES.BOARD
          : OBJECT_TYPES.CARD
    })
  }

  return defaultInputDefinition
}

// from component to block
const componentMapper = component => {
  const {
    component: type,
    metadata: { id, input, meta = {}, output, x: index },
    blockMetadata
  } = component

  const block = {
    ...BLOCKS_DEFINITION[type],
    id,
    index,
    input,
    inputDefinition: mapInputDefinition(component, BLOCKS_DEFINITION[type].inputDefinition),
    meta,
    output,
    error: {},
    warn: {},
    blockMetadata
  }

  return {
    block,
    // some blocks (such rename) just modify inputs so they don't have outputs
    blockOutputs: block.isCreateOutput ? output : []
  }
}

// from trigger to block
const triggerMapper = workflow => {
  const {
    triggerId,
    filterUuid,
    eventType,
    eventObject,
    metadata: { output },
    scheduleSettings
  } = workflow

  const block = {
    ...BLOCKS_DEFINITION[TRIGGER],
    id: triggerId,
    index: 0,
    output,
    meta: {
      filterUuid,
      eventType,
      eventObject
    },
    scheduleSettings
  }

  return {
    block,
    blockOutputs: output
  }
}

// some blocks may require data transforming when mapping from static connection to meta
const getMappedMetaField = (block, inputName, data) => {
  switch (block.type) {
    case MOVE_CARD: {
      return moveCardBlockMappers(inputName, data)
    }
    case HIDE_CARD_SECTION: {
      return hideCardSectionMappers(inputName, data)
    }
    case DEFINE_STRING_FROM_BOARD:
    case DEFINE_STRING_FROM_CARD:
    case DEFINE_STRING_FROM_WIDGET:
    case DEFINE_STRING_FROM_USER:
    case DEFINE_STRING_FROM_COLUMN:
    case DEFINE_STRING_FROM_JSON_PAYLOAD:
      return defineStringBlocksMappers(inputName, data)
    case GET_WIDGET_DATA:
    case UPDATE_WIDGET_DATA:
      return widgetDataBlocksMappers(inputName, data)
    case SEND_EMAIL:
    case PUBLISH_FEED_NOTIFICATION:
    case REST_API_CLIENT: {
      return systemActionsMappers(inputName, data)
    }
    default: {
      return data
    }
  }
}

export default class FlowToStateMapper {
  constructor() {
    this.workflowDefinition = {}

    this.workflowBlocks = []
    this.workflowBlocksMap = {}
    this.outputs = getInitialOutputs()
    this.outputsHistory = []
    this.outputsNames = {}
    this.outputsStoppers = {}
  }

  getData() {
    return {
      workflowDefinition: this.workflowDefinition,
      workflowBlocks: this.workflowBlocks,
      workflowBlocksMap: this.workflowBlocksMap,
      outputs: this.outputs,
      outputsHistory: this.outputsHistory,
      outputsNames: this.outputsNames,
      outputsStoppers: this.outputsStoppers
    }
  }

  setWorkflowDefinition(workflow) {
    this.workflowDefinition = workflow
  }

  createBlockFromComponent(workflow) {
    const { block, blockOutputs } = workflow.component
      ? componentMapper(workflow)
      : // trigger don't have type
        triggerMapper(workflow)

    // add block index
    this.workflowBlocks = addBlockIndex({
      workflowBlocks: this.workflowBlocks,
      index: block.index,
      blockId: block.id
    })

    this.workflowBlocksMap = addBlockToMap({
      workflowBlocksMap: this.workflowBlocksMap,
      block
    })

    // update global outputs map if blocks has outputs
    this.outputs = addOutputs({ outputsMap: this.outputs, outputs: blockOutputs })

    // set global outputs history for each block output (if exists)
    this.outputsHistory = updateOutputsHistory({
      outputsHistory: this.outputsHistory,
      outputs: blockOutputs,
      blockId: block.id
    })
  }

  // update data of block static inputs
  processStaticConnection(connection) {
    const {
      tgt: { process: blockId, port: inputName },
      data
    } = connection

    const block = this.workflowBlocksMap[blockId]

    this.workflowBlocksMap[blockId].meta = {
      ...block.meta,
      [inputName]: getMappedMetaField(block, inputName, data)
    }
  }

  // update data of block inputs and update history
  // if input can update history (i.e. rename block)
  processComponentsConnection(connection) {
    const {
      tgt: { process: blockId, port: inputName },
      metadata: { outputId }
    } = connection

    this.workflowBlocksMap[blockId].input = {
      ...this.workflowBlocksMap[blockId].input,
      [inputName]: outputId
    }

    const inputDefinition = this.workflowBlocksMap[blockId].inputDefinition[inputName]

    if (inputDefinition?.canUpdateHistory) {
      this.outputsHistory = addBlockToHistory({
        workflowBlocksMap: this.workflowBlocksMap,
        outputsHistory: this.outputsHistory,
        outputId,
        blockId
      })
    }
  }

  processConnection(connection) {
    const isDynamic = 'src' in connection

    if (isDynamic) {
      this.processComponentsConnection(connection)
    } else {
      this.processStaticConnection(connection)
    }
  }

  fillOutputsNames() {
    const blocks = Object.values(this.workflowBlocksMap)

    blocks.forEach(block => {
      if (block.type === TRIGGER) {
        // trigger block has outputs with hardcoded names
        this.outputsNames = addDefaultOutputsNames({
          outputsNames: this.outputsNames,
          outputs: block.output
        })

        return
      }

      // set outputs names based on blocks static and dynamic inputs
      this.outputsNames = {
        ...this.outputsNames,
        ...changeOutputNamesFromInput(block),
        ...changeOutputNamesFromMeta(block, block.meta)
      }
    })
  }

  fillOutputsStoppers() {
    const blocks = Object.values(this.workflowBlocksMap)

    blocks.forEach(block => {
      if (!block.hasOutputStopper) {
        return
      }

      // mark output which selected as input in delete block as stopped
      this.outputsStoppers = addOutputStopper({
        outputsStoppers: this.outputsStoppers,
        blockId: block.id,
        // input is mandatory, so it should be defined on this step
        outputId: block.input[EFieldKeys.SOURCE_INPUT]
      })
    })
  }

  convert({ workflow, flow }) {
    const { processes, connections } = flow

    this.setWorkflowDefinition(workflow)

    this.createBlockFromComponent(workflow)

    Object.values(processes).forEach(component => {
      if (component.component === TRIGGER) {
        // trigger component saved to flow only for BE purposes
        // all required info for trigger block we got above from trigger object
        return
      }

      this.createBlockFromComponent(component)
    })

    connections.forEach(connection => this.processConnection(connection))

    this.fillOutputsNames()
    this.fillOutputsStoppers()

    return this.getData()
  }
}
