import React from 'react'

import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'

import SetCardOwnerContainer from '../../../containers/workflowBuilder/cardOwnerBlocks/SetCardOwnerContainer'

const SetCardOwner = props => {
  const { block, updateWorkflowBlock, changeWorkflowBlockInput } = props

  return (
    <SetCardOwnerContainer
      block={block}
      sourceInputType={OBJECT_TYPES.CARD}
      sourceInputFieldLabel={messages.CARD_LEVEL_INPUT}
      sourceInputFieldPlaceholder={messages.CARD_LEVEL_INPUT_PLACEHOLDER}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
    />
  )
}

export default SetCardOwner
