import { useMemo } from 'react'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import CreateBoardButton from 'components/boardMenu/CreateBoardButton'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import { CONVERT_DATA_INPUT_COUNT } from 'components/workflowBuilder/widgetDataBlocks/convertWidgetData/convertWidgetData.constants'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'

const MAX_INPUTS_COUNT = 1

type TProps = {
  block: IWorkflowBlock
  handleDeleteInput: (index: number) => void
  handleInputChange: (index: number, item: IWorkflowDropdownValue) => void
  handleReset: (index: number) => void
  handleAddInput: () => void
}

export const ConvertWidgetDataBlockInputs = ({
  block,
  handleDeleteInput,
  handleInputChange,
  handleReset,
  handleAddInput
}: TProps) => {
  const { meta, id, error, input } = block

  const params = meta[EFieldKeys.PARAMS] as string[]

  const list = useMemo(
    () =>
      params.map((_: string, index: number) => {
        const param = params[index]

        return (
          <div key={`${String(param)}-${String(index)}`} className="block-input-item">
            <div className="block-input-item-label">
              <span>{`A${index + 1}`}</span>
            </div>
            <div className="input-container">
              <BlockInputContainer
                supportedTypes={[OBJECT_TYPES.STRING, OBJECT_TYPES.WIDGET_DATA]}
                blockId={id}
                value={param ? input[param] : null}
                multiTypesSupported
              >
                {({
                  selectedItem,
                  items,
                  searchItems
                }: {
                  selectedItem?: IWorkflowDropdownValue
                  items: IWorkflowDropdownValue[]
                  searchItems: (search: string) => void
                }) => (
                  <AnyLevelDropdown
                    error={error[index]}
                    items={items}
                    searchItems={searchItems}
                    value={selectedItem}
                    type={selectedItem?.type}
                    placeholder={messages.CONVERT_DATA_BLOCK_INPUT_PLACEHOLDER}
                    supportHistory
                    onReset={() => handleReset(index)}
                    onSelect={(item: IWorkflowDropdownValue) => handleInputChange(index, item)}
                  />
                )}
              </BlockInputContainer>
            </div>
            {index >= MAX_INPUTS_COUNT && (
              <div className="block-input-item-remove" onClick={() => handleDeleteInput(index)}>
                <i className="icon up-font-ic-cross-16" />
              </div>
            )}
          </div>
        )
      }),
    [params, error, handleDeleteInput, handleInputChange, handleReset, id, input]
  )

  return (
    <div className="block-inputs-list">
      {list}
      {params.length < CONVERT_DATA_INPUT_COUNT && (
        <CreateBoardButton
          className="inline-create-board-button"
          text={messages.ADD_INPUT}
          onClick={handleAddInput}
        />
      )}
    </div>
  )
}
