import React from 'react'
import FindBoardContainer from '../../../containers/workflowBuilder/findBlocks/FindBoardContainer'

const FindBoard = props => {
  const { block, changeWorkflowBlockInput, updateWorkflowBlock } = props

  return (
    <FindBoardContainer
      block={block}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
    />
  )
}

export default FindBoard
