import cn from 'classnames'
import messages from 'constants/messages'
import CircleSpinner from 'components/spinners/CircleSpinner'
import { type IJob, EJobStatuses } from 'features/jobs/jobs.types'
import { AIProgressMessage } from './aiProgressMessage/aiProgressMessage'
import { WFBlockName } from './wfBlockName/wfBlockName'

type TProps = { job: IJob }

export const WorkflowExecutionIndicatorItem = ({ job }: TProps) => {
  const isDone = job.status === EJobStatuses.DONE
  const isError = job.status === EJobStatuses.ERROR
  const isInProgress = !isDone && !isError

  const { message, customComponentName, name, componentName } = job.data

  return (
    <div className="board_item">
      <div className="board_data">
        <i
          className={cn('icon', {
            'icon-workflow': !isDone,
            'icon-workflow-colored': isDone
          })}
        />
        <div
          className={cn('board_content', {
            'finished-import': isDone,
            'failed-import': isError
          })}
        >
          <span className="board_name bold">{name}</span>
          {!!componentName && isInProgress && (
            <WFBlockName customName={customComponentName} blockType={componentName} />
          )}
          {!!message && isInProgress && <AIProgressMessage message={message} />}
        </div>
      </div>
      <span className="board_progress">
        {!isDone && !isError && <CircleSpinner className="copied-board__spinner" />}
        {isDone && <span className="blue-link static">{messages.FINISHED}</span>}
        {isError && <span className="blue-link static failed">{messages.FAILED}</span>}
      </span>
    </div>
  )
}
