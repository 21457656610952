import { Component } from 'react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { CARD_OWNER_UNSET } from 'helpers/workflowCardOwnerBlocks/workflowCardOwnerBlocksHelpers'
import SetCardOwnerBlock from 'components/workflowBuilder/cardOwnerBlocks/SetCardOwnerBlock'
import SetCardOwnerOptions from 'components/workflowBuilder/cardOwnerBlocks/SetCardOwnerOptions'

export default class SetCardOwnerContainer extends Component {
  handleInputChange = (fieldName, item) => {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset = fieldName => {
    this.handleInputChange(fieldName, { id: null })
  }

  updateBlockMeta = (key, fieldValue) => {
    const {
      updateWorkflowBlock,
      block: { id, meta }
    } = this.props

    if (meta[key] !== fieldValue) {
      updateWorkflowBlock({
        id,
        data: {
          meta: { ...meta, [key]: fieldValue },
          error: {}
        }
      })
    }
  }

  changeSelectOption = (propName, { value }) => {
    if (value === CARD_OWNER_UNSET) this.handleReset(EFieldKeys.USER_INPUT)
    this.updateBlockMeta(propName, value)
  }

  render() {
    const { sourceInputType, sourceInputFieldLabel, sourceInputFieldPlaceholder, block } =
      this.props
    const meta = block.meta || {}

    return (
      <SetCardOwnerBlock
        inputType={sourceInputType}
        inputFieldLabel={sourceInputFieldLabel}
        inputFieldPlaceholder={sourceInputFieldPlaceholder}
        handleReset={this.handleReset}
        handleInputChange={this.handleInputChange}
        block={block}
      >
        <SetCardOwnerOptions
          type={meta[EFieldKeys.TYPE]}
          block={block}
          handleReset={this.handleReset}
          handleInputChange={this.handleInputChange}
          changeSelectOption={this.changeSelectOption}
        />
      </SetCardOwnerBlock>
    )
  }
}
