// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.3.0
//   protoc               v3.19.1
// source: common/key.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../google/protobuf/timestamp.pb";

export const protobufPackage = "upboard.io.common";

/**
 * Scope defines data partitioning and data storage location.
 * Used as part of keys and in some requests.
 *
 * Each service determines the level of a *_id fields to be set,
 * as different services might require different hierarchy scope.
 * Service which using scopes has to validate passed scope level,
 * to ensure that scope is valid (defines least minimal level).
 * It's not prohibited to pass fully define scopes to a services
 * requiring less defined scopes. Basically you can pass card level defined
 * scope (all of the fields down to card_id are set) to services
 * which requires board level defined scope (all of the fields down
 * to board_id are set).
 *
 * Scopes should not be used for direct entities identification.
 * So, if the key directly identifies entity placed on level N in the
 * hierarchy, then scope always points to level N-1 in the same hierarchy.
 */
export interface Scope {
  organizationId: string;
  tenantId: string;
  boardId: string;
  /** PK to Card entity. In old messages can be written cardUuid value */
  cardId: string;
  widgetId: string;
  /** Identification to the same card in different states: draft or active */
  cardUuid: string;
}

/**
 * Key identifies entity.
 *
 * Incomplete key is a key which has set scope, but doesn't set a valid id.
 * Complete key is a key which has set scope and id.
 * Basically complete key identifies entity, while incomplete not, but might be used
 * by service to store the allocated id when RPC done.
 */
export interface Key {
  id: string;
  scope:
    | Scope
    | undefined;
  /**
   * version represents entity version which this key is referncing
   * When returned from the service is always populated
   * On incoming requests is optional
   */
  version: number;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
}

function createBaseScope(): Scope {
  return { organizationId: "", tenantId: "", boardId: "", cardId: "", widgetId: "", cardUuid: "" };
}

export const Scope: MessageFns<Scope> = {
  encode(message: Scope, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.organizationId !== "") {
      writer.uint32(10).string(message.organizationId);
    }
    if (message.tenantId !== "") {
      writer.uint32(18).string(message.tenantId);
    }
    if (message.boardId !== "") {
      writer.uint32(26).string(message.boardId);
    }
    if (message.cardId !== "") {
      writer.uint32(34).string(message.cardId);
    }
    if (message.widgetId !== "") {
      writer.uint32(42).string(message.widgetId);
    }
    if (message.cardUuid !== "") {
      writer.uint32(50).string(message.cardUuid);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Scope {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseScope();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.organizationId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.boardId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.cardId = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.widgetId = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.cardUuid = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Scope>, I>>(base?: I): Scope {
    return Scope.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Scope>, I>>(object: I): Scope {
    const message = createBaseScope();
    message.organizationId = object.organizationId ?? "";
    message.tenantId = object.tenantId ?? "";
    message.boardId = object.boardId ?? "";
    message.cardId = object.cardId ?? "";
    message.widgetId = object.widgetId ?? "";
    message.cardUuid = object.cardUuid ?? "";
    return message;
  },
};

function createBaseKey(): Key {
  return { id: "", scope: undefined, version: 0, createdAt: undefined, updatedAt: undefined };
}

export const Key: MessageFns<Key> = {
  encode(message: Key, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.scope !== undefined) {
      Scope.encode(message.scope, writer.uint32(18).fork()).join();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(34).fork()).join();
    }
    if (message.updatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedAt), writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Key {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.scope = Scope.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.updatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Key>, I>>(base?: I): Key {
    return Key.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Key>, I>>(object: I): Key {
    const message = createBaseKey();
    message.id = object.id ?? "";
    message.scope = (object.scope !== undefined && object.scope !== null) ? Scope.fromPartial(object.scope) : undefined;
    message.version = object.version ?? 0;
    message.createdAt = object.createdAt ?? undefined;
    message.updatedAt = object.updatedAt ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
