import { Component } from 'react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import RenameBlock from 'components/workflowBuilder/renameBlocks/RenameBlock'
import RenameOptions from 'components/workflowBuilder/renameBlocks/RenameOptions'

export default class RenameBlockContainer extends Component {
  state = {
    name: this.props.block.meta[EFieldKeys.NAME]
  }

  handleInputChange = (fieldName, item) => {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset = fieldName => {
    this.handleInputChange(fieldName, { id: null })
  }

  changeSelectOption = (propName, { value }) => {
    this.updateBlockMeta(propName, value)
  }

  changeCustomName = event => {
    const { value } = event.target

    this.setState({ name: value })
  }

  blurCustomName = event => {
    const { value } = event.target
    this.updateBlockMeta(EFieldKeys.NAME, value.trim())
  }

  updateBlockMeta = (key, fieldValue) => {
    const {
      updateWorkflowBlock,
      block: { id, meta }
    } = this.props

    if (meta[key] !== fieldValue) {
      updateWorkflowBlock({
        id,
        data: {
          meta: { ...meta, [key]: fieldValue },
          error: {}
        }
      })
    }
  }

  render() {
    const { name } = this.state
    const { sourceInputType, sourceInputFieldLabel, sourceInputFieldPlaceholder, block } =
      this.props
    const meta = block.meta || {}

    return (
      <RenameBlock
        inputType={sourceInputType}
        inputFieldLabel={sourceInputFieldLabel}
        inputFieldPlaceholder={sourceInputFieldPlaceholder}
        handleReset={this.handleReset}
        handleInputChange={this.handleInputChange}
        block={block}
      >
        <RenameOptions
          type={meta[EFieldKeys.TYPE]}
          inputType={sourceInputType}
          block={block}
          customName={name}
          textPosition={meta[EFieldKeys.TEXT_POSITION]}
          handleReset={this.handleReset}
          changeCustomName={this.changeCustomName}
          blurCustomName={this.blurCustomName}
          handleInputChange={this.handleInputChange}
          changeSelectOption={this.changeSelectOption}
        />
      </RenameBlock>
    )
  }
}
