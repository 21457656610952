import { type IUser } from '../tasks/tasks.types'

export interface ITeamPermissions {
  accessCardBuilder: boolean
  addCardFollower: boolean
  addTeamMember: boolean
  copyBoard: boolean
  copyCard: boolean
  createApp: boolean
  createBoard: boolean
  createCard: boolean
  createCardComment: boolean
  createCredential: boolean
  createDataBlock: boolean
  createDictionary: boolean
  createFilter: boolean
  createTenant: boolean
  createVariable: boolean
  createWidget: boolean
  createWorkflow: boolean
  deleteApp: boolean
  deleteBoard: boolean
  deleteCard: boolean
  deleteCardComment: boolean
  deleteCardCommentAsAdmin: boolean
  deleteCardFollower: boolean
  deleteCredential: boolean
  deleteDataBlock: boolean
  deleteDictionary: boolean
  deleteFilter: boolean
  deleteOrganization: boolean
  deleteOrganizationMember: boolean
  deleteTeamMember: boolean
  deleteTenant: boolean
  deleteVariable: boolean
  deleteWidget: boolean
  deleteWorkflow: boolean
  downloadAppTemplate: boolean
  editTeamMemberRole: boolean
  executeWorkflow: boolean
  exportApp: boolean
  exportBundle: boolean
  followCard: boolean
  hideColumn: boolean
  importBundle: boolean
  isAppstoreEnabled: boolean
  makeBoardPublic: boolean
  manageAppstoreInfo: boolean
  manageOrganization: boolean
  readAllOrganizations: boolean
  readBoard: boolean
  readCard: boolean
  readCardComment: boolean
  readCredential: boolean
  readDataBlock: boolean
  readDictionary: boolean
  readFilter: boolean
  readMenuWidgets: boolean
  readOrganization: boolean
  readOrganizationMembers: boolean
  readSSOConfigs: boolean
  readTeamLog: boolean
  readTeamMembers: boolean
  readTenant: boolean
  readVariable: boolean
  readWidget: boolean
  readWorkflow: boolean
  renameCard: boolean
  setIsAutoRefresh3rdPartyDataSourcesToggle: boolean
  updateApp: boolean
  updateBoard: boolean
  updateBoardSettings: boolean
  updateCard: boolean
  updateCardComment: boolean
  updateCredential: boolean
  updateDataBlock: boolean
  updateDictionary: boolean
  updateFilter: boolean
  updateSSOConfigs: boolean
  updateTenant: boolean
  updateVariable: boolean
  updateWidget: boolean
  updateWorkflow: boolean
}

export type TTeamRole =
  | 'OrganizationAdmin'
  | 'Admin'
  | 'Editor'
  | 'BoardAdmin'
  | 'Collaborator'
  | 'BoardViewer'

export enum ETeamRole {
  WorkspaceAdmin = 'OrganizationAdmin',
  TeamAdmin = 'Admin',
  BoardManager = 'Editor',
  CardBuilder = 'BoardAdmin',
  Collaborator = 'Collaborator',
  Viewer = 'BoardViewer'
}

export interface ITeam {
  accessInfo: {
    permissions: ITeamPermissions
    role: string
    userId: string
  }
  name: string
  organizationId: string
  tenantId: string
  usersNumber: number
}

export interface ITeamMemberACLTeam {
  role: ETeamRole
  isInactive: boolean
}

export interface ITeamMemberACLBoard {
  boardId: string
  name: string
  role: string
}

export interface ITeamMemberACLWorkspace {
  role: string
}

export interface ITeamMemberACL {
  tenant: ITeamMemberACLTeam
  boards: ITeamMemberACLBoard[]
  organization: ITeamMemberACLWorkspace
}

export interface ITeamMember extends IUser {
  tenantId: string
  acl: ITeamMemberACL
}

export type TTeamMemberMap = Record<ITeamMember['userId'], ITeamMember>
