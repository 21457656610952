import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getFieldNamesItems } from '../../../../helpers/workflowBuilder/inputOperations'
import TextButton from '../../../buttons/TextButton'
import messages from '../../../../constants/messages'
import StringInputItem from './StringInputItem'
import '../../../../scss/workflowBuilder/common/StringInputsList/string-inputs-list.scss'

class StringInputsList extends Component {
  getActiveFieldNamesItems() {
    const {
      block: { inputDefinition },
      fieldName,
      inputsCount
    } = this.props
    const names = getFieldNamesItems(fieldName, inputDefinition)

    const activeNames = names.filter((name, index) => index + 1 <= inputsCount)
    return activeNames
  }

  render() {
    const {
      block,
      onAddRecipient,
      onDeleteRecipient,
      handleInputChange,
      handleReset,
      addButtonLabel,
      staticInputsCount,
      className,
      inputFieldPlaceholder,
      inputsCount,
      maxInputsCount
    } = this.props

    const activeNames = this.getActiveFieldNamesItems()

    const items = activeNames || null
    return (
      <div className={`string-inputs-list ${className}`}>
        {items.map((item, index) => (
          <StringInputItem
            key={item}
            block={block}
            fieldName={item}
            onDelete={onDeleteRecipient}
            handleInputChange={handleInputChange}
            handleReset={handleReset}
            isStaticInput={index < staticInputsCount}
            inputFieldPlaceholder={inputFieldPlaceholder}
          />
        ))}
        <TextButton
          className="add-button"
          size="small"
          onClick={onAddRecipient}
          disabled={maxInputsCount === inputsCount}
        >
          <span>{addButtonLabel}</span>
        </TextButton>
      </div>
    )
  }
}

StringInputsList.defaultProps = {
  block: {},
  className: '',
  inputsCount: 0,
  staticInputsCount: 0,
  addButtonLabel: messages.ADD_EMAIL_STRING,
  inputFieldPlaceholder: messages.EMAIL_LEVEL_INPUT_PLACEHOLDER,
  maxInputsCount: 5
}

StringInputsList.propTypes = {
  inputsCount: PropTypes.number,
  staticInputsCount: PropTypes.number,
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  onAddRecipient: PropTypes.func.isRequired,
  onDeleteRecipient: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  block: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }),
  addButtonLabel: PropTypes.string,
  inputFieldPlaceholder: PropTypes.string,
  maxInputsCount: PropTypes.number
}

export default StringInputsList
