import { useId } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import { changeWorkflowBlockInput, updateWorkflowBlock } from 'actions/workflowActions'
import { mapSelectedValuesFromLabel } from 'helpers/fields/selectHelpers'
import FormField from 'components/common/FormField'
import RadioField from 'components/common/RadioField'
import MultiCreatableSelect from 'components/fields/MultiCreatableSelect'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import { DropdownInput } from '../../common/dropdownInput/dropdownInput'
import { MAX_CARD_ACTION_COUNT } from '../model/hideCardActionBlock.constants'
import classes from './hide-card-action-block.module.scss'

type TMeta = {
  [EFieldKeys.ACTIONS]: Array<{ name: string; hide: boolean }>
  [EFieldKeys.HIDE]: boolean
}

type TProps = {
  tenantId: string
  block: IWorkflowBlock<TMeta>
  signedInUserId: string
  invalid: boolean
}

export const HideCardActionBlock = ({ block }: TProps) => {
  const hideActionToggleId = useId()
  const showActionToggleId = useId()

  const { id, meta, input, error } = block

  const dispatch = useDispatch()

  const updateWorkflowBlockMeta = (newMeta: Partial<IWorkflowBlock['meta']>) => {
    const updatedMeta = { ...meta, ...newMeta }

    // @ts-expect-error
    dispatch(updateWorkflowBlock({ id, data: { meta: updatedMeta, error: {} } }))
  }

  const handleInputChange = (
    key: EFieldKeys.SOURCE_INPUT,
    item: Partial<IWorkflowDropdownValue> | null
  ) => {
    dispatch(
      // @ts-expect-error
      changeWorkflowBlockInput({
        fieldName: key,
        blockId: id,
        outputId: item ? item.id : null,
        oldOutputId: input[key] || null,
        error
      })
    )
  }

  const handleSelect = (
    key: EFieldKeys.SOURCE_INPUT,
    item: Partial<IWorkflowDropdownValue> | null
  ) => {
    handleInputChange(key, item)
  }

  const handleCardActionListChange = (values: Array<{ label: string; value: string }>) => {
    updateWorkflowBlockMeta({ [EFieldKeys.ACTIONS]: values.map(({ value }) => value) })
  }

  const handleVisibilityToggle = (shouldHide: boolean) => {
    updateWorkflowBlockMeta({ [EFieldKeys.HIDE]: shouldHide })
  }

  const cardActionsSelectError = error[EFieldKeys.ACTIONS] as string | undefined
  const isCardSelected = !!input[EFieldKeys.SOURCE_INPUT]
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const isMaxTokenCountReached = meta[EFieldKeys.ACTIONS].length >= MAX_CARD_ACTION_COUNT

  return (
    <div className={classes.wfBuilderHideCardActionBlock}>
      <DropdownInput
        block={block}
        type={OBJECT_TYPES.CARD}
        fieldName={EFieldKeys.SOURCE_INPUT}
        title="Card Input"
        placeholder="Select Card output from prior steps as the input"
        onSelect={(option: Partial<IWorkflowDropdownValue> | null) =>
          handleSelect(EFieldKeys.SOURCE_INPUT, option)
        }
        onReset={() => handleSelect(EFieldKeys.SOURCE_INPUT, null)}
      />
      <FormField
        label="Action(s)"
        error={cardActionsSelectError}
        hintText={isMaxTokenCountReached && 'You have reached the limit of actions'}
      >
        <MultiCreatableSelect
          className={classNames('card-actions-select', !!cardActionsSelectError && 'error')}
          size="large"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          values={mapSelectedValuesFromLabel(meta[EFieldKeys.ACTIONS])}
          placeholder="Type and press enter to add"
          isValidNewOption={() => !isMaxTokenCountReached}
          isDisabled={!isCardSelected}
          onChange={handleCardActionListChange}
        />
      </FormField>
      <div className={classes.toggle}>
        <RadioField
          id={hideActionToggleId}
          label="Hide action(s)"
          checked={meta[EFieldKeys.HIDE]}
          disabled={!isCardSelected}
          onChange={() => handleVisibilityToggle(true)}
        />
        <RadioField
          id={showActionToggleId}
          label="Unhide action(s)"
          checked={!meta[EFieldKeys.HIDE]}
          disabled={!isCardSelected}
          onChange={() => handleVisibilityToggle(false)}
        />
      </div>
    </div>
  )
}
