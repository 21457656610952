import { requestWrapper } from 'helpers/fetchHelpers'

const url = import.meta.env.VITE_PROMPT_TEMPLATE_URL

// prompt template list
export function processGetPromptTemplateList() {
  return requestWrapper({
    rUrl: `${url}template`,
    type: 'GET',
    payload: {}
  })
}

// prompt template
export function processGetPromptTemplate({ id }) {
  return requestWrapper({
    rUrl: `${url}template/${id}`,
    type: 'GET',
    payload: {}
  })
}
