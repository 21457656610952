import { type IAppStoreConfig } from 'constants/appStore/appStore.types'
import { requestWrapper } from 'helpers/fetchHelpers'

type TRequestResponse<T> = { data: T }

export class _AppStoreService {
  private get _baseUrl() {
    return `${import.meta.env.VITE_BACKEND_URL}api/`
  }

  private _request = <TResponse = void>(method: string, path: string, payload?: unknown) => {
    return requestWrapper({
      rUrl: `${this._baseUrl}${path}`,
      type: method,
      payload
    }) as Promise<TRequestResponse<TResponse>>
  }

  getAppStoreConfig = () => {
    return this._request<IAppStoreConfig>('GET', 'app-store/configs')
  }

  sendEmailToAdmin = (appId: string) => {
    return this._request('POST', `appstore/${appId}/admin/notification`)
  }

  sendEmailToAdminCollection = (collectionId: string) => {
    return this._request('POST', `appstore/collection/${collectionId}/admin/notification`)
  }
}

export const AppStoreService = new _AppStoreService()
