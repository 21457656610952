export const REACT_ROOT_DOM_ID = 'root'

export const MAX_WIDGET_COUNT = 300

export const IMPORT_BUNDLE_DROPZONE_ID = 'import-bundle-dropzone'

export const ICONS_PATH = '/assets/images/icons'

export const BUY_WORKSPACE_SUBSCRIPTION_URL = 'https://praxie.com/pricing/'

// 00 - Demo Space.
// Do not remove, might be needed to hide branding for.
export const PROD_DEMO_ORG_ID = '7c68eb3e-abf1-4996-8378-bced54fb9af7'

export const TODAY_FORMULA = 'TODAY()'
export const UPDATE_FREQUENCY_IN_HOURS = 2

// Synced up with breakpoints from `variables.scss`.
// Prefer using CSS media queries for performance.
// Use only if you want to hide big components on JS level to conditionally render components.
// These breakpoints do not support REM values like `variables.scss` does.
export const RESPONSIVE_BREAKPOINTS = {
  SM: 640,
  MD: 768,
  LG: 1024,
  XL: 1280,
  '2XL': 1536
} as const

export enum EHttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export enum ERequestHeaders {
  ContentLength = 'Content-Length',
  Host = 'Host',
  UserAgent = 'User-Agent',
  Accept = 'Accept',
  AcceptEncoding = 'Accept-Encoding',
  ContentType = 'Content-Type'
}

export enum EKeyCode {
  LEFT_ARROW = 'ArrowLeft',
  UP_ARROW = 'ArrowUp',
  RIGHT_ARROW = 'ArrowRight',
  DOWN_ARROW = 'ArrowDown',
  TAB = 'Tab',
  DEL = 'Delete',
  BACKSPACE = 'Backspace',
  LEFT_BRACKET = '[',
  SPACE = ' ',
  D = 'D',
  F = 'F',
  A = 'A',
  Q = 'Q',
  S = 'S',
  ENTER = 'Enter'
}

export enum EDataType {
  text = 'upboard.text',
  numeric = 'upboard.numeric',
  hyperLink = 'upboard.hyperLink',
  smartLink = 'upboard.smartLink',
  user = 'upboard.user',
  file = 'upboard.file'
}

export const DEV_NEW_BOARD_MENU_ORG_ID_SET = new Set([
  '63802627-fd2e-4f24-81c9-8e6670c54d06',
  '44d18102-1343-4bc6-b831-50eedfe19939',
  'a786a63a-fe41-460e-bdb1-92ac1f2fc681',
  'be70d80f-ca0a-4e95-a7f9-3835665d9aa6',
  '5ba982b9-a5d8-4d63-9953-ea11b8a11d9d',
  '80b25f9c-5bbc-41cd-8005-b6de95b6aeaa',
  'f731aacf-42ed-48fa-adc8-2189847cf05e',
  '44b4cdda-f9e4-4fc9-b413-06fbfeb9f9a4',
  '2dd854a3-962b-4e77-9950-1b8fee3d4951',
  'aa675b53-48d6-4e15-bbe8-30f0b64ab338',
  'af88aa98-75f5-41d2-8687-2eebd3debe28',
  '0cd86dd9-b506-458c-8227-fdc7c710bece',
  'e69a0ea5-af99-49a1-9d50-ab09b2823572',
  'cda23b51-220c-4a8c-a940-9f5f7e3d44df'
])

export const STAGE_NEW_BOARD_MENU_ORG_ID_SET = new Set([
  '93b0b1b7-228b-4beb-93bc-62b71f975d3e',
  'fb199003-f27a-4c67-8895-ad40d1403403',
  'e7e83404-3157-4270-89b2-fc65ae421cf2',
  '294d9756-a062-4a6e-97ad-604655052e53',
  'c063670d-923e-48b1-aa30-fe320fed368e',
  '87c35d41-8a02-4389-817f-719e81263d65',
  '22a6b814-082e-400f-8604-d7c456e4b9b2',
  'c78b6b6a-ed88-4bd9-9e95-7cbbd78e4aa0',
  '4f3029db-0456-410c-bf28-0e8515a3f958',
  'b53df42f-b496-406b-94e7-373ca82f8acd',
  'd7955714-91d0-4e45-ab30-918c6462f089'
])

export const PROD_NEW_BOARD_MENU_ORG_ID_SET = new Set([
  'b42ec050-b809-4d3d-bacd-8195cbf6804c',
  '7c68eb3e-abf1-4996-8378-bced54fb9af7',
  '82ff8a61-8c0d-497e-9e05-c51f31499537',
  'a7261637-1ab6-4e85-8f66-c16f9f163996',
  '7fffdbf9-26b9-4808-ba54-6b36b3563897',
  'df26ae42-3fd9-42fd-b22b-a09781d53253'
])
