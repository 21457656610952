import classNames from 'classnames'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { getSelectedOptionByKey, mapSelectedValuesFromLabel } from 'helpers/fields/selectHelpers'
import {
  RULES,
  PREDICATES_OPTIONS,
  PREDICATES_OPTIONS_LIST,
  BOARD_INPUT_COUNT
} from 'helpers/workflowFindBlock/workflowFindBloksHelpers'
import FormField from 'components/common/FormField'
import MultiCreatableSelect from 'components/fields/MultiCreatableSelect'
import ReactSelect from 'components/fields/ReactSelect'
import { Switcher } from 'components/common/switcher/switcher'
import { Tooltip } from 'components/common/tooltip/tooltip'
import StringInputsList from '../../common/StringInputsList'
import 'scss/workflowBuilder/findBlocks/find-board-combined-string.scss'

const FindBoardCombinedStringOption = ({
  block,
  block: { meta, error },
  toggleRule,
  changeSelectOption,
  changeTextInputsList,
  handleReset,
  handleInputChange,
  onAddStringInput,
  onDeleteStringInput
}) => {
  const staticInputsCount = 1
  const isMaxTokensCountReached = meta[EFieldKeys.NAMES].length >= BOARD_INPUT_COUNT
  const stringInputCount = meta[EFieldKeys.COLUMN_NUMBER]
  const isNotValidItem = error[EFieldKeys.NAMES] || error[EFieldKeys.STRING_INPUT]

  return (
    <>
      <div className="find-board-combined-string">
        <div className="find-board-combined-string-predicates">
          <p className="find-board-combined-string-label">{messages.BOARD_NAME}</p>
          <ReactSelect
            className="predicate-select"
            size="small"
            value={getSelectedOptionByKey(meta[EFieldKeys.PREDICATE], PREDICATES_OPTIONS)}
            options={PREDICATES_OPTIONS_LIST}
            isDisabled
            menuPortalEnabled
            onChange={option => changeSelectOption(EFieldKeys.PREDICATE, option)}
          />
        </div>
        <div className="find-board-combined-string-inputs">
          <div className="find-board-combined-string-switcher-wrapper">
            <p className="find-board-combined-string-label">
              {messages.INPUTS}
              <Tooltip
                id="find-board-tooltip"
                className="find-board-tooltip"
                title={messages.INPUTS_TOOLTIP}
                placement="top"
              >
                <i className="icon workflow-block-icon icon-workflow-info" />
              </Tooltip>
            </p>
            <p className="find-board-combined-string-label switcher-label">
              {messages.RULES_LABEL}
            </p>
            <Switcher
              id="find-board-combined-switcher"
              className="find-board-combined-string-switcher"
              checked={meta[EFieldKeys.RULE] === RULES.OR}
              offLabel={messages.OR}
              onChange={toggleRule}
              onLabel={messages.AND}
            />
          </div>
          <FormField
            label={messages.TEXT_INPUTS}
            id="find-board-text-inputs"
            hintText={
              isMaxTokensCountReached
                ? messages.MAX_TEXT_INPUTS_COUNT_REACHED
                : messages.TEXT_INPUTS_HINT
            }
          >
            <MultiCreatableSelect
              className={classNames('find-board-combined-string-names-select', {
                'error-state': error[EFieldKeys.NAMES]
              })}
              size="large"
              placeholder=""
              maxLength={200}
              values={mapSelectedValuesFromLabel(meta[EFieldKeys.NAMES])}
              onChange={changeTextInputsList}
            />
          </FormField>
          <FormField
            label={messages.STRING_LEVEL_INPUT}
            id="find-board-combined-string-input"
            className="find-board-combined-string-input-block"
          >
            <StringInputsList
              block={block}
              fieldName={EFieldKeys.STRING_INPUT}
              addButtonLabel={messages.ADD_BOARD_STRING}
              inputsCount={stringInputCount}
              handleInputChange={handleInputChange}
              handleReset={handleReset}
              staticInputsCount={staticInputsCount}
              inputFieldPlaceholder={messages.STRING_LEVEL_INPUT_PLACEHOLDER}
              onAddRecipient={onAddStringInput}
              onDeleteRecipient={onDeleteStringInput}
            />
          </FormField>
        </div>
      </div>
      <p className="find-board-combined-string-error">
        {isNotValidItem && messages.emptyCombinedString}
      </p>
    </>
  )
}

export default FindBoardCombinedStringOption
