import gql from 'graphql-tag'

import {
  AppFragment,
  AppStoreHistoryFragment,
  AppStoreInfoFragment,
  NavItemFragment,
  PublishedAppFragment,
  PublishedAppsFragment
} from '../fragments/appFragment'

export const getAppQuery = expand => {
  const { app = true, navItems = false } = expand

  const appFragment = app ? AppFragment.fragment : ''
  const navItemsFragment = navItems ? NavItemFragment.fragment : ''

  return gql`
        query getApp ($organizationId: ID!, $tenantId: ID!, $appId: ID!) {
            organization(id: $organizationId) {
                tenant(id: $tenantId) {
                    app(id: $appId) {
                        id
                        showCardName
                        showExportTools
                        showBoardsInMenu
                        allowToEditCardInAppView
                        ${app ? `...${AppFragment.name}` : ''}
                        ${navItems ? `navItems: menu {...${NavItemFragment.name}}` : ''}
                    }
                }
            }
        }
        ${appFragment}
        ${navItemsFragment}
    `
}

export const getAppStoreInfoQuery = expand => {
  const { appStoreInfo = true, history = false } = expand

  const appStoreInfoFragment = appStoreInfo ? AppStoreInfoFragment.fragment : ''
  const historyFragment = history ? AppStoreHistoryFragment.fragment : ''

  return gql`
        query getAppStoreInfo (
            $appId: ID!,
            $historySize: Int = 1
            $versionId: ID = null
       ) {
            appStoreInfo(id: $appId) {
                id
                ${appStoreInfo ? `...${AppStoreInfoFragment.name}` : ''}
                ${
                  history
                    ? `history(id: $versionId, size: $historySize) { ...${AppStoreHistoryFragment.name}}`
                    : ''
                }
            }
        }
        ${appStoreInfoFragment}
        ${historyFragment}
    `
}

export const getPublishedAppQuery = gql`
    query getPublishedApp ($organizationId: ID!, $appId: ID!) {
        organization(id: $organizationId) {
            library(id: $appId) {
                ...${PublishedAppFragment.name}
            }
        }
    }
    ${PublishedAppFragment.fragment}
`

export const getAllCategoriesAppsQuery = gql`
  query getPublishedApps($organizationId: ID!) {
    organization(id: $organizationId) {
      categoriesApps
    }
  }
`

export const getPublishedAppsQuery = gql`
    query getPublishedApps ($organizationId: ID!) {
        organization(id: $organizationId) {
            library {
                ...${PublishedAppsFragment.name}
            }
        }
    }
    ${PublishedAppsFragment.fragment}
`

export const getFilteredCategoryAppsQuery = gql`
  query getPaginatedLibrary(
    $organizationId: ID!
    $filter: LibraryFilter
    $limit: Int
    $after: Int
  ) {
    organization(id: $organizationId) {
      paginatedLibrary(filter: $filter, limit: $limit, after: $after)
    }
  }
`

export const getFilteredCollectionsAppsQuery = gql`
  query getPaginatedCollections(
    $organizationId: ID!
    $filter: CollectionFilter!
    $limit: Int
    $after: Int
  ) {
    organization(id: $organizationId) {
      paginatedCollections(filter: $filter, limit: $limit, after: $after){
        versions {
          ...${PublishedAppsFragment.name}
        }
        total
      }
    }
  }
  ${PublishedAppsFragment.fragment}
`

export const getPurchasedAppsQuery = gql`
  query getPurchasedApps($organizationId: ID!, $filter: PurchasedAppsFilter!) {
    organization(id: $organizationId) {
      purchasedApps(filter: $filter)
    }
  }
`

export const getRelatedAppsQuery = gql`
  query getRelatedApps($organizationId: ID!, $appId: ID!) {
    organization(id: $organizationId) {
      tenants {
        appToAppStoreRel(appStoreInfoId: $appId) {
          app {
            id
            name
            homepage {
              tenantId
              boardId
              cardUuid
              cardOpeningMode
            }
            boardIds
            tenantId
          }
        }
      }
    }
  }
`
