import React from 'react'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

import BlockInputContainer from '../../../containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from '../../common/AnyLevelDropdown'

import '../../../scss/workflowBuilder/renameBlocks/rename-block.scss'

const RenameBlock = props => {
  const {
    children,
    inputType,
    inputFieldLabel,
    inputFieldPlaceholder,
    handleReset,
    handleInputChange,
    block: { id, error, input }
  } = props

  return (
    <div className="rename-block">
      <BlockInputContainer type={inputType} blockId={id} value={input[EFieldKeys.SOURCE_INPUT]}>
        {({ selectedItem, items, searchItems }) => (
          <AnyLevelDropdown
            supportHistory
            title={inputFieldLabel}
            placeholder={inputFieldPlaceholder}
            items={items}
            searchItems={searchItems}
            onSelect={handleInputChange.bind(null, EFieldKeys.SOURCE_INPUT)}
            onReset={handleReset.bind(null, EFieldKeys.SOURCE_INPUT)}
            value={selectedItem}
            type={inputType}
            error={error[EFieldKeys.SOURCE_INPUT]}
          />
        )}
      </BlockInputContainer>
      {children}
    </div>
  )
}

export default RenameBlock
