import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import { getFieldNamesItems } from 'helpers/workflowBuilder/inputOperations'
import { DropdownInput } from 'components/workflowBuilder/common/dropdownInput/dropdownInput'
import {
  type IWorkflowBlock,
  type IWorkflowDropdownValue,
  type IWorkflowScenarioTemplate
} from 'features/workflow/workflow.types'
import './send-prompt-to-ai-block-string-inputs.scss'

type TProps = {
  block: IWorkflowBlock
  currentScenario: IWorkflowScenarioTemplate | null
  handleFieldSelect: (fieldName: string, item?: Partial<IWorkflowDropdownValue> | null) => void
}

export const SendPromptToAIBlockStringInputs = ({
  block,
  currentScenario,
  handleFieldSelect
}: TProps) => {
  const stringFieldNames = getFieldNamesItems(EFieldKeys.STRING_INPUT, block.inputDefinition)

  const getStringInputTitle = (index: number) => {
    if (!currentScenario?.fields) return ''

    return `String input for [${Object.values(currentScenario.fields)[index]}]`
  }

  if (!stringFieldNames.length) return null

  return (
    <div className="workflow-send-prompt-to-ai-string-inputs">
      {stringFieldNames.map((fieldName, index) => (
        <div key={`${fieldName}-${String(index)}`} className="string-input">
          <DropdownInput
            block={block}
            type={OBJECT_TYPES.STRING}
            fieldName={fieldName}
            title={getStringInputTitle(index)}
            placeholder={messages.STRING_LEVEL_INPUT_PLACEHOLDER}
            onSelect={item => handleFieldSelect(fieldName, item)}
            onReset={() => handleFieldSelect(fieldName, null)}
          />
        </div>
      ))}
    </div>
  )
}
