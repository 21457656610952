import React, { Component } from 'react'

import HideCardSectionBlock from '../../../components/workflowBuilder/hideCardSectionBlock/HideCardSectionBlock'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

import { getNewSection } from '../../../helpers/workflowHideCardSectionBlock/workflowHideCardSectionBlockHelpers'
import { changeArrayItemByUuid } from '../../../helpers/filters/conditionsHelpers'

class HideCardSectionBlockContainer extends Component {
  constructor(props) {
    super(props)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.addSection = this.addSection.bind(this)
    this.deleteSection = this.deleteSection.bind(this)
    this.changeSectionNumber = this.changeSectionNumber.bind(this)
    this.toggleSectionVisibility = this.toggleSectionVisibility.bind(this)
  }

  handleInputChange(item) {
    const { block } = this.props

    const fieldName = EFieldKeys.SOURCE_INPUT

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset() {
    this.handleInputChange({ id: null })
  }

  updateWorkflowBlock(data) {
    const { block } = this.props

    const meta = block.meta || {}

    this.props.updateWorkflowBlock({
      id: block.id,
      data: {
        meta: {
          ...meta,
          ...data
        },
        error: {}
      }
    })
  }

  addSection() {
    const {
      block: { meta }
    } = this.props
    const sections = meta[EFieldKeys.SECTIONS]

    this.updateWorkflowBlock({
      [EFieldKeys.SECTIONS]: [...sections, getNewSection()]
    })
  }

  deleteSection(uuid) {
    const {
      block: { meta }
    } = this.props
    const sections = meta[EFieldKeys.SECTIONS]

    this.updateWorkflowBlock({
      [EFieldKeys.SECTIONS]: sections.filter(section => section.uuid !== uuid)
    })
  }

  changeSectionNumber(uuid, index) {
    const {
      block: { meta }
    } = this.props
    const sections = meta[EFieldKeys.SECTIONS]

    this.updateWorkflowBlock({
      [EFieldKeys.SECTIONS]: changeArrayItemByUuid({
        items: sections,
        uuid,
        newProps: {
          index: index ? parseInt(index, 10) : ''
        }
      })
    })
  }

  toggleSectionVisibility(uuid) {
    const {
      block: { meta }
    } = this.props
    const sections = meta[EFieldKeys.SECTIONS]

    this.updateWorkflowBlock({
      [EFieldKeys.SECTIONS]: sections.map(section => {
        if (section.uuid === uuid) {
          return {
            ...section,
            hide: !section.hide
          }
        }

        return section
      })
    })
  }

  render() {
    const { block } = this.props

    return (
      <HideCardSectionBlock
        block={block}
        handleInputChange={this.handleInputChange}
        handleReset={this.handleReset}
        addSection={this.addSection}
        deleteSection={this.deleteSection}
        changeSectionNumber={this.changeSectionNumber}
        toggleSectionVisibility={this.toggleSectionVisibility}
      />
    )
  }
}

export default HideCardSectionBlockContainer
