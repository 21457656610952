import { connect } from 'react-redux'

import JobsIndicator from 'components/jobsIndicator/JobsIndicator'
import { WorkflowExecutionIndicatorItem } from 'components/jobsIndicator/workflowExecutionIndicatorItem'
import jobsIndicatorHOC from '../hoc/jobsIndicatorHOC'

import { toggleWorkflowExecutionIndicator } from 'actions'
import { EJobStatuses } from 'features/jobs/jobs.types'
import { WORKFLOW_EXECUTION_JOB } from 'features/jobs/jobs.constants'

const JOB_TYPES = [WORKFLOW_EXECUTION_JOB]
const RUNNING_JOBS_STATUSES = [EJobStatuses.RUNNING, EJobStatuses.PENDING]

const getTitleText = jobs => {
  const activeJobs = jobs.filter(item => RUNNING_JOBS_STATUSES.includes(item.status))
  const activeJobsCount = activeJobs.length

  if (activeJobsCount) {
    return `Executing ${activeJobsCount} workflow${activeJobsCount > 1 ? 's' : ''}`
  }

  return 'Workflow executed'
}

const WorkflowExecutionIndicatorContainer = ({
  isIndicatorHidden,
  jobs,
  visible,
  invisible,
  width,
  transform,
  onMessageHide,
  onToggleHideJobsIndicator,
  onCloseJobsIndicator
}) => {
  return (
    <JobsIndicator
      title={getTitleText(jobs)}
      closeTooltipText="Close window. Workflows execution will continue"
      visible={visible}
      invisible={invisible}
      width={width}
      transform={transform}
      hidden={isIndicatorHidden}
      onMessageHide={onMessageHide}
      onCloseJobsIndicator={onCloseJobsIndicator}
      onToggleHideJobsIndicator={onToggleHideJobsIndicator}
    >
      {jobs.map(job => (
        <WorkflowExecutionIndicatorItem key={job.id} job={job} />
      ))}
    </JobsIndicator>
  )
}

function mapStateToProps(state) {
  return {
    jobs: state.socket.jobs.filter(job => {
      const isWorkflowJob = JOB_TYPES.includes(job.type)
      const shouldBeShown = job.data && job.data.isExecutionShown

      return isWorkflowJob && shouldBeShown
    })
  }
}

const mapDispatchToProps = {
  toggleJobsIndicator: toggleWorkflowExecutionIndicator
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(jobsIndicatorHOC(WorkflowExecutionIndicatorContainer))
