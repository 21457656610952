import { getUserPreferences, setUserPreferences } from 'helpers/userPreferences.helpers'
import { MAX_RECENT_BOARDS_COUNT } from './recentBoards.constants'
import { type TRecentBoard } from './recentBoards.types'

export const getRecentBoards = (userId: string): TRecentBoard[] => {
  const userPreferences = getUserPreferences()

  return userPreferences[userId]?.recentBoards ?? []
}

// Add a board object to the "recent boards" list in localStorage.
export const addBoardToRecent = ({ userId, board }: { userId: string; board: TRecentBoard }) => {
  const boards = getRecentBoards(userId)

  const filteredBoards = boards.filter(b => b.id !== board.id)
  filteredBoards.unshift(board)

  const newRecentBoards = filteredBoards.slice(0, MAX_RECENT_BOARDS_COUNT)

  setUserPreferences({ userId, preferences: { recentBoards: newRecentBoards } })
}

// Remove a board by ID from the "recent boards" list in localStorage.
export const removeBoardFromRecent = ({ userId, boardId }: { userId: string; boardId: string }) => {
  const boards = getRecentBoards(userId)
  const filteredBoards = boards.filter(b => b.id !== boardId)

  setUserPreferences({ userId, preferences: { recentBoards: filteredBoards } })
}
