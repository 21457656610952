import React from 'react'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

import RadioFieldsBlock from './RadioFieldsBlock'

import BlockInputContainer from '../../../containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from '../../common/AnyLevelDropdown'

import '../../../scss/workflowBuilder/copyBlocks/copy-blocks.scss'

const CopyBlock = props => {
  const {
    block: { id, meta, input, error },
    inputSourceAttr: { inputSourceType, inputSourceTitle, inputSourcePlaceholder },
    inputTargetAttr: {
      targetFieldKey,
      inputTargetType,
      inputTargetTitle,
      inputTargetPlaceholder,
      inputTargetTooltip
    },
    radioFieldAttr,
    handleReset,
    handleInputChange,
    toggleRadioFields
  } = props

  const isSourceInputSelected = !!input[EFieldKeys.SOURCE_INPUT]
  return (
    <div className="copy-blocks">
      <BlockInputContainer
        type={inputSourceType}
        blockId={id}
        value={input[EFieldKeys.SOURCE_INPUT]}
      >
        {({ selectedItem, items, searchItems }) => (
          <AnyLevelDropdown
            supportHistory
            title={inputSourceTitle}
            placeholder={inputSourcePlaceholder}
            items={items}
            searchItems={searchItems}
            onSelect={handleInputChange.bind(null, EFieldKeys.SOURCE_INPUT)}
            onReset={handleReset.bind(null, EFieldKeys.SOURCE_INPUT)}
            value={selectedItem}
            type={inputSourceType}
            error={error[EFieldKeys.SOURCE_INPUT]}
          />
        )}
      </BlockInputContainer>
      <div className="wrapper">
        <BlockInputContainer type={inputTargetType} blockId={id} value={input[targetFieldKey]}>
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              supportHistory
              title={
                <span>
                  {inputTargetTitle}
                  {inputTargetTooltip}
                </span>
              }
              placeholder={inputTargetPlaceholder}
              items={items}
              searchItems={searchItems}
              onSelect={handleInputChange.bind(null, targetFieldKey)}
              onReset={handleReset.bind(null, targetFieldKey)}
              value={selectedItem}
              type={inputTargetType}
              disabled={!isSourceInputSelected}
              error={error[targetFieldKey]}
            />
          )}
        </BlockInputContainer>
      </div>
      {radioFieldAttr && (
        <RadioFieldsBlock
          id={id}
          meta={meta}
          radioFieldAttr={radioFieldAttr}
          toggleRadioFields={toggleRadioFields}
          isRadioDisabled={!isSourceInputSelected}
        />
      )}
    </div>
  )
}

export default CopyBlock
