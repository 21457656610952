import { type SelectChangeEvent } from '@mui/material/Select/Select'
import { EHttpMethod } from 'constants/common'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { Select } from 'components/common/select/select'
import { TokenInputBox } from 'components/tokenInputBox/tokenInputBox'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import { TOKEN_INPUT_BOX_HINT_TEXT } from '../restApiClientBlock.constants'
import './information-section.scss'

const URL_TARGET_INPUT_MAX_LENGTH = 10000

const HTTP_METHOD_OPTIONS = Object.values(EHttpMethod).map(method => ({
  value: method,
  label: method
}))

type TProps = {
  block: IWorkflowBlock
  updateWorkflowBlockMeta: (meta: Partial<IWorkflowBlock['meta']>) => void
  updateBlockMeta: (key: EFieldKeys, fieldValue: unknown[]) => void
  handleInputChange: (fieldName: string, item: Partial<IWorkflowDropdownValue>) => void
  handleReset: (fieldName: string) => void
}

export const InformationSection = ({
  block,
  updateWorkflowBlockMeta,
  updateBlockMeta,
  handleInputChange,
  handleReset
}: TProps) => {
  const handleMethodChange = (newMethod: EHttpMethod) => {
    updateWorkflowBlockMeta({ [EFieldKeys.METHOD_TYPE]: newMethod })
  }

  return (
    <div className="wf-rest-api-block-information-section">
      <Select
        size={40}
        className="method"
        label="HTTP method"
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value={block.meta[EFieldKeys.METHOD_TYPE]}
        options={HTTP_METHOD_OPTIONS}
        onChange={({ target }: SelectChangeEvent<unknown>) =>
          handleMethodChange(target.value as EHttpMethod)
        }
      />
      <TokenInputBox
        block={block}
        fieldMeta={block.meta}
        bodyFieldKey={EFieldKeys.URL_TARGET}
        tokenFieldKey={EFieldKeys.STRING_INPUT}
        tokenInputBoxLabel="URL of the target service"
        tokenInputPlaceholder="https://example.com"
        error={block.error[EFieldKeys.URL_TARGET] as string}
        handleInputChange={handleInputChange}
        handleReset={handleReset}
        maxLength={URL_TARGET_INPUT_MAX_LENGTH}
        updateBlockMeta={updateBlockMeta}
        tokenInputBoxHintText={TOKEN_INPUT_BOX_HINT_TEXT}
        shouldPreventEnterAction
        hasHint
      />
    </div>
  )
}
