import React from 'react'
import classNames from 'classnames'
import UserLogo from '../../UserLogo'

const UserValue = ({ email, username, avatarImage, error, isUndefined }) => (
  <div className="any-level-dropdown-selected-value">
    <UserLogo avatarImage={avatarImage} username={username} size="extra-small-24px" />
    <div className="any-level-dropdown-selected-value-text">
      <p
        className={classNames('any-level-dropdown-selected-value-title', {
          'is-undefined': isUndefined
        })}
      >
        {email}
      </p>
      {username && <p className="any-level-dropdown-selected-value-subtitle">{username}</p>}
      {error && <p className="any-level-dropdown-selected-value-error">{error}</p>}
    </div>
  </div>
)

export default UserValue
