import { OPEN_CARD_MODES } from 'helpers/workflowExecution/workflowMetaHelpers'

export const OPEN_CARD_OPTIONS = [
  {
    label: 'Open in the same view mode',
    value: OPEN_CARD_MODES.sameView
  },
  {
    label: 'Open in Detailed view with board in background',
    value: OPEN_CARD_MODES.detailed
  },
  {
    label: 'Open in Presentation mode',
    value: OPEN_CARD_MODES.fullScreen
  },
  {
    label: 'Open in Full Screen view',
    value: OPEN_CARD_MODES.embed
  },
  {
    label: 'Open in App view',
    value: OPEN_CARD_MODES.appView
  }
]
