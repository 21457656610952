import React, { Component } from 'react'

import SendEmailBlock from '../../../components/workflowBuilder/systemActions/SendEmailBlock'

export default class SendEmailBlockContainer extends Component {
  constructor(props) {
    super(props)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.updateBlockMeta = this.updateBlockMeta.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  handleInputChange(fieldName, item) {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null,
      error: block.error
    })
  }

  handleReset(fieldName) {
    this.handleInputChange(fieldName, { id: null })
  }

  updateBlockMeta(key, fieldValue) {
    const {
      updateWorkflowBlock,
      block: { id, meta, error }
    } = this.props

    if (meta[key] !== fieldValue) {
      updateWorkflowBlock({
        id,
        data: {
          meta: { ...meta, [key]: fieldValue },
          error
        }
      })
    }
  }

  render() {
    const { block, tenantId } = this.props
    return (
      <SendEmailBlock
        block={block}
        handleInputChange={this.handleInputChange}
        updateBlockMeta={this.updateBlockMeta}
        handleReset={this.handleReset}
        tenantId={tenantId}
      />
    )
  }
}
