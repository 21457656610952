import { EJobStatuses } from 'features/jobs/jobs.types'

export const OPEN_CARD_MODES = {
  detailed: 'detailed',
  fullScreen: 'fullScreen',
  embed: 'embed',
  appView: 'appView',
  sameView: 'sameView'
}

export const getDefaultWorkflowMeta = () => ({
  isWorkflowMode: false,
  isScriptMode: false,
  loadingMessage: 'Loading...',
  isSuccessMessageEnabled: false,
  successMessage: 'Operation is finished...',
  workflowsCount: 0
})

export const isWorkflowMetaActive = widget => {
  if (!widget || (widget && !widget.workflowMeta)) {
    return false
  }

  const {
    workflowMeta: { isWorkflowMode, isScriptMode }
  } = widget

  return isWorkflowMode || isScriptMode
}

/**
 * ButtonWidget has some data format which was introduced for future.
 * With workflow builder this data format become redundant but widget use it for script execution.
 * For backward compatibility need to properly update this data during script setup.
 * @param {Object} widget
 * @returns {string}
 */
export const getScriptFromWidget = widget => {
  if (!widget.actions || (widget.actions && !widget.actions[0])) {
    return ''
  }

  return widget.actions[0].script
}

export const getScriptForWidget = script => ({
  actions: script && script.trim() ? [{ script }] : []
})

export const runScript = args => {
  const { backendUrl, tenantId, boardId, cardUuid, script } = args

  if (!script) {
    return
  }

  try {
    const func = new Function('cardIds', script)
    func({ tenantId, boardId, cardUuid, url: backendUrl })
  } catch (err) {
    console.error(err)
  }
}

export const getFinishData = finishedJobs => {
  const successJobs = finishedJobs
    .filter(job => job.status === EJobStatuses.DONE)
    .sort((job1, job2) => job1.finishDate - job2.finishDate)
  const lastSuccessJob = successJobs.length ? successJobs[successJobs.length - 1] : null
  return lastSuccessJob?.data?.finishData || {}
}
