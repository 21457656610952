import  { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { OBJECT_TYPES } from 'constants/workflows'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import { LEVEL_DROPDOWN_CLASSNAME } from '../tokenInputBox.constants'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import './insert-token-dropdown.scss'

export const InsertTokenDropdown = ({
  block: { input, id, error },
  handleInputChange,
  fieldName,
  id: commentDropdownId,
  insertedToken,
  isDisabled
}) => {
  const inputRef = useRef(null)
  const spanRef = useRef(null)
  const isSubject = fieldName.startsWith('subject')
  const stringKeys = Object.keys(input).filter(token => token.startsWith(EFieldKeys.STRING_INPUT))
  const subjectStringKeys = Object.keys(input).filter(token =>
    token.startsWith(EFieldKeys.SUBJECT_INPUT)
  )
  const ubfKeys = Object.keys(input).filter(token => token.startsWith(EFieldKeys.UBF_CELL))
  const subjectUbfKeys = Object.keys(input).filter(token =>
    token.startsWith(EFieldKeys.SUBJECT_UBF_CELL)
  )

  const getDropdownList = list => {
    const stringValues = isSubject ? subjectStringKeys : stringKeys
    const ubfValues = isSubject ? subjectUbfKeys : ubfKeys
    const stringAvailable = stringValues.some(string => !input[string])
    const ubfAvailable = ubfValues.some(ubf => !input[ubf])
    if (stringAvailable && ubfAvailable) {
      return list
    }
    if (stringAvailable) {
      return list.filter(item => item.type === OBJECT_TYPES.STRING)
    }
    if (ubfAvailable) {
      return list.filter(item => item.type === OBJECT_TYPES.WIDGET_DATA)
    }
    return []
  }

  useEffect(() => {
    // manually focus on any level field
    setTimeout(() => {
      const tokenWrapperRef = spanRef.current
      const currentFieldName = tokenWrapperRef.dataset.fieldName
      if (currentFieldName !== insertedToken) return

      if (inputRef.current) {
        inputRef.current.focus()
      }
      if (insertedToken && !inputRef.current && tokenWrapperRef) {
        const selection = window.getSelection()
        const range = selection.getRangeAt(0)
        range.setStartAfter(tokenWrapperRef)
        range.setEndAfter(tokenWrapperRef)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    })
  }, [insertedToken])

  return (
    <span
      ref={spanRef}
      data-field-name={fieldName}
      id={commentDropdownId}
      className={classNames(`${LEVEL_DROPDOWN_CLASSNAME} ${commentDropdownId}`, {
        'empty-field': !input[fieldName]
      })}
      contentEditable={false}
      suppressContentEditableWarning
    >
      <BlockInputContainer
        multiTypesSupported
        type={OBJECT_TYPES.STRING}
        supportedTypes={[OBJECT_TYPES.STRING, OBJECT_TYPES.WIDGET_DATA]}
        blockId={id}
        value={input[fieldName]}
      >
        {({ selectedItem, items, searchItems }) => (
          <AnyLevelDropdown
            supportHistory
            inputRef={inputRef}
            items={getDropdownList(items)}
            searchItems={searchItems}
            onSelect={handleInputChange.bind(null, fieldName)}
            onReset={() => {}}
            value={selectedItem}
            type={OBJECT_TYPES.COMMENT}
            disabled={isDisabled}
            error={error[fieldName]}
          />
        )}
      </BlockInputContainer>
    </span>
  )
}

