// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.3.0
//   protoc               v3.19.1
// source: dictionary/v1/dictionary.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { type CallContext, type CallOptions } from "nice-grpc-common";
import { PageInfoWithTotal, PageOptions } from "../../common/page.pb";
import { Timestamp } from "../../google/protobuf/timestamp.pb";

export const protobufPackage = "upboard.io.dictionary.v1";

export enum OrderBy {
  CreationDate = 0,
  Name = 1,
  UNRECOGNIZED = -1,
}

export enum OrderDirection {
  DESC = 0,
  ASC = 1,
  UNRECOGNIZED = -1,
}

/** By default Hint{flag: false, text_message: ""} */
export interface Hint {
  flag: boolean;
  textMessage: string;
}

/** By default QueryWidget{flag: false, query_id: "", board_id: "", card_id: ""} */
export interface QueryWidget {
  flag: boolean;
  queryId: string;
  boardId: string;
  cardId: string;
}

export interface Option {
  /** Field required */
  name: string;
}

export interface CreateCommandRequest {
  /** Field required */
  tenantId: string;
  /** Field required */
  name: string;
  /** Field required */
  createdBy: string;
  /** Field required */
  options: Option[];
  hint: Hint | undefined;
  queryWidget: QueryWidget | undefined;
}

export interface CreateCommandResultResponse {
  dictionaryId: string;
}

export interface DetailsRequest {
  /** Field required */
  dictionaryId: string;
  /** Field required */
  tenantId: string;
}

export interface DictionaryDetails {
  id: string;
  tenantId: string;
  name: string;
  createdBy: string;
  options: Option[];
  creationDate: Date | undefined;
  hint: Hint | undefined;
  queryWidget: QueryWidget | undefined;
}

export interface ListByTenantRequest {
  /** Field required */
  tenantId: string;
  /** By default OrderBy.CreationDate */
  orderBy: OrderBy;
  /** By default OrderDirection.DESC */
  orderDirection: OrderDirection;
  /** By default empty list */
  filters: FilterCriteria[];
  /**
   * Field required.
   * Set Limit = 0 to no limit
   */
  page: PageOptions | undefined;
}

export interface ListByTenantResponse {
  size: number;
  dictionaries: DictionaryDetails[];
  page: PageInfoWithTotal | undefined;
}

export interface UpdateCommandRequest {
  /** Field required */
  dictionaryId: string;
  /** Field required */
  tenantId: string;
  /** Field required */
  name: string;
  /** Field required */
  options: Option[];
  /** If not provided it would be changed to default value */
  hint:
    | Hint
    | undefined;
  /** If not provided it would be changed to default value */
  queryWidget: QueryWidget | undefined;
}

export interface UpdateCommandResultResponse {
}

export interface DeleteCommandRequest {
  /** Field required */
  dictionaryId: string;
  /** Field required */
  tenantId: string;
}

export interface DeleteCommandResultResponse {
}

export interface CopyCommandRequest {
  /** Field required */
  dictionaryId: string;
  /** Field required */
  tenantId: string;
  /** Field required */
  targetTenantId: string;
  /** Field required */
  newName: string;
}

export interface CopyCommandResultResponse {
}

export interface FilterCriteria {
  /**
   * Field required.
   * One of ["<=", ">=", "<", ">", "=", "in", "not-in", "!="]
   */
  operator: string;
  name?: string | undefined;
  creationDate?: Date | undefined;
}

export interface BulkCreateCommandRequest {
  /** Field required */
  tenantId: string;
  /** Field required */
  bulk: BulkRequestCreateDictionary[];
}

export interface BulkCreateCommandResultResponse {
  ids: string[];
}

export interface BulkRequestCreateDictionary {
  /** Field required */
  name: string;
  /** Field required */
  createdBy: string;
  /** Field required */
  options: Option[];
  /** If not provided it would be changed to default value */
  hint:
    | Hint
    | undefined;
  /** If not provided it would be changed to default value */
  queryWidget: QueryWidget | undefined;
}

export interface BulkDetailsRequest {
  /** Field required */
  tenantId: string;
  bulkIds: string[];
}

export interface BulkDetailsResponse {
  size: number;
  dictionaries: DictionaryDetails[];
}

function createBaseHint(): Hint {
  return { flag: false, textMessage: "" };
}

export const Hint: MessageFns<Hint> = {
  encode(message: Hint, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.flag !== false) {
      writer.uint32(8).bool(message.flag);
    }
    if (message.textMessage !== "") {
      writer.uint32(18).string(message.textMessage);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Hint {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.flag = reader.bool();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.textMessage = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Hint>, I>>(base?: I): Hint {
    return Hint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Hint>, I>>(object: I): Hint {
    const message = createBaseHint();
    message.flag = object.flag ?? false;
    message.textMessage = object.textMessage ?? "";
    return message;
  },
};

function createBaseQueryWidget(): QueryWidget {
  return { flag: false, queryId: "", boardId: "", cardId: "" };
}

export const QueryWidget: MessageFns<QueryWidget> = {
  encode(message: QueryWidget, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.flag !== false) {
      writer.uint32(8).bool(message.flag);
    }
    if (message.queryId !== "") {
      writer.uint32(18).string(message.queryId);
    }
    if (message.boardId !== "") {
      writer.uint32(26).string(message.boardId);
    }
    if (message.cardId !== "") {
      writer.uint32(34).string(message.cardId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): QueryWidget {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQueryWidget();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.flag = reader.bool();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.queryId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.boardId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.cardId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<QueryWidget>, I>>(base?: I): QueryWidget {
    return QueryWidget.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QueryWidget>, I>>(object: I): QueryWidget {
    const message = createBaseQueryWidget();
    message.flag = object.flag ?? false;
    message.queryId = object.queryId ?? "";
    message.boardId = object.boardId ?? "";
    message.cardId = object.cardId ?? "";
    return message;
  },
};

function createBaseOption(): Option {
  return { name: "" };
}

export const Option: MessageFns<Option> = {
  encode(message: Option, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Option {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Option>, I>>(base?: I): Option {
    return Option.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Option>, I>>(object: I): Option {
    const message = createBaseOption();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseCreateCommandRequest(): CreateCommandRequest {
  return { tenantId: "", name: "", createdBy: "", options: [], hint: undefined, queryWidget: undefined };
}

export const CreateCommandRequest: MessageFns<CreateCommandRequest> = {
  encode(message: CreateCommandRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.createdBy !== "") {
      writer.uint32(26).string(message.createdBy);
    }
    for (const v of message.options) {
      Option.encode(v!, writer.uint32(34).fork()).join();
    }
    if (message.hint !== undefined) {
      Hint.encode(message.hint, writer.uint32(42).fork()).join();
    }
    if (message.queryWidget !== undefined) {
      QueryWidget.encode(message.queryWidget, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateCommandRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCommandRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.options.push(Option.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.hint = Hint.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.queryWidget = QueryWidget.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateCommandRequest>, I>>(base?: I): CreateCommandRequest {
    return CreateCommandRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCommandRequest>, I>>(object: I): CreateCommandRequest {
    const message = createBaseCreateCommandRequest();
    message.tenantId = object.tenantId ?? "";
    message.name = object.name ?? "";
    message.createdBy = object.createdBy ?? "";
    message.options = object.options?.map((e) => Option.fromPartial(e)) || [];
    message.hint = (object.hint !== undefined && object.hint !== null) ? Hint.fromPartial(object.hint) : undefined;
    message.queryWidget = (object.queryWidget !== undefined && object.queryWidget !== null)
      ? QueryWidget.fromPartial(object.queryWidget)
      : undefined;
    return message;
  },
};

function createBaseCreateCommandResultResponse(): CreateCommandResultResponse {
  return { dictionaryId: "" };
}

export const CreateCommandResultResponse: MessageFns<CreateCommandResultResponse> = {
  encode(message: CreateCommandResultResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.dictionaryId !== "") {
      writer.uint32(10).string(message.dictionaryId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateCommandResultResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCommandResultResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dictionaryId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateCommandResultResponse>, I>>(base?: I): CreateCommandResultResponse {
    return CreateCommandResultResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCommandResultResponse>, I>>(object: I): CreateCommandResultResponse {
    const message = createBaseCreateCommandResultResponse();
    message.dictionaryId = object.dictionaryId ?? "";
    return message;
  },
};

function createBaseDetailsRequest(): DetailsRequest {
  return { dictionaryId: "", tenantId: "" };
}

export const DetailsRequest: MessageFns<DetailsRequest> = {
  encode(message: DetailsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.dictionaryId !== "") {
      writer.uint32(10).string(message.dictionaryId);
    }
    if (message.tenantId !== "") {
      writer.uint32(18).string(message.tenantId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DetailsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dictionaryId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DetailsRequest>, I>>(base?: I): DetailsRequest {
    return DetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DetailsRequest>, I>>(object: I): DetailsRequest {
    const message = createBaseDetailsRequest();
    message.dictionaryId = object.dictionaryId ?? "";
    message.tenantId = object.tenantId ?? "";
    return message;
  },
};

function createBaseDictionaryDetails(): DictionaryDetails {
  return {
    id: "",
    tenantId: "",
    name: "",
    createdBy: "",
    options: [],
    creationDate: undefined,
    hint: undefined,
    queryWidget: undefined,
  };
}

export const DictionaryDetails: MessageFns<DictionaryDetails> = {
  encode(message: DictionaryDetails, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.tenantId !== "") {
      writer.uint32(18).string(message.tenantId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.createdBy !== "") {
      writer.uint32(34).string(message.createdBy);
    }
    for (const v of message.options) {
      Option.encode(v!, writer.uint32(42).fork()).join();
    }
    if (message.creationDate !== undefined) {
      Timestamp.encode(toTimestamp(message.creationDate), writer.uint32(50).fork()).join();
    }
    if (message.hint !== undefined) {
      Hint.encode(message.hint, writer.uint32(58).fork()).join();
    }
    if (message.queryWidget !== undefined) {
      QueryWidget.encode(message.queryWidget, writer.uint32(66).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DictionaryDetails {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDictionaryDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.options.push(Option.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.creationDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.hint = Hint.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.queryWidget = QueryWidget.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DictionaryDetails>, I>>(base?: I): DictionaryDetails {
    return DictionaryDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DictionaryDetails>, I>>(object: I): DictionaryDetails {
    const message = createBaseDictionaryDetails();
    message.id = object.id ?? "";
    message.tenantId = object.tenantId ?? "";
    message.name = object.name ?? "";
    message.createdBy = object.createdBy ?? "";
    message.options = object.options?.map((e) => Option.fromPartial(e)) || [];
    message.creationDate = object.creationDate ?? undefined;
    message.hint = (object.hint !== undefined && object.hint !== null) ? Hint.fromPartial(object.hint) : undefined;
    message.queryWidget = (object.queryWidget !== undefined && object.queryWidget !== null)
      ? QueryWidget.fromPartial(object.queryWidget)
      : undefined;
    return message;
  },
};

function createBaseListByTenantRequest(): ListByTenantRequest {
  return { tenantId: "", orderBy: 0, orderDirection: 0, filters: [], page: undefined };
}

export const ListByTenantRequest: MessageFns<ListByTenantRequest> = {
  encode(message: ListByTenantRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    if (message.orderBy !== 0) {
      writer.uint32(16).int32(message.orderBy);
    }
    if (message.orderDirection !== 0) {
      writer.uint32(24).int32(message.orderDirection);
    }
    for (const v of message.filters) {
      FilterCriteria.encode(v!, writer.uint32(34).fork()).join();
    }
    if (message.page !== undefined) {
      PageOptions.encode(message.page, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListByTenantRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListByTenantRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.orderBy = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.orderDirection = reader.int32() as any;
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.filters.push(FilterCriteria.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.page = PageOptions.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListByTenantRequest>, I>>(base?: I): ListByTenantRequest {
    return ListByTenantRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListByTenantRequest>, I>>(object: I): ListByTenantRequest {
    const message = createBaseListByTenantRequest();
    message.tenantId = object.tenantId ?? "";
    message.orderBy = object.orderBy ?? 0;
    message.orderDirection = object.orderDirection ?? 0;
    message.filters = object.filters?.map((e) => FilterCriteria.fromPartial(e)) || [];
    message.page = (object.page !== undefined && object.page !== null)
      ? PageOptions.fromPartial(object.page)
      : undefined;
    return message;
  },
};

function createBaseListByTenantResponse(): ListByTenantResponse {
  return { size: 0, dictionaries: [], page: undefined };
}

export const ListByTenantResponse: MessageFns<ListByTenantResponse> = {
  encode(message: ListByTenantResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.size !== 0) {
      writer.uint32(8).int32(message.size);
    }
    for (const v of message.dictionaries) {
      DictionaryDetails.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.page !== undefined) {
      PageInfoWithTotal.encode(message.page, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListByTenantResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListByTenantResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.size = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.dictionaries.push(DictionaryDetails.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.page = PageInfoWithTotal.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListByTenantResponse>, I>>(base?: I): ListByTenantResponse {
    return ListByTenantResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListByTenantResponse>, I>>(object: I): ListByTenantResponse {
    const message = createBaseListByTenantResponse();
    message.size = object.size ?? 0;
    message.dictionaries = object.dictionaries?.map((e) => DictionaryDetails.fromPartial(e)) || [];
    message.page = (object.page !== undefined && object.page !== null)
      ? PageInfoWithTotal.fromPartial(object.page)
      : undefined;
    return message;
  },
};

function createBaseUpdateCommandRequest(): UpdateCommandRequest {
  return { dictionaryId: "", tenantId: "", name: "", options: [], hint: undefined, queryWidget: undefined };
}

export const UpdateCommandRequest: MessageFns<UpdateCommandRequest> = {
  encode(message: UpdateCommandRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.dictionaryId !== "") {
      writer.uint32(10).string(message.dictionaryId);
    }
    if (message.tenantId !== "") {
      writer.uint32(18).string(message.tenantId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.options) {
      Option.encode(v!, writer.uint32(34).fork()).join();
    }
    if (message.hint !== undefined) {
      Hint.encode(message.hint, writer.uint32(42).fork()).join();
    }
    if (message.queryWidget !== undefined) {
      QueryWidget.encode(message.queryWidget, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateCommandRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCommandRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dictionaryId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.options.push(Option.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.hint = Hint.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.queryWidget = QueryWidget.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateCommandRequest>, I>>(base?: I): UpdateCommandRequest {
    return UpdateCommandRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCommandRequest>, I>>(object: I): UpdateCommandRequest {
    const message = createBaseUpdateCommandRequest();
    message.dictionaryId = object.dictionaryId ?? "";
    message.tenantId = object.tenantId ?? "";
    message.name = object.name ?? "";
    message.options = object.options?.map((e) => Option.fromPartial(e)) || [];
    message.hint = (object.hint !== undefined && object.hint !== null) ? Hint.fromPartial(object.hint) : undefined;
    message.queryWidget = (object.queryWidget !== undefined && object.queryWidget !== null)
      ? QueryWidget.fromPartial(object.queryWidget)
      : undefined;
    return message;
  },
};

function createBaseUpdateCommandResultResponse(): UpdateCommandResultResponse {
  return {};
}

export const UpdateCommandResultResponse: MessageFns<UpdateCommandResultResponse> = {
  encode(_: UpdateCommandResultResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateCommandResultResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateCommandResultResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateCommandResultResponse>, I>>(base?: I): UpdateCommandResultResponse {
    return UpdateCommandResultResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateCommandResultResponse>, I>>(_: I): UpdateCommandResultResponse {
    const message = createBaseUpdateCommandResultResponse();
    return message;
  },
};

function createBaseDeleteCommandRequest(): DeleteCommandRequest {
  return { dictionaryId: "", tenantId: "" };
}

export const DeleteCommandRequest: MessageFns<DeleteCommandRequest> = {
  encode(message: DeleteCommandRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.dictionaryId !== "") {
      writer.uint32(10).string(message.dictionaryId);
    }
    if (message.tenantId !== "") {
      writer.uint32(18).string(message.tenantId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteCommandRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteCommandRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dictionaryId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteCommandRequest>, I>>(base?: I): DeleteCommandRequest {
    return DeleteCommandRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteCommandRequest>, I>>(object: I): DeleteCommandRequest {
    const message = createBaseDeleteCommandRequest();
    message.dictionaryId = object.dictionaryId ?? "";
    message.tenantId = object.tenantId ?? "";
    return message;
  },
};

function createBaseDeleteCommandResultResponse(): DeleteCommandResultResponse {
  return {};
}

export const DeleteCommandResultResponse: MessageFns<DeleteCommandResultResponse> = {
  encode(_: DeleteCommandResultResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteCommandResultResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteCommandResultResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteCommandResultResponse>, I>>(base?: I): DeleteCommandResultResponse {
    return DeleteCommandResultResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteCommandResultResponse>, I>>(_: I): DeleteCommandResultResponse {
    const message = createBaseDeleteCommandResultResponse();
    return message;
  },
};

function createBaseCopyCommandRequest(): CopyCommandRequest {
  return { dictionaryId: "", tenantId: "", targetTenantId: "", newName: "" };
}

export const CopyCommandRequest: MessageFns<CopyCommandRequest> = {
  encode(message: CopyCommandRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.dictionaryId !== "") {
      writer.uint32(10).string(message.dictionaryId);
    }
    if (message.tenantId !== "") {
      writer.uint32(18).string(message.tenantId);
    }
    if (message.targetTenantId !== "") {
      writer.uint32(26).string(message.targetTenantId);
    }
    if (message.newName !== "") {
      writer.uint32(34).string(message.newName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CopyCommandRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCopyCommandRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dictionaryId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.targetTenantId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.newName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CopyCommandRequest>, I>>(base?: I): CopyCommandRequest {
    return CopyCommandRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CopyCommandRequest>, I>>(object: I): CopyCommandRequest {
    const message = createBaseCopyCommandRequest();
    message.dictionaryId = object.dictionaryId ?? "";
    message.tenantId = object.tenantId ?? "";
    message.targetTenantId = object.targetTenantId ?? "";
    message.newName = object.newName ?? "";
    return message;
  },
};

function createBaseCopyCommandResultResponse(): CopyCommandResultResponse {
  return {};
}

export const CopyCommandResultResponse: MessageFns<CopyCommandResultResponse> = {
  encode(_: CopyCommandResultResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CopyCommandResultResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCopyCommandResultResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CopyCommandResultResponse>, I>>(base?: I): CopyCommandResultResponse {
    return CopyCommandResultResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CopyCommandResultResponse>, I>>(_: I): CopyCommandResultResponse {
    const message = createBaseCopyCommandResultResponse();
    return message;
  },
};

function createBaseFilterCriteria(): FilterCriteria {
  return { operator: "", name: undefined, creationDate: undefined };
}

export const FilterCriteria: MessageFns<FilterCriteria> = {
  encode(message: FilterCriteria, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.operator !== "") {
      writer.uint32(10).string(message.operator);
    }
    if (message.name !== undefined) {
      writer.uint32(18).string(message.name);
    }
    if (message.creationDate !== undefined) {
      Timestamp.encode(toTimestamp(message.creationDate), writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FilterCriteria {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFilterCriteria();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.operator = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.creationDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<FilterCriteria>, I>>(base?: I): FilterCriteria {
    return FilterCriteria.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FilterCriteria>, I>>(object: I): FilterCriteria {
    const message = createBaseFilterCriteria();
    message.operator = object.operator ?? "";
    message.name = object.name ?? undefined;
    message.creationDate = object.creationDate ?? undefined;
    return message;
  },
};

function createBaseBulkCreateCommandRequest(): BulkCreateCommandRequest {
  return { tenantId: "", bulk: [] };
}

export const BulkCreateCommandRequest: MessageFns<BulkCreateCommandRequest> = {
  encode(message: BulkCreateCommandRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    for (const v of message.bulk) {
      BulkRequestCreateDictionary.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BulkCreateCommandRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkCreateCommandRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.bulk.push(BulkRequestCreateDictionary.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BulkCreateCommandRequest>, I>>(base?: I): BulkCreateCommandRequest {
    return BulkCreateCommandRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkCreateCommandRequest>, I>>(object: I): BulkCreateCommandRequest {
    const message = createBaseBulkCreateCommandRequest();
    message.tenantId = object.tenantId ?? "";
    message.bulk = object.bulk?.map((e) => BulkRequestCreateDictionary.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBulkCreateCommandResultResponse(): BulkCreateCommandResultResponse {
  return { ids: [] };
}

export const BulkCreateCommandResultResponse: MessageFns<BulkCreateCommandResultResponse> = {
  encode(message: BulkCreateCommandResultResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.ids) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BulkCreateCommandResultResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkCreateCommandResultResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BulkCreateCommandResultResponse>, I>>(base?: I): BulkCreateCommandResultResponse {
    return BulkCreateCommandResultResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkCreateCommandResultResponse>, I>>(
    object: I,
  ): BulkCreateCommandResultResponse {
    const message = createBaseBulkCreateCommandResultResponse();
    message.ids = object.ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseBulkRequestCreateDictionary(): BulkRequestCreateDictionary {
  return { name: "", createdBy: "", options: [], hint: undefined, queryWidget: undefined };
}

export const BulkRequestCreateDictionary: MessageFns<BulkRequestCreateDictionary> = {
  encode(message: BulkRequestCreateDictionary, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.createdBy !== "") {
      writer.uint32(18).string(message.createdBy);
    }
    for (const v of message.options) {
      Option.encode(v!, writer.uint32(26).fork()).join();
    }
    if (message.hint !== undefined) {
      Hint.encode(message.hint, writer.uint32(34).fork()).join();
    }
    if (message.queryWidget !== undefined) {
      QueryWidget.encode(message.queryWidget, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BulkRequestCreateDictionary {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkRequestCreateDictionary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.options.push(Option.decode(reader, reader.uint32()));
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.hint = Hint.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.queryWidget = QueryWidget.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BulkRequestCreateDictionary>, I>>(base?: I): BulkRequestCreateDictionary {
    return BulkRequestCreateDictionary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkRequestCreateDictionary>, I>>(object: I): BulkRequestCreateDictionary {
    const message = createBaseBulkRequestCreateDictionary();
    message.name = object.name ?? "";
    message.createdBy = object.createdBy ?? "";
    message.options = object.options?.map((e) => Option.fromPartial(e)) || [];
    message.hint = (object.hint !== undefined && object.hint !== null) ? Hint.fromPartial(object.hint) : undefined;
    message.queryWidget = (object.queryWidget !== undefined && object.queryWidget !== null)
      ? QueryWidget.fromPartial(object.queryWidget)
      : undefined;
    return message;
  },
};

function createBaseBulkDetailsRequest(): BulkDetailsRequest {
  return { tenantId: "", bulkIds: [] };
}

export const BulkDetailsRequest: MessageFns<BulkDetailsRequest> = {
  encode(message: BulkDetailsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    for (const v of message.bulkIds) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BulkDetailsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkDetailsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.bulkIds.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BulkDetailsRequest>, I>>(base?: I): BulkDetailsRequest {
    return BulkDetailsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkDetailsRequest>, I>>(object: I): BulkDetailsRequest {
    const message = createBaseBulkDetailsRequest();
    message.tenantId = object.tenantId ?? "";
    message.bulkIds = object.bulkIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseBulkDetailsResponse(): BulkDetailsResponse {
  return { size: 0, dictionaries: [] };
}

export const BulkDetailsResponse: MessageFns<BulkDetailsResponse> = {
  encode(message: BulkDetailsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.size !== 0) {
      writer.uint32(8).int32(message.size);
    }
    for (const v of message.dictionaries) {
      DictionaryDetails.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BulkDetailsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBulkDetailsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.size = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.dictionaries.push(DictionaryDetails.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BulkDetailsResponse>, I>>(base?: I): BulkDetailsResponse {
    return BulkDetailsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BulkDetailsResponse>, I>>(object: I): BulkDetailsResponse {
    const message = createBaseBulkDetailsResponse();
    message.size = object.size ?? 0;
    message.dictionaries = object.dictionaries?.map((e) => DictionaryDetails.fromPartial(e)) || [];
    return message;
  },
};

/** Dictionary service manages dictionaries across team */
export type DictionaryDefinition = typeof DictionaryDefinition;
export const DictionaryDefinition = {
  name: "Dictionary",
  fullName: "upboard.io.dictionary.v1.Dictionary",
  methods: {
    /** Creates dictionary */
    create: {
      name: "Create",
      requestType: CreateCommandRequest,
      requestStream: false,
      responseType: CreateCommandResultResponse,
      responseStream: false,
      options: {},
    },
    /**
     * Update dictionary details. Changes Name and Options
     * Provide new options even if they didn't change
     */
    update: {
      name: "Update",
      requestType: UpdateCommandRequest,
      requestStream: false,
      responseType: UpdateCommandResultResponse,
      responseStream: false,
      options: {},
    },
    /** Remove dictionary from team */
    delete: {
      name: "Delete",
      requestType: DeleteCommandRequest,
      requestStream: false,
      responseType: DeleteCommandResultResponse,
      responseStream: false,
      options: {},
    },
    /** Copy dictionary to another team */
    copy: {
      name: "Copy",
      requestType: CopyCommandRequest,
      requestStream: false,
      responseType: CopyCommandResultResponse,
      responseStream: false,
      options: {},
    },
    /** BulkCreate creates several dictionaries in provided team (tenant) */
    bulkCreate: {
      name: "BulkCreate",
      requestType: BulkCreateCommandRequest,
      requestStream: false,
      responseType: BulkCreateCommandResultResponse,
      responseStream: false,
      options: {},
    },
    /** Get Dictionary details */
    details: {
      name: "Details",
      requestType: DetailsRequest,
      requestStream: false,
      responseType: DictionaryDetails,
      responseStream: false,
      options: {},
    },
    /** List dictionaries by team */
    listByTenant: {
      name: "ListByTenant",
      requestType: ListByTenantRequest,
      requestStream: false,
      responseType: ListByTenantResponse,
      responseStream: false,
      options: {},
    },
    /** Bulk Get Dictionary details by array of dictionary id */
    bulkDetails: {
      name: "BulkDetails",
      requestType: BulkDetailsRequest,
      requestStream: false,
      responseType: BulkDetailsResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface DictionaryServiceImplementation<CallContextExt = {}> {
  /** Creates dictionary */
  create(
    request: CreateCommandRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<CreateCommandResultResponse>>;
  /**
   * Update dictionary details. Changes Name and Options
   * Provide new options even if they didn't change
   */
  update(
    request: UpdateCommandRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<UpdateCommandResultResponse>>;
  /** Remove dictionary from team */
  delete(
    request: DeleteCommandRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<DeleteCommandResultResponse>>;
  /** Copy dictionary to another team */
  copy(
    request: CopyCommandRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<CopyCommandResultResponse>>;
  /** BulkCreate creates several dictionaries in provided team (tenant) */
  bulkCreate(
    request: BulkCreateCommandRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<BulkCreateCommandResultResponse>>;
  /** Get Dictionary details */
  details(request: DetailsRequest, context: CallContext & CallContextExt): Promise<DeepPartial<DictionaryDetails>>;
  /** List dictionaries by team */
  listByTenant(
    request: ListByTenantRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<ListByTenantResponse>>;
  /** Bulk Get Dictionary details by array of dictionary id */
  bulkDetails(
    request: BulkDetailsRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<BulkDetailsResponse>>;
}

export interface DictionaryClient<CallOptionsExt = {}> {
  /** Creates dictionary */
  create(
    request: DeepPartial<CreateCommandRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<CreateCommandResultResponse>;
  /**
   * Update dictionary details. Changes Name and Options
   * Provide new options even if they didn't change
   */
  update(
    request: DeepPartial<UpdateCommandRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<UpdateCommandResultResponse>;
  /** Remove dictionary from team */
  delete(
    request: DeepPartial<DeleteCommandRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<DeleteCommandResultResponse>;
  /** Copy dictionary to another team */
  copy(
    request: DeepPartial<CopyCommandRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<CopyCommandResultResponse>;
  /** BulkCreate creates several dictionaries in provided team (tenant) */
  bulkCreate(
    request: DeepPartial<BulkCreateCommandRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<BulkCreateCommandResultResponse>;
  /** Get Dictionary details */
  details(request: DeepPartial<DetailsRequest>, options?: CallOptions & CallOptionsExt): Promise<DictionaryDetails>;
  /** List dictionaries by team */
  listByTenant(
    request: DeepPartial<ListByTenantRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<ListByTenantResponse>;
  /** Bulk Get Dictionary details by array of dictionary id */
  bulkDetails(
    request: DeepPartial<BulkDetailsRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<BulkDetailsResponse>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
