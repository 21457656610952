import { getCard } from 'api/bindCardAPI'
import { SECTION_STATUSES } from 'constants/detailedView/sectionsLoading'
import {
  getInitialSectionsLoading,
  getNonHiddenSections,
  getSectionsLoading
} from 'helpers/detailedView/sectionsLoading'
import { getBoard } from 'api/bindBoardAPI'
import { getJob } from 'api/jobsAPI'
import {
  emitAction,
  getSubscriptionTarget,
  closeSocketConnection,
  getCardsSubscriptionPayload
} from 'helpers/socketHelpers'
import {
  isAnyWidgetFromFirstSection,
  isWidgetFromFirstSection
} from 'helpers/widget/widgetDataHelpers'
import { addTimestampToUrl, automaticallyDownloadFile, getSnapshotUrl } from 'helpers/fetchHelpers'
import { updateSnapshotsOnCards } from 'helpers/board/boardOperations'
import { editingWidgetSelector } from 'selectors/builderSelectors'
import { EJobTypes, EJobStatuses } from 'features/jobs/jobs.types'
import { useTeamStore } from 'features/team/team.store'
import { WIDGETS_IN_SYSTEM } from 'features/widgets/widgets.constants'
import {
  TOGGLE_BOARD_SUBSCRIPTION,
  ORGANIZATION_UPDATE,
  TENANT_UPDATE,
  BOARD_UPDATE,
  CARD_CREATE,
  COLUMN_CREATE,
  CARD_UPDATE,
  CARD_DELETE,
  CARD_REORDER,
  CARD_START_EDIT,
  CARD_END_EDIT,
  BOARD_LOCKED,
  BOARD_UNLOCKED,
  BOARD_WATCHERS_UPDATE,
  RESUBSCRIBE_EVENT,
  UNSUBSCRIBE_EVENT,
  UNSUBSCRIBE_ALL_EVENT,
  SUBSCRIBE_EVENT,
  TOGGLE_COMMENTS_SUBSCRIPTION,
  TOGGLE_MONITOR_SUBSCRIPTION,
  COMMENT_CREATE,
  COMMENT_UPDATE,
  COMMENT_DELETE,
  APP_SCHEMA_IMPORTED,
  JOB_COMPLETED,
  APP_PUBLISH_COMPLETED,
  APP_PUBLISH_FAILED,
  MONITOR_UPDATE,
  CARD_CREATE_SOCKET,
  COLUMN_CREATE_SOCKET,
  CARD_UPDATE_IN_CARD_BUILDER_SOCKET,
  CARD_UPDATE_IN_BOARD_VIEW_SOCKET,
  WORKFLOW_SOURCE,
  CARDBUILDER_SOURCE,
  CARD_REORDER_SOCKET,
  CARD_SNAPSHOT_UPDATE_SOCKET,
  CARD_DELETE_SOCKET,
  BOARD_UPDATE_SOCKET,
  TENANT_UPDATE_SOCKET,
  ORGANIZATION_UPDATE_SOCKET,
  BOARD_WATCHERS_UPDATE_SOCKET,
  CARD_START_EDIT_SOCKET,
  CARD_END_EDIT_SOCKET,
  WIDGET_UPDATE_SOCKET,
  BULK_WIDGETS_CREATE_SOCKET,
  WIDGET_DELETE_SOCKET,
  TOGGLE_BOARD_COPYING,
  JOB_COMPLETED_SOCKET,
  BOARD_LOCKED_SOCKET,
  BOARD_UNLOCKED_SOCKET,
  MONITOR_UPDATE_SOCKET,
  CARD_SNAPSHOT_UPDATE,
  WIDGET_UPDATE,
  BULK_WIDGETS_CREATE,
  WIDGET_DELETE,
  TOGGLE_BOARD_WATCHING,
  BOARD_START_WATCH,
  BOARD_END_WATCH,
  ADD_NEW_JOB,
  REMOVE_JOB
} from '../constants/actionTypes'
import {
  setCardsPositionsETag,
  setCardComments,
  clearCardSnapshot,
  updateBoardWidgets,
  updateColumnCards
} from './boardActions'
import { replaceSectionsLoading } from './detailedViewActions'
import { boardMenuRequest } from './profileActions'
import { toggleDrawerDrag } from './drawerActions'
import { poleWorkflowExecutionJobs } from './clickWorkflowActions'
import {
  disableSocketFallback,
  enableSocketFallback,
  isSocketFallbackEnabled
} from './socketPollingActions'

export function toggleBoardSubscription(payload) {
  return { type: TOGGLE_BOARD_SUBSCRIPTION, payload }
}

export const subscribeToBoardEvents = emitAction(args => {
  const {
    activeOrganization: { organizationId },
    currentBoard: { tenantId, boardId },
    cards,
    isBoardView
  } = args

  const payload = [
    {
      type: ORGANIZATION_UPDATE,
      target: getSubscriptionTarget({ organizationId })
    },
    {
      type: TENANT_UPDATE,
      target: getSubscriptionTarget({ tenantId })
    },
    {
      type: BOARD_UPDATE,
      target: getSubscriptionTarget({ tenantId, boardId })
    },
    {
      type: CARD_CREATE,
      target: getSubscriptionTarget({ tenantId, boardId })
    },
    {
      type: COLUMN_CREATE,
      target: getSubscriptionTarget({ tenantId, boardId })
    },
    {
      type: CARD_UPDATE,
      target: getSubscriptionTarget({ tenantId, boardId })
    },
    {
      type: CARD_DELETE,
      target: getSubscriptionTarget({ tenantId, boardId })
    },
    {
      type: CARD_REORDER,
      target: getSubscriptionTarget({ tenantId, boardId })
    },
    {
      type: CARD_START_EDIT,
      target: getSubscriptionTarget({ tenantId, boardId })
    },
    {
      type: CARD_END_EDIT,
      target: getSubscriptionTarget({ tenantId, boardId })
    },
    {
      type: BOARD_LOCKED,
      target: getSubscriptionTarget({ tenantId, boardId })
    },
    {
      type: BOARD_UNLOCKED,
      target: getSubscriptionTarget({ tenantId, boardId })
    },
    ...getCardsSubscriptionPayload({ cards, tenantId, boardId })
  ]
  // push watchers subscription
  if (isBoardView) {
    payload.push({
      type: BOARD_WATCHERS_UPDATE,
      target: getSubscriptionTarget({
        tenantId,
        boardId
      })
    })
  }

  return {
    type: RESUBSCRIBE_EVENT,
    payload: {
      [UNSUBSCRIBE_EVENT]: [],
      [UNSUBSCRIBE_ALL_EVENT]: true,
      [SUBSCRIBE_EVENT]: payload
    }
  }
})

export const subscribeToCardsEvents = emitAction(({ boardId, tenantId, cards }) => {
  const payload = getCardsSubscriptionPayload({ boardId, tenantId, cards })
  return {
    type: RESUBSCRIBE_EVENT,
    payload: {
      [UNSUBSCRIBE_EVENT]: [],
      [UNSUBSCRIBE_ALL_EVENT]: false,
      [SUBSCRIBE_EVENT]: payload
    }
  }
})

export function toggleCommentsSubscription(payload) {
  return { type: TOGGLE_COMMENTS_SUBSCRIPTION, payload }
}

export function toggleMonitorSubscription(payload) {
  return { type: TOGGLE_MONITOR_SUBSCRIPTION, payload }
}

export const subscribeToCommentsEvents = emitAction(({ boardID, tenantID, cardUUID }) => {
  const target = getSubscriptionTarget({
    tenantId: tenantID,
    boardId: boardID,
    cardUuid: cardUUID
  })
  const payload = [
    {
      type: COMMENT_CREATE,
      target
    },
    {
      type: COMMENT_UPDATE,
      target
    },
    {
      type: COMMENT_DELETE,
      target
    }
  ]
  return {
    type: RESUBSCRIBE_EVENT,
    payload: {
      [UNSUBSCRIBE_EVENT]: [],
      [UNSUBSCRIBE_ALL_EVENT]: false,
      [SUBSCRIBE_EVENT]: payload
    }
  }
})

export const unsubscribeFromCommentsEvents = emitAction(({ boardID, tenantID, cardUUID }) => {
  const target = getSubscriptionTarget({
    tenantId: tenantID,
    boardId: boardID,
    cardUuid: cardUUID
  })
  const payload = [
    {
      type: COMMENT_CREATE,
      target
    },
    {
      type: COMMENT_UPDATE,
      target
    },
    {
      type: COMMENT_DELETE,
      target
    }
  ]
  return {
    type: RESUBSCRIBE_EVENT,
    payload: {
      [UNSUBSCRIBE_EVENT]: payload,
      [UNSUBSCRIBE_ALL_EVENT]: false,
      [SUBSCRIBE_EVENT]: []
    }
  }
})

export const unsubscribeFromAllEvents = emitAction(() => ({
  type: RESUBSCRIBE_EVENT,
  payload: {
    [UNSUBSCRIBE_EVENT]: [],
    [UNSUBSCRIBE_ALL_EVENT]: true,
    [SUBSCRIBE_EVENT]: []
  }
}))

export const subscribeToJobEvents = emitAction(({ jobs }) => {
  const payload = jobs.reduce(
    (currentPayload, jobId) => [
      ...currentPayload,
      {
        type: APP_SCHEMA_IMPORTED,
        target: getSubscriptionTarget({ jobId })
      },
      {
        type: JOB_COMPLETED,
        target: getSubscriptionTarget({ jobId })
      }
    ],
    []
  )
  return {
    type: RESUBSCRIBE_EVENT,
    payload: {
      [UNSUBSCRIBE_EVENT]: [],
      [UNSUBSCRIBE_ALL_EVENT]: false,
      [SUBSCRIBE_EVENT]: payload
    }
  }
})

export const subscribeToAppPublishEvents = emitAction(({ jobs }) => {
  const payload = jobs.reduce((acc, { data: { appId }, id: versionId }) => {
    acc.push({
      type: APP_PUBLISH_COMPLETED,
      target: getSubscriptionTarget({ appId, versionId })
    })
    acc.push({
      type: APP_PUBLISH_FAILED,
      target: getSubscriptionTarget({ appId, versionId })
    })

    return acc
  }, [])

  return {
    type: RESUBSCRIBE_EVENT,
    payload: {
      [UNSUBSCRIBE_EVENT]: [],
      [UNSUBSCRIBE_ALL_EVENT]: false,
      [SUBSCRIBE_EVENT]: payload
    }
  }
})

export const subscribeToMonitorEvents = emitAction(({ monitorId, tenantId }) => {
  const payload = [
    {
      type: MONITOR_UPDATE,
      target: getSubscriptionTarget({ monitorId, tenantId })
    }
  ]
  return {
    type: RESUBSCRIBE_EVENT,
    payload: {
      [UNSUBSCRIBE_EVENT]: [],
      [UNSUBSCRIBE_ALL_EVENT]: false,
      [SUBSCRIBE_EVENT]: payload
    }
  }
})

export const unsubscribeFromMonitorEvents = emitAction(({ monitorId, tenantId }) => {
  const payload = {
    type: MONITOR_UPDATE,
    target: getSubscriptionTarget({ monitorId, tenantId })
  }
  return {
    type: RESUBSCRIBE_EVENT,
    payload: {
      [UNSUBSCRIBE_EVENT]: payload,
      [UNSUBSCRIBE_ALL_EVENT]: false,
      [SUBSCRIBE_EVENT]: []
    }
  }
})

export const subscribeToLockEvents = emitAction(({ boardId, tenantId }) => {
  const payload = [
    {
      type: BOARD_LOCKED,
      target: getSubscriptionTarget({ tenantId, boardId })
    },
    {
      type: BOARD_UNLOCKED,
      target: getSubscriptionTarget({ tenantId, boardId })
    }
  ]
  return {
    type: RESUBSCRIBE_EVENT,
    payload: {
      [UNSUBSCRIBE_EVENT]: [],
      [UNSUBSCRIBE_ALL_EVENT]: false,
      [SUBSCRIBE_EVENT]: payload
    }
  }
})

export function subscribeToJobs() {
  return (dispatch, getState) => {
    const state = getState()

    const { jobs, publishJobs } = state.socket.jobs.reduce(
      (acc, job) => {
        if (!job.id || [EJobStatuses.DONE, EJobStatuses.ERROR].includes(job.status)) {
          return acc
        }

        if (job.type === EJobTypes.PUBLISH_APP_JOB) {
          acc.publishJobs.push(job)
        } else {
          acc.jobs.push(job.id)
        }

        return acc
      },
      { jobs: [], publishJobs: [] }
    )

    if (jobs.length) {
      dispatch(subscribeToJobEvents({ jobs }))
    }
    if (publishJobs.length) {
      dispatch(subscribeToAppPublishEvents({ jobs: publishJobs }))
    }
  }
}

export function subscribeToBoard(isBoardView) {
  return (dispatch, getState) => {
    const {
      profile: { activeOrganization },
      board: { currentBoard, cards }
    } = getState()

    dispatch(
      subscribeToBoardEvents({
        activeOrganization,
        currentBoard,
        cards,
        isBoardView
      })
    )
    // resubscribe to jobs because above we unsubscribed from all events
    dispatch(subscribeToJobs())
    dispatch(toggleBoardSubscription(true))
  }
}

export function subscribeToComments({ boardID, tenantID, cardUUID }) {
  return dispatch => {
    dispatch(subscribeToCommentsEvents({ boardID, tenantID, cardUUID }))
    dispatch(
      toggleCommentsSubscription({
        isSubscribedToComments: true,
        subscribedCard: { boardID, tenantID, cardUUID }
      })
    )
  }
}

export function unsubscribeFromComments() {
  return (dispatch, getState) => {
    const state = getState()
    if (state.socket.subscribedCard.cardUUID) {
      dispatch(unsubscribeFromCommentsEvents(state.socket.subscribedCard))
    }
    dispatch(
      toggleCommentsSubscription({
        isSubscribedToComments: false,
        subscribedCard: {}
      })
    )
  }
}

export function subscribeToCards({ cards, boardId, tenantId }) {
  return dispatch => {
    dispatch(subscribeToCardsEvents({ cards, boardId, tenantId }))
  }
}

export function subscribeToMonitor(payload) {
  return dispatch => {
    const { tenantId, boardId, cardUuid, monitorId, jobs } = payload
    dispatch(subscribeToMonitorEvents(payload))
    dispatch(
      toggleMonitorSubscription({
        isSubscribedToMonitor: true,
        subscribedJobs: jobs,
        subscribedMonitor: { tenantId, boardId, cardUuid, monitorId }
      })
    )
  }
}

export function unsubscribeFromMonitor({ tenantId, monitorId }) {
  return dispatch => {
    dispatch(
      toggleMonitorSubscription({
        isSubscribedToMonitor: false,
        subscribedMonitor: {},
        subscribedJobs: []
      })
    )
    dispatch(unsubscribeFromMonitorEvents({ tenantId, monitorId }))
  }
}

export const cardCreated = payload => async dispatch => {
  if (payload.etag) {
    dispatch(setCardsPositionsETag(payload.etag))
  }

  try {
    const { data: card } = await dispatch(
      getCard({
        ...payload,
        expand: {
          widgetsJSON: true,
          lockOwner: true
        },
        filters: {
          widgetsJSON: {
            fromFirstVisibleSection: true,
            showOnBoardView: true
          }
        },
        calledFrom: 'cardCreatedSocket'
      })
    )

    dispatch(
      subscribeToCards({
        cards: [card],
        tenantId: payload.tenantId,
        boardId: payload.boardId
      })
    )

    dispatch(replaceSectionsLoading(getInitialSectionsLoading([card])))

    dispatch({ type: CARD_CREATE_SOCKET, payload: card })
  } catch (err) {
    console.error(err)
  }
}

export function columnCreated(payload) {
  return dispatch => {
    if (payload.etag) {
      dispatch(setCardsPositionsETag(payload.etag))
    }

    dispatch(getCard({ ...payload, expand: {}, calledFrom: 'columnCreatedSocket' }))
      .then(response =>
        dispatch({
          type: COLUMN_CREATE_SOCKET,
          payload: response.data
        })
      )
      .catch(err => console.error(err))
  }
}

export const cardUpdatedInCardBuilder = payload => async dispatch => {
  try {
    const { data: card } = await dispatch(
      getCard({
        ...payload,
        expand: {
          widgetsJSON: true,
          lockOwner: true
        },
        calledFrom: 'cardUpdatedInCardBuilderSocket'
      })
    )

    dispatch(
      replaceSectionsLoading(
        getSectionsLoading({
          cardUuid: card.uuid,
          sections: getNonHiddenSections({
            height: card.height,
            hiddenSections: card.hiddenSections
          }),
          status: SECTION_STATUSES.LOADED
        })
      )
    )

    dispatch({ type: CARD_UPDATE_IN_CARD_BUILDER_SOCKET, payload: card })
  } catch (err) {
    console.error(err)
  }
}

export function cardUpdatedInBoardView(payload) {
  return dispatch => {
    dispatch(
      getCard({
        ...payload,
        expand: {
          lockOwner: true,
          widgetsJSON: true
        },
        calledFrom: 'cardUpdatedInBoardViewSocket'
      })
    )
      .then(response => {
        // Do not update card snapshot as it will be got with snapshot_update socket event

        const { snapshot, ...card } = response.data
        dispatch({
          type: CARD_UPDATE_IN_BOARD_VIEW_SOCKET,
          payload: card
        })

        const shouldUpdateBoardWidgets = payload.source === WORKFLOW_SOURCE

        if (shouldUpdateBoardWidgets) {
          const { uuid, widgets = [] } = response.data
          dispatch(updateBoardWidgets({ [uuid]: widgets }))
        }

        // https://leverxeu.atlassian.net/browse/EUPBOARD01-15513
        // Update cards in a column if column sorting has been updated via sockets
        if (payload.isCol) {
          dispatch(updateColumnCards({ ...payload, columnUuid: payload.cardUuid }))
        }
      })
      .catch(err => console.error(err))
  }
}

export function cardUpdated(payload) {
  return dispatch => {
    if (payload.etag) {
      dispatch(setCardsPositionsETag(payload.etag))
    }
    const isCardUpdatedInCardBuilder = payload.source === CARDBUILDER_SOURCE
    if (isCardUpdatedInCardBuilder) {
      dispatch(cardUpdatedInCardBuilder(payload))
    } else {
      dispatch(cardUpdatedInBoardView(payload))
    }
  }
}

export const cardReordered = payload => (dispatch, getState) => {
  const currentCardsPositionsETag = getState().board.cardsPositionsETag

  if (payload.etag === currentCardsPositionsETag) return

  dispatch(getCard({ ...payload, expand: {}, calledFrom: 'cardReorderedSocket' }))
    .then(response => {
      if (payload.etag) {
        dispatch(setCardsPositionsETag(payload.etag))
      }
      dispatch({
        type: CARD_REORDER_SOCKET,
        payload: { card: response.data, etag: payload.etag }
      })
    })
    .catch(err => console.error(err))
}

export function cardSnapshotUpdated(payload) {
  const updatedSnapshot = updateSnapshotsOnCards([payload])
  const snapshotLink = updatedSnapshot[0].snapshot

  const snapshotURL = addTimestampToUrl(snapshotLink, !!snapshotLink.includes('uploader'))

  return {
    type: CARD_SNAPSHOT_UPDATE_SOCKET,
    payload: { ...payload, snapshot: snapshotURL }
  }
}

export function cardDeleted(payload) {
  return dispatch => {
    if (payload.etag) {
      dispatch(setCardsPositionsETag(payload.etag))
    }
    dispatch({
      type: CARD_DELETE_SOCKET,
      payload
    })
  }
}

export function boardUpdated(payload) {
  return dispatch => {
    if (payload.etag) {
      dispatch(setCardsPositionsETag(payload.etag))
    }
    dispatch(getBoard(payload))
      .then(response => {
        // There is no need to use the isColumnsHidden field when updating the board.
        // The isColumnsHidden field is required to support legacy boards, but is now handled by the FE.

        const { isColumnsHidden, ...restData } = response.data

        dispatch({
          type: BOARD_UPDATE_SOCKET,
          payload: restData
        })
      })
      .catch(err => console.error(err))
  }
}

export function tenantUpdated(payload) {
  return {
    type: TENANT_UPDATE_SOCKET,
    payload
  }
}

export function organizationUpdated(payload) {
  return {
    type: ORGANIZATION_UPDATE_SOCKET,
    payload
  }
}

export function commentUpdated(payload) {
  return dispatch => {
    dispatch(
      setCardComments({
        tenantID: payload.tenantId,
        boardID: payload.boardId,
        cardUUID: payload.cardUuid
      })
    )
  }
}

export function boardWatchersUpdated(payload) {
  return {
    type: BOARD_WATCHERS_UPDATE_SOCKET,
    payload
  }
}

export function cardStartedEditing(payload) {
  return {
    type: CARD_START_EDIT_SOCKET,
    payload
  }
}

export function cardEndedEditing(payload) {
  return {
    type: CARD_END_EDIT_SOCKET,
    payload
  }
}

export function widgetUpdated(payload) {
  return (dispatch, getState) => {
    const editingWidget = editingWidgetSelector(getState())

    const isCardNameSyncWithProject =
      payload.widgetClassName === WIDGETS_IN_SYSTEM.ProjectTaskWidget.name && payload.cardNameInSync

    // Ignore updates for widgets that are being edited.
    // https://leverxeu.atlassian.net/browse/EUPBOARD01-16016
    const isWidgetEditing = editingWidget === payload.uuid
    const shouldUpdateWidget = isCardNameSyncWithProject || !isWidgetEditing

    if (shouldUpdateWidget) {
      dispatch({
        type: WIDGET_UPDATE_SOCKET,
        payload: { data: payload }
      })
    }
  }
}

export function widgetCreated(payload) {
  return {
    type: BULK_WIDGETS_CREATE_SOCKET,
    payload: { data: payload }
  }
}

export function widgetDeleted(payload) {
  return {
    type: WIDGET_DELETE_SOCKET,
    payload: { data: payload }
  }
}

export function toggleBoardCopying(payload) {
  return {
    type: TOGGLE_BOARD_COPYING,
    payload
  }
}

export function workflowJobCompleted(payload) {
  return {
    type: JOB_COMPLETED_SOCKET,
    payload
  }
}

export function jobCompleted(payload) {
  return dispatch => {
    const job = payload
    const isDone = job.status === EJobStatuses.DONE

    if ([EJobTypes.COPY_BOARD_JOB, EJobTypes.IMPORT_BOARDS_JOB].includes(job.type)) {
      dispatch(boardMenuRequest())
    }

    if (isDone && job.type === EJobTypes.EXPORT_BOARDS_JOB) {
      const { linkToBundle } = job.data
      automaticallyDownloadFile(getSnapshotUrl(linkToBundle))
    }

    dispatch({
      type: JOB_COMPLETED_SOCKET,
      payload: job
    })
  }
}

export function jobCompletedFromSocket(payload) {
  return dispatch =>
    getJob({ id: payload.jobId })
      .then(response => dispatch(jobCompleted(response.data)))
      .catch(err => console.error(err))
}

export const publishJobCompletedFromSocket = payload => dispatch => {
  const { appVersionId: versionId, status } = payload

  dispatch(
    jobCompleted({
      id: versionId,
      data: {},
      status,
      progress: { current: null, finishedAt: null, startedAt: null, total: 100 },
      type: EJobTypes.PUBLISH_APP_JOB
    })
  )
}

export function boardLocked() {
  return dispatch => {
    dispatch(toggleBoardCopying(true))
    dispatch(toggleDrawerDrag(true))
    dispatch({
      type: BOARD_LOCKED_SOCKET
    })
  }
}

export function boardUnlocked() {
  return dispatch => {
    dispatch(toggleBoardCopying(false))
    dispatch(toggleDrawerDrag(false))
    dispatch({
      type: BOARD_UNLOCKED_SOCKET
    })
  }
}

export function monitorUpdated(payload) {
  return dispatch => {
    dispatch(poleWorkflowExecutionJobs(payload))
    dispatch({
      type: MONITOR_UPDATE_SOCKET,
      payload
    })
  }
}

export function resolveSocketAction(store, payload, type) {
  switch (type) {
    case CARD_CREATE:
      store.dispatch(cardCreated(payload))
      break
    case CARD_UPDATE:
      store.dispatch(cardUpdated(payload))
      break
    case CARD_SNAPSHOT_UPDATE:
      store.dispatch(cardSnapshotUpdated(payload))
      break
    case CARD_DELETE:
      store.dispatch(cardDeleted(payload))
      break
    case CARD_REORDER:
      store.dispatch(cardReordered(payload))
      break
    case COLUMN_CREATE:
      store.dispatch(columnCreated(payload))
      break
    case COMMENT_UPDATE:
    case COMMENT_CREATE:
    case COMMENT_DELETE:
      store.dispatch(commentUpdated(payload))
      break
    case BOARD_UPDATE:
      store.dispatch(boardUpdated(payload))
      break
    case TENANT_UPDATE: {
      store.dispatch(tenantUpdated(payload))

      if (payload.id) {
        useTeamStore.setState({ id: payload.id })
        useTeamStore.getState().fetchMembers()
      }

      break
    }
    case ORGANIZATION_UPDATE:
      store.dispatch(organizationUpdated(payload))
      break
    case BOARD_WATCHERS_UPDATE:
      store.dispatch(boardWatchersUpdated(payload))
      break
    case CARD_START_EDIT:
      store.dispatch(cardStartedEditing(payload))
      break
    case CARD_END_EDIT:
      store.dispatch(cardEndedEditing(payload))
      store.dispatch(clearCardSnapshot({ uuid: payload.cardUuid }))
      break
    case WIDGET_UPDATE:
      store.dispatch(widgetUpdated(payload))

      if (isWidgetFromFirstSection(payload)) {
        store.dispatch(clearCardSnapshot({ uuid: payload.cardUuid }))
      }
      break
    case BULK_WIDGETS_CREATE:
      store.dispatch(widgetCreated(payload))

      if (isAnyWidgetFromFirstSection(payload) && payload.length) {
        store.dispatch(clearCardSnapshot({ uuid: payload[0].cardUuid }))
      }

      break
    case WIDGET_DELETE:
      store.dispatch(widgetDeleted(payload))

      store.dispatch(clearCardSnapshot({ uuid: payload.cardUuid }))
      break
    case JOB_COMPLETED:
      store.dispatch(jobCompletedFromSocket(payload))
      break
    case BOARD_LOCKED:
      store.dispatch(boardLocked())
      break
    case BOARD_UNLOCKED:
      store.dispatch(boardUnlocked())
      break
    case MONITOR_UPDATE:
      store.dispatch(monitorUpdated(payload))
      break
    case APP_PUBLISH_COMPLETED:
      store.dispatch(
        publishJobCompletedFromSocket({
          ...payload,
          status: EJobStatuses.DONE
        })
      )
      break
    case APP_PUBLISH_FAILED:
      store.dispatch(
        publishJobCompletedFromSocket({
          ...payload,
          status: EJobStatuses.ERROR
        })
      )
      break
    default:
  }
}

export function toggleBoardWatching(payload) {
  return { type: TOGGLE_BOARD_WATCHING, payload }
}

export const boardStartWatchEvent = emitAction((payload, callback) => ({
  type: BOARD_START_WATCH,
  payload,
  callback
}))

export const boardEndWatchEvent = emitAction(payload => ({
  type: BOARD_END_WATCH,
  payload
}))

export function boardStartWatch({ tenantId, boardId }) {
  return dispatch => {
    dispatch(
      boardStartWatchEvent({ tenantId, boardId }, watchingUsers => {
        dispatch(
          toggleBoardWatching({
            watchingUsers
          })
        )
      })
    )
    dispatch(
      toggleBoardWatching({
        isWatchingBoard: true,
        watchingBoard: { tenantId, boardId }
      })
    )
  }
}

export function boardEndWatch({ tenantId, boardId }) {
  return (dispatch, getState) => {
    if (getState().socket.isWatchingBoard) {
      dispatch(boardEndWatchEvent({ tenantId, boardId }))
    }
    dispatch(
      toggleBoardWatching({
        isWatchingBoard: false,
        watchingBoard: {},
        watchingUsers: []
      })
    )
  }
}

export const unsubscribeFromBoard = () => (dispatch, getState) => {
  dispatch(boardEndWatch(getState().socket.watchingBoard))

  dispatch(unsubscribeFromAllEvents())
  // resubscribe to jobs because above we unsubscribed from all events
  dispatch(subscribeToJobs())
  dispatch(toggleBoardSubscription(false))
}

export function resolveSocketConnection(store) {
  const {
    socket: {
      isSubscribedToBoard,
      isSubscribedToComments,
      isWatchingBoard,
      subscribedCard,
      watchingBoard
    },
    profile: { activeOrganization },
    board: { currentBoard, cards }
  } = store.getState()

  if (isSubscribedToBoard) {
    store.dispatch(
      subscribeToBoardEvents({
        activeOrganization,
        currentBoard,
        cards,
        isWatchingBoard
      })
    )
  }
  if (isSubscribedToComments) {
    store.dispatch(subscribeToComments(subscribedCard))
  }
  if (isWatchingBoard) {
    store.dispatch(boardStartWatch(watchingBoard))
  }
  if (isSocketFallbackEnabled()) {
    store.dispatch(disableSocketFallback())
  }
}

export function resolveSocketDisconnection(store) {
  if (!isSocketFallbackEnabled()) {
    store.dispatch(enableSocketFallback())
  }
}

export function clearSocketConnection(isCloseSocketConnection) {
  return (dispatch, getState) => {
    dispatch(unsubscribeFromAllEvents())
    dispatch(boardEndWatch(getState().socket.watchingBoard))
    if (isCloseSocketConnection) {
      closeSocketConnection()
    }
  }
}

export function addNewJob(payload) {
  return dispatch => {
    dispatch({ type: ADD_NEW_JOB, payload })
    dispatch(subscribeToJobs())
  }
}

export function removeJob(payload) {
  return { type: REMOVE_JOB, payload }
}
