import { SECTION_STATUSES } from 'constants/detailedView/sectionsLoading'
import { getNumberedArray } from 'helpers/common/commonHelpers'
import { asyncInterval } from 'helpers/common/timeoutHelpers'
import { PDF_GENERATION_STATUS } from 'constants/detailedView/constants'
import { flagsList, hasFlag } from 'helpers/widget/widgetDataHelpers'
import { getCardSectionCount } from 'features/cards/cards.helpers'

export const getInitialSectionsLoading = cards =>
  cards.reduce((acc, card) => {
    const { uuid, isCol } = card

    if (isCol) {
      return acc
    }

    acc[uuid] = {}

    return acc
  }, {})

export const getSectionsLoading = ({ cardUuid, sections, status }) => ({
  [cardUuid]: sections.reduce((acc, section) => {
    acc[section] = { status }

    return acc
  }, {})
})

export const getPrevVisibleSection = ({ section, hiddenSections = [] }) => {
  let prevSection = section !== 0 ? section - 1 : null

  while (prevSection !== null && hiddenSections.includes(prevSection)) {
    prevSection = prevSection !== 0 ? prevSection - 1 : null
  }

  return prevSection
}

export const getNextVisibleSection = ({ section, cardHeight, hiddenSections = [] }) => {
  const sectionsCount = getCardSectionCount(cardHeight)

  let nextSection = section + 1

  while (nextSection < sectionsCount) {
    if (hiddenSections.includes(nextSection)) {
      nextSection += 1
    } else {
      return nextSection
    }
  }

  return null
}

export const getNeighbourSections = args => {
  const { section, cardHeight, hiddenSections = [] } = args

  const sections = [
    getPrevVisibleSection({ section, hiddenSections }),
    section,
    getNextVisibleSection({ section, cardHeight, hiddenSections })
  ]

  return sections.filter(item => item !== null)
}

export const filterSectionsToFetch = ({ sections, sectionsLoading = {} }) =>
  sections.reduce((acc, section) => {
    const sectionLoading = sectionsLoading[section]

    const finishedStatuses = [SECTION_STATUSES.LOADED, SECTION_STATUSES.LOADING]

    if (finishedStatuses.includes(sectionLoading?.status ?? null)) {
      return acc
    }

    acc.push(section)

    return acc
  }, [])

export const getNonHiddenSections = ({ height, hiddenSections = [] }) => {
  const sectionsCount = getCardSectionCount(height)

  return getNumberedArray(sectionsCount).reduce((acc, section) => {
    if (hiddenSections.includes(section)) {
      return acc
    }

    acc.push(section)

    return acc
  }, [])
}

/**
 * @param args {Object}
 * @param args.cardHeight {number}
 * @param args.hiddenSections {number[] | undefined}
 * @param args.sectionsLoading {Record<number, string> | undefined}
 * @returns {boolean}
 */

export const areAllSectionsLoaded = ({ cardHeight, hiddenSections = [], sectionsLoading = {} }) => {
  const sectionsCount = getCardSectionCount(cardHeight)
  const visibleSectionsCount = sectionsCount - hiddenSections.length

  const loadedSectionsCount = Object.values(sectionsLoading).reduce(
    (count, section) => (section.status === SECTION_STATUSES.LOADED ? count + 1 : count),
    0
  )

  return loadedSectionsCount === visibleSectionsCount
}
const getSectionLoadedStatus = (section, widgets, alreadyLoadedSections) => {
  // we need to check if the section has widgets on it
  // if not - return true;
  // if has - check if all of them are loaded
  const sectionWidgets = widgets.filter(widget => widget.section === section)
  const visibleOnDetailedWidgets = sectionWidgets.filter(
    widget => !hasFlag(widget.flags, flagsList.isHiddenOnDetailed)
  )
  const hasWidgets = !!visibleOnDetailedWidgets.length
  if (!hasWidgets) return true
  return alreadyLoadedSections.includes(section)
}
const widgetLoadingStateCheck = async (getState, section, cardUuid) => {
  const isPDFGeneration = getState().detailed.isPDFGeneration
  if (!isPDFGeneration) {
    return Promise.reject(new Error(PDF_GENERATION_STATUS.REJECTED))
  }
  const alreadyLoadedSections = getState().detailed.renderedSections
  const sectionsLoading = getState().detailed.sectionsLoading[cardUuid]
  const currentSectionLoading = sectionsLoading && sectionsLoading[section]
  const widgets = getState().board.widgetsMap[cardUuid]
  const isSectionLoaded =
    currentSectionLoading && currentSectionLoading.status === SECTION_STATUSES.LOADED
  const isReady = isSectionLoaded
    ? getSectionLoadedStatus(section, widgets, alreadyLoadedSections)
    : false

  return Promise.resolve(isReady)
}

export const handleSectionLoaded = async (section, getState, cardUuid) => {
  const sectionElement = document.querySelector(`div[data-pagenumber='${section}']`)

  if (!sectionElement) return Promise.resolve()
  sectionElement.scrollIntoView(false)

  try {
    await asyncInterval(
      widgetLoadingStateCheck.bind(null, getState, section, cardUuid),
      500,
      5,
      true
    )
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(e)
  }
}

export const areAllSectionsRendered = args => {
  const { widgets = [], hiddenSections = [], renderedSections = [] } = args

  let allWidgetsRendered

  if (widgets?.length === 0) {
    allWidgetsRendered = true
  } else {
    allWidgetsRendered = !widgets.some(
      widget =>
        !hiddenSections.includes(widget.section) && !renderedSections.includes(widget.section)
    )
  }

  return allWidgetsRendered
}
