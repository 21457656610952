import React from 'react'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

import BlockInputContainer from '../../../containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from '../../common/AnyLevelDropdown'

import '../../../scss/workflowBuilder/addCommentBlock/add-comment-block.scss'

const AddCardCommentBlock = props => {
  const {
    block: { id, input, error },
    sourceInputType,
    sourceInputFieldLabel,
    sourceInputFieldPlaceholder,
    handleReset,
    handleInputChange,
    children
  } = props

  return (
    <div className="add-comment-block">
      <BlockInputContainer
        type={sourceInputType}
        blockId={id}
        value={input[EFieldKeys.SOURCE_INPUT]}
      >
        {({ selectedItem, items, searchItems }) => (
          <AnyLevelDropdown
            supportHistory
            title={sourceInputFieldLabel}
            placeholder={sourceInputFieldPlaceholder}
            items={items}
            searchItems={searchItems}
            onSelect={handleInputChange.bind(null, EFieldKeys.SOURCE_INPUT)}
            onReset={handleReset.bind(null, EFieldKeys.SOURCE_INPUT)}
            value={selectedItem}
            type={sourceInputType}
            error={error[EFieldKeys.SOURCE_INPUT]}
          />
        )}
      </BlockInputContainer>
      {children}
    </div>
  )
}

export default AddCardCommentBlock
