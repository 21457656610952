import React from 'react'

import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'

import GetWidgetDataBlock from './GetWidgetDataBlock'

const GetWidgetData = props => {
  const { block, tenantId, updateWorkflowBlock, changeWorkflowBlockInput } = props

  return (
    <GetWidgetDataBlock
      block={block}
      tenantId={tenantId}
      inputFieldLabel={messages.WIDGET_LEVEL_INPUT}
      inputFieldPlaceholder={messages.WIDGET_LEVEL_INPUT_PLACEHOLDER}
      outputFieldPlaceholder={messages.SELECT_WIDGET_ATTRIBUTE}
      inputType={OBJECT_TYPES.WIDGET}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
      noItemsPlaceholder={messages.NO_WIDGETS_IN_TEAM}
      noItemsFoundPlaceholder={messages.NO_WIDGETS_FOUND}
    />
  )
}

export default GetWidgetData
