import InfoIcon from 'assets/images/icons/info.svg?react'
import { ERequestHeaders } from 'constants/common'
import { ExpandableSection } from 'components/common/expandableSection/expandableSection'
import { Tooltip } from 'components/common/tooltip/tooltip'
import './hidden-headers.scss'

const HIDDEN_HEADERS = [
  { key: ERequestHeaders.ContentLength, value: '<calculated when request is sent>' },
  { key: ERequestHeaders.Host, value: '<calculated when request is sent>' },
  { key: ERequestHeaders.UserAgent, value: 'Praxie' },
  { key: ERequestHeaders.Accept, value: '*/*' },
  { key: ERequestHeaders.AcceptEncoding, value: 'gzip, deflate, br' },
  { key: ERequestHeaders.ContentType, value: '<calculated when request is sent>' }
]

export const HiddenHeaders = () => {
  const header = (
    <>
      <span className="title">Hidden Headers</span>
      <Tooltip
        id="hidden-headers-tooltip"
        placement="top"
        title="Hidden headers will be automatically added to the request"
        wrapperClassName="hidden-headers-tooltip"
        shouldWrapChildrenWithDiv
      >
        <InfoIcon className="info-icon" />
      </Tooltip>
    </>
  )

  return (
    <ExpandableSection
      className="wf-rest-api-block-hidden-headers"
      title={header}
      expandArrowPlacement="left"
      expandArrowOrientation="horizontal"
      shouldExpandByDefault={false}
    >
      <div className="hidden-headers-content">
        {HIDDEN_HEADERS.map(({ key, value }) => (
          <div key={key} className="hidden-header">
            <div className="header-key" title={key}>
              {key}
            </div>
            <div className="header-value" title={value}>
              {value}
            </div>
          </div>
        ))}
      </div>
    </ExpandableSection>
  )
}
