import messages from '../../../constants/messages'
import type {
  IRequestError,
  TAssociatedWidgetsDataResponse,
  TAssociatedWidgetsRequest,
  TAssociatedWidgetsResponse,
  TCredentialModalData,
  TCredentialSettingsError
} from './credentialSettings.types'

export const validateCreationData = (
  data: TCredentialModalData,
  isCreate?: boolean
): { isValid: boolean; errors: TCredentialSettingsError } => {
  let isValid = true
  let errors = {} as TCredentialSettingsError

  if (!data.name) {
    isValid = false
    errors = { ...errors, name: messages.emptyField }
  }

  if (!data.login) {
    isValid = false
    errors = { ...errors, login: messages.emptyField }
  }

  if (!data.password && isCreate) {
    isValid = false
    errors = { ...errors, password: messages.emptyField }
  }

  return { isValid, errors }
}

export const isCredentialsRequestError = (value: unknown): value is IRequestError =>
  !!value && typeof value === 'object' && 'errorCode' in value && 'message' in value

export const getAssociatedWidgetsDataPayload = (
  widgetsPayload: Array<TAssociatedWidgetsResponse>
) =>
  widgetsPayload.reduce(
    (accum: TAssociatedWidgetsRequest, widgetData: TAssociatedWidgetsResponse) => {
      const { organizationId, tenantId, boardId, cardUuid, widgetId } = widgetData
      if (!accum.organizationId) accum.organizationId = organizationId
      if (!accum.tenantIds.includes(tenantId)) accum.tenantIds.push(tenantId)
      if (!accum.boardIds.includes(boardId)) accum.boardIds.push(boardId)
      if (!accum.cardIds.includes(cardUuid)) accum.cardIds.push(cardUuid)
      if (!accum.widgetIds.includes(widgetId)) accum.widgetIds.push(widgetId)
      return accum
    },
    {
      organizationId: '',
      tenantIds: [],
      boardIds: [],
      cardIds: [],
      widgetIds: []
    }
  )

export const mapAssociatedWidgetsData = (
  widgetsIdData: Array<TAssociatedWidgetsResponse>,
  widgetsData: Array<TAssociatedWidgetsDataResponse>
) => {
  return widgetsIdData.map(widgetIdData => {
    const tenant = widgetsData.find(widget => widgetIdData.tenantId === widget.id)
    const board = tenant?.boards.find(boardItem => boardItem.id === widgetIdData.boardId)
    const card = board?.cards.find(cardItem => cardItem.uuid === widgetIdData.cardUuid)
    const widget = card?.widgets.find(widgetItem => widgetItem.id === widgetIdData.widgetId)
    return {
      widgetName: widget?.widgetTitle || '',
      teamName: tenant?.name || '',
      cardName: card?.name || ''
    }
  })
}
