import { useCallback } from 'react'
import { changeWorkflowBlockInput } from 'actions'
import { useDispatch } from 'react-redux'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import { clearBlockError } from 'helpers/workflowBuilder/blocksValidation'
import FormField from 'components/common/FormField'
import { EOutputFormatType } from 'components/workflowBuilder/widgetDataBlocks/convertWidgetData/convertWidgetData.constants'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import { useUpdateBlockMeta } from 'features/workflow/workflowBuilder/model/workflowBuilder.hooks'
import { ConvertWidgetDataBlockFormulaInput } from './components/convertWidgetDataBlockFormulaInput'
import { ConvertWidgetDataBlockInputs } from './components/convertWidgetDataBlockInputs'
import { ConvertWidgetDataBlockOutputSelector } from './components/convertWidgetDataBlockOutputSelector'
import './convert-widget-data-block.scss'

type TProps = {
  block: IWorkflowBlock
}

export const ConvertWidgetData = ({ block }: TProps) => {
  const { id, error, meta, input } = block

  const dispatch = useDispatch()
  const updateBlockMeta = useUpdateBlockMeta(id)

  const params = meta[EFieldKeys.PARAMS] as string[]

  const getFieldName = useCallback(
    (fieldKey: string) => {
      return Object.keys(input).find(key => {
        const isCorrectKey = key.startsWith(fieldKey)
        const isEmptyInput = !input[key]
        if (params.includes(key)) return false
        return isCorrectKey && isEmptyInput
      })
    },
    [input, params]
  )

  const updateParams = useCallback(
    (index: number, value: string | null) => {
      const updatedParams = params.map((param: string | null, idx: number) => {
        if (idx === index) {
          return value
        }
        return param
      })
      updateBlockMeta({ [EFieldKeys.PARAMS]: updatedParams })
    },
    [params, updateBlockMeta]
  )

  const clearInput = useCallback(
    (index: number) => {
      const fieldName = params[index]
      if (!fieldName) {
        return
      }
      dispatch(
        // @ts-expect-error
        changeWorkflowBlockInput({
          fieldName,
          blockId: id,
          outputId: null,
          oldOutputId: input[fieldName] || null,
          error
        })
      )
    },
    [params, dispatch, error, id, input]
  )

  const handleInputChange = useCallback(
    (index: number, item: IWorkflowDropdownValue) => {
      const fieldKey =
        item.type === OBJECT_TYPES.WIDGET_DATA ? EFieldKeys.UBF_CELL : EFieldKeys.STRING_INPUT
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const isKeyExist = !item.type || params[index]?.startsWith(fieldKey)

      if (isKeyExist) {
        // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
        const fieldName = params[index] as string

        dispatch(
          // @ts-expect-error
          changeWorkflowBlockInput({
            fieldName,
            blockId: id,
            outputId: item.id,
            oldOutputId: input[fieldName] || null,
            error
          })
        )
        return
      }
      const updatedFieldName = getFieldName(fieldKey)
      if (!updatedFieldName) {
        return
      }
      const updatedError = clearBlockError(error, index)
      const prevKey = params[index]
      if (prevKey) {
        clearInput(index)
      }
      updateParams(index, updatedFieldName)
      dispatch(
        // @ts-expect-error
        changeWorkflowBlockInput({
          fieldName: updatedFieldName,
          blockId: id,
          outputId: item.id,
          oldOutputId: input[updatedFieldName] || null,
          error: updatedError
        })
      )
    },
    [params, error, dispatch, getFieldName, id, input, updateParams, clearInput]
  )

  const handleReset = useCallback(
    (index: number) => {
      clearInput(index)
      updateParams(index, null)
    },
    [clearInput, updateParams]
  )

  const handleAddInput = useCallback(() => {
    updateBlockMeta({ [EFieldKeys.PARAMS]: [...params, null] })
  }, [params, updateBlockMeta])

  const handleDeleteInput = useCallback(
    (index: number) => {
      const fieldName = params[index]
      const updatedParams = params.filter((_: string, idx: number) => index !== idx)
      updateBlockMeta({
        [EFieldKeys.PARAMS]: updatedParams
      })
      if (fieldName) {
        clearInput(index)
      }
    },
    [params, updateBlockMeta, clearInput]
  )

  return (
    <div className="workflow-convert-widget-data-block">
      <FormField label={messages.INPUTS} id="convert-widget-data-inputs" className="inputs-fields">
        <ConvertWidgetDataBlockInputs
          handleDeleteInput={handleDeleteInput}
          handleInputChange={handleInputChange}
          handleReset={handleReset}
          handleAddInput={handleAddInput}
          block={block}
        />
      </FormField>
      <ConvertWidgetDataBlockFormulaInput
        formula={meta[EFieldKeys.FORMULA] as string}
        error={error[EFieldKeys.FORMULA] as string | null}
        updateBlockMeta={updateBlockMeta}
      />
      <ConvertWidgetDataBlockOutputSelector
        blockId={block.id}
        dataType={meta[EFieldKeys.DATA_TYPE] as OBJECT_TYPES}
        type={meta[EFieldKeys.TYPE] as EOutputFormatType}
        updateBlockMeta={updateBlockMeta}
      />
    </div>
  )
}
