import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import { debounce } from 'helpers/common/timeoutHelpers'
import messages from 'constants/messages'
import DotsSpinner from '../spinners/DotsSpinner'
import 'scss/common/autosuggest-input.scss'

const SuggestionsList = ({ suggestions, isLoading, onSelect }) => {
  const noItems = !suggestions.length
  return (
    <div className="suggestions-wrapper">
      {isLoading && <DotsSpinner color="dark" customClass="suggestions-loader" />}
      {noItems && !isLoading && <div className="empty-state">{messages.NO_RESULTS_FOUND}</div>}
      {!isLoading && (
        <ul className="suggestions-list">
          {suggestions.map(suggest => (
            <li className="suggest-item" key={suggest} onMouseDown={onSelect.bind(null, suggest)}>
              {suggest}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

class AutosuggestInput extends Component {
  inputRef = React.createRef()

  debouncedChange = debounce(value => {
    this.getSuggestions(value)
  }, 300)

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      suggestions: [],
      isLoading: false
    }
  }

  onChange = () => {
    const value = this.getRefValue()
    if (value !== this.props.value) {
      this.changeValue(value)
      this.debouncedChange(value)
    }
  }

  onFocus = () => {
    const { value } = this.getRefValue()
    if (value && value.trim().length > 2) {
      this.setState({ isOpen: true })
    }
  }

  onBlur = event => {
    const value = event.target.value
    this.changeValue(value)
  }

  changeValue = value => {
    const { onBlur } = this.props
    if (onBlur && value !== this.props.value) {
      onBlur(value)
    }
  }

  getRefValue = () => this.inputRef.current?.value || ''

  onSelect = (value, event) => {
    event.stopPropagation()
    this.changeValue(value)
    this.setState({
      isOpen: false
    })
  }

  getSuggestions = value => {
    if (value.trim().length > 2) {
      this.setState({
        isLoading: true
      })
      this.loadSuggestions(value)
        .then(suggestions => {
          this.toggleSuggestions(suggestions, true)
          this.setState({
            isLoading: false
          })
        })
        .catch(() =>
          this.setState({
            isLoading: false
          })
        )
    }
  }

  loadSuggestions = value => {
    if (this.props.loadSuggestions) {
      return this.props.loadSuggestions(value)
    }
    return Promise.resolve([])
  }

  toggleSuggestions(suggestions, isOpen) {
    this.setState({
      suggestions,
      isOpen
    })
  }

  render() {
    const { isOpen, suggestions, isLoading } = this.state
    const { value, label, disabled, placeholder, className, error, maxLength } = this.props
    return (
      <div className="autosuggest-input-wrapper">
        <p className="input-field-label"> {label} </p>
        <input
          ref={this.inputRef}
          onFocus={this.onFocus}
          type="text"
          value={value}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder={placeholder}
          className={classNames('input-field autosuggest-input', className, {
            invalid: !disabled && error
          })}
          disabled={disabled}
          maxLength={maxLength}
        />
        {isOpen && (
          <SuggestionsList
            suggestions={suggestions}
            isLoading={isLoading}
            onSelect={this.onSelect}
          />
        )}
        {!disabled && error && <p className="field-error">{error} </p>}
      </div>
    )
  }
}

AutosuggestInput.defaultProps = {
  label: '',
  placeholder: '',
  className: '',
  error: '',
  value: '',
  disabled: false,
  maxLength: 50
}

AutosuggestInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  value: PropTypes.string
}

export default onClickOutside(AutosuggestInput)
