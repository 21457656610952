import { type ReactNode } from 'react'
import './box-label.scss'

type TProps = {
  shouldShowLengthIndicator?: boolean
  label?: ReactNode
  maxLength: number
  textLimit: number | null
}

export const BoxLabel = ({ shouldShowLengthIndicator, label, maxLength, textLimit }: TProps) => {
  return (
    <div className="token-input-box-label">
      <div>{label}</div>
      {shouldShowLengthIndicator && (
        <div className="length-indicator">{`${maxLength - (textLimit ?? 0)}/${maxLength}`}</div>
      )}
    </div>
  )
}
