import { type Component, type JSX, type ReactNode, forwardRef } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import DotsSpinner from 'components/spinners/DotsSpinner'
import './icon-button.scss'

type TProps = JSX.IntrinsicElements['button'] & {
  as?: typeof Component
  appearance?: 'primary' | 'secondary' | 'secondary2' | 'ribbon' | 'light-bg'
  size?: 'small' | 'medium' | 'large'
  icon: ReactNode
  iconPosition?: 'left' | 'right'
  iconSeparated?: boolean
  busy?: boolean
}

export const IconButton = forwardRef<HTMLButtonElement, TProps>(
  (
    {
      as: Component = 'button',
      id,
      type = 'button',
      size = 'medium',
      icon,
      iconPosition = 'left',
      iconSeparated = true,
      children,
      className,
      appearance = 'primary',
      onClick,
      busy = false,
      disabled = false,
      autoFocus,
      ...restProps
    },
    ref
  ) => {
    return (
      <Component
        ref={ref}
        id={id}
        type={type}
        className={classNames('up-button', 'up-icon-button', appearance, size, className, {
          'icon-separated': iconSeparated,
          disabled,
          busy
        })}
        autoFocus={autoFocus}
        disabled={disabled || busy}
        onClick={onClick}
        {...restProps}
      >
        {busy && (
          <motion.div className="button-loader" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <DotsSpinner className="spinner" color={appearance === 'primary' ? 'light' : 'dark'} />
          </motion.div>
        )}
        <span
          className={classNames('icon-button-icon-wrapper', {
            'left-icon': iconPosition === 'left',
            'right-icon': iconPosition === 'right'
          })}
        >
          {icon}
        </span>
        <span className="icon-button-text">{children}</span>
      </Component>
    )
  }
)
