import React from 'react'
import classNames from 'classnames'
import { OBJECT_TYPES } from 'constants/workflows'
import { Tooltip } from 'components/common/tooltip/tooltip'

const StringCommentValue = ({ value, title, subtitle, error, isUndefined }) => (
  <Tooltip id="comment-string" placement="top" title={subtitle}>
    <div
      data-field-value={JSON.stringify(value)}
      className={classNames('any-level-dropdown__comment-value', {
        'is-undefined': isUndefined || error,
        'is-string': value && value.type === OBJECT_TYPES.STRING,
        'is-widget-data': value && value.type === OBJECT_TYPES.WIDGET_DATA
      })}
    >
      <span className="comment-bracket">[</span>
      {title}
      <span className="comment-bracket">]</span>
    </div>
  </Tooltip>
)

export default StringCommentValue
