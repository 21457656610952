import { Component } from 'react'
import ObjectAttributeSelector from 'components/workflowBuilder/common/ObjectAttributeSelector'

export const getAttributeOptions = (type, types) =>
  Object.entries(types).reduce((acc, [key, attribute]) => {
    if (attribute[type]) {
      acc.push({
        value: key,
        label: attribute.name
      })
    }

    return acc
  }, [])

const getAttributeParams = (attribute, attributes) =>
  (attribute && attributes[attribute] && attributes[attribute].params) || {}

class ObjectAttributeSelectorContainer extends Component {
  onChangeAttribute = data => {
    const { onChangeAttribute } = this.props
    const objectAttribute = {
      attribute: data.value,
      params: {
        x: 1,
        y: 1
      }
    }

    onChangeAttribute(objectAttribute)
  }

  onChangeAttributeParam = (key, value) => {
    const { onChangeAttribute, objectAttribute } = this.props
    const newAttribute = {
      ...objectAttribute,
      params: {
        ...objectAttribute.params,
        [key]: value
      }
    }

    onChangeAttribute(newAttribute)
  }

  blurNumericField = (e, propName) => {
    const { value } = e.target

    // because coordinates start from 1
    if (!value || (value && parseInt(value, 10) === 0)) {
      this.onChangeAttributeParam(propName, 1)
    }
  }

  changeNumericValue = (e, propName) => {
    const { value } = e.target
    const digitsPattern = /^[0-9]*$/gi

    if (digitsPattern.test(value)) {
      this.onChangeAttributeParam(propName, value)
    }
  }

  render() {
    const {
      objectAttribute,
      type,
      attributeTypes,
      disabled,
      placeholder,
      error,
      showAttributeSelector
    } = this.props

    const attribute = objectAttribute && objectAttribute.attribute
    const params = objectAttribute && objectAttribute.params
    const attributesOptions = getAttributeOptions(type, attributeTypes)
    const paramsOptions = getAttributeParams(attribute, attributeTypes)

    return (
      <ObjectAttributeSelector
        type={type}
        attribute={attribute}
        attributesOptions={attributesOptions}
        params={params}
        paramsOptions={paramsOptions}
        blurNumericField={this.blurNumericField}
        changeNumericValue={this.changeNumericValue}
        disabled={disabled}
        placeholder={placeholder}
        error={error}
        showAttributeSelector={showAttributeSelector}
        onChangeAttribute={this.onChangeAttribute}
      />
    )
  }
}

export default ObjectAttributeSelectorContainer
