import { type SelectChangeEvent } from '@mui/material/Select/Select'
import { updateWorkflowTriggerBlock, validateBlock } from 'actions'
import { produce } from 'immer'
import { useDispatch } from 'react-redux'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { Select } from 'components/common/select/select'
import { type TWorkflowScheduleSettings } from 'features/workflow/workflow.types'
import { type TTriggerBlockError } from '../triggerBlock.types'
import { MonthsSection } from './monthsSection/monthsSection'
import { WF_SCHEDULED_DEFAULT_MONTHS } from './monthsSection/monthsSection.constants'
import { RunTimesSection } from './runTimesSection/runTimesSection'
import {
  WF_SCHEDULED_DEFAULT_MONTH_DAYS,
  WF_SCHEDULED_DEFAULT_SETTINGS,
  WF_SCHEDULED_DEFAULT_WEEKDAYS,
  WF_SCHEDULED_RUNS_FREQUENCY_OPTIONS
} from './scheduleParams.constants'
import { formatISOToUTCHours } from './scheduleParams.helpers'
import { EWFScheduledRunsFrequency } from './scheduleParams.types'
import { WeekdaysSection } from './weekdaysSection/weekdaysSection'
import './schedule-params.scss'

type TProps = {
  id: string | null
  meta: { [key in EFieldKeys]: string }
  scheduleSettings?: TWorkflowScheduleSettings
  error?: TTriggerBlockError
}

export const ScheduleParams = ({ id, meta, scheduleSettings, error }: TProps) => {
  const dispatch = useDispatch()

  const { frequency, hours, daysOfWeek, daysOfMonth, monthsOfYear } =
    scheduleSettings ?? WF_SCHEDULED_DEFAULT_SETTINGS

  const handleUpdateWFTriggerValue = (value: Partial<TWorkflowScheduleSettings>) => {
    const basicScheduleSettings: TWorkflowScheduleSettings = {
      frequency,
      hours,
      ...value
    }

    const updatedScheduleSettings = produce(basicScheduleSettings, draft => {
      if (draft.frequency === EWFScheduledRunsFrequency.WEEKLY) {
        draft.daysOfWeek = value.daysOfWeek ?? daysOfWeek ?? WF_SCHEDULED_DEFAULT_WEEKDAYS
      }

      if (draft.frequency === EWFScheduledRunsFrequency.MONTHLY) {
        draft.monthsOfYear = value.monthsOfYear ?? monthsOfYear ?? WF_SCHEDULED_DEFAULT_MONTHS
        draft.daysOfMonth = value.daysOfMonth ?? daysOfMonth ?? WF_SCHEDULED_DEFAULT_MONTH_DAYS
      }
    })

    const payload = {
      id,
      data: {
        meta,
        scheduleSettings: updatedScheduleSettings
      }
    }

    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(updateWorkflowTriggerBlock(payload))

    // validate block if there is an error
    if (error && Object.keys(error).length) {
      dispatch(validateBlock(id))
    }
  }

  return (
    <section className="workflow-trigger-schedule-params">
      <Select
        className="runs-frequency-select"
        menuItemClassName="runs-frequency-select-menu-item"
        label="Runs"
        value={frequency}
        options={WF_SCHEDULED_RUNS_FREQUENCY_OPTIONS}
        onChange={({ target }: SelectChangeEvent<unknown>) =>
          handleUpdateWFTriggerValue({ frequency: target.value as EWFScheduledRunsFrequency })
        }
      />
      {frequency === EWFScheduledRunsFrequency.WEEKLY && (
        <WeekdaysSection
          selectedWeekdays={daysOfWeek ?? []}
          setSelectedWeekdays={daysOfWeek => handleUpdateWFTriggerValue({ daysOfWeek })}
          error={error?.daysOfWeek}
        />
      )}
      {frequency === EWFScheduledRunsFrequency.MONTHLY && (
        <MonthsSection
          selectedMonths={monthsOfYear ?? []}
          setSelectedMonths={monthsOfYear => handleUpdateWFTriggerValue({ monthsOfYear })}
          selectedDays={daysOfMonth ?? []}
          setSelectedDays={daysOfMonth => handleUpdateWFTriggerValue({ daysOfMonth })}
          monthsError={error?.monthsOfYear}
          daysError={error?.daysOfMonth}
        />
      )}
      <RunTimesSection
        hours={hours}
        setTimeItems={items =>
          handleUpdateWFTriggerValue({ hours: items.map(formatISOToUTCHours) })
        }
        error={error?.hours}
      />
    </section>
  )
}
