import { type OBJECT_TYPES } from 'constants/workflows'
import { type IWorkflowBlock } from 'features/workflow/workflow.types'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import DEFINE_STRING_BLOCKS_CONFIG from 'helpers/workflowDefineStringBlocks/defineStringAttributesConfig'
import ObjectAttributeSelectorContainer from 'containers/workflowBuilder/common/ObjectAttributeSelectorContainer'
import { ObjectOutputKeyField } from './objectOutputKeyField/objectOutputKeyField'

type TProps = {
  type: OBJECT_TYPES
  outputField: EFieldKeys
  blockMeta: IWorkflowBlock['meta']
  onOutputChange: (meta: IWorkflowBlock['meta']) => void
  placeholder?: string
  helperText?: string
  disabled?: boolean
  error?: boolean
}

export const ObjectOutputField = ({
  type,
  blockMeta,
  outputField,
  onOutputChange,
  placeholder,
  disabled,
  error
}: TProps) => {
  if (outputField === EFieldKeys.KEY) {
    return (
      <ObjectOutputKeyField
        blockMeta={blockMeta}
        onChange={onOutputChange}
        placeholder={placeholder}
        disabled={disabled}
        error={error}
      />
    )
  }

  return (
    <ObjectAttributeSelectorContainer
      type={type}
      objectAttribute={blockMeta}
      onChangeAttribute={onOutputChange}
      attributeTypes={DEFINE_STRING_BLOCKS_CONFIG}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
    />
  )
}
