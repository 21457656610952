import React from 'react'

import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

import ToggleFlagContainer from '../../../containers/workflowBuilder/toggleFlagBlock/ToggleFlagContainer'

const radioFieldLabels = [messages.HIDE_WIDGETS_GROUP, messages.UNHIDE_WIDGETS_GROUP]

const HideWidgetsBundle = props => {
  const { block, updateWorkflowBlock, changeWorkflowBlockInput } = props

  return (
    <ToggleFlagContainer
      block={block}
      inputType={OBJECT_TYPES.WIDGETS_BUNDLE}
      radioFieldKey={EFieldKeys.FLAGGED}
      radioFieldLabels={radioFieldLabels}
      inputFieldTitle={messages.WIDGETS_BUNDLE_LEVEL_INPUT}
      inputFieldPlaceholder={messages.WIDGETS_BUNDLE_LEVEL_INPUT_PLACEHOLDER}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
    />
  )
}

export default HideWidgetsBundle
