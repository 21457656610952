import React, { Component } from 'react'

import FilterSelectorContainer from 'containers/filters/filterSelector/FilterSelectorContainer'

import 'scss/workflowBuilder/common/workflow-filter-selector.scss'

class WorkflowFilterSelector extends Component {
  changeFilter = (filterUuid = null) => {
    const { filterPropName } = this.props

    this.updateWorkflowBlock({ [filterPropName]: filterUuid })
  }

  updateWorkflowBlock(data) {
    const { block } = this.props

    const meta = block.meta || {}

    this.props.updateWorkflowBlock({
      id: block.id,
      data: {
        meta: {
          ...meta,
          ...data
        },
        error: {}
      }
    })
  }

  render() {
    const {
      tenantId,
      block: { meta, error },
      filterPropName,
      filterObject
    } = this.props

    return (
      <div className="workflow-filter-selector">
        <div className="filter-selector-wrapper">
          <FilterSelectorContainer
            selectedFilter={meta[filterPropName]}
            tenantId={tenantId}
            changeFilter={this.changeFilter}
            filterObject={meta.eventObject || filterObject}
            error={error?.[filterPropName]}
          />
        </div>
      </div>
    )
  }
}

export default WorkflowFilterSelector
