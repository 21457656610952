import LocalizedStrings from 'react-localization'

const messages = new LocalizedStrings({
  en: {
    // common words and phrases
    CONTINUE: 'continue',
    DISCARD: 'discard',
    DELETE: 'Delete',
    CANCEL: 'cancel',
    CONFIRM: 'confirm',
    COPY: 'Copy',
    CREATE: 'create',
    NEXT: 'next',
    SKIP: 'skip',
    OK: 'ok',
    YES: 'Yes',
    TRY_AGAIN: 'Please try again later.',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High',
    SAVE: 'Save',
    USERNAME: 'Username',
    DATA: 'Data',
    LINK: 'Link',
    DOWNLOAD: 'download',
    DOWNLOAD_FILES: 'download files',
    YOU: 'You',
    SIZE: 'Size',
    NAME: 'Name',
    WITH: 'With',
    HIDE: 'Hide',
    INVITED: 'invited',
    N_A: 'n/a',
    JOIN: 'Join',
    GO_TO_UPBOARD: 'go to Praxie',
    UPBOARD: 'Praxie',
    ACCOUNT: 'Account',
    CARDS: 'Cards',
    ACTIONS: 'Actions',
    BACK_TO_BOARDS: 'back to boards',
    UNKNOWN: 'Unknown',
    USERS: 'Users',
    ALL: 'All',
    TYPE_TO_SEARCH: 'Type to search',
    GO: 'GO!',
    SEE_MORE: 'See more',
    SEE_LESS: 'See less',
    RETRY: 'Retry',
    WORKSPACE: 'workspace',
    SELECTED: 'selected',
    SELECT: 'select',
    UNSELECT: 'unselect',
    FROM: 'from',
    TO: 'to',
    TASK: 'task',
    BOARD: 'Board',
    COLUMN: 'Column',
    CARD: 'Card',
    WIDGET: 'Widget',
    USER: 'User',
    CLOSE: 'Close',
    SAVE_AND_UPDATE_ALL: 'Save & update all',
    SEARCH: 'Search',
    GET: 'Get',
    RELOAD: 'Reload',
    ADD: 'Add',
    DONE: 'Done',
    RECENT: 'Recent',
    WELCOME: 'Welcome',
    SUGGESTED: 'Suggested',
    ASSIGN: 'Assign',
    OF: 'of',
    NO_OPTIONS: 'No options',
    COPIED: 'Copied',
    UNASSIGN: 'Unassign',
    COPY_EMAIL: 'Copy email',
    EMPTY: 'Empty',
    PLACEHOLDER: 'Placeholder',
    SELECT_TITLE: 'Select',
    MINE: 'Mine',
    REPORTS: 'Reports',
    REQUIRED: 'Required',
    DETAILS: 'Details',
    LOADING: 'Loading',
    NO_THANKS: 'No thanks',
    SELECT_USER: 'Select user',
    TAKE_TOUR: 'Take tour',
    ADDED: 'Added',
    LICENSE: 'License',
    LICENSES: 'Licenses',
    GET_APP: 'Get app',
    UNDEPLOY: 'Undeploy',
    APPLICATION: 'Application',
    APPLICATION_LINK: 'Application link',
    APPLICATIONS: 'Applications',
    DEPLOYMENT_DATE: 'Deployment date',
    SOURCE: 'Source',
    LICENSE_ID: 'License ID',
    NOTE: 'Note',
    CURRENT_PLAN: 'Current plan',
    PRO_BETA: 'Pro (Beta)',
    EXPIRY_DATE: 'Expiry Date',
    UPGRADE: 'Upgrade',
    MANAGE: 'Manage',
    OPEN_IN_APP_STORE: 'Open in Praxie App Store',
    UPGRADE_TO: 'Upgrade to',
    BUY_SUBSCRIPTION: 'Buy subscription',
    BUY_INDIVIDUALLY: 'Individual Applications',
    SUBSCRIBE: 'Subscribe',
    UNSUBSCRIBE: 'Unsubscribe',
    CANCEL_SUBSCRIPTION: 'Cancel subscription',
    UPDATE_SUBSCRIPTION: 'Update subscription',
    TEMPLATE: 'Template',
    GET_FULL_EXPERIENCE: 'Get full experience',
    COLLECTION: 'Collection',
    SUBSCRIPTION: 'Subscription',
    SUBSCRIPTIONS: 'Subscriptions',
    GET_GOING: 'Get Going!',
    BUY: 'Buy',
    RESUBSCRIBE: 'Resubscribe',
    APPLICATIONS_INSIDE: 'Includes {appsCount} applications',
    APP_IS_YOURS: '{name} is yours!',
    CREATE_WORKSPACE: 'create workspace',
    THEN: 'then',
    TRIAL_PERIOD_DURATION: '{trialPeriod} days free',
    TRIAL_PERIOD_DURATION_THEN: '{trialPeriod} days free then',
    TRIAL: 'Trial',
    REINVITE: 'Re-invite',
    NOTES: 'Notes',
    COMPLETE: 'Complete',
    REFRESH: 'Refresh',
    CONNECTED: 'Connected',
    NOT_CONNECTED: 'Not connected',
    SET_UP: 'Set-up',
    CREATING_THUMBNAIL: 'Creating thumbnail...',
    NO_PREVIEW_AVAILABLE: 'No preview available',
    NOT_SYNC: 'Not sync',
    SYNC_FAILED: 'Sync failed',
    HELP: 'Help',
    GET_STARTED: 'Get Started',
    NO_TUTORIALS_YET: 'No tutorials yet',
    SCROLL: 'Scroll',
    TILL_DATE: 'until {date}',
    ARCHIVED_COLLECTION: '(Discontinued) {collectionName}',
    OPEN_APP: 'Open app',

    // Welcome modal and change username modal
    WELCOME_ONBOARD: 'Welcome onboard',
    WELCOME_TO_UPBOARD_2: 'Welcome to Praxie',
    WELCOME_TO_UPBOARD_3: 'Welcome to Praxie.',
    WELCOME_TO_UPBOARD_TEXT: 'Add a touch of personality to your account',
    CHANGE_USERNAME_LINK_TEXT: 'by providing your name',
    VERIFY_ACCOUNT: 'Verify account',
    SETTINGS: 'Settings',
    PROFILE_SETTINGS: 'Profile settings',
    TEAM_SETTINGS: 'Team settings',
    ACCOUNT_SETTINGS: 'User Settings',
    INTEGRATIONS: 'Integrations',
    NO_CONNECTION_WITH_JIRA: 'No connection with JIRA account',
    ADD_ACCOUNT: 'Add account',
    CURRENT_PASSWORD: 'Current password',
    ACCOUNT_EMAIL_ADDRESS: 'Account Email Address',
    CHANGE_PASSWORD: 'Change password',
    SIGN_OUT: 'Sign out ',
    NO_ORGANIZATION_ASSIGNMENT_TITLE: 'No organization role assignment',
    NO_ORGANIZATION_ASSIGNMENT_DESCR: 'Please contact organization Administrator',
    GOT_IT: 'Got it',

    // notifications
    SECONDS_AGO: count => `${count} seconds ago`,
    MINUTES_AGO: count => `${count} minutes ago`,
    HOURS_AGO: count => `${count} hours ago`,
    DAYS_AGO: count => `${count} days ago`,

    // Sign up/sign in/ wordings
    SUCCESS_PASSWORD_CHANGE: 'Your password has been changed',
    NEED_EMAIL_CONFIRMATION: 'Please confirm this email.',
    USER_ALREADY_EXIST: 'Username already exists.',
    INVALID_REGISTRATION_KEY: 'To complete registration, please enter a valid registration key.',
    RESET_PASSWORD_ERROR_MESSAGE: 'Your link is broken or expired. Please try again.',
    KEEP_ME_SIGNED_IN: 'Keep me signed in',
    FORGOT_PASSWORD: 'Forgot password?',
    FORGOT_ORGANIZATION: 'Forgot workspace name?',
    TERMS_AND_PRIVACY: 'Terms and Privacy Policy',
    READ_AND_AGREE_TERMS: 'I read and agree with Praxie’s ',
    TERMS_ERROR: 'Please, read and agree with Terms of Service and Privacy Policy documents.',
    CONFIRM_PASSWORD: 'Confirm Password',
    REGISTRATION_KEY: 'Registration Key',
    JOIN_MESSAGE_1: 'Welcome to ',
    JOIN_MESSAGE_2: '! Create a password for this workspace to login.',
    MY_WORKSPACE: 'My Workspace',
    SIGN_UP: 'sign up',
    REQUEST_ACCOUNT: 'Request account',
    CREATE_ACCOUNT: 'Create account',
    INVALID_LINK_TITLE: 'This link is no longer valid',
    INVALID_LINK_DESC: 'Invitation link was already used or has expired.',
    INVALID_VERIFICATION_LINK: 'Verification link was already used or has expired.',
    SIGN_IN: 'Sign in',
    TO_UPBOARD: 'to Praxie',
    SIGN_IN_TO: 'Sign in to ',
    YOU_ALREADY_SIGN_IN_TO: 'My workspaces:',
    ORGANIZATION_NAME: 'Workspace name',
    COMPLETE_SETUP: 'Complete setup in 2 simple steps',
    STEP: step => `Step ${step}`,
    PERSONALIZE_WORKSPACE: 'Personalize workspace',
    PERSONALIZE_WORKSPACE_HINT:
      'Enter name of workspace the way you would like\nit to display in your profile (e.g., My, Company)',
    SETUP_YOUR_PERSONAL_PROFILE: 'Set-up your personal profile',
    BACK: 'Back',
    FINISH: 'Finish',
    SETUP_PERSONAL_PROFILE_HINT:
      'Enter your name the way you would like it to display in your profile (e.g., First Last)',
    SETUP_ORGANIZATION_HINT:
      'Name of the workspace or entity that owns or manages this Praxie account',
    OPTIONAL: 'Optional',
    SELECT_BEST_PRACTICES: 'Select Apps & Templates',
    SELECT_BEST_PRACTICES_HINT:
      'Choose the templates to import for your team or tap the “Finish” button to do it later',
    EXISTING_ORGANIZATION_NAME_ERROR: 'That Workspace Name is already has taken. Try another',
    SIGN_UP_SUCCESS_TITLE: "You're Almost There!",
    SIGN_UP_SUCCESS_TEXT_1: 'We just sent an email to ',
    SIGN_UP_SUCCESS_TEXT_2: '. Click the confirmation link in the email to complete your signup.',
    SIGN_UP_SUCCESS_TEXT_HINT_1: "If you don't get our email, check your spam filter.",
    SIGN_UP_SUCCESS_TEXT_HINT_2: " if you have any trouble logging in - we're here to help!",
    CONTACT_US: 'Contact us',
    GET_A_TOUR: 'Get a tour',
    SIGN_UP_COPYING_TITLE: "We're creating your boards...",
    CREATING_YOUR_WORKSPACE: 'Creating your workspace...',
    CONFIGURING_YOUR_USER_PROFILE: 'Configuring your user profile...',
    BUILDING_YOUR_UPBOARDs: 'Building your Praxies...',
    GETTING_READY_TO_MAKE_YOU_HAPPY: 'Getting ready to make you happy...',
    SIGN_UP_COPYING_TEXT_1: "Please keep this page open while we're setting things up.",
    SIGN_UP_TO_GET: 'Sign up to get',
    SIGN_UP_TO_GET_YOUR: 'Sign up to get your',
    SIGN_IN_TO_GET: 'Sign in to get',
    SIGN_IN_TO_GET_YOUR: 'Sign in to get your',
    INVALID_ENTITLEMENT_LINK: 'This Entitlement link is invalid',
    APP_ADVERTISEMENT: 'Best practice processes, templates and apps for everything',
    ENTITLEMENT_WORKSPACE_PREPARING_TITLE: 'Almost There!',
    ENTITLEMENT_WORKSPACE_PREPARING_SUBTITLE: "We're preparing things just for you.",

    // new sigh in/up flow
    SIGN_IN_TO_EXISTING_ORGANIZATION: 'Signin to existing workspace',
    SIGN_IN_TO_EXISTING_ORGANIZATION_DESC: 'Enter workspace’s name',
    CREATE_NEW_ORGANIZATION: 'Create new workspace',
    CREATE_NEW_ORGANIZATION_DESC: 'Create new workspace and invite your colleagues',
    ORGANIZATION_CREATION: 'Create New Workspace for: ',
    SET_YOUR_EMAIL_AND_PASSWORD: 'Enter your email address and password for this account',
    ORG_NAME_DOESNT_EXIST: 'Incorrect Workspace name',
    ENTER_EMAIL_AND_PASS: 'Enter your email address and password to sign in',
    GET_BETA_REGISTRATION_KEY: 'Get my beta registration key',
    MY_WORKSPACES: 'My workspaces for:',
    OTHER_WORKSPACES_WITH_EMAIL: 'Other workspaces with this email but different password:',
    NOT_LOGGED_IN: 'Not logged in',

    // Password strength
    PASSWORD_MUST_HAVE: 'Your password must have:',
    LENGTH_REQUIREMENT: '8 or more characters',
    LETTERS_REQUIREMENT: 'Upper & lowercase letters',
    NUMBER_REQUIREMENT: 'At least one number',
    STRENGTH: 'Strength: ',
    PASSWORD_STRENGTH_DESCRIPTION:
      'Avoid using a password from another site,' +
      ' or something too obvious like your pet’s name.',

    // Reset password and organization
    SEND_ME_RESET_LINK: 'send reset link',
    SEND_ORGANIZATIONS_LIST: 'Send Workspace List',
    FORGOT_YOUR_PASSWORD_TITLE: 'Forgot your password?',
    ENTER_EMAIL_TO_SEND_LINK:
      'Enter your email address to receive a link to change workspace password(s).',
    ENTER_EMAIL_TO_SEND_ORGANIZATIONS:
      'Enter email address associated with your Praxie account(s) to receive an email with a list of your workspaces.',
    LINK_TO_RESET_PASSWORD_SENT_TITLE: 'Link successfully sent',
    LINK_TO_RESET_ORGANIZATION_SENT_TITLE: email => `The email was sent to ${email}`,
    LINK_TO_RESET_PASSWORD_SENT_DESC_1: 'Instructions to reset password successfully sent to ',
    LINK_TO_RESET_ORGANIZATION_SENT_1: 'The list of workspaces was sent to ',
    LINK_TO_RESET_ORGANIZATION_SENT_2: ', if the email is valid.',
    SUCCESS: 'Success!',
    RESET_PASSWORD: 'Reset password',
    RESET_PASSWORD_FOR_ORG: name => `Reset password for the account: ${name}`,

    // Toast messages
    SOMETHING_WRONG:
      'Board refreshed because another user made an update. Please make changes on the latest version of the board.',
    CANNOT_DELETE_BOARD:
      'This board could not be removed because someone is editing its card(s) now. Please try later.',
    REFRESH_PAGE: 'Oops, something went wrong. Please refresh the page',
    DONT_HAVE_PERMISSION: "Sorry, you don't have permission to perform this operation",
    PRESS_SAVE_CARD_AGAIN:
      'Oops! Internet connection error. Tap “Publish on board” again to save your work.',
    CARD_COULD_NOT_BE_SAVED:
      'Card could not be saved since one of the widgets has inconsistent data. Please do not close the browser tab and contact Praxie support with the issue details.',
    REQUEST_SENT: 'Request sent! Our team will respond by email.',
    WORKFLOW_SUCCESSFULLY_COPIED: 'Workflow {workflowName} successfully copied to {boardName}',
    SESSION_EXPIRED_TOAST_MESSAGE: ' Your session expired. Please login again.',
    NEW_VERSION_RELEASED_TOAST_MESSAGE1: 'Good news! We just released an update to Praxie.',
    NEW_VERSION_RELEASED_TOAST_MESSAGE2:
      'Refresh your page for new features and better performance.',

    // Confirmation wording for delete operations on filters
    FILTERS_DELETE_CONFIRMATION_NO_CONNECT_TITLE: 'Are you sure you want to delete this Filter?',
    FILTERS_DELETE_CONFIRMATION_NO_CONNECT_DESCR:
      'If you delete this Filter, it will be removed for all team members and cannot be restored.',
    FILTERS_DELETE_CONFIRMATION_WITH_CONNECT_TITLE_PART_ONE:
      'Deletion is unavailable because there are ',
    FILTERS_DELETE_CONFIRMATION_WITH_CONNECT_TITLE_PART_TWO: ' connections using this filter.',
    FILTERS_DELETE_CONFIRMATION_WITH_CONNECT_DESCR:
      'To delete this Filter you should break all connections of it.',

    // Confirmation wording for update operations on filters
    FILTERS_UPDATE_CONFIRMATION_WITH_CONNECT_TITLE_PART_ONE: 'There are ',
    FILTERS_UPDATE_CONFIRMATION_WITH_CONNECT_TITLE_PART_TWO: ' connections using this filter.',
    FILTERS_UPDATE_CONFIRMATION_WITH_CONNECT_DESCR:
      'Do you want to proceed and update all connections?',

    // Confirmation wording and toast messages for delete operations on board
    COLUMN_DELETE_CONFIRMATION:
      'Heads up! If you delete this, all the ' +
      'associated cards and related data will also be permanently deleted and cannot be restored.',
    DELETE_COLUMN_TOAST_MESSAGE: 'Column was successfully deleted.',
    CARD_DELETE_CONFIRMATION:
      'Heads up! If you delete this card, all ' +
      ' content and data in the card will be permanently deleted and cannot be restored.',
    DELETE_CARD_TOAST_MESSAGE: 'Card has been deleted.',
    BOARD_DELETE_CONFIRMATION:
      'Heads up! If you delete this board, all' +
      ' cards and related data on the board will be permanently deleted and cannot be restored',
    DELETE_BOARD_TOAST_MESSAGE: 'Board has been deleted',

    // Unsaved changes modal
    UNSAVED_CHANGES_TITLE: 'Heads up!',
    UNPUBLISHED_CHANGES_TITLE: 'Heads up!',
    UNSAVED_CHANGES_DESCRIPTION: 'You have unpublished changes.',
    UNSAVED_CHANGES_DISCARD_CHANGES_BUTTON: 'Continue without saving',
    UNSAVED_CHANGES_SAVE_BUTTON: 'Save & Continue',
    UNPUBLISHED_CHANGES_SAVE_BUTTON: 'Publish & Continue',
    UNPUBLISHED_CHANGES_DISCARD_CHANGES_BUTTON: 'Discard Changes',
    DO_YOU_WANT_TO: 'Do you want to',
    EDITING_OR: 'editing or',
    DISCARD_ALL_CHANGES: 'discard all changes',
    UNTITLED_CARD: 'Untitled Card',

    // card builder server error modal
    START_EDIT_ERROR_MESSAGE:
      'System was not able to complete the operation. Please try to perform it again.',
    CB_SERVER_ERROR_TITLE: 'Oops! Network connection temporarily lost.',
    CB_SERVER_ERROR_DESC_1: 'Reconnecting to server in ',
    CB_SERVER_ERROR_DESC_2: 'seconds to attempt to save your draft…',
    CB_SERVER_ERROR_WAIT: 'Wait',
    CB_SERVER_ERROR_DONT_SAVE: "Don't save & cancel publishing",
    CONNECTING: 'Connecting...',
    MIGHT_TAKE_UP_TO_FEW_MINUTES: 'It might take up to few minutes',
    SAVE_ACTIVE_ERROR_MESSAGE:
      'Oops! Unable to publish changes. Please check your network connection and try again.',

    // Custom image uploading and removing
    MAX_SIZE: 'File(s) {names} was not uploaded because it exceeds the {limit} size limit.',
    WRONG_FILE_TYPE: "File(s) {names} was not uploaded. Please choose the correct file's format.",
    WRONG_FILE: 'File(s) {names} was not uploaded.',
    FILE_UPLOAD_ERROR: 'Upload error. Could not connect to server.',
    WIDGET_DELETE_CONFIRMATION_HEADER: name => `Delete «${name}» file?`,
    WIDGET_DELETE_CONFIRMATION:
      'If you delete this file, all widgets on the board associated with this file will lose their source data which cannot be restored.',
    RELATED_CARDS: 'Linked cards',
    NO_RELATED_CARDS: 'No linked cards',

    // Unsaved draft message
    UNSAVED_DRAFT_1: 'You have an unsaved draft of the following card: ',
    SIGN_IN_INTO_GOOGLE: 'Sign in to Google Account',
    SIGN_IN_INTO_GOOGLE_DECS_1: 'One or more widgets on this board link to',
    SIGN_IN_INTO_GOOGLE_DECS_2: 'To access this data, login to your',
    GOOGLE_DRIVE_ACCOUNT: 'Google Drive account',
    SIGN_IN_INTO_MS_ACCOUNT: 'Sign in to Microsoft',
    SIGN_IN_INTO_MS_ACCOUNT_DESC_1: 'One or more widgets on this board link to',
    SIGN_IN_INTO_MS_ACCOUNT_DESC_2: 'To access this data, login to your',
    ONE_DRIVE_ACCOUNT: 'Microsoft account',

    // Copy board modal
    COPY_BOARD_TITLE: 'Copy board',
    BOARD_NAME: 'Board name',
    COPY_TO: 'Copy to',
    NAME_COPY: name => `${name} copy`,
    DELETE_BOARD_NAME: boardName => `Delete «${boardName}» board?`,

    // Board creation modal
    CREATE_NEW_BOARD: 'Create new board',
    IN_TEAM: 'In team',

    // Lock edit modal
    OOPS: 'oops!',
    CARD_LOCKED: 'Another user is currently editing this card.',

    // My profile modal
    MY_PROFILE_TITLE: 'My profile',
    NO_NOTIFICATION: 'No current notifications.',
    NO_NOTIFICATION_DESC:
      "Stay tuned! Notifications about your workspace's activity will display here when available.",

    // Cancel subscription modal
    CANCEL_SUBSCRIPTION_TITLE: 'Are you sure you want to cancel this subscription?',
    CANCEL_SUBSCRIPTION_DESC:
      'Once you cancel, you will no longer have access to the applications associated with this subscription in your Workspace.',
    CANCEL_LAST_SUBSCRIPTION_DESC:
      'Once you cancel, you will no longer have access to any applications within this Workspace.',

    // Restriction to buy subscription modal
    NO_PERMISSIONS_TO_BUY: 'Only workspace administrators can buy {bundle} for this account.',
    NO_PERMISSIONS_TO_BUY_DESC:
      'Send email to your administrator to request the purchase or create your own new workspace to buy this {bundle}.',
    REQUEST_TO_ADMIN: 'Request sent to your Admin!',

    // Validation messages
    emptyField: "You can't leave this empty",
    emptyField2: 'Please specify the password.',
    emptyRecipients: 'Please add at least one recipient.',
    emptyCombinedString: 'Please add at least one text or string input',
    shortPassword: 'Passwords must contain at least 8 characters.',
    passwordNotMatch: "Passwords don't match. Please try again.",
    incorrectEmail: 'Please enter a valid email address.',
    incorrectPassword: 'Wrong password. Please try again.',
    containNumber: 'Password must contain at least one number.',
    containLetter: 'Password must contain at least one upper and lowercase letter.',
    cyrillicPattern: 'Only latin characters can be used.',
    spacesPattern: 'Only latin characters without spaces can be used.',
    CARD_NAME_VALIDATION: 'Please specify the card name before saving.',
    incorrectJiraUrl: 'Please enter a valid url.',
    validateNumber: 'Please enter valid number value.',
    validateOrganizationName:
      'Oops, sorry! Your workspace can only include letters, numbers, spaces and basic symbols like dash ( - ), dot ( . ) and underline ( _ )',
    organizationNameMinLength: 'Workspace name must contain at least 3 characters',
    incorrectLink: 'Incorrect link',
    INCORRECT_VIDEO_LINK: 'Heads up! Your link is not valid. Please try another link',
    validateTrialPeriod: 'Provide value from 1 to 99',
    emptyTrialPeriod: 'Add number of days for trial period',
    NO_EMPTY_HINT_TEXT: 'Please make sure that there is no empty hint text',

    // Charts edit modal
    CHART: 'Chart',
    UPDATE_CHART: 'update chart',
    UNLINK: 'unlink',
    GO_TO_LINKING: 'GO TO LINKING',
    WIDGET_USE_DATA_FROM_ANOTHER_WIDGET: 'Widget uses linked data from other widgets.',
    CLICK_UNLINK_1: 'Click ',
    CLICK_UNLINK_DETAILED: ' button to remove data links and enter data manually ',
    CLICK_UNLINK_EDIT: ' button to provide data manually or click ',
    CLICK_UNLINK_3: ' button to view and edit linked data.',
    PROVIDE_AT_LEAST_ONE_ROW: 'Please provide at least one row of data to display on the chart.',

    // data linking
    USE: 'Use',
    DATA_LINKING: 'Data linking',
    PREVIEW: 'Preview',
    DATA_SOURCE_WIDGET: 'Data source widget',
    COPY_LINK: 'Copy link',
    LINK_COPIED: 'Link is copied.',
    CLEAR_LINK: 'Clear link',
    PASTE_LINK: 'Paste link',
    LINK_IS_BROKEN: 'Link is broken',
    PASTE_TRANSPOSED: 'Transpose (rotate) data link',
    MAX_WIDGETS_COUNT_REACH:
      'The maximum number of data sources has been reached. Remove link(s) to one or more data providers to unlock this interface.',
    DATA_SOURCE: 'Data source',
    BROKEN_LINK: 'broken link',
    WIDGET_TITLE: 'Widget Title',
    CHOOSE_DATA_SOURCE: 'Select data source widget',
    NO_WIDGETS_FOUND: 'No widgets found',
    DATA_PROVIDERS: 'Data providers',
    DATA_SOURCE_NOT_FOUND: 'Data source widget has not found',
    REMOVED_USER: 'Removed user',
    LOOP_IS_DEFINED: 'Loop is defined',
    DATA_LINKING_DETAILED_VIED: 'Allow data linking in detailed view',

    // action widget edit modal
    UPDATE_WIDGET: 'update widget',

    // jira connection modal
    JIRA_CONNECTION: 'JIRA Connection',
    JIRA_CONNECTION_CONFIGURATION_TEXT:
      'This configuration will be used for all JIRA widgets in this team.',
    SPECIFY_JIRA_INSTANCE: teamName =>
      `Specify JIRA instance to associate with your account in ${teamName}.`,
    URL: 'URL',
    CONNECTED_JIRA: 'Connected JIRA instance:',
    LOGIN: 'Login',
    LOG_IN: 'Log in',
    JIRA_LOGIN_PLACEHOLDER: 'Type JIRA login here',
    JIRA_PASSWORD_PLACEHOLDER: 'Type JIRA password here',
    ADD_LINK: 'Add Link',
    BASIC: 'Basic',
    ADVANCED: 'Advanced',
    SPECIFY_FILTERS: 'Specify filter values to retrieve data from JIRA:',
    PROJECT: 'Project',
    ISSUE_TYPE: 'Issue type',
    STATUS: 'Status',
    ASSIGNEE: 'Assignee',
    COMPONENT: 'Component',
    SPRINT: 'Sprint',
    FAVOURITE_FILTER: 'Favorite filter',
    NO_RESULTS: 'No results',
    FIND_PROJECTS: 'Find Projects..',
    FIND_ISSUE_TYPES: 'Find Issue Types..',
    FIND_STATUSES: 'Find Statuses..',
    FIND_USERS: 'Find Users..',
    FIND_COMPONENTS: 'Find Components..',
    FIND_SPRINTS: 'Find Sprints..',
    FIND_FILTERS: 'Find Filters..',
    AUTHENTICATION_FAILED: 'Authentication failed. Please check login and password',
    CUSTOM_DATA: 'Custom Data',
    JIRA_DATA: 'JIRA Data',

    // board view
    SHOW_CARD_THUMBNAIL: 'Show card thumbnail on Board view',
    ADD_CARD_TO_BOARD_VIEW: 'Add individual items to Board view',
    FIT_TO_WIDTH: 'Fit width',
    FIT_TO_HEIGHT: 'Fit height',
    REFRESH_NOT_AVAILABLE_FOR_VIEWERS: 'Refresh is not available for Board Viewers',
    TEAM_SETTINGS_NAME_HINT: 'Name of the team that belongs to this Praxie account',
    MEMBERS: 'Members',
    TEAM_ROLE: 'Team role',
    INVITE: 'Invite',
    BOARD_ROLE: 'Board role',
    SEARCH_FIELD_PLACEHOLDER: 'Find member by username or email',
    BOARD_ADMIN: 'Board Administrator ',
    TEAM_ADMIN: 'Team Admin',
    SORT_BY: 'Sort by',
    CURRENT_COUNT_OF_COUNT: (currentCount, count) => `${currentCount} of ${count}`,
    CURRENT_COUNT_OF_COUNT_WITH_BORDER: (currentCount, count, rightBorderCount) =>
      `${rightBorderCount}-${currentCount} of ${count}`,
    NO_RESULTS_FOUND: 'No results found',
    EDIT_DELETE: 'Edit / Delete',
    ROLE: 'Role',
    BOARDS_COUNT: count => `(${count} ${count > 1 ? 'boards' : 'board'})`,
    FIND_BOARDS: 'Find board',

    DATE_FORMAT: 'Date Format',
    DATE_FORMAT_HINT: "This date format will be applied for all users' content within the team.",

    // team members deactivate
    TEAM_MEMBER_DEACTIVATE_DESC: 'Please confirm you want to remove {username} from {tenantName}',
    TEAM_MEMBER_DEACTIVATE_TITLE: 'Remove Team Member',
    REMOVE: 'remove',

    // team variables page
    MANAGE_TEAM_VARIABLES: 'Variables',
    NO_VARIABLES_ADDED: 'No Variables exist in this team',
    ADD_NEW_VARIABLE_MESSAGE: 'Create new Variable by click on the button below',
    ADD_NEW_VARIABLE_BUTTON: 'New variable',
    NO_VARIABLES_FOUND: 'No Variables found',

    // invitation to the team
    INVITATION_TO_THE_TEAM1: 'Invitation to ',
    INVITATION_TO_THE_TEAM2: '  team',
    SELECT_TEAM_FOR_INVITE: 'Select team for your invite',
    PLEASE_ENTER_EMAIL_YOU_WANT_TO_INVITE: 'Email(s) of the user(s) to invite',
    ENTER_UPBOARD_USER_EMAIL: 'Enter email(s)',
    MAX_20_EMAILS: 'Separate emails with commas (max 20 email addresses)',
    MEMBER: 'Member',
    EDITOR: 'Editor',
    ADMIN: 'Admin',
    VIEWER: 'Viewer',
    CARD_BUILDER: 'Card builder',
    CARD_BUILDER_TITLE: 'Card Builder',
    COLLABORATOR: 'Collaborator',
    ORGANIZATION_ADMIN: 'Workspace admin',
    BOARD_MANAGER: 'Board Manager',
    BOARDS_ROLE: 'Boards role',
    FOR_BOARDS: 'For boards',
    SELECT_BOARD_FOR_ADMIN: 'Select boards where user(s) will have Admin role',
    EXISTING_USERS_ARE_ADDED: 'New user(s) have been added to the',
    INVITE_SENT: 'Invite sent!',
    TEAM: 'Team',
    MEMBERS_PERMISSIONS_SET_UP: "'s permissions set up",
    PERMISSIONS_ARE_UPDATED: "'s permissions were updated within",
    EDITOR_ROLE_HINT: 'Editors can manage all boards and content within the team.',
    ADMIN_ROLE_HINT:
      'Administrators can manage all boards and content and control account settings for the team.',
    MEMBER_ROLE_HINT: 'Members can access specific boards assigned to them.',
    INVITE_TO_ALL_BOARDS1: 'Invite new user(s) to ',
    INVITE_TO_ALL_BOARDS2: 'All boards and apps',
    INVITE_FOR_SPECIFIC_BOARDS: 'Invite and set roles for specific boards and/or apps',
    ADD_OTHER_ROLE: '+ Add other role',
    BOARDS: 'Boards',
    ALL_BOARDS2: 'All Boards and apps',
    ROLE_SELECT_PLACEHOLDER: 'Select board(s) and/or app(s) where user(s) will have',
    CARD_BUILDER_DESCRIPTION:
      'Can create, edit, move and delete cards. Can also assign owners and followers to specific cards.',
    COLLABORATOR_DESCRIPTION:
      'Can enter, edit and delete data and content on cards. Can also follow specific cards.',
    VIEWER_DESCRIPTION: 'Can view and follow cards.',
    BOARD_MANAGER_DESCRIPTION: 'Can create, edit, copy and delete boards and cards.',
    TEAM_ADMIN_DESCRIPTION: 'Can manage all boards, members, and content across the team account.',

    // copy card
    COPY_CARD: 'Copy Card',
    COPY_AS_CARD_LINK: 'Copy as Card Link',
    COPY_CARD_TOOLTIP: 'Select how to copy the input card',
    COPY_CARD_TOAST:
      'To paste card, go to the desired board and select "Paste Card" in the column\'s menu.',
    CARD_SUCCESSFULLY_PASTE: 'Card successfully copied!',
    UNABLE_TO_PASTE_REMOVED_CARD:
      'Oops! The source card no longer exists and cannot be pasted into the board.',

    // card dropdown
    EDIT_CARD: 'Edit card',
    RENAME_CARD: 'Rename card',
    DELETE_CARD: 'Delete card',
    DELETE_CARD_LINK: 'Delete card link',
    DUPLICATE_CARD: 'Duplicate card',
    CARD_IS_EDITED: 'Card is being edited by another user.',

    // presentation mode
    PRESENTATION_MODE: 'Presentation mode',
    SECONDS: 'seconds',
    CHANGE_CARD_EVERY: 'Change {card} in slideshow every',
    PLAY_PAUSE: 'Play/pause',

    // column dropdown
    PASTE_CARD: 'paste card',
    PASTE_LINKED_CARD: 'Paste Linked Card',
    DELETE_COLUMN: 'delete column',
    RENAME_COLUMN: 'rename column',
    HIDE_COLUMN: 'hide column',
    UNHIDE_COLUMN: 'unhide column',
    EXCLUDED_FROM_KANBAN_COLUMN_TOOLTIP: `This column excluded from Kanban mode:
•    column hidden in Kanban view
•    cards cannot be moved into it`,
    HIDE_COLUMN_IN_KANBAN: 'Hide in Kanban',
    SHOW_COLUMN_IN_KANBAN: 'Show in Kanban',
    HIDDEN_COLUMN_DISABLED: 'Hidden columns cannot be excluded in Kanban mode',
    EXCLUDED_COLUMN_DISABLED: 'Excluded columns cannot be hidden',

    ADD_BOARD: 'Add board',
    NEW_BOARD: 'New Board',
    UNTITLED_COLUMN: 'Untitled column',

    // Empty state wordings
    EMPTY_AUTHORING_AREA_1: 'Create your custom card!',
    NO_WIDGETS_ON_CARD: 'No widgets on a card',
    EMPTY_AUTHORING_AREA_2: 'Drag widgets here to start authoring ',

    EMPTY_BOARD_VIEW_1: 'Board view of this card is blank.',
    EMPTY_BOARD_VIEW_2:
      'Change settings above to use card thumbnail or select specific widgets to display on the board',

    EMPTY_FILES_TAB_1: 'No files have been uploaded to any cards',
    EMPTY_FILES_TAB_2: 'Drag and drop files onto specific cards or hit the "+" button above',

    EMPTY_WIDGETS_MENU: 'No widgets added yet',
    EMPTY_CARD_MENU: 'No cards added yet',
    EMPTY_IMAGES_MENU: 'No images added yet',

    LIBRARY: 'Library',
    EMPTY_DRAWER_CARDS_1: 'Board contains no cards',
    EMPTY_DRAWER_CARDS_2: 'Go to board view to add cards',
    DRAWER_SEARCH_TIP: 'Start searching for Cards by Card Name, Widget name and Widget data',
    MATCHED_IN_WIDGET_DATA: 'The search request is found in widget content',
    NO_APPLICATIONS_YET: 'No applications yet',
    NO_SUBSCRIPTIONS_YET: 'No subscriptions yet',

    // Card authoring
    DETAILED_VIEW: 'Detailed view',
    SECTION_N: 'Section {N}',
    SECTIONS_DIVIDERS: 'Section dividers',
    PLEASE_DELETE_WIDGETS:
      'Active section must be empty to be removed. Please move or delete widgets and try again',
    PLEASE_MOVE_WIDGETS:
      'Action unavailable as section has widgets which belongs to another section',
    ENTER_CARD_NAME: 'Enter card name',
    CARD_NAME: 'Card name',
    THIRD_PARTY_WIDGETS: '3rd Party widgets',
    MANAGEMENT: 'Management',
    MEDIA: 'Media',
    FORMS: 'Forms',
    BOARD_VIEW: 'Board View',
    PUBLISH: 'Publish',
    ACTION_IS_UNAVAILABLE: 'Cannot complete action. Unhide the section and then try again.',
    CANNOT_COMPLETE_ACTION: 'Cannot complete action',
    CANNOT_HIDE_SECTION: 'Cannot hide this section since all other sections are currently hidden.',
    HIDE_SECTION: 'Hide section in detailed view',
    SECTION_IS_HIDDEN: 'Section is currently hidden in detailed view',
    REMOVE_SECTION: 'Delete section',
    ADD_NEW_SECTION: 'Add new section',
    MAX_GROUPED_FILES_WARNING: 'Oops! Limit of 50 files can be added to File Manager',
    MAX_WIDGET_COUNT_HINT: 'Widget counter. Max {N} widgets can be added to the Card',
    MAX_WIDGETS_COUNT_WARNING:
      'You have reached the limit of widgets for the card. You need to remove widget from the card in order to add a new one.',
    SAVING_IN_DRAFT: 'Saving...',
    DRAFT_SAVED: 'Draft saved',

    // detailed view
    EDIT_IN_CARD_BUILDER: 'Edit in Card Builder',
    EXPORT_AS_PDF: 'Export to PDF',
    PDF_EXPORT_INSTRUCTION:
      'Scroll to the bottom of the card in order to enable "Export to PDF" feature.',
    PDF_EXPORT_RESTRICTION: 'PDF export is not allowed for cards that have more than 15 sections.',
    PDF_EXPORT_ERROR: 'Some error while pdf generation was occurred',
    COPY_SHAREABLE_LINK: 'Copy page link',
    REMOVE_CARD: 'Remove card',
    CARD_WIDGETS_LOADING_ERROR: "Can't retrieve widgets",
    CARD_WIDGETS_LOADING_ERROR_DESC: "Can't retrieve widgets",
    WIDGET_UPDATE_ERROR_WHEN_CARD_EDITING:
      'Your changes cannot be saved since another user is currently editing this card from within card builder. Please try again later.',
    WIDGET_UPDATE_ERROR_WHEN_SERVER_DOWN:
      'Oops! Connection lost. Your changes were not saved. Please refresh page and try again.',
    DONT_SHOW_ME_AGAIN: "Don't show this again",
    IS_EDITING_CARD: 'is editing card...',
    DONT_ABLE_TO_EDIT_CARD: 'Editing disabled until existing user finishes their own changes',
    CHANGES_MAY_NOT_BE_SAVED: 'Changes you made may not be saved',
    COPY_PAGE_SNAPSHOT: 'Copy page as image',
    SNAPSHOT_SUCCESSFULLY_COPIED: 'Screenshot copied to clipboard!',
    LINK_COPIED_TO_CLIPBOARD: 'Link copied to clipboard!',
    NO_PERMISSIONS_TO_CARD:
      'Oops! You don’t have permission to view this link. Contact your workspace Administrator for access',
    CHANGES_SAVED: 'Changes saved!',
    CHANGES_NOT_SAVED: 'Changes not saved',
    SAVING_CHANGES: 'Saving changes...',
    SWIPE_ADVICE: 'Swipe left, right, up, and down to navigate between cards',

    // tools ribbon
    UNDO: 'Undo',
    CTRL_Z: '(Ctrl+Z)',
    REDO: 'Redo',
    CTRL_Y: '(Ctrl+Y)',
    CUT: 'Cut',
    CTRL_X: '(Ctrl+X)',
    PASTE: 'Paste',
    CTRL_V: '(Ctrl+V)',
    CTRL_C: '(Ctrl+C)',
    DEL: '(Del)',
    UNGROUP: 'Ungroup',
    GROUP: 'Group',
    ADD_TO_BOARD_BOARD_VIEW: 'Board view',
    MOVE_FILES_TO_UPLOAD: 'Move files into the File Manager',
    ADD_FILES_TO_UPLOAD: 'Add files to the File Manager',
    SHOW_WIDGET_IN_FULLSCREEN: 'Open widget on full screen',
    STYLE: 'Style',
    FONT_TYPE: 'Font type',
    FONT_SIZE: 'Font size',
    CUSTOM: 'Custom',
    LARGE: 'Large',
    SMALL: 'Small',
    LOCKED_IN_DETAILED_VIEW: 'Locked in detailed view',
    UNLOCKED_IN_DETAILED_VIEW: 'Unlocked in detailed view',
    LINKED_WIDGET_DISABLED_EDITING: 'Data is supplied by other widget. Manual editing is disabled',
    BORDER_COLOR: 'Border color',
    BORDER_WIDTH: 'Border width',
    BACKGROUND_COLOR: 'Background color',
    HOVER_COLOR: 'Hover color',
    BOLD: 'Bold',
    ITALIC: 'Italic',
    TEXT_COLOR: 'Text color',
    BULLET_LIST: 'Bullet list',
    ORDERED_LIST: 'Ordered list',
    TEXT_ALIGN: 'Text align',
    VERTICAL_ALIGN: 'Vertical align',
    CLEAR_FORMATTING: 'Clear formatting',
    LOCK_IN_DETAILED_VIEW: 'Lock in detailed view',
    UNLOCK_IN_DETAILED_VIEW: 'Unlock in detailed view',
    ADD_ROWS_DETAILED_VIEW: 'Enable table editing in detailed view',
    HEADING_ROW_HINT: 'Header mode enables column sorting',
    HEADER_MODE: 'Header mode',
    LABEL: 'Label',
    TEXT_WRAPPING: 'Text wrapping',

    // files tab
    ALL_FILE_TYPES: 'All file types',
    IMAGES: 'Images',
    SVG_IMAGES: 'SVG Images',
    PDF_FILES: 'PDF files',
    MS_POWERPOINT: 'MS Powerpoint slides',
    MS_WORD: 'MS Word docs',
    MS_EXCEL: 'MS Excel sheets',
    GOOGLE_DOCS: 'Google docs',
    GOOGLE_SHEETS: 'Google sheets',
    GOOGLE_SLIDES: 'Google slides',
    OTHER_FILES: 'Other files',
    EVERYONE: 'Everyone',
    MY_COMPUTER: 'My computer',
    GOOGLE_DRIVE: 'Google drive',
    OPEN_IN_GDRIVE: 'Open in GDrive',
    OPEN_IN_OFFICE: 'Open in Office 365',
    OPEN_LINK: 'open link',
    ADD_A_FILE_FROM: 'Add a file from:',
    FIND_MEMBER: 'Find team member',
    NO_SEARCH_RESULTS: 'No files found',
    WE_SEARCHED: 'We searched hard but no results',
    JUST_YOURS: 'Just yours',
    S_FILES: '`s files',
    DELETE_FILE: 'delete file',
    RENAME_FILE: 'rename file',
    DROP_FILES_HERE: 'Drop files to add them to this card',
    ITEMS_UPLOADING: (count, from) => `Uploading ${count} of ${from} items`,
    CANCEL_FILE_UPLOADING: count => `Uploading of ${count} file(s) was cancelled.`,
    ONE_DRIVE: 'One drive',
    WORD_ONLINE: 'Word online',
    EXCEL_ONLINE: 'Excel online',
    POWERPOINT_ONLINE: 'PowerPoint online',
    FIND_FILE_TYPE: 'Find file type',

    // link to card
    IT_IS_LINK_TO_CARD: boardName => `This is a linked card from the board: ${boardName}`,
    SOURCE_CARD_FORBIDDEN: 'Source card unavailable',
    SOURCE_CARD_FORBIDDEN_DESC:
      "The source card was either removed or you don't have permission to view the original board that contains this card.",
    CARD_IS_LINKED: 'Link to another card',
    CARD_IS_LINKED_DESC: 'You cannot open link to another card',

    // team settings
    PASSWORD_CHANGED_SUCCESS: 'Password successfully changed!',
    DISPLAY_NAME: 'Display Name',
    NEW_DISPLAY_NAME: 'New Display Name',
    SAVE_CHANGES: 'Save changes',
    EMAIL_ADDRESS: 'Email Address',
    NEW_EMAIL_ADDRESS: 'New Email Address',
    UPDATE_EMAIL: 'Update Email',
    CUSTOMIZE_FOR_TEAM: 'Customize for all teams',
    GENERAL: 'General',
    UPLOAD_PHOTO: 'Upload photo',
    UPLOAD_PHOTO_FORMAT: 'JPG, GIF or PNG.',
    INVALID_AVATAR_TYPE: name =>
      `File ${name}\ncould not be uploaded. Please choose the correct file format`,
    INVALID_AVATAR_SIZE: name =>
      `File ${name}\ncould not be uploaded because it exceeds 2MB size limit.`,
    INVALID_TEMPLATE_FILE_SIZE: name =>
      `File ${name}\ncould not be uploaded because it exceeds 50MB size limit.`,
    UPLOAD_PHOTO_SIZE: 'Max size 2Mb',
    PHOTO_CROPPING: 'Photo cropping',
    PHOTO_UPLOADING: 'Photo uploading',
    DELETE_PHOTO: 'Delete photo',
    MY_MANAGER: 'My Manager',
    MY_SUBORDINATES: 'My Direct Reports',
    ENABLE_SENDING_NOTIFICATIONS: 'Email and feed notifications',

    // team profile settings notifications
    NOTIFICATION: 'Notification',
    NOTIFICATIONS: 'Notifications',
    NOTIFICATION_HINT:
      'Notifications are on by default. Turn off to disable email ' +
      'and feed notifications for all users in this team',

    // card owners, participants
    FOLLOWERS: 'Followers',
    REMOVE_ALL: 'Remove all',
    FIND_USER: 'Find user',
    OWNER: 'Created by',
    FOLLOWING: 'Following',
    FOLLOW: 'Follow',
    OWNER_FOLLOWING: 'Owner cannot unfollow the card',
    SUGGESTED_USERS: 'Suggested users',
    OWNERS: 'Owners',
    PERMISSION_TO_CHANGE_OWNERS_REQUIRED: 'You have no permissions to manage Card owners',
    CARD_OWNER_UNSET_OUTPUT_PREFIX: 'with unset Card Owners',
    CARD_OWNER_SET_OUTPUT_PREFIX: 'with set Card Owner',
    CARD_OWNER_ADD_OUTPUT_PREFIX: 'with added Card Owner',
    NO_OWNERS: 'No owners',
    NO_FOLLOWERS: 'No followers',

    // card comments
    WRITE_A_COMMENT: 'Add a comment...',
    COMMENTS: 'Comments',
    REPLY: 'Reply',
    EDIT: 'Edit',
    NO_COMMENTS_TITLE: 'No comments',
    NO_COMMENTS_TEXT: 'Be the first to add a comment',
    REPLIES_COUNT: count => `Replies (${count})`,
    AUTHOR_OF_THE_COMMENT: 'Author of the comment',
    COMMENT_BOX_LABEL: 'Comment text',
    USER_WHO_TRIGGERED_WORKFLOW_LABEL: 'User who triggered workflow',
    WORKSPACE_NAME_LABEL: '[Workspace name]',
    COMMENT_BOX_HINT_TEXT: 'to add [String Input] text',
    COMMENT_BOX_NOTIFICATION_HINT_TEXT: 'to select and configure message',
    COMMENT_BOX_NOTIFICATION_LIMIT: '(max ({limit}) [String] and [Widget Data] inputs each)',
    COMMENT_FROM_WF: 'Comment submitted by Praxie Workflow Engine',
    ADD_COMMENT_LEVEL_INPUT_PLACEHOLDER: 'Select output from prior step',
    COMMENT_TEXT_PLACEHOLDER: 'Enter text to be posted as a comment within the card',

    // send notification
    SEND_NOTIFICATION_RECIPIENT: 'To',
    SEND_NOTIFICATION_SENDER: 'From',
    ADD_EMAIL_STRING: '+ Add Email [String]',
    EMAIL_LEVEL_INPUT_PLACEHOLDER: 'Select user output from prior step(s)',
    SEND_NOTIFICATION_SUBJECT: 'Subject',
    SEND_NOTIFICATION_BODY: 'Body',
    SEND_EMAIL_SUBJECT_PLACEHOLDER: 'Enter subject of email',
    SEND_EMAIL_BODY_PLACEHOLDER: 'Enter body text of email',
    SEND_EMAIL_OUTPUT: 'Send',
    SEND_EMAIL_POSTFIX_OUTPUT: 'email',
    ADD_USER_STRING: '+ Add User',
    PUBLISH_NOTIFICATION_BODY_PLACEHOLDER: 'Text for feed notification',
    PUBLISH_FEED_NOTIFICATION_OUTPUT: 'Post notification in feed',
    SPECIFIC_ROLES: 'Specific user roles',
    SPECIFIC_ROLES_FOR_EMAIL_PLACEHOLDER: 'Select who will receive email',
    SPECIFIC_ROLES_FOR_NOTIFICATION_PLACEHOLDER: 'Select who will receive notification',
    ALL_TEAM_MEMBERS_PLACEHOLDER:
      'All users in team, regardless of their permissions, are going to receive your email',
    ALL_TEAM_MEMBERS: 'All users in team',
    BOARD_LEVEL_ACCESS: 'Board-level Access',

    // WF: convert widget data
    ADD_INPUT: 'Add input',
    CONVERT_DATA_BLOCK_INPUT_PLACEHOLDER: 'Select string output from prior step(s)',
    ENTER_FORMULA: 'Enter formula',
    ENTER_FORMULA_PLACEHOLDER: 'e.g. =CONCATENATE(A1:A2)',
    ENTER_FORMULA_HINT: 'Use Standard Excel formula format.',
    DEFINE_OUTPUT_DATA_TYPE: 'Define output data type',
    STRING_DATA_TYPE: 'String',
    DATE: 'Date',
    UNFORMATTED: 'Unformatted',
    STANDARD: 'Standard',
    PERCENT: 'Percent',
    USD: 'USD, $',
    EURO: 'EUR, €',
    GBP: 'GBP, £',
    STRING_DATA_OUTPUT: 'String data',
    WIDGET_DATA_OUTPUT: 'Widget data',

    // board settings
    DEFAULT_LIBRARY: 'Default library',
    DEFAULT_LIBRARY_HINT:
      'Selected board will be the default option in the “Library” menu for this board',
    PUBLIC_BOARD_LABEL: 'Praxie Community Board',
    AUTO_REFRESH_3RD_PARTY_LABEL: 'Auto-refresh 3rd party data sources',
    MAKE_PUBLIC_BOARD:
      'Making this a “Community Board” allows any currently registered Praxie user to find and gain view-only access to all content on this board.',
    AUTO_REFRESH_3RD_PARTY_DESCRIPTION:
      'All widgets that use 3rd party data will be auto-refreshed each time page with widget is opened',
    DEFAULT_CARD: 'Default card',
    DEFAULT_CARD_HINT:
      'Select a default template card instead of a blank card when the "+" is tapped on the board',
    WELCOME_CARD_HINT:
      'Choose the default card that will automatically open and display the first time a user logs into this board',
    AUTHORIZATION_MODE_HINT: "Setup visibility access for users and users' direct reports",
    DEFAULT_CARD_ERROR: 'Please select a default card',
    DEFAULT_CARD_OPENING: 'Options for "Default template card"',
    DEFAULT_CARD_OPENING_OPTIONS: {
      board: 'Add card to board',
      detailed_view: 'Add card and open in Detailed view',
      full_screen: 'Add card and open in Presentation mode',
      card_builder: 'Add card and open in Card Builder'
    },
    FIND_A_BOARD: 'Find a board',
    FIND_A_CARD: 'Find a card',
    NO_BOARDS_FOUND: 'No boards found',
    NO_CARDS_FOUND: 'No cards found',
    NO_COLUMNS_FOUND: 'No columns found',
    SELECT_DEFAULT_CARD: 'Select card from default library',
    WELCOME_CARD: 'Welcome Card',
    AUTHORIZATION_MODE: 'Authorization mode',
    DEFAULT: 'Default',
    OWNED_CARDS: 'Owned Cards',
    AUTH_MODE_DEFAULT_DESC: 'Everyone can see all cards on board',
    AUTH_MODE_OWNERS_DESC:
      'Users who are Collaborators and Viewers can only see the cards they are assigned to as Owners',
    DIRECT_OWNERS: 'Owned & Direct Reports Cards',
    AUTH_MODE_DIRECT_OWNERS_DESC:
      'Users who are Collaborators and Viewers can see the cards they are assigned to as Owners, plus any card that one of their Direct Reports is assigned to as an Owner.',
    INDIRECT_OWNERS: 'Owned & Indirect Reports',
    AUTH_MODE_INDIRECT_OWNERS_DESC:
      'Users who are Collaborators and Viewers can see the cards they are assigned to as Owners, plus any card that one of their Direct Reports and indirect reports is assigned to as an Owner (the full cascading reporting tree).',
    SELECT_WELCOME_CARD_PLACEHOLDER: 'Select Welcome Card',
    WIDGETS: 'Widgets',
    DATA_RECORDS: 'Data Records',

    // board menu
    SHARED_BOARDS: 'Shared boards',
    PUBLIC_BOARD_TOOLTIP: name => `Community board shared by ${name}`,
    VIEW_ONLY_BOARD_TOOLTIP: 'View only',
    ON_THIS_BOARD: 'on this board now',
    COPYING_BOARD: 'Processing boards...',
    CREATE_NEW_TEAM: 'New team',
    NO_BOARDS_ADDED: 'No available boards',
    BOARD_ERROR: 'Board loading error',
    BOARD_REQUEST_ERROR: 'Oops! Board cannot be loaded. Please try again later.',
    HAS_ACTIVE_WORKFLOWS: 'Has active workflows',
    ADD_APP: 'Add App',
    CREATE_APP: 'Create App',
    DELETE_APP: 'Delete app',
    EXPORT_APP: 'Export app',
    EDIT_APP: 'Edit app',
    BOARD_AUTH_LOCK_TOOLTIP:
      'This board has limited access configuration for Collaborators and Viewers',
    PRAXIE_APP_STORE: 'Praxie App Store',

    // terminate user
    OOPS_CANCEL: 'Oops, cancel',
    TERMINATE: 'Terminate',
    TERMINATE_ACCOUNT: 'Delete account',
    TERMINATE_USER_TITLE: 'Sorry to see you go. Are you sure?',
    TERMINATE_USER_HINT:
      'This action will delete your account and permanently remove all mentions of you across all teams in the system.',
    TERMINATE_USER_ENTER_PASSWORD: 'To terminate account you should provide your password',
    ONLY_ADMIN_IN_THE_TEAMS: teamNames => `User is the only admin in the team(s): ${teamNames}`,
    CANT_DELETE_USER_WITH_CONNECTED_FILTERS: teamNames =>
      `Oops! Your account can not be deleted because you own Filters with live connections in the following Teams: ${teamNames}`,

    // delete team member confirm
    DEACTIVATE_PROFILE: 'Leave team',
    DELETE_MEMBER_TITLE: teamName => `Confirm You are Leaving ${teamName}`,
    DELETE_MEMBER_HINT1:
      "By removing this team from your profile, you'll lose access to all of the team's boards, content, and communications. To rejoin the team, you'll need to be invited back by an authorized user. ",
    DELETE_MEMBER_HINT2: 'Any other Praxie teams you belong to will not be affected.',
    DELETE_MEMBER_ENTER_PASSWORD: 'To leave the team you should provide your password',

    // delete team member rejection
    DELETE_MEMBER_REJECTION_TITLE: " Oops, you can't deactivate this profile just yet.",
    OK_GOT_IT: 'Ok, got it',
    GO_TO_TEAM: 'Go to Team Members Management',
    DELETE_MEMBER_REJECTION_HINT1: 'You are the only Admin of ',
    DELETE_MEMBER_REJECTION_HINT2:
      ' Team. If you want to leave the team, you need to assign another Admin before that.',

    // invite to team modal
    INVITE_PEOPLE: 'Invite people',
    INVITE_MEMBER: 'Invite member',
    INVITE_USERS: 'Invite users',

    // delete team
    DELETE_TEAM: 'Delete team',
    DELETE_TEAM_TITLE: 'Confirm Deletion of Team',
    DELETE_TEAM_HINT:
      'I understand that all of the team’s boards, cards, widgets and data will be permanently deleted',
    DELETE_TEAM_ENTER_PASSWORD1: 'To delete the team,',
    DELETE_TEAM_ENTER_PASSWORD2: 'please provide your password',
    TEAM_HAS_BEEN_REMOVED: teamName => `Team ${teamName}\nhas been successfully deleted.`,

    // team log
    DATE_TIME: 'Date - Time',
    EVENT_DESC: 'Event description',
    WIDGET_DELETE_INFORMATION: 'Some widgets on board not found',
    NO_LOGS: 'No logs',

    // issue counter
    WIDGET_NAME: 'Widget name',
    TOP_LABEL: 'Top Label',
    BOTTOM_LABEL: 'Bottom Label',
    COUNTER: 'Counter',
    COUNTER_PLACEHOLDER: 'Type number here',

    // board actions
    RENAME_BOARD: 'Rename board',
    DELETE_BOARD: 'Delete board',

    // widget navigation menu
    PHOTOS: 'photos',
    SHAPES: 'shapes',
    DONUT_CHARTS: 'Donut Charts',
    BAR_CHARTS: 'Bar Charts',
    FLAGS: 'Flags',
    OUTLINE_ICONS: 'Outline icons',
    SOLID_ICONS: 'Solid icons',
    LINES: 'lines',
    VIDEO: 'video',
    ICONS: 'icons',
    TEXT: 'text',
    TEXT_GROUPS: 'Text groups',
    TABLE_SAMPLES: 'Table Samples',
    FILE_MANAGER: 'File Manager',

    // color picker
    ADD_TO_FAVORITES: 'Add to favorites',

    // delete
    DELETE_CARD_TITLE: cardName => `Delete «${cardName}» card?`,
    DELETE_COLUMN_TITLE: columnName => `Delete «${columnName}» column?`,
    CONFIRM_DELETE: 'Confirm Delete',

    // notifications
    FOLLOWER_ASSIGN_NOTIFICATION: ' made you a follower of ',
    FOLLOWER_UNASSIGN_NOTIFICATION: ' removed you as a follower of ',
    OWNER_ASSIGN_NOTIFICATION: ' made you an "Owner" of the card ',
    OWNER_UNASSIGN_NOTIFICATION: ' removed you as an "Owner" of ',
    COMMENT_MENTION_NOTIFICATION: ' mentioned you in a comment ',
    COMMENT_DELETE_NOTIFICATION: ' deleted your comment from ',
    COMMENT_REPLY_NOTIFICATION: ' replied to your comment on ',
    TEAM_SHARE_NOTIFICATION: ' invites you to join ',
    TEAM_DEACTIVATE_NOTIFICATION: ' deactivated you from ',

    CARD_RENAME_NOTIFICATION_1: ' renamed ',
    CARD_RENAME_NOTIFICATION_2: ' to ',
    CARD_DELETE_NOTIFICATION: ' deleted ',
    CARD_POSITION_CHANGE_NOTIFICATION_1: ' moved ',
    CARD_POSITION_CHANGE_NOTIFICATION_2: ' from ',
    CARD_MODIFY_NOTIFICATION: ' modified the card',
    IN_CARD_BUILDER: ' in Card Builder',
    BOARDS_BUNDLE_EXPORTED_NOTIFICATION: ' exported ',
    BOARDS_BUNDLE_IMPORTED_NOTIFICATION: ' imported ',
    WIDGET_UPDATED_NOTIFICATION_1: ' updated ',
    WIDGET_UPDATED_NOTIFICATION_2: ' widget on ',

    WORKFLOW_PUBLISHED_NOTIFICATION: ' published notification',

    // profileNotifications
    USER_CARD_OWNER_REMOVED: 'I am removed as an "Owner" of a card',
    UPBOARD_WELCOME: 'Praxie welcome',
    CARD_REMOVED: 'Someone deletes a card',
    COMMENT_REPLY: 'I receive a reply to one of my comments on a card',
    USER_CARD_FOLLOWER_REMOVED: 'I am removed as a follower of a card',
    USER_TEAM_DEACTIVATE: 'Someone deactivates my team',
    CARD_MODIFIED: 'Someone modifies a card',
    COMMENT_MENTION: 'I am mentioned on a card',
    USER_CARD_FOLLOWER_ADDED: 'I am assigned as a follower of a card',
    USER_TEAM_INVITE: 'I am invited to join a new team',
    CARD_POSITION_CHANGED: 'Someone moves a card',
    COMMENT_REMOVED_BY_ADMIN: 'One of my comments is deleted from a card',
    CARD_RENAMED: 'Someone renames a card',
    WIDGET_UPDATED: 'Somebody updates a widget',
    USER_CARD_OWNER_ASSIGN: 'I am assigned as the "Owner" of a card',
    MARKETING_EMAILING: 'Praxie news, tools and tips',
    PROJECT_WIDGET_NOTIFICATIONS: 'Project Widget notifications',

    // project widget notifications
    FROM_THE_CARD: ' from the card:',
    USER_UNASIGNED_FROM_TASK_NOTIFICATION: ' unassigned you from a task: ',
    USER_ASIGNED_TO_TASK_NOTIFICATION: ' assigned a task to you: ',
    TASK_STATUS_CHANGED_NOTIFICATION: ' changed the status of your task, ',
    TASK_DURATION_CHANGED_NOTIFICATION: ' updated the duration of the task, ',
    PROJECT_START_DATE_CHANGED_NOTIFICATION: ' changed the start date of ',
    PROJECT_END_DATE_CHANGED_NOTIFICATION: ' has changed the end date of ',
    PROJECT_NAME_CHANGED_NOTIFICATION: ' changed the name of the project',
    HEADS_UP_NOTIFICATION: 'Heads up! ',
    TASK_END_DATE_IS_COMING: ' is due tomorrow.',
    TASK_IS_BEHIND_THE_SCHEDULE: ' is behind the schedule.',

    // smart link
    WEB_LINK: 'Web link',
    LINK_TO_CARD: 'Link to card',
    LINK_TO_BOARD: 'Link to board',
    LINK_ADDRESS: 'Link address',
    SELECT_CARD_TO_LINK: 'Select card you want to link',
    SELECT_BOARD_TO_LINK: 'Select board to link',
    OTHER_MODE: 'Other mode',
    BACK_BUTTON_INFO: 'Back Button mode. Button returns user to previous page.',

    // open mode
    CURRENT_CARD_LINK: 'Preserve current card view',
    DETAILED_VIEW_LINK: 'Open in Detailed view with board in background',
    PRESENTATION_MODE_LINK: 'Open in Presentation mode',
    FULLSCREEN_MODE_LINK: 'Open in Full Screen view',
    APP_VIEW_LINK: 'Open in App view',

    // organization
    ORGANIZATION_PROFILE: 'Workspace profile',
    ORGANIZATION_SETTINGS: 'Workspace Settings',
    WORKSPACE_MEMBERS: 'Workspace Members',
    ADD_TO_TEAM: 'Add to Team',
    BILLING_AND_PURCHASES: 'Billing and Purchases',
    AWAITING_CANCELLATION: 'Cancels {date}',
    PRO_PLAN_DESCRIPTION:
      'All file templates plus all basic interactive online apps and business processes',
    PRO_PLAN_EXPIRY_DATE: 'Infinite (Beta)',
    SWITCH_TO: 'Switch to ',
    SIGN_IN_TO_ANOTHER_ORGANIZATION: 'Sign in to another workspace...',
    MANAGE_ORGANIZATION_MEMBERS: 'Manage workspace members',
    REGION: 'Region',
    USA_REGION: 'USA region',
    CUSTOMIZE_FOR_ORGANIZATION: name => `Customize for ${name} workspace`,
    DELETE_ORGANIZATION: 'Delete workspace',
    UPLOAD_LOGO: 'Upload logo',
    CANT_LEAVE_ORGANIZATION: "You can't leave workspace",
    ORGANIZATION_DESCRIPTION_TITLE1: 'Name of the workspace or entity that owns',
    ORGANIZATION_DESCRIPTION_TITLE2: 'or manages this Praxie account',
    ORGANIZATION_REGION_TITLE: 'Preferred data storage location',
    ORGANIZATION_NAME_EXISTS: 'Workspace with this name already exists. Please try another name',
    ORGANIZATION_SESSION_TITLE: 'Session timeout',
    ORGANIZATION_SESSION_TIMEOUT: 'Timeout setting',
    ORGANIZATION_SESSION_TIMEOUT_PLACEHOLDER: 'e.g. 100',
    ORGANIZATION_SESSION_LIMIT_ERROR1: 'Limit exceeded. Max value is {maxValue}',
    ORGANIZATION_SESSION_LIMIT_ERROR2: 'Min value is {minValue}',
    ORGANIZATION_SESSION_DESCRIPTION:
      'If user is inactive for this duration, they will be automatically logged out and required to login again using their existing credentials',
    COMMUNITY_CONTENT: 'Community Content',
    COMMUNITY_BOARDS_HINT:
      'Сommunity Content includes "public" apps and boards that are available to other users outside of your account. Enabling Community Content for your account means that your account administrators and board managers can choose to make your content visible or available to be copied by other users into their own accounts. While we encourage sharing best practices across our community of users, we recommend that you do NOT enable these settings if you content contains confidential or proprietary information.',
    COMMUNITY_BOARDS_TEXT_1: 'Enable Community Content for this account',
    COMMUNITY_BOARDS_TEXT_2: name =>
      `Allow public ${name} community content to be copied into other non ${name} user accounts`,
    COMMUNITY_BOARDS_DESCRIPTION_1:
      'Allow my workspace’s administrators and board managers to list specific boards as public Community Boards for all Praxie users to see.',
    COMMUNITY_BOARDS_DESCRIPTION_2:
      'Allow my workspace’s administrators and board managers to designate and allow specific Community Boards to be copied by other Praxie users into their own workspaces.',
    ORGANIZATION_SETTINGS_TITLE: 'Organization settings',

    // delete organization
    DELETE_ORGANIZATION_TITLE: 'Heads Up! Are You Sure?',
    DELETE_ORGANIZATION_SUBTITLE: 'You’re about to delete this workspace’s entire account.',
    DELETE_ORGANIZATION_ENTER_PASSWORD1: 'To delete the workspace,',
    DELETE_ORGANIZATION_HINT:
      'I understand that all of this workspace’s teams, boards, cards, widgets, files, and messages will be permanently deleted and cannot be restored.',
    DELETE_ORGANIZATION_CONFIRMATION: name => `Workspace ${name} has been successfully removed`,

    // card authoring footer
    IS_LAST_SHOWN_SECTION:
      'Cannot delete this section since it is the only  currently visible section',
    MOVE_SECTION_UP: 'Move Section Up',
    MOVE_SECTION_DOWN: 'Move Section Down',
    MOVE_SECTION_RESTRICTION:
      "Oops! This section cannot be moved since it contains a widget that 's also sits on an adjacent section. Please move or delete the widget and try again.",
    ADD_SECTION_RESTRICTION:
      'Oops! New section cannot be added because the active section has a widget spanning two sections. Move the widget to a single section and try again.',

    // copy boards indicator
    HIDE_WINDOW: 'Hide window',
    SHOW_WINDOW: 'Show window',
    CLOSE_COPY_WINDOW: 'Close window. Copying will continue',
    CLOSE_IMPORT_WINDOW: 'Close window. Importing will continue',
    CLOSE_EXPORT_WINDOW: 'Close window. Exporting will continue',
    OPEN: 'Open',
    EXPORTED: 'Exported',
    IMPORTED: 'Imported',
    BOARDS_IN_BRACKETS: number => ` (${number} board(s))`,
    COPIYNG_BOARD_ACTIVE: number =>
      number > 1 ? `Copying ${number} boards` : `Copying ${number} board`,
    COPIYNG_BOARD_DONE: number =>
      number > 1 ? `${number} boards copied` : `${number} board copied`,
    EXPORTING_IN_PROGRESS: 'Exporting',
    IMPORTING_IN_PROGRESS: 'Importing',
    IMPORT_APP_INDICATOR_TITLE: 'Adding App to {tenant}',

    // app publish indicator
    APP_PUBLISHING_IN_PROGRESS: 'Publishing',
    CLOSE_APP_PUBLISH_WINDOW: 'Close window. Publishing will continue',
    PUBLISHED: 'Published',

    // boards export/import
    EXPORT_BOARDS: 'Export',
    EXPORT_BOARDS_DIRECTIONS: 'Select boards or an app to export as a downloadable file',
    IMPORT: 'Import',
    BUNDLE_NAME: 'Name your downloadable file',
    BOARDS_LIST_LABEL: 'Include these boards in export',
    EXPORT: 'export',
    NUMBER_OF_BOARDS: ' board(s)',
    OPERATION_FAILED: 'Operation failed',
    INCLUDE_THESE_BOARDS_IN_EXPORT: 'Include these boards in export',
    INCLUDE_THIS_APP_IN_EXPORT: 'Include this app in export',
    APP: 'App',
    BOARDS_WITHIN: 'Boards included',
    ONLY_N_BOARDS_INSIDE_APP: 'Apps with more than {N} boards exceed export size limited',
    NO_BOARDS_TO_EXPORT: 'No boards to export',
    NO_APPS_TO_EXPORT: 'No apps to export',
    BOARDS_REQUIRED_TO_EXPORT_APP: 'You need to have at least one Board in the App to export it',
    SPECIAL_SYMBOLS_ERROR: 'Bundle name can not contain special characters such as % / \\ # ?',
    DOTS_SYMBOLS_ERROR: 'Bundle name can not contain periods only',

    // bundle picker
    AUTHOR: 'Author',
    PREMIUM_BUNDLE_CONTACT_US: ' for beta access to premium business processes & templates',
    MORE: 'more',
    SELECTED_BUNDLE_HINT_NEXT: 'Already added',
    INFO_ABOUT_TEMPLATE: 'Template description',
    UPLOAD_TEMPLATE_FILE: 'Upload template file',
    TEMPLATE_FILE_CONDITION: 'Max size 50 Mb',

    // tenant creation modal
    TEAM_NAME: 'Team name',
    NAME_YOUR_TEAM: 'Name your team',
    CREATE_TEAM: 'Create team',
    TENANT_EMPTY_STATE: 'No boards exist yet for this team',

    // workspaces administration
    WORKSPACES_SEARCH_FIELD: 'Type something to start search...',
    SYSTEM_ADMIN_PANEL: 'System admin panel',

    // toggle hidden columns dropdown
    HIDDEN_COLUMNS_DESC: 'Click to make hidden columns visible to Board and Team Admins.',
    SHOWN_COLUMNS_DESC: 'Click to hide columns to Board and Team Admins.',

    // board settings
    BOARD_SETTINGS_TITLE: '{boardName} board settings',
    CREATE_NEW: 'Create new',
    CREATE_NEW_FILTER: 'New',
    WORKFLOWS: number => `Workflows (${number})`,
    WORKFLOW: 'Workflow',
    GENERAL_SETTINGS: 'General settings',
    BOARD_SETTINGS: 'Board settings',
    LIST_OF_WORKFLOW: 'Workflows',
    NO_WORKFLOW: 'No workflows added',
    NO_WORKFLOW_DESC: 'Use "Create new" button to create new workflow',
    RENAME_WORKFLOW: 'rename workflow',
    DUPLICATE_WORKFLOW: 'duplicate workflow',
    DELETE_WORKFLOW: 'delete workflow',
    COPY_WORKFLOW: 'Copy workflow',
    CHOOSE_TEAM: 'Choose team',
    CHOOSE_BOARD: 'Choose board',
    COPY_WORKFLOW_TO: 'Copy workflow to:',
    DELETE_WORKFLOW_TITLE: cardName => `Delete «${cardName}» workflow?`,
    WORKFLOW_DELETE_CONFIRMATION: 'Are you sure you want to delete this workflow?',
    TRIGGER_RULE: 'Trigger rule',
    TRIGGER_OBJECT: 'Trigger object',
    WORKFLOW_NAME: 'Workflow name',
    TRIGGER_EVENT: 'Trigger event',
    WORKFLOW_WILL_RUN: 'Workflow will be run after triggering',
    WORKFLOW_WONT_RUN: "Workflow won't be run after triggering",
    EDIT_WORKFLOW: 'Edit workflow',
    NO_RULES: 'No Rules',
    CURRENT_BOARD: 'Current board',
    ANOTHER_BOARD: 'Another board',

    // WB: Triggers block
    OBJECT: 'Object',
    EVENT: 'Event',
    FILTER: 'Filter',
    SELECT_FILTER_FOR_WIDGET_GROUP:
      'Select existing filter or create a new one to specify the widget group',
    COMBINE_WITH: 'Combine rules with',
    NO_CONDITIONS: 'No Conditions',
    NO_CONDITIONS_DESCR: 'Add condition(s) to create new rule',
    ADD_CONDITION: 'Add condition',
    RULE_CONFIGURATION: 'Rule configuration',
    ADD_RULE: '+ Add rule',
    TRIGGER_OUTPUT_TOOLTIP: 'The board, column, card and user where the trigger is located',
    TRIGGER_OUTPUT_TITLE: "All Trigger's parameters",
    MULTI_INPUT_CONDITION_PLACEHOLDER: 'Type and press enter to add',
    DELETED: 'Deleted',
    SOME_ITEMS_DO_NOT_EXIST: 'Some of these items do not exist',

    // WB: Hide widget block
    HIDE_WIDGET: 'Hide widget',
    UNHIDE_WIDGET: 'Unhide widget',
    HIDE_BLOCK_PREFIX: 'Hidden/Visible',
    HIDE_WIDGETS_GROUP: 'Hide widget group',
    UNHIDE_WIDGETS_GROUP: 'Unhide widget group',
    HIDE_WIDGETS_GROUP_TITLE: 'Hide Widget Group',

    // WB: Hide card section block
    ADD_SECTION: '+ Add section',
    HIDE_CARD_SECTION: 'Hide section',
    UNHIDE_CARD_SECTION: 'Unhide section',

    // WB: Hide column block
    HIDE_COLUMN_BLOCK: 'Hide column',
    HIDE_COLUMN_OPTION: 'Hide column',
    UNHIDE_COLUMN_OPTION: 'Unhide column',

    // WB: Lock widget block
    LOCK_WIDGET: 'Lock widget',
    UNLOCK_WIDGET: 'Unlock widget',
    LOCK_WIDGET_BLOCK_PREFIX: 'Locked/unlocked',
    LOCK_WIDGETS_GROUP: 'Lock widget group',
    UNLOCK_WIDGETS_GROUP: 'Unlock widget group',

    // WB: Move card block
    MOVE_CARD_TO_COLUMN: 'Move Card to Column',
    DEFINE_TARGET_COLUMN: 'Define target column as',
    DEFINE_TARGET_COLUMN_DESC: 'Column might be defined only within board of selected card input',
    COLUMN_NAME_EQUAL_TO_INPUT_FIELD: 'Column name is equal to [Input field]',
    COLUMN_NAME_EQUAL_TO_STRING: 'Column name is equal to [String Input]',
    COLUMN_NUMBER_EQUAL_TO_INPUT_FIELD: 'Column Number equal to [Input field]',
    VERY_LEFT_COLUMN: 'Very left column',
    VERY_RIGHT_COLUMN: 'Very right column',
    PREV_COLUMN: "Column to the left of the card's current column",
    NEXT_COLUMN: "Column to the right of the card's current column",
    COLUMN_NUMBER: 'Column number',
    RELATIVE_RIGHT_COLUMN_NUMBER: 'Move card to the right X columns:',
    RELATIVE_LEFT_COLUMN_NUMBER: 'Move card to the left X columns:',
    RELATIVE_COLUMN_NUMBER_LEFT_TOOLTIP:
      'Card will be moved to the left by the number of specified columns (e.g., selecting 3 will move card to the third column to the left)',
    RELATIVE_COLUMN_NUMBER_RIGHT_TOOLTIP:
      'Card will be moved to the right by the number of specified columns (e.g., selecting 3 will move card to the third column to the right)',
    TYPE_COLUMN_NAME: 'Type Column name',
    COLUMN_NAME: 'Column name',
    MOVE_BLOCK_PREFIX: 'Moved',
    FIND_COLUMN_SELECT_LABEL: 'Select how to search for column',

    // WB: Move column
    MOVE_COLUMN_OPTIONS_TITLE: 'Move Column',
    VERY_LEFT_POSITION: 'Far left',
    VERY_RIGHT_POSITION: 'Far right',
    LEFT_CURRENT_COLUMN: "To the left of the column's current position",
    RIGHT_CURRENT_COLUMN: "To the right of the column's current position",
    MOVE_COLUMN_LEFT_LABEL: 'Move column to the left X columns:',
    MOVE_COLUMN_RIGHT_LABEL: 'Move column to the right X columns:',
    MOVE_COLUMN_LEFT_TOOLTIP:
      'Column will be moved to the left by the number of specified columns (e.g., selecting 3 will move the column to the third position from the left)',
    MOVE_COLUMN_RIGHT_TOOLTIP:
      'Column will be moved to the right by the number of specified columns (e.g., selecting 3 will move the column to the third position from the right)',
    COLUMN_POSITION_FROM_LEFT: 'Defined position from the far left',
    COLUMN_POSITION_FROM_RIGHT: 'Defined position from the far right',
    COLUMN_POSITION_FROM_LEFT_LABEL: 'X position from the left',
    COLUMN_POSITION_FROM_RIGHT_LABEL: 'X position from the right',

    // WB: Rename blocks
    SELECT_WAY_OF_RENAMING: 'Select how to rename column',
    CUSTOM_TEXT: 'Custom text',
    AFTER_STRING: 'After [String]',
    BEFORE_STRING: 'Before [String]',
    USE_STRING_OUTPUT: 'Use [String]-output',
    USE_STRING_OUTPUT_CUSTOM_TEXT: 'Use [String]-output + custom text',
    ENTER_CUSTOM_TEXT: 'Enter custom text',
    RENAME_BLOCK_PREFIX: 'Renamed',
    RENAME_BOARD_BLOCK: 'Rename Board',
    RENAME_CARD_BLOCK: 'Rename Card',
    RENAME_COLUMN_BLOCK: 'Rename column',

    // WB Card Owner blocks
    SET_CARD_OWNER_CARD_BLOCK: 'Set Card Owner',
    CARD_OWNER_SET_OUTPUT: 'Set owner',
    CARD_OWNER_ADD_OUTPUT: 'Make owner',
    CARD_OWNER_UNSET_OUTPUT: 'Remove all owners',
    SET_CARD_OWNER_ACTION_TYPE: 'Action Type',
    CARD_OWNER_UNSET_TEXT: 'All Owners will be unset from this card',

    // WB: copy blocks
    DESTINATION_BOARD_FOR_COPY: 'Copy card to the board',
    DESTINATION_BOARD_FOR_COPY_PLACEHOLDER: 'Select board to receive the copied card',
    CARD_COPY: 'Card copy',
    BOARD_COPY: 'Board copy',
    COLUMN_COPY: 'Column copy',
    DESTINATION_CARD_FOR_COPY: 'Copy widgets to the card:',
    DESTINATION_CARD_FOR_COPY_PLACEHOLDER: 'Select card to receive the copied widgets',
    WIDGETS_BUNDLE_COPY: 'Widget group copy',
    COPY_GROUP_OF_WIDGETS: 'Copy group of widgets',
    COPY_COLUMN: 'Copy Column',
    COPY_COLUMN_DESTINATION_BOARD: 'Copy column to board',
    COPY_COLUMN_DESTINATION_BOARD_PLACEHOLDER: 'Select board to receive copied column',
    COPY_COLUMN_TOOLTIP: 'Column will be placed on far right of board',

    // WB: delete blocks
    DELETE_CARD_BLOCK: 'Delete Card',
    DELETE_CARD_BUNDLE_BLOCK: 'Delete Card Group',
    DELETE_CARD_OUTPUT: 'Deleted card',
    DELETE_CARD_BUNDLE_OUTPUT: 'Deleted Card Group',
    DELETE_WIDGET_BLOCK: 'Delete Widget',
    DELETE_WIDGET_OUTPUT: 'Deleted widget',
    DELETE_WIDGETS_BUNDLE_BLOCK: 'Delete Widget Group',
    DELETE_WIDGETS_BUNDLE_OUTPUT: 'Deleted Widget Group',
    DELETE_COLUMN_BLOCK: 'Delete Column',
    DELETE_COLUMN_OUTPUT: 'Deleted column',

    // WB: finish block
    DEFINE_FINAL_STEP_LABEL: 'Define “final step” action',
    DEFINE_IN_TRIGGER_OBJECT_SETTINGS: 'Define in trigger object setting',
    DISPLAY_CARD_WHEN_WF_FINISHED: 'Display card after the workflow is finished',
    DEFINE_IN_TRIGGER_OBJECT_SETTINGS_DESC:
      'This option allows you to define the final action for the button in the workflow configuration settings within the Card Builder page.',
    DISPLAY_BOARD_WHEN_WF_FINISHED: 'Display board after completing workflow',

    // WB: stage tracker block
    UNDEFINED_WIDGET_TYPE_WARN:
      "Unknown widget type identified. Select a Stage Tracker widget to ensure workflow doesn't break.",
    UNKNOWN_WIDGET_TYPE: 'Unknown widget type',
    MOVE_STAGE_BY: 'Move Stage by:',
    WITH_STAGE_WORKFLOW: 'with stage workflow',
    SELECT_STAGE_SWITCHING_WAY: 'Select how to move to next or previous stages',
    INCORRECT_WIDGET_TYPE: 'Incorrect widget. Select a Stage Tracker widget.',
    STAGE_NAME_PLACEHOLDER: 'Enter Stage name',
    MOVE_ONE_STAGE_LEFT: 'Move one stage left',
    MOVE_ONE_STAGE_RIGHT: 'Move one stage right',
    MOVE_TO_NOT_STARTED: 'Move to "NOT STARTED"',
    MOVE_TO_COMPLETE_STAGE: 'Move to "COMPLETE" stage',
    MOVE_TO_NAME_INPUT_FIELD: 'Move to stage with name equal to [INPUT field]',
    MOVE_TO_NAME_INPUT_STRING: 'Move to stage with name equal to [String Input]',

    // WB: find widget group block
    WIDGETS_TOKENS_PLACEHOLDER: 'Enter widget names and tap "Enter" to include in workflow',
    WIDGETS_FROM_CARD: 'Widgets selected',
    MAX_WIDGET_NAMES_COUNT_REACHED: 'You have reached the limit of widgets for Widget group',
    FIND_WIDGET_SELECT_LABEL: 'Select how to search for widget',
    WIDGET_NAME_EQUAL_TO_INPUT_FIELD: 'Widget name is equal to [Input field]',
    WIDGET_NAME_EQUAL_TO_STRING_INPUT: 'Widget name is equal to [String Input]',
    CARD_WITH_STRING_NAME: 'Card with [String] name',
    WIDGET_WITH_STRING_NAME: 'Widget with [String] name',
    BOARD_WITH_STRING_NAME: 'Board with [String] name',
    SELECT_WIDGET_FROM_FILTER: 'Select widgets from filter',
    FILTER_INPUT: 'Filter Input',

    // WB: find board block
    FIND_BOARD_SELECT_LABEL: 'Select how to choose board',
    BOARD_NAME_EQUAL_TO_INPUT_FIELD: 'Board name is equal to [Input Field]',
    BOARD_NAME_EQUAL_TO_STRING_INPUT: 'Board name is equal to [String Input]',
    BOARD_NAME_CONTAINS_COMBINED_INPUT: 'Board name contains [Input Field] + [String Input]',
    ENTER_BOARD_NAME: 'Enter board name',
    TEXT_INPUT: 'Board Name',
    RULES_LABEL: 'Combined with',
    INPUTS: 'Inputs',
    INPUTS_TOOLTIP:
      'Use both text and string inputs together to define the board name search results',
    TEXT_INPUTS: 'Text inputs',
    GET_WIDGET_DATA_TOOLTIP: 'First define column, then row',
    ADD_BOARD_STRING: '+ Add Board [String]',
    TEXT_INPUTS_HINT: 'Separate multiple text inputs with commas',
    MAX_TEXT_INPUTS_COUNT_REACHED: 'You have reached the limit of text input for Board',
    FOUND_BOARD: 'Found board',

    // WB: add card comment block
    ADD_COMMENT_BLOCK_POSTFIX: 'with added comment',
    UNKNOWN_STRING: 'Unknown string',
    UPDATED: 'Updated',

    // WB: Create blocks
    WITH_CUSTOM_NAME: 'With custom name',
    DEFAULT_NAME: 'Default name',
    CREATE_COLUMN_SOURCE_BOARD_TITLE: 'Select board for new column',
    CUSTOM_NAME: 'Custom name',
    CREATE_COLUMN_TOOLTIP: 'New column will be added to far right of board',
    DEFAULT_NAME_PH_ONE: 'By default ',
    DEFAULT_NAME_PH_TWO: ' name will be set',
    COLUMN_WITH_STRING_NAME: 'Column with [String] name',

    // WB: Email block
    SEND_EMAIL_BLOCK: 'Send Email',

    // WB: Find User block
    FIND_USER_BLOCK: 'Find User',
    FIND_USER_BLOCK_HINT:
      'String Variable should contain identificator (email) of the existing user in the system, otherwise Workflow execution will fail',
    USER_FOUND_BY_STRING: 'User found by [string]',
    PUBLISH_FEED_NOTIFICATION_BLOCK: 'Post Feed Notification',

    // WB: Widget Data block
    GET_WIDGET_DATA_OUTPUT: (x, y) => `Widget Data [${x}, ${y}]`,
    GET_WIDGET_CARD_DATA_OUTPUT: 'Widget Data: Card name',

    // workflow meta settings
    WORKFLOW_META_MODAL_TITLE: 'Workflow mode set-up',
    WORKFLOW_MODE: 'Workflow mode',
    WORKFLOW_MODE_DESC:
      'By turning on this mode the widget become a trigger for connected workflow',
    LOADING_MESSAGE: 'Loading message',
    LOADING_MESSAGE_DESC: 'Message that will be displayed during workflow execution',
    FINISH_MESSAGE_DESC: 'Message that will be displayed after workflow executed',
    FINISH_MESSAGE: 'Finish message',
    FINAL_STEP: 'Define "final" step',
    FINAL_STEP_DESC: 'Action that should happen upon completion of workflow',
    WORKFLOW_SCRIPT_MODE: 'Workflow script mode',
    WORKFLOW_SCRIPT_MODE_DESC:
      'By turning on this mode you will be able to provide your custom script to setup workflow',
    SCRIPT: 'Script',
    CLOSE_DETAILED_FINAL_STEP: 'Close Detailed card view and stay on board',
    STAY_ON_CARD_FINAL_STEP: 'Stay on current card and keep same view mode',
    MAX_SYMBOLS: number => `Max ${number} number of symbols`,
    OPEN_EXISTING_CARD: 'Open existing card',
    OPEN_CARD_FROM_WORKFLOW: 'Open card/board from defined workflow',
    OPEN_WEB_LINK: 'Open web link',
    OPEN_IN_DETAILED_VIEW: 'Open in Detailed view with board in background',
    OPEN_IN_FULLSCREEN_VIEW: 'Open in Presentation mode',
    OPEN_IN_EMBED_VIEW: 'Open in Full Screen view',
    OPEN_IN_APP_VIEW: 'Open in App view',
    CARD_WILL_BE_OBTAINED_FROM: 'Card from the',
    FINAL_BLOCK: 'final block',
    NO_WORKFLOW_CONNECTIONS: 'No connections yet',
    NO_WORKFLOW_CONNECTIONS_DESC:
      'Here will be displayed the list of all objects within the team that connected to this filter',
    OF_CONNECTED_WORKFLOW: 'of the Workflow will open after workflow runs',
    OPEN_CARD_FROM_WORKFLOW_HINT_1:
      'If no card is defined in the final block of the workflow, users will stay on the current view after the workflow is completed.',
    OPEN_CARD_FROM_WORKFLOW_HINT_2:
      'If more than one workflow exists with the card in the final block, the card of the last completed workflow will open',
    NUMBER_OF_TRIGGERED: 'Number of triggered workflows:',

    // Workflow execution indicator
    EXECUTING_N_WORKFLOWS: number => `Executing ${number} workflows`,
    WORKFLOW_IS_EXECUTED: 'Action completed',
    WORKFLOW_EXECUTED: 'Workflow executed',
    FINISHED: 'Finished',
    FAILED: 'Failed',
    CLOSE_WORKFLOW_EXECUTION_INDICATOR: 'Close window. Workflows execution will continue',
    NO_WIDGET_TO_RUN_WORKFLOW: 'Button Widget with expected Widgets ID is not found on the card',
    WIDGET_IS_NOT_IN_WORKFLOW_MODE:
      'Button Widget could not trigger Workflow execution because it is not in "Workflow" mode',

    // Comment box text sizes
    HEADLINE: 'Headline',
    BODY: 'Body',
    CAPTION: 'Caption',

    // Workflow builder
    TRIGGER: 'Trigger',
    FINISH_BLOCK: 'Finish block',
    TRIGGERS_BOARD: 'Trigger’s board',
    TRIGGERS_CARD: 'Trigger’s card',
    TRIGGERS_COLUMN: 'Trigger’s column',
    TRIGGERS_FILE: 'File',
    TRIGGERS_USER: 'Triggered By User',
    JSON_PAYLOAD: 'JSON payload',
    UNDEFINED_OUTPUT: 'Undefined',
    OUTPUT: 'OUTPUT',
    FROM_: 'from ',
    EMPTY_DRAG_AREA: 'Drag action block here to add it in workflow',
    BACK_TO_SETTINGS: 'Back to settings',
    ALL_CHANGES_SAVED: 'All changes saved',
    ERRORS_ARE_DETECTED: 'Errors are detected',
    ERRORS_DETECTED_IN_BLOCKS: 'Configuration errors are detected in the next action blocks:',
    ADJUST_WORKFLOW: 'Adjust workflow',
    SAVE_ANYWAY: 'Save anyway',
    FIRST_N_RESULTS: number => `The first ${number} results are displayed`,
    DELETE_BLOCK: 'Delete block',
    UNDEFINED_STRING: 'Undefined string',
    UNDEFINED_WIDGET: 'Undefined widget',
    UNDEFINED_WIDGET_DATA: 'Undefined data',
    UNDEFINED_CARD: 'Undefined card',
    UNDEFINED_CARD_BUNDLE: 'Undefined card group',
    UNDEFINED_BOARD: 'Undefined board',
    UNDEFINED_USER: 'Undefined user',
    UNDEFINED_WIDGETS_BUNDLE: 'Undefined widget group',
    UNDEFINED_COLUMN: 'Undefined column',
    UNDEFINED_JSON: 'Undefined JSON',
    UNDEFINED_FILE: 'Undefined File',
    CARD_LEVEL_INPUT: 'Card Input',
    CARD_BUNDLE_LEVEL_INPUT: 'Card Group Input',
    NOT_SELECTED: 'Not selected',
    FIND_WIDGET: 'Find widget',
    FIND_COLUMN: 'Find column',
    FIND_BOARD: 'Find board',
    TYPE_WIDGET_NAME: 'Enter name of widget',
    CARD_LEVEL_INPUT_PLACEHOLDER: 'Select card output from prior step(s) as the input',
    CARD_BUNDLE_LEVEL_INPUT_PLACEHOLDER: 'Select Card Group output from prior step(s) as the input',
    CREATE_WIDGET_GROUP_FROM_CARD: 'Create widget group from card',
    FIND_CARD: 'Find card',
    FIND_CARD_SELECT_LABEL: 'Select how to search for card',
    TYPE_CARD_NAME: 'Enter name of card',
    BOARD_LEVEL_INPUT: 'Board Input',
    TARGET_BOARD_LEVEL_INPUT: 'Destination board for the selected card',
    BOARD_LEVEL_INPUT_PLACEHOLDER: 'Select board output from prior step(s) as the input',
    TARGET_BOARD_LEVEL_INPUT_PLACEHOLDER: 'Select destination board from previous step(s)',
    DEFINE_STRING_FROM: 'Define string from',
    SELECT_BOARD_ATTRIBUTE: 'Select Board attribute',
    SELECT_CARD_ATTRIBUTE: 'Select Card attribute',
    SELECT_WIDGET_ATTRIBUTE: 'Select Widget attribute',
    SELECT_USER_ATTRIBUTE: 'Select User attribute',
    SELECT_COLUMN_ATTRIBUTE: 'Select Column attribute',
    WIDGET_LEVEL_INPUT: 'Widget Input',
    WIDGET_LEVEL_INPUT_PLACEHOLDER: 'Select widget output from prior step(s) as the input',
    STRING_LEVEL_INPUT: 'String Input',
    STRING_LEVEL_INPUT_PLACEHOLDER: 'Select string output from prior step(s)',
    WIDGETS_BUNDLE_LEVEL_INPUT: 'Widget group Input',
    WIDGETS_BUNDLE_LEVEL_INPUT_PLACEHOLDER: 'Select widget group from prior step(s) as the input',
    COLUMN_LEVEL_INPUT: 'Column Input',
    COLUMN_LEVEL_INPUT_PLACEHOLDER: 'Select column output from prior step(s) as the input',
    DEFINE_STRING_FROM_BOARD: 'Define String from Board',
    DEFINE_STRING_FROM_CARD: 'Define String from Card',
    DEFINE_STRING_FROM_WIDGET: 'Define String from Widget',
    DEFINE_STRING_FROM_USER: 'Define String from User',
    DEFINE_STRING_FROM_COLUMN: 'Define String from Column',
    STAGE_TRACKER_MOVE_STAGE: 'Stage Tracker: Move Stage',
    COPY_BOARD: 'Copy Board',
    GET_BOARD: 'Get board',
    GET_BOARD_PLACEHOLDER: 'Find board in the team',
    GET_COLUMN: 'Get column',
    GET_COLUMN_PLACEHOLDER: 'Find specific column',
    GET_CARD: 'Get card',
    GET_CARD_PLACEHOLDER: 'Find card in the team',
    GET_DATA_FROM_CELL: 'Get data from cell',
    GET_DATA_FROM: 'Get data from',
    DEFINE_CELL_TO_UPDATE: 'Define cell to update',
    CARD_NAME_EQUAL_TO_INPUT_FIELD: 'Card name is equal to [Input field]',
    CARD_NAME_EQUAL_TO_STRING_INPUT: 'Card name is equal to [String Input]',
    GET_WIDGET: 'Get widget',
    TARGET_WIDGET_FOR_UPDATE: 'Target Widget for update',
    GET_WIDGET_DATA: 'Get Widget Data',
    UPDATE_WIDGET_DATA: 'Update Widget Data',
    WIDGET_DATA_INPUT: 'Widget data Input',
    GET_WIDGET_PLACEHOLDER: 'Find widget in the team',
    UPDATE_WIDGET_DATA_PLACEHOLDER: 'Select widget data output from prior step(s) as the input',
    TARGET_WIDGET_PLACEHOLDER: 'Select target widget from previous blocks',
    NO_INPUTS_FOUND: 'No inputs found',
    PROVIDE_MANDATORY_INFORMATION: 'Please provide all required mandatory information',
    INVALID_BLOCK: 'Invalid block',
    WORKFLOW_WILL_BE_LOST: name =>
      `If you continue without saving, all changes on ${name} workflow will be lost. Do you want to save changes?`,
    OBJECT_NOT_FOUND: type => `${type} not found`,
    SHOW_WORKFLOW_EXECUTION: 'Show workflow execution',
    SHOW_WORKFLOW_EXECUTION_DESC:
      'With turning on this control process of workflow execution will be shown to the user.',
    NO_BOARDS_IN_TEAM: 'There are no boards in the Team',
    NO_COLUMNS_IN_TEAM: 'There are no columns in the Team',
    NO_CARDS_IN_TEAM: 'There are no cards in the Team',
    NO_WIDGETS_IN_TEAM: 'There are no widgets in the Team',
    WIDGETS_BUNDLE: 'Widget group',
    GET_USER: 'Get user',
    GET_USER_PLACEHOLDER: 'Find user',
    NO_USERS_FOUND: 'No users found',
    NO_USERS_IN_TEAM: 'There are no users in the Team',
    USER_LEVEL_INPUT: 'User Input',
    USER_LEVEL_INPUT_PLACEHOLDER: 'Select user output from prior step(s) as the input',
    NO_BLOCKS_FOUND: 'No blocks found',
    WORKFLOW_BLOCKS_EMPTY_HINT:
      'We are doing our best to deliver more and more blocks for yours workflows',
    DEFINE_STRING_OPTION_BOARD_ID_NAME: 'Board ID',
    DEFINE_STRING_OPTION_BOARD_NAME: 'Board Name',
    DEFINE_STRING_OPTION_CARD_NAME: 'Card Name',
    DEFINE_STRING_OPTION_CARD_ID: 'Card ID',
    DEFINE_STRING_OPTION_WIDGET_CLASS: 'Widget Class',
    DEFINE_STRING_OPTION_WIDGET_NAME: 'Widget Name',
    DEFINE_STRING_OPTION_WIDGET_ID: 'Widget ID',
    DEFINE_STRING_OPTION_WIDGET_DATA: 'Widget Data',
    DEFINE_STRING_OPTION_USER_ID: 'User ID',
    DEFINE_STRING_OPTION_USER_NAME: 'User email',
    DEFINE_STRING_OPTION_USER_DISPLAY_NAME: 'Display Name',
    DEFINE_STRING_OPTION_USER_MANAGER_NAME: "Manager's email",
    DEFINE_STRING_OPTION_OWNER_EMAIL: "Card Owner's email",
    DEFINE_STRING_OPTION_OWNER_ID: "Card Owner's ID",
    DEFINE_STRING_OPTION_COLUMN_NAME: 'Column name',
    DEFINE_STRING_OPTION_COLUMN_ID: 'Column ID',
    WIDGET_DATA_CARD_NAME: 'Card name',
    WIDGET_DATA_UBF: 'Cell data',
    CREATE_COLUMN: 'Create column',
    ADD_COMMENT: 'Add Comment',
    SYSTEM_ACTIONS_WF_GROUP_NAME: 'System Actions',
    DELETE_WIDGET: 'Delete Widget',
    JSON: 'JSON',
    FILE: 'File',
    CARD_BUNDLE: 'Card group',

    // widgets placeholders
    RENDERING: 'Rendering...',
    WIDGET_RENDERING_ERROR:
      'Something went wrong in the widget execution. Refresh the page to reset the state of the widget.',

    // SQL widget
    SQL_WIDGET: 'SQL Widget',
    SQL_REQUEST_TITLE: 'SQL request',
    SQL_EDITOR_PLACEHOLDER: 'Enter SQL code.',
    SET_UP_SQL: 'Set-up sql',
    RUN_SYNC: 'Run sync',
    LAST_SYNC: 'Last sync',
    LAST_ERROR: 'Last error',
    NO_ERRORS: 'No errors',
    VERIFY_CONNECTION: 'Verify Connection',
    RUN_SQL: 'Run SQL',
    SQL_SETUP: 'SQL set-up',
    EMPTY_SET_UP: 'Not set-up yet',
    SETUP_CREDENTIALS_LABEL: 'Select credentials',
    SETUP_DATABASE_LABEL: 'Database Address',
    SETUP_DATABASE_NAME_LABEL: 'Database Name',
    SETUP_PORT_LABEL: 'Port',
    SETUP_CREDENTIALS_PLACEHOLDER: 'Please select credentials',
    SETUP_DATABASE_PLACEHOLDER: 'e.g. 123.123.123.123',
    SETUP_DATABASE_NAME_PLACEHOLDER: 'Type database name',
    SETUP_PORT_PLACEHOLDER: 'e.g. 1234',
    SETUP_CONNECTION_ERROR:
      'Something went wrong. Please check credential parameters or reconfigure SQL set-up.',
    SCHEDULE_PERIOD_TITLE: 'Sync period',
    SCHEDULE_MINUTES_PLACEHOLDER: 'e.g. 7',
    SCHEDULE_PERIOD_PLACEHOLDER: 'Please select period',
    SCHEDULE_TIME_TITLE: 'Set time',
    SQL_STATUS_TITLE: 'SQL Status:',
    SQL_RESTRICTION_MESSAGE: 'Configure SQL from detailed view in App or Board',
    SCHEDULE_REPEAT_SYNC: 'Repeat sync',
    SCHEDULE_START_AT: 'starting at',
    SCHEDULE_MINUTE_OF_HOUR: 'minute(s) after an hour',

    // global filters list
    APPLY: 'Apply',
    FILTER_NAME: 'Name',
    FILTERS_OBJECT: 'Object',
    CREATED: 'Created',
    MY_FILTERS: 'My Filters',
    ALL_FILTERS: 'All Filters',
    NO_FILTERS_CREATED: 'No Filters exist in this team',
    NO_FILTERS_CREATED_DESC:
      'Filters created here are available across your team for anyone with permissions to view and use',
    NO_FILTERS_FOUND: 'No Filters found',
    ADJUST_SEARCH_REQUEST: 'Modify your search and try again',
    NO_FILTERS_CREATED_BY_YOU: 'You have no existing filters',
    NO_FILTERS_CREATED_BY_YOU_DESC:
      'Create a new filter here. Filters created here are available across your team for anyone with permissions to view and use',
    PERMISSIONS: 'Permissions',
    RULES: 'Rules',
    LIST_OF_GLOBAL_FILTERS: 'Filters',
    NO_FILTERS_WITH_CARD_OBJECT: 'No filters with Card object exist',
    NO_FILTERS_WITH_WIDGET_OBJECT: 'No filters with Widget object exist',
    OBJECT_MATCH_TOOLTIP: object => `Only filters for ${object} can be applied`,
    CANT_DELETE_FILTER_WITH_CONNECTIONS: 'Filter cannot be deleted due to existing connections',
    CANT_DELETE_FILTER_OWN_BY_ANOTHER_USER: "You can't delete filters created by other users",
    CANT_EDIT_FILTER_OWN_BY_ANOTHER_USER: "You can't edit filters created by other users",
    CONNECTIONS: 'Connections',
    HEADS_UP: 'Heads up!',
    YOU_APPLY_FILTER_OWNER_DY_ANOTHER_USER: "You've applied a filter owned by another user",
    COPY_FILTER_OWNED_BY_ANOTHER_USER:
      'If the current owner modifies the filter, it may change your desired data or results. To avoid this, create your own version of this filter by copying this one.',
    NEW_FILTER_NAME: 'New filter name',
    COPY_FILTER: 'Copy filter',
    NO_EXISTING_FILTER: 'No existing filter',
    CHANGE: 'Change',
    FILTER_DETAILS: 'Filter details',
    FILTER_WORKS_ON_OWNER_PERMISSIONS: "Filter works based on the Owner's permissions",
    BOARDS_LIMITED_ACCESS: 'Boards Limited Access',
    IS_CURRENT: 'is current',
    IS: 'is',
    NO_FILTERS: 'No filters',
    FILTERS_LIST_HINT: 'View',
    LIST_OF_ALL_FILTERS: 'list of existing Filters',
    FILTERS_SEARCH_PLACEHOLDER: 'Find card filter',
    FILTER_EDIT_RESTRICTION_MESSAGE: 'You cannot edit filters created by other users',

    // Global Filter configuration
    FILTER_CONFIGURATION: 'Filter configuration',
    LOAD_CONNECTIONS: 'Load Connections',
    SAVE_AND_APPLY: 'Save & Apply',
    FILTER_RESULT: 'Filter result',
    REMOVE_FILTER: 'Remove filter',
    FILTER_OBJECT: 'Filter object',
    PLAIN_TEXT: 'Plain text',
    SELECT_FILTER_OBJECT_TOOLTIP: 'Select object to use with the filter',
    FILTER_NAME_PLACEHOLDER: 'Type filter name here',
    SET_FILTER_RULES: 'Set Rules',
    FILTER_OBJECT_ERROR: "Filter doesn't match the object of the entity. Please change it",
    RULES_ERROR: 'At least one rule is required',
    CANT_CHANGE_FILTER_OBJECT_TOOLTIP:
      "You can't change filter object because the filter has some connections",
    CHANGES_WILL_BE_APPLIED_TO_ALL_CONNECTIONS:
      'Heads up! Changes will be applied to all connections.',

    // sso configuration
    UPLOAD: 'Upload',
    NO_FILE_ADDED: 'No file added',
    IDENTITY_PROVIDER: 'Identity provider',
    OKTA: 'Okta',
    NO_SSO_CONFIGURATION: 'No identity provider for single sign-on (SSO) access to this Workspace',
    CONFIGURE_SSO: 'Configure SSO',
    SIGN_IN_WITH_SSO: 'Sign in with SSO',
    SIGN_IN_WITH_SSO_SUBTITLE_1: 'Enter name of workspace',
    SIGN_IN_WITH_SSO_SUBTITLE_2: 'you want to sign in with SSO',
    CONNECTED_IDENTITY_PROVIDER: 'Connected Identity Provider',
    SSO_ONLY: 'SSO Only',
    JIT_PROVISIONING: 'JIT Provisioning',
    SSO_ONLY_HINT: 'Users can only login by using selected SSO provider',
    JIT_PROVISIONING_HINT: 'Automated creation of new Praxie User with first SSO sign in',
    DELETE_SSO_CONFIGURATION: identityProvider =>
      `Delete configuration for Identity Provider "${identityProvider}" for your Workspace?`,
    DELETE_SSO_CONFIGURATION_DESCRIPTION:
      'Heads up! If you delete the configuration for the selected Identity Provider, users will not be able to sign in into your Workspace using SSO.',
    CHOOSE_IDENTITY_PROVIDER: 'Choose identity provider',
    CONFIGURE_SSO_CONNECTION: 'Configure your SSO connection',
    SSO_CONFIGURATION: 'SSO Configuration',
    SELECT_IDENTITY_PROVIDER_DESCRIPTION:
      "Select your company's identity provider to be used with your Praxie account",
    ADD_ROW: '+ Add Team',
    SELECT_TEAM: 'Select Team',
    SELECT_BOARDS: 'Select Boards',
    SELECT_BOARDS_TO_COPY: 'Select boards to copy',
    SELECT_ROLE: 'Select Role',
    COPY_FROM: 'Copy from',
    BOARDS_TO_COPY: 'Boards to copy',
    DEFAULT_ROLE_COPIED: 'Default role for boards',
    DEFAULT_TEAM: 'Default team',
    DEFAULT_BOARDS: 'Default boards',
    DEFAULT_ROLE: 'Default role',
    JIT_PROVISIONING_DESC: 'New user automatically created in Praxie upon first SSO sign in',
    WAY_OF_USER_CREATION: 'Select how new users will be created',
    SELECT_TEAM_TO_COPY_IN: 'Select Team for new boards',
    SELECT_EXISTING_BOARDS: 'Select specific Teams/Boards for access by new users',
    SELECT_EXISTING_BOARDS_TO_COPY: 'Select Boards to be created for each new user',
    EXISTING_CONTENT: 'Provide access to existing content for new user',
    COPIED_CONTENT: 'Create new board(s) for each new user',
    TARGET_TEAM: 'Team Name',
    ENTER_SSO_URL: 'Enter your SSO URL',
    SSO_URL_HINT: 'mycompany.okta.com',
    SSO_ISSUER: 'SSO Issuer',
    SSO_ISSUER_HINT: 'mycompany.okta.com/ISSUERID',
    SSO_CERTIFICATE_KEY: 'SSO Certificate Key',
    SSO_CERTIFICATE_KEY_HINT: 'Paste your SSO Key Value here',
    SSO_UNSAVED_MESSAGE_TITLE: 'You have unsaved changes',
    SSO_UNSAVED_MESSAGE_BODY:
      'Heads up! If you cancel, all changes in SSO configuration will be lost.',
    NO_SSO_CONFIGURED_ERROR: 'No SSO configured for this workspace',
    NO_PASSWORD_IS_REQUIRED: 'No password is required',
    NO_PASSWORD_SSO_ONLY_SIGNIN: 'SSO Only Signin is set for this account',
    SSO_CONFIGURATION_LINKS_TITLE: 'Copy Praxie SSO configuration links',
    COPY_TO_CLIPBOARD: 'Copy to clipboard',
    LINK_IS_COPIED_TO_CLIPBOARD: 'Link is copied to clipboard',
    LINK_IS_COPIED: 'Link copied!',
    SSO_CONFIGURATION_LINKS_WARNING:
      'The SSO connection wouldn’t be fully set without pasting these links into corresponding fields in Okta Praxie app configuration',
    BOARD_NAMING_CONVENTION: 'Naming convention for new boards',
    SELECT_EXISTING_BOARDS_TO_COPY_SHARE:
      'Select existing Teams/Boards to be copied and shared with new users by default',
    ORIGINAL_BOARD_PREFIX: 'Original Board name',
    ORIGINAL_BEFORE_DISPLAY_NAME_PREFIX: 'Original Board name + new user’s Display Name',
    ORIGINAL_AFTER_DISPLAY_NAME_PREFIX: 'New user’s Display Name + original Board name',

    // saml sso configuration
    SAML_SSO_URL_HINT: 'mycompany.saml.com',
    SAML_SSO_ISSUER_HINT: 'mycompany.saml.com/ISSUERID',
    SAML_SSO: 'Custom SAML SSO',

    // switcher operators
    AND: 'And',
    OR: 'Or',

    // Home page
    HOME: 'Home',
    APPS: 'Apps',
    SEE_ALL: 'See all',
    VIEW_ALL: 'View all',
    APPS_SWIM_LANE_TITLE: 'Featured from',
    WELCOME_ONBOARD_GREETING: 'Get your team up and onboard',
    COMMUNITY_BOARD: 'Community Board',
    PLEASE_CHOOSE_TEAM: 'Please choose the team',
    HOME_PAGE_NO_SEARCH_RESULTS: 'No search results',
    HOME_PAGE_BANNER_TITLE: 'Get your team up to speed and on board',
    TRAINING_CENTER_CARD: 'Training Center',
    APP_STORE_CARD: 'App Store',
    APP_STORE_APPS: 'Apps',
    BROWSE_APPS: 'Browse Apps',
    NEW_APPS_TOOLTIP: 'New Apps!',
    APP_STORE_CARD_DISABLED_TOOLTIP: 'This feature will be available soon',
    IMPORTED_APP_COACHMARK_FINISH: 'Got it',
    SUBSCRIBED_APPS: 'My Apps',
    SUBSCRIBED_APPS_TEXT:
      'All Apps available in your subscription(s) appear here. The Apps you add can be opened from your',
    GROUP_BY: 'Group by',
    NO_APPS_YET: 'No apps yet',
    FIND_NEW_APPS: 'You can find new apps in Praxie App Store',
    SOMETHING_WENT_WRONG: 'Something went wrong',
    PLEASE_RELOAD_THE_PAGE: 'Please reload the page',
    RELOAD_PAGE: 'RELOAD PAGE',
    INVITE_TO_PRAXIE: 'Invite your team to Praxie',

    // My actions page
    MY_ACTIONS: 'My Actions',
    MY_TASKS: 'My Tasks',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    FIND_TASK: 'Find a task',
    NO_TASKS_YET: 'No tasks yet',
    ADD_FIRST_TASK: 'You can add first task in Project Management Widget from Detailed or App view',
    TRY_ANOTHER_FILTER: 'Let’s try another filter',
    TASKS_LOADING_ERROR: 'Problem with tasks loading',
    CLEAR_DATES: 'Clear Dates',
    PERIOD: 'Period',
    SEE_PERIOD: 'See Period',
    PERIOD_TOOLTIP: 'Period {duration} days',
    UNKNOWN_ERROR_FOR_MY_ACTIONS:
      'Unknown error occurred while loading My Tasks table. Please try to reload the page',
    USERS_ASSIGNED_TOOLTIP: 'Users assigned',
    ALLOCATION: 'Allocation',
    ASSIGNEES: 'Assignees',
    UNASSIGN_ALL: 'Unassign all',
    NO_MORE_USERS_SUGGEST: 'No more users to suggest',
    NO_MATCHES_FOUND: 'No matches found',
    PARENT_TASK_NAME: 'Parent task: {parentName}',
    EDIT_TASK_NAME: 'Edit task name',
    PROJECT_NAME: 'Project name',

    // entitlement coachmarks
    UPLOADED_APP_COACHMARK_TITLE: 'Here’s Your App!',
    HOMEPAGE_COACHMARK_TITLE: 'Here is your Homepage',
    HOMEPAGE_COACHMARK_CONTENT:
      'You can add apps to your Homepage from Subscribed Apps page by clicking on "Add" button.',
    HOMEPAGE_COACHMARK_COLLECTION_TITLE:
      'This is your Homepage. Access apps that you add from your Subscribed Apps and the App Store here',
    HOMEPAGE_COACHMARK_COLLECTION_CONTENT: 'Take a tour to learn more',
    AVAILABLE_APPS_COACHMARK_TITLE: 'Available Apps',
    AVAILABLE_APPS_COACHMARK_CONTENT:
      'The apps in your subscription(s) are here. Click “Add” on any app to add it to your Homepage',
    APP_STORE_COACHMARK_TITLE: 'App Store',
    APP_STORE_COACHMARK_CONTENT: 'Get more great apps for your project, team, and organization',
    INVITE_COACHMARK_TITLE: 'Invite Your Team',
    INVITE_COACHMARK_CONTENT: 'Invite others to join your team and collaborate',
    USER_PROFILE_COACHMARK_TITLE: 'Complete Setup',
    USER_PROFILE_COACHMARK_CONTENT: 'Update your profile, subscriptions, and settings',
    SUBSCRIPTION_COACHMARK_TITLE:
      'Visit the App Store to get templates and add applications to your workspace.',
    PROCESS_PRO_COACHMARK_TITLE: 'of apps and templates in the App Store',
    TEMPLATES_PRO_COACHMARK_TITLE: 'templates in the App Store ',
    NEW_IMPORTED_APP_COACHMARK_TITLE: 'Here’s your new App!',

    // bundle details page
    BACK_HOME: 'Back home',
    DESCRIPTION: 'Description',
    APP_PAGE_FILE_TEMPLATE_TITLE: 'File Template',
    NO_BUNDLE: "Bundle doesn't exit",
    DONT_HAVE_PERMISSIONS_TO_GET_BUNDLE:
      'You don’t have permissions to add this App in any team.\nPlease ask admin of your team to add App there.',

    // error messages
    NO_BOARD_LOADING: 'Oops! Page didn’t load.',
    TRY_RELOAD_BOARD: 'Refresh to try again.',
    TRY_WORKFLOW_AGAIN: 'Oops! Workflow error. Please try again.',
    CARD_ERROR_500: 'Oops! Error loading card',
    PLEASE_REFRESH: 'Please refresh to try again',
    CARD_ERROR_404: 'Oops! Card not found',
    ERROR_404_DESC: 'It is either deleted or does not exist',
    ERROR_403: 'Oops! Access denied',
    ERROR_403_DESC: 'You do not have permission to view this card',
    SAVE_ERROR: 'Heads up! Your change wasn’t saved, try once again.',
    LOADING_FAILED: 'Loading failed. Please try again',
    BOARD_AUTH_LOCK_ERROR:
      'Oops! You have no permissions to view this Cards. Contact your Workspace Administrator.',
    MAX_WORKFLOWS_COUNT_ERROR: '{number} max',
    FILL_COUNTER_INPUT_ERROR: 'Please fill in and save all inputs first',
    SOMETHING_WENT_WRONG_TRY_AGAIN: 'Something went wrong. Please try again.',

    // workspace members
    DATE_ADDED: 'Date added',
    EXPIRATION_DATE: 'Expiration date',
    MANAGER: 'Manager',
    DIRECT_REPORTS: count => `direct report${count === 1 ? '' : 's'}`,
    N_SELECTED: '{count} Selected',
    ASSIGN_MANAGER: 'Assign manager',
    SET_MANAGER_TO_USER: 'Assign manager for this user',

    // Workspace members filter
    WM_FILTER_RULES_LABEL: 'Show users where',
    WM_ADD_FILTER_BTN: 'Add filter',
    IS_EQUAL_PREDICATE: 'is equal to',
    IS_NOT_EQUAL_PREDICATE: 'is not equal to',
    FILTERS_APPLIED: count => `${count} filter${count === 1 ? '' : 's'} applied`,
    AND_MORE: ' and {count} more',
    PREDICATE_VALUE_SEPARATOR: '{predicate}: ',
    FILTERED_COUNT: '{filterTotal} of {total} filtered out',
    REMOVE_USER: 'Remove user',
    SELECT_MANAGER: 'Select manager',
    INVITED_IN_TENANT_TOOLTIP: 'User was invited, but hasn’t accepted invitation yet',
    NO_MEMBERS_FOUND: 'No members found',
    LETS_TRY_ANOTHER_SEARCH_REQUEST: 'Let’s try another search request',
    USER_SEARCH_TOOLTIP: 'Search requires exact user name (full user name and case sensitive)',
    ASSIGN_MANAGER_TITLE: 'Assign Manager',
    REMOVED_MEMBER: 'Removed member',
    REMOVE_USER_TITLE: 'Remove User',
    COULDNT_REMOVE_WORKSPACE_MEMBER: "We couldn't remove Workspace Member",
    WORKSPACE_MEMBER_REMOVE_DESC: 'Please confirm you want to remove ',
    USER_REMOVE_REJECTION_TITLE: 'Oops! User can’t be removed.',
    USER_REMOVE_REJECTION_DESC: 'This user is assigned as a ',
    USER_REMOVE_REJECTION_REASSIGN_MANAGER:
      'Reassign Manager for all direct report(s) to remove this user.',
    DELETE_USER_TOAST_MESSAGE: 'User {username} has been successfully deactivated.',
    FIND_USER_BY_NAME: 'Find User by user name',

    // user profile
    MEMBER_PREVIEW_BACK_BUTTON_TOOLTIP: 'Back to members list',
    EDIT_ROLE: 'Edit role',
    REMOVE_USER_FROM_TEAM: 'Delete user from team',
    TEAMS_TAB: 'Teams ({count})',
    SUBORDINATES_TAB: 'Direct Reports ({count})',
    USER_SUBORDINATES_SEARCH_FIELD: 'Find subordinate',
    USER_SUBORDINATES_NO_SEARCH_RESULTS: 'No search results',
    NO_USER_SUBORDINATES_FOUND: 'No subordinates found',
    NO_RECORDS_FOUND: 'No Records Found',
    ORGANIZATION_ADMIN_TABLE_HEADER: 'Workspace Admin in {allTeams}',
    ALL_TEAMS: 'All teams',
    ADD_MANAGER_SUBORDINATES: 'Add new Direct Report',
    REMOVE_SUBORDINATE: 'Remove direct report',
    REMOVE_SUBORDINATE_DESC:
      'Confirm you want to remove {subordinate} from list of direct reports for {username}',
    REMOVE_SUBORDINATE_ERROR_MESSAGE: 'Subordinate deletion error. Please try again.',
    ADD_MANAGER_SUBORDINATES_TOAST_MESSAGE: 'New subordinate(s) have been added',
    ADD_MANAGER_SUBORDINATES_ERROR_MESSAGE: 'Adding new Direct Report error. Please try again',
    MEMBER_ASSIGNMENT_COMPLETED: 'Successfully assigned',
    MEMBER_ASSIGNMENT_ERROR: 'Assignment error. Please try again',

    // app view
    UNSELECT_WIDGET: 'Unselect widget',
    DISPLAY_CARD_NAME_IN_APP_VIEW: 'Display card names as page headings within the app',
    ALLOW_TO_EDIT_CARD_IN_APP_VIEW: 'Enable editing from App View',
    ALLOW_USERS_TO_SEE_BOARDS_LIST: 'Display list of boards within App in main menu',
    ENABLE_EXPORT_IN_APP_VIEW: 'Enable PDF/Image exports from within App',
    SCREEN_ORIENTATION_WARNING: 'Switch to landscape view for a better experience',
    EDIT_CARD_DESIGN: 'Edit Page Design',

    // homescreen preview settings
    HOME_SCREEN_PREVIEW: 'Home Page Image',
    HOME_SCREEN_PREVIEW_HINT: 'Set up thumbnail image of this board to display on the home page',
    HOME_SCREEN_PREVIEW_PLACEHOLDER_DESC:
      'By default, a thumbnail image with the first letter of the board name is displayed',
    HOME_SCREEN_PREVIEW_CARD_SNAPSHOT_DESC:
      'Thumbnail of the selected card will be displayed to represent this board on the home page',
    CARD_SNAPSHOT: 'Existing Cards',
    NOT_SPECIFIED: 'Not specified',
    GO_HOME: 'Go Home',

    // my apps
    IMPORT_APP: 'Import app',

    // app delete
    DELETE_WHOLE_APP: 'Delete the whole App',
    DELETE_APP_WITHOUT_BOARDS: 'Preserve boards',
    DELETE_APP_CONFIRMATION_TITLE: "Heads up! You're about to delete {appName}",
    DELETE_APP_CONFIRMATION_DESC: `By removing this App from {tenantName}, all users will lose access to all of the apps' boards, content, and communications.
            The following boards will be deleted:`,
    DELETE_EMPTY_APP_CONFIRMATION_DESC:
      'By removing this App from {tenantName}, you won’t be able to interact with it anymore.',
    DELETE_APP_BOARDS_DESC:
      'To remove {appName} but preserve the content from this app as separate boards within {tenantName} select Preserve Boards. Note: the navigation between boards will be lost.',
    DELETE_APP_ERROR:
      'Oops! This app is currently published in the App Store. Un-publish and try again.',

    // app builder
    APP_WILL_BE_LOST:
      'If you continue without saving, all changes on {appName} app will be lost. Do you want to save changes?',
    APP_NAME: 'App name',
    APP_EMPTY_BOARDS_LIST_TITLE: 'App does not contain any boards',
    APP_EMPTY_BOARDS_LIST_DESC: 'Add a board to start creating App',
    N_BOARDS_IN_APP: '{number} Boards in App',
    NO_BOARDS_IN_TEAM_TO_ADD_IN_APP:
      'There are no available boards in the team. Add at least one board to invite users to the App',
    ADDING_BOARDS_TO_APPS_TITLE: 'Adding boards to App',
    START_ADDING_BOARDS_TO_APPS_TITLE: 'Add boards to App',
    BOARDS_SELECTION_BOARDS_COUNT: count => `All ${count} ${count > 1 ? 'Boards' : 'Board'}`,
    BOARDS_SELECTION_TEAM_LABEL: 'from {teamLabel} team',
    APP_SERVER_ERROR_TITLE: 'Something went wrong…',

    APP_NAVIGATION_PREVIEW: 'App navigation preview',
    APP_CONTENT_WILL_BE_HERE: 'App content will display here',
    APP_THUMBNAIL: 'App thumbnail',
    DROP_APP_LOGO_HERE: 'Upload image (png, jpeg, svg) via drag and drop or {browse}',
    BROWSE: 'browse',
    DROP_FILES_HERE_TO_ADD: 'Drop file here to upload',
    NAVIGATION_ELEMENT: 'Navigation element',
    LINK_NAME: 'Link name',
    GROUP_NAME: 'Group name',
    APP_HOMEPAGE: 'App homepage',
    CARD_LINK: 'Card link',
    BOARD_LINK: 'Board link',
    EXTERNAL_LINK: 'External link',
    ENTER_LINK: 'Enter link',
    ENTER_LINK_NAME: 'Enter link name',
    SELECT_BOARD: 'Select board',
    SELECT_CARD: 'Select card',
    SELECT_CARD_VIEW: 'Select card view',
    WAY_TO_DISPLAY: 'Display format',
    APP_VIEW: 'App view',
    FULL_SCREEN_VIEW: 'Full-screen view',
    LINK_TO: 'Link to',
    HOMEPAGE: 'Homepage',
    HOMEPAGE_LINK: 'Homepage link',
    BROKEN_LINKS_INSIDE: 'Broken links inside',
    ADD_TO_GROUP: 'Add to group',
    MAKE_GROUP: 'Make a group',
    CONTENT_SETTINGS: 'Content',
    APP_STORE_SETTINGS: 'AppStore',
    TYPE: 'Type',
    INDUSTRY: 'Industry',
    CHOOSE_INDUSTRY: 'Choose industry',
    CATEGORY: 'Category',
    CHOOSE_CATEGORY: 'Select one or more categories',
    CHOOSE_COLLECTION: 'Select one or more collections',
    PREMIUM_APP: 'Premium App',
    UNPUBLISHED: 'Unpublished',
    PUBLISHED_VERSION: 'Published version {version}',
    COPIES_OF_APP_ALLOWED_TO_BE_EDITED:
      'Copies of this app can be edited and customized after being added to user accounts.',
    PURCHASE: 'Purchase',
    ENABLE_PURCHASE: 'Enable Purchase',
    STRIPE_PRICE_ID: 'API ID',
    STRIPE_PRICE_ID_PLACEHOLDER: 'e.g. price_1Jf15SKfDvVaHLH9pSoHrtK6',
    STRIPE_ID_TOOLTIP: 'What and where/how to get it in stripe',
    STRIPE_PRICE: 'Price',
    STRIPE_PAYMENT_OPTION: 'Billing Period',
    STRIPE_PAYMENT_OPTION_TOOLTIP: 'e.g. per month, annual',
    STRIPE_PAYMENT_DEFAULT: 'Default',
    STRIPE_PAYMENT_DEFAULT_TOOLTIP: 'Choose default for user',
    STRIPE_ADD_PRICE: 'Add another price',
    TEMPLATES_INSIDE: 'Search tags',
    TRIAL_PERIOD: 'Trial period',
    PROMOTION_CODE: 'Promotion code',
    TAGS: 'Tags',
    NEW_TEMPLATE_TAG: 'Enter a keyword or keyword phrase and tap enter',
    CREATE_TAG_PLACEHOLDER: 'Enter keyword or keyword phrase',
    CREATE_TAG_OPTION: 'Create',
    APP_META_AUTHOR_NAME_PLACEHOLDER: 'Company or Individual name',
    APP_META_AUTHOR_EMAIL_PLACEHOLDER: 'Contact email',
    LINK_TO_VIDEO: 'Link to video',
    VIDEO_LINK_TOOLTIP:
      'Enter YouTube or Vimeo link for optional app overview, instructions, or other information',
    APP_STORE_THUMBNAIL: 'AppStore thumbnail',
    N_UPLOADED: '{number} uploaded',
    SNAPSHOT: 'Snapshot',
    NO_UPLOADED_IMAGES_YET: 'No current uploads ',
    DROP_IMAGES_HERE: 'Upload image (png, jpeg) via drag and drop',
    APP_CONTENT: 'App content',
    APP_SNAPSHOT_HINT: 'Snapshot image of selected card will display on app preview page',
    PUBLISH_V: 'Publish V.{version}',
    UNPUBLISH: 'Unpublish',
    PUBLISH_ERROR_MESSAGE:
      'You have already published the same version. Please provide a unique value in Version field',
    PUBLISH_APP_NAME_ERROR_MESSAGE: 'Please provide a valid App name',
    APP_DESCRIPTION_PLACEHOLDER: 'Enter description of app',
    INFO: 'Info',
    WHATS_NEW: "What's new",
    CURRENT_UPDATE_VERSION: 'Version info',
    VERSION: 'Version',
    VERSION_WHATSNEW_PLACEHOLDER: 'Enter description of this update',
    VERSION_HISTORY: 'Version history',
    V_NUMBER: 'V.{version}',
    VERSION_NUMBER: 'Version {version}',
    PREVIOUS_VERSION: 'Previous version',
    NO_VERSIONS_YET: 'No version history',
    NO_VERSIONS_YET_DESC: 'No versions exist of this app',
    SNAPSHOT_WAS_DELETED: 'Snapshot was deleted',
    FAILED_TO_LOAD: 'Failed to load',
    PLACEMENT_ORDER: 'Listing Position',
    HOMEPAGE_SWIMLANE: 'Home Page',
    APPSTORE_CATEGORIES: 'App Store Categories',
    LEFT_SEQUENTIAL: 'Display order (from left)',
    SEQUENTIAL_TEXT: 'Display order (from top left column)',
    CONFIRM_PUBLISHING: 'Confirm Publishing',
    CREATE_NEW_LINK: 'Create new link',
    FIND_LICENSE: 'Find license by ID or note',
    NO_LICENSES: 'No existing licenses',
    NO_LICENSES_FOUND: 'No licenses found',
    CREATE_FIRST_LICENSE: 'Please create first license link',
    MODIFY_SEARCH_AND_TRY_AGAIN: 'Modify your search and try again',
    OPEN_NOTES: 'Open notes',
    CREATE_LINK: 'Create link',
    TERMINATE_LINK: 'Terminate link',
    PUBLISH_APP_TO_VIEW_LICENSES: 'Publish this app to view licenses list',
    LINK_CREATED_AND_COPIED: 'Link created and copied!',
    CONFIRM_ENTITLEMENT_TERMINATION_TITLE: 'Are you sure you want to terminate this license?',
    CONFIRM_ENTITLEMENT_TERMINATION_TEXT: 'This link will no longer be available',
    TYPE_A_NOTE_HERE: 'Type a note here...',

    // detailed view section
    FAILED_TO_FETCH_WIDGETS: 'Failed to get widgets',
    FAILED_TO_FETCH_WIDGETS_DESC:
      'Failed to get widgets for this section. We try again in a few seconds...',

    // variables list
    FIND_VARIABLE: 'Find variable',
    V_ATTRIBUTE: 'V=Attribute',
    CREATED_BY: 'Created by',
    CREATION_DATE: 'Creation date',
    VARIABLE: 'Variable',
    VARIABLES: 'Variables',
    NEW: 'New',
    DUPLICATE: 'Duplicate',
    CANT_DELETE_VARIABLE_OWN_BY_ANOTHER_USER: "You can't delete variables created by other users",
    CANT_EDIT_VARIABLE_OWN_BY_ANOTHER_USER: "You can't edit variables created by other users",

    // variables configuration
    VARIABLE_CONFIGURATION: 'Variable configuration',
    VARIABLE_NAME: 'Variable name',
    DEFINE_FROM: 'Define from',
    CARD_FROM_BOARD: 'Card from board',
    WIDGET_FROM_BOARD: 'Widget from board',
    CARD_BOARD_CURRENT_TOOLTIP:
      'Current allocation of card to which will be connected filter with this variable',
    WIDGET_BOARD_CURRENT_TOOLTIP:
      'Current allocation of widget to which will be connected filter with this variable',
    FROM_CARD: 'From card',
    CARD_CURRENT_TOOLTIP: 'Current allocation of connected to filter object',
    WITH_NAME: 'With name',
    WIDGET_ATTRIBUTE: 'Widget attribute',
    CARD_ATTRIBUTE: 'Card attribute',
    CARD_CREATED_DATE: 'Card Created Date',
    CARD_UPDATED_DATE: 'Card Updated Date',
    WIDGET_CREATED_DATE: 'Widget Created Date',
    WIDGET_UPDATED_DATE: 'Widget Updated Date',
    CARD_OWNERS: 'Card Owners',
    WITH_NAME_IS_EQUAL_TO: 'With name is equal to',
    CURRENT: 'Current',
    GET_ATTRIBUTE: 'Get attribute',
    PLEASE_PROVIDE_A_UNIQUE_NAME: 'Please provide a unique name',
    CLEAR: 'Clear',
    OBJECTS: 'Objects',
    START_TO_TYPE_TAG_NAME: 'Start to type tag name',
    VARIABLE_TAGS: 'Variable tags',
    VARIABLE_IS: 'V=',
    VARIABLE_ATTRIBUTE: 'V=Attribute',
    VARIABLES_LIMIT: 'You have reached the max variables limit',

    // delete variable
    DELETE_VARIABLE_TOOLTIP:
      'Heads up! This variable is connected to the filters or widgets and can not be deleted',

    // pdf generation
    EXPORTING_CARD_IN_PDF: 'Exporting PDF',
    CARD_SCANNING: 'exporting',
    DOWNLOADING_PDF: 'downloading pdf',
    CANCEL_PDF: 'Cancel',

    // app store
    GO_TO_APP_STORE: 'go to app store',
    APP_STORE_SEARCH_PLACEHOLDER: 'Find apps, templates, and other cool stuff',
    APP_STORE_NO_SEARCH_RESULTS: 'No results found',
    APP_STORE_NO_APPS_FOUND: 'No apps found',
    APP_STORE_SOMETHING_WENT_WRONG: 'Oops!',
    APP_STORE_RELOAD_THE_PAGE: 'Please refresh page',
    FEATURED: 'Featured',
    ENTITLEMENT_CONSUMING_CANCEL: 'If you cancel, you can get app later.',

    // btn other modes
    BACK_MODE: 'Back Button mode',
    BACK_MODE_DESCRIPTION: 'Button returns user to previous page',
    INVITE_MODE: 'Invite mode',
    INVITE_MODE_DESCRIPTION: 'Button will open the invite people dialog box for the team.',

    // Upgrade subscription banners
    TEMPLATES_PRO: 'Templates Pro',
    PROCESS_PRO: 'Praxie Pro',
    UPGRADE_TO_TEMPLATES_PRO: 'Upgrade to Templates Pro',
    UPGRADE_TO_PROCESS_PRO: 'Upgrade to Process Pro',
    TEMPLATES_PRO_COLLECTION: 'Templates Pro Collection',
    APP_STORE_PREMIUM_APPS: 'Add Premium Apps',
    APP_STORE_DESCRIPTION: 'Get advanced solutions for specific business processes and functions',
    TEMPLATES_PRO_DESCRIPTION: 'Access to all PowerPoint & Excel files',
    TEMPLATES_PRO_DESCRIPTION_2: 'File templates',
    PROCESS_PRO_DESCRIPTION:
      'Access the full collection of interactive online apps, plus all PowerPoint & Excel files',
    PROCESS_PRO_DESCRIPTION_2: 'Interactive apps & file templates',
    FULLY_INTERACTIVE_ONLINE_APPS: 'fully interactive online apps',
    // Locked Workspace
    SUBSCRIPTION_EXPIRED: 'Heads up! Your subscription has expired.',
    PROLONG_SUBSCRIPTION: 'Renew subscription or enter new billing information if needed.',
    CONTACT_MANAGER_PROLONG_SUBSCRIPTION:
      'Please ask your admin to prolong subscription or attach new billing info to access your teams',
    GO_TO_BILLING: 'Billing',
    AVAILABLE_NOW: 'Available now',
    WORKSPACE_ENT_TEMPLATE_WELCOME_MODAL_TEXT: 'Access all PowerPoint & Excel files.',
    WORKSPACE_ENT_PRO_WELCOME_MODAL_TEXT: 'Access interactive apps and all file templates.',

    // Trial period Banner
    TRIAL_PERIOD_BANNER_TEXT: 'Trial period for {subscriptionName} ends ',
    TRIAL_PERIOD_TOOLTIP: 'Trial period ends {date}',
    ENDS_TODAY: 'Ends today',
    ENDS_IN_AT: 'Ends in {number} {unit} at {time}',

    // Board view paddings
    CONTENT: 'Content',
    WIDGET_PADDINGS: 'Widget paddings',
    CELL_PADDINGS: 'Cell paddings',
    RESET_TO_DEFAULT: 'Reset to default',
    PADDING_HINT: '{type} padding',

    // invitation restriction
    NO_INVITE_PERMISSIONS: 'Oops! You don’t have permissions to invite people to this team.',
    CONTACT_ADMIN_FOR_PERMISSIONS:
      'Contact your team Admin to get permissions or request they make the invitations directly.',

    COLLECTIONS: 'Collections',
    CATEGORIES: 'Categories',
    DOWNLOADING_TEMPLATE: 'Downloading Template',
    CLOSE_DOWNLOADING_TEMPLATE_WINDOW: 'Close window. Downloading will continue',
    TEMPLATE_DOWNLOADED: 'Downloaded',

    ORGANIZATION_COLLECTIONS_FETCH_ERROR:
      'An error occurred while fetching organization collections',
    STRIPE_BUY_ITEM_ERROR: 'An error occurred while requesting item purchase.',
    LOADING_APPS: 'Loading apps...',
    DISCOVER_ALL_APPS: 'Discover all apps',

    APP_ENTITLEMENT_DESCRIPTION: 'Add to your workspace and team to get started.',
    YOU_ARE_SUBSCRIBED_TO: 'You’re subscribed to {name}',

    DONT_SHOW_COACHMARK: 'Don’t show again',

    OTHER: 'Other',
    CREDENTIALS: 'Credentials',
    CREDENTIAL: 'Credential',
    NO_CURRENT_CREDENTIALS: 'No Current Credentials',
    NO_CREDENTIALS_HINT: 'Save credentials to connect them later to 3rd party Widgets.',
    AUTHENTICATION_TYPE: 'Authentication type',
    CREATE_NEW_CREDENTIALS: 'Create new credentials',
    EDIT_CREDENTIALS: 'Edit credentials',
    CREDENTIALS_NAME: 'Credentials name',
    CREDENTIALS_NAME_PLACEHOLDER: 'Type credentials name here',
    CREDENTIALS_USERNAME_PLACEHOLDER: 'e. g. username@mail.com',
    DELETE_CREDENTIALS: 'Delete credentials',
    DELETE_CONFIRMATION_TEXT: credentialName =>
      `Are you sure you want to delete “${credentialName}” credentials?`,
    CREDENTIAL_CONFIGURATION: 'Credential configuration',
    ASSOCIATED_WIDGETS: 'Associated widgets',
    CANT_DELETE_CREDENTIALS: 'Can’t delete credentials',
    CANT_DELETE_CREDENTIALS_DESCRIPTION:
      'These credentials are currently connected to widgets in the system and can’t be deleted',
    ASSOCIATED_WIDGETS_EMPTY_TITLE: 'No Connected Widgets',
    ASSOCIATED_WIDGETS_EMPTY_SUBTITLE: 'Connected widgets will display here',
    CONTACT: 'Contact',
    CUSTOMIZE_APPS: 'Customize apps for your company',
    DICTIONARIES: 'Lists',
    DICTIONARY: 'List',
    FIND_DICTIONARIES: 'Find lists',
    DICTIONARIES_EMPTY_TITLE: 'No Lists exist in this team',
    DICTIONARIES_EMPTY_SUBTITLE: 'Create list options',
    DICTIONARIES_EMPTY_SEARCH_TITLE: 'No Lists found',
    COPY_DICTIONARY: 'Copy list',
    NEW_DICTIONARY_NAME: 'New list name',
    DELETE_DICTIONARY: 'Delete list',
    DELETE_DICTIONARY_CONFIRMATION: 'Are you sure you want to delete “{dictionaryName}” list?',
    NEW_DICTIONARY: 'New list',
    DICTIONARY_CONFIGURATION: 'List Configuration',
    DICTIONARY_NAME_PLACEHOLDER: 'Enter list name here...',
    DICTIONARY_NAME: 'List name',
    DICTIONARY_HINT_ENABLED: 'Enable hint text',
    DICTIONARY_MENU_OPTIONS: 'Dropdown menu options',
    ADD_OPTION: 'Add option',
    HINT_OPTION: 'Hint text',
    OPTIONS_DUPLICATES: 'Options must be unique',
    DICTIONARY_NAMES_DUPLICATES: 'List name should be unique',
    DICTIONARY_NAME_RESTRICTION_MESSAGE: 'List name should contain max 350 symbols',
    DICTIONARY_QUERY_RESTRICTION_MESSAGE: 'Please select Query widget to retrieve options from',
    ADD_OPTION_RESTRICTION_MESSAGE: 'You can not add more than 300 options'
  }
})

// @ts-ignore
export default messages
