import classNames from 'classnames'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import { mapSelectedValuesFromLabel } from 'helpers/fields/selectHelpers'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import FormField from 'components/common/FormField'
import MultiCreatableSelect from 'components/fields/MultiCreatableSelect'
import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'
import 'scss/workflowBuilder/findBundleBlock/find-widgets-bundle-block.scss'

export const MAX_TOKENS_COUNT = 100

const FindWidgetsBundleBlock = props => {
  const {
    block: { id, input, meta, error },
    handleReset,
    loadSuggestion,
    isValidNewOption,
    handleInputChange,
    changeWidgetsList
  } = props

  const isSourceInputSelected = !!input[EFieldKeys.SOURCE_INPUT]
  const isMaxTokensCountReached = meta[EFieldKeys.NAMES].length >= MAX_TOKENS_COUNT

  return (
    <div className="find-widgets-bundle-blocks">
      <BlockInputContainer
        type={OBJECT_TYPES.CARD}
        blockId={id}
        value={input[EFieldKeys.SOURCE_INPUT]}
      >
        {({ selectedItem, items, searchItems }) => (
          <AnyLevelDropdown
            supportHistory
            title={messages.CARD_LEVEL_INPUT}
            placeholder={messages.CARD_LEVEL_INPUT_PLACEHOLDER}
            items={items}
            searchItems={searchItems}
            onSelect={handleInputChange.bind(null, EFieldKeys.SOURCE_INPUT)}
            onReset={handleReset.bind(null, EFieldKeys.SOURCE_INPUT)}
            value={selectedItem}
            type={OBJECT_TYPES.CARD}
            error={error[EFieldKeys.SOURCE_INPUT]}
          />
        )}
      </BlockInputContainer>
      <FormField
        label={messages.WIDGETS_FROM_CARD}
        id="widgets-names-list"
        error={error[EFieldKeys.NAMES]}
        hintText={isMaxTokensCountReached ? messages.MAX_WIDGET_NAMES_COUNT_REACHED : null}
      >
        <MultiCreatableSelect
          isValidNewOption={isValidNewOption}
          className={classNames('widgets-names-list-select', {
            'error-state': error[EFieldKeys.NAMES]
          })}
          size="large"
          placeholder={messages.WIDGETS_TOKENS_PLACEHOLDER}
          maxLength={30}
          values={mapSelectedValuesFromLabel(meta[EFieldKeys.NAMES])}
          loadSuggestion={loadSuggestion}
          isDisabled={!isSourceInputSelected}
          menuPortalEnabled
          onChange={changeWidgetsList}
        />
      </FormField>
    </div>
  )
}

export default FindWidgetsBundleBlock
