import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { getSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import {
  FIND_BOARD_OPTIONS,
  FIND_BOARD_OPTIONS_LIST
} from 'helpers/workflowFindBlock/workflowFindBloksHelpers'
import FormField from 'components/common/FormField'
import ReactSelect from 'components/fields/ReactSelect'
import 'scss/workflowBuilder/findBlocks/find-board-block.scss'

const FindBoardBlock = ({ block: { id, meta }, changeSelectOption, children }) => {
  const type = meta[EFieldKeys.TYPE]

  return (
    <div className="find-board-block">
      <FormField label={messages.FIND_BOARD_SELECT_LABEL} id={`${id}-way-of-find-board`}>
        <ReactSelect
          className="way-of-find"
          size="large"
          value={getSelectedOptionByKey(type, FIND_BOARD_OPTIONS)}
          options={FIND_BOARD_OPTIONS_LIST}
          menuPortalEnabled
          onChange={option => changeSelectOption(EFieldKeys.TYPE, option)}
        />
      </FormField>
      {children}
    </div>
  )
}

export default FindBoardBlock
