import { DEFAULT_CARD_HEIGHT } from 'helpers/builderHelpers'
import { DATA_SECTION_ATTR } from './cards.constants'

export const getCardSectionCount = (cardHeight: number) => {
  return cardHeight ? Math.floor(cardHeight / DEFAULT_CARD_HEIGHT) : 1
}

export const getCardAuthoring = () => document.querySelector('.card-authoring')
export const getCardScrollableArea = () => document.querySelector('.scrollable-card-area')

export const getWidgetSection = (widget: HTMLElement) => {
  return parseFloat(widget.getAttribute(DATA_SECTION_ATTR) || '0')
}
