import { requestWrapper, url } from 'helpers/fetchHelpers'
import type {
  ICredentialAssociatedWidgetsPayload,
  ICredentialData,
  ICredentialDataPayload,
  ICredentialsListPayload,
  IEditCredentialDataPayload,
  TAssociatedWidgetsResponse
} from '../components/organizationSettings/credentials/credentialSettings.types'
import { isCredentialsRequestError } from '../components/organizationSettings/credentials/credentialsSettings.helpers'

export const createCredential = async (payload: ICredentialDataPayload) => {
  try {
    await requestWrapper({
      rUrl: `${url}credential`,
      type: 'POST',
      payload
    })
    return await Promise.resolve()
  } catch (error) {
    if (isCredentialsRequestError(error) && error.errorCode === 405) {
      return Promise.reject(error)
    }
    console.error(error)
    return Promise.reject()
  }
}

export const getCredentials = async (
  payload?: ICredentialsListPayload
): Promise<{ rows: ICredentialData[]; pageCursor: string; count: number }> => {
  try {
    const { data } = (await requestWrapper({
      rUrl: `${url}credential/organization`,
      type: 'POST',
      payload
    })) as { data: { rows: ICredentialData[]; pageCursor: string; count: number } }
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}

export const editCredential = async (payload: IEditCredentialDataPayload) => {
  try {
    await requestWrapper({
      rUrl: `${url}credential`,
      type: 'PUT',
      payload
    })
    return await Promise.resolve()
  } catch (error: unknown) {
    if (isCredentialsRequestError(error) && error.errorCode === 405) {
      return Promise.reject(error)
    }
    console.error(error)
    return Promise.reject()
  }
}

export const deleteCredential = async (credentialId: string) => {
  try {
    await requestWrapper({
      rUrl: `${url}credential/${credentialId}`,
      type: 'DELETE'
    })
    return await Promise.resolve()
  } catch (e) {
    return Promise.reject(e)
  }
}

export const getCredentialAssociatedWidgets = async (
  credentialId: string,
  payload?: ICredentialAssociatedWidgetsPayload
): Promise<{ rows: Array<TAssociatedWidgetsResponse>; pageCursor: string; count: number }> => {
  try {
    const { data } = (await requestWrapper({
      rUrl: `${url}credential/${credentialId}/widgets`,
      type: 'POST',
      payload
    })) as { data: { rows: Array<TAssociatedWidgetsResponse>; pageCursor: string; count: number } }
    return data
  } catch (e) {
    return Promise.reject(e)
  }
}
