import { EHttpMethod } from 'constants/common'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { EWorkflowBlockTypes } from 'constants/workflowBuilder/blocksTypes'
import {
  BLOCK_MENU_GROUPS,
  OBJECT_TYPES,
  OUTPUT_NAMES,
  TRIGGER_EVENT_OUTPUT_DEFINITIONS,
  TRIGGER_EVENTS,
  TRIGGER_OBJECTS,
  WF_USERS
} from 'constants/workflows'
import { DEFAULT_INPUT_COUNT } from 'helpers/workflowAddCommentBlock/workflowAddCommentBlockHelpers'
import { generateBlockInputs } from 'helpers/workflowBuilder/inputOperations'
import { CARD_OWNER_SET } from 'helpers/workflowCardOwnerBlocks/workflowCardOwnerBlocksHelpers'
import {
  BOARD_INPUT_COUNT,
  PLAIN_STRING,
  PREDICATES_KEYS,
  RULES
} from 'helpers/workflowFindBlock/workflowFindBloksHelpers'
import { getNewSection } from 'helpers/workflowHideCardSectionBlock/workflowHideCardSectionBlockHelpers'
import { VERY_LEFT } from 'helpers/workflowMoveColumnBlock/moveColumnBlockHelpers'
import {
  AFTER_STRING,
  STRING_INPUT
} from 'helpers/workflowRenameBlocks/workflowRenameBlocksHelpers'
import { ONE_STAGE_LEFT } from 'helpers/workflowStageTracker/switchWidgetConfig'
import {
  BODY_INPUTS_COUNT,
  SUBJECT_INPUTS_COUNT,
  USER_EMAIL_INPUTS_COUNT
} from 'helpers/workflowSystemActions/sendNotificationBlock'
import { DEFAULT_WF_FINISH_BLOCK_VALUE } from 'components/workflowBuilder/finishBlocks/finishBlock.constants'
import { SEND_PROMPT_TO_AI_BLOCK_FILE_INPUTS_COUNT } from 'components/workflowBuilder/sendPromptToAIBlock/sendPromptToAIBlock.constants'
import {
  DEFAULT_REST_API_BLOCK_RETRIES,
  DEFAULT_REST_API_BLOCK_RETRY_TIMEOUT_FACTOR,
  DEFAULT_REST_API_BLOCK_TIMEOUT,
  REST_API_BLOCK_STRING_INPUTS_COUNT
} from 'components/workflowBuilder/systemActions/restApiClientBlock/restApiClientBlock.constants'
import { EAuthorizationType } from 'components/workflowBuilder/systemActions/restApiClientBlock/restApiClientBlock.types'
import { CONVERT_DATA_INPUT_COUNT } from 'components/workflowBuilder/widgetDataBlocks/convertWidgetData/convertWidgetData.constants'

/**
 *  hasOutputStopper - if set to true, tells WF mapper, that SOURCE_INPUT of this block
 *  is output stopper and it should be added to output stoppers map
 **/

export const BLOCKS_DEFINITION = {
  [EWorkflowBlockTypes.TRIGGER]: {
    id: '5ce9be6a-3291-421d-b266-7406639f3fec',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.TRIGGER,
    inputDefinition: {},
    input: {},
    output: TRIGGER_EVENT_OUTPUT_DEFINITIONS[TRIGGER_EVENTS.CLICK],
    name: messages.TRIGGER,
    group: null,
    meta: {
      [EFieldKeys.EVENT_TYPE]: TRIGGER_EVENTS.CLICK,
      [EFieldKeys.EVENT_OBJECT]: TRIGGER_OBJECTS.WIDGET,
      [EFieldKeys.FILTER_UUID]: null
    }
  },
  [EWorkflowBlockTypes.FINISH]: {
    id: 'ceab65ae-4f10-4757-8056-5eb5b627d751',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.FINISH,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: false
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.CARD_MODE]: {
        inputName: EFieldKeys.CARD_MODE,
        isStatic: true
      },
      [EFieldKeys.WEB_LINK]: {
        inputName: EFieldKeys.WEB_LINK,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    meta: {
      [EFieldKeys.TYPE]: DEFAULT_WF_FINISH_BLOCK_VALUE
    },
    output: [{ type: OBJECT_TYPES.CARD, outputName: OUTPUT_NAMES.OUT }],
    name: messages.FINISH_BLOCK,
    group: null
  },
  [EWorkflowBlockTypes.GET_BOARD]: {
    id: '8f862bae-a97a-4067-afd2-aeccfd4662ef',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.GET_BOARD,
    inputDefinition: {
      [EFieldKeys.ENTITY_INPUT]: {
        inputName: EFieldKeys.ENTITY_INPUT,
        isStatic: true
      }
    },
    input: {},
    output: [{ type: OBJECT_TYPES.BOARD, outputName: OUTPUT_NAMES.OUT }],
    name: 'Get Board',
    group: BLOCK_MENU_GROUPS.BOARD,
    meta: {
      [EFieldKeys.ENTITY_INPUT]: null
    }
  },
  [EWorkflowBlockTypes.GET_COLUMN]: {
    id: '9946d49b-83f9-4e98-b9fe-9166f352faed',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.GET_COLUMN,
    inputDefinition: {
      [EFieldKeys.ENTITY_INPUT]: {
        inputName: EFieldKeys.ENTITY_INPUT,
        isStatic: true
      }
    },
    input: {},
    output: [{ type: OBJECT_TYPES.COLUMN, outputName: OUTPUT_NAMES.OUT }],
    name: 'Get Column',
    group: BLOCK_MENU_GROUPS.COLUMN,
    meta: {
      [EFieldKeys.ENTITY_INPUT]: null
    }
  },
  [EWorkflowBlockTypes.MOVE_COLUMN]: {
    id: 'e06e9c2c-62f0-46b6-b729-a1262c713766',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.MOVE_COLUMN,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.COLUMN,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.RELATIVE_COLUMN_NUMBER]: {
        inputName: EFieldKeys.RELATIVE_COLUMN_NUMBER,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.COLUMN, outputName: OUTPUT_NAMES.OUT }],
    name: 'Move Column',
    group: BLOCK_MENU_GROUPS.COLUMN,
    meta: {
      [EFieldKeys.TYPE]: VERY_LEFT,
      [EFieldKeys.RELATIVE_COLUMN_NUMBER]: 1
    }
  },
  [EWorkflowBlockTypes.GET_CARD]: {
    id: '9d8c343a-bb8f-46bf-91d0-e6fdab568807',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.GET_CARD,
    inputDefinition: {
      [EFieldKeys.ENTITY_INPUT]: {
        inputName: EFieldKeys.ENTITY_INPUT,
        isStatic: true
      }
    },
    input: {},
    output: [{ type: OBJECT_TYPES.CARD, outputName: OUTPUT_NAMES.OUT }],
    name: 'Get Card',
    group: BLOCK_MENU_GROUPS.CARD,
    meta: {
      [EFieldKeys.ENTITY_INPUT]: null
    }
  },
  [EWorkflowBlockTypes.GET_WIDGET]: {
    id: '0f067a5b-8fbe-4ef6-96f6-8619c2b10cff',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.GET_WIDGET,
    inputDefinition: {
      [EFieldKeys.ENTITY_INPUT]: {
        inputName: EFieldKeys.ENTITY_INPUT,
        isStatic: true
      }
    },
    input: {},
    output: [{ type: OBJECT_TYPES.WIDGET, outputName: OUTPUT_NAMES.OUT }],
    name: 'Get Widget',
    group: BLOCK_MENU_GROUPS.WIDGET,
    meta: {
      [EFieldKeys.ENTITY_INPUT]: null
    }
  },
  [EWorkflowBlockTypes.GET_WIDGET_DATA]: {
    // need to define block id
    id: '0f067a5b-8fbe-4ef6-96f6-8619c2b10cffd',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.GET_WIDGET_DATA,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGET,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.ATTRIBUTE]: {
        inputName: EFieldKeys.ATTRIBUTE,
        isStatic: true
      },
      [EFieldKeys.PARAMS]: {
        inputName: EFieldKeys.PARAMS,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.WIDGET_DATA, outputName: OUTPUT_NAMES.OUT }],
    name: messages.GET_WIDGET_DATA,
    group: BLOCK_MENU_GROUPS.WIDGET,
    meta: {
      [EFieldKeys.ATTRIBUTE]: '',
      [EFieldKeys.PARAMS]: { x: 1, y: 1 }
    }
  },
  [EWorkflowBlockTypes.UPDATE_WIDGET_DATA]: {
    id: '1f067a5b-8fbe-4ef6-96f6-8619c2b10cff',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.UPDATE_WIDGET_DATA,
    inputDefinition: {
      [EFieldKeys.UBF_CELL]: {
        inputName: EFieldKeys.UBF_CELL,
        type: OBJECT_TYPES.WIDGET_DATA,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGET,
        canUpdateHistory: true
      },
      [EFieldKeys.PARAMS]: {
        inputName: EFieldKeys.PARAMS,
        isStatic: true
      }
    },
    input: {
      [EFieldKeys.UBF_CELL]: null,
      [EFieldKeys.SOURCE_INPUT]: null
    },
    output: [{ type: OBJECT_TYPES.WIDGET, outputName: OUTPUT_NAMES.OUT }],
    name: messages.UPDATE_WIDGET_DATA,
    group: BLOCK_MENU_GROUPS.WIDGET,
    meta: {
      [EFieldKeys.PARAMS]: { x: 1, y: 1 }
    }
  },
  [EWorkflowBlockTypes.CONVERT_WIDGET_DATA]: {
    id: '1f067a5b-8fbe-4ef6-96f6-8619c2b10c45',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.CONVERT_WIDGET_DATA,
    inputDefinition: {
      ...generateBlockInputs({
        prefix: EFieldKeys.STRING_INPUT,
        value: {
          inputName: EFieldKeys.STRING_INPUT,
          type: OBJECT_TYPES.STRING,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: CONVERT_DATA_INPUT_COUNT
      }),
      ...generateBlockInputs({
        prefix: EFieldKeys.UBF_CELL,
        value: {
          inputName: EFieldKeys.UBF_CELL,
          type: OBJECT_TYPES.WIDGET_DATA,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: CONVERT_DATA_INPUT_COUNT
      }),
      [EFieldKeys.PARAMS]: {
        // Added array of inputs
        inputName: EFieldKeys.PARAMS,
        isStatic: true
      },
      [EFieldKeys.FORMULA]: {
        // Entered formula
        inputName: EFieldKeys.FORMULA,
        isStatic: true
      },
      [EFieldKeys.DATA_TYPE]: {
        // output data type : string / widgetData
        inputName: EFieldKeys.DATA_TYPE,
        isStatic: true
      },
      [EFieldKeys.TYPE]: {
        // output format
        inputName: EFieldKeys.TYPE,
        isStatic: true
      }
    },
    input: {
      ...generateBlockInputs({
        prefix: EFieldKeys.STRING_INPUT,
        value: null,
        inputCount: CONVERT_DATA_INPUT_COUNT
      }),
      ...generateBlockInputs({
        prefix: EFieldKeys.UBF_CELL,
        value: null,
        inputCount: CONVERT_DATA_INPUT_COUNT
      })
    },
    output: [
      {
        type: OBJECT_TYPES.STRING,
        outputName: OUTPUT_NAMES.OUT,
        defaultPresentationName: messages.STRING_DATA_OUTPUT
      }
    ],
    name: 'Widget Data Conversion',
    group: BLOCK_MENU_GROUPS.WIDGET,
    meta: {
      [EFieldKeys.FORMULA]: '',
      [EFieldKeys.TYPE]: '',
      [EFieldKeys.DATA_TYPE]: OBJECT_TYPES.STRING,
      [EFieldKeys.PARAMS]: [null]
    }
  },
  [EWorkflowBlockTypes.FIND_BOARD]: {
    id: '59f0631b-701d-40c6-8533-1e310aa6f623',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.FIND_BOARD,
    inputDefinition: {
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.NAMES]: {
        // Text inputs
        inputName: EFieldKeys.NAMES,
        isStatic: true
      },
      [EFieldKeys.PREDICATE]: {
        inputName: EFieldKeys.PREDICATE,
        isStatic: true
      },
      [EFieldKeys.RULE]: {
        inputName: EFieldKeys.RULE,
        isStatic: true
      },
      ...generateBlockInputs({
        // Dynamic string inputs
        prefix: EFieldKeys.STRING_INPUT,
        value: {
          inputName: EFieldKeys.STRING_INPUT,
          type: OBJECT_TYPES.STRING,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: BOARD_INPUT_COUNT
      }),
      [EFieldKeys.COLUMN_NUMBER]: {
        // Number of added string inputs
        inputName: EFieldKeys.COLUMN_NUMBER,
        isStatic: true
      }
    },
    input: {
      ...generateBlockInputs({
        prefix: EFieldKeys.STRING_INPUT,
        value: null,
        inputCount: BOARD_INPUT_COUNT
      })
    },
    output: [{ type: OBJECT_TYPES.BOARD, outputName: OUTPUT_NAMES.OUT }],
    name: 'Find Board',
    group: BLOCK_MENU_GROUPS.BOARD,
    meta: {
      [EFieldKeys.NAMES]: [],
      [EFieldKeys.TYPE]: PLAIN_STRING,
      [EFieldKeys.RULE]: RULES.OR,
      [EFieldKeys.PREDICATE]: PREDICATES_KEYS.CONTAINS,
      [EFieldKeys.COLUMN_NUMBER]: 1
    }
  },
  [EWorkflowBlockTypes.FIND_CARD]: {
    id: 'b8b5ac82-3bf1-46a5-852d-d1f780457531',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.FIND_CARD,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.BOARD,
        canUpdateHistory: false,
        presetValue: true // to enable value preset after block creation
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.NAME]: {
        inputName: EFieldKeys.NAME,
        isStatic: true
      },
      [EFieldKeys.STRING_INPUT]: {
        inputName: EFieldKeys.STRING_INPUT,
        type: OBJECT_TYPES.STRING,
        canUpdateHistory: false
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.STRING_INPUT]: null },
    output: [{ type: OBJECT_TYPES.CARD, outputName: OUTPUT_NAMES.OUT }],
    name: 'Find Card',
    group: BLOCK_MENU_GROUPS.CARD,
    meta: {
      [EFieldKeys.TYPE]: PLAIN_STRING,
      [EFieldKeys.NAME]: ''
    }
  },
  [EWorkflowBlockTypes.FIND_WIDGET]: {
    id: '013e02b1-e028-4484-94fc-15ef817c1a58',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.FIND_WIDGET,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.NAME]: {
        inputName: EFieldKeys.NAME,
        isStatic: true
      },
      [EFieldKeys.STRING_INPUT]: {
        inputName: EFieldKeys.STRING_INPUT,
        type: OBJECT_TYPES.STRING,
        canUpdateHistory: false
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.STRING_INPUT]: null },
    output: [{ type: OBJECT_TYPES.WIDGET, outputName: OUTPUT_NAMES.OUT }],
    name: 'Find Widget',
    group: BLOCK_MENU_GROUPS.WIDGET,
    meta: {
      [EFieldKeys.TYPE]: PLAIN_STRING,
      [EFieldKeys.NAME]: ''
    }
  },
  [EWorkflowBlockTypes.FIND_WIDGETS_BUNDLE]: {
    id: 'e4338f19-9510-4cfb-a65c-907e370366ae',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.FIND_WIDGETS_BUNDLE,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.NAMES]: {
        inputName: EFieldKeys.NAMES,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.WIDGETS_BUNDLE, outputName: OUTPUT_NAMES.OUT }],
    name: 'Create Widget Group from Card',
    group: BLOCK_MENU_GROUPS.WIDGETS_BUNDLE,
    meta: {
      [EFieldKeys.NAMES]: []
    }
  },
  [EWorkflowBlockTypes.SELECT_WIDGETS_FROM_FILTER]: {
    id: '6653cc15-6962-45c4-a769-d4f1afc9e777',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.SELECT_WIDGETS_FROM_FILTER,
    inputDefinition: {
      [EFieldKeys.FILTER_UUID_SNAKE]: {
        inputName: EFieldKeys.FILTER_UUID_SNAKE,
        isStatic: true
      }
    },
    input: {},
    output: [{ type: OBJECT_TYPES.WIDGETS_BUNDLE, outputName: OUTPUT_NAMES.OUT }],
    name: 'Select Widget from Filter',
    group: BLOCK_MENU_GROUPS.WIDGETS_BUNDLE,
    meta: { [EFieldKeys.FILTER_UUID_SNAKE]: null }
  },
  [EWorkflowBlockTypes.FIND_CARD_BUNDLE_FROM_FILTER]: {
    id: '8491ecf1-cef7-40b0-8626-873979e72ec7',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.FIND_CARD_BUNDLE_FROM_FILTER,
    inputDefinition: {
      [EFieldKeys.FILTER_UUID_SNAKE]: {
        inputName: EFieldKeys.FILTER_UUID_SNAKE,
        isStatic: true
      }
    },
    input: {},
    output: [{ type: OBJECT_TYPES.CARD_BUNDLE, outputName: OUTPUT_NAMES.OUT }],
    name: 'Define Card Group from Filter',
    group: BLOCK_MENU_GROUPS.CARD_BUNDLE,
    meta: { [EFieldKeys.FILTER_UUID_SNAKE]: null }
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_BOARD]: {
    id: 'a89537af-73e8-44fe-a157-ab719487deef',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.DEFINE_STRING_FROM_BOARD,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.BOARD,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.ATTRIBUTE]: {
        inputName: EFieldKeys.ATTRIBUTE,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.STRING, outputName: OUTPUT_NAMES.OUT }],
    name: messages.DEFINE_STRING_FROM_BOARD,
    group: BLOCK_MENU_GROUPS.BOARD,
    meta: {
      [EFieldKeys.ATTRIBUTE]: ''
    }
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_CARD]: {
    id: '91fcad1f-33f1-45fc-8dd6-de9e900ef2ba',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.DEFINE_STRING_FROM_CARD,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.ATTRIBUTE]: {
        inputName: EFieldKeys.ATTRIBUTE,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.STRING, outputName: OUTPUT_NAMES.OUT }],
    name: messages.DEFINE_STRING_FROM_CARD,
    group: BLOCK_MENU_GROUPS.CARD,
    meta: {
      [EFieldKeys.ATTRIBUTE]: ''
    }
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_WIDGET]: {
    id: '079f2faa-1bf1-42c2-8d98-5bb8c43a58fb',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.DEFINE_STRING_FROM_WIDGET,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGET,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.ATTRIBUTE]: {
        inputName: EFieldKeys.ATTRIBUTE,
        isStatic: true
      },
      [EFieldKeys.PARAMS]: {
        inputName: EFieldKeys.PARAMS,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.STRING, outputName: OUTPUT_NAMES.OUT }],
    name: messages.DEFINE_STRING_FROM_WIDGET,
    group: BLOCK_MENU_GROUPS.WIDGET,
    meta: {
      [EFieldKeys.ATTRIBUTE]: '',
      [EFieldKeys.PARAMS]: { x: 1, y: 1 }
    }
  },
  [EWorkflowBlockTypes.DEFINE_FILE_FROM_WIDGET]: {
    id: '321b9e92-a727-4b76-89de-cca23bbe99c5',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.DEFINE_FILE_FROM_WIDGET,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGET,
        canUpdateHistory: false,
        presetValue: false
      }
    },
    input: {},
    output: [{ type: OBJECT_TYPES.FILE, outputName: OUTPUT_NAMES.OUT }],
    name: 'Define File from Widget',
    group: BLOCK_MENU_GROUPS.FILE,
    meta: { [EFieldKeys.SOURCE_INPUT]: null }
  },
  [EWorkflowBlockTypes.CONVERT_WIDGET_DATA_INTO_FILE]: {
    id: '030be138-90f0-4b02-a98c-f5dea897aec7',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.CONVERT_WIDGET_DATA_INTO_FILE,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGET,
        canUpdateHistory: false,
        presetValue: false
      }
    },
    input: {},
    output: [{ type: OBJECT_TYPES.FILE, outputName: OUTPUT_NAMES.OUT }],
    name: 'Convert Query Widget Data into File',
    group: BLOCK_MENU_GROUPS.FILE,
    meta: { [EFieldKeys.SOURCE_INPUT]: null }
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_JSON_PAYLOAD]: {
    id: '7d0ade41-f37a-478f-bf6a-808f184475fd',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.DEFINE_STRING_FROM_JSON_PAYLOAD,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.JSON,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.KEY]: {
        inputName: EFieldKeys.KEY,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.STRING, outputName: OUTPUT_NAMES.OUT }],
    name: 'Define String from JSON payload',
    group: BLOCK_MENU_GROUPS.JSON,
    meta: {
      [EFieldKeys.KEY]: ''
    }
  },
  [EWorkflowBlockTypes.SWITCH_STAGE_WIDGET]: {
    id: 'ab2ac757-cb4f-4dfe-88d9-a7ee064d3723',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.SWITCH_STAGE_WIDGET,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGET,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.STRING_INPUT]: {
        inputName: EFieldKeys.STRING_INPUT,
        type: OBJECT_TYPES.STRING,
        canUpdateHistory: false
      },
      [EFieldKeys.NAME]: {
        inputName: EFieldKeys.NAME,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.STRING_INPUT]: null },
    output: [{ type: OBJECT_TYPES.WIDGET, outputName: OUTPUT_NAMES.OUT }],
    name: messages.STAGE_TRACKER_MOVE_STAGE,
    group: BLOCK_MENU_GROUPS.WIDGET,
    meta: {
      [EFieldKeys.TYPE]: ONE_STAGE_LEFT,
      [EFieldKeys.NAME]: ''
    }
  },
  [EWorkflowBlockTypes.HIDE_WIDGET]: {
    id: '140c47b7-5591-469c-a7d5-bde339a8e743',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.HIDE_WIDGET,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGET,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.HIDE]: {
        inputName: EFieldKeys.HIDE,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.WIDGET, outputName: OUTPUT_NAMES.OUT }],
    name: 'Hide/unhide Widget',
    group: BLOCK_MENU_GROUPS.WIDGET,
    meta: {
      [EFieldKeys.HIDE]: true
    }
  },
  [EWorkflowBlockTypes.HIDE_WIDGETS_BUNDLE_COMPONENT]: {
    id: 'e2d4d4a5-fec1-4ef6-a402-37aecdd98a6f',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.HIDE_WIDGETS_BUNDLE_COMPONENT,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGETS_BUNDLE,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.FLAGGED]: {
        inputName: EFieldKeys.FLAGGED,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.WIDGETS_BUNDLE, outputName: OUTPUT_NAMES.OUT }],
    name: 'Hide/unhide Widget Group',
    group: BLOCK_MENU_GROUPS.WIDGETS_BUNDLE,
    meta: {
      [EFieldKeys.FLAGGED]: true
    }
  },
  [EWorkflowBlockTypes.LOCK_WIDGET]: {
    id: '9cbea0d5-796c-4078-a22c-dd492ce3669e',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.LOCK_WIDGET,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGET,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.FLAGGED]: {
        inputName: EFieldKeys.FLAGGED,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.WIDGET, outputName: OUTPUT_NAMES.OUT }],
    name: 'Lock Widget',
    group: BLOCK_MENU_GROUPS.WIDGET,
    meta: {
      [EFieldKeys.FLAGGED]: true
    }
  },
  [EWorkflowBlockTypes.HIDE_CARD_SECTION]: {
    id: 'bda9fc0a-0099-49e3-b808-a017278e27cd',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.HIDE_CARD_SECTION,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.SECTIONS]: {
        inputName: EFieldKeys.SECTIONS,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.CARD, outputName: OUTPUT_NAMES.OUT }],
    name: 'Hide/Unhide Card Section',
    group: BLOCK_MENU_GROUPS.CARD,
    meta: {
      [EFieldKeys.SECTIONS]: [getNewSection()]
    }
  },
  [EWorkflowBlockTypes.HIDE_CARD_ACTION]: {
    id: '1a393ded-4863-48cb-9154-83e41aea5a41',
    type: EWorkflowBlockTypes.HIDE_CARD_ACTION,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.ACTIONS]: {
        inputName: EFieldKeys.ACTIONS,
        isStatic: true
      },
      [EFieldKeys.HIDE]: {
        inputName: EFieldKeys.HIDE,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.CARD, outputName: OUTPUT_NAMES.OUT }],
    name: 'Hide/Unhide Card Action',
    group: BLOCK_MENU_GROUPS.CARD,
    meta: {
      [EFieldKeys.ACTIONS]: [], // Array of action names.
      [EFieldKeys.HIDE]: true
    },
    isCreateOutput: false
  },
  [EWorkflowBlockTypes.MOVE_CARD]: {
    id: '5c8d8a82-00d7-48de-8e3e-016e3ad6d78b',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.MOVE_CARD,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.STRING_INPUT]: {
        inputName: EFieldKeys.STRING_INPUT,
        type: OBJECT_TYPES.STRING,
        canUpdateHistory: false
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.COLUMN_NUMBER]: {
        inputName: EFieldKeys.COLUMN_NUMBER,
        isStatic: true
      },
      [EFieldKeys.RELATIVE_COLUMN_NUMBER]: {
        inputName: EFieldKeys.RELATIVE_COLUMN_NUMBER,
        isStatic: true
      },
      [EFieldKeys.NAME]: {
        inputName: EFieldKeys.NAME,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.STRING_INPUT]: null },
    output: [{ type: OBJECT_TYPES.CARD, outputName: OUTPUT_NAMES.OUT }],
    meta: {
      [EFieldKeys.TYPE]: STRING_INPUT,
      [EFieldKeys.COLUMN_NUMBER]: 1,
      [EFieldKeys.RELATIVE_COLUMN_NUMBER]: 1,
      [EFieldKeys.NAME]: ''
    },
    name: messages.MOVE_CARD_TO_COLUMN,
    group: BLOCK_MENU_GROUPS.CARD
  },
  [EWorkflowBlockTypes.MOVE_CARD_TO_BOARD]: {
    id: 'e06e9c2c-62f0-46b6-b729-a1262c713756',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.MOVE_CARD_TO_BOARD,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.BOARD_INPUT]: {
        inputName: EFieldKeys.BOARD_INPUT,
        type: OBJECT_TYPES.BOARD,
        isStatic: false,
        canUpdateHistory: false
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.BOARD_INPUT]: null },
    output: [{ type: OBJECT_TYPES.CARD, outputName: OUTPUT_NAMES.OUT }],
    name: 'Move Card to Board',
    group: BLOCK_MENU_GROUPS.CARD,
    meta: {}
  },
  [EWorkflowBlockTypes.COPY_BOARD]: {
    id: '8463f2bf-a3ec-41cc-9b93-0e835f703908',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.COPY_BOARD,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.BOARD,
        canUpdateHistory: false,
        presetValue: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.BOARD, outputName: OUTPUT_NAMES.OUT }],
    name: messages.COPY_BOARD,
    group: BLOCK_MENU_GROUPS.BOARD
  },
  [EWorkflowBlockTypes.COPY_CARD]: {
    id: 'eb85da57-1e97-4ee3-a94c-ea24a6f20f4a',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.COPY_CARD,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.BOARD_INPUT]: {
        inputName: EFieldKeys.BOARD_INPUT,
        type: OBJECT_TYPES.BOARD,
        canUpdateHistory: false
      },
      [EFieldKeys.IS_LINK]: {
        inputName: EFieldKeys.IS_LINK,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.BOARD_INPUT]: null },
    output: [{ type: OBJECT_TYPES.CARD, outputName: OUTPUT_NAMES.OUT }],
    name: messages.COPY_CARD,
    group: BLOCK_MENU_GROUPS.CARD,
    meta: {
      [EFieldKeys.IS_LINK]: false
    }
  },
  [EWorkflowBlockTypes.COPY_WIDGETS_BUNDLE]: {
    id: 'bc95381c-518b-4e43-ad28-bf3351ee33fb',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.COPY_WIDGETS_BUNDLE,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGETS_BUNDLE,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.CARD_INPUT]: {
        inputName: EFieldKeys.CARD_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: false
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.CARD_INPUT]: null },
    output: [{ type: OBJECT_TYPES.WIDGETS_BUNDLE, outputName: OUTPUT_NAMES.OUT }],
    name: 'Copy Widget Group',
    group: BLOCK_MENU_GROUPS.WIDGETS_BUNDLE,
    meta: {}
  },
  [EWorkflowBlockTypes.COPY_COLUMN]: {
    id: '4b900246-94c2-4c7d-b015-e24e04d89045',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.COPY_COLUMN,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.COLUMN,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.BOARD_INPUT]: {
        inputName: EFieldKeys.BOARD_INPUT,
        type: OBJECT_TYPES.BOARD,
        canUpdateHistory: false
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.BOARD_INPUT]: null },
    output: [{ type: OBJECT_TYPES.COLUMN, outputName: OUTPUT_NAMES.OUT }],
    name: messages.COPY_COLUMN,
    group: BLOCK_MENU_GROUPS.COLUMN,
    meta: {}
  },
  [EWorkflowBlockTypes.LOCK_WIDGETS_BUNDLE_COMPONENT]: {
    id: 'cf25e899-3399-417e-b059-ca6b3dee4502',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.LOCK_WIDGETS_BUNDLE_COMPONENT,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGETS_BUNDLE,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.FLAGGED]: {
        inputName: EFieldKeys.FLAGGED,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.WIDGETS_BUNDLE, outputName: OUTPUT_NAMES.OUT }],
    name: 'Lock Widget Group',
    group: BLOCK_MENU_GROUPS.WIDGETS_BUNDLE,
    meta: {
      [EFieldKeys.FLAGGED]: true
    }
  },
  [EWorkflowBlockTypes.RENAME_BOARD]: {
    id: '6dca4f62-84ad-41ef-800f-f36f185cebe1',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.RENAME_BOARD,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.BOARD,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.STRING_INPUT]: {
        inputName: EFieldKeys.STRING_INPUT,
        type: OBJECT_TYPES.STRING,
        canUpdateHistory: false
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.NAME]: {
        inputName: EFieldKeys.NAME,
        isStatic: true
      },
      [EFieldKeys.TEXT_POSITION]: {
        inputName: EFieldKeys.TEXT_POSITION,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.STRING_INPUT]: null },
    meta: {
      [EFieldKeys.TYPE]: STRING_INPUT,
      [EFieldKeys.NAME]: '',
      [EFieldKeys.TEXT_POSITION]: AFTER_STRING
    },
    output: [{ type: OBJECT_TYPES.BOARD, outputName: OUTPUT_NAMES.OUT }],
    name: messages.RENAME_BOARD_BLOCK,
    group: BLOCK_MENU_GROUPS.BOARD
  },
  [EWorkflowBlockTypes.CREATE_COLUMN]: {
    id: '940c2e0e-e49f-44b5-bd5e-df5127de683f',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.CREATE_COLUMN,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.BOARD,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.STRING_INPUT]: {
        inputName: EFieldKeys.STRING_INPUT,
        type: OBJECT_TYPES.STRING,
        canUpdateHistory: false,
        presetValue: false
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.NAME]: {
        inputName: EFieldKeys.NAME,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.STRING_INPUT]: null },
    meta: {
      [EFieldKeys.TYPE]: PLAIN_STRING,
      [EFieldKeys.NAME]: ''
    },
    output: [{ type: OBJECT_TYPES.COLUMN, outputName: OUTPUT_NAMES.OUT }],
    name: 'Create Column',
    group: BLOCK_MENU_GROUPS.COLUMN
  },
  [EWorkflowBlockTypes.RENAME_CARD]: {
    id: '5b19b03d-f389-476e-803f-da50e72dc657',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.RENAME_CARD,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.STRING_INPUT]: {
        inputName: EFieldKeys.STRING_INPUT,
        type: OBJECT_TYPES.STRING,
        canUpdateHistory: false
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.NAME]: {
        inputName: EFieldKeys.NAME,
        isStatic: true
      },
      [EFieldKeys.TEXT_POSITION]: {
        inputName: EFieldKeys.TEXT_POSITION,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.STRING_INPUT]: null },
    meta: {
      [EFieldKeys.TYPE]: STRING_INPUT,
      [EFieldKeys.NAME]: '',
      [EFieldKeys.TEXT_POSITION]: AFTER_STRING
    },
    output: [{ type: OBJECT_TYPES.CARD, outputName: OUTPUT_NAMES.OUT }],
    name: messages.RENAME_CARD_BLOCK,
    group: BLOCK_MENU_GROUPS.CARD
  },
  [EWorkflowBlockTypes.SET_CARD_OWNER]: {
    id: '5b19b03d-f389-476e-803f-da50e72dc658',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.SET_CARD_OWNER,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.USER_INPUT]: {
        inputName: EFieldKeys.USER_INPUT,
        type: OBJECT_TYPES.USER,
        canUpdateHistory: false
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.USER_INPUT]: null },
    meta: {
      [EFieldKeys.TYPE]: CARD_OWNER_SET
    },
    output: [{ type: OBJECT_TYPES.CARD, outputName: OUTPUT_NAMES.OUT }],
    name: messages.SET_CARD_OWNER_CARD_BLOCK,
    group: BLOCK_MENU_GROUPS.CARD
  },
  [EWorkflowBlockTypes.DELETE_CARD]: {
    id: '347541db-cdf6-45d2-81b9-7a140844f6ee',
    isCreateOutput: false,
    hasOutputStopper: true,
    type: EWorkflowBlockTypes.DELETE_CARD,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: false,
        presetValue: false
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [],
    name: messages.DELETE_CARD_BLOCK,
    group: BLOCK_MENU_GROUPS.CARD
  },
  [EWorkflowBlockTypes.DELETE_CARD_BUNDLE]: {
    id: 'd739c07b-a8f0-48be-b796-62360598e81e',
    isCreateOutput: false,
    hasOutputStopper: true,
    type: EWorkflowBlockTypes.DELETE_CARD_BUNDLE,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD_BUNDLE,
        canUpdateHistory: false,
        presetValue: false
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [],
    name: messages.DELETE_CARD_BUNDLE_BLOCK,
    group: BLOCK_MENU_GROUPS.CARD_BUNDLE
  },
  [EWorkflowBlockTypes.DELETE_COLUMN]: {
    id: 'a96566fd-7f06-4774-9dce-ee0129aa58d6',
    isCreateOutput: false,
    hasOutputStopper: true,
    type: EWorkflowBlockTypes.DELETE_COLUMN,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.COLUMN,
        canUpdateHistory: false,
        presetValue: false
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [],
    name: messages.DELETE_COLUMN_BLOCK,
    group: BLOCK_MENU_GROUPS.COLUMN
  },
  [EWorkflowBlockTypes.DELETE_WIDGET]: {
    id: '3675edd0-48f8-424f-82c6-2f59e9f18cd4',
    isCreateOutput: false,
    hasOutputStopper: true,
    type: EWorkflowBlockTypes.DELETE_WIDGET,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGET,
        canUpdateHistory: false,
        presetValue: false
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [],
    name: messages.DELETE_WIDGET_BLOCK,
    group: BLOCK_MENU_GROUPS.WIDGET
  },
  [EWorkflowBlockTypes.DELETE_WIDGETS_BUNDLE]: {
    id: '0d0d5ecb-8562-4368-8955-9e4af8e2c540',
    isCreateOutput: false,
    hasOutputStopper: true,
    type: EWorkflowBlockTypes.DELETE_WIDGETS_BUNDLE,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGETS_BUNDLE,
        canUpdateHistory: false,
        presetValue: false
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [],
    name: messages.DELETE_WIDGETS_BUNDLE_BLOCK,
    group: BLOCK_MENU_GROUPS.WIDGETS_BUNDLE
  },
  [EWorkflowBlockTypes.ADD_CARD_COMMENT]: {
    id: 'a42e72ba-cbde-402a-ad02-2d9e764b0afd',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.ADD_CARD_COMMENT,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.CARD,
        canUpdateHistory: true,
        presetValue: true
      },
      ...generateBlockInputs({
        prefix: EFieldKeys.STRING_INPUT,
        value: {
          inputName: EFieldKeys.STRING_INPUT,
          type: OBJECT_TYPES.STRING,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: DEFAULT_INPUT_COUNT
      }),
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.TEXT]: {
        inputName: EFieldKeys.TEXT,
        isStatic: true
      }
    },
    input: {
      [EFieldKeys.SOURCE_INPUT]: null,
      ...generateBlockInputs({
        prefix: EFieldKeys.STRING_INPUT,
        value: null,
        inputCount: DEFAULT_INPUT_COUNT
      })
    },
    meta: {
      [EFieldKeys.TEXT]: [],
      [EFieldKeys.TYPE]: WF_USERS.CURRENT_USER
    },
    output: [{ type: OBJECT_TYPES.CARD, outputName: OUTPUT_NAMES.OUT }],
    name: messages.ADD_COMMENT,
    group: BLOCK_MENU_GROUPS.CARD
  },
  [EWorkflowBlockTypes.GET_USER]: {
    id: '9d8c343a-bb8f-46bf-91d0-e6fdab568808',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.GET_USER,
    inputDefinition: {
      [EFieldKeys.ENTITY_INPUT]: {
        inputName: EFieldKeys.ENTITY_INPUT,
        isStatic: true
      }
    },
    input: {},
    output: [{ type: OBJECT_TYPES.USER, outputName: OUTPUT_NAMES.OUT }],
    name: 'Get User',
    group: BLOCK_MENU_GROUPS.USER,
    meta: {
      [EFieldKeys.ENTITY_INPUT]: null
    }
  },
  [EWorkflowBlockTypes.FIND_COLUMN]: {
    id: 'b8b5ac82-3bf1-46a5-852d-d1f780457532',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.FIND_COLUMN,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.BOARD,
        canUpdateHistory: false,
        presetValue: true // to enable value preset after block creation
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.NAME]: {
        inputName: EFieldKeys.NAME,
        isStatic: true
      },
      [EFieldKeys.STRING_INPUT]: {
        inputName: EFieldKeys.STRING_INPUT,
        type: OBJECT_TYPES.STRING,
        canUpdateHistory: false
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.STRING_INPUT]: null },
    output: [{ type: OBJECT_TYPES.COLUMN, outputName: OUTPUT_NAMES.OUT }],
    name: 'Find Column',
    group: BLOCK_MENU_GROUPS.COLUMN,
    meta: {
      [EFieldKeys.TYPE]: PLAIN_STRING,
      [EFieldKeys.NAME]: ''
    }
  },
  [EWorkflowBlockTypes.RENAME_COLUMN]: {
    id: 'ac764277-0860-4336-a0bc-7b7a3ef52e98',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.RENAME_COLUMN,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.COLUMN,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.STRING_INPUT]: {
        inputName: EFieldKeys.STRING_INPUT,
        type: OBJECT_TYPES.STRING,
        canUpdateHistory: false
      },
      [EFieldKeys.TYPE]: {
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.NAME]: {
        inputName: EFieldKeys.NAME,
        isStatic: true
      },
      [EFieldKeys.TEXT_POSITION]: {
        inputName: EFieldKeys.TEXT_POSITION,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.STRING_INPUT]: null },
    meta: {
      [EFieldKeys.TYPE]: STRING_INPUT,
      [EFieldKeys.NAME]: '',
      [EFieldKeys.TEXT_POSITION]: AFTER_STRING
    },
    output: [{ type: OBJECT_TYPES.COLUMN, outputName: OUTPUT_NAMES.OUT }],
    name: 'Rename Column',
    group: BLOCK_MENU_GROUPS.COLUMN
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_USER]: {
    id: 'b8b5ac82-3bf1-46a5-852d-d1f780457532',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.DEFINE_STRING_FROM_USER,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.USER,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.ATTRIBUTE]: {
        inputName: EFieldKeys.ATTRIBUTE,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.STRING, outputName: OUTPUT_NAMES.OUT }],
    name: messages.DEFINE_STRING_FROM_USER,
    group: BLOCK_MENU_GROUPS.USER,
    meta: {
      [EFieldKeys.ATTRIBUTE]: ''
    }
  },
  [EWorkflowBlockTypes.FIND_USER]: {
    id: 'caa6190f-5b16-4a5c-afab-82754e6b7aab',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.FIND_USER,
    inputDefinition: {
      [EFieldKeys.STRING_INPUT]: {
        inputName: EFieldKeys.STRING_INPUT,
        type: OBJECT_TYPES.STRING,
        canUpdateHistory: false,
        presetValue: true
      }
    },
    input: { [EFieldKeys.STRING_INPUT]: null },
    output: [{ type: OBJECT_TYPES.USER, outputName: OUTPUT_NAMES.OUT }],
    name: messages.FIND_USER_BLOCK,
    group: BLOCK_MENU_GROUPS.USER,
    meta: {}
  },
  [EWorkflowBlockTypes.HIDE_COLUMN]: {
    id: '4b5c6545-ad76-4ef0-a684-d17c1823057b',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.HIDE_COLUMN,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.COLUMN,
        canUpdateHistory: true,
        presetValue: true
      },
      [EFieldKeys.FLAGGED]: {
        inputName: EFieldKeys.FLAGGED,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.COLUMN, outputName: OUTPUT_NAMES.OUT }],
    name: 'Hide/unhide Column',
    group: BLOCK_MENU_GROUPS.COLUMN,
    meta: {
      [EFieldKeys.FLAGGED]: true
    }
  },
  [EWorkflowBlockTypes.SEND_EMAIL]: {
    id: '4b5c6545-ad76-4ef0-a684-d17c1823057b',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.SEND_EMAIL,
    inputDefinition: {
      [EFieldKeys.NAMES]: {
        // Recipients
        inputName: EFieldKeys.NAMES,
        isStatic: true
      },
      ...generateBlockInputs({
        // Dynamic recipients
        prefix: EFieldKeys.NAME,
        value: {
          inputName: EFieldKeys.NAME,
          type: OBJECT_TYPES.STRING,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: USER_EMAIL_INPUTS_COUNT
      }),
      [EFieldKeys.COLUMN_NUMBER]: {
        // Number of added dynamic recipients
        inputName: EFieldKeys.COLUMN_NUMBER,
        isStatic: true
      },
      ...generateBlockInputs({
        // string inputs for subject section
        prefix: EFieldKeys.SUBJECT_INPUT,
        value: {
          inputName: EFieldKeys.SUBJECT_INPUT,
          type: OBJECT_TYPES.STRING,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: SUBJECT_INPUTS_COUNT
      }),
      ...generateBlockInputs({
        // string inputs for body section
        prefix: EFieldKeys.STRING_INPUT,
        value: {
          inputName: EFieldKeys.STRING_INPUT,
          type: OBJECT_TYPES.STRING,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: BODY_INPUTS_COUNT
      }),
      ...generateBlockInputs({
        // widget data inputs for subject section
        prefix: EFieldKeys.SUBJECT_UBF_CELL,
        value: {
          inputName: EFieldKeys.SUBJECT_UBF_CELL,
          type: OBJECT_TYPES.WIDGET_DATA,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: BODY_INPUTS_COUNT
      }),
      ...generateBlockInputs({
        // widget data inputs for body section
        prefix: EFieldKeys.UBF_CELL,
        value: {
          inputName: EFieldKeys.UBF_CELL,
          type: OBJECT_TYPES.WIDGET_DATA,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: BODY_INPUTS_COUNT
      }),
      [EFieldKeys.TYPE]: {
        // Sender
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.SUBJECT]: {
        // SUBJECT
        inputName: EFieldKeys.SUBJECT,
        isStatic: true
      },
      [EFieldKeys.TEXT]: {
        // Body
        inputName: EFieldKeys.TEXT,
        isStatic: true
      },
      [EFieldKeys.ROLES]: {
        // Roles
        inputName: EFieldKeys.ROLES,
        isStatic: true
      }
    },
    input: {
      ...generateBlockInputs({
        prefix: EFieldKeys.SUBJECT_INPUT,
        value: null,
        inputCount: SUBJECT_INPUTS_COUNT
      }),
      ...generateBlockInputs({
        prefix: EFieldKeys.STRING_INPUT,
        value: null,
        inputCount: BODY_INPUTS_COUNT
      }),
      ...generateBlockInputs({
        prefix: EFieldKeys.SUBJECT_UBF_CELL,
        value: null,
        inputCount: BODY_INPUTS_COUNT
      }),
      ...generateBlockInputs({
        prefix: EFieldKeys.UBF_CELL,
        value: null,
        inputCount: BODY_INPUTS_COUNT
      }),
      ...generateBlockInputs({
        prefix: EFieldKeys.NAME,
        value: null,
        inputCount: USER_EMAIL_INPUTS_COUNT
      })
    },
    output: [],
    name: messages.SEND_EMAIL_BLOCK,
    group: BLOCK_MENU_GROUPS.SYSTEM_ACTIONS,
    meta: {
      [EFieldKeys.NAMES]: [],
      [EFieldKeys.COLUMN_NUMBER]: 1,
      [EFieldKeys.SUBJECT]: [],
      [EFieldKeys.TEXT]: [],
      [EFieldKeys.TYPE]: WF_USERS.CURRENT_USER,
      [EFieldKeys.ROLES]: []
    }
  },
  [EWorkflowBlockTypes.PUBLISH_FEED_NOTIFICATION]: {
    id: '65ea1ed1-8442-43a2-96a2-cc6d2fcc7318',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.PUBLISH_FEED_NOTIFICATION,
    inputDefinition: {
      ...generateBlockInputs({
        // Dynamic users
        prefix: EFieldKeys.USER_INPUT,
        value: {
          inputName: EFieldKeys.USER_INPUT,
          type: OBJECT_TYPES.USER,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: USER_EMAIL_INPUTS_COUNT
      }),
      [EFieldKeys.COLUMN_NUMBER]: {
        // Number of added dynamic users
        inputName: EFieldKeys.COLUMN_NUMBER,
        isStatic: true
      },
      ...generateBlockInputs({
        // string inputs for body section
        prefix: EFieldKeys.STRING_INPUT,
        value: {
          inputName: EFieldKeys.STRING_INPUT,
          type: OBJECT_TYPES.STRING,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: BODY_INPUTS_COUNT
      }),
      ...generateBlockInputs({
        // widget data inputs for body section
        prefix: EFieldKeys.UBF_CELL,
        value: {
          inputName: EFieldKeys.UBF_CELL,
          type: OBJECT_TYPES.WIDGET_DATA,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: BODY_INPUTS_COUNT
      }),
      [EFieldKeys.TYPE]: {
        // Sender
        inputName: EFieldKeys.TYPE,
        isStatic: true
      },
      [EFieldKeys.TEXT]: {
        // Body
        inputName: EFieldKeys.TEXT,
        isStatic: true
      },
      [EFieldKeys.ROLES]: {
        // Roles
        inputName: EFieldKeys.ROLES,
        isStatic: true
      }
    },
    input: {
      ...generateBlockInputs({
        prefix: EFieldKeys.STRING_INPUT,
        value: null,
        inputCount: BODY_INPUTS_COUNT
      }),
      ...generateBlockInputs({
        prefix: EFieldKeys.UBF_CELL,
        value: null,
        inputCount: BODY_INPUTS_COUNT
      }),
      ...generateBlockInputs({
        prefix: EFieldKeys.USER_INPUT,
        value: null,
        inputCount: USER_EMAIL_INPUTS_COUNT
      })
    },
    output: [],
    name: messages.PUBLISH_FEED_NOTIFICATION_BLOCK,
    group: BLOCK_MENU_GROUPS.SYSTEM_ACTIONS,
    meta: {
      [EFieldKeys.TEXT]: [],
      [EFieldKeys.COLUMN_NUMBER]: 1,
      [EFieldKeys.TYPE]: WF_USERS.CURRENT_USER,
      [EFieldKeys.ROLES]: []
    }
  },
  [EWorkflowBlockTypes.REST_API_CLIENT]: {
    id: '94533cd1-a54b-4299-906d-adbcc689f9c8',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.REST_API_CLIENT,
    inputDefinition: {
      ...generateBlockInputs({
        prefix: EFieldKeys.STRING_INPUT,
        value: {
          inputName: EFieldKeys.STRING_INPUT,
          type: OBJECT_TYPES.STRING,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: REST_API_BLOCK_STRING_INPUTS_COUNT
      }),
      [EFieldKeys.METHOD_TYPE]: {
        inputName: EFieldKeys.METHOD_TYPE,
        isStatic: true
      },
      [EFieldKeys.URL_TARGET]: {
        inputName: EFieldKeys.URL_TARGET,
        isStatic: true
      },
      [EFieldKeys.AUTHORIZATION_TYPE]: {
        inputName: EFieldKeys.AUTHORIZATION_TYPE,
        isStatic: true
      },
      [EFieldKeys.CREDENTIAL_ID]: {
        inputName: EFieldKeys.CREDENTIAL_ID,
        isStatic: true
      },
      [EFieldKeys.HEADERS]: {
        inputName: EFieldKeys.HEADERS,
        isStatic: true
      },
      [EFieldKeys.BODY]: {
        inputName: EFieldKeys.BODY,
        isStatic: true
      },
      [EFieldKeys.IS_RETRY_ENABLED]: {
        inputName: EFieldKeys.IS_RETRY_ENABLED,
        isStatic: true
      },
      [EFieldKeys.TIMEOUT]: {
        inputName: EFieldKeys.TIMEOUT,
        isStatic: true
      },
      [EFieldKeys.RETRIES]: {
        inputName: EFieldKeys.RETRIES,
        isStatic: true
      },
      [EFieldKeys.RETRY_TIMEOUT_FACTOR]: {
        inputName: EFieldKeys.RETRY_TIMEOUT_FACTOR,
        isStatic: true
      },
      [EFieldKeys.DATA_TYPE]: {
        inputName: EFieldKeys.DATA_TYPE,
        isStatic: true
      }
    },
    input: {
      ...generateBlockInputs({
        prefix: EFieldKeys.STRING_INPUT,
        value: null,
        inputCount: REST_API_BLOCK_STRING_INPUTS_COUNT
      })
    },
    output: [{ type: OBJECT_TYPES.JSON, outputName: OUTPUT_NAMES.OUT }],
    name: 'REST API Client',
    group: BLOCK_MENU_GROUPS.SYSTEM_ACTIONS,
    meta: {
      [EFieldKeys.METHOD_TYPE]: EHttpMethod.GET,
      [EFieldKeys.URL_TARGET]: [],
      [EFieldKeys.AUTHORIZATION_TYPE]: EAuthorizationType.NOT_SPECIFIED,
      [EFieldKeys.CREDENTIAL_ID]: '',
      [EFieldKeys.HEADERS]: [],
      [EFieldKeys.BODY]: [],
      [EFieldKeys.IS_RETRY_ENABLED]: false,
      [EFieldKeys.TIMEOUT]: DEFAULT_REST_API_BLOCK_TIMEOUT,
      [EFieldKeys.RETRIES]: DEFAULT_REST_API_BLOCK_RETRIES,
      [EFieldKeys.RETRY_TIMEOUT_FACTOR]: DEFAULT_REST_API_BLOCK_RETRY_TIMEOUT_FACTOR,
      [EFieldKeys.DATA_TYPE]: OBJECT_TYPES.JSON
    }
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_COLUMN]: {
    id: 'e9eb4c93-f308-4265-ac30-d41705f114b6',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.DEFINE_STRING_FROM_COLUMN,
    inputDefinition: {
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.COLUMN,
        canUpdateHistory: false,
        presetValue: true
      },
      [EFieldKeys.ATTRIBUTE]: {
        inputName: EFieldKeys.ATTRIBUTE,
        isStatic: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null },
    output: [{ type: OBJECT_TYPES.STRING, outputName: OUTPUT_NAMES.OUT }],
    name: messages.DEFINE_STRING_FROM_COLUMN,
    group: BLOCK_MENU_GROUPS.COLUMN,
    meta: {
      [EFieldKeys.ATTRIBUTE]: ''
    }
  },
  [EWorkflowBlockTypes.SEND_PROMPT_TO_AI]: {
    id: '4b5c6545-ad76-4ef0-a684-d17c1823057b',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.SEND_PROMPT_TO_AI,
    inputDefinition: {
      [EFieldKeys.TEMPLATE_ID]: {
        // AI scenario
        inputName: EFieldKeys.TEMPLATE_ID,
        isStatic: true
      },
      [EFieldKeys.CREDENTIAL_IDS]: {
        inputName: EFieldKeys.CREDENTIAL_IDS,
        isStatic: true
      },
      [EFieldKeys.COLUMN_NUMBER]: {
        // Number of inputs in the scenario
        inputName: EFieldKeys.COLUMN_NUMBER,
        isStatic: true
      },
      [EFieldKeys.FILE_INPUT_NUMBER]: {
        // Number of inputs in the scenario
        inputName: EFieldKeys.FILE_INPUT_NUMBER,
        isStatic: true
      },
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGET,
        canUpdateHistory: true
      },
      ...generateBlockInputs({
        prefix: EFieldKeys.FILE_INPUT,
        value: {
          inputName: EFieldKeys.FILE_INPUT,
          type: OBJECT_TYPES.FILE,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: SEND_PROMPT_TO_AI_BLOCK_FILE_INPUTS_COUNT
      })
    },
    input: {
      [EFieldKeys.SOURCE_INPUT]: null,
      ...generateBlockInputs({
        prefix: EFieldKeys.FILE_INPUT,
        value: null,
        inputCount: SEND_PROMPT_TO_AI_BLOCK_FILE_INPUTS_COUNT
      })
    },
    output: [{ type: OBJECT_TYPES.WIDGET, outputName: OUTPUT_NAMES.OUT }],
    name: 'Update Widget with AI Data',
    group: BLOCK_MENU_GROUPS.WIDGET,
    meta: {
      [EFieldKeys.TEMPLATE_ID]: '',
      [EFieldKeys.TEMPLATE_OBJECT]: null,
      [EFieldKeys.COLUMN_NUMBER]: 0,
      [EFieldKeys.FILE_INPUT]: [null],
      [EFieldKeys.FILE_INPUT_NUMBER]: 0,
      [EFieldKeys.CREDENTIAL_IDS]: []
    }
  },
  [EWorkflowBlockTypes.DEFINE_JSON_WITH_AI]: {
    id: 'e0413826-7953-4f0e-89a5-c3cb24c666de',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.DEFINE_JSON_WITH_AI,
    inputDefinition: {
      [EFieldKeys.TEMPLATE_ID]: {
        inputName: EFieldKeys.TEMPLATE_ID,
        isStatic: true
      },
      [EFieldKeys.CREDENTIAL_IDS]: {
        inputName: EFieldKeys.CREDENTIAL_IDS,
        isStatic: true
      },
      [EFieldKeys.COLUMN_NUMBER]: {
        inputName: EFieldKeys.COLUMN_NUMBER,
        isStatic: true
      },
      [EFieldKeys.FILE_INPUT_NUMBER]: {
        inputName: EFieldKeys.FILE_INPUT_NUMBER,
        isStatic: true
      },
      ...generateBlockInputs({
        prefix: EFieldKeys.FILE_INPUT,
        value: {
          inputName: EFieldKeys.FILE_INPUT,
          type: OBJECT_TYPES.FILE,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: SEND_PROMPT_TO_AI_BLOCK_FILE_INPUTS_COUNT
      })
    },
    input: {
      ...generateBlockInputs({
        prefix: EFieldKeys.FILE_INPUT,
        value: null,
        inputCount: SEND_PROMPT_TO_AI_BLOCK_FILE_INPUTS_COUNT
      })
    },
    output: [{ type: OBJECT_TYPES.JSON, outputName: OUTPUT_NAMES.OUT }],
    name: 'Get JSON with AI',
    group: BLOCK_MENU_GROUPS.JSON,
    meta: {
      [EFieldKeys.TEMPLATE_ID]: '',
      [EFieldKeys.TEMPLATE_OBJECT]: null,
      [EFieldKeys.COLUMN_NUMBER]: 0,
      [EFieldKeys.FILE_INPUT]: [null],
      [EFieldKeys.FILE_INPUT_NUMBER]: 0,
      [EFieldKeys.CREDENTIAL_IDS]: []
    }
  },
  [EWorkflowBlockTypes.GENERATE_FILE_WITH_AI]: {
    id: 'a7b9d130-b3e8-4c95-a6f0-1f379d7e4e07',
    isCreateOutput: true,
    type: EWorkflowBlockTypes.GENERATE_FILE_WITH_AI,
    inputDefinition: {
      [EFieldKeys.TEMPLATE_ID]: {
        inputName: EFieldKeys.TEMPLATE_ID,
        isStatic: true
      },
      [EFieldKeys.CREDENTIAL_IDS]: {
        inputName: EFieldKeys.CREDENTIAL_IDS,
        isStatic: true
      },
      [EFieldKeys.COLUMN_NUMBER]: {
        inputName: EFieldKeys.COLUMN_NUMBER,
        isStatic: true
      },
      [EFieldKeys.FILE_INPUT_NUMBER]: {
        inputName: EFieldKeys.FILE_INPUT_NUMBER,
        isStatic: true
      },
      ...generateBlockInputs({
        prefix: EFieldKeys.FILE_INPUT,
        value: {
          inputName: EFieldKeys.FILE_INPUT,
          type: OBJECT_TYPES.FILE,
          canUpdateHistory: false,
          presetValue: false
        },
        inputCount: SEND_PROMPT_TO_AI_BLOCK_FILE_INPUTS_COUNT
      })
    },
    input: {
      ...generateBlockInputs({
        prefix: EFieldKeys.FILE_INPUT,
        value: null,
        inputCount: SEND_PROMPT_TO_AI_BLOCK_FILE_INPUTS_COUNT
      })
    },
    output: [{ type: OBJECT_TYPES.FILE, outputName: OUTPUT_NAMES.OUT }],
    name: 'Generate File with AI',
    group: BLOCK_MENU_GROUPS.FILE,
    meta: {
      [EFieldKeys.TEMPLATE_ID]: '',
      [EFieldKeys.TEMPLATE_OBJECT]: null,
      [EFieldKeys.COLUMN_NUMBER]: 0,
      [EFieldKeys.FILE_INPUT]: [null],
      [EFieldKeys.FILE_INPUT_NUMBER]: 0,
      [EFieldKeys.CREDENTIAL_IDS]: []
    }
  },
  [EWorkflowBlockTypes.UPDATE_WIDGET_WITH_FILE]: {
    id: '9570b8e5-0243-48d9-9238-ff9a4c4ddf3a',
    isCreateOutput: false,
    type: EWorkflowBlockTypes.UPDATE_WIDGET_WITH_FILE,
    inputDefinition: {
      [EFieldKeys.FILE_INPUT]: {
        inputName: EFieldKeys.FILE_INPUT,
        type: OBJECT_TYPES.FILE,
        canUpdateHistory: false
      },
      [EFieldKeys.SOURCE_INPUT]: {
        inputName: EFieldKeys.SOURCE_INPUT,
        type: OBJECT_TYPES.WIDGET,
        canUpdateHistory: true
      }
    },
    input: { [EFieldKeys.SOURCE_INPUT]: null, [EFieldKeys.FILE_INPUT]: null },
    output: [
      {
        type: OBJECT_TYPES.WIDGET,
        outputName: OUTPUT_NAMES.OUT
      }
    ],
    name: 'Update Widget with File',
    group: BLOCK_MENU_GROUPS.WIDGET,
    meta: {
      [EFieldKeys.FILE_INPUT]: null,
      [EFieldKeys.SOURCE_INPUT]: null
    }
  }
}

export const BLOCKS_LIST = Object.values(BLOCKS_DEFINITION)
