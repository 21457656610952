import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import {
  COLUMN_TYPES,
  COLUMN_TYPES_OPTIONS,
  PREV_COLUMN,
  NEXT_COLUMN,
  COLUMN_POSITION_FROM_LEFT,
  COLUMN_POSITION_FROM_RIGHT
} from 'helpers/workflowMoveColumnBlock/moveColumnBlockHelpers'
import { getSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import NumberFieldContainer from 'containers/workflowBuilder/common/NumberFieldContainer'
import FormField from 'components/common/FormField'
import { Tooltip } from 'components/common/tooltip/tooltip'
import ReactSelect from 'components/fields/ReactSelect'
import 'scss/workflowBuilder/moveColumnBlock/move-column-options.scss'

const getColumnNameFieldLabel = columnType => {
  const isColumnNumber =
    columnType === COLUMN_POSITION_FROM_LEFT || columnType === COLUMN_POSITION_FROM_RIGHT
  if (isColumnNumber) {
    return columnType === COLUMN_POSITION_FROM_LEFT
      ? messages.COLUMN_POSITION_FROM_LEFT_LABEL
      : messages.COLUMN_POSITION_FROM_RIGHT_LABEL
  }

  const isPrevColumn = columnType === PREV_COLUMN
  const tooltipText = isPrevColumn
    ? messages.MOVE_COLUMN_LEFT_TOOLTIP
    : messages.MOVE_COLUMN_RIGHT_TOOLTIP
  const labelText = isPrevColumn
    ? messages.MOVE_COLUMN_LEFT_LABEL
    : messages.MOVE_COLUMN_RIGHT_LABEL

  return (
    <span className="relative-column-number-label">
      {labelText}
      <Tooltip placement="top" id="relative-column-number-tooltip" title={tooltipText}>
        <i className="icon icon-hint" />
      </Tooltip>
    </span>
  )
}

const typesWithColumnNumber = [
  COLUMN_POSITION_FROM_LEFT,
  COLUMN_POSITION_FROM_RIGHT,
  PREV_COLUMN,
  NEXT_COLUMN
]

const MoveColumnOptions = ({
  isDisabled,
  block: { error },
  columnType,
  columnNumber,
  changeColumnType,
  changeColumnNumber
}) => (
  <div className="move-column">
    <FormField label={messages.MOVE_COLUMN_OPTIONS_TITLE} id="move-column-options">
      <ReactSelect
        className="move-column-options"
        size="large"
        value={getSelectedOptionByKey(columnType, COLUMN_TYPES)}
        options={COLUMN_TYPES_OPTIONS}
        isDisabled={isDisabled}
        menuPortalEnabled
        onChange={changeColumnType}
      />
    </FormField>
    {typesWithColumnNumber.includes(columnType) && (
      <FormField
        label={getColumnNameFieldLabel(columnType)}
        error={error[EFieldKeys.RELATIVE_COLUMN_NUMBER]}
        id="column-number"
      >
        <NumberFieldContainer
          value={columnNumber}
          disabled={isDisabled}
          onChange={changeColumnNumber}
        />
      </FormField>
    )}
  </div>
)

export default MoveColumnOptions
