import { motion } from 'framer-motion'
import './ai-progress-message.scss'

type TProps = {
  message: string
}

export const AIProgressMessage = ({ message }: TProps) => (
  <motion.div
    key={message}
    className="wf-ai-progress-message"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
  >
    {message}
  </motion.div>
)
