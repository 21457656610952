import React, { Component } from 'react'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'
import {
  RULES,
  BOARD_INPUT_COUNT
} from '../../../helpers/workflowFindBlock/workflowFindBloksHelpers'
import {
  getFieldNameIndex,
  getFieldNamesItems
} from '../../../helpers/workflowBuilder/inputOperations'
import FindBoardBlock from '../../../components/workflowBuilder/findBlocks/FindBoardBlock/FindBoardBlock'
import FindBoardOptions from '../../../components/workflowBuilder/findBlocks/FindBoardBlock/FindBoardOptions'

export default class FindBoardContainer extends Component {
  state = {
    customName: this.props.block.meta[EFieldKeys.NAMES][0] || ''
  }

  changeCustomName = e => {
    this.setState({ customName: e.target.value })
  }

  blurCustomName = e => {
    const { value } = e.target
    const newValue = value.trim() ? [value.trim()] : []
    this.updateBlockMeta(EFieldKeys.NAMES, newValue)
  }

  toggleRule = () => {
    const {
      block: { meta }
    } = this.props
    const activeRule = meta[EFieldKeys.RULE] === RULES.AND ? RULES.OR : RULES.AND
    this.updateBlockMeta(EFieldKeys.RULE, activeRule)
  }

  changeTextInputsList = values => {
    if (values.length > BOARD_INPUT_COUNT) {
      return
    }
    const newNames = values.map(item => item.value)
    this.updateBlockMeta([EFieldKeys.NAMES], newNames)
    this.setState({ customName: newNames[0] })
  }

  handleInputChange = (fieldName, item) => {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset = fieldName => {
    this.handleInputChange(fieldName, { id: null })
  }

  changeSelectOption = (propName, { value }) => {
    this.updateBlockMeta(propName, value)
  }

  getMetaValue = key => {
    const { meta } = { ...this.props.block }

    return meta[key]
  }

  onAddStringInput = () => {
    const stringInputCount = this.getMetaValue(EFieldKeys.COLUMN_NUMBER)

    if (stringInputCount < BOARD_INPUT_COUNT) {
      this.updateBlockMeta(EFieldKeys.COLUMN_NUMBER, stringInputCount + 1)
    }
  }

  onDeleteStringInput = field => {
    const stringInputCount = this.getMetaValue(EFieldKeys.COLUMN_NUMBER)
    const newStringInputCount = stringInputCount - 1

    // Reorder string inputs if not the last item has been removed
    this.updateBlockMeta(EFieldKeys.COLUMN_NUMBER, newStringInputCount)
    const feildIndex = getFieldNameIndex(field, EFieldKeys.STRING_INPUT) + 1
    if (feildIndex < stringInputCount) {
      this.collapseInputOrdering(field)
    } else {
      this.handleReset(field)
    }
  }

  collapseInputOrdering = field => {
    const {
      block: { inputDefinition, input }
    } = this.props
    const fieldName = EFieldKeys.STRING_INPUT
    const fieldsToReorder = getFieldNamesItems(fieldName, inputDefinition).filter(
      name => getFieldNameIndex(name, fieldName) >= getFieldNameIndex(field, fieldName)
    )

    fieldsToReorder.forEach((key, index) => {
      const nextKey = fieldsToReorder[index + 1]
      if (index === fieldsToReorder.length) {
        this.handleReset(key)
      } else if (input[key] !== input[nextKey]) {
        this.handleInputChange(key, { id: input[nextKey] })
      }
    })
  }

  updateBlockMeta = (key, fieldValue) => {
    const {
      updateWorkflowBlock,
      block: { id, meta }
    } = this.props

    if (meta[key] !== fieldValue) {
      updateWorkflowBlock({
        id,
        data: {
          meta: { ...meta, [key]: fieldValue }
        }
      })
    }
  }

  render() {
    const { block } = this.props
    const { customName } = this.state

    const meta = block.meta || {}

    return (
      <FindBoardBlock block={block} changeSelectOption={this.changeSelectOption}>
        <FindBoardOptions
          type={meta[EFieldKeys.TYPE]}
          block={block}
          customName={customName}
          changeCustomName={this.changeCustomName}
          blurCustomName={this.blurCustomName}
          handleInputChange={this.handleInputChange}
          changeSelectOption={this.changeSelectOption}
          handleReset={this.handleReset}
          toggleRule={this.toggleRule}
          changeTextInputsList={this.changeTextInputsList}
          onAddStringInput={this.onAddStringInput}
          onDeleteStringInput={this.onDeleteStringInput}
        />
      </FindBoardBlock>
    )
  }
}
