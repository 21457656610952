import { Route } from 'react-router-dom'
import {
  createReduxEnhancer,
  ErrorBoundary,
  init,
  reactRouterV5BrowserTracingIntegration,
  replayIntegration,
  withSentryRouting
} from '@sentry/react'
import { history } from 'services/common/history.service'

class _SentryService {
  constructor() {
    if (!this._shouldEnableSentry) return

    init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: this._release,
      environment: this._environment,
      maxBreadcrumbs: 50,
      integrations: [reactRouterV5BrowserTracingIntegration({ history }), replayIntegration()],
      tracesSampleRate: this._tracesSampleRate,
      replaysOnErrorSampleRate: this._replaysOnErrorSampleRate
    })
  }

  private _ENVIRONMENT_MAP = new Map([
    ['local', 'local'],
    ['test', 'test'],
    ['development', 'dev'],
    ['stage', 'stage'],
    ['production', 'prod']
  ]) as ReadonlyMap<string, string>

  private get _environment() {
    if (!import.meta.env.VITE_ENVIRONMENT) return undefined
    return this._ENVIRONMENT_MAP.get(import.meta.env.VITE_ENVIRONMENT)
  }

  private get _release() {
    return window.SENTRY_RELEASE?.id ?? 'undefined'
  }

  private get _tracesSampleRate() {
    // Capture 80% of performance traces on Prod. Disable on other environments.
    if (this._environment === this._ENVIRONMENT_MAP.get('production')) return 0.8
    return 0
  }

  private get _replaysOnErrorSampleRate() {
    // Capture 100% of replays on Prod. Disable on other environments.
    if (this._environment === this._ENVIRONMENT_MAP.get('production')) return 1.0
    return 0
  }

  private get _shouldEnableSentry() {
    const environment = this._environment
    if (!environment) return false

    return (
      environment !== this._ENVIRONMENT_MAP.get('local') &&
      environment !== this._ENVIRONMENT_MAP.get('test')
    )
  }

  Route = withSentryRouting(Route)
  ErrorBoundary = ErrorBoundary
  // Sentry does not have a proper type definition for createReduxEnhancer.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  reduxEnhancer = createReduxEnhancer()
}

export const SentryService = new _SentryService()
