import React from 'react'
import PlainInput from 'components/fields/PlainInput'
import FormField from '../../../common/FormField'
import BlockInputContainer from '../../../../containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from '../../../common/AnyLevelDropdown/index'
import FindBoardCombinedStringOption from './FindBoardCombinedStringOption'
import {
  PLAIN_STRING,
  STRING_INPUT,
  COMBINED_STRING
} from '../../../../helpers/workflowFindBlock/workflowFindBloksHelpers'
import messages from '../../../../constants/messages'
import { OBJECT_TYPES } from '../../../../constants/workflows'
import { EFieldKeys } from '../../../../constants/workflowBuilder/blocksFieldsKeys'
import '../../../../scss/workflowBuilder/findBlocks/find-board-options.scss'
import { getFieldNamesItems } from '../../../../helpers/workflowBuilder/inputOperations'

const FindBoardOptions = props => {
  const {
    type,
    block,
    block: { id, input, error, inputDefinition },
    customName,
    changeCustomName,
    blurCustomName,
    handleInputChange,
    changeSelectOption,
    handleReset,
    toggleRule,
    changeTextInputsList,
    onAddStringInput,
    onDeleteStringInput
  } = props
  const stringInputsNames = getFieldNamesItems(EFieldKeys.STRING_INPUT, inputDefinition)
  return (
    <div className="find-board-options">
      {type === PLAIN_STRING && (
        <FormField
          className="wrapper"
          label={messages.TEXT_INPUT}
          error={error[EFieldKeys.NAMES]}
          id={`${id}-find-plain-string`}
        >
          <PlainInput
            className="input-field"
            placeholder={messages.ENTER_BOARD_NAME}
            maxLength={200}
            value={customName}
            onChange={changeCustomName}
            onBlur={blurCustomName}
          />
        </FormField>
      )}
      {type === STRING_INPUT && (
        <div className="wrapper">
          <BlockInputContainer
            type={OBJECT_TYPES.STRING}
            blockId={id}
            value={input[stringInputsNames[0]]}
          >
            {({ selectedItem, items, searchItems }) => (
              <AnyLevelDropdown
                supportHistory
                title={messages.STRING_LEVEL_INPUT}
                placeholder={messages.STRING_LEVEL_INPUT_PLACEHOLDER}
                items={items}
                searchItems={searchItems}
                onSelect={handleInputChange.bind(null, stringInputsNames[0])}
                onReset={handleReset.bind(null, stringInputsNames[0])}
                value={selectedItem}
                type={OBJECT_TYPES.STRING}
                error={error[EFieldKeys.STRING_INPUT]}
              />
            )}
          </BlockInputContainer>
        </div>
      )}
      {type === COMBINED_STRING && (
        <FindBoardCombinedStringOption
          block={block}
          changeSelectOption={changeSelectOption}
          toggleRule={toggleRule}
          changeTextInputsList={changeTextInputsList}
          handleReset={handleReset}
          handleInputChange={handleInputChange}
          onAddStringInput={onAddStringInput}
          onDeleteStringInput={onDeleteStringInput}
        />
      )}
    </div>
  )
}

export default FindBoardOptions
