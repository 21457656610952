import { DateTime } from 'luxon'

export const formatUTCHoursToISO = ({ hour, minute }: { hour: string; minute: string }) => {
  const localTime = DateTime.fromObject(
    {
      hour: Number(hour),
      minute: Number(minute),
      second: 0,
      millisecond: 0
    },
    { zone: 'utc' }
  )

  return localTime.toFormat('HH:mm:ssZZ')
}

export const formatISOToUTCHours = (time: string) => {
  const utcTimeString = DateTime.fromISO(time).setZone('UTC').toFormat('HH:mm')

  return {
    hour: utcTimeString.split(':')[0] ?? '',
    minute: utcTimeString.split(':')[1] ?? ''
  }
}

export const getDefaultISOTime = () => {
  const localTime = DateTime.local().set({ hour: 9, minute: 0, second: 0, millisecond: 0 })

  return localTime.toFormat('HH:mm:ssZZ')
}
