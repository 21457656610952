import { Button } from '@praxie/shared'
import { motion } from 'framer-motion'
import CloseIcon from 'assets/images/icons/cross.svg?react'
import PlusIcon from 'assets/images/icons/plus-rounded.svg?react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import { Tooltip } from 'components/common/tooltip/tooltip'
import { DropdownInput } from 'components/workflowBuilder/common/dropdownInput/dropdownInput'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import { SEND_PROMPT_TO_AI_BLOCK_FILE_INPUTS_COUNT } from '../../sendPromptToAIBlock.constants'
import './send-prompt-to-ai-block-file-inputs.scss'

type TProps = {
  block: IWorkflowBlock
  handleFieldSelect: (fieldName: string, item?: Partial<IWorkflowDropdownValue> | null) => void
  handleFileInputAdd: () => void
  handleFileInputDelete: (index: number) => void
  handleFileInputChange: (index: number, item: Partial<IWorkflowDropdownValue>) => void
}

export const SendPromptToAIBlockFileInputs = ({
  block,
  handleFieldSelect,
  handleFileInputAdd,
  handleFileInputDelete,
  handleFileInputChange
}: TProps) => {
  const fileInputs = block.meta[EFieldKeys.FILE_INPUT] as string[]
  const isAddFileButtonDisabled = fileInputs.length >= SEND_PROMPT_TO_AI_BLOCK_FILE_INPUTS_COUNT
  const isEmptyState = fileInputs.length === 0

  return (
    <div className="workflow-send-prompt-to-ai-file-inputs">
      <span className="title">File Inputs</span>
      {isEmptyState ? (
        <motion.div className="empty-state" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <span className="empty-state-message">No inputs yet</span>
          <Button
            appearance="secondary3"
            disabled={isAddFileButtonDisabled}
            onClick={handleFileInputAdd}
          >
            <PlusIcon />
            Add input
          </Button>
        </motion.div>
      ) : (
        <>
          {fileInputs.map((fieldName, index) => (
            <motion.div
              key={`file-input-${String(index)}`}
              className="file-input"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <DropdownInput
                block={block}
                type={OBJECT_TYPES.FILE}
                fieldName={fieldName}
                placeholder="Select file output from previous blocks"
                onSelect={item => handleFileInputChange(index, item)}
                onReset={() => handleFieldSelect(fieldName, null)}
              />
              <Button
                appearance="secondary3"
                className="delete-input-btn"
                onClick={() => handleFileInputDelete(index)}
              >
                <CloseIcon title="Delete" className="delete-icon" />
              </Button>
            </motion.div>
          ))}
          <div className="file-actions">
            <Tooltip
              title={isAddFileButtonDisabled ? 'Max 5 inputs' : ''}
              placement="top"
              shouldWrapChildrenWithDiv
            >
              <Button
                appearance="secondary3"
                disabled={isAddFileButtonDisabled}
                onClick={handleFileInputAdd}
              >
                <PlusIcon />
                Add input
              </Button>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  )
}
