import { type ReactNode, useEffect, useState } from 'react'
import Collapse from '@mui/material/Collapse'
import classNames from 'classnames'
import ChevronIcon from 'assets/images/icons/chevron.svg?react'
import './expandable-section.scss'

type TProps = {
  className?: string
  title: ReactNode
  children: ReactNode
  expandArrowPlacement?: 'left' | 'right'
  expandArrowOrientation?: 'vertical' | 'horizontal'
  shouldExpandByDefault?: boolean
  shouldUnmountOnExit?: boolean
  isDisabled?: boolean
  isExpanded?: boolean | undefined
  onToggle?: (isExpanded: boolean) => void
  onClick?: () => void
}

export const ExpandableSection = ({
  className,
  title,
  children,
  expandArrowPlacement = 'right',
  expandArrowOrientation = 'vertical',
  shouldExpandByDefault = true,
  shouldUnmountOnExit = true,
  isDisabled = false,
  isExpanded: isExpandedProp,
  onToggle,
  onClick
}: TProps) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedProp ?? shouldExpandByDefault)

  useEffect(() => {
    if (isExpandedProp !== undefined) {
      setIsExpanded(isExpandedProp)
    }
  }, [isExpandedProp])

  const handleSectionToggle = () => {
    if (isDisabled) return

    onToggle?.(!isExpanded)
    setIsExpanded(prevState => !prevState)
  }

  const expandArrow = (
    <div className={classNames('arrow-icon', expandArrowPlacement, expandArrowOrientation)}>
      <ChevronIcon />
    </div>
  )

  return (
    <div
      className={classNames('praxie-expandable-section', className, isDisabled && 'disabled')}
      data-testid="praxie-expandable-section"
      onClick={onClick}
    >
      <div
        className={classNames('section-toggle', { open: isExpanded })}
        data-testid="section-toggle"
        onClick={handleSectionToggle}
      >
        {expandArrowPlacement === 'left' && expandArrow}
        <span className="section-title">{title}</span>
        {expandArrowPlacement === 'right' && expandArrow}
      </div>
      <Collapse
        in={isExpanded}
        timeout="auto"
        aria-disabled={isDisabled}
        unmountOnExit={shouldUnmountOnExit}
      >
        <div className="section-content" data-testid="section-content">
          {children}
        </div>
      </Collapse>
    </div>
  )
}
