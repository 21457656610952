import classNames from 'classnames'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import { getSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import {
  COMBINED_STRING,
  CUSTOM_TEXT_POSITION,
  CUSTOM_TEXT_POSITION_OPTIONS,
  PLAIN_STRING,
  RENAMING_OPTIONS,
  RENAMING_OPTIONS_LIST,
  STRING_INPUT
} from 'helpers/workflowRenameBlocks/workflowRenameBlocksHelpers'
import FormField from 'components/common/FormField'
import PlainInput from 'components/fields/PlainInput'
import ReactSelect from 'components/fields/ReactSelect'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import 'scss/workflowBuilder/renameBlocks/rename-options.scss'

const RenameOptions = ({
  type,
  inputType,
  customName,
  textPosition,
  handleReset,
  changeCustomName,
  blurCustomName,
  handleInputChange,
  changeSelectOption,
  block: { id, error, input }
}) => {
  const isDisabled = !input[EFieldKeys.SOURCE_INPUT]
  const isStringInputSelected = !!input[EFieldKeys.STRING_INPUT]

  const isStringInputShown = [STRING_INPUT, COMBINED_STRING].includes(type)
  const isCustomTextShown = [PLAIN_STRING, COMBINED_STRING].includes(type)
  const isCombinedString = type === COMBINED_STRING

  const isCustomTextDisabled = isDisabled || (isStringInputShown && !isStringInputSelected)

  return (
    <div className="rename-options-block">
      <FormField label={`Select how to rename ${inputType}`} id="way-of-renaming">
        <ReactSelect
          className="way-of-renaming"
          size="large"
          value={getSelectedOptionByKey(type, RENAMING_OPTIONS)}
          options={RENAMING_OPTIONS_LIST}
          isDisabled={isDisabled}
          menuPortalEnabled
          onChange={option => changeSelectOption(EFieldKeys.TYPE, option)}
        />
      </FormField>
      {isStringInputShown && (
        <BlockInputContainer
          type={OBJECT_TYPES.STRING}
          blockId={id}
          value={input[EFieldKeys.STRING_INPUT]}
        >
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              title={messages.STRING_LEVEL_INPUT}
              placeholder={messages.STRING_LEVEL_INPUT_PLACEHOLDER}
              items={items}
              searchItems={searchItems}
              value={selectedItem}
              type={OBJECT_TYPES.STRING}
              disabled={isDisabled}
              error={error[EFieldKeys.STRING_INPUT]}
              supportHistory
              onSelect={option => handleInputChange(EFieldKeys.STRING_INPUT, option)}
              onReset={() => handleReset(EFieldKeys.STRING_INPUT)}
            />
          )}
        </BlockInputContainer>
      )}
      {isCustomTextShown && (
        <FormField
          className={classNames({ combined: isCombinedString })}
          label={messages.CUSTOM_TEXT}
          error={error[EFieldKeys.NAME]}
          id="rename-plain-string"
        >
          <PlainInput
            className="input-field"
            placeholder={messages.CUSTOM_TEXT}
            maxLength={50}
            value={customName}
            disabled={isCustomTextDisabled}
            onChange={changeCustomName}
            onBlur={blurCustomName}
          />
          {isCombinedString && (
            <ReactSelect
              className="custom-text-position"
              size="large"
              value={getSelectedOptionByKey(textPosition, CUSTOM_TEXT_POSITION)}
              options={CUSTOM_TEXT_POSITION_OPTIONS}
              isDisabled={isCustomTextDisabled}
              menuPortalEnabled
              onChange={option => changeSelectOption(EFieldKeys.TEXT_POSITION, option)}
            />
          )}
        </FormField>
      )}
    </div>
  )
}

export default RenameOptions
