import {
  addHighlightedWidget,
  resetHighlightedWidgets,
  setHighlightedSection
} from 'actions/builderActions'
import { DEFAULT_CARD_HEIGHT, DEFAULT_CARD_WIDTH } from 'helpers/builderHelpers'
import { getWidgetSection } from 'features/cards/cards.helpers'
import {
  type TActionType,
  type TClosestSnapPoint,
  type TInteractEvent,
  type TSnapDirection,
  type TSnappingState,
  type TWidgetRect,
  ESnapPoint
} from './widgetSnapping.types'

class _WidgetSnappingService {
  private readonly _SNAP_THRESHOLD = 5
  private _snappingState: TSnappingState = this._initialSnappingState
  private _actionType: TActionType = 'move'
  private _cachedWidgetRects: TWidgetRect[] = []
  private _isMultiSelect = false

  private get _initialSnappingState() {
    return {
      initialCursorPosition: { x: null, y: null },
      snapDelta: { x: null, y: null },
      snappedPoints: {
        left: false,
        right: false,
        top: false,
        bottom: false,
        centerX: false,
        centerY: false
      }
    }
  }

  private _dispatchReduxAction = (action: unknown) => {
    const store = window.getStore()
    const dispatch = store.dispatch as (action: unknown) => void

    dispatch(action)
  }

  private _calculateMinDistances = (currentRect: TWidgetRect, targetRect: TWidgetRect) => {
    // Calculate minimum horizontal distance between all possible snap points.
    const minHorizontalDistance = Math.min(
      Math.abs(currentRect.left - targetRect.left),
      Math.abs(currentRect.left - targetRect.right),
      Math.abs(currentRect.right - targetRect.left),
      Math.abs(currentRect.right - targetRect.right),
      Math.abs(currentRect.centerX - targetRect.centerX)
    )

    // Calculate minimum vertical distance between all possible snap points.
    const minVerticalDistance = Math.min(
      Math.abs(currentRect.top - targetRect.top),
      Math.abs(currentRect.top - targetRect.bottom),
      Math.abs(currentRect.bottom - targetRect.top),
      Math.abs(currentRect.bottom - targetRect.bottom),
      Math.abs(currentRect.centerY - targetRect.centerY)
    )

    return { minHorizontalDistance, minVerticalDistance }
  }

  get actionType() {
    return this._actionType
  }

  get snappingState() {
    const isSnapped = Object.values(this._snappingState.snappedPoints).some(Boolean)

    return { snappingData: this._snappingState, isSnapped }
  }

  get cachedWidgetRects() {
    return this._cachedWidgetRects
  }

  initSnappingState = ({
    selectedWidgets,
    allWidgets,
    actionType,
    isMultiSelect = false
  }: {
    selectedWidgets: NodeList
    allWidgets: NodeList
    actionType: TActionType
    isMultiSelect?: boolean
  }) => {
    this._actionType = actionType
    this._isMultiSelect = isMultiSelect
    this._cachedWidgetRects = this.collectUnselectedWidgets({ selectedWidgets, allWidgets })
  }

  resetSnappingState = () => {
    this._snappingState = this._initialSnappingState
    this._cachedWidgetRects = []

    this.resetHighlightEffects()
  }

  getWidgetRect = (widget: HTMLElement) => {
    const x = parseFloat(widget.dataset.x || '0')
    const y = parseFloat(widget.dataset.y || '0')
    const section = parseFloat(widget.dataset.section || '0')

    const width = widget.offsetWidth
    const height = widget.offsetHeight

    // For multiselect mode: we use absolute coordinates to maintain
    // correct positioning of multiple widgets across different sections.
    const top = this._isMultiSelect ? y + section * DEFAULT_CARD_HEIGHT : y
    const bottom = top + height

    const centerX = x + width / 2
    const centerY = top + height / 2

    return {
      id: widget.id,
      section,
      centerX,
      centerY,
      left: x,
      right: x + width,
      top,
      bottom,
      height,
      width
    }
  }

  // Collects all widget rectangles from current section that are not selected.
  collectUnselectedWidgets = ({
    selectedWidgets,
    allWidgets
  }: {
    selectedWidgets: NodeList
    allWidgets: NodeList
  }) => {
    const selectedWidgetsArray = Array.from(selectedWidgets) as HTMLElement[]
    const allWidgetsArray = Array.from(allWidgets) as HTMLElement[]

    const selectedSections = new Set<number>()
    const selectedWidgetIds = new Set<string>()

    // Collect section numbers and widget IDs in a single loop
    selectedWidgetsArray.forEach(widget => {
      selectedSections.add(getWidgetSection(widget))
      selectedWidgetIds.add(widget.id)
    })

    return allWidgetsArray.reduce<TWidgetRect[]>((acc, widget) => {
      const isWidgetInSameSection =
        selectedSections.has(getWidgetSection(widget)) && !selectedWidgetIds.has(widget.id)

      if (isWidgetInSameSection) {
        acc.push(this.getWidgetRect(widget))
      }
      return acc
    }, [])
  }

  addWidgetHighlight = (widgetId: string) => {
    this._dispatchReduxAction(addHighlightedWidget(widgetId))
  }

  addSectionHighlight = (sectionNumber: number) => {
    this._dispatchReduxAction(setHighlightedSection(sectionNumber))
  }

  resetHighlightEffects = () => {
    this._dispatchReduxAction(resetHighlightedWidgets())
    this._dispatchReduxAction(setHighlightedSection(null))
  }

  // Checks if horizontal snapping is needed based on widget positions.
  checkHorizontalSnap = (targetRect: TWidgetRect, otherRect: TWidgetRect) => {
    return (
      Math.abs(targetRect.left - otherRect.left) < this._SNAP_THRESHOLD ||
      Math.abs(targetRect.right - otherRect.right) < this._SNAP_THRESHOLD ||
      Math.abs(targetRect.left - otherRect.right) < this._SNAP_THRESHOLD ||
      Math.abs(targetRect.right - otherRect.left) < this._SNAP_THRESHOLD
    )
  }

  // Checks if vertical snapping is needed based on widget positions.
  checkVerticalSnap = (targetRect: TWidgetRect, otherRect: TWidgetRect) => {
    return (
      Math.abs(targetRect.top - otherRect.top) < this._SNAP_THRESHOLD ||
      Math.abs(targetRect.bottom - otherRect.bottom) < this._SNAP_THRESHOLD ||
      Math.abs(targetRect.top - otherRect.bottom) < this._SNAP_THRESHOLD ||
      Math.abs(targetRect.bottom - otherRect.top) < this._SNAP_THRESHOLD
    )
  }

  // Checks if center snapping is required based on widget positions.
  checkCenterSnap = (targetRect: TWidgetRect, otherRect: TWidgetRect) => {
    return (
      Math.abs(targetRect.centerX - otherRect.centerX) < this._SNAP_THRESHOLD ||
      Math.abs(targetRect.centerY - otherRect.centerY) < this._SNAP_THRESHOLD
    )
  }

  // Determines if snapping is required based on widget positions.
  shouldSnapToWidget = (targetRect: TWidgetRect, otherRect: TWidgetRect) => {
    const shouldSnapToHorizontal = this.checkHorizontalSnap(targetRect, otherRect)
    const shouldSnapToVertical = this.checkVerticalSnap(targetRect, otherRect)
    const shouldSnapToCenter =
      this._actionType === 'move' ? this.checkCenterSnap(targetRect, otherRect) : false

    return shouldSnapToHorizontal || shouldSnapToVertical || shouldSnapToCenter
  }

  findClosestWidgets = (currentRect: TWidgetRect) => {
    let closestHorizontalWidget = null as TWidgetRect | null
    let closestVerticalWidget = null as TWidgetRect | null

    // Track minimum distances and gaps to find the closest widgets.
    let minHorizontalDistance = Infinity
    let minVerticalDistance = Infinity
    // These gaps are used as tiebreakers when distances are equal.
    let horizontalVerticalGap = Infinity
    let verticalHorizontalGap = Infinity

    this._cachedWidgetRects.forEach((widget: TWidgetRect) => {
      if (!this.shouldSnapToWidget(currentRect, widget)) return

      const { minHorizontalDistance: horizontalDistance, minVerticalDistance: verticalDistance } =
        this._calculateMinDistances(currentRect, widget)

      const isCloserHorizontally =
        horizontalDistance < minHorizontalDistance ||
        (horizontalDistance === minHorizontalDistance && verticalDistance < horizontalVerticalGap)

      if (isCloserHorizontally) {
        minHorizontalDistance = horizontalDistance
        horizontalVerticalGap = verticalDistance
        closestHorizontalWidget = widget
      }

      const isCloserVertically =
        verticalDistance < minVerticalDistance ||
        (verticalDistance === minVerticalDistance && horizontalDistance < verticalHorizontalGap)

      if (isCloserVertically) {
        minVerticalDistance = verticalDistance
        verticalHorizontalGap = horizontalDistance
        closestVerticalWidget = widget
      }
    })

    return { closestHorizontalWidget, closestVerticalWidget }
  }

  snapToCenter = (currentRect: TWidgetRect, centerX: number, centerY: number) => {
    const { snapDelta } = this._snappingState

    const currentSection = this._isMultiSelect
      ? Math.floor(currentRect.top / DEFAULT_CARD_HEIGHT)
      : currentRect.section

    const deltaX = Math.abs(currentRect.centerX - centerX)
    const deltaY = Math.abs(currentRect.centerY - centerY)

    const shouldSnapX = deltaX < this._SNAP_THRESHOLD
    const shouldSnapY = deltaY < this._SNAP_THRESHOLD

    if (shouldSnapX) {
      snapDelta.x ??= currentRect.centerX - centerX
      currentRect.left = centerX - currentRect.width / 2
    }

    if (shouldSnapY) {
      snapDelta.y ??= currentRect.centerY - centerY
      currentRect.top = centerY - currentRect.height / 2
    }

    if (shouldSnapX || shouldSnapY) {
      this.addSectionHighlight(currentSection)
    }
  }

  snapToClosestSectionCenter = (currentRect: TWidgetRect) => {
    const relativeSectionCenterY = DEFAULT_CARD_HEIGHT / 2

    const sectionCenterY = this._isMultiSelect
      ? relativeSectionCenterY +
        Math.floor(currentRect.top / DEFAULT_CARD_HEIGHT) * DEFAULT_CARD_HEIGHT
      : relativeSectionCenterY

    this.snapToCenter(currentRect, DEFAULT_CARD_WIDTH / 2, sectionCenterY)
  }

  // Checks and applies snapping to the closest widget points.
  snapToClosestWidgetPoints = (
    targetPoint: ESnapPoint,
    closestPoint: ESnapPoint,
    currentRect: TWidgetRect,
    closestWidget: TWidgetRect
  ) => {
    const { snappedPoints, snapDelta } = this._snappingState

    const currentWidgetPosition = currentRect[targetPoint]
    const closestWidgetPosition = closestWidget[closestPoint]

    const isHorizontal =
      targetPoint === ESnapPoint.left ||
      targetPoint === ESnapPoint.right ||
      targetPoint === ESnapPoint.centerX
    const offsetKey = isHorizontal ? 'x' : 'y'

    snapDelta[offsetKey] ??= currentWidgetPosition - closestWidgetPosition

    if (this._actionType === 'move') {
      if (targetPoint === ESnapPoint.right) {
        currentRect.left = closestWidgetPosition - currentRect.width
      }
      if (targetPoint === ESnapPoint.bottom) {
        currentRect.top = closestWidgetPosition - currentRect.height
      }
      if (targetPoint === ESnapPoint.centerX) {
        currentRect.left = closestWidgetPosition - currentRect.width / 2
      }
      if (targetPoint === ESnapPoint.centerY) {
        currentRect.top = closestWidgetPosition - currentRect.height / 2
      }
    }

    currentRect[targetPoint] = closestWidgetPosition
    snappedPoints[targetPoint] = true

    const widgetId = closestWidget.id.split('id_')[1]!

    this.addWidgetHighlight(widgetId)
  }

  private _getResizeSnapOptions(edges: TInteractEvent['edges']): Array<[ESnapPoint, ESnapPoint]> {
    const snapOptions: Array<[ESnapPoint, ESnapPoint]> = []

    // Horizontal options.
    if (edges?.right) {
      snapOptions.push([ESnapPoint.right, ESnapPoint.left], [ESnapPoint.right, ESnapPoint.right])
    }
    if (edges?.left) {
      snapOptions.push([ESnapPoint.left, ESnapPoint.left], [ESnapPoint.left, ESnapPoint.right])
    }

    // Vertical options.
    if (edges?.bottom) {
      snapOptions.push([ESnapPoint.bottom, ESnapPoint.top], [ESnapPoint.bottom, ESnapPoint.bottom])
    }
    if (edges?.top) {
      snapOptions.push([ESnapPoint.top, ESnapPoint.top], [ESnapPoint.top, ESnapPoint.bottom])
    }

    return snapOptions
  }

  private _getMoveSnapOptions(): Array<[ESnapPoint, ESnapPoint]> {
    return [
      // Horizontal options.
      [ESnapPoint.left, ESnapPoint.left],
      [ESnapPoint.left, ESnapPoint.right],
      [ESnapPoint.right, ESnapPoint.left],
      [ESnapPoint.right, ESnapPoint.right],
      // Vertical options.
      [ESnapPoint.top, ESnapPoint.top],
      [ESnapPoint.top, ESnapPoint.bottom],
      [ESnapPoint.bottom, ESnapPoint.top],
      [ESnapPoint.bottom, ESnapPoint.bottom],
      // Center options.
      [ESnapPoint.centerX, ESnapPoint.centerX],
      [ESnapPoint.centerY, ESnapPoint.centerY]
    ]
  }

  private _findClosestPoints(
    snapOptions: Array<[ESnapPoint, ESnapPoint]>,
    currentRect: TWidgetRect,
    closestWidget: TWidgetRect
  ) {
    const horizontalOptions = [ESnapPoint.left, ESnapPoint.right, ESnapPoint.centerX]
    const verticalOptions = [ESnapPoint.top, ESnapPoint.bottom, ESnapPoint.centerY]

    return snapOptions.reduce<{
      closestHorizontalPoint: TClosestSnapPoint
      closestVerticalPoint: TClosestSnapPoint
    }>(
      (acc, [targetPoint, closestPoint]) => {
        const distance = Math.abs(currentRect[targetPoint] - closestWidget[closestPoint])
        if (distance > this._SNAP_THRESHOLD) return acc

        const isHorizontalOption = horizontalOptions.includes(targetPoint)
        const isVerticalOption = verticalOptions.includes(targetPoint)

        const isClosestHorizontal =
          isHorizontalOption &&
          (!acc.closestHorizontalPoint || distance < acc.closestHorizontalPoint.distance)
        const isClosestVertical =
          isVerticalOption &&
          (!acc.closestVerticalPoint || distance < acc.closestVerticalPoint.distance)

        if (isClosestHorizontal) {
          acc.closestHorizontalPoint = { targetPoint, closestPoint, distance }
        }
        if (isClosestVertical) {
          acc.closestVerticalPoint = { targetPoint, closestPoint, distance }
        }

        return acc
      },
      { closestHorizontalPoint: null, closestVerticalPoint: null }
    )
  }

  handleSnap = ({
    currentRect,
    closestWidget,
    event,
    snapDirection
  }: {
    currentRect: TWidgetRect
    closestWidget: TWidgetRect
    event: TInteractEvent
    snapDirection: TSnapDirection
  }) => {
    const snapOptions =
      this._actionType === 'resize'
        ? this._getResizeSnapOptions(event.edges)
        : this._getMoveSnapOptions()

    const { closestHorizontalPoint, closestVerticalPoint } = this._findClosestPoints(
      snapOptions,
      currentRect,
      closestWidget
    )

    if (closestHorizontalPoint && snapDirection === 'horizontal') {
      this.snapToClosestWidgetPoints(
        closestHorizontalPoint.targetPoint,
        closestHorizontalPoint.closestPoint,
        currentRect,
        closestWidget
      )
    }

    if (closestVerticalPoint && snapDirection === 'vertical') {
      this.snapToClosestWidgetPoints(
        closestVerticalPoint.targetPoint,
        closestVerticalPoint.closestPoint,
        currentRect,
        closestWidget
      )
    }
  }

  handleUnsnap = (event: TInteractEvent, currentRect: TWidgetRect) => {
    const { snapDelta, snappedPoints, initialCursorPosition } = this._snappingState
    const { x: initialX = 0, y: initialY = 0 } = initialCursorPosition

    // Calculate how far the cursor has moved from the initial position.
    const deltaX = event.pageX - (initialX ?? 0) + (snapDelta.x ?? 0)
    const deltaY = event.pageY - (initialY ?? 0) + (snapDelta.y ?? 0)

    const shouldUnsnapX = snapDelta.x !== null && Math.abs(deltaX) > this._SNAP_THRESHOLD
    const shouldUnsnapY = snapDelta.y !== null && Math.abs(deltaY) > this._SNAP_THRESHOLD

    if (shouldUnsnapX) {
      const shouldUpdateLeftPosition =
        (this._actionType === 'resize' && event.edges?.left && snappedPoints.left) ||
        this._actionType === 'move'

      if (shouldUpdateLeftPosition) {
        currentRect.left += deltaX
      }

      currentRect.centerX += deltaX
      currentRect.right += deltaX
      snapDelta.x = null
    }

    if (shouldUnsnapY) {
      const shouldUpdateTopPosition =
        (this._actionType === 'resize' && event.edges?.top && snappedPoints.top) ||
        this._actionType === 'move'

      if (shouldUpdateTopPosition) {
        currentRect.top += deltaY
      }

      currentRect.centerY += deltaY
      currentRect.bottom += deltaY
      snapDelta.y = null
    }

    if (snapDelta.x === null) {
      initialCursorPosition.x = null
      snappedPoints.right = false
      snappedPoints.left = false
      snappedPoints.centerX = false
    }

    if (snapDelta.y === null) {
      initialCursorPosition.y = null
      snappedPoints.top = false
      snappedPoints.bottom = false
      snappedPoints.centerY = false
    }

    if (snapDelta.x === null && snapDelta.y === null) {
      this._snappingState = this._initialSnappingState
    }

    if (shouldUnsnapX || shouldUnsnapY) {
      this.resetHighlightEffects()
    }
  }

  // Updates the snapping state based on the current mouse position during the interaction.
  // If snapping is applied, it records the initial cursor position.
  // If unsnapping is applied, it resets the initial cursor position.
  updateSnappingState = (event: TInteractEvent) => {
    const { snapDelta, initialCursorPosition, snappedPoints } = this._snappingState

    const isSnappedVertically = snapDelta.x !== null
    const isSnappedHorizontally = snapDelta.y !== null

    if (!isSnappedVertically && !isSnappedHorizontally) {
      initialCursorPosition.x = null
      initialCursorPosition.y = null
    } else {
      const shouldSetInitialXForResize =
        this._actionType === 'resize' &&
        ((snappedPoints.left && event.edges?.right) || (snappedPoints.right && event.edges?.left))

      const shouldSetInitialYForResize =
        this._actionType === 'resize' &&
        ((snappedPoints.top && event.edges?.bottom) || (snappedPoints.bottom && event.edges?.top))

      // Set initial X position if:
      // 1. For move: when horizontal snap is active and no initial position.
      // 2. For resize: when resizing from one side while having snap on the opposite side.
      if (initialCursorPosition.x === null || shouldSetInitialXForResize) {
        initialCursorPosition.x = event.pageX
      }

      // Set initial Y position if:
      // 1. For move: when vertical snap is active and no initial position.
      // 2. For resize: when resizing from one side while having snap on the opposite side.
      if (initialCursorPosition.y === null || shouldSetInitialYForResize) {
        initialCursorPosition.y = event.pageY
      }
    }
  }

  snapWidgetToClosestElement = ({
    event,
    currentRect
  }: {
    event: TInteractEvent
    currentRect: TWidgetRect
  }) => {
    const { closestHorizontalWidget, closestVerticalWidget } = this.findClosestWidgets(currentRect)

    if (closestHorizontalWidget) {
      const { minHorizontalDistance } = this._calculateMinDistances(
        currentRect,
        closestHorizontalWidget
      )

      if (minHorizontalDistance < this._SNAP_THRESHOLD) {
        this.handleSnap({
          currentRect,
          closestWidget: closestHorizontalWidget,
          event,
          snapDirection: 'horizontal'
        })
      }
    }

    if (closestVerticalWidget) {
      const { minVerticalDistance } = this._calculateMinDistances(
        currentRect,
        closestVerticalWidget
      )

      if (minVerticalDistance < this._SNAP_THRESHOLD) {
        this.handleSnap({
          currentRect,
          closestWidget: closestVerticalWidget,
          event,
          snapDirection: 'vertical'
        })
      }
    }

    if (!closestVerticalWidget && !closestHorizontalWidget && this._actionType === 'move') {
      this.snapToClosestSectionCenter(currentRect)
    }

    this.updateSnappingState(event)
  }

  // Returns the updated rectangle position after snapping.
  getSnappingPosition = ({
    event,
    target,
    deltaX,
    deltaY,
    isSnappingEnabled = true
  }: {
    event: TInteractEvent | undefined
    target: HTMLElement
    deltaX: number
    deltaY: number
    isSnappingEnabled?: boolean
  }) => {
    const currentRect = this.getWidgetRect(target)

    if (!isSnappingEnabled) {
      this._snappingState = this._initialSnappingState
      this.resetHighlightEffects()
    }

    const { snapDelta, snappedPoints } = this._snappingState

    const shouldUpdateTopPosition = snapDelta.y === null || snappedPoints.bottom
    const shouldUpdateLeftPosition = snapDelta.x === null || snappedPoints.right

    if (shouldUpdateTopPosition) {
      currentRect.top += deltaY
    }
    if (shouldUpdateLeftPosition) {
      currentRect.left += deltaX
    }

    const hasSnapOffset = snapDelta.x !== null || snapDelta.y !== null

    if (hasSnapOffset && event) {
      this.handleUnsnap(event, currentRect)
    }

    if (isSnappingEnabled && event) {
      this.snapWidgetToClosestElement({ event, currentRect })
    }

    currentRect.height = currentRect.bottom - currentRect.top
    currentRect.width = currentRect.right - currentRect.left

    return { currentRect }
  }
}

export const WidgetSnappingService = new _WidgetSnappingService()
