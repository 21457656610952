import { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@praxie/shared'
import { DialogActions, DialogContent } from 'components/common/dialog/dialog'
import {
  loadSuggestion,
  loadVariableSuggestion,
  loadSearchSuggestion
} from 'helpers/autosuggestHelpers'
import { FILTERS_OBJECTS, OPERATOR, RULES_MODAL_MODES } from 'constants/filters/filtersConstants'
import {
  RULE_VALUE_TYPE,
  FILTER_PREDICATE,
  changeArrayItemByUuid,
  createNewCondition,
  getPredicateConfig,
  getAvailableRulesConfig,
  getFirstAvailableCondition
} from 'helpers/filters/conditionsHelpers'
import messages from 'constants/messages'
import { IconButton } from 'components/buttons/iconButton/iconButton'
import RulesPreview from 'components/filters/conditionsModal/RulesPreview'
import RulesModalControls from 'components/filters/conditionsModal/RulesModalControls'
import EmptyIcon from 'assets/images/icons/queryBuilderWidget/ic_empty_rules_modal.svg?react'
import { FilterRuleConditions } from 'features/filter/filterRuleConditions/filterRuleConditions'
import { getDefaultPredicates } from 'features/filter/filterRuleConditions/filterRuleConditions.helpers'
import {
  EFilterRuleCondition,
  EFilterRuleConditionPredicateName
} from 'features/filter/filter.types'
import 'scss/filters/conditionsModal/rules-modal.scss'

class ConditionsConfigurationContainer extends Component {
  state = {
    operator: OPERATOR.AND,
    conditions: []
  }

  get rulesConfig() {
    const { conditions } = this.state
    const { rulesConfig } = this.props

    if (!Object.keys(rulesConfig).length) {
      return {}
    }

    return getAvailableRulesConfig(rulesConfig, conditions)
  }

  componentDidMount() {
    const { ruleUuid, mode, getEditedRule } = this.props

    if (mode === RULES_MODAL_MODES.EDIT) {
      const { conditions, conditionsOperator: operator } = getEditedRule(ruleUuid)

      this.setState({ conditions: [...conditions], operator })
    } else if (mode === RULES_MODAL_MODES.CREATE) {
      this.setState({ conditions: [] })
    }
  }

  handleCloseModal = () => {
    const { toggleRulesModal } = this.props

    toggleRulesModal({ state: false })
  }

  handleApplyRules = () => {
    const { conditions, operator: conditionsOperator } = this.state
    const { ruleUuid: uuid, applyRules } = this.props

    this.handleCloseModal()

    applyRules({
      uuid,
      conditionsOperator,
      conditions
    })
  }

  handleAddCondition = () => {
    const { conditions } = this.state

    const availableCondition = getFirstAvailableCondition(this.rulesConfig)

    if (availableCondition) {
      this.setState({
        conditions: [...conditions, createNewCondition(availableCondition, this.rulesConfig)]
      })
    }
  }

  handleConditionDelete = uuid => {
    this.setState(state => ({
      conditions: state.conditions.filter(rule => rule.uuid !== uuid)
    }))
  }

  handleConditionSelect = (uuid, value) => {
    const { rulesConfig } = this.props

    const conditionConfig = rulesConfig[value]
    const conditionDefaultPredicates = getDefaultPredicates(
      conditionConfig,
      EFilterRuleConditionPredicateName.condition
    )

    this.setState(({ conditions }) => ({
      conditions: changeArrayItemByUuid({
        items: conditions,
        uuid,
        newProps: {
          ...conditionDefaultPredicates,
          condition: value,
          x: 1,
          y: 1,
          valueType: RULE_VALUE_TYPE.VALUE,
          values: [],
          variables: [],
          entityIds: []
        }
      })
    }))
  }

  handlePredicateSelect = (rule, { predicateConfig, predicateName, predicate }) => {
    const defaultPredicates = getDefaultPredicates(predicateConfig, predicateName)

    const valueType =
      predicate === FILTER_PREDICATE.IS && rule.condition !== EFilterRuleCondition.team
        ? RULE_VALUE_TYPE.ENTITY_IDS
        : RULE_VALUE_TYPE.VALUE

    const newProps = {
      ...defaultPredicates,
      [predicateName]: predicate,
      valueType,
      values: [],
      variables: [],
      entityIds: []
    }

    this.setState(({ conditions }) => ({
      conditions: changeArrayItemByUuid({
        items: conditions,
        uuid: rule.uuid,
        newProps
      })
    }))
  }

  handleValueTypeSelect = (uuid, valueType) => {
    this.setState(({ conditions }) => ({
      conditions: changeArrayItemByUuid({
        items: conditions,
        uuid,
        newProps: {
          valueType,
          values: [],
          variables: [],
          entityIds: []
        }
      })
    }))
  }

  handleValuesChange = (uuid, values) => {
    this.setState(({ conditions }) => ({
      conditions: changeArrayItemByUuid({
        items: conditions,
        uuid,
        newProps: {
          values: values.map(item => item.value)
        }
      })
    }))
  }

  handleVariablesChange = (uuid, variables) => {
    this.setState(({ conditions }) => ({
      conditions: changeArrayItemByUuid({
        items: conditions,
        uuid,
        newProps: {
          variables: Array.isArray(variables) ? variables : [variables]
        }
      })
    }))
  }

  handleEntityIdsChange = (uuid, entityIds) => {
    this.setState(({ conditions }) => ({
      conditions: changeArrayItemByUuid({
        items: conditions,
        uuid,
        newProps: {
          entityIds: Array.isArray(entityIds) ? entityIds : [entityIds]
        }
      })
    }))
  }

  toggleOperator = () => {
    this.setState(state => ({
      operator: state.operator === OPERATOR.AND ? OPERATOR.OR : OPERATOR.AND
    }))
  }

  handleLoadSuggestion = (rule, inputValue) => {
    const { condition, predicate } = rule
    const { tenantId, rulesConfig } = this.props
    const { isSearchInput } = getPredicateConfig(rulesConfig, { condition, predicate })
    if (isSearchInput) {
      return loadSearchSuggestion({
        type: condition,
        tenantId,
        inputValue
      })
    }
    return loadSuggestion({
      type: condition,
      tenantId,
      inputValue
    })
  }

  handleLoadVariableSuggestion = inputValue => {
    const { tenantId } = this.props

    return loadVariableSuggestion({
      tenantId,
      inputValue
    })
  }

  handleParamsChange = (uuid, propName, value) => {
    this.setState(({ conditions }) => ({
      conditions: changeArrayItemByUuid({
        items: conditions,
        uuid,
        newProps: {
          [propName]: value
        }
      })
    }))
  }

  render() {
    const { rulesConfig, maxRulesCount, queryObject, editVariable } = this.props

    const { operator, conditions } = this.state

    return (
      <>
        <DialogContent className="content">
          <RulesPreview
            operator={operator}
            rulesConfig={rulesConfig}
            rules={conditions}
            queryObject={queryObject}
          />
          <RulesModalControls
            operator={operator}
            className="rule-controls"
            toggleOperator={this.toggleOperator}
          >
            <IconButton
              className="btn-add-condition"
              appearance="ribbon"
              iconSeparated={false}
              size="small"
              icon={<i className="fa fa-plus-circle" aria-hidden="true" />}
              disabled={conditions.length >= maxRulesCount}
              onClick={this.handleAddCondition}
            >
              {messages.ADD_CONDITION}
            </IconButton>
          </RulesModalControls>
          <FilterRuleConditions
            className="rule-configuration-conditions"
            conditions={conditions}
            rulesConfig={this.rulesConfig}
            emptyStateIcon={<EmptyIcon />}
            emptyStateFirstRow="No Conditions"
            emptyStateSecondRow="Add condition(s) to create new rule"
            isSupportVariables
            onVariableUpdate={editVariable}
            onConditionDelete={this.handleConditionDelete}
            onConditionSelect={this.handleConditionSelect}
            onPredicateSelect={this.handlePredicateSelect}
            onValueTypeSelect={this.handleValueTypeSelect}
            onVariablesChange={this.handleVariablesChange}
            onEntityIdsChange={this.handleEntityIdsChange}
            onValuesChange={this.handleValuesChange}
            onParamsChange={this.handleParamsChange}
            onLoadSuggestion={this.handleLoadSuggestion}
            onLoadVariableSuggestion={this.handleLoadVariableSuggestion}
          />
        </DialogContent>
        <DialogActions className="footer">
          <Button className="cancel-btn" appearance="secondary" onClick={this.handleCloseModal}>
            {messages.CANCEL}
          </Button>
          <Button disabled={false} autoFocus onClick={this.handleApplyRules}>
            {messages.DONE}
          </Button>
        </DialogActions>
      </>
    )
  }
}

ConditionsConfigurationContainer.propTypes = {
  tenantId: PropTypes.string.isRequired,
  ruleUuid: PropTypes.string,
  getEditedRule: PropTypes.func.isRequired,
  toggleRulesModal: PropTypes.func.isRequired,
  applyRules: PropTypes.func.isRequired,
  maxRulesCount: PropTypes.number,
  mode: PropTypes.oneOf([RULES_MODAL_MODES.CREATE, RULES_MODAL_MODES.EDIT]).isRequired,
  rulesConfig: PropTypes.object,
  queryObject: PropTypes.oneOf([
    FILTERS_OBJECTS.WIDGET,
    FILTERS_OBJECTS.CARD,
    FILTERS_OBJECTS.DATA_RECORD
  ]).isRequired
}

ConditionsConfigurationContainer.defaultProps = {
  rulesConfig: {},
  maxRulesCount: 20,
  ruleUuid: null
}

export default ConditionsConfigurationContainer
