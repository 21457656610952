import { type ComponentProps, type ElementType, type FunctionComponent } from 'react'
import EyeIcon from 'assets/images/icons/eye.svg?react'
import FileIcon from 'assets/images/icons/workflowBuilder/file-grey.svg?react'
import ConvertWidgetDataIcon from 'assets/images/icons/workflowBuilder/ic-convert-widget-data.svg?react'
import CreateColumnContainer from 'containers/workflowBuilder/createBlocks/CreateColumnContainer'
import DeleteBlockContainer from 'containers/workflowBuilder/deleteBlocks/DeleteBlockContainer'
import FindWidgetsBundleBlockContainer from 'containers/workflowBuilder/findBundleBlock/FindWidgetsBundleBlockContainer'
import FinishBlockContainer from 'containers/workflowBuilder/finishBlock/FinishBlockContainer'
import HideCardSectionBlockContainer from 'containers/workflowBuilder/hideCardSectionBlock/HideCardSectionBlockContainer'
import MoveCardBlockContainer from 'containers/workflowBuilder/moveCardBlock/MoveCardBlockContainer'
import MoveCardToBoardBlockContainer from 'containers/workflowBuilder/moveCardBlock/MoveCardToBoardBlockContainer'
import MoveColumnBlockContainer from 'containers/workflowBuilder/moveColumnBlock/MoveColumnBlockContainer'
import CopyBoardContainer from 'containers/workflowBuilder/сopyBlocks/CopyBoardContainer'
import AddCardComment from 'components/workflowBuilder/addCardCommentBlock/AddCardComment'
import SetCardOwner from 'components/workflowBuilder/cardOwnerBlocks/SetCardOwner'
import DefineStringFromBoard from 'components/workflowBuilder/defineStringBlocks/DefineStringFromBoard'
import DefineStringFromCard from 'components/workflowBuilder/defineStringBlocks/DefineStringFromCard'
import DefineStringFromColumn from 'components/workflowBuilder/defineStringBlocks/DefineStringFromColumn'
import DefineStringFromJsonPayload from 'components/workflowBuilder/defineStringBlocks/DefineStringFromJsonPayload'
import DefineStringFromUser from 'components/workflowBuilder/defineStringBlocks/DefineStringFromUser'
import DefineStringFromWidget from 'components/workflowBuilder/defineStringBlocks/DefineStringFromWidget'
import FindBoard from 'components/workflowBuilder/findBlocks/FindBoard'
import FindCard from 'components/workflowBuilder/findBlocks/FindCard'
import FindColumn from 'components/workflowBuilder/findBlocks/FindColumn'
import FindUser from 'components/workflowBuilder/findBlocks/FindUser'
import FindWidget from 'components/workflowBuilder/findBlocks/FindWidget'
import FindCardBundleFromFilter from 'components/workflowBuilder/findBundleBlock/FindCardBundleFromFilter'
import GetBoard from 'components/workflowBuilder/getBlocks/GetBoard'
import GetCard from 'components/workflowBuilder/getBlocks/GetCard'
import GetColumn from 'components/workflowBuilder/getBlocks/GetColumn'
import GetUser from 'components/workflowBuilder/getBlocks/GetUser'
import GetWidget from 'components/workflowBuilder/getBlocks/GetWidget'
import { HideCardActionBlock } from 'components/workflowBuilder/hideCardActionBlock'
import RenameBoard from 'components/workflowBuilder/renameBlocks/RenameBoard'
import RenameCard from 'components/workflowBuilder/renameBlocks/RenameCard'
import RenameColumn from 'components/workflowBuilder/renameBlocks/RenameColumn'
import SelectWidgetsFromFilter from 'components/workflowBuilder/selectBlocks/SelectWidgetsFromFilter'
import { SendPromptToAIBlock } from 'components/workflowBuilder/sendPromptToAIBlock/sendPromptToAIBlock'
import SwitchWidget from 'components/workflowBuilder/switchStageWidgetBlocks/SwitchWidget'
import PublishFeedNotification from 'components/workflowBuilder/systemActions/PublishFeedNotification'
import { RestAPIClientBlock } from 'components/workflowBuilder/systemActions/restApiClientBlock/restApiClientBlock'
import SendEmail from 'components/workflowBuilder/systemActions/SendEmail'
import HideColumn from 'components/workflowBuilder/toggleFlagBlocks/HideColumn'
import HideWidget from 'components/workflowBuilder/toggleFlagBlocks/HideWidget'
import HideWidgetsBundle from 'components/workflowBuilder/toggleFlagBlocks/HideWidgetsBundle'
import LockWidget from 'components/workflowBuilder/toggleFlagBlocks/LockWidget'
import LockWidgetsBundle from 'components/workflowBuilder/toggleFlagBlocks/LockWidgetsBundle'
import { TriggerBlock } from 'components/workflowBuilder/triggersBlock/triggerBlock'
import { ConvertWidgetData } from 'components/workflowBuilder/widgetDataBlocks/convertWidgetData/convertWidgetData'
import { ConvertWidgetDataIntoFile } from 'components/workflowBuilder/widgetDataBlocks/convertWidgetDataIntoFile/convertWidgetDataIntoFile'
import { DefineFileFromWidget } from 'components/workflowBuilder/widgetDataBlocks/defineFileFromWidget/defineFileFromWidget'
import GetWidgetData from 'components/workflowBuilder/widgetDataBlocks/GetWidgetData'
import UpdateWidgetData from 'components/workflowBuilder/widgetDataBlocks/UpdateWidgetData'
import { UpdateWidgetWithFile } from 'components/workflowBuilder/widgetDataBlocks/updateWidgetWithFile/updateWidgetWithFile'
import CopyCard from 'components/workflowBuilder/сopyBlocks/CopyCard'
import CopyColumn from 'components/workflowBuilder/сopyBlocks/CopyColumn'
import CopyWidgetsBundle from 'components/workflowBuilder/сopyBlocks/CopyWidgetsBundle'
import { EWorkflowBlockTypes } from './workflowBuilder/blocksTypes'

export type TWorkflowMenuItem =
  | {
      className: string // Deprecated way to define icon. Use Icon instead.
      Action: ElementType
    }
  | {
      Icon: FunctionComponent<ComponentProps<'svg'> & { title?: string }>
      Action: ElementType
    }

export const WORKFLOWS_LIST: Record<EWorkflowBlockTypes, TWorkflowMenuItem> = {
  [EWorkflowBlockTypes.TRIGGER]: {
    className: 'trigger',
    Action: TriggerBlock
  },
  [EWorkflowBlockTypes.GET_BOARD]: {
    className: 'get-board',
    Action: GetBoard
  },
  [EWorkflowBlockTypes.GET_COLUMN]: {
    className: 'get-column',
    Action: GetColumn
  },
  [EWorkflowBlockTypes.MOVE_COLUMN]: {
    className: 'move-column',
    Action: MoveColumnBlockContainer
  },
  [EWorkflowBlockTypes.GET_CARD]: {
    className: 'get-card',
    Action: GetCard
  },
  [EWorkflowBlockTypes.GET_WIDGET]: {
    className: 'get-widget',
    Action: GetWidget
  },
  [EWorkflowBlockTypes.GET_WIDGET_DATA]: {
    className: 'get-widget-data',
    Action: GetWidgetData
  },
  [EWorkflowBlockTypes.UPDATE_WIDGET_DATA]: {
    className: 'update-widget-data',
    Action: UpdateWidgetData
  },
  [EWorkflowBlockTypes.CONVERT_WIDGET_DATA]: {
    Icon: ConvertWidgetDataIcon,
    Action: ConvertWidgetData
  },
  [EWorkflowBlockTypes.FIND_BOARD]: {
    className: 'find-board',
    Action: FindBoard
  },
  [EWorkflowBlockTypes.FIND_WIDGET]: {
    className: 'find-widget',
    Action: FindWidget
  },
  [EWorkflowBlockTypes.FIND_CARD]: {
    className: 'find-card',
    Action: FindCard
  },
  [EWorkflowBlockTypes.FIND_WIDGETS_BUNDLE]: {
    className: 'find-widget-bundle',
    Action: FindWidgetsBundleBlockContainer
  },
  [EWorkflowBlockTypes.SELECT_WIDGETS_FROM_FILTER]: {
    className: 'select-widgets-from-filter',
    Action: SelectWidgetsFromFilter
  },
  [EWorkflowBlockTypes.FIND_CARD_BUNDLE_FROM_FILTER]: {
    className: 'find-card-bundle-from-filter',
    Action: FindCardBundleFromFilter
  },
  [EWorkflowBlockTypes.HIDE_WIDGET]: {
    className: 'hide-widget',
    Action: HideWidget
  },
  [EWorkflowBlockTypes.HIDE_WIDGETS_BUNDLE_COMPONENT]: {
    className: 'hide-widget',
    Action: HideWidgetsBundle
  },
  [EWorkflowBlockTypes.LOCK_WIDGET]: {
    className: 'workflow-lock-widget',
    Action: LockWidget
  },
  [EWorkflowBlockTypes.HIDE_CARD_SECTION]: {
    Icon: EyeIcon,
    Action: HideCardSectionBlockContainer
  },
  [EWorkflowBlockTypes.HIDE_CARD_ACTION]: {
    Icon: EyeIcon,
    Action: HideCardActionBlock
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_BOARD]: {
    className: 'define-string-board',
    Action: DefineStringFromBoard
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_CARD]: {
    className: 'define-string-card',
    Action: DefineStringFromCard
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_WIDGET]: {
    className: 'define-string-widget',
    Action: DefineStringFromWidget
  },
  [EWorkflowBlockTypes.DEFINE_FILE_FROM_WIDGET]: {
    Icon: FileIcon,
    Action: DefineFileFromWidget
  },
  [EWorkflowBlockTypes.CONVERT_WIDGET_DATA_INTO_FILE]: {
    Icon: FileIcon,
    Action: ConvertWidgetDataIntoFile
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_JSON_PAYLOAD]: {
    className: 'define-string-json-payload',
    Action: DefineStringFromJsonPayload
  },
  [EWorkflowBlockTypes.SWITCH_STAGE_WIDGET]: {
    className: 'workflow-switch-widget',
    Action: SwitchWidget
  },
  [EWorkflowBlockTypes.MOVE_CARD]: {
    className: 'move-card',
    Action: MoveCardBlockContainer
  },
  [EWorkflowBlockTypes.MOVE_CARD_TO_BOARD]: {
    className: 'move-card-to-board',
    Action: MoveCardToBoardBlockContainer
  },
  [EWorkflowBlockTypes.COPY_CARD]: {
    className: 'workflow-copy-card',
    Action: CopyCard
  },
  [EWorkflowBlockTypes.COPY_WIDGETS_BUNDLE]: {
    className: 'workflow-copy-widgets-bundle',
    Action: CopyWidgetsBundle
  },
  [EWorkflowBlockTypes.COPY_BOARD]: {
    className: 'workflow-copy-board',
    Action: CopyBoardContainer
  },
  [EWorkflowBlockTypes.COPY_COLUMN]: {
    className: 'workflow-copy-column',
    Action: CopyColumn
  },
  [EWorkflowBlockTypes.LOCK_WIDGETS_BUNDLE_COMPONENT]: {
    className: 'workflow-lock-widget',
    Action: LockWidgetsBundle
  },
  [EWorkflowBlockTypes.RENAME_CARD]: {
    className: 'workflow-rename-card',
    Action: RenameCard
  },
  [EWorkflowBlockTypes.SET_CARD_OWNER]: {
    className: 'workflow-card-owner',
    Action: SetCardOwner
  },
  [EWorkflowBlockTypes.RENAME_BOARD]: {
    className: 'workflow-rename-board',
    Action: RenameBoard
  },
  [EWorkflowBlockTypes.RENAME_COLUMN]: {
    className: 'workflow-rename-column',
    Action: RenameColumn
  },
  [EWorkflowBlockTypes.CREATE_COLUMN]: {
    className: 'workflow-create-column',
    Action: CreateColumnContainer
  },
  [EWorkflowBlockTypes.DELETE_CARD]: {
    className: 'workflow-delete-card',
    Action: DeleteBlockContainer
  },
  [EWorkflowBlockTypes.DELETE_CARD_BUNDLE]: {
    className: 'workflow-delete-card-bundle',
    Action: DeleteBlockContainer
  },
  [EWorkflowBlockTypes.DELETE_WIDGET]: {
    className: 'workflow-delete-widget',
    Action: DeleteBlockContainer
  },
  [EWorkflowBlockTypes.DELETE_WIDGETS_BUNDLE]: {
    className: 'workflow-delete-widgets-bundle',
    Action: DeleteBlockContainer
  },
  [EWorkflowBlockTypes.DELETE_COLUMN]: {
    className: 'workflow-delete-column',
    Action: DeleteBlockContainer
  },
  [EWorkflowBlockTypes.ADD_CARD_COMMENT]: {
    className: 'workflow-add-card-comment',
    Action: AddCardComment
  },
  [EWorkflowBlockTypes.GET_USER]: {
    className: 'workflow-get-user',
    Action: GetUser
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_USER]: {
    className: 'define-string-user',
    Action: DefineStringFromUser
  },
  [EWorkflowBlockTypes.FIND_COLUMN]: {
    className: 'find-column',
    Action: FindColumn
  },
  [EWorkflowBlockTypes.FIND_USER]: {
    className: 'find-user',
    Action: FindUser
  },
  [EWorkflowBlockTypes.HIDE_COLUMN]: {
    className: 'hide-column',
    Action: HideColumn
  },
  [EWorkflowBlockTypes.SEND_EMAIL]: {
    className: 'workflow-send-email',
    Action: SendEmail
  },
  [EWorkflowBlockTypes.REST_API_CLIENT]: {
    className: 'workflow-rest-api-client',
    Action: RestAPIClientBlock
  },
  [EWorkflowBlockTypes.PUBLISH_FEED_NOTIFICATION]: {
    className: 'workflow-publish-feed-notification',
    Action: PublishFeedNotification
  },
  [EWorkflowBlockTypes.DEFINE_STRING_FROM_COLUMN]: {
    className: 'define-string-column',
    Action: DefineStringFromColumn
  },
  [EWorkflowBlockTypes.SEND_PROMPT_TO_AI]: {
    className: 'workflow-send-prompt-to-ai',
    Action: SendPromptToAIBlock
  },
  [EWorkflowBlockTypes.DEFINE_JSON_WITH_AI]: {
    className: 'workflow-define-json-with-ai',
    Action: SendPromptToAIBlock
  },
  [EWorkflowBlockTypes.GENERATE_FILE_WITH_AI]: {
    Icon: FileIcon,
    Action: SendPromptToAIBlock
  },
  [EWorkflowBlockTypes.UPDATE_WIDGET_WITH_FILE]: {
    Icon: FileIcon,
    Action: UpdateWidgetWithFile
  },
  [EWorkflowBlockTypes.FINISH]: {
    className: 'workflow-finish',
    Action: FinishBlockContainer
  }
}
