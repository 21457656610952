import React, { Component } from 'react'

import CopyBlock from '../../../components/workflowBuilder/сopyBlocks/CopyBlock'

export default class CopyBlockContainer extends Component {
  handleInputChange = (fieldName, item) => {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset = fieldName => {
    this.handleInputChange(fieldName, { id: null })
  }

  toggleRadioFields = () => {
    const {
      block: { meta },
      radioFieldAttr: { radioFieldKey }
    } = this.props

    this.updateWorkflowBlock({
      [radioFieldKey]: !meta[radioFieldKey]
    })
  }

  updateWorkflowBlock = data => {
    const { block } = this.props

    const meta = block.meta || {}

    this.props.updateWorkflowBlock({
      id: block.id,
      data: {
        meta: {
          ...meta,
          ...data
        },
        error: {}
      }
    })
  }

  render() {
    const { block, inputSourceAttr, inputTargetAttr, radioFieldAttr } = this.props

    return (
      <CopyBlock
        block={block}
        inputSourceAttr={inputSourceAttr}
        inputTargetAttr={inputTargetAttr}
        radioFieldAttr={radioFieldAttr}
        handleReset={this.handleReset}
        handleInputChange={this.handleInputChange}
        toggleRadioFields={this.toggleRadioFields}
      />
    )
  }
}
