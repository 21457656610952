import { Component } from 'react'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { BLOCKS_DEFINITION } from 'features/workflow/workflowBuilder/model/workflowBuilder.constants'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import { ObjectOutputField } from 'components/workflowBuilder/common/objectOutputField/objectOutputField'

class DefineStringBlock extends Component {
  constructor(props) {
    super(props)
    this.onInputSelect = this.onInputSelect.bind(this)
    this.onOutputChange = this.onOutputChange.bind(this)
    this.onReset = this.onReset.bind(this)
  }

  onOutputChange(outputValue) {
    this.updateBlockMeta(outputValue)
  }

  onInputSelect(item) {
    const fieldName = EFieldKeys.SOURCE_INPUT
    const { block } = this.props
    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  onReset() {
    const { block, outputField } = this.props

    const output = {
      [outputField]: ''
    }

    if (BLOCKS_DEFINITION[block.type].inputDefinition[EFieldKeys.PARAMS]) {
      output[EFieldKeys.PARAMS] = { x: 1, y: 1 }
    }

    this.onOutputChange(output)
    this.onInputSelect({ id: null })
  }

  updateBlockMeta(value) {
    const { updateWorkflowBlock, block } = this.props
    const meta = { ...block.meta, ...value }
    updateWorkflowBlock({ id: block.id, data: { meta, error: {} } })
  }

  render() {
    const {
      block,
      inputType,
      outputField,
      inputFieldLabel,
      inputFieldPlaceholder,
      outputFieldPlaceholder,
      attributeLabel
    } = this.props

    const { error, id, input, meta } = block
    const inputValue = input && input[EFieldKeys.SOURCE_INPUT]
    const blockMeta = meta || {}

    return (
      <div className="workflow-actions-content">
        <BlockInputContainer type={inputType} blockId={id} value={inputValue}>
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              title={inputFieldLabel}
              placeholder={inputFieldPlaceholder}
              items={items}
              value={selectedItem}
              type={inputType}
              searchItems={searchItems}
              error={error[EFieldKeys.SOURCE_INPUT]}
              supportHistory
              onSelect={this.onInputSelect}
              onReset={this.onReset}
            />
          )}
        </BlockInputContainer>
        <p className="input-field-label">{attributeLabel || messages.DEFINE_STRING_FROM}</p>
        <ObjectOutputField
          type={inputType}
          blockMeta={blockMeta}
          outputField={outputField}
          placeholder={outputFieldPlaceholder}
          error={!!error[outputField]}
          disabled={!inputValue}
          onOutputChange={this.onOutputChange}
        />
      </div>
    )
  }
}

export default DefineStringBlock
