import { changeWorkflowBlockInput, updateWorkflowBlock } from 'actions'
import { useDispatch } from 'react-redux'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import { ExecutionConditions } from './executionConditions/executionConditions'
import './update-widget-with-file.scss'

type TProps = {
  block: IWorkflowBlock
}

export const UpdateWidgetWithFile = ({ block: { id, meta, input, error } }: TProps) => {
  const dispatch = useDispatch()

  const updateWorkflowBlockMeta = (newMeta: Partial<IWorkflowBlock['meta']>) => {
    const updatedMeta = { ...meta, ...newMeta }

    // @ts-expect-error
    dispatch(updateWorkflowBlock({ id, data: { meta: updatedMeta, error: {} } }))
  }

  const handleInputChange = (fieldName: string, item: Partial<IWorkflowDropdownValue> | null) => {
    dispatch(
      // @ts-expect-error
      changeWorkflowBlockInput({
        fieldName,
        blockId: id,
        outputId: item ? item.id : null,
        oldOutputId: input[fieldName] || null,
        error
      })
    )
  }

  const handleSelect = (item: Partial<IWorkflowDropdownValue> | null, key: EFieldKeys) => {
    handleInputChange(key, item)
    updateWorkflowBlockMeta({ [key]: item })
  }

  return (
    <div className="workflow-update-widget-with-file-block">
      <ExecutionConditions />
      <div className="block-input-container">
        <BlockInputContainer
          type={OBJECT_TYPES.FILE}
          blockId={id}
          value={input[EFieldKeys.FILE_INPUT]}
        >
          {({
            selectedItem,
            items,
            searchItems
          }: {
            selectedItem: IWorkflowDropdownValue
            items: IWorkflowDropdownValue[]
            searchItems: (search: string) => void
          }) => (
            <AnyLevelDropdown
              title="File to send"
              placeholder="Select file output from previous blocks"
              type={OBJECT_TYPES.FILE}
              items={items}
              value={selectedItem}
              searchItems={searchItems}
              error={error[EFieldKeys.FILE_INPUT]}
              supportHistory
              onSelect={(item: Partial<IWorkflowDropdownValue> | null) =>
                handleSelect(item, EFieldKeys.FILE_INPUT)
              }
              onReset={() => handleSelect(null, EFieldKeys.FILE_INPUT)}
            />
          )}
        </BlockInputContainer>
      </div>
      <div className="block-input-container">
        <BlockInputContainer
          type={OBJECT_TYPES.WIDGET}
          blockId={id}
          value={input[EFieldKeys.SOURCE_INPUT]}
        >
          {({
            selectedItem,
            items,
            searchItems
          }: {
            selectedItem: IWorkflowDropdownValue
            items: IWorkflowDropdownValue[]
            searchItems: (search: string) => void
          }) => (
            <AnyLevelDropdown
              title="Widget to update"
              placeholder="Select widget to update"
              type={OBJECT_TYPES.WIDGET}
              items={items}
              value={selectedItem}
              searchItems={searchItems}
              error={error[EFieldKeys.SOURCE_INPUT]}
              supportHistory
              onSelect={(item: Partial<IWorkflowDropdownValue> | null) =>
                handleSelect(item, EFieldKeys.SOURCE_INPUT)
              }
              onReset={() => handleSelect(null, EFieldKeys.SOURCE_INPUT)}
            />
          )}
        </BlockInputContainer>
      </div>
    </div>
  )
}
