import { type ReactNode } from 'react'
import { FloatingPortal } from '@floating-ui/react'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import {
  FLOATING_RIBBON_DEFAULTS,
  FLOATING_RIBBON_ELEMENT_ID
} from '../model/floatingRibbon.constants'
import { getFloatingRibbonContainerAnimation } from '../model/floatingRibbon.helpers'
import { type TFloatingRibbonPositioningOptions } from '../model/floatingRibbon.types'
import { useRibbonPositioning } from '../model/useRibbonPositioning'
import classes from './floating-ribbon.module.scss'

type TRibbonProps = TFloatingRibbonPositioningOptions & {
  className?: string
  children?: ReactNode
}

const Ribbon = ({
  anchor,
  className,
  children,
  boundary,
  placement = FLOATING_RIBBON_DEFAULTS.PLACEMENT,
  offset = FLOATING_RIBBON_DEFAULTS.OFFSET,
  shiftOffset = FLOATING_RIBBON_DEFAULTS.SHIFT_OFFSET,
  portalTarget
}: TRibbonProps) => {
  const { refs, floatingStyles, context, middlewareData } = useRibbonPositioning({
    anchor,
    boundary,
    placement,
    offset,
    shiftOffset
  })

  const { y, x, placement: computedPlacement, isPositioned } = context
  const portalRoot = portalTarget ?? anchor?.parentElement

  // Omit transform from Floating styles. Positioning is handled by Motion.
  const { transform, ...styles } = floatingStyles
  const containerAnimationProps = getFloatingRibbonContainerAnimation(
    computedPlacement,
    middlewareData
  )

  return (
    <FloatingPortal root={portalRoot}>
      <motion.div
        key={isPositioned.toString()}
        ref={refs.setFloating}
        id={FLOATING_RIBBON_ELEMENT_ID}
        className={cn(classes.praxieFloatingRibbon, className)}
        style={styles}
        initial={false}
        animate={{ y, x }}
        role="menu"
        onClick={e => e.stopPropagation()}
      >
        <motion.div className={classes.container} {...containerAnimationProps}>
          {children}
        </motion.div>
      </motion.div>
    </FloatingPortal>
  )
}

type TProps = TRibbonProps & {
  shouldShow?: boolean
}

export const FloatingRibbon = ({ shouldShow = true, ...restProps }: TProps) => {
  return <AnimatePresence>{shouldShow && <Ribbon {...restProps} />}</AnimatePresence>
}
