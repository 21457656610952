import cn from 'classnames'
import BoardsBundleIcon from 'assets/images/icons/ic_boards_bundle.svg?react'
import messages from 'constants/messages'
import CircleSpinner from 'components/spinners/CircleSpinner'
import { type IJob, EJobStatuses } from 'features/jobs/jobs.types'

const getExportErrorMessage = (error: IJob['error']) => {
  if (error?.type === 'NotOrgAdminExportCrossTeamFilter') {
    return 'Oops! Export cannot be completed due to cross-team filter security constraints. Contact your Workspace Admin to run your export.'
  }

  return messages.OPERATION_FAILED
}

type TProps = {
  job: IJob
  retryExportBoardsBundle: () => void
}

export const ExportIndicatorItem = ({ job, retryExportBoardsBundle }: TProps) => {
  const isDone = job.status === EJobStatuses.DONE
  const isError = job.status === EJobStatuses.ERROR

  return (
    <div className="board_item">
      <div className="board_data">
        <BoardsBundleIcon />
        <div
          className={cn('board_content', {
            'finished-import': isDone,
            'failed-import': isError
          })}
        >
          <span className="board_name bold">
            {job.data.bundleName}
            {job.data.boardIds && (
              <span className="gray-text">
                {messages.BOARDS_IN_BRACKETS(job.data.boardIds.length)}
              </span>
            )}
          </span>
          {isError && <span className="error-text">{getExportErrorMessage(job.error)}</span>}
        </div>
      </div>
      <span className="board_progress">
        {!isDone && !isError && <CircleSpinner className="copied-board__spinner" />}
        {isDone && <span className="blue-link static">{messages.EXPORTED}</span>}
        {isError && (
          <span className="blue-link" onClick={retryExportBoardsBundle}>
            {messages.RETRY}
          </span>
        )}
      </span>
    </div>
  )
}
