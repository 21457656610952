export const extractBoard = ({ data }) => {
  const {
    organization: {
      tenant: { board, name }
    }
  } = data
  return { ...board, tenantName: name }
}

export const extractBoards = ({ data }) => {
  const {
    organization: {
      tenant: { boards }
    }
  } = data
  return boards
}
