import { type ReactNode } from 'react'
import { useEffectOnce } from 'react-use'
import ErrorIcon from 'assets/images/icons/error.svg?react'
import { SentryService } from 'services/sentry.service'
import classes from './error-boundary.module.scss'

type TErrorBoundaryProps = {
  children: ReactNode
  errorText: string
  isRoot?: boolean
}

export const ErrorBoundary = ({ children, errorText, isRoot }: TErrorBoundaryProps) => {
  const silentMUISelectErrors = () => {
    const originalConsoleWarn = window.console.warn

    window.console.warn = (message: string, ...optionalParams: unknown[]) => {
      // MUI complains about out-of-range values in Select component, but it's working fine.
      if (message.includes('MUI: You have provided an out-of-range value')) return
      originalConsoleWarn(message, ...optionalParams)
    }
  }

  useEffectOnce(() => {
    if (!isRoot) return undefined

    silentMUISelectErrors()
  })

  return (
    <SentryService.ErrorBoundary
      fallback={
        <div className={classes.errorBoundary}>
          <ErrorIcon />
          <span className={classes.errorText} title={errorText}>
            {errorText}
          </span>
        </div>
      }
    >
      {children}
    </SentryService.ErrorBoundary>
  )
}
