import { type PropsWithChildren, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editingWidgetSelector } from 'selectors/builderSelectors'
import { clearWidgetSelection } from 'actions/builderActions'
import { FLOATING_RIBBON_ELEMENT_ID } from 'components/common/floatingRibbon/model/floatingRibbon.constants'
import { DETAILED_VIEW_MODAL_CONTAINER_SELECTOR } from 'components/detailedViewModal/detailedViewModal.constants'
import { EWidgetModes } from 'features/widgets/widgets.types'
import classes from './widget-click-away-wrapper.module.scss'

type TProps = PropsWithChildren & {
  uuid: string
  mode: EWidgetModes
  isThumbnailView: boolean
  isSnapshotPreview: boolean
}

// A HoC to encapsulate widget click away event handling.
// Captures clicks outside the widget container and clears the widget selection.
export const WidgetClickAwayWrapper = ({
  uuid,
  mode,
  children,
  isThumbnailView,
  isSnapshotPreview
}: TProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const editingWidgetId = useSelector(editingWidgetSelector) as string
  const dispatch = useDispatch()

  useEffect(() => {
    // Capture clicks only for active widget in Detailed View modal.
    if (
      mode !== EWidgetModes.DETAILED ||
      isThumbnailView ||
      isSnapshotPreview ||
      editingWidgetId !== uuid
    )
      return

    const detailedViewModal = document.querySelector(DETAILED_VIEW_MODAL_CONTAINER_SELECTOR)
    if (!detailedViewModal) return

    const handleClick = (e: Event) => {
      const { current: widgetContainer } = ref
      const target = e.target as HTMLElement

      // Ignore clicks originating from the widget container.
      if (widgetContainer?.contains(target)) return

      // Ignore clicks originating from the floating ribbon of the widget.
      const floatingRibbon = detailedViewModal.querySelector(`#${FLOATING_RIBBON_ELEMENT_ID}`)
      if (floatingRibbon?.contains(target)) return

      // @ts-expect-error: dispatch expects an action, but we're passing a thunk
      dispatch(clearWidgetSelection(false))
    }

    detailedViewModal.addEventListener('mousedown', handleClick)
    detailedViewModal.addEventListener('touchstart', handleClick)

    return () => {
      detailedViewModal.removeEventListener('mousedown', handleClick)
      detailedViewModal.removeEventListener('touchstart', handleClick)
    }
  }, [uuid, mode, editingWidgetId, isThumbnailView, isSnapshotPreview, dispatch])

  return <div ref={ref} className={classes.widgetClickAwayWrapper}>{children}</div>
}
