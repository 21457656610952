import React, { Component } from 'react'

import MoveColumnBlock from '../../../components/workflowBuilder/moveColumnBlock/MoveColumnBlock'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

export default class MoveColumnBlockContainer extends Component {
  constructor(props) {
    super(props)

    const columnNumber = props.block.meta[EFieldKeys.RELATIVE_COLUMN_NUMBER]

    this.state = {
      columnNumber
    }

    this.handleReset = this.handleReset.bind(this)
    this.changeColumnType = this.changeColumnType.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.changeColumnNumber = this.changeColumnNumber.bind(this)
  }

  changeColumnType({ value }) {
    this.updateBlockMeta(EFieldKeys.TYPE, value)
  }

  changeColumnNumber(columnNumber) {
    this.setState({ columnNumber })
    this.updateBlockMeta(EFieldKeys.RELATIVE_COLUMN_NUMBER, columnNumber)
  }

  handleInputChange(fieldName, item) {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset(fieldName) {
    this.handleInputChange(fieldName, { id: null })
  }

  updateBlockMeta(key, fieldValue) {
    const {
      updateWorkflowBlock,
      block: { id, meta }
    } = this.props

    if (meta[key] !== fieldValue) {
      updateWorkflowBlock({
        id,
        data: {
          meta: { ...meta, [key]: fieldValue },
          error: {}
        }
      })
    }
  }

  render() {
    const { block } = this.props
    const { columnNumber } = this.state
    const meta = block.meta || {}
    return (
      <MoveColumnBlock
        block={block}
        columnType={meta[EFieldKeys.TYPE]}
        columnNumber={columnNumber}
        handleReset={this.handleReset}
        changeColumnType={this.changeColumnType}
        handleInputChange={this.handleInputChange}
        changeColumnNumber={this.changeColumnNumber}
      />
    )
  }
}
