import { useMemo } from 'react'
import { Button } from '@praxie/shared'
import { produce } from 'immer'
import PlusIcon from 'assets/images/icons/ic_plus_bold.svg?react'
import { formatUTCHoursToISO, getDefaultISOTime } from '../scheduleParams.helpers'
import { MAX_SCHEDULED_RUN_TIME_ITEMS_COUNT } from './runTimesSection.constants'
import { TimeSlotItem } from './timeSlotItem/timeSlotItem'
import './run-times-section.scss'

const getItemId = (time: string, index: number) => `${time}-${index}`

type TProps = {
  hours: Array<{ hour: string; minute: string }>
  error?: string
  setTimeItems: (timeItems: string[]) => void
}

export const RunTimesSection = ({ setTimeItems, error, hours }: TProps) => {
  const timeItems = useMemo(() => hours.map(formatUTCHoursToISO), [hours])

  const handleItemDelete = (index: number) => {
    const newTimeItems = produce(timeItems, draft => {
      draft.splice(index, 1)
    })
    setTimeItems(newTimeItems)
  }

  const handleItemChange = (index: number, value: string) => {
    const newTimeItems = produce(timeItems, draft => {
      draft[index] = value
    })

    setTimeItems(newTimeItems)
  }

  const handleItemAdd = () => {
    const newTimeItems = produce(timeItems, draft => {
      draft.push(getDefaultISOTime())
    })

    setTimeItems(newTimeItems)
  }

  const isAddButtonDisabled = timeItems.length >= MAX_SCHEDULED_RUN_TIME_ITEMS_COUNT

  return (
    <div className="workflow-run-times-section">
      <div className="title">Run times</div>
      <div className="items-container">
        {timeItems.map((timeItem, index) => {
          const isInvalid = index ? timeItems.slice(0, index).includes(timeItem) : false

          return (
            <TimeSlotItem
              key={getItemId(timeItem, index)}
              time={timeItem}
              isDeleteDisabled={timeItems.length === 1}
              error={isInvalid ? error : ''}
              onDelete={() => handleItemDelete(index)}
              onChange={value => handleItemChange(index, value)}
            />
          )
        })}
        <Button
          appearance="secondary3"
          className="add-item-button"
          disabled={isAddButtonDisabled}
          onClick={handleItemAdd}
        >
          <PlusIcon className="plus-icon" />
          <span>add time</span>
        </Button>
      </div>
    </div>
  )
}
