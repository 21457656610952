import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { getSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import {
  SWITCH_WIDGET_WAYS_OPTIONS,
  SWITCH_WIDGET_WAYS_CONFIGURATION,
  INPUT_FIELD_EQUAL,
  STRING_INPUT_EQUAL
} from 'helpers/workflowStageTracker/switchWidgetConfig'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import FormField from 'components/common/FormField'
import ReactSelect from 'components/fields/ReactSelect'
import PlainInput from 'components/fields/PlainInput'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import 'scss/workflowBuilder/switchWidgetBlock/stage-name.scss'

const SwitchWidgetBlock = ({
  inputFieldLabel,
  inputFieldPlaceholder,
  inputType,
  outputFieldPlaceholder,
  block,
  handleInputChange,
  handleReset,
  changeSelectOption,
  switchStageBy,
  stageName,
  changeStageName,
  blurColumnName
}) => {
  const { error, warn, id, input } = block
  const inputValue = input && input[EFieldKeys.SOURCE_INPUT]
  const isDisabled = !input[EFieldKeys.SOURCE_INPUT]

  return (
    <div className="workflow-actions-content">
      <BlockInputContainer type={inputType} blockId={id} value={inputValue}>
        {({ selectedItem, items, searchItems }) => (
          <AnyLevelDropdown
            title={inputFieldLabel}
            placeholder={inputFieldPlaceholder}
            items={items}
            value={selectedItem}
            type={inputType}
            searchItems={searchItems}
            error={error[EFieldKeys.SOURCE_INPUT]}
            warn={warn[EFieldKeys.SOURCE_INPUT]}
            supportHistory
            onSelect={option => handleInputChange(EFieldKeys.SOURCE_INPUT, option)}
            onReset={() => handleReset(EFieldKeys.SOURCE_INPUT)}
          />
        )}
      </BlockInputContainer>
      <FormField
        label={messages.MOVE_STAGE_BY}
        error={error[EFieldKeys.TYPE]}
        id="switch-widget-way"
      >
        <ReactSelect
          size="large"
          placeholder={outputFieldPlaceholder}
          value={getSelectedOptionByKey(switchStageBy, SWITCH_WIDGET_WAYS_CONFIGURATION)}
          options={SWITCH_WIDGET_WAYS_OPTIONS}
          isDisabled={isDisabled}
          menuPortalEnabled
          onChange={option => changeSelectOption(EFieldKeys.TYPE, option)}
        />
      </FormField>
      <div className="switch-block-stage-name">
        {switchStageBy === INPUT_FIELD_EQUAL && (
          <FormField label="" error={error[EFieldKeys.NAME]} id="stage-name-plain-string">
            <PlainInput
              className="input-field"
              placeholder={messages.STAGE_NAME_PLACEHOLDER}
              maxLength={50}
              value={stageName}
              disabled={isDisabled}
              onChange={changeStageName}
              onBlur={blurColumnName}
            />
          </FormField>
        )}
        {switchStageBy === STRING_INPUT_EQUAL && (
          <BlockInputContainer
            type={OBJECT_TYPES.STRING}
            blockId={id}
            value={input[EFieldKeys.STRING_INPUT]}
          >
            {({ selectedItem, items, searchItems }) => (
              <AnyLevelDropdown
                title={messages.STRING_LEVEL_INPUT}
                placeholder={messages.STRING_LEVEL_INPUT_PLACEHOLDER}
                items={items}
                searchItems={searchItems}
                value={selectedItem}
                type={OBJECT_TYPES.STRING}
                disabled={isDisabled}
                error={error[EFieldKeys.STRING_INPUT]}
                supportHistory
                onSelect={option => handleInputChange(EFieldKeys.STRING_INPUT, option)}
                onReset={() => handleReset(EFieldKeys.STRING_INPUT)}
              />
            )}
          </BlockInputContainer>
        )}
      </div>
    </div>
  )
}

export default SwitchWidgetBlock
