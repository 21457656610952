import { type MouseEvent, type SyntheticEvent } from 'react'
import classNames from 'classnames'
import { produce } from 'immer'
import TextButton from 'components/buttons/TextButton'
import CheckBoxField from 'components/common/CheckBoxField'
import { MenuItem } from 'components/common/menu/menu'
import { Select } from 'components/common/select/select'
import { Tooltip } from 'components/common/tooltip/tooltip'
import {
  SCHEDULED_DAYS_LIST,
  SCHEDULED_LAST_DAY_LABEL,
  SCHEDULED_LAST_DAY_VALUE
} from './daysSelect.constants'
import { renderDaySelectValue } from './daysSelect.helpers'
import './days-select.scss'

type TProps = {
  selectedDays: number[]
  setSelectedDays: (selectedDays: number[]) => void
  error?: string
}

export const DaysSelect = ({ selectedDays, setSelectedDays, error }: TProps) => {
  const handleDayClick = (day: number) => {
    const updatedDays = produce(selectedDays, draft => {
      if (draft.includes(day)) {
        draft.splice(draft.indexOf(day), 1)
      } else {
        draft.push(day)
      }
    })

    setSelectedDays(updatedDays)
  }

  const handleSelectAll = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setSelectedDays(SCHEDULED_DAYS_LIST)
  }

  const handleUnselectAll = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setSelectedDays([])
  }

  return (
    <Tooltip
      id="wf-scheduled-days-select-tooltip"
      placement="top"
      title={renderDaySelectValue(selectedDays)}
    >
      <Select
        value={selectedDays}
        className={classNames('wf-scheduled-params-days-select', { 'has-error': !!error })}
        label="On days"
        renderValue={renderDaySelectValue}
        MenuProps={{
          MenuListProps: {
            className: 'wf-scheduled-params-days-select-menu-list'
          },
          className: 'wf-scheduled-params-days-select-menu'
        }}
        helperText={error ?? ''}
        error={!!error}
        multiple
      >
        <div className="control-buttons-wrapper">
          <TextButton appearance="secondary" onClick={handleUnselectAll}>
            unselect all
          </TextButton>
          <TextButton onClick={handleSelectAll}>select all</TextButton>
        </div>
        {SCHEDULED_DAYS_LIST.map(day => {
          const label = day === SCHEDULED_LAST_DAY_VALUE ? SCHEDULED_LAST_DAY_LABEL : day
          const key = `day-${day}`

          return (
            <MenuItem key={day} value={day} onClick={() => handleDayClick(day)}>
              <CheckBoxField
                id={key}
                label={label}
                checked={selectedDays.includes(day)}
                onClick={(e: MouseEvent<HTMLDivElement>) => e.preventDefault()}
              />
            </MenuItem>
          )
        })}
      </Select>
    </Tooltip>
  )
}
