import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import { CustomHeaders } from './customHeaders/customHeaders'
import { HiddenHeaders } from './hiddenHeaders/hiddenHeaders'
import './headers-section.scss'

type TProps = {
  block: IWorkflowBlock
  updateWorkflowBlockMeta: (meta: Partial<IWorkflowBlock['meta']>) => void
  handleInputChange: (fieldName: string, item: Partial<IWorkflowDropdownValue>) => void
  handleReset: (fieldName: string) => void
}

export const HeadersSection = ({
  block,
  updateWorkflowBlockMeta,
  handleInputChange,
  handleReset
}: TProps) => (
  <div className="wf-rest-api-block-headers-section">
    <CustomHeaders
      block={block}
      updateWorkflowBlockMeta={updateWorkflowBlockMeta}
      handleInputChange={handleInputChange}
      handleReset={handleReset}
    />
    <HiddenHeaders />
  </div>
)
