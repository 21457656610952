import React from 'react'
import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import CopyBlockContainer from 'containers/workflowBuilder/сopyBlocks/CopyBlockContainer'
import { Tooltip } from 'components/common/tooltip/tooltip'

const inputTargetTooltip = (
  <Tooltip
    id="copy-column-tooltip"
    className="copy-column-tooltip"
    title={messages.COPY_COLUMN_TOOLTIP}
    placement="top"
  >
    <i className="icon workflow-block-icon icon-workflow-info" />
  </Tooltip>
)

const inputSourceAttr = {
  inputSourceType: OBJECT_TYPES.COLUMN,
  inputSourceTitle: messages.COLUMN_LEVEL_INPUT,
  inputSourcePlaceholder: messages.COLUMN_LEVEL_INPUT_PLACEHOLDER
}

const inputTargetAttr = {
  inputTargetType: OBJECT_TYPES.BOARD,
  targetFieldKey: EFieldKeys.BOARD_INPUT,
  inputTargetTitle: messages.COPY_COLUMN_DESTINATION_BOARD,
  inputTargetPlaceholder: messages.COPY_COLUMN_DESTINATION_BOARD_PLACEHOLDER,
  inputTargetTooltip
}

const CopyColumn = props => {
  const { block, updateWorkflowBlock, changeWorkflowBlockInput } = props

  return (
    <CopyBlockContainer
      block={block}
      inputSourceAttr={inputSourceAttr}
      inputTargetAttr={inputTargetAttr}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
    />
  )
}

export default CopyColumn
