import { TRIGGER_EVENTS } from 'constants/workflows'
import { HelpNoteTooltip } from 'components/workflowBuilder/common/helpNoteTooltip/helpNoteTooltip'
import { type IWorkflowBlock } from 'features/workflow/workflow.types'
import { AdditionalTriggerParams } from './additionalTriggerParams/additionalTriggerParams'
import { EndpointTriggerUrl } from './endpointTriggerUrl/endpointTriggerUrl'
import { ScheduleParams } from './scheduleParams/scheduleParams'
import { type TTriggerBlockError } from './triggerBlock.types'
import { TriggerBlockAttributes } from './triggerBlockAttributes/triggerBlockAtrributes'
import './trigger-block.scss'

const WEBHOOK_HELP_NOTES = [
  {
    title: 'Help note',
    description: 'Here are some tips how to properly configure Webhook call event.'
  },
  {
    title:
      '1. Identify whether additional trigger’s parameters (trigger’s column, trigger’s card) are needed for the setup',
    description:
      'If trigger’s card is selected, then trigger’s column will be automatically selected by the system.'
  },
  {
    title: '2. Set up action block(s)',
    description: 'Select and configure action blocks in the workflow.'
  },
  {
    title: '3. Save Workflow',
    description: 'Endpoint URL is updated with the Workflow ID on saving.'
  },
  {
    title: '4. Copy Endpoint URL'
  },
  {
    title: '5. Propagate copied URL to the external system'
  }
]

type TProps = {
  block: IWorkflowBlock
}

export const TriggerBlock = ({ block: { id, meta, output, scheduleSettings, error } }: TProps) => {
  const hasAdditionalParameters = meta.eventType === TRIGGER_EVENTS.RECEIVE

  const isScheduleEvent = meta.eventType === TRIGGER_EVENTS.SCHEDULE

  return (
    <div className="workflow-trigger-block">
      <TriggerBlockAttributes id={id} output={output} meta={meta} />
      {hasAdditionalParameters && (
        <>
          <EndpointTriggerUrl />
          <AdditionalTriggerParams id={id} output={output} />
          <HelpNoteTooltip notes={WEBHOOK_HELP_NOTES} />
        </>
      )}
      {isScheduleEvent && (
        <ScheduleParams
          id={id}
          meta={meta}
          scheduleSettings={scheduleSettings}
          error={error as TTriggerBlockError}
        />
      )}
    </div>
  )
}
