import { EKeyCode } from 'constants/common'

export const DEFAULT_MAX_LENGTH = 1000

export const DEFAULT_RIBBON_CONFIG = {
  bold: true,
  italic: true,
  underline: true,
  list: true,
  fontSize: true,
  link: true
}

export const ARROWS_KEYS = [
  EKeyCode.LEFT_ARROW,
  EKeyCode.UP_ARROW,
  EKeyCode.RIGHT_ARROW,
  EKeyCode.DOWN_ARROW
]

export const LEVEL_DROPDOWN_CLASSNAME = 'insert-token-dropdown'
