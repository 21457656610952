import classNames from 'classnames'
import RibbonToggleButton from 'components/ribbonTools/RibbonToggleButton'
import './ribbon-text-sizes-dropdown.scss'

const ListItem = ({ children, fontSize, checked, onMouseDown, styles }) => (
  <li onMouseDown={onMouseDown} className="">
    <i className={classNames({ 'icon icon-checked-small': checked })} />
    <span className={classNames('item-text', styles)}>{children}</span>
    <span>{fontSize}</span>
  </li>
)

export const RibbonTextSizeDropdown = ({ listItems, selectItem }) => {
  return (
    <RibbonToggleButton
      id="text-sizes-dropdown"
      className="text-sizes-dropdown"
      iconRenderer={() => <i className="icon icon-text-size" />}
      dropUp
    >
      {listItems.map((item, index) => (
        <ListItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          onMouseDown={selectItem.bind(null, index)}
          fontSize={item.fontSize}
          checked={item.checked}
          styles={item.styles}
        >
          {item.message}
        </ListItem>
      ))}
    </RibbonToggleButton>
  )
}
