import React from 'react'
import classNames from 'classnames'
import AnyLevelDropdown from '../../../common/AnyLevelDropdown'
import BlockInputContainer from '../../../../containers/workflowBuilder/common/BlockInputContainer'

import RoundIconButton from '../../../buttons/RoundIconButton'
import '../../../../scss/workflowBuilder/common/StringInputsList/string-input-item.scss'

const StringInputItem = props => {
  const {
    block: { input, id, error, inputDefinition },
    fieldName,
    onDelete,
    handleInputChange,
    handleReset,
    isStaticInput,
    inputFieldPlaceholder
  } = props
  return (
    <div
      className={classNames('string-input-item', {
        'static-string-input-item': isStaticInput
      })}
    >
      <div className="input-container">
        <BlockInputContainer
          type={inputDefinition[fieldName].type}
          blockId={id}
          value={input[fieldName]}
        >
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              supportHistory
              placeholder={inputFieldPlaceholder}
              items={items}
              searchItems={searchItems}
              onSelect={handleInputChange.bind(null, fieldName)}
              onReset={handleReset.bind(null, fieldName)}
              value={selectedItem}
              type={inputDefinition[fieldName].type}
              error={error[fieldName]}
            />
          )}
        </BlockInputContainer>
      </div>
      <RoundIconButton
        size="small"
        className="icon-button button-close"
        onClick={() => onDelete(fieldName)}
      >
        <i className="icon icon-close icon-close-8ea3b1" />
      </RoundIconButton>
    </div>
  )
}

export default StringInputItem
