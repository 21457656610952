import { motion } from 'framer-motion'
import { Switcher } from 'components/common/switcher/switcher'
import './curl-mode-switcher.scss'

type TProps = {
  isCurlMode: boolean
  setIsCurlMode: (isCurlMode: boolean) => void
}

export const CurlModeSwitcher = ({ isCurlMode, setIsCurlMode }: TProps) => {
  return (
    <motion.div
      className="wf-rest-api-client-curl-mode-switcher"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="switcher-label">cURL</div>
      <Switcher checked={isCurlMode} onChange={event => setIsCurlMode(event.target.checked)} />
    </motion.div>
  )
}
