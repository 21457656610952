import { type TRecentBoard } from 'features/boards/recentBoards/model/recentBoards.types'
import { type IMyTasksTableFilters } from 'features/tasks/myTasksTable/components/filters/myTasksTableFilters.types'

type TUserPreferences = Record<
  string,
  Partial<{
    boardsWithUnhiddenColumns: string[]
    isSwipeNavigationHintShown: boolean
    isHelpSectionCollapsed: boolean
    presentationIntervalValue: number
    myTasksTableFilters: IMyTasksTableFilters
    recentBoards: TRecentBoard[]
  }>
>

export const getUserPreferences = () => {
  return JSON.parse(localStorage.getItem('userPreferences') || '{}') as TUserPreferences
}

export const setUserPreferences = ({
  userId,
  preferences
}: {
  userId: string
  preferences: Record<string, unknown>
}) => {
  const userPreferences = getUserPreferences()

  userPreferences[userId] = {
    ...userPreferences[userId],
    ...preferences
  }

  localStorage.setItem('userPreferences', JSON.stringify(userPreferences))
}
