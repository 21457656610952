import React from 'react'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

import BlockInputContainer from '../../../containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from '../../common/AnyLevelDropdown'

import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'

import '../../../scss/workflowBuilder/copyBlocks/copy-blocks.scss'

const CopyBoard = props => {
  const {
    block: { id, input, error },
    handleReset,
    handleInputChange
  } = props

  return (
    <div className="copy-blocks">
      <BlockInputContainer
        type={OBJECT_TYPES.BOARD}
        blockId={id}
        value={input[EFieldKeys.SOURCE_INPUT]}
      >
        {({ selectedItem, items, searchItems }) => (
          <AnyLevelDropdown
            supportHistory
            title={messages.BOARD_LEVEL_INPUT}
            placeholder={messages.BOARD_LEVEL_INPUT_PLACEHOLDER}
            items={items}
            searchItems={searchItems}
            onSelect={handleInputChange}
            onReset={handleReset}
            value={selectedItem}
            type={OBJECT_TYPES.BOARD}
            error={error[EFieldKeys.SOURCE_INPUT]}
          />
        )}
      </BlockInputContainer>
    </div>
  )
}

export default CopyBoard
