import { Component } from 'react'
import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import AddCardCommentBlock from 'components/workflowBuilder/addCardCommentBlock/AddCardCommentBlock'
import AddCardCommentOptions from 'components/workflowBuilder/addCardCommentBlock/AddCardCommentOptions'

export default class AddCardCommentBlockContainer extends Component {
  handleInputChange = (fieldName, item) => {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset = fieldName => {
    this.handleInputChange(fieldName, { id: null })
  }

  changeSelectOption = (propName, { value }) => {
    this.updateBlockMeta(propName, value)
  }

  updateBlockMeta = (key, fieldValue) => {
    const {
      updateWorkflowBlock,
      block: { id, meta }
    } = this.props

    if (meta[key] !== fieldValue) {
      updateWorkflowBlock({
        id,
        data: {
          meta: { ...meta, [key]: fieldValue },
          error: {}
        }
      })
    }
  }

  render() {
    const { block } = this.props
    const meta = block.meta || {}

    return (
      <AddCardCommentBlock
        block={block}
        sourceInputType={OBJECT_TYPES.CARD}
        sourceInputFieldLabel={messages.CARD_LEVEL_INPUT}
        sourceInputFieldPlaceholder={messages.ADD_COMMENT_LEVEL_INPUT_PLACEHOLDER}
        handleInputChange={this.handleInputChange}
        handleReset={this.handleReset}
      >
        <AddCardCommentOptions
          type={meta[EFieldKeys.TYPE]}
          block={block}
          updateBlockMeta={this.updateBlockMeta}
          handleInputChange={this.handleInputChange}
          handleReset={this.handleReset}
          changeSelectOption={this.changeSelectOption}
        />
      </AddCardCommentBlock>
    )
  }
}
