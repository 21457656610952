import messages from 'constants/messages'
import { TRIGGER_OBJECTS } from 'constants/workflows'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import WorkflowFilterSelector from 'components/workflowBuilder/common/WorkflowFilterSelector'
import FilterSelectorHeader from 'components/workflowBuilder/common/FilterSelectorHeader'
import 'scss/workflowBuilder/selectBlocks/select-widgets-from-filter.scss'

const FindCardBundleFromFilter = props => {
  const { block, tenantId, updateWorkflowBlock, changeWorkflowBlockInput } = props

  return (
    <div className="select-widgets-from-filter">
      <FilterSelectorHeader
        title={messages.FILTER_INPUT}
        description="Select existing filter or create a new one to specify the card group"
      />
      <WorkflowFilterSelector
        block={block}
        tenantId={tenantId}
        filterPropName={EFieldKeys.FILTER_UUID_SNAKE}
        filterObject={TRIGGER_OBJECTS.CARD}
        updateWorkflowBlock={updateWorkflowBlock}
        changeWorkflowBlockInput={changeWorkflowBlockInput}
      />
    </div>
  )
}

export default FindCardBundleFromFilter
