import { Component } from 'react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'

import { FinishBlock } from 'components/workflowBuilder/finishBlocks/FinishBlock'
import { OBJECT_TYPES } from 'constants/workflows'
import { OPEN_CARD_MODES } from 'helpers/workflowExecution/workflowMetaHelpers'
import { EFinishBlockValues } from 'components/workflowBuilder/finishBlocks/finishBlock.types'
import { produce } from 'immer'

const updateBlockOutput = ({ fieldValue, output }) => [
  {
    ...output[0],
    type: fieldValue === EFinishBlockValues.DISPLAY_BOARD ? OBJECT_TYPES.BOARD : OBJECT_TYPES.CARD
  }
]

const updateBlockInputDefinition = ({ fieldValue, inputDefinition }) => ({
  ...inputDefinition,
  [EFieldKeys.SOURCE_INPUT]: {
    ...inputDefinition[EFieldKeys.SOURCE_INPUT],
    type: fieldValue === EFinishBlockValues.DISPLAY_BOARD ? OBJECT_TYPES.BOARD : OBJECT_TYPES.CARD
  }
})

export default class FinishBlockContainer extends Component {
  constructor(props) {
    super(props)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.changeSelectOption = this.changeSelectOption.bind(this)
  }

  handleInputChange(item) {
    const {
      block: { input, id }
    } = this.props

    const fieldName = EFieldKeys.SOURCE_INPUT

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: id,
      outputId: item.id,
      oldOutputId: input[fieldName] || null
    })
  }

  handleReset() {
    this.handleInputChange({ id: null })
  }

  changeSelectOption({ value }) {
    this.updateBlockMeta(EFieldKeys.TYPE, value)
  }

  handleChangeBlockMetaValue = (key, value) => {
    const {
      updateWorkflowBlock,
      block: { meta, id }
    } = this.props

    updateWorkflowBlock({
      id,
      data: {
        meta: {
          ...meta,
          [key]: value
        }
      }
    })
  }

  updateBlockMeta(key, fieldValue) {
    const {
      updateWorkflowBlock,
      block: { id, meta, output, inputDefinition }
    } = this.props

    this.handleReset()

    if (meta[key] !== fieldValue) {
      const updatedMeta = produce(meta, draft => {
        draft[key] = fieldValue
        delete draft[EFieldKeys.WEB_LINK]

        if (fieldValue === EFinishBlockValues.DISPLAY_CARD) {
          draft[EFieldKeys.CARD_MODE] = OPEN_CARD_MODES.sameView
        } else {
          delete draft[EFieldKeys.CARD_MODE]
        }
      })

      updateWorkflowBlock({
        id,
        data: {
          meta: updatedMeta,
          error: {},
          inputDefinition: updateBlockInputDefinition({ fieldValue, inputDefinition }),
          output: updateBlockOutput({ fieldValue, output })
        }
      })
    }
  }

  render() {
    const { block } = this.props

    return (
      <FinishBlock
        block={block}
        handleReset={this.handleReset}
        handleInputChange={this.handleInputChange}
        changeSelectOption={this.changeSelectOption}
        handleChangeBlockMetaValue={this.handleChangeBlockMetaValue}
      />
    )
  }
}
