import React from 'react'

import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

import ToggleFlagContainer from '../../../containers/workflowBuilder/toggleFlagBlock/ToggleFlagContainer'

const radioFieldLabels = [messages.HIDE_COLUMN_OPTION, messages.UNHIDE_COLUMN_OPTION]

const HideColumn = props => {
  const { block, updateWorkflowBlock, changeWorkflowBlockInput } = props

  return (
    <ToggleFlagContainer
      block={block}
      inputType={OBJECT_TYPES.COLUMN}
      radioFieldKey={EFieldKeys.FLAGGED}
      radioFieldLabels={radioFieldLabels}
      inputFieldTitle={messages.COLUMN_LEVEL_INPUT}
      inputFieldPlaceholder={messages.COLUMN_LEVEL_INPUT_PLACEHOLDER}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
    />
  )
}

export default HideColumn
