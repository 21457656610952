import React from 'react'
import { Button } from '@praxie/shared'
import messages from 'constants/messages'
import { IconButton } from 'components/buttons/iconButton/iconButton'
import { Tooltip } from 'components/common/tooltip/tooltip'

const FilterConfigurationFooter = props => {
  const {
    onSave,
    onSaveAndApply,
    onCancel,
    onRemoveFilter,
    isApplyDisabled,
    isRemoveButtonShown,
    isRemoveButtonDisabled,
    isSaveAndApplyShown,
    isSaveAndApplyBusy,
    isSaveShown,
    isSaveBusy,
    isRemoveBusy
  } = props

  const disabled = isSaveAndApplyBusy || isSaveBusy || isRemoveBusy

  return (
    <div className="modal-footer-content">
      <div className="buttons-wrapper">
        {isRemoveButtonShown && (
          <Tooltip
            id="filter-remove-from-configuration-modal-tooltip"
            placement="top"
            title={isRemoveButtonDisabled ? messages.CANT_DELETE_FILTER_WITH_CONNECTIONS : ''}
            shouldWrapChildrenWithDiv
          >
            <IconButton
              appearance="secondary"
              iconSeparated={false}
              icon={<i className="icon icon-delete" />}
              onClick={onRemoveFilter}
              busy={isRemoveBusy}
              disabled={disabled || isRemoveButtonDisabled}
            >
              {messages.REMOVE_FILTER}
            </IconButton>
          </Tooltip>
        )}
        {isRemoveButtonShown && <div className="buttons-delimiter" />}
        <Button appearance="secondary" onClick={onCancel} disabled={disabled}>
          {messages.CANCEL}
        </Button>
        {isSaveShown && (
          <Button
            className="save-btn"
            autoFocus
            onClick={onSave}
            busy={isSaveBusy}
            disabled={disabled}
          >
            {messages.SAVE}
          </Button>
        )}
        {isSaveAndApplyShown && (
          <Button
            className="save-and-apply-btn"
            onClick={onSaveAndApply}
            autoFocus
            busy={isSaveAndApplyBusy}
            disabled={disabled || isApplyDisabled}
          >
            {messages.SAVE_AND_APPLY}
          </Button>
        )}
      </div>
    </div>
  )
}

export default FilterConfigurationFooter
