import { type JSX, type ReactNode } from 'react'
import classNames from 'classnames'
import 'scss/common/empty-state.scss'

type TProps = {
  as?: keyof JSX.IntrinsicElements
  imgSrc?: string
  imgClassName?: string
  children?: ReactNode
  show: boolean
  className?: string
  icon?: ReactNode
  iconClassName?: string
  firstRow?: ReactNode | string
  secondRow?: ReactNode | string
  'data-testid'?: string
}
const EmptyState = ({
  as: Component = 'p',
  show = false,
  className = '',
  iconClassName = '',
  firstRow = '',
  secondRow = '',
  children = null,
  icon = null,
  imgSrc,
  'data-testid': dataTestId,
  imgClassName
}: TProps) => {
  if (!show) return null

  return (
    <div className={classNames('empty-state-component', className)} data-testid={dataTestId}>
      <i className={classNames('icon', iconClassName)} />
      {imgSrc && <img className={imgClassName} src={imgSrc} alt="Empty state" />}
      {icon}
      {firstRow && <Component className="first-row">{firstRow}</Component>}
      {secondRow && <Component className="second-row">{secondRow}</Component>}
      {children}
    </div>
  )
}

export default EmptyState
