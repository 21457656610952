import { add } from 'date-fns'
import Cookies from 'js-cookie'
import { GOOGLE_SUCCESSFUL_LOGIN } from 'features/widgets/eventBus/eventBus.constants'

export class _GoogleService {
  private readonly _DEFAULT_SERVICES_TO_LOAD = ['client']
  private readonly _ACCESS_TOKEN_COOKIE_NAME = 'praxie-google-auth-cookie'
  private readonly _CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID
  private readonly _API_KEY = import.meta.env.VITE_GOOGLE_API_KEY
  private readonly _SCOPE = 'https://www.googleapis.com/auth/drive.readonly'
  private readonly _DISCOVERY_DOCS = ['https://sheets.googleapis.com/$discovery/rest?version=v4']

  private _tokenClient: google.accounts.oauth2.TokenClient | null = null
  private _accessToken: string | undefined
  private _authCallback: () => void = () => {}

  constructor() {
    this._initTokenClient()
  }

  private _parseExpiration = (expirationInSeconds: number) => {
    return add(new Date(), { seconds: expirationInSeconds })
  }

  private _dispatchAuthEvent = () => {
    const event = new CustomEvent(GOOGLE_SUCCESSFUL_LOGIN, { detail: { type: 'successful' } })

    document.dispatchEvent(event)
  }

  private _initTokenClient = () => {
    this._accessToken = Cookies.get(this._ACCESS_TOKEN_COOKIE_NAME)

    if (!window.google) return

    this._tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this._CLIENT_ID,
      scope: this._SCOPE,
      callback: response => {
        if (response.error) {
          throw new Error(response.error)
        } else {
          this._accessToken = response.access_token

          Cookies.set(this._ACCESS_TOKEN_COOKIE_NAME, this._accessToken, {
            expires: this._parseExpiration(parseInt(response.expires_in, 10))
          })

          this._authCallback()
          this._dispatchAuthEvent()
        }
      }
    })
  }

  private _initSpreadSheetsClient = () => {
    if (gapi.client.hasOwnProperty('sheets')) return Promise.resolve()

    if (!this._accessToken) {
      throw new Error('NO_GOOGLE_ACCESS_TOKEN_FOUND')
    }

    gapi.client.setToken({ access_token: this._accessToken })

    return gapi.client.init({ apiKey: this._API_KEY, discoveryDocs: this._DISCOVERY_DOCS })
  }

  auth = () => {
    return new Promise<void>(resolve => {
      if (this._accessToken) {
        resolve()
      } else if (this._tokenClient) {
        this._authCallback = resolve
        this._tokenClient.requestAccessToken()
      }
    })
  }

  loadServices = (services: string[]) => {
    const needToLoad = services.filter(service => !gapi.hasOwnProperty(service))

    if (!needToLoad.length) return Promise.resolve()

    return new Promise(resolve => {
      gapi.load(needToLoad.join(':'), resolve)
    })
  }

  init = (services: string[] = []): Promise<typeof this._accessToken> => {
    if (!gapi) return Promise.reject(new Error('GAPI_DONT_LOADED'))

    return this.loadServices([...this._DEFAULT_SERVICES_TO_LOAD, ...services])
      .then(this.auth)
      .then(this._initSpreadSheetsClient)
      .then(() => this._accessToken)
  }
}

export const GoogleService = new _GoogleService()
