import React, { Component } from 'react'

import CopyBoard from '../../../components/workflowBuilder/сopyBlocks/CopyBoard'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

export default class CopyBoardContainer extends Component {
  constructor(props) {
    super(props)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  handleInputChange(item) {
    const { block } = this.props

    const fieldName = EFieldKeys.SOURCE_INPUT

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset() {
    this.handleInputChange({ id: null })
  }

  render() {
    const { block } = this.props

    return (
      <CopyBoard
        block={block}
        handleReset={this.handleReset}
        handleInputChange={this.handleInputChange}
      />
    )
  }
}
