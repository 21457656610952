import { useState } from 'react'
import { motion } from 'framer-motion'
import InfoIcon from 'assets/images/icons/info.svg?react'
import './help-note-tooltip.scss'

type TNote = { title: string; description?: string }

type TNoteBlockProps = TNote & {
  showIcon: boolean
}

const NoteBlock = ({ title, description, showIcon }: TNoteBlockProps) => (
  <div className="block">
    <div className="title">
      {showIcon && <InfoIcon className="icon" />}
      <span>{title}</span>
    </div>
    {description && <span className="description">{description}</span>}
  </div>
)

type TProps = {
  notes: TNote[]
}

export const HelpNoteTooltip = ({ notes }: TProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <motion.div
      className="workflow-help-note-tooltip"
      initial={{ opacity: 0, height: 50, x: '-50%' }}
      animate={{ opacity: 1, width: 50, x: 0 }}
      whileHover={{ height: 580, width: 250 }}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <motion.div
        key={isExpanded ? 'show' : 'hide'}
        className="content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.15 }}
      >
        {isExpanded ? (
          notes.map((block, index) => (
            <NoteBlock
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              title={block.title}
              description={block.description}
              showIcon={index === 0}
            />
          ))
        ) : (
          <InfoIcon className="icon" />
        )}
      </motion.div>
    </motion.div>
  )
}
