import { type SelectChangeEvent } from '@mui/material/Select/Select'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { Select } from 'components/common/select/select'
import { Switcher } from 'components/common/switcher/switcher'
import { type IWorkflowBlock } from 'features/workflow/workflow.types'
import './retry-policy-section.scss'

const TIMEOUT_OPTIONS = [
  { label: '1 second', value: 1 },
  { label: '2 seconds', value: 2 },
  { label: '3 seconds', value: 3 },
  { label: '4 seconds', value: 4 },
  { label: '5 seconds', value: 5 },
  { label: '10 seconds', value: 10 },
  { label: '15 seconds', value: 15 },
  { label: '20 seconds', value: 20 },
  { label: '25 seconds', value: 25 },
  { label: '30 seconds', value: 30 },
  { label: '35 seconds', value: 35 },
  { label: '40 seconds', value: 40 },
  { label: '45 seconds', value: 45 },
  { label: '50 seconds', value: 50 },
  { label: '55 seconds', value: 55 },
  { label: '60 seconds', value: 60 },
  { label: '70 seconds', value: 70 },
  { label: '80 seconds', value: 80 },
  { label: '90 seconds', value: 90 },
  { label: '100 seconds', value: 100 },
  { label: '110 seconds', value: 110 },
  { label: '120 seconds', value: 120 }
]

const RETRIES_OPTIONS = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 }
]

const RETRY_TIMEOUT_FACTOR = [
  { label: '1 second', value: 1 },
  { label: '2 seconds', value: 2 },
  { label: '3 seconds', value: 3 },
  { label: '4 seconds', value: 4 },
  { label: '5 seconds', value: 5 }
]

type TProps = {
  block: IWorkflowBlock
  updateBlockMeta: (key: EFieldKeys, fieldValue: unknown) => void
}

export const RetryPolicySection = ({ block, updateBlockMeta }: TProps) => {
  return (
    <div className="wf-rest-api-block-retry-policy-section">
      <Switcher
        id="retry-policy-switcher"
        className="retry-policy-switcher"
        checked={block.meta[EFieldKeys.IS_RETRY_ENABLED] as boolean}
        onChange={event => updateBlockMeta(EFieldKeys.IS_RETRY_ENABLED, event.target.checked)}
      />
      <div className="section-content">
        <Select
          size={40}
          className="timeout"
          label="Timeout"
          MenuProps={{ className: 'retry-policy-timeout-select-menu' }}
          value={block.meta[EFieldKeys.TIMEOUT] as number}
          options={TIMEOUT_OPTIONS}
          disabled={!block.meta[EFieldKeys.IS_RETRY_ENABLED]}
          onChange={({ target }: SelectChangeEvent<unknown>) =>
            updateBlockMeta(EFieldKeys.TIMEOUT, target.value as number)
          }
        />
        <Select
          size={40}
          className="retries"
          label="Retries"
          value={block.meta[EFieldKeys.RETRIES] as number}
          options={RETRIES_OPTIONS}
          disabled={!block.meta[EFieldKeys.IS_RETRY_ENABLED]}
          onChange={({ target }: SelectChangeEvent<unknown>) =>
            updateBlockMeta(EFieldKeys.RETRIES, target.value as number)
          }
        />
        <Select
          size={40}
          className="retry-timeout-factor"
          label="Retry timeout factor"
          value={block.meta[EFieldKeys.RETRY_TIMEOUT_FACTOR] as number}
          options={RETRY_TIMEOUT_FACTOR}
          disabled={!block.meta[EFieldKeys.IS_RETRY_ENABLED]}
          onChange={({ target }: SelectChangeEvent<unknown>) =>
            updateBlockMeta(EFieldKeys.RETRY_TIMEOUT_FACTOR, target.value as number)
          }
        />
      </div>
      <div className="hint-message">
        Each retry increases the workflow execution time exponentially based on the chosen timeout
        factor. For example, 3 retries with a factor of 3 will wait 3, 9, 27 seconds between
        attempts.
      </div>
    </div>
  )
}
