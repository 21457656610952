import { useEffect, useState } from 'react'
import classNames from 'classnames'
import CloseIcon from 'assets/images/icons/cross.svg?react'
import { TimeInput } from 'components/common/timeInput/timeInput'
import { getDefaultISOTime } from '../../scheduleParams.helpers'
import './time-slot-item.scss'

type TProps = {
  time: string
  onChange: (time: string) => void
  onDelete: () => void
  isDeleteDisabled?: boolean
  error?: string
}

export const TimeSlotItem = ({ time, onChange, onDelete, isDeleteDisabled, error }: TProps) => {
  const [inputValue, setInputValue] = useState(time)

  useEffect(() => {
    setInputValue(time)
  }, [time])

  const onInputBlur = () => {
    const value = inputValue || getDefaultISOTime()

    setInputValue(value)
    onChange(value)
  }

  const handleDeleteButtonClick = () => {
    if (!isDeleteDisabled) onDelete()
  }

  return (
    <div className="wf-triggers-time-slot-item">
      <div className="input-wrapper">
        <TimeInput
          value={inputValue}
          error={!!error}
          errorMessage={error}
          className="schedule-time-input"
          onChange={setInputValue}
          onBlur={onInputBlur}
        />
      </div>
      <div
        className={classNames('close-icon', { disabled: isDeleteDisabled })}
        onClick={handleDeleteButtonClick}
      >
        <CloseIcon />
      </div>
    </div>
  )
}
