import React, { Component } from 'react'

import messages from '../../../constants/messages'
import BlockInputContainer from '../common/BlockInputContainer'
import AnyLevelDropdown from '../../../components/common/AnyLevelDropdown'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from '../../../constants/workflows'
import '../../../scss/workflowBuilder/moveCardBlock/move-card-to-board.scss'

export default class MoveCardBlockContainer extends Component {
  handleInputChange(fieldName, item) {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset(fieldName) {
    this.handleInputChange(fieldName, { id: null })
  }

  render() {
    const {
      block: { id, error, input }
    } = this.props

    const isDisabled = !input[EFieldKeys.SOURCE_INPUT]

    return (
      <div className="move-card-to-board">
        <BlockInputContainer
          type={OBJECT_TYPES.CARD}
          blockId={id}
          value={input[EFieldKeys.SOURCE_INPUT]}
        >
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              supportHistory
              title={messages.CARD_LEVEL_INPUT}
              placeholder={messages.CARD_LEVEL_INPUT_PLACEHOLDER}
              items={items}
              searchItems={searchItems}
              onSelect={this.handleInputChange.bind(this, EFieldKeys.SOURCE_INPUT)}
              onReset={this.handleReset.bind(this, EFieldKeys.SOURCE_INPUT)}
              value={selectedItem}
              type={OBJECT_TYPES.COLUMN}
              error={error[EFieldKeys.SOURCE_INPUT]}
            />
          )}
        </BlockInputContainer>
        <div className="bord-selection-block">
          <BlockInputContainer
            type={OBJECT_TYPES.BOARD}
            blockId={id}
            value={input[EFieldKeys.BOARD_INPUT]}
          >
            {({ selectedItem, items, searchItems }) => (
              <AnyLevelDropdown
                supportHistory
                title={messages.TARGET_BOARD_LEVEL_INPUT}
                placeholder={messages.TARGET_BOARD_LEVEL_INPUT_PLACEHOLDER}
                items={items}
                searchItems={searchItems}
                onSelect={this.handleInputChange.bind(this, EFieldKeys.BOARD_INPUT)}
                onReset={this.handleReset.bind(this, EFieldKeys.BOARD_INPUT)}
                value={selectedItem}
                type={OBJECT_TYPES.BOARD}
                disabled={isDisabled}
                error={error[EFieldKeys.BOARD_INPUT]}
              />
            )}
          </BlockInputContainer>
        </div>
      </div>
    )
  }
}
