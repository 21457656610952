import classNames from 'classnames'
import { IconButton } from 'components/buttons/iconButton/iconButton'

const CreateBoardButton = props => (
  <IconButton
    appearance="secondary"
    iconSeparated={false}
    className={classNames('board-menu-add-board-button hidden-on-md hidden-on-sm', props.className)}
    icon={<i className="icon icon-round-plus-6c7e8a" />}
    onClick={props.onClick}
  >
    {props.text}
  </IconButton>
)

export default CreateBoardButton
