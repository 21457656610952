import { OBJECT_TYPES } from 'constants/workflows'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'

type TProps = {
  block: IWorkflowBlock
  type: OBJECT_TYPES
  fieldName: string
  title?: string
  placeholder: string
  onSelect: (item: Partial<IWorkflowDropdownValue>) => void
  onReset: () => void
}

export const DropdownInput = ({
  type,
  block,
  fieldName,
  title,
  placeholder,
  onSelect,
  onReset
}: TProps) => (
  <BlockInputContainer type={type} blockId={block.id} value={block.input[fieldName]}>
    {({
      selectedItem,
      items,
      searchItems
    }: {
      selectedItem?: IWorkflowDropdownValue
      items: IWorkflowDropdownValue[]
      searchItems: (search: string) => void
    }) => (
      <AnyLevelDropdown
        type={type}
        title={title}
        items={items}
        value={selectedItem}
        searchItems={searchItems}
        placeholder={placeholder}
        error={block.error[fieldName]}
        supportHistory
        onSelect={onSelect}
        onReset={onReset}
      />
    )}
  </BlockInputContainer>
)
