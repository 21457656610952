import React, { Component } from 'react'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import ObjectAttributeSelectorContainer from 'containers/workflowBuilder/common/ObjectAttributeSelectorContainer'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import GET_WIDGET_DATA_BLOCKS_CONFIG from 'helpers/workflowWidgetDataBlock/widgetAttributesConfig'
import { Tooltip } from 'components/common/tooltip/tooltip'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import 'scss/workflowBuilder/widgetDataBlocks/widget-data-blocks.scss'

class GetWidgetDataBlock extends Component {
  componentDidUpdate(prevProps) {
    const { block } = this.props
    const { input } = block
    if (input[EFieldKeys.SOURCE_INPUT] !== prevProps.block.input[EFieldKeys.SOURCE_INPUT]) {
      const output = input[EFieldKeys.SOURCE_INPUT]
        ? {}
        : {
            [EFieldKeys.PARAMS]: { x: 1, y: 1 }
          }
      this.onOutputChange(output)
    }
  }

  onOutputChange = outputValue => {
    this.updateBlockMeta(outputValue)
  }

  onInputSelect = item => {
    const fieldName = EFieldKeys.SOURCE_INPUT
    const { block } = this.props
    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item ? item.id : null,
      oldOutputId: block.input[fieldName] || null
    })
  }

  onReset = () => {
    this.onInputSelect(null)
  }

  updateBlockMeta(value) {
    const { updateWorkflowBlock, block } = this.props
    const meta = { ...block.meta, ...value }
    updateWorkflowBlock({ id: block.id, data: { meta, error: {} } })
  }

  render() {
    const { block, inputType, inputFieldLabel, inputFieldPlaceholder, outputFieldPlaceholder } =
      this.props
    const { error, input, meta, id } = block
    const inputValue = input && input[EFieldKeys.SOURCE_INPUT]
    const outputValue = meta || {}
    const showTooltip =
      outputValue[EFieldKeys.ATTRIBUTE] === GET_WIDGET_DATA_BLOCKS_CONFIG.UBF.value
    return (
      <div className="workflow-actions-content widget-data-block">
        <BlockInputContainer type={inputType} blockId={id} value={inputValue}>
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              title={inputFieldLabel}
              placeholder={inputFieldPlaceholder}
              items={items}
              onSelect={this.onInputSelect}
              onReset={this.onReset}
              value={selectedItem}
              type={inputType}
              searchItems={searchItems}
              error={error[EFieldKeys.SOURCE_INPUT]}
              supportHistory
            />
          )}
        </BlockInputContainer>
        <div className="input-row-container">
          <p className="input-field-label">{messages.GET_DATA_FROM}</p>
          {showTooltip && (
            <Tooltip
              id="get-widget-data-tooltip"
              className="get-widget-data-tooltip"
              title={messages.GET_WIDGET_DATA_TOOLTIP}
              placement="top"
            >
              <i className="icon workflow-block-icon icon-workflow-info" />
            </Tooltip>
          )}
        </div>
        <ObjectAttributeSelectorContainer
          type={inputType}
          disabled={!inputValue}
          objectAttribute={outputValue}
          placeholder={outputFieldPlaceholder}
          attributeTypes={GET_WIDGET_DATA_BLOCKS_CONFIG}
          onChangeAttribute={this.onOutputChange}
          error={error[EFieldKeys.ATTRIBUTE]}
        />
      </div>
    )
  }
}

export default GetWidgetDataBlock
