import React, { Component } from 'react'

import AnyLevelDropdown from '../../common/AnyLevelDropdown'
import MoveColumnOptions from './MoveColumnOptions'
import BlockInputContainer from '../../../containers/workflowBuilder/common/BlockInputContainer'

import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from '../../../constants/workflows'
import messages from '../../../constants/messages'

import '../../../scss/workflowBuilder/moveColumnBlock/move-column-block.scss'

export default class MoveColumnBlock extends Component {
  shouldComponentUpdate(nextProps) {
    const { block, columnType, columnNumber } = nextProps

    // eslint-disable-next-line react/destructuring-assignment
    const isColumnTypeChanged = columnType !== this.props.columnType
    // eslint-disable-next-line react/destructuring-assignment
    const isColumnNumberChanged = columnNumber !== this.props.columnNumber
    // eslint-disable-next-line react/destructuring-assignment
    const isBlockChanged = block !== this.props.block

    return isColumnTypeChanged || isBlockChanged || isColumnNumberChanged
  }

  render() {
    const {
      block,
      columnType,
      columnNumber,
      handleReset,
      changeColumnType,
      handleInputChange,
      changeColumnNumber
    } = this.props

    const isDisabled = !block.input[EFieldKeys.SOURCE_INPUT]

    return (
      <div className="move-column-block">
        <BlockInputContainer
          type={OBJECT_TYPES.COLUMN}
          blockId={block.id}
          value={block.input[EFieldKeys.SOURCE_INPUT]}
        >
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              title={messages.COLUMN_LEVEL_INPUT}
              placeholder={messages.COLUMN_LEVEL_INPUT_PLACEHOLDER}
              items={items}
              searchItems={searchItems}
              value={selectedItem}
              type={OBJECT_TYPES.COLUMN}
              error={block.error[EFieldKeys.SOURCE_INPUT]}
              supportHistory
              /* eslint-disable-next-line react/jsx-no-bind */
              onSelect={handleInputChange.bind(null, EFieldKeys.SOURCE_INPUT)}
              /* eslint-disable-next-line react/jsx-no-bind */
              onReset={handleReset.bind(null, EFieldKeys.SOURCE_INPUT)}
            />
          )}
        </BlockInputContainer>
        <MoveColumnOptions
          isDisabled={isDisabled}
          block={block}
          columnType={columnType}
          columnNumber={columnNumber}
          handleReset={handleReset}
          changeColumnType={changeColumnType}
          changeColumnNumber={changeColumnNumber}
        />
      </div>
    )
  }
}
