import React, { Component } from 'react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import messages from 'constants/messages'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import ObjectAttributeSelectorContainer from 'containers/workflowBuilder/common/ObjectAttributeSelectorContainer'
import DEFINE_STRING_BLOCKS_CONFIG from 'helpers/workflowDefineStringBlocks/defineStringAttributesConfig'
import { Tooltip } from 'components/common/tooltip/tooltip'
import AnyLevelDropdown from '../../common/AnyLevelDropdown'
import 'scss/workflowBuilder/widgetDataBlocks/widget-data-blocks.scss'

class UpdateWidgetDataBlock extends Component {
  onInputSelect = entity => {
    const fieldName = EFieldKeys.UBF_CELL
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: entity ? entity.id : null,
      oldOutputId: block.input[fieldName] || null
    })
    if (entity) {
      this.onOutputChange({ [EFieldKeys.PARAMS]: { x: 1, y: 1 } })
    } else {
      this.onOutputChange({
        [EFieldKeys.SOURCE_INPUT]: null,
        [EFieldKeys.PARAMS]: { x: 1, y: 1 }
      })
    }
  }

  onOutputChange = value => {
    const { updateWorkflowBlock, block } = this.props

    const meta = { ...block.meta, ...value }
    updateWorkflowBlock({ id: block.id, data: { meta, error: {} } })
  }

  onSourceReset = () => {
    this.onInputSelect(null)
  }

  onOutputReset = () => {
    this.onOutputChange({
      [EFieldKeys.SOURCE_INPUT]: null,
      [EFieldKeys.PARAMS]: { x: 1, y: 1 }
    })
  }

  onOutputSelect = entity => {
    const fieldName = EFieldKeys.SOURCE_INPUT
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: entity ? entity.id : null,
      oldOutputId: block.input[fieldName] || null
    })
    this.onOutputChange({ [EFieldKeys.SOURCE_INPUT]: entity })
  }

  render() {
    const {
      block,
      inputType,
      inputFieldLabel,
      inputFieldPlaceholder,
      outputType,
      outputFieldLabel,
      outputFieldPlaceholder
    } = this.props
    const { error, input, meta, id } = block
    const inputValue = input && input[EFieldKeys.UBF_CELL]
    const outputValue = meta || {}
    const targetWidget = input && input[EFieldKeys.SOURCE_INPUT]
    return (
      <div className="workflow-actions-content widget-data-block">
        <BlockInputContainer type={inputType} blockId={id} value={inputValue}>
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              title={inputFieldLabel}
              placeholder={inputFieldPlaceholder}
              items={items}
              onSelect={this.onInputSelect}
              onReset={this.onSourceReset}
              value={selectedItem}
              type={inputType}
              searchItems={searchItems}
              error={error[EFieldKeys.UBF_CELL]}
              supportHistory
            />
          )}
        </BlockInputContainer>
        <div className="target-widget-container">
          <BlockInputContainer type={outputType} blockId={id} value={targetWidget}>
            {({ selectedItem, items, searchItems }) => (
              <AnyLevelDropdown
                disabled={!inputValue}
                title={outputFieldLabel}
                placeholder={outputFieldPlaceholder}
                items={items}
                onSelect={this.onOutputSelect}
                onReset={this.onOutputReset}
                value={selectedItem}
                type={outputType}
                searchItems={searchItems}
                error={error[EFieldKeys.SOURCE_INPUT]}
                supportHistory
              />
            )}
          </BlockInputContainer>
          <div className="input-row-container">
            <p className="input-field-label">{messages.DEFINE_CELL_TO_UPDATE}</p>
            <Tooltip
              id="get-widget-data-tooltip"
              className="get-widget-data-tooltip"
              title={messages.GET_WIDGET_DATA_TOOLTIP}
              placement="top"
            >
              <i className="icon workflow-block-icon icon-workflow-info" />
            </Tooltip>
          </div>
          <div className="input-row-container">
            <ObjectAttributeSelectorContainer
              type={inputType}
              disabled={!targetWidget}
              objectAttribute={{
                params: outputValue.params,
                attribute: 'UBF'
              }}
              showAttributeSelector={false}
              attributeTypes={DEFINE_STRING_BLOCKS_CONFIG}
              onChangeAttribute={this.onOutputChange}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default UpdateWidgetDataBlock
