import {
  TOGGLE_APP_DELETE_MODAL,
  SET_CURRENT_APP,
  TOGGLE_CURRENT_APP_LOADING,
  TOGGLE_APP_HISTORY_MODAL,
  TOGGLE_TOP_MENU_OPENED,
  IMPORT_APP_INDICATOR_TYPE
} from 'constants/actionTypes'
import { deleteApp as deleteAppApi, processImportApp } from 'api/appAPI'
import {
  getApp,
  getPublishedApps as getPublishedAppsApi,
  getAllCategoriesApps as getAllCategoriesAppsApi,
  getPublishedCategoriesApps as getPublishedCategoriesAppsApi,
  getFilteredCategoryApps as getFilteredCategoryAppsApi,
  getFilteredCollectionsApps as getFilteredCollectionsAppsApi
} from 'api/bindAppAPI'
import { navigateToRecentBoard } from 'helpers/routesHelpers'
import { QueryClientService } from 'services/common/queryClient.service'
import { UserAccessService } from 'services/userAccess/userAccess.service'
import { boardMenuRequest } from './profileActions'
import { importBundle } from './boardActions'

export const toggleAppDeleteModal = payload => ({
  type: TOGGLE_APP_DELETE_MODAL,
  payload
})

export const deleteApp = payload => async (dispatch, getState) => {
  await deleteAppApi(payload)
  await dispatch(boardMenuRequest())

  void QueryClientService.queryClient.invalidateQueries({
    queryKey: UserAccessService.getTenantAppListQueryKey(payload.tenantId)
  })

  const {
    board: { currentBoard }
  } = getState()

  if (payload.appId === currentBoard.appId) {
    navigateToRecentBoard()
  }
}

export const setCurrentApp = payload => ({ type: SET_CURRENT_APP, payload })

export const toggleCurrentAppLoading = payload => ({
  type: TOGGLE_CURRENT_APP_LOADING,
  payload
})

export const getCurrentApp = payload => async dispatch => {
  try {
    dispatch(toggleCurrentAppLoading(true))

    const app = await dispatch(
      getApp({
        variables: payload,
        expand: { navItems: true, app: false }
      })
    )

    if (app) {
      const {
        navItems,
        showCardName,
        showExportTools,
        showBoardsInMenu,
        allowToEditCardInAppView
      } = app

      dispatch(
        setCurrentApp({
          navItems,
          showCardName,
          showExportTools,
          showBoardsInMenu,
          allowToEditCardInAppView
        })
      )
    }
  } catch (err) {
    console.error(err)
  }

  dispatch(toggleCurrentAppLoading(false))
}

export const getPublishedApps = payload => dispatch =>
  dispatch(getPublishedAppsApi({ variables: payload }))

export const getAllCategoriesApps = payload => dispatch =>
  dispatch(getAllCategoriesAppsApi({ variables: payload }))

export const getPublishedCategoriesApps = payload => dispatch =>
  dispatch(getPublishedCategoriesAppsApi({ variables: payload }))

export const getFilteredCategoryApps = payload => dispatch =>
  dispatch(getFilteredCategoryAppsApi({ variables: payload }))

export const getFilteredCollectionsApps = payload => dispatch =>
  dispatch(getFilteredCollectionsAppsApi({ variables: payload }))

export const toggleAppHistoryModal = payload => ({
  type: TOGGLE_APP_HISTORY_MODAL,
  payload
})

export const toggleTopMenuOpened = payload => ({
  type: TOGGLE_TOP_MENU_OPENED,
  payload
})

export const importApp = payload =>
  importBundle(processImportApp, IMPORT_APP_INDICATOR_TYPE)(payload)
