import { requestWrapper, url } from 'helpers/fetchHelpers'
import {
  extractApp,
  extractAppsCategories,
  extractAppsLibrary,
  extractAppStoreInfo,
  extractFilteredAppsCategories,
  extractFilteredAppsCollections,
  extractPurchasedApp,
  extractRelatedApps
} from 'graphql/dataExtractors/appExtractors'
import {
  getAppQuery,
  getAppStoreInfoQuery,
  getPublishedAppsQuery,
  getPublishedAppQuery,
  getFilteredCategoryAppsQuery,
  getAllCategoriesAppsQuery,
  getFilteredCollectionsAppsQuery,
  getPurchasedAppsQuery,
  getRelatedAppsQuery
} from 'graphql/queries/appQueries'
import graphqlRequestWrapper from '../graphql/common/requestHelpers'

export function createApp({ tenantId, data }) {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/app`,
    type: 'POST',
    payload: data
  })
}

export const getApp = ({ variables, expand, organizationId }) =>
  graphqlRequestWrapper({
    query: getAppQuery(expand),
    variables: { organizationId, ...variables }
  }).then(extractApp)

export function getAppRequest({ tenantId, appId }) {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/app/${appId}`,
    type: 'GET'
  })
}

export function updateApp({ tenantId, appId, data }) {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/app/${appId}`,
    type: 'PUT',
    payload: data
  })
}

export function deleteApp({ tenantId, appId, withBoards }) {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/app/${appId}${withBoards ? '?cascade=true' : ''}`,
    type: 'DELETE',
    payload: {}
  })
}

export function createAppMeta({ tenantId, appId, data }) {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/app/${appId}/appstore`,
    type: 'POST',
    payload: data
  })
}

export function updateAppMeta({ tenantId, appId, data }) {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/app/${appId}/appstore`,
    type: 'PUT',
    payload: data
  })
}

export function uploadAppMetaFile({ id, uploadData, file }) {
  const data = new FormData()

  Object.entries(uploadData.fields).forEach(([key, value]) => {
    data.append(key, value)
  })
  data.append('file', file)

  return requestWrapper({
    rUrl: uploadData.url,
    type: 'POST',
    payload: data,
    withCredentials: false,
    contentType: 'multipart/form-data'
  }).then(() => ({ ...uploadData, id }))
}

export function publishApp({ tenantId, appId }) {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/app/${appId}/appstore/publish`,
    type: 'PUT',
    payload: {}
  })
}

export function unpublishApp({ tenantId, appId }) {
  return requestWrapper({
    rUrl: `${url}tenant/${tenantId}/app/${appId}/appstore/unpublish`,
    type: 'PUT',
    payload: {}
  })
}

export const getAppStoreInfo = ({ variables, expand }) =>
  graphqlRequestWrapper({
    fetchPolicy: 'no-cache',
    query: getAppStoreInfoQuery(expand),
    variables
  }).then(extractAppStoreInfo)

export const getPublishedApp = ({ variables, organizationId }) =>
  graphqlRequestWrapper({
    query: getPublishedAppQuery,
    variables: { organizationId, ...variables }
  }).then(extractAppsLibrary)

export function getTags({ size = 6, text }) {
  return requestWrapper({
    withCanceler: true,
    rUrl: `${url}app/tags/suggestion`,
    type: 'GET',
    payload: { size, text }
  })
}

export function getPublishedApps({ organizationId }) {
  return graphqlRequestWrapper({
    query: getPublishedAppsQuery,
    variables: { organizationId }
  }).then(extractAppsLibrary)
}

export function getAllCategoriesApps({ variables, organizationId }) {
  return graphqlRequestWrapper({
    query: getAllCategoriesAppsQuery,
    variables: { organizationId, ...variables }
  }).then(extractAppsCategories)
}

export function getPublishedCategoryApps({ variables, organizationId }) {
  return graphqlRequestWrapper({
    query: getFilteredCategoryAppsQuery,
    variables: { organizationId, ...variables }
  }).then(extractFilteredAppsCategories)
}

export function getFilteredCategoryApps({ organizationId, variables: { filter, limit, after } }) {
  return graphqlRequestWrapper({
    query: getFilteredCategoryAppsQuery,
    variables: { organizationId, filter, limit, after }
  }).then(extractFilteredAppsCategories)
}

export function getFilteredCollectionsApps({
  organizationId,
  variables: { filter, limit, after }
}) {
  return graphqlRequestWrapper({
    query: getFilteredCollectionsAppsQuery,
    variables: { organizationId, filter, limit, after }
  }).then(extractFilteredAppsCollections)
}

export function fetchCollectionAppsCount({ organizationId, variables: { filter } }) {
  return graphqlRequestWrapper({
    query: getFilteredCollectionsAppsQuery,
    variables: { organizationId, filter }
  }).then(extractFilteredAppsCollections)
}

export function getPurchasedApps({ organizationId, variables: { filter } }) {
  return graphqlRequestWrapper({
    query: getPurchasedAppsQuery,
    variables: { organizationId, filter }
  }).then(extractPurchasedApp)
}

export function getRelatedApps({ organizationId, variables }) {
  return graphqlRequestWrapper({
    query: getRelatedAppsQuery,
    variables: { organizationId, ...variables }
  }).then(extractRelatedApps)
}

export function processImportApp(payload) {
  return requestWrapper({
    rUrl: `${url}tenant/${payload.tenantId}/app/${payload.appId}/import`,
    type: 'POST',
    payload: {}
  })
}
