import type { ChangeEvent } from 'react'

import { type IWorkflowBlock } from 'features/workflow/workflow.types'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { TextField } from 'components/common/textField/textField'
import './object-output-key-field.scss'

type TProps = {
  blockMeta: IWorkflowBlock['meta']
  onChange: (meta: IWorkflowBlock['meta']) => void
  placeholder?: string
  disabled?: boolean
  error?: boolean
}

export const ObjectOutputKeyField = ({
  blockMeta,
  onChange,
  placeholder,
  disabled,
  error
}: TProps) => {
  const keyValue = blockMeta[EFieldKeys.KEY] as string

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    onChange({
      ...blockMeta,
      [EFieldKeys.KEY]: value
    })
  }

  return (
    <div className="workflow-builder-object-output-key-field">
      <TextField
        className="output-key-input"
        value={keyValue}
        name={EFieldKeys.KEY}
        onChange={handleChange}
        placeholder={placeholder}
        inputProps={{ maxLength: 50 }}
        disabled={disabled}
        error={!disabled && error}
      />
      <p className="helper-text">
        If there is more than one key with the same name, system will pick the first one.
      </p>
    </div>
  )
}
