import classNames from 'classnames'
import { motion } from 'framer-motion'
import { produce } from 'immer'
import { WF_SCHEDULED_WEEKDAY_OPTIONS } from './weekdaysSection.constants'
import { EWFScheduledWeekdays } from './weekdaysSection.types'
import './weekdays-section.scss'

type TProps = {
  selectedWeekdays: EWFScheduledWeekdays[]
  setSelectedWeekdays: (weekdays: EWFScheduledWeekdays[]) => void
  error?: string
}

export const WeekdaysSection = ({ selectedWeekdays, setSelectedWeekdays, error }: TProps) => {
  const handleWeekdayClick = (weekday: number) => {
    const updatedWeekdays = produce(selectedWeekdays, draft => {
      if (draft.includes(weekday)) {
        draft.splice(draft.indexOf(weekday), 1)
      } else {
        draft.push(weekday)
      }
    })

    setSelectedWeekdays(updatedWeekdays)
  }

  return (
    <div className="wf-trigger-weekdays-section">
      <div className={classNames('weekdays-wrapper', { 'has-error': !!error })}>
        {WF_SCHEDULED_WEEKDAY_OPTIONS.map(({ label, value }) => {
          const weekday = Number(value)

          return (
            <div
              key={weekday}
              className={classNames('weekday-item', {
                selected: selectedWeekdays.includes(weekday)
              })}
              onClick={() => handleWeekdayClick(weekday)}
            >
              {label}
            </div>
          )
        })}
      </div>
      {!!error && (
        <motion.div
          className="weekdays-error-message"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {error}
        </motion.div>
      )}
    </div>
  )
}
