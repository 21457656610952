import { OBJECT_TYPES } from 'constants/workflows'
import { EWorkflowScenarioReturnFormat } from './workflow.types'

export const DEFAULT_WORKFLOW_SCENARIO_RETURN_FORMATS = [EWorkflowScenarioReturnFormat.TEXT]

export const WORKFLOW_SCENARIO_OUTPUT_FORMAT_MAP = {
  [OBJECT_TYPES.FILE]: EWorkflowScenarioReturnFormat.FILE,
  [OBJECT_TYPES.JSON]: EWorkflowScenarioReturnFormat.JSON,
  [OBJECT_TYPES.WIDGET]: EWorkflowScenarioReturnFormat.TEXT
}
