import React from 'react'
import FindBlock from './FindBlock'
import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'
import {
  FIND_CARD_OPTIONS,
  FIND_CARD_OPTIONS_LIST
} from '../../../helpers/workflowFindBlock/workflowFindBloksHelpers'

const FindCard = props => {
  const { block, updateWorkflowBlock, changeWorkflowBlockInput, tenantId } = props
  return (
    <FindBlock
      findFieldLabel={messages.FIND_CARD}
      findFieldPlaceholder={messages.TYPE_CARD_NAME}
      findSelectLabel={messages.FIND_CARD_SELECT_LABEL}
      inputFieldLabel={messages.BOARD_LEVEL_INPUT}
      inputFieldPlaceholder={messages.BOARD_LEVEL_INPUT_PLACEHOLDER}
      inputType={OBJECT_TYPES.BOARD}
      findOptions={FIND_CARD_OPTIONS}
      findOptionsList={FIND_CARD_OPTIONS_LIST}
      block={block}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
      tenantId={tenantId}
      maxLength={50}
    />
  )
}

export default FindCard
