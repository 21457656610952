import React from 'react'

import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'

import UpdateWidgetDataBlock from './UpdateWidgetDataBlock'

const UpdateWidgetData = props => {
  const { block, tenantId, updateWorkflowBlock, changeWorkflowBlockInput } = props

  return (
    <UpdateWidgetDataBlock
      block={block}
      tenantId={tenantId}
      inputFieldLabel={messages.WIDGET_DATA_INPUT}
      outputFieldLabel={messages.TARGET_WIDGET_FOR_UPDATE}
      inputFieldPlaceholder={messages.UPDATE_WIDGET_DATA_PLACEHOLDER}
      outputFieldPlaceholder={messages.TARGET_WIDGET_PLACEHOLDER}
      inputType={OBJECT_TYPES.WIDGET_DATA}
      outputType={OBJECT_TYPES.WIDGET}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
      noItemsPlaceholder={messages.NO_WIDGETS_IN_TEAM}
      noItemsFoundPlaceholder={messages.NO_WIDGETS_FOUND}
    />
  )
}

export default UpdateWidgetData
