import { createReducer } from 'redux-create-reducer'
import * as ActionTypes from '../constants/actionTypes'

export const builder = createReducer(
  {},
  {
    [ActionTypes.SELECT_WIDGET_PANEL](state, action) {
      return {
        ...state,
        currentWidgetPanelItem: action.payload
      }
    },

    [ActionTypes.SET_CURRENT_CARD](state, action) {
      return {
        ...state,
        currentCard: action.payload
      }
    },

    [ActionTypes.RECEIVE_CARD](state, action) {
      return { ...state, currentCard: action.payload }
    },

    [ActionTypes.TOGGLE_UNSAVED_CHANGES_MODAL](state, action) {
      return {
        ...state,
        isUnsavedChangesShown: action.payload
      }
    },

    [ActionTypes.TOGGLE_EMPTY_NAME_VALIDATION](state, action) {
      return {
        ...state,
        isEmptyNameValidationShown: action.payload
      }
    },

    [ActionTypes.RECEIVE_CARD_UPDATE](state, action) {
      const currentCard = { ...state.currentCard, ...action.payload.data }
      return {
        ...state,
        currentCard
      }
    },

    [ActionTypes.TOGGLE_UNSAVED_CHANGES_FLAG](state, action) {
      return {
        ...state,
        isUnsavedChanges: action.payload
      }
    },

    [ActionTypes.TOGGLE_SAVE_TO_BOARD_FLAG](state, action) {
      return {
        ...state,
        isSaveToBoard: action.payload
      }
    },

    [ActionTypes.RECEIVE_WIDGET_CREATE](state, action) {
      const currentCard = { ...state.currentCard }
      const oldWidgets = currentCard.widgets ? currentCard.widgets.slice(0) : []

      currentCard.widgets = [...oldWidgets, ...action.payload]

      return {
        ...state,
        currentCard
      }
    },

    [ActionTypes.RECEIVE_WIDGET_DELETE](state, action) {
      const currentCard = { ...state.currentCard }
      currentCard.widgets = currentCard.widgets.filter(
        item => action.payload.indexOf(item.uuid) === -1
      )

      return {
        ...state,
        currentCard
      }
    },

    [ActionTypes.RECEIVE_WIDGET_UPDATE](state, action) {
      const currentCard = { ...state.currentCard }
      const widgets = currentCard.widgets ? currentCard.widgets.slice(0) : []

      widgets.forEach((item, index) => {
        action.payload.forEach(widget => {
          if (widget.uuid === item.uuid) {
            widgets.splice(index, 1, widget)
          }
        })
      })

      currentCard.widgets = widgets

      return {
        ...state,
        currentCard
      }
    },

    [ActionTypes.UPDATE_RESOLVED_DRAFT_WIDGETS](state, action) {
      const currentCard = { ...state.currentCard }

      if (!currentCard.uuid || !currentCard.widgets) {
        return state
      }

      const widgets = [...currentCard.widgets]

      widgets.forEach((localWidget, index) => {
        action.payload.forEach(widget => {
          const isSameWidget = widget.uuid === localWidget.uuid

          // if no timestamp in local widget -
          // no one operation wasn't perform after card opening with this widget
          // if no timestamp in remote widget - it was widget non-updated widget from card
          const localLastUpdate = localWidget.lastUpdate || 0
          const remoteLastUpdate = widget.lastUpdate || 0

          // if timestamp of last update of local widget more than
          // timestamp from widget from payload - skip it to not override users changes
          const wasUpdatedByUser = localLastUpdate > remoteLastUpdate

          if (isSameWidget && !wasUpdatedByUser) {
            widgets.splice(index, 1, widget)
          }
        })
      })

      currentCard.widgets = widgets

      return {
        ...state,
        currentCard
      }
    },

    [ActionTypes.SET_SELECTED_WIDGETS](state, action) {
      return {
        ...state,
        selectedWidgets: action.payload
      }
    },

    [ActionTypes.SET_HIGHLIGHTED_WIDGETS](state, action) {
      return {
        ...state,
        highlightedWidgets: action.payload
      }
    },

    [ActionTypes.SET_HIGHLIGHTED_SECTION](state, action) {
      return {
        ...state,
        highlightedSection: action.payload
      }
    },

    [ActionTypes.SET_EDITING_WIDGET](state, action) {
      return {
        ...state,
        editingWidget: action.payload
      }
    },

    [ActionTypes.TOGGLE_BUILDER_CREATE_MODE](state, action) {
      return {
        ...state,
        createMode: action.payload
      }
    },

    [ActionTypes.START_EDIT_RECEIVE_ERROR](state, action) {
      return {
        ...state,
        createMode: action.payload
      }
    },

    [ActionTypes.TOGGLE_BOARD_VIEW_MODE](state, action) {
      return {
        ...state,
        boardViewAutoMode: action.payload
      }
    },

    [ActionTypes.SET_WIDGETS_TO_BUFFER](state, action) {
      return {
        ...state,
        widgetsBuffer: action.payload
      }
    },

    [ActionTypes.TOGGLE_EDIT_LOCKED_MODAL](state, action) {
      return {
        ...state,
        isEditLockedModalShown: action.payload
      }
    },

    [ActionTypes.SET_UNSAVED_CHANGES_CALLBACK](state, action) {
      return {
        ...state,
        unsavedChangesCallback: action.payload
      }
    },

    [ActionTypes.TOGGLE_CHARTS_MODAL](state, action) {
      return {
        ...state,
        chartsModalData: action.payload
      }
    },

    [ActionTypes.TOGGLE_ISSUE_COUNTER_MODAL](state, action) {
      return {
        ...state,
        issueCounterData: action.payload
      }
    },

    [ActionTypes.SET_AUTOSAVE_INTERVAL_ID](state, action) {
      return { ...state, autoSaveID: action.payload }
    },

    [ActionTypes.TOGGLE_POPUP_NOTIFICATION](state, action) {
      return { ...state, popupNotificationData: action.payload }
    },

    [ActionTypes.TOGGLE_DATA_LINKING](state, action) {
      // toggleDataLinking(false) sets initial state of dataLinkingModalData
      const dataLinkingModalData =
        action.payload === false ? { state: false, widgetID: undefined } : action.payload
      return { ...state, dataLinkingModalData }
    },

    [ActionTypes.TOGGLE_SMART_LINK_MODAL](state, action) {
      return { ...state, smartLinkModalData: action.payload }
    },

    [ActionTypes.SET_ACTIVE_SECTION](state, action) {
      return {
        ...state,
        activeSection: action.payload
      }
    },

    [ActionTypes.BOARD_UPDATE_SOCKET](state, action) {
      const currentCard = { ...state.currentCard }
      const board = { ...currentCard.board, name: action.payload.name }
      return {
        ...state,
        currentCard: {
          ...currentCard,
          board
        }
      }
    },

    [ActionTypes.TENANT_UPDATE_SOCKET](state, action) {
      const currentCard = { ...state.currentCard }
      const board = { ...currentCard.board, tenantName: action.payload.teamName }
      return {
        ...state,
        currentCard: {
          ...currentCard,
          board
        }
      }
    },

    [ActionTypes.SET_LEFT_MENU_CARDS](state, action) {
      return { ...state, leftMenuCards: action.payload }
    },

    [ActionTypes.REPLACE_CARD_IN_LEFT_MENU](state, action) {
      const leftMenuCards = [...state.leftMenuCards]

      const cardToReplace = {
        ...action.payload,
        widgets: [],
        snapshot: '',
        board: null
      }
      const { tenantId, boardId, uuid } = cardToReplace

      // check whether edited card belongs to board opened in left-hand menu
      const isSameBoard =
        leftMenuCards[0] &&
        leftMenuCards[0].tenantId === tenantId &&
        leftMenuCards[0].boardId === boardId

      if (!isSameBoard) {
        return state
      }

      const cardIndex = leftMenuCards.findIndex(card => card.uuid === uuid)

      if (cardIndex !== -1) {
        const card = {
          ...leftMenuCards[cardIndex],
          ...cardToReplace
        }
        leftMenuCards.splice(cardIndex, 1, card)
      } else {
        leftMenuCards.push({ ...cardToReplace, posN: 1, posD: 1 })
      }

      return { ...state, leftMenuCards }
    },

    [ActionTypes.TOGGLE_CB_SERVER_ERROR](state, action) {
      return { ...state, isServerError: action.payload }
    },

    [ActionTypes.SET_ACTIVE_WIDGET_UPDATED](state, action) {
      return { ...state, activeWidgetWasUpdated: action.payload }
    },

    [ActionTypes.BULK_WIDGETS_UPDATE](state, action) {
      if (!action.payload) {
        return { ...state, bulkWidgetsUpdatePayload: [] }
      }
      const bulkWidgetsUpdatePayload = [...state.bulkWidgetsUpdatePayload, action.payload]
      return { ...state, bulkWidgetsUpdatePayload }
    }
  }
)

export default builder
