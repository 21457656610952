import { OBJECT_TYPES } from 'constants/workflows'
import messages from 'constants/messages'
import Value from './ValueRenderer'
import UserValue from './UserValue'
import StringCommentValue from './StringCommentValue'
import 'scss/common/any-level-dropdown/any-level-dropdown-selected-item/any-level-dropdown-selected-item.scss'

const SelectedItem = ({ type, value, supportHistory }) => {
  const isUndefined = (supportHistory && !value.isDefined) || (value && value.error)
  const color = isUndefined ? 'grey' : 'colored'
  const className = type === OBJECT_TYPES.widget ? value.widgetClassName : `${type}-${color}`
  if (supportHistory) {
    const source = value.history[value.history.length - 1]
    const subtitle = `${messages.FROM}: ${source.index + 1}. ${source.customName || source.block}`
    return type !== OBJECT_TYPES.COMMENT ? (
      <Value
        title={value.name}
        className={className}
        subtitle={subtitle}
        isUndefined={isUndefined}
        error={value.error}
      />
    ) : (
      <StringCommentValue
        title={value.name}
        value={value}
        className={className}
        isUndefined={isUndefined}
        error={value.error}
        subtitle={subtitle}
      />
    )
  }
  switch (type) {
    case OBJECT_TYPES.BOARD:
      return (
        <Value
          className={className}
          title={value.name}
          error={value.error}
          isUndefined={isUndefined}
        />
      )
    case OBJECT_TYPES.CARD:
    case OBJECT_TYPES.CARD_BUNDLE:
    case OBJECT_TYPES.COLUMN:
      return (
        <Value
          className={className}
          title={value.name}
          subtitle={value.boardName}
          error={value.error}
          isUndefined={isUndefined}
        />
      )
    case OBJECT_TYPES.WIDGET:
      return (
        <Value
          className={className}
          title={value.widgetTitle}
          subtitle={`${value.boardName}: ${value.cardName}`}
          error={value.error}
          isUndefined={isUndefined}
        />
      )
    case OBJECT_TYPES.USER:
      return (
        <UserValue
          email={value.email}
          username={value.username}
          avatarImage={value.avatarImage}
          error={value.error}
          isUndefined={isUndefined}
        />
      )
    case OBJECT_TYPES.STRING:
    case OBJECT_TYPES.JSON:
      return (
        <Value
          className={className}
          title={value.value}
          subtitle={`${messages.FROM}: ${value.source}`}
        />
      )
    default:
      return <div />
  }
}

export default SelectedItem
