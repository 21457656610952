import { produce } from 'immer'
import { createReducer } from 'redux-create-reducer'
import {
  TOGGLE_BOARD_SUBSCRIPTION,
  TOGGLE_COMMENTS_SUBSCRIPTION,
  TOGGLE_BOARD_WATCHING,
  BOARD_WATCHERS_UPDATE_SOCKET,
  ADD_NEW_JOB,
  REMOVE_JOB,
  JOB_COMPLETED_SOCKET,
  TOGGLE_BOARD_COPYING,
  TOGGLE_MONITOR_SUBSCRIPTION,
  MONITOR_UPDATE_SOCKET
} from '../constants/actionTypes'

export const socket = createReducer(
  {},
  {
    [TOGGLE_BOARD_SUBSCRIPTION](state, action) {
      return { ...state, isSubscribedToBoard: action.payload }
    },

    [TOGGLE_COMMENTS_SUBSCRIPTION](state, action) {
      const { isSubscribedToComments, subscribedCard } = action.payload
      return { ...state, isSubscribedToComments, subscribedCard }
    },

    [TOGGLE_BOARD_WATCHING](state, action) {
      return { ...state, ...action.payload }
    },

    [BOARD_WATCHERS_UPDATE_SOCKET](state, action) {
      return { ...state, watchingUsers: action.payload }
    },

    [ADD_NEW_JOB](state, action) {
      return { ...state, jobs: [...state.jobs, action.payload] }
    },

    [REMOVE_JOB](state, action) {
      return { ...state, jobs: state.jobs.filter(job => job.id !== action.payload.id) }
    },

    [JOB_COMPLETED_SOCKET](state, action) {
      const { id, data, progress, status, importedContext, error } = action.payload
      const jobs = state.jobs.map(job => {
        if (job.id === id) {
          return {
            ...job,
            data: {
              ...job.data,
              ...data
            },
            importedContext,
            progress,
            status,
            error
          }
        }

        return job
      })

      return { ...state, jobs }
    },

    [TOGGLE_BOARD_COPYING](state, action) {
      return { ...state, isBoardCopying: action.payload }
    },

    [TOGGLE_MONITOR_SUBSCRIPTION](state, action) {
      const { isSubscribedToMonitor, subscribedJobs, subscribedMonitor } = action.payload
      return { ...state, isSubscribedToMonitor, subscribedJobs, subscribedMonitor }
    },

    [MONITOR_UPDATE_SOCKET](state, action) {
      return produce(state, draft => {
        const { monitorId, message, customComponentName, componentName, errorCode } = action.payload

        if (!message && !customComponentName && !componentName) {
          return
        }

        const job = draft.jobs.find(job => job.parentId === monitorId)

        if (job) {
          const blockComponentName = errorCode ? undefined : componentName

          job.data = {
            ...job.data,
            message,
            customComponentName,
            componentName: blockComponentName
          }
        }
      })
    }
  }
)

export default socket
