import React, { Component } from 'react'

import DeleteBlock from '../../../components/workflowBuilder/deleteBlocks/DeleteBlock'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

export default class DeleteBlockContainer extends Component {
  constructor(props) {
    super(props)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  handleInputChange(item) {
    const { block } = this.props

    const fieldName = EFieldKeys.SOURCE_INPUT

    this.props.changeWorkflowBlockInput({
      addToOutputsStoppers: true,
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
    this.props.clearWorkflowBlocksStoppedInput({ blockId: block.id })
  }

  handleReset() {
    this.handleInputChange({ id: null })
  }

  render() {
    const { block } = this.props

    return (
      <DeleteBlock
        block={block}
        handleReset={this.handleReset}
        handleInputChange={this.handleInputChange}
      />
    )
  }
}
