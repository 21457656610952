import { type ReactNode, useMemo } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { StyledEngineProvider } from '@mui/material/styles'
import { NextUIProvider } from '@nextui-org/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PATHS } from 'constants/paths.constants'
import { QueryClientService } from 'services/common/queryClient.service'
import IssueCounterContainer from 'containers/modals/IssueCounterContainer'
import WorkflowOverlayContainer from 'containers/workflowOverlay/WorkflowOverlayContainer'
import { useServiceWorker } from 'hooks/useServiceWorker'
import ToastsQueueContainer from '../ToastsQueueContainer'
import { useOrganizationSubscriptionAccess } from './hooks/useOrganizationSubscriptionAccess'
import { useSessionExpirationNotification } from './hooks/useSessionExpirationNotification'
import 'scss/main.scss'
import '@praxie/react-quill/dist/quill.snow.css'

type TProps = {
  children: ReactNode
}

export const AppContainer = ({ children }: TProps) => {
  const history = useHistory()

  useServiceWorker()

  const isSnapshotPage = !!matchPath(history.location.pathname, {
    path: PATHS.snapshot.routerPath
  })

  useOrganizationSubscriptionAccess(isSnapshotPage)
  useSessionExpirationNotification()

  const shouldEnableTanstackQueryDevtools =
    import.meta.env.VITE_IS_TANSTACK_QUERY_DEV_TOOLS_ENABLED ?? false

  return useMemo(
    () => (
      <StyledEngineProvider injectFirst>
        <NextUIProvider className="next-ui-provider">
          <QueryClientProvider client={QueryClientService.queryClient}>
            {children}
            {!isSnapshotPage && <ToastsQueueContainer />}
            <IssueCounterContainer />
            <WorkflowOverlayContainer />
            <div id="portal-container" className="portal-container" />
            {shouldEnableTanstackQueryDevtools && <ReactQueryDevtools initialIsOpen={false} />}
          </QueryClientProvider>
        </NextUIProvider>
      </StyledEngineProvider>
    ),
    [children, isSnapshotPage, shouldEnableTanstackQueryDevtools]
  )
}
