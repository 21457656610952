/**
 * Calculates the Greatest Common Divisor (GCD) of two numbers using the Euclidean algorithm.
 *
 * @param {number} a - First number.
 * @param {number} b - Second number.
 * @returns {number} - The GCD of a and b.
 */
export const getGCD = (a, b) => {
  if (b === 0) return a
  return getGCD(b, a % b)
}

/**
 * Calculate the position of a Card in the Farey sequence based on the previous and next card positions.
 *
 * @param neighborCardsPositions
 * @param {{ posN: number, posD: number } | undefined} neighborCardsPositions.prevPos
 * @param {{ posN: number, posD: number } | undefined} neighborCardsPositions.nextPos
 * @returns {{posN: number, posD: number}}
 */
export const getPosition = ({ prevPos, nextPos }) => {
  if (!prevPos && !nextPos) return { posN: 1, posD: 1 }

  let newPosN, newPosD

  if (!prevPos) {
    newPosN = nextPos.posN + 1
    newPosD = nextPos.posD
  } else if (!nextPos) {
    newPosN = prevPos.posN
    newPosD = prevPos.posD + 1
  } else {
    newPosN = prevPos.posN + nextPos.posN
    newPosD = prevPos.posD + nextPos.posD
  }

  const divisor = getGCD(newPosN, newPosD)

  return {
    posN: newPosN / divisor,
    posD: newPosD / divisor
  }
}

export const sortByPositions = (a, b) => {
  const aPos = a.posN / a.posD
  const bPos = b.posN / b.posD

  return bPos - aPos
}

const sortByPositionsDEV = (a, b) => {
  const aPos = a.posN / a.posD
  const bPos = b.posN / b.posD

  if (a.posN === b.posN && a.posD === b.posD) {
    console.error('Cards(Columns) positions collision', a, b)
  }

  return bPos - aPos
}

export function getMatrixFromList(list) {
  // to catch positions collision on dev env and send trace on the server
  const sortFunc = import.meta.env.MODE === 'development' ? sortByPositionsDEV : sortByPositions
  const map = {}

  list.forEach(item => {
    if (item.uuid && item.isCol) {
      map[item.uuid] = {
        immutableIndex: item.uuid,
        header: item,
        cards: []
      }
    }
  })

  list.forEach(item => {
    if (item.uuid && !item.isCol && map[item.columnUuid]) {
      map[item.columnUuid].cards.push(item)
    }
  })

  const matrix = Object.values(map)

  matrix.forEach(column => {
    column.cards.sort(sortFunc)
  })

  matrix.sort((a, b) => sortFunc(a.header, b.header))

  matrix.push({
    cards: []
  })

  return matrix
}
