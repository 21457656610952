import React from 'react'
import classNames from 'classnames'

const ValueRenderer = ({ className, title, subtitle, error, isUndefined }) => (
  <div className="any-level-dropdown-selected-value">
    <i className={`icon workflow-block-icon small icon-${className}`} />
    <div className="any-level-dropdown-selected-value-text">
      <p
        className={classNames('any-level-dropdown-selected-value-title', {
          'is-undefined': isUndefined
        })}
      >
        {title}
      </p>
      {subtitle && <p className="any-level-dropdown-selected-value-subtitle">{subtitle}</p>}
      {error && <p className="any-level-dropdown-selected-value-error">{error}</p>}
    </div>
  </div>
)

export default ValueRenderer
