import { type TWorkflowScheduleSettings } from 'features/workflow/workflow.types'
import { formatISOToUTCHours, getDefaultISOTime } from './scheduleParams.helpers'
import { EWFScheduledRunsFrequency } from './scheduleParams.types'
import { EWFScheduledWeekdays } from './weekdaysSection/weekdaysSection.types'

export const WF_SCHEDULED_RUNS_FREQUENCY_OPTIONS = Object.values(EWFScheduledRunsFrequency).map(
  value => ({
    label: value,
    value
  })
)

export const WF_SCHEDULED_DEFAULT_WEEKDAYS = [EWFScheduledWeekdays.MONDAY]

export const WF_SCHEDULED_DEFAULT_MONTH_DAYS = [1]

export const WF_SCHEDULED_DEFAULT_SETTINGS: TWorkflowScheduleSettings = {
  frequency: EWFScheduledRunsFrequency.DAILY,
  hours: [formatISOToUTCHours(getDefaultISOTime())]
}
