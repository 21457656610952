import {
  type ClipboardEvent,
  type FocusEvent,
  type KeyboardEvent,
  type ReactNode,
  useEffect,
  useRef,
  useState
} from 'react'
import classNames from 'classnames'
import { EKeyCode } from 'constants/common'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import FormField from 'components/common/FormField'
import { SMART_LINK_SELECTOR } from 'components/modals/smartLink/smartLinkModal'
import { InsertTokenDropdown } from 'components/tokenInputBox/insertTokenDropdown/insertTokenDropdown'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import { BoxLabel } from './boxLabel/boxLabel'
import CommentBoxRibbon from './CommentBoxRibbon/CommentBoxRibbon'
import {
  ARROWS_KEYS,
  DEFAULT_MAX_LENGTH,
  DEFAULT_RIBBON_CONFIG,
  LEVEL_DROPDOWN_CLASSNAME
} from './tokenInputBox.constants'
import {
  flatSingle,
  getRemovedInputValues,
  getValidForMarkdownParseComments,
  isInputToken,
  isNodeAnyLevelDropdownInput
} from './tokenInputBox.helpers'
import './token-input-box.scss'

const TokenInputBoxHint = ({ tokenInputBoxHintText }: { tokenInputBoxHintText: string }) => (
  <div className="praxie-token-input-box-hint">
    {messages.TYPE}
    <i className="icon workflow-block-icon bracket-icon hint-icon" />
    {tokenInputBoxHintText}
  </div>
)

export type TTokenInputValue = string | { fieldName: string }

type TProps = {
  block: IWorkflowBlock
  fieldMeta: IWorkflowBlock['meta']
  bodyFieldKey: EFieldKeys
  tokenFieldKey: string
  error?: string | null
  availableTokenFieldKeys?: string[]
  tokenInputBoxHintText?: string
  tokenInputBoxLabel?: ReactNode
  tokenInputPlaceholder?: string
  customClassName?: string
  hasRibbon?: boolean
  hasHint?: boolean
  isDisabled?: boolean
  isMultiTokenSupported?: boolean
  maxLength?: number
  ribbonConfig?: {
    bold?: boolean
    italic?: boolean
    underline?: boolean
    list?: boolean
    fontSize?: boolean
    link?: boolean
  }
  shouldPreventEnterAction?: boolean
  shouldShowLengthIndicator?: boolean
  handleReset: (fieldName: string) => void
  handleInputChange: (fieldName: string, item: Partial<IWorkflowDropdownValue>) => void
  updateBlockMeta: (key: EFieldKeys, fieldValue: TTokenInputValue[]) => void
}

export const TokenInputBox = ({
  block,
  fieldMeta,
  bodyFieldKey,
  tokenFieldKey,
  error,
  availableTokenFieldKeys,
  tokenInputBoxHintText = messages.COMMENT_BOX_HINT_TEXT,
  tokenInputBoxLabel = '',
  tokenInputPlaceholder = messages.COMMENT_TEXT_PLACEHOLDER,
  customClassName = '',
  hasRibbon = false,
  hasHint = false,
  isDisabled = false,
  isMultiTokenSupported = false,
  maxLength = DEFAULT_MAX_LENGTH,
  ribbonConfig = DEFAULT_RIBBON_CONFIG,
  shouldPreventEnterAction = false,
  handleReset,
  handleInputChange,
  updateBlockMeta,
  shouldShowLengthIndicator = false
}: TProps) => {
  const [inputValues, setInputValues] = useState<TTokenInputValue[]>([])
  const [insertedToken, setInsertedToken] = useState<EFieldKeys | string | null>(null)
  const [shouldShowPlaceholder, setShouldShowPlaceholder] = useState(true)
  const [shouldShowRibbon, setShouldShowRibbon] = useState(false)

  const brRef = useRef<HTMLBRElement>(null)
  const tokenInputBoxRef = useRef<HTMLDivElement>(null)
  const tokenInputBoxRibbonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setInputValues(fieldMeta[bodyFieldKey] as TTokenInputValue[])
  }, [bodyFieldKey, fieldMeta])

  const getTextLimit = () => {
    // In some cases tokenInputBox.innerText length always 1 because of the br tag;
    const tokenInputBox = tokenInputBoxRef.current

    if (!tokenInputBox) return null

    const content = tokenInputBox.innerText
    const contentLength = content[content.length - 1] === '\n' ? content.length - 1 : content.length

    return maxLength - contentLength
  }

  const removeTokenInputPartByIndexes = (indexes: number[]) => {
    const updatedInputValues = inputValues.filter((value, id) => {
      const shouldBeRemoved = indexes.includes(id)

      if (shouldBeRemoved && isInputToken(value)) {
        // @ts-expect-error
        handleReset(value.fieldName as string)
      }

      return !shouldBeRemoved
    })

    setInputValues(updatedInputValues)
  }

  // Method removes text nodes that can-not be deleted by setState (contentEditable behavior)
  const removeChildNodes = () => {
    const tokenInputBox = tokenInputBoxRef.current
    const nodesToBeRemoved = [] as ChildNode[]

    tokenInputBox?.childNodes.forEach(node => {
      const element = node as HTMLElement | null

      if (element?.nodeType === Node.TEXT_NODE) {
        nodesToBeRemoved.push(node)
      }

      if (
        element?.classList &&
        element.classList[0] !== LEVEL_DROPDOWN_CLASSNAME &&
        node.nodeType === 1
      ) {
        // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
        const textContent = node.textContent as string

        node.textContent = textContent
          .split('%%')
          .filter(item =>
            isMultiTokenSupported
              ? availableTokenFieldKeys?.every(tokenKey => !item.includes(tokenKey))
              : !item.includes(tokenFieldKey)
          )
          .filter(Boolean)
          .join()
      }
    })

    nodesToBeRemoved.forEach(node => node.remove())
  }

  // Return array of strings and objects
  const getValueFromTokenInputBox = () => {
    const tokenInputBox = tokenInputBoxRef.current

    const inputValues = [] as TTokenInputValue[]

    tokenInputBox?.childNodes.forEach(node => {
      const currentNode = node as HTMLElement | null

      const isInsertTokenDropdown =
        currentNode?.classList && currentNode.classList[0] === LEVEL_DROPDOWN_CLASSNAME

      const item = isInsertTokenDropdown
        ? { fieldName: currentNode.dataset.fieldName ?? '' }
        : (node.textContent ?? '')

      inputValues.push(item)
    })

    // Get rid of text duplication
    removeChildNodes()

    // br tag should always be in the TokenInputBox due to ff issues with caret and contentEditable false elements
    if (!tokenInputBox?.lastChild || tokenInputBox.lastChild.nodeName !== 'BR') {
      tokenInputBox?.appendChild(brRef.current as Element)
    }

    const splitInputValues = inputValues.map(value =>
      typeof value !== 'string' ? value : value.split('%%').filter(Boolean)
    )

    return flatSingle(splitInputValues as string[][]).map((value: TTokenInputValue) => {
      if (typeof value !== 'string') {
        return value
      }

      const isTokenFieldValue = isMultiTokenSupported
        ? availableTokenFieldKeys?.some(tokenKey => value.includes(tokenKey))
        : value.includes(tokenFieldKey)

      if (isTokenFieldValue) {
        return { fieldName: value }
      }

      return value
    })
  }

  const getInsertedFieldNames = () => {
    return inputValues.reduce((acc: string[], value: TTokenInputValue) => {
      if (typeof value !== 'string') {
        acc.push(value.fieldName)
      }

      return acc
    }, [])
  }

  const getFieldName = (newKey?: EFieldKeys) => {
    const insertedFieldNames = getInsertedFieldNames()

    return Object.keys(block.input).find(key => {
      const keyToCheck = newKey ?? tokenFieldKey
      const isCorrectKey =
        isMultiTokenSupported && !newKey
          ? availableTokenFieldKeys?.some(tokenKey => key.startsWith(tokenKey))
          : key.startsWith(keyToCheck)

      const isEmptyStringInput = !block.input[key]

      if (insertedFieldNames.includes(key)) {
        return false
      }

      return isCorrectKey && isEmptyStringInput
    })
  }

  const updateTokenInputBoxMeta = () => {
    let updatedInputValues = getValueFromTokenInputBox() as TTokenInputValue[]

    const isEmpty =
      updatedInputValues.length === 1 &&
      typeof updatedInputValues[0] === 'string' &&
      !updatedInputValues[0]?.trim().length

    if (isEmpty) {
      updatedInputValues = []
    }

    setInputValues(prevState => {
      const removedInputValues = getRemovedInputValues(prevState, updatedInputValues)

      removedInputValues.forEach(value => {
        if (typeof value !== 'string' && isInputToken(value)) {
          handleReset(value.fieldName)
        }
      })

      return updatedInputValues
    })
    setInsertedToken(null)

    if (hasRibbon) {
      updatedInputValues = getValidForMarkdownParseComments(updatedInputValues) as string[]
    }

    updateBlockMeta(bodyFieldKey, updatedInputValues)
  }

  const onInputChange = (fieldName: string, item: IWorkflowDropdownValue) => {
    if (item.type === OBJECT_TYPES.WIDGET_DATA && !fieldName.includes(EFieldKeys.UBF_CELL)) {
      const fieldKey = fieldName.includes(EFieldKeys.SUBJECT_INPUT)
        ? EFieldKeys.SUBJECT_UBF_CELL
        : EFieldKeys.UBF_CELL

      const updatedFieldName = getFieldName(fieldKey)

      const updatedInputValues = inputValues.map((value: TTokenInputValue) => {
        if (typeof value !== 'string' && value.fieldName === fieldName) {
          return { ...value, fieldName: updatedFieldName }
        }

        return value
      }) as TTokenInputValue[]

      if (!updatedFieldName) return

      handleInputChange(updatedFieldName, item)

      setInsertedToken(block.input[updatedFieldName] ? null : updatedFieldName)
      setInputValues(updatedInputValues)

      return
    }

    handleInputChange(fieldName, item)

    setInsertedToken(block.input[fieldName] ? null : fieldName)
  }

  const handlePressInsideAnyLevelInput = (e: KeyboardEvent<HTMLDivElement>) => {
    const target = e.target as HTMLInputElement

    if (!target.value) {
      const tokenDropdownId = Number(target.closest(`.${LEVEL_DROPDOWN_CLASSNAME}`)?.id)

      removeTokenInputPartByIndexes([tokenDropdownId])

      setTimeout(() => tokenInputBoxRef.current?.focus(), 100)
      e.preventDefault()
    }
  }

  const handleSpaceInsideAnyLevelInput = (e: KeyboardEvent<HTMLDivElement>) => {
    const target = e.target as HTMLInputElement

    if (!target.value) {
      const tokenDropdownId = Number(target.closest(`.${LEVEL_DROPDOWN_CLASSNAME}`)?.id)
      removeTokenInputPartByIndexes([tokenDropdownId])

      setTimeout(() => {
        tokenInputBoxRef.current?.focus()
        document.execCommand('insertHTML', false, '[')
      }, 100)
      e.preventDefault()
    }
  }

  const handleLeftBracketKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement

    if (target.innerText.length > maxLength) {
      e.preventDefault()
      return
    }

    const fieldName = getFieldName()

    if (!fieldName) return

    e.preventDefault()
    // instead of calculating of caret offset use trick with insertHTML
    document.execCommand('insertHTML', false, `%%${fieldName}%%`)
    const updatedInputValues = getValueFromTokenInputBox()

    setInputValues(updatedInputValues)
    setInsertedToken(fieldName)
  }

  const handleEnterKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()

    if (shouldPreventEnterAction) return

    const zeroWidthSpace = '\u200b'
    const inlineBreak = document.createTextNode(`\n${zeroWidthSpace}`)

    const selection = window.getSelection()

    if (selection) {
      const range = selection.getRangeAt(0)

      const boundaryRange = range.cloneRange()
      boundaryRange.collapse(false)
      boundaryRange.insertNode(inlineBreak)

      range.selectNode(inlineBreak)
      selection.removeAllRanges()
      selection.addRange(range)
      selection.collapseToEnd()
    } else {
      document.execCommand('insertHTML', false, '\n')
    }
  }

  const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
    if (!tokenInputBoxRef.current) return

    event.preventDefault()

    const clipboardData = event.clipboardData
    const pastedText = clipboardData.getData('Text')

    const currentText = tokenInputBoxRef.current.textContent ?? ''
    const allowedText = pastedText.slice(0, maxLength - currentText.length)

    document.execCommand('insertHTML', false, allowedText)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement

    if (isNodeAnyLevelDropdownInput(target)) {
      onKeyPressInsideAnyLevelDropdown(e)
      return
    }

    switch (e.key as EKeyCode) {
      case EKeyCode.LEFT_BRACKET: {
        handleLeftBracketKeyPress(e)
        break
      }
      case EKeyCode.ENTER: {
        handleEnterKeyPress(e)
        break
      }
      default: {
        const isSpecialKey = e.ctrlKey || e.metaKey || ARROWS_KEYS.includes(e.key as EKeyCode)

        if (isSpecialKey) return

        const isMaxLengthExceeded = target.innerText.length >= maxLength
        const isDeleteKey = e.key === 'Backspace' || e.key === 'Delete'

        if (isMaxLengthExceeded && !isDeleteKey) {
          e.preventDefault()
        }

        break
      }
    }
  }

  const handleBlurTokenInputBox = (e: FocusEvent<HTMLDivElement>) => {
    const tokenInputBoxRibbon = tokenInputBoxRibbonRef.current

    if (tokenInputBoxRibbon?.contains(e.relatedTarget)) return

    updateTokenInputBoxMeta()
    setShouldShowPlaceholder(true)
  }

  const handleFocusInWrapper = () => {
    setShouldShowRibbon(hasRibbon)
    setShouldShowPlaceholder(false)
  }

  const handleBlurFromWrapper = (e: FocusEvent<HTMLDivElement>) => {
    if (!hasRibbon) return

    const relatedTarget = e.relatedTarget as HTMLDivElement | null
    const smartLinkModal = document.querySelector(`.${SMART_LINK_SELECTOR}`)

    const isSmartLinkModal =
      smartLinkModal &&
      (!relatedTarget ||
        smartLinkModal.contains(relatedTarget) ||
        relatedTarget.contains(smartLinkModal))

    if (isSmartLinkModal) return

    if (tokenInputBoxRibbonRef.current?.contains(relatedTarget)) return

    setShouldShowRibbon(false)
  }

  const onKeyPressInsideAnyLevelDropdown = (e: KeyboardEvent<HTMLDivElement>) => {
    switch (e.key as EKeyCode) {
      case EKeyCode.BACKSPACE:
        handlePressInsideAnyLevelInput(e)
        break
      case EKeyCode.SPACE:
        handleSpaceInsideAnyLevelInput(e)
        break
      default:
        break
    }
  }

  const isEmpty = !(fieldMeta[bodyFieldKey] as unknown[]).length
  const canRenderPlaceholder = isEmpty && shouldShowPlaceholder
  const canRenderRibbon = hasRibbon && shouldShowRibbon

  return (
    <FormField
      id="praxie-token-input-box"
      className="praxie-token-input-box-field"
      label={
        <BoxLabel
          label={tokenInputBoxLabel}
          maxLength={maxLength}
          textLimit={getTextLimit()}
          shouldShowLengthIndicator={shouldShowLengthIndicator}
        />
      }
      error={error}
      hintText={hasHint && <TokenInputBoxHint tokenInputBoxHintText={tokenInputBoxHintText} />}
    >
      <div
        className="praxie-token-input-box-wrapper"
        onFocus={handleFocusInWrapper}
        onBlur={handleBlurFromWrapper}
      >
        {canRenderPlaceholder && (
          <div className="token-input-box-placeholder">{tokenInputPlaceholder}</div>
        )}
        <div
          // A terrible idea to use JSON.stringify as a key, but it's the only way to
          // force the component to re-render when the input values array changes. The re-render is
          // required to avoid "failed to execute 'removeChild' on 'Node'" error in the console.
          // https://leverxeu.atlassian.net/browse/EUPBOARD01-16737.
          key={JSON.stringify(inputValues)}
          ref={tokenInputBoxRef}
          className={classNames('token-input-box', customClassName, {
            disabled: isDisabled,
            'has-ribbon': canRenderRibbon,
            'has-error': error
          })}
          data-text={tokenInputPlaceholder}
          contentEditable={!isDisabled}
          suppressContentEditableWarning
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
          onBlur={handleBlurTokenInputBox}
        >
          {inputValues.map((inputValue: TTokenInputValue, id) =>
            typeof inputValue === 'object' ? (
              <InsertTokenDropdown
                key={`token-input-${String(id)}`}
                id={id}
                block={block}
                fieldName={inputValue.fieldName}
                insertedToken={insertedToken}
                handleInputChange={onInputChange}
                isDisabled={isDisabled}
              />
            ) : (
              <span key={`${inputValue}${String(id)}`} id={String(id)}>
                {inputValue}
              </span>
            )
          )}
          <br ref={brRef} />
        </div>
        {canRenderRibbon && (
          <CommentBoxRibbon
            commentBoxMaxLength={maxLength}
            textLimit={getTextLimit()}
            commentBoxRef={tokenInputBoxRef}
            commentBoxRibbonRef={tokenInputBoxRibbonRef}
            updateCommentBoxMeta={updateTokenInputBoxMeta}
            error={error}
            ribbonConfig={ribbonConfig}
          />
        )}
      </div>
    </FormField>
  )
}
