import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import { getSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import {
  STRING_INPUT,
  PLAIN_STRING,
  COLUMN_NUMBER,
  COLUMN_TYPES,
  COLUMN_TYPES_OPTIONS,
  PREV_COLUMN,
  NEXT_COLUMN
} from 'helpers/workflowMoveCardBlock/moveCardBlockHelpers'
import NumberFieldContainer from 'containers/workflowBuilder/common/NumberFieldContainer'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import FormField from 'components/common/FormField'
import { Tooltip } from 'components/common/tooltip/tooltip'
import PlainInput from 'components/fields/PlainInput'
import ReactSelect from 'components/fields/ReactSelect'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import 'scss/workflowBuilder/moveCardBlock/define-column.scss'

const getColumnNameFieldLabel = columnType => {
  if (columnType === COLUMN_NUMBER) {
    return messages.COLUMN_NUMBER
  }

  const isPrevColumn = columnType === PREV_COLUMN
  const tooltipText = isPrevColumn
    ? messages.RELATIVE_COLUMN_NUMBER_LEFT_TOOLTIP
    : messages.RELATIVE_COLUMN_NUMBER_RIGHT_TOOLTIP
  const labelText = isPrevColumn
    ? messages.RELATIVE_LEFT_COLUMN_NUMBER
    : messages.RELATIVE_RIGHT_COLUMN_NUMBER

  return (
    <span className="relative-column-number-label">
      {labelText}
      <Tooltip placement="top" id="relative-column-number-tooltip" title={tooltipText}>
        <i className="icon icon-hint" />
      </Tooltip>
    </span>
  )
}

const typesWithColumnNumber = [COLUMN_NUMBER, PREV_COLUMN, NEXT_COLUMN]

const DefineColumn = ({
  isDefineDisabled,
  block: { id, input, error },
  columnType,
  columnName,
  columnNumber,
  handleReset,
  changeColumnType,
  changeColumnName,
  blurColumnName,
  changeColumnNumber,
  handleInputChange
}) => (
  <div className="define-column">
    <FormField
      label={messages.DEFINE_TARGET_COLUMN}
      id="define-target-column"
      hintText={messages.DEFINE_TARGET_COLUMN_DESC}
    >
      <ReactSelect
        className="define-target-column"
        size="large"
        value={getSelectedOptionByKey(columnType, COLUMN_TYPES)}
        options={COLUMN_TYPES_OPTIONS}
        isDisabled={isDefineDisabled}
        menuPortalEnabled
        onChange={changeColumnType}
      />
    </FormField>
    {columnType === STRING_INPUT && (
      <BlockInputContainer
        type={OBJECT_TYPES.STRING}
        blockId={id}
        value={input[EFieldKeys.STRING_INPUT]}
      >
        {({ selectedItem, items, searchItems }) => (
          <AnyLevelDropdown
            title={messages.STRING_LEVEL_INPUT}
            placeholder={messages.STRING_LEVEL_INPUT_PLACEHOLDER}
            items={items}
            searchItems={searchItems}
            value={selectedItem}
            type={OBJECT_TYPES.STRING}
            disabled={isDefineDisabled}
            error={error[EFieldKeys.STRING_INPUT]}
            supportHistory
            onSelect={option => handleInputChange(EFieldKeys.STRING_INPUT, option)}
            onReset={() => handleReset(EFieldKeys.STRING_INPUT)}
          />
        )}
      </BlockInputContainer>
    )}
    {typesWithColumnNumber.includes(columnType) && (
      <FormField
        label={getColumnNameFieldLabel(columnType)}
        error={error[EFieldKeys.COLUMN_NUMBER]}
        id="column-number"
      >
        <NumberFieldContainer
          value={columnNumber}
          disabled={isDefineDisabled}
          onChange={changeColumnNumber}
        />
      </FormField>
    )}
    {columnType === PLAIN_STRING && (
      <FormField
        label={messages.COLUMN_NAME}
        error={error[EFieldKeys.NAME]}
        id="column-plain-string"
      >
        <PlainInput
          className="input-field"
          placeholder={messages.TYPE_COLUMN_NAME}
          maxLength={50}
          value={columnName}
          disabled={isDefineDisabled}
          onChange={changeColumnName}
          onBlur={blurColumnName}
        />
      </FormField>
    )}
  </div>
)

export default DefineColumn
