import { CalendarDateTime, parseTime, Time, ZonedDateTime } from '@internationalized/date'
import { type TimeInputProps, TimeInput as NextTimeInput } from '@nextui-org/react'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import ClockIcon from 'assets/images/icons/ic_clock.svg?react'
import './time-input.scss'

type TProps = Omit<TimeInputProps, 'value' | 'onChange'> & {
  className?: string
  error?: boolean
  errorMessage?: string
  value?: string
  onChange?: (value: string) => void
}

type TTimeValue = Time | ZonedDateTime | CalendarDateTime

const parseTimeFromISO = (time?: string) =>
  time ? parseTime(DateTime.fromFormat(time, 'H:mm:ssZZ').toFormat('HH:mm:ss')) : null

const parseTimeToISO = (time: TTimeValue) =>
  DateTime.fromFormat(time.toString(), 'HH:mm:ss').toISOTime({
    suppressMilliseconds: true
  }) ?? ''

export const TimeInput = ({
  className,
  error,
  errorMessage = 'Invalid time',
  value,
  onChange,
  ...props
}: TProps) => {
  const handleChange = (value: TTimeValue | null) => {
    if (!onChange) return

    if (!value) {
      onChange('')
      return
    }

    const parsedValue = parseTimeToISO(value)
    onChange(parsedValue)
  }

  return (
    <NextTimeInput
      classNames={{
        base: classNames('time-input-container', className, {
          'has-error': error
        }),
        inputWrapper: classNames('input-wrapper'),
        input: 'input',
        helperWrapper: 'helper-wrapper'
      }}
      errorMessage={errorMessage}
      endContent={<ClockIcon />}
      isInvalid={error}
      value={parseTimeFromISO(value)}
      aria-label="Time input"
      onChange={handleChange}
      {...props}
    />
  )
}
