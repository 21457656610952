import { OBJECT_TYPES } from '../../constants/workflows'

export const groupWorkflowMenu = array => {
  const groups = {}
  if (!array || !array.length) {
    return groups
  }

  array.forEach(item => {
    if (!item.group) {
      return
    }

    if (!groups[item.group]) {
      groups[item.group] = []
    }

    groups[item.group].push(item)
  })

  return groups
}

export const filterWorkflowMenu = (array, search) => {
  if (!search || !search.trim()) {
    return array
  }
  return array.filter(item => item.name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1)
}

export const getSuggestionsType = type => {
  switch (type) {
    case OBJECT_TYPES.CARD:
      return 'cardName'
    case OBJECT_TYPES.WIDGET:
      return 'widgetTitle'
    case OBJECT_TYPES.COLUMN:
      return 'columnName'
    default:
      return ''
  }
}
