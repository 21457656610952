/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback } from 'react'
import { updateWorkflowBlock } from 'actions'
import { useDispatch, useSelector } from 'react-redux'
import { getBlock } from 'selectors/workflowBuilder.selectors'

export const useUpdateBlockMeta = (id: string | null) => {
  const dispatch = useDispatch()
  const { meta, error } = useSelector(getBlock(id))

  return useCallback(
    (data: Record<string, unknown>) => {
      dispatch(
        // @ts-expect-error
        updateWorkflowBlock({
          id,
          data: {
            meta: { ...meta, ...data },
            error
          }
        })
      )
    },
    [dispatch, meta, error, id]
  )
}
