import { type Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import classNames from 'classnames'
import DotsIcon from 'assets/images/icons/dots.svg?react'
import HintIcon from 'assets/images/icons/hint_icon.svg?react'
import Close from 'assets/images/icons/ic_cross_xl_B5BFC7.svg?react'
import messages from 'constants/messages'
import { IconButton } from 'components/buttons/iconButton/iconButton'
import { TextField } from 'components/common/textField/textField'
import { MAX_DICTIONARY_MANUAL_OPTION_LENGTH } from '../../dictionariesSettings.constants'
import { type TOption } from '../../dictionariesSettings.types'
import { useOptionItemDragging } from './useOptionItemDragging'
import './option-item.scss'

type TProps = {
  index?: number
  deleteOption?: () => void
  changeOptionValue?: (value: string) => void
  onDrop?: ({
    indexOfSource,
    indexOfTarget,
    closestEdgeOfTarget
  }: {
    indexOfSource: number
    indexOfTarget: number
    closestEdgeOfTarget: Edge | null
  }) => void
  option: TOption
  deleteDisabled: boolean
  hasError: boolean
  isQuerySelected?: boolean
  isHint?: boolean
  getOptionErrorText?: (props: { index: number; value: string }) => string
}

export const OptionItem = ({
  index,
  option,
  deleteOption,
  changeOptionValue,
  onDrop,
  deleteDisabled,
  hasError,
  isQuerySelected,
  isHint,
  getOptionErrorText
}: TProps) => {
  const { value } = option

  const { ref, dragHandleRef, dropIndicator, isDragging } = useOptionItemDragging({ index, onDrop })

  const getErrorMessage = () => {
    if (isHint) {
      return messages.NO_EMPTY_HINT_TEXT
    }

    if (getOptionErrorText && index !== undefined) {
      return getOptionErrorText({ index, value })
    }

    return messages.emptyField
  }

  const canDrag = !isQuerySelected && !isHint && !!changeOptionValue

  return (
    <div className={classNames('dictionary-option-item', { dragging: isDragging })}>
      <div ref={ref} className={classNames('content')}>
        {canDrag && (
          <div ref={dragHandleRef} className="drag-handle">
            <DotsIcon className="icon" />
          </div>
        )}
        <div className={classNames('option-data', { 'hint-option': isHint })}>
          <TextField
            className="option-value"
            defaultValue={value}
            size={34}
            inputProps={{ maxLength: MAX_DICTIONARY_MANUAL_OPTION_LENGTH }}
            error={hasError}
            disabled={!changeOptionValue || isQuerySelected}
            helperText={hasError ? getErrorMessage() : ''}
            onBlur={({ target }) => changeOptionValue?.(target.value.trim())}
          />
        </div>
        {!isQuerySelected && !!changeOptionValue && (
          <IconButton
            size="medium"
            appearance="light-bg"
            className={classNames('delete-option-btn', { 'hint-btn': isHint })}
            tabIndex="-1"
            disabled={deleteDisabled || !deleteOption}
            icon={
              isHint ? <HintIcon title={messages.HINT_OPTION} /> : <Close title={messages.DELETE} />
            }
            onClick={deleteOption}
          />
        )}
      </div>
      {dropIndicator}
    </div>
  )
}
