import React from 'react'
import { components } from 'react-select'
import UserLogo from '../../../common/UserLogo'
import '../../../../scss/workflowBuilder/systemActions/menu-option.scss'

const MenuOption = props => {
  const userData = props.data

  return (
    <components.Option {...props}>
      {userData.email ? (
        <div className="menu-option">
          <UserLogo
            avatarImage={userData.avatarImage}
            username={userData.username}
            size="extra-small-24px"
          />
          <div className="menu-option-info">
            <p className="menu-option-title">{userData.email}</p>
            {userData.username && <p className="menu-option-subtitle">{userData.username}</p>}
          </div>
        </div>
      ) : (
        props.children
      )}
    </components.Option>
  )
}

export default MenuOption
