import React from 'react'
import UserLogo from '../../UserLogo'

const UserLevelItem = ({ item, onSelect }) => {
  const payload = { ...item }
  delete payload.emailAlias
  return (
    <div
      className="any-level-dropdown-item user-level-item"
      onMouseDown={onSelect.bind(null, payload)}
    >
      <UserLogo avatarImage={item.avatarImage} username={item.username} size="extra-small-24px" />
      <div className="any-level-dropdown-item-title bold center user-name">{item.username}</div>
      &nbsp;
      <p className="any-level-dropdown-item-subtitle user-email">({item.emailAlias})</p>
    </div>
  )
}

export default UserLevelItem
