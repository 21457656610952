import {
  type Channel,
  type CompatServiceDefinition,
  createChannel,
  createClient,
  FetchTransport
} from 'nice-grpc-web'

export class _GrpcService {
  private _istioHost = new URL(
    window.istioHost || import.meta.env.VITE_ISTIO_HOST || 'https://lxapidev.praxie.com/'
  ).origin

  createChannel = (host: string = this._istioHost) =>
    createChannel(host, FetchTransport({ credentials: 'include' }))

  private _channel = this.createChannel()

  createClient<Service extends CompatServiceDefinition>(
    service: Service,
    channel: Channel = this._channel
  ) {
    return createClient(service, channel)
  }
}

export const GrpcService = new _GrpcService()
