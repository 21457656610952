// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.3.0
//   protoc               v3.19.1
// source: useraccess/v1/public.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { type CallContext, type CallOptions } from "nice-grpc-common";

export const protobufPackage = "upboard.io.useraccess.v1";

export interface ListTenantMenuItem {
  id: string;
  name: string;
  tenantRole: string;
  memberCount: number;
}

export interface App {
  id: string;
  tenantId: string;
  name: string;
  boardCount: number;
}

export interface Board {
  id: string;
  tenantId: string;
  name: string;
  boardRole: string;
}

export interface ListTenantMenusRequest {
}

export interface ListTenantMenusResponse {
  tenants: ListTenantMenuItem[];
}

export interface ListTenantAppsRequest {
  /** required */
  tenantId: string;
  /** optional */
  paginationToken: string;
  /** positive, default 20, max 50 */
  pageSize: number;
}

export interface ListTenantAppsResponse {
  nextPageToken: string;
  apps: App[];
  totalCount: number;
}

export interface ListTenantAppBoardsRequest {
  /** required */
  tenantId: string;
  /** required */
  appId: string;
  /** optional */
  paginationToken: string;
  /** positive, default 20, max 50 */
  pageSize: number;
}

export interface ListTenantAppBoardsResponse {
  nextPageToken: string;
  boards: Board[];
  totalCount: number;
}

export interface ListTenantBoardsRequest {
  /** required */
  tenantId: string;
  /** optional */
  paginationToken: string;
  /** positive, default 20, max 50 */
  pageSize: number;
}

export interface ListTenantBoardsResponse {
  nextPageToken: string;
  boards: Board[];
  totalCount: number;
}

export interface AppSearchResult {
  id: string;
  tenantId: string;
  tenantName: string;
  name: string;
}

export interface BoardSearchResult {
  id: string;
  tenantId: string;
  tenantName: string;
  name: string;
  /** optional */
  appName: string;
}

export interface SearchAppsRequest {
  /** required, min length is 3 */
  query: string;
  /** optional */
  paginationToken: string;
  /** positive, default 20, max 50 */
  pageSize: number;
}

export interface SearchAppsResponse {
  nextPageToken: string;
  apps: AppSearchResult[];
  totalCount: number;
}

export interface SearchBoardsRequest {
  /** required, min length is 3 */
  query: string;
  /** optional */
  paginationToken: string;
  /** positive, default 20, max 50 */
  pageSize: number;
}

export interface SearchBoardsResponse {
  nextPageToken: string;
  boards: BoardSearchResult[];
  totalCount: number;
}

function createBaseListTenantMenuItem(): ListTenantMenuItem {
  return { id: "", name: "", tenantRole: "", memberCount: 0 };
}

export const ListTenantMenuItem: MessageFns<ListTenantMenuItem> = {
  encode(message: ListTenantMenuItem, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.tenantRole !== "") {
      writer.uint32(26).string(message.tenantRole);
    }
    if (message.memberCount !== 0) {
      writer.uint32(32).int64(message.memberCount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTenantMenuItem {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTenantMenuItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.tenantRole = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.memberCount = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListTenantMenuItem>, I>>(base?: I): ListTenantMenuItem {
    return ListTenantMenuItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTenantMenuItem>, I>>(object: I): ListTenantMenuItem {
    const message = createBaseListTenantMenuItem();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.tenantRole = object.tenantRole ?? "";
    message.memberCount = object.memberCount ?? 0;
    return message;
  },
};

function createBaseApp(): App {
  return { id: "", tenantId: "", name: "", boardCount: 0 };
}

export const App: MessageFns<App> = {
  encode(message: App, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.tenantId !== "") {
      writer.uint32(18).string(message.tenantId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.boardCount !== 0) {
      writer.uint32(32).int64(message.boardCount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): App {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.boardCount = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<App>, I>>(base?: I): App {
    return App.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<App>, I>>(object: I): App {
    const message = createBaseApp();
    message.id = object.id ?? "";
    message.tenantId = object.tenantId ?? "";
    message.name = object.name ?? "";
    message.boardCount = object.boardCount ?? 0;
    return message;
  },
};

function createBaseBoard(): Board {
  return { id: "", tenantId: "", name: "", boardRole: "" };
}

export const Board: MessageFns<Board> = {
  encode(message: Board, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.tenantId !== "") {
      writer.uint32(18).string(message.tenantId);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.boardRole !== "") {
      writer.uint32(34).string(message.boardRole);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Board {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBoard();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.boardRole = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Board>, I>>(base?: I): Board {
    return Board.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Board>, I>>(object: I): Board {
    const message = createBaseBoard();
    message.id = object.id ?? "";
    message.tenantId = object.tenantId ?? "";
    message.name = object.name ?? "";
    message.boardRole = object.boardRole ?? "";
    return message;
  },
};

function createBaseListTenantMenusRequest(): ListTenantMenusRequest {
  return {};
}

export const ListTenantMenusRequest: MessageFns<ListTenantMenusRequest> = {
  encode(_: ListTenantMenusRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTenantMenusRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTenantMenusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListTenantMenusRequest>, I>>(base?: I): ListTenantMenusRequest {
    return ListTenantMenusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTenantMenusRequest>, I>>(_: I): ListTenantMenusRequest {
    const message = createBaseListTenantMenusRequest();
    return message;
  },
};

function createBaseListTenantMenusResponse(): ListTenantMenusResponse {
  return { tenants: [] };
}

export const ListTenantMenusResponse: MessageFns<ListTenantMenusResponse> = {
  encode(message: ListTenantMenusResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.tenants) {
      ListTenantMenuItem.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTenantMenusResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTenantMenusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.tenants.push(ListTenantMenuItem.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListTenantMenusResponse>, I>>(base?: I): ListTenantMenusResponse {
    return ListTenantMenusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTenantMenusResponse>, I>>(object: I): ListTenantMenusResponse {
    const message = createBaseListTenantMenusResponse();
    message.tenants = object.tenants?.map((e) => ListTenantMenuItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListTenantAppsRequest(): ListTenantAppsRequest {
  return { tenantId: "", paginationToken: "", pageSize: 0 };
}

export const ListTenantAppsRequest: MessageFns<ListTenantAppsRequest> = {
  encode(message: ListTenantAppsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    if (message.paginationToken !== "") {
      writer.uint32(18).string(message.paginationToken);
    }
    if (message.pageSize !== 0) {
      writer.uint32(24).int64(message.pageSize);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTenantAppsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTenantAppsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.paginationToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.pageSize = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListTenantAppsRequest>, I>>(base?: I): ListTenantAppsRequest {
    return ListTenantAppsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTenantAppsRequest>, I>>(object: I): ListTenantAppsRequest {
    const message = createBaseListTenantAppsRequest();
    message.tenantId = object.tenantId ?? "";
    message.paginationToken = object.paginationToken ?? "";
    message.pageSize = object.pageSize ?? 0;
    return message;
  },
};

function createBaseListTenantAppsResponse(): ListTenantAppsResponse {
  return { nextPageToken: "", apps: [], totalCount: 0 };
}

export const ListTenantAppsResponse: MessageFns<ListTenantAppsResponse> = {
  encode(message: ListTenantAppsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.nextPageToken !== "") {
      writer.uint32(10).string(message.nextPageToken);
    }
    for (const v of message.apps) {
      App.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.totalCount !== 0) {
      writer.uint32(24).int64(message.totalCount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTenantAppsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTenantAppsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.apps.push(App.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.totalCount = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListTenantAppsResponse>, I>>(base?: I): ListTenantAppsResponse {
    return ListTenantAppsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTenantAppsResponse>, I>>(object: I): ListTenantAppsResponse {
    const message = createBaseListTenantAppsResponse();
    message.nextPageToken = object.nextPageToken ?? "";
    message.apps = object.apps?.map((e) => App.fromPartial(e)) || [];
    message.totalCount = object.totalCount ?? 0;
    return message;
  },
};

function createBaseListTenantAppBoardsRequest(): ListTenantAppBoardsRequest {
  return { tenantId: "", appId: "", paginationToken: "", pageSize: 0 };
}

export const ListTenantAppBoardsRequest: MessageFns<ListTenantAppBoardsRequest> = {
  encode(message: ListTenantAppBoardsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    if (message.appId !== "") {
      writer.uint32(18).string(message.appId);
    }
    if (message.paginationToken !== "") {
      writer.uint32(26).string(message.paginationToken);
    }
    if (message.pageSize !== 0) {
      writer.uint32(32).int64(message.pageSize);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTenantAppBoardsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTenantAppBoardsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.appId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.paginationToken = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.pageSize = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListTenantAppBoardsRequest>, I>>(base?: I): ListTenantAppBoardsRequest {
    return ListTenantAppBoardsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTenantAppBoardsRequest>, I>>(object: I): ListTenantAppBoardsRequest {
    const message = createBaseListTenantAppBoardsRequest();
    message.tenantId = object.tenantId ?? "";
    message.appId = object.appId ?? "";
    message.paginationToken = object.paginationToken ?? "";
    message.pageSize = object.pageSize ?? 0;
    return message;
  },
};

function createBaseListTenantAppBoardsResponse(): ListTenantAppBoardsResponse {
  return { nextPageToken: "", boards: [], totalCount: 0 };
}

export const ListTenantAppBoardsResponse: MessageFns<ListTenantAppBoardsResponse> = {
  encode(message: ListTenantAppBoardsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.nextPageToken !== "") {
      writer.uint32(10).string(message.nextPageToken);
    }
    for (const v of message.boards) {
      Board.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.totalCount !== 0) {
      writer.uint32(24).int64(message.totalCount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTenantAppBoardsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTenantAppBoardsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.boards.push(Board.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.totalCount = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListTenantAppBoardsResponse>, I>>(base?: I): ListTenantAppBoardsResponse {
    return ListTenantAppBoardsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTenantAppBoardsResponse>, I>>(object: I): ListTenantAppBoardsResponse {
    const message = createBaseListTenantAppBoardsResponse();
    message.nextPageToken = object.nextPageToken ?? "";
    message.boards = object.boards?.map((e) => Board.fromPartial(e)) || [];
    message.totalCount = object.totalCount ?? 0;
    return message;
  },
};

function createBaseListTenantBoardsRequest(): ListTenantBoardsRequest {
  return { tenantId: "", paginationToken: "", pageSize: 0 };
}

export const ListTenantBoardsRequest: MessageFns<ListTenantBoardsRequest> = {
  encode(message: ListTenantBoardsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.tenantId !== "") {
      writer.uint32(10).string(message.tenantId);
    }
    if (message.paginationToken !== "") {
      writer.uint32(18).string(message.paginationToken);
    }
    if (message.pageSize !== 0) {
      writer.uint32(24).int64(message.pageSize);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTenantBoardsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTenantBoardsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.paginationToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.pageSize = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListTenantBoardsRequest>, I>>(base?: I): ListTenantBoardsRequest {
    return ListTenantBoardsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTenantBoardsRequest>, I>>(object: I): ListTenantBoardsRequest {
    const message = createBaseListTenantBoardsRequest();
    message.tenantId = object.tenantId ?? "";
    message.paginationToken = object.paginationToken ?? "";
    message.pageSize = object.pageSize ?? 0;
    return message;
  },
};

function createBaseListTenantBoardsResponse(): ListTenantBoardsResponse {
  return { nextPageToken: "", boards: [], totalCount: 0 };
}

export const ListTenantBoardsResponse: MessageFns<ListTenantBoardsResponse> = {
  encode(message: ListTenantBoardsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.nextPageToken !== "") {
      writer.uint32(10).string(message.nextPageToken);
    }
    for (const v of message.boards) {
      Board.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.totalCount !== 0) {
      writer.uint32(24).int64(message.totalCount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ListTenantBoardsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListTenantBoardsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.boards.push(Board.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.totalCount = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ListTenantBoardsResponse>, I>>(base?: I): ListTenantBoardsResponse {
    return ListTenantBoardsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListTenantBoardsResponse>, I>>(object: I): ListTenantBoardsResponse {
    const message = createBaseListTenantBoardsResponse();
    message.nextPageToken = object.nextPageToken ?? "";
    message.boards = object.boards?.map((e) => Board.fromPartial(e)) || [];
    message.totalCount = object.totalCount ?? 0;
    return message;
  },
};

function createBaseAppSearchResult(): AppSearchResult {
  return { id: "", tenantId: "", tenantName: "", name: "" };
}

export const AppSearchResult: MessageFns<AppSearchResult> = {
  encode(message: AppSearchResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.tenantId !== "") {
      writer.uint32(18).string(message.tenantId);
    }
    if (message.tenantName !== "") {
      writer.uint32(26).string(message.tenantName);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AppSearchResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAppSearchResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.tenantName = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AppSearchResult>, I>>(base?: I): AppSearchResult {
    return AppSearchResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AppSearchResult>, I>>(object: I): AppSearchResult {
    const message = createBaseAppSearchResult();
    message.id = object.id ?? "";
    message.tenantId = object.tenantId ?? "";
    message.tenantName = object.tenantName ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseBoardSearchResult(): BoardSearchResult {
  return { id: "", tenantId: "", tenantName: "", name: "", appName: "" };
}

export const BoardSearchResult: MessageFns<BoardSearchResult> = {
  encode(message: BoardSearchResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.tenantId !== "") {
      writer.uint32(18).string(message.tenantId);
    }
    if (message.tenantName !== "") {
      writer.uint32(26).string(message.tenantName);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    if (message.appName !== "") {
      writer.uint32(42).string(message.appName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BoardSearchResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBoardSearchResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.tenantName = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.appName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BoardSearchResult>, I>>(base?: I): BoardSearchResult {
    return BoardSearchResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BoardSearchResult>, I>>(object: I): BoardSearchResult {
    const message = createBaseBoardSearchResult();
    message.id = object.id ?? "";
    message.tenantId = object.tenantId ?? "";
    message.tenantName = object.tenantName ?? "";
    message.name = object.name ?? "";
    message.appName = object.appName ?? "";
    return message;
  },
};

function createBaseSearchAppsRequest(): SearchAppsRequest {
  return { query: "", paginationToken: "", pageSize: 0 };
}

export const SearchAppsRequest: MessageFns<SearchAppsRequest> = {
  encode(message: SearchAppsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.query !== "") {
      writer.uint32(10).string(message.query);
    }
    if (message.paginationToken !== "") {
      writer.uint32(18).string(message.paginationToken);
    }
    if (message.pageSize !== 0) {
      writer.uint32(24).int64(message.pageSize);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SearchAppsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchAppsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.query = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.paginationToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.pageSize = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchAppsRequest>, I>>(base?: I): SearchAppsRequest {
    return SearchAppsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchAppsRequest>, I>>(object: I): SearchAppsRequest {
    const message = createBaseSearchAppsRequest();
    message.query = object.query ?? "";
    message.paginationToken = object.paginationToken ?? "";
    message.pageSize = object.pageSize ?? 0;
    return message;
  },
};

function createBaseSearchAppsResponse(): SearchAppsResponse {
  return { nextPageToken: "", apps: [], totalCount: 0 };
}

export const SearchAppsResponse: MessageFns<SearchAppsResponse> = {
  encode(message: SearchAppsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.nextPageToken !== "") {
      writer.uint32(10).string(message.nextPageToken);
    }
    for (const v of message.apps) {
      AppSearchResult.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.totalCount !== 0) {
      writer.uint32(24).int64(message.totalCount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SearchAppsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchAppsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.apps.push(AppSearchResult.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.totalCount = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchAppsResponse>, I>>(base?: I): SearchAppsResponse {
    return SearchAppsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchAppsResponse>, I>>(object: I): SearchAppsResponse {
    const message = createBaseSearchAppsResponse();
    message.nextPageToken = object.nextPageToken ?? "";
    message.apps = object.apps?.map((e) => AppSearchResult.fromPartial(e)) || [];
    message.totalCount = object.totalCount ?? 0;
    return message;
  },
};

function createBaseSearchBoardsRequest(): SearchBoardsRequest {
  return { query: "", paginationToken: "", pageSize: 0 };
}

export const SearchBoardsRequest: MessageFns<SearchBoardsRequest> = {
  encode(message: SearchBoardsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.query !== "") {
      writer.uint32(10).string(message.query);
    }
    if (message.paginationToken !== "") {
      writer.uint32(18).string(message.paginationToken);
    }
    if (message.pageSize !== 0) {
      writer.uint32(24).int64(message.pageSize);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SearchBoardsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchBoardsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.query = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.paginationToken = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.pageSize = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchBoardsRequest>, I>>(base?: I): SearchBoardsRequest {
    return SearchBoardsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchBoardsRequest>, I>>(object: I): SearchBoardsRequest {
    const message = createBaseSearchBoardsRequest();
    message.query = object.query ?? "";
    message.paginationToken = object.paginationToken ?? "";
    message.pageSize = object.pageSize ?? 0;
    return message;
  },
};

function createBaseSearchBoardsResponse(): SearchBoardsResponse {
  return { nextPageToken: "", boards: [], totalCount: 0 };
}

export const SearchBoardsResponse: MessageFns<SearchBoardsResponse> = {
  encode(message: SearchBoardsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.nextPageToken !== "") {
      writer.uint32(10).string(message.nextPageToken);
    }
    for (const v of message.boards) {
      BoardSearchResult.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.totalCount !== 0) {
      writer.uint32(24).int64(message.totalCount);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SearchBoardsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchBoardsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.boards.push(BoardSearchResult.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.totalCount = longToNumber(reader.int64());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchBoardsResponse>, I>>(base?: I): SearchBoardsResponse {
    return SearchBoardsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchBoardsResponse>, I>>(object: I): SearchBoardsResponse {
    const message = createBaseSearchBoardsResponse();
    message.nextPageToken = object.nextPageToken ?? "";
    message.boards = object.boards?.map((e) => BoardSearchResult.fromPartial(e)) || [];
    message.totalCount = object.totalCount ?? 0;
    return message;
  },
};

/**
 * All of its methods are designed to be used by front-end client.
 * Authentication token is required to access this service.
 */
export type UserAccessPublicDefinition = typeof UserAccessPublicDefinition;
export const UserAccessPublicDefinition = {
  name: "UserAccessPublic",
  fullName: "upboard.io.useraccess.v1.UserAccessPublic",
  methods: {
    /** ListTenantMenus returns a list of tenants with their respective member count. */
    listTenantMenus: {
      name: "ListTenantMenus",
      requestType: ListTenantMenusRequest,
      requestStream: false,
      responseType: ListTenantMenusResponse,
      responseStream: false,
      options: {},
    },
    /** ListTenantApps returns a paginated list of apps of a tenant. */
    listTenantApps: {
      name: "ListTenantApps",
      requestType: ListTenantAppsRequest,
      requestStream: false,
      responseType: ListTenantAppsResponse,
      responseStream: false,
      options: {},
    },
    /** ListTenantAppBoards returns a paginated list of boards of an app of a tenant. */
    listTenantAppBoards: {
      name: "ListTenantAppBoards",
      requestType: ListTenantAppBoardsRequest,
      requestStream: false,
      responseType: ListTenantAppBoardsResponse,
      responseStream: false,
      options: {},
    },
    /** ListTenantBoards returns a paginated list of boards that don't belong to any app inside tenant. */
    listTenantBoards: {
      name: "ListTenantBoards",
      requestType: ListTenantBoardsRequest,
      requestStream: false,
      responseType: ListTenantBoardsResponse,
      responseStream: false,
      options: {},
    },
    /** SearchApps performs app search. */
    searchApps: {
      name: "SearchApps",
      requestType: SearchAppsRequest,
      requestStream: false,
      responseType: SearchAppsResponse,
      responseStream: false,
      options: {},
    },
    /** SearchBoards performs board search. */
    searchBoards: {
      name: "SearchBoards",
      requestType: SearchBoardsRequest,
      requestStream: false,
      responseType: SearchBoardsResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface UserAccessPublicServiceImplementation<CallContextExt = {}> {
  /** ListTenantMenus returns a list of tenants with their respective member count. */
  listTenantMenus(
    request: ListTenantMenusRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<ListTenantMenusResponse>>;
  /** ListTenantApps returns a paginated list of apps of a tenant. */
  listTenantApps(
    request: ListTenantAppsRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<ListTenantAppsResponse>>;
  /** ListTenantAppBoards returns a paginated list of boards of an app of a tenant. */
  listTenantAppBoards(
    request: ListTenantAppBoardsRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<ListTenantAppBoardsResponse>>;
  /** ListTenantBoards returns a paginated list of boards that don't belong to any app inside tenant. */
  listTenantBoards(
    request: ListTenantBoardsRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<ListTenantBoardsResponse>>;
  /** SearchApps performs app search. */
  searchApps(
    request: SearchAppsRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<SearchAppsResponse>>;
  /** SearchBoards performs board search. */
  searchBoards(
    request: SearchBoardsRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<SearchBoardsResponse>>;
}

export interface UserAccessPublicClient<CallOptionsExt = {}> {
  /** ListTenantMenus returns a list of tenants with their respective member count. */
  listTenantMenus(
    request: DeepPartial<ListTenantMenusRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<ListTenantMenusResponse>;
  /** ListTenantApps returns a paginated list of apps of a tenant. */
  listTenantApps(
    request: DeepPartial<ListTenantAppsRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<ListTenantAppsResponse>;
  /** ListTenantAppBoards returns a paginated list of boards of an app of a tenant. */
  listTenantAppBoards(
    request: DeepPartial<ListTenantAppBoardsRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<ListTenantAppBoardsResponse>;
  /** ListTenantBoards returns a paginated list of boards that don't belong to any app inside tenant. */
  listTenantBoards(
    request: DeepPartial<ListTenantBoardsRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<ListTenantBoardsResponse>;
  /** SearchApps performs app search. */
  searchApps(
    request: DeepPartial<SearchAppsRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<SearchAppsResponse>;
  /** SearchBoards performs board search. */
  searchBoards(
    request: DeepPartial<SearchBoardsRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<SearchBoardsResponse>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
