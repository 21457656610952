import React, { Component } from 'react'
import classNames from 'classnames'
import messages from 'constants/messages'
import { sanitize } from 'helpers/builderHelpers'
import RibbonGroup from 'components/ribbonTools/RibbonGroup'
import { SMART_LINK_MODES } from 'constants/cardBuilder/widgetsConstants'
import { SmartLinkModal } from 'components/modals/smartLink/smartLinkModal'
import RibbonIconButton from 'components/ribbonTools/RibbonIconButton'
import { RibbonTextSizeDropdown } from '../ribbonTextSizeDropdown/ribbonTextSizeDropdown'
import { isNodePartOfAnyLevelDropdown } from '../tokenInputBox.helpers'
import { LEVEL_DROPDOWN_CLASSNAME } from '../tokenInputBox.constants'
import './comment-box-ribbon.scss'

const LIST_ITEMS = [
  {
    checked: true,
    styles: 'headline-text',
    fontSize: 20,
    message: messages.HEADLINE,
    heading: '# '
  },
  {
    checked: false,
    styles: 'body-text',
    fontSize: 16,
    message: messages.BODY,
    heading: '## '
  },
  {
    checked: false,
    styles: 'caption-text',
    fontSize: 12,
    message: messages.CAPTION,
    heading: '### '
  }
]

export default class CommentBoxRibbon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSmartLink: false,
      prevRange: null,
      listItems: LIST_ITEMS
    }
  }

  selectItem = itemId => {
    const { listItems } = this.state
    this.setState({
      listItems: listItems.map((item, i) => ({
        ...item,
        checked: itemId === i
      }))
    })

    this.surroundTextWith(listItems[itemId].heading, '')
  }

  nextNode = node => {
    if (node.hasChildNodes()) {
      return node.firstChild
    }
    while (node && !node.nextSibling) {
      // eslint-disable-next-line no-param-reassign
      node = node.parentNode
    }
    if (!node) {
      return null
    }
    return node.nextSibling
  }

  getRangeSelectedNodes = range => {
    let node = range.startContainer
    const endNode = range.endContainer

    // Special case for a range that is contained within a single node
    if (node === endNode) {
      return [node]
    }

    // Iterate nodes until we hit the end container
    const rangeNodes = []
    while (node && node !== endNode) {
      rangeNodes.push((node = this.nextNode(node)))
    }

    // Add partially selected nodes at the start of the range
    node = range.startContainer
    while (node && node !== range.commonAncestorContainer) {
      rangeNodes.unshift(node)
      node = node.parentNode
    }

    return rangeNodes
  }

  getSelectedNodes = () => {
    if (window.getSelection) {
      const sel = window.getSelection()
      if (!sel.isCollapsed) {
        return this.getRangeSelectedNodes(sel.getRangeAt(0))
      }
    }
    return []
  }

  surroundTextWith = (startMarkdownSymbol, endMarkDownSymbol) => {
    const sel = window.getSelection()
    const { commentBoxRef } = this.props
    const commentBox = commentBoxRef.current
    const isInCommentBox = commentBox.contains(sel.anchorNode)

    if (!isInCommentBox) {
      // prevent any actions in case when selection not inside comment box;
      return
    }

    if (sel.rangeCount > 0) {
      const range = sel.getRangeAt(0)
      const startNode = range.startContainer
      const startOffset = range.startOffset
      const startTextNode = document.createTextNode(
        startOffset === 0 ? startMarkdownSymbol.replace('\n', '') : startMarkdownSymbol
      )
      const endTextNode = document.createTextNode(endMarkDownSymbol)

      const boundaryRange = range.cloneRange()
      boundaryRange.collapse(false)
      boundaryRange.insertNode(endTextNode)
      boundaryRange.setStart(startNode, startOffset)
      boundaryRange.collapse(true)
      boundaryRange.insertNode(startTextNode)

      range.setStartAfter(startTextNode)
      range.setEndBefore(endTextNode)
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }

  setLink = link => {
    const { prevRange } = this.state
    const { textLimit } = this.props
    const croppedLink = link.substring(0, textLimit)

    setTimeout(() => {
      const sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(prevRange)
      this.surroundTextWith('[', `](${sanitize(croppedLink)})`)
    }, 100)
    this.setState({ prevRange: null })
  }

  toggleSmartLinkModal = () => {
    this.setState(({ showSmartLink }) => ({ showSmartLink: !showSmartLink }))
  }

  openLinkModal = () => {
    const sel = window.getSelection()
    const range = sel.getRangeAt(0)
    this.setState({ prevRange: range }, this.toggleSmartLinkModal)
  }

  setListPrefix = startMarkdownSymbol => {
    const sel = window.getSelection()

    if (sel.type === 'Range') {
      this.setListToTheSelectedRange(startMarkdownSymbol)
      this.props.updateCommentBoxMeta()
      sel.collapseToEnd()
      return
    }

    if (sel.type === 'Caret') {
      this.setListToTheCaretSelection(startMarkdownSymbol)
      this.props.updateCommentBoxMeta()
    }
  }

  setListToTheCaretSelection = startMarkdownSymbol => {
    const sel = window.getSelection()
    const range = sel.getRangeAt(0)
    const { commentBoxRef } = this.props
    const commentBox = commentBoxRef.current
    // TODO handle case when caret on the text node and token at the right
    if (sel.anchorNode.isEqualNode(commentBox)) {
      // handle case when caret endOffset
      // in the token and token is last element of commentBox
      if (sel.anchorOffset === 0) {
        range.setStart(sel.anchorNode, 0)
      } else {
        range.setStart(sel.anchorNode, sel.anchorOffset - sel.rangeCount)
      }
      range.setStart(sel.anchorNode, sel.anchorOffset)
      this.surroundTextWith(startMarkdownSymbol.replace('\n', ''), '')
      return
    }

    const startMarkdownForFirstRow = startMarkdownSymbol.replace('\n', '')

    if (sel.anchorNode.nodeType === Node.TEXT_NODE && sel.anchorOffset === 0) {
      const span = sel.anchorNode.parentElement
      const prevElement = span.previousElementSibling
      const isPrevNodeAnyLevelDropdown =
        prevElement &&
        prevElement.classList &&
        prevElement.classList[0] === LEVEL_DROPDOWN_CLASSNAME
      if (isPrevNodeAnyLevelDropdown) {
        commentBox.insertBefore(document.createTextNode(startMarkdownForFirstRow), prevElement)
        return
      }
    }

    const indexOfNewLine = sel.anchorNode.textContent.lastIndexOf('\n')
    range.setStart(sel.anchorNode, indexOfNewLine + 1)
    this.surroundTextWith(startMarkdownSymbol.replace('\n', ''), '')
  }

  setListToTheSelectedRange = startMarkdownSymbol => {
    const { commentBoxRef } = this.props
    const commentBox = commentBoxRef.current

    const selectedNodes = this.getSelectedNodes()
    const [firstSelectedChild] = selectedNodes
    const startMarkdownForFirstRow = startMarkdownSymbol.replace('\n', '')

    if (firstSelectedChild.isEqualNode(commentBox)) {
      // case when only tokens was selected
      commentBox.childNodes.forEach(node => {
        if (commentBox.firstChild.isEqualNode(node)) {
          commentBox.insertBefore(document.createTextNode(startMarkdownForFirstRow), node)
        }

        if (!isNodePartOfAnyLevelDropdown(node)) {
          node.textContent = node.textContent.replace(/\n/g, startMarkdownSymbol)
        }
      })

      return
    }

    const onlyEditableNodes = selectedNodes.filter(Boolean).filter(node => {
      const isNodeToken = node.classList && node.classList[0] === LEVEL_DROPDOWN_CLASSNAME
      const isNodePartOfToken = isNodePartOfAnyLevelDropdown(node)
      return !isNodeToken && !isNodePartOfToken
    })

    const [firstEditableNode] = onlyEditableNodes
    if (commentBox.firstChild.isEqualNode(firstEditableNode)) {
      firstEditableNode.textContent = `${startMarkdownForFirstRow}${firstEditableNode.textContent}`
    }

    onlyEditableNodes.forEach(text => {
      if (text.textContent.indexOf('\n') >= 0) {
        text.textContent = text.textContent.replace(/\n/g, startMarkdownSymbol)
      }
    })
  }

  render() {
    const {
      error,
      commentBoxRibbonRef,
      textLimit,
      ribbonConfig: { bold, italic, underline, list, fontSize, link }
    } = this.props
    const { listItems, showSmartLink } = this.state

    return (
      <div
        ref={commentBoxRibbonRef}
        tabIndex={-1}
        className={classNames('comment-box-ribbon', {
          'has-error': !!error,
          disabled: textLimit <= 0
        })}
        id="comment-box-ribbon"
      >
        <RibbonGroup margin={8}>
          {bold && (
            <RibbonIconButton
              id="comment-box-bold-button"
              onClick={this.surroundTextWith.bind(null, '**', '**')}
            >
              <i className="up-font-ic-text-bold" />
            </RibbonIconButton>
          )}
          {italic && (
            <RibbonIconButton
              id="comment-box-italic-button"
              onClick={this.surroundTextWith.bind(null, '*', '*')}
            >
              <i className="up-font-ic-text-italic" />
            </RibbonIconButton>
          )}
          {underline && (
            <RibbonIconButton
              id="comment-box-underline-button"
              onClick={this.surroundTextWith.bind(null, '___', '___')}
            >
              <i className="icon icon-text-underline" />
            </RibbonIconButton>
          )}
        </RibbonGroup>
        {fontSize && <RibbonTextSizeDropdown listItems={listItems} selectItem={this.selectItem} />}
        {list && (
          <RibbonGroup margin={8}>
            <RibbonIconButton
              id="comment-box-bullet-list-button"
              onClick={this.setListPrefix.bind(null, '\n* ')}
            >
              <i className="up-font-ic-bulletlist" />
            </RibbonIconButton>
            <RibbonIconButton
              id="comment-box-numbered-list-button"
              onClick={this.setListPrefix.bind(null, '\n1. ')}
            >
              <i className="up-font-ic-numberedlist" />
            </RibbonIconButton>
          </RibbonGroup>
        )}
        {link && (
          <RibbonIconButton id="comment-box-link-button" onClick={this.openLinkModal}>
            <i className="up-font-ic-link" />
          </RibbonIconButton>
        )}
        {link && (
          <SmartLinkModal
            customStyles="comment-box-link"
            show={showSmartLink}
            onModalConfirm={this.setLink}
            onModalCancel={this.toggleSmartLinkModal}
            tabs={[]}
            mode={SMART_LINK_MODES.WEB_LINK}
          />
        )}
      </div>
    )
  }
}
