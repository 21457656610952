// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.3.0
//   protoc               v3.19.1
// source: uploader/v1/uploader.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { type CallContext, type CallOptions } from "nice-grpc-common";
import { GetResponseType } from "../../common/api.pb";
import { Key, Scope } from "../../common/key.pb";

export const protobufPackage = "upboard.io.uploader.v1";

export enum FileFolder {
  WIDGET_FOLDER = 0,
  SNAPSHOT_FOLDER = 1,
  APP_FOLDER = 2,
  BUNDLE_FOLDER = 3,
  UNRECOGNIZED = -1,
}

export enum BundleStatus {
  BUNDLE_STATUS_FREE = 0,
  BUNDLE_STATUS_PREMIUM = 1,
  UNRECOGNIZED = -1,
}

export interface AddBundleRequest {
  info?: BundleData | undefined;
  chunkData?: Uint8Array | undefined;
}

export interface AddBundleResponse {
  key: Key | undefined;
}

export interface UploadDefaultBundleResourceRequest {
  info?: BundleData | undefined;
  chunkData?: Uint8Array | undefined;
}

export interface BundleData {
  key: Key | undefined;
  info: File | undefined;
  existedBundleUuid: string;
}

export interface UploadFileRequest {
  key: Key | undefined;
  info: File | undefined;
  isPublic: boolean;
  createThumbnail: boolean;
  folder: FileFolder;
  data: Uint8Array;
}

export interface UploadBundleRequest {
  key: Key | undefined;
  info: File | undefined;
  folder: FileFolder;
  data: Uint8Array;
}

export interface UploadAvatarRequest {
  key: Key | undefined;
  info:
    | File
    | undefined;
  /** use for user logo */
  userId: string;
  data: Uint8Array;
  type: UploadAvatarRequestAvatarType;
  clear: boolean;
  /** use for app logo */
  appId: string;
}

export enum UploadAvatarRequestAvatarType {
  FOR_USER = 0,
  FOR_ORGANISATION = 1,
  FOR_APP = 2,
  UNRECOGNIZED = -1,
}

export interface UploadTemplateRequest {
  key: Key | undefined;
  info: File | undefined;
  appId: string;
  data: Uint8Array;
}

export interface UploadFileRequestByStream {
  info?: FileData | undefined;
  chunkData?: Uint8Array | undefined;
}

export interface FileData {
  key: Key | undefined;
  info: File | undefined;
  isPublic: boolean;
  createThumbnail: boolean;
  folder: FileFolder;
}

export interface GetBundleMetadataRequest {
  key: Key | undefined;
}

export interface BundleMetadataResponse {
  result: BundleMetadataResponseResult | undefined;
}

export interface BundleMetadataResponseResult {
  type: GetResponseType;
  key: Key | undefined;
  metadata: FileMetadata[];
}

export interface CreatedFileResponse {
  key: Key | undefined;
  info: File | undefined;
  fileLink: string;
  thumbnailLink: string;
}

export interface UploadFileResponse {
  key: Key | undefined;
  info: File | undefined;
  fileLink: string;
  thumbnailLink: string;
  widgetClassName: string;
  /** for image */
  width: number;
  height: number;
  /** foe csv */
  csvRows: string;
  /** for svg */
  strokeColor: string;
  strokeWidth: string;
  fillColor: string;
}

export interface File {
  name: string;
  mimeType: string;
}

export interface FileMetadata {
  description: string;
  videoLink: string;
  presentationName: string;
  uuid: string;
  status: BundleStatus;
  boardList: string;
  isDefault: boolean;
  images: string[];
  order: string;
}

export interface UpdateBundleMetadataRequest {
  metadataItem: BundleMetadataItem | undefined;
}

export interface BundleMetadataItem {
  key: Key | undefined;
  metadata: FileMetadata | undefined;
}

export interface FileSignLinkRequest {
  items: FileSignLinkRequestFileSignLinkItem[];
}

export enum FileSignLinkRequestSignLinkType {
  FOR_BOARD_FILE = 0,
  FOR_TEMPLATE = 1,
  UNRECOGNIZED = -1,
}

export interface FileSignLinkRequestFileSignLinkItem {
  key: Key | undefined;
  filename: string;
  /** default some minutes - max - 8 hour */
  signTimeout: number;
  signType: FileSignLinkRequestSignLinkType;
  appId: string;
}

export interface GenerateSignedLinkRequest {
  key: Key | undefined;
  filename: string;
  appId: string;
  countLink: number;
  linkType: GenerateSignedLinkRequestSignedLinkType;
}

export enum GenerateSignedLinkRequestSignedLinkType {
  FOR_TEMPLATE_LOGO = 0,
  FOR_APP_STORE_INFO = 1,
  UNRECOGNIZED = -1,
}

export interface GenerateSignedLinkResponse {
  results: GenerateSignedLinkResponseResult[];
}

export interface GenerateSignedLinkResponseResult {
  key: Key | undefined;
  appId: string;
  signedLink: string;
  fields: { [key: string]: string };
}

export interface GenerateSignedLinkResponseResultFieldsEntry {
  key: string;
  value: string;
}

export interface DeleteFileRequest {
  key: Key | undefined;
}

export interface DeleteFileRequestByNames {
  key: Key | undefined;
  filenames: string[];
}

export interface FileSignLinkResponse {
  results: FileSignLinkResponseResult[];
}

export interface FileSignLinkResponseResult {
  type: GetResponseType;
  signedLink: string;
  encodedFilename: string;
}

export interface CopyBoardFilesResponse {
  results: CreatedFileResponse[];
}

export interface EmptyResponse {
}

export interface GetBoardFileRequest {
  key: Key | undefined;
  filename: string;
}

export interface DataChunk {
  data: Uint8Array;
  size: number;
}

export interface CopyFileRequest {
  srcKey: Key | undefined;
  srcFilename: string;
  dstKey: Key | undefined;
  dstFilename: string;
  folder: FileFolder;
}

export interface ZipAndUploadBundleRequest {
  key: Key | undefined;
  boardIds: string[];
  bundleName: string;
  serializedEntities: string;
  appId: string;
}

export interface ZipAndUploadBundleResponse {
  filepath: string;
}

export interface GetFileFromArchiveRequest {
  key: Key | undefined;
  bundleName: string;
  fileName: string;
}

export interface ImportBundleFilesRequest {
  newKey: Key | undefined;
  oldKey: Key | undefined;
  bundleName: string;
  cardUuidsMap: { [key: string]: string };
}

export interface ImportBundleFilesRequestCardUuidsMapEntry {
  key: string;
  value: string;
}

export interface CopyBoardFilesRequest {
  srcKey: Key | undefined;
  dstKey: Key | undefined;
  names: string[];
  /** Used for copy snapshots if provided */
  cardUuidsMap: { [key: string]: string };
}

export interface CopyBoardFilesRequestCardUuidsMapEntry {
  key: string;
  value: string;
}

export interface GetUserAvatarSignedLinkRequest {
  /** Field required */
  userId: string;
}

export interface GetUserAvatarSignedLinkResponse {
  link: string;
}

export interface ParseTableDataRowsRequest {
  /** Field required */
  filepath: string;
  /** Field required */
  batchSize: number;
  /** Field required */
  rowsLimit: number;
  /**
   * Field required. Default is 0.
   * Each step triggers appropriate logic
   * 1 - file uploaded
   * 2 - file validated
   * 0 or other - file parsing
   */
  step: number;
  /** Default false. If true sends error on step 0, 2 */
  isError: boolean;
}

export interface TableDataRowsResponse {
}

export interface UploadDatasetRequest {
  /** Field required */
  scope:
    | Scope
    | undefined;
  /** Field required */
  info:
    | File
    | undefined;
  /** Field required non-empty */
  data: Uint8Array;
}

export interface UploadDatasetResponse {
  parseProcessId: string;
}

export interface ErrorDetails {
  errorMessage: string;
}

function createBaseAddBundleRequest(): AddBundleRequest {
  return { info: undefined, chunkData: undefined };
}

export const AddBundleRequest: MessageFns<AddBundleRequest> = {
  encode(message: AddBundleRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.info !== undefined) {
      BundleData.encode(message.info, writer.uint32(10).fork()).join();
    }
    if (message.chunkData !== undefined) {
      writer.uint32(18).bytes(message.chunkData);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AddBundleRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddBundleRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.info = BundleData.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.chunkData = reader.bytes();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddBundleRequest>, I>>(base?: I): AddBundleRequest {
    return AddBundleRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddBundleRequest>, I>>(object: I): AddBundleRequest {
    const message = createBaseAddBundleRequest();
    message.info = (object.info !== undefined && object.info !== null)
      ? BundleData.fromPartial(object.info)
      : undefined;
    message.chunkData = object.chunkData ?? undefined;
    return message;
  },
};

function createBaseAddBundleResponse(): AddBundleResponse {
  return { key: undefined };
}

export const AddBundleResponse: MessageFns<AddBundleResponse> = {
  encode(message: AddBundleResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AddBundleResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddBundleResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AddBundleResponse>, I>>(base?: I): AddBundleResponse {
    return AddBundleResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddBundleResponse>, I>>(object: I): AddBundleResponse {
    const message = createBaseAddBundleResponse();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    return message;
  },
};

function createBaseUploadDefaultBundleResourceRequest(): UploadDefaultBundleResourceRequest {
  return { info: undefined, chunkData: undefined };
}

export const UploadDefaultBundleResourceRequest: MessageFns<UploadDefaultBundleResourceRequest> = {
  encode(message: UploadDefaultBundleResourceRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.info !== undefined) {
      BundleData.encode(message.info, writer.uint32(10).fork()).join();
    }
    if (message.chunkData !== undefined) {
      writer.uint32(18).bytes(message.chunkData);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadDefaultBundleResourceRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadDefaultBundleResourceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.info = BundleData.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.chunkData = reader.bytes();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UploadDefaultBundleResourceRequest>, I>>(
    base?: I,
  ): UploadDefaultBundleResourceRequest {
    return UploadDefaultBundleResourceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadDefaultBundleResourceRequest>, I>>(
    object: I,
  ): UploadDefaultBundleResourceRequest {
    const message = createBaseUploadDefaultBundleResourceRequest();
    message.info = (object.info !== undefined && object.info !== null)
      ? BundleData.fromPartial(object.info)
      : undefined;
    message.chunkData = object.chunkData ?? undefined;
    return message;
  },
};

function createBaseBundleData(): BundleData {
  return { key: undefined, info: undefined, existedBundleUuid: "" };
}

export const BundleData: MessageFns<BundleData> = {
  encode(message: BundleData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.info !== undefined) {
      File.encode(message.info, writer.uint32(18).fork()).join();
    }
    if (message.existedBundleUuid !== "") {
      writer.uint32(26).string(message.existedBundleUuid);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BundleData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBundleData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.info = File.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.existedBundleUuid = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BundleData>, I>>(base?: I): BundleData {
    return BundleData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BundleData>, I>>(object: I): BundleData {
    const message = createBaseBundleData();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.info = (object.info !== undefined && object.info !== null) ? File.fromPartial(object.info) : undefined;
    message.existedBundleUuid = object.existedBundleUuid ?? "";
    return message;
  },
};

function createBaseUploadFileRequest(): UploadFileRequest {
  return {
    key: undefined,
    info: undefined,
    isPublic: false,
    createThumbnail: false,
    folder: 0,
    data: new Uint8Array(0),
  };
}

export const UploadFileRequest: MessageFns<UploadFileRequest> = {
  encode(message: UploadFileRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.info !== undefined) {
      File.encode(message.info, writer.uint32(18).fork()).join();
    }
    if (message.isPublic !== false) {
      writer.uint32(24).bool(message.isPublic);
    }
    if (message.createThumbnail !== false) {
      writer.uint32(32).bool(message.createThumbnail);
    }
    if (message.folder !== 0) {
      writer.uint32(40).int32(message.folder);
    }
    if (message.data.length !== 0) {
      writer.uint32(50).bytes(message.data);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadFileRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadFileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.info = File.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.isPublic = reader.bool();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.createThumbnail = reader.bool();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.folder = reader.int32() as any;
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.data = reader.bytes();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UploadFileRequest>, I>>(base?: I): UploadFileRequest {
    return UploadFileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadFileRequest>, I>>(object: I): UploadFileRequest {
    const message = createBaseUploadFileRequest();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.info = (object.info !== undefined && object.info !== null) ? File.fromPartial(object.info) : undefined;
    message.isPublic = object.isPublic ?? false;
    message.createThumbnail = object.createThumbnail ?? false;
    message.folder = object.folder ?? 0;
    message.data = object.data ?? new Uint8Array(0);
    return message;
  },
};

function createBaseUploadBundleRequest(): UploadBundleRequest {
  return { key: undefined, info: undefined, folder: 0, data: new Uint8Array(0) };
}

export const UploadBundleRequest: MessageFns<UploadBundleRequest> = {
  encode(message: UploadBundleRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.info !== undefined) {
      File.encode(message.info, writer.uint32(18).fork()).join();
    }
    if (message.folder !== 0) {
      writer.uint32(40).int32(message.folder);
    }
    if (message.data.length !== 0) {
      writer.uint32(50).bytes(message.data);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadBundleRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadBundleRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.info = File.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.folder = reader.int32() as any;
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.data = reader.bytes();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UploadBundleRequest>, I>>(base?: I): UploadBundleRequest {
    return UploadBundleRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadBundleRequest>, I>>(object: I): UploadBundleRequest {
    const message = createBaseUploadBundleRequest();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.info = (object.info !== undefined && object.info !== null) ? File.fromPartial(object.info) : undefined;
    message.folder = object.folder ?? 0;
    message.data = object.data ?? new Uint8Array(0);
    return message;
  },
};

function createBaseUploadAvatarRequest(): UploadAvatarRequest {
  return { key: undefined, info: undefined, userId: "", data: new Uint8Array(0), type: 0, clear: false, appId: "" };
}

export const UploadAvatarRequest: MessageFns<UploadAvatarRequest> = {
  encode(message: UploadAvatarRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.info !== undefined) {
      File.encode(message.info, writer.uint32(18).fork()).join();
    }
    if (message.userId !== "") {
      writer.uint32(26).string(message.userId);
    }
    if (message.data.length !== 0) {
      writer.uint32(34).bytes(message.data);
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.clear !== false) {
      writer.uint32(48).bool(message.clear);
    }
    if (message.appId !== "") {
      writer.uint32(58).string(message.appId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadAvatarRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadAvatarRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.info = File.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.userId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.data = reader.bytes();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.clear = reader.bool();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.appId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UploadAvatarRequest>, I>>(base?: I): UploadAvatarRequest {
    return UploadAvatarRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadAvatarRequest>, I>>(object: I): UploadAvatarRequest {
    const message = createBaseUploadAvatarRequest();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.info = (object.info !== undefined && object.info !== null) ? File.fromPartial(object.info) : undefined;
    message.userId = object.userId ?? "";
    message.data = object.data ?? new Uint8Array(0);
    message.type = object.type ?? 0;
    message.clear = object.clear ?? false;
    message.appId = object.appId ?? "";
    return message;
  },
};

function createBaseUploadTemplateRequest(): UploadTemplateRequest {
  return { key: undefined, info: undefined, appId: "", data: new Uint8Array(0) };
}

export const UploadTemplateRequest: MessageFns<UploadTemplateRequest> = {
  encode(message: UploadTemplateRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.info !== undefined) {
      File.encode(message.info, writer.uint32(18).fork()).join();
    }
    if (message.appId !== "") {
      writer.uint32(26).string(message.appId);
    }
    if (message.data.length !== 0) {
      writer.uint32(34).bytes(message.data);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadTemplateRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadTemplateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.info = File.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.appId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.data = reader.bytes();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UploadTemplateRequest>, I>>(base?: I): UploadTemplateRequest {
    return UploadTemplateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadTemplateRequest>, I>>(object: I): UploadTemplateRequest {
    const message = createBaseUploadTemplateRequest();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.info = (object.info !== undefined && object.info !== null) ? File.fromPartial(object.info) : undefined;
    message.appId = object.appId ?? "";
    message.data = object.data ?? new Uint8Array(0);
    return message;
  },
};

function createBaseUploadFileRequestByStream(): UploadFileRequestByStream {
  return { info: undefined, chunkData: undefined };
}

export const UploadFileRequestByStream: MessageFns<UploadFileRequestByStream> = {
  encode(message: UploadFileRequestByStream, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.info !== undefined) {
      FileData.encode(message.info, writer.uint32(10).fork()).join();
    }
    if (message.chunkData !== undefined) {
      writer.uint32(18).bytes(message.chunkData);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadFileRequestByStream {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadFileRequestByStream();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.info = FileData.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.chunkData = reader.bytes();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UploadFileRequestByStream>, I>>(base?: I): UploadFileRequestByStream {
    return UploadFileRequestByStream.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadFileRequestByStream>, I>>(object: I): UploadFileRequestByStream {
    const message = createBaseUploadFileRequestByStream();
    message.info = (object.info !== undefined && object.info !== null) ? FileData.fromPartial(object.info) : undefined;
    message.chunkData = object.chunkData ?? undefined;
    return message;
  },
};

function createBaseFileData(): FileData {
  return { key: undefined, info: undefined, isPublic: false, createThumbnail: false, folder: 0 };
}

export const FileData: MessageFns<FileData> = {
  encode(message: FileData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.info !== undefined) {
      File.encode(message.info, writer.uint32(18).fork()).join();
    }
    if (message.isPublic !== false) {
      writer.uint32(24).bool(message.isPublic);
    }
    if (message.createThumbnail !== false) {
      writer.uint32(32).bool(message.createThumbnail);
    }
    if (message.folder !== 0) {
      writer.uint32(40).int32(message.folder);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.info = File.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.isPublic = reader.bool();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.createThumbnail = reader.bool();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.folder = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<FileData>, I>>(base?: I): FileData {
    return FileData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileData>, I>>(object: I): FileData {
    const message = createBaseFileData();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.info = (object.info !== undefined && object.info !== null) ? File.fromPartial(object.info) : undefined;
    message.isPublic = object.isPublic ?? false;
    message.createThumbnail = object.createThumbnail ?? false;
    message.folder = object.folder ?? 0;
    return message;
  },
};

function createBaseGetBundleMetadataRequest(): GetBundleMetadataRequest {
  return { key: undefined };
}

export const GetBundleMetadataRequest: MessageFns<GetBundleMetadataRequest> = {
  encode(message: GetBundleMetadataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetBundleMetadataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBundleMetadataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetBundleMetadataRequest>, I>>(base?: I): GetBundleMetadataRequest {
    return GetBundleMetadataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBundleMetadataRequest>, I>>(object: I): GetBundleMetadataRequest {
    const message = createBaseGetBundleMetadataRequest();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    return message;
  },
};

function createBaseBundleMetadataResponse(): BundleMetadataResponse {
  return { result: undefined };
}

export const BundleMetadataResponse: MessageFns<BundleMetadataResponse> = {
  encode(message: BundleMetadataResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.result !== undefined) {
      BundleMetadataResponseResult.encode(message.result, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BundleMetadataResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBundleMetadataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.result = BundleMetadataResponseResult.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BundleMetadataResponse>, I>>(base?: I): BundleMetadataResponse {
    return BundleMetadataResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BundleMetadataResponse>, I>>(object: I): BundleMetadataResponse {
    const message = createBaseBundleMetadataResponse();
    message.result = (object.result !== undefined && object.result !== null)
      ? BundleMetadataResponseResult.fromPartial(object.result)
      : undefined;
    return message;
  },
};

function createBaseBundleMetadataResponseResult(): BundleMetadataResponseResult {
  return { type: 0, key: undefined, metadata: [] };
}

export const BundleMetadataResponseResult: MessageFns<BundleMetadataResponseResult> = {
  encode(message: BundleMetadataResponseResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(18).fork()).join();
    }
    for (const v of message.metadata) {
      FileMetadata.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BundleMetadataResponseResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBundleMetadataResponseResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.metadata.push(FileMetadata.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BundleMetadataResponseResult>, I>>(base?: I): BundleMetadataResponseResult {
    return BundleMetadataResponseResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BundleMetadataResponseResult>, I>>(object: I): BundleMetadataResponseResult {
    const message = createBaseBundleMetadataResponseResult();
    message.type = object.type ?? 0;
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.metadata = object.metadata?.map((e) => FileMetadata.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreatedFileResponse(): CreatedFileResponse {
  return { key: undefined, info: undefined, fileLink: "", thumbnailLink: "" };
}

export const CreatedFileResponse: MessageFns<CreatedFileResponse> = {
  encode(message: CreatedFileResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.info !== undefined) {
      File.encode(message.info, writer.uint32(18).fork()).join();
    }
    if (message.fileLink !== "") {
      writer.uint32(26).string(message.fileLink);
    }
    if (message.thumbnailLink !== "") {
      writer.uint32(34).string(message.thumbnailLink);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreatedFileResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatedFileResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.info = File.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.fileLink = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.thumbnailLink = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreatedFileResponse>, I>>(base?: I): CreatedFileResponse {
    return CreatedFileResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreatedFileResponse>, I>>(object: I): CreatedFileResponse {
    const message = createBaseCreatedFileResponse();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.info = (object.info !== undefined && object.info !== null) ? File.fromPartial(object.info) : undefined;
    message.fileLink = object.fileLink ?? "";
    message.thumbnailLink = object.thumbnailLink ?? "";
    return message;
  },
};

function createBaseUploadFileResponse(): UploadFileResponse {
  return {
    key: undefined,
    info: undefined,
    fileLink: "",
    thumbnailLink: "",
    widgetClassName: "",
    width: 0,
    height: 0,
    csvRows: "",
    strokeColor: "",
    strokeWidth: "",
    fillColor: "",
  };
}

export const UploadFileResponse: MessageFns<UploadFileResponse> = {
  encode(message: UploadFileResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.info !== undefined) {
      File.encode(message.info, writer.uint32(18).fork()).join();
    }
    if (message.fileLink !== "") {
      writer.uint32(26).string(message.fileLink);
    }
    if (message.thumbnailLink !== "") {
      writer.uint32(34).string(message.thumbnailLink);
    }
    if (message.widgetClassName !== "") {
      writer.uint32(42).string(message.widgetClassName);
    }
    if (message.width !== 0) {
      writer.uint32(48).int64(message.width);
    }
    if (message.height !== 0) {
      writer.uint32(56).int64(message.height);
    }
    if (message.csvRows !== "") {
      writer.uint32(66).string(message.csvRows);
    }
    if (message.strokeColor !== "") {
      writer.uint32(74).string(message.strokeColor);
    }
    if (message.strokeWidth !== "") {
      writer.uint32(82).string(message.strokeWidth);
    }
    if (message.fillColor !== "") {
      writer.uint32(90).string(message.fillColor);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadFileResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadFileResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.info = File.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.fileLink = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.thumbnailLink = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.widgetClassName = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.width = longToNumber(reader.int64());
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.height = longToNumber(reader.int64());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.csvRows = reader.string();
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.strokeColor = reader.string();
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.strokeWidth = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.fillColor = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UploadFileResponse>, I>>(base?: I): UploadFileResponse {
    return UploadFileResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadFileResponse>, I>>(object: I): UploadFileResponse {
    const message = createBaseUploadFileResponse();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.info = (object.info !== undefined && object.info !== null) ? File.fromPartial(object.info) : undefined;
    message.fileLink = object.fileLink ?? "";
    message.thumbnailLink = object.thumbnailLink ?? "";
    message.widgetClassName = object.widgetClassName ?? "";
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    message.csvRows = object.csvRows ?? "";
    message.strokeColor = object.strokeColor ?? "";
    message.strokeWidth = object.strokeWidth ?? "";
    message.fillColor = object.fillColor ?? "";
    return message;
  },
};

function createBaseFile(): File {
  return { name: "", mimeType: "" };
}

export const File: MessageFns<File> = {
  encode(message: File, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.mimeType !== "") {
      writer.uint32(26).string(message.mimeType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): File {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.mimeType = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<File>, I>>(base?: I): File {
    return File.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<File>, I>>(object: I): File {
    const message = createBaseFile();
    message.name = object.name ?? "";
    message.mimeType = object.mimeType ?? "";
    return message;
  },
};

function createBaseFileMetadata(): FileMetadata {
  return {
    description: "",
    videoLink: "",
    presentationName: "",
    uuid: "",
    status: 0,
    boardList: "",
    isDefault: false,
    images: [],
    order: "",
  };
}

export const FileMetadata: MessageFns<FileMetadata> = {
  encode(message: FileMetadata, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.description !== "") {
      writer.uint32(10).string(message.description);
    }
    if (message.videoLink !== "") {
      writer.uint32(18).string(message.videoLink);
    }
    if (message.presentationName !== "") {
      writer.uint32(26).string(message.presentationName);
    }
    if (message.uuid !== "") {
      writer.uint32(34).string(message.uuid);
    }
    if (message.status !== 0) {
      writer.uint32(40).int32(message.status);
    }
    if (message.boardList !== "") {
      writer.uint32(50).string(message.boardList);
    }
    if (message.isDefault !== false) {
      writer.uint32(56).bool(message.isDefault);
    }
    for (const v of message.images) {
      writer.uint32(66).string(v!);
    }
    if (message.order !== "") {
      writer.uint32(74).string(message.order);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileMetadata {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.description = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.videoLink = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.presentationName = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.uuid = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.boardList = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.isDefault = reader.bool();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.images.push(reader.string());
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.order = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<FileMetadata>, I>>(base?: I): FileMetadata {
    return FileMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileMetadata>, I>>(object: I): FileMetadata {
    const message = createBaseFileMetadata();
    message.description = object.description ?? "";
    message.videoLink = object.videoLink ?? "";
    message.presentationName = object.presentationName ?? "";
    message.uuid = object.uuid ?? "";
    message.status = object.status ?? 0;
    message.boardList = object.boardList ?? "";
    message.isDefault = object.isDefault ?? false;
    message.images = object.images?.map((e) => e) || [];
    message.order = object.order ?? "";
    return message;
  },
};

function createBaseUpdateBundleMetadataRequest(): UpdateBundleMetadataRequest {
  return { metadataItem: undefined };
}

export const UpdateBundleMetadataRequest: MessageFns<UpdateBundleMetadataRequest> = {
  encode(message: UpdateBundleMetadataRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.metadataItem !== undefined) {
      BundleMetadataItem.encode(message.metadataItem, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UpdateBundleMetadataRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateBundleMetadataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.metadataItem = BundleMetadataItem.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateBundleMetadataRequest>, I>>(base?: I): UpdateBundleMetadataRequest {
    return UpdateBundleMetadataRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateBundleMetadataRequest>, I>>(object: I): UpdateBundleMetadataRequest {
    const message = createBaseUpdateBundleMetadataRequest();
    message.metadataItem = (object.metadataItem !== undefined && object.metadataItem !== null)
      ? BundleMetadataItem.fromPartial(object.metadataItem)
      : undefined;
    return message;
  },
};

function createBaseBundleMetadataItem(): BundleMetadataItem {
  return { key: undefined, metadata: undefined };
}

export const BundleMetadataItem: MessageFns<BundleMetadataItem> = {
  encode(message: BundleMetadataItem, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.metadata !== undefined) {
      FileMetadata.encode(message.metadata, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BundleMetadataItem {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBundleMetadataItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.metadata = FileMetadata.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BundleMetadataItem>, I>>(base?: I): BundleMetadataItem {
    return BundleMetadataItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BundleMetadataItem>, I>>(object: I): BundleMetadataItem {
    const message = createBaseBundleMetadataItem();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? FileMetadata.fromPartial(object.metadata)
      : undefined;
    return message;
  },
};

function createBaseFileSignLinkRequest(): FileSignLinkRequest {
  return { items: [] };
}

export const FileSignLinkRequest: MessageFns<FileSignLinkRequest> = {
  encode(message: FileSignLinkRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.items) {
      FileSignLinkRequestFileSignLinkItem.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileSignLinkRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileSignLinkRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.items.push(FileSignLinkRequestFileSignLinkItem.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<FileSignLinkRequest>, I>>(base?: I): FileSignLinkRequest {
    return FileSignLinkRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileSignLinkRequest>, I>>(object: I): FileSignLinkRequest {
    const message = createBaseFileSignLinkRequest();
    message.items = object.items?.map((e) => FileSignLinkRequestFileSignLinkItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFileSignLinkRequestFileSignLinkItem(): FileSignLinkRequestFileSignLinkItem {
  return { key: undefined, filename: "", signTimeout: 0, signType: 0, appId: "" };
}

export const FileSignLinkRequestFileSignLinkItem: MessageFns<FileSignLinkRequestFileSignLinkItem> = {
  encode(message: FileSignLinkRequestFileSignLinkItem, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.filename !== "") {
      writer.uint32(18).string(message.filename);
    }
    if (message.signTimeout !== 0) {
      writer.uint32(24).int64(message.signTimeout);
    }
    if (message.signType !== 0) {
      writer.uint32(32).int32(message.signType);
    }
    if (message.appId !== "") {
      writer.uint32(42).string(message.appId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileSignLinkRequestFileSignLinkItem {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileSignLinkRequestFileSignLinkItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.filename = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.signTimeout = longToNumber(reader.int64());
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.signType = reader.int32() as any;
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.appId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<FileSignLinkRequestFileSignLinkItem>, I>>(
    base?: I,
  ): FileSignLinkRequestFileSignLinkItem {
    return FileSignLinkRequestFileSignLinkItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileSignLinkRequestFileSignLinkItem>, I>>(
    object: I,
  ): FileSignLinkRequestFileSignLinkItem {
    const message = createBaseFileSignLinkRequestFileSignLinkItem();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.filename = object.filename ?? "";
    message.signTimeout = object.signTimeout ?? 0;
    message.signType = object.signType ?? 0;
    message.appId = object.appId ?? "";
    return message;
  },
};

function createBaseGenerateSignedLinkRequest(): GenerateSignedLinkRequest {
  return { key: undefined, filename: "", appId: "", countLink: 0, linkType: 0 };
}

export const GenerateSignedLinkRequest: MessageFns<GenerateSignedLinkRequest> = {
  encode(message: GenerateSignedLinkRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.filename !== "") {
      writer.uint32(18).string(message.filename);
    }
    if (message.appId !== "") {
      writer.uint32(26).string(message.appId);
    }
    if (message.countLink !== 0) {
      writer.uint32(32).int64(message.countLink);
    }
    if (message.linkType !== 0) {
      writer.uint32(40).int32(message.linkType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GenerateSignedLinkRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateSignedLinkRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.filename = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.appId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.countLink = longToNumber(reader.int64());
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.linkType = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GenerateSignedLinkRequest>, I>>(base?: I): GenerateSignedLinkRequest {
    return GenerateSignedLinkRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateSignedLinkRequest>, I>>(object: I): GenerateSignedLinkRequest {
    const message = createBaseGenerateSignedLinkRequest();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.filename = object.filename ?? "";
    message.appId = object.appId ?? "";
    message.countLink = object.countLink ?? 0;
    message.linkType = object.linkType ?? 0;
    return message;
  },
};

function createBaseGenerateSignedLinkResponse(): GenerateSignedLinkResponse {
  return { results: [] };
}

export const GenerateSignedLinkResponse: MessageFns<GenerateSignedLinkResponse> = {
  encode(message: GenerateSignedLinkResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.results) {
      GenerateSignedLinkResponseResult.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GenerateSignedLinkResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateSignedLinkResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.results.push(GenerateSignedLinkResponseResult.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GenerateSignedLinkResponse>, I>>(base?: I): GenerateSignedLinkResponse {
    return GenerateSignedLinkResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateSignedLinkResponse>, I>>(object: I): GenerateSignedLinkResponse {
    const message = createBaseGenerateSignedLinkResponse();
    message.results = object.results?.map((e) => GenerateSignedLinkResponseResult.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGenerateSignedLinkResponseResult(): GenerateSignedLinkResponseResult {
  return { key: undefined, appId: "", signedLink: "", fields: {} };
}

export const GenerateSignedLinkResponseResult: MessageFns<GenerateSignedLinkResponseResult> = {
  encode(message: GenerateSignedLinkResponseResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.appId !== "") {
      writer.uint32(18).string(message.appId);
    }
    if (message.signedLink !== "") {
      writer.uint32(26).string(message.signedLink);
    }
    Object.entries(message.fields).forEach(([key, value]) => {
      GenerateSignedLinkResponseResultFieldsEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GenerateSignedLinkResponseResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateSignedLinkResponseResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.appId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.signedLink = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          const entry4 = GenerateSignedLinkResponseResultFieldsEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.fields[entry4.key] = entry4.value;
          }
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GenerateSignedLinkResponseResult>, I>>(
    base?: I,
  ): GenerateSignedLinkResponseResult {
    return GenerateSignedLinkResponseResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateSignedLinkResponseResult>, I>>(
    object: I,
  ): GenerateSignedLinkResponseResult {
    const message = createBaseGenerateSignedLinkResponseResult();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.appId = object.appId ?? "";
    message.signedLink = object.signedLink ?? "";
    message.fields = Object.entries(object.fields ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseGenerateSignedLinkResponseResultFieldsEntry(): GenerateSignedLinkResponseResultFieldsEntry {
  return { key: "", value: "" };
}

export const GenerateSignedLinkResponseResultFieldsEntry: MessageFns<GenerateSignedLinkResponseResultFieldsEntry> = {
  encode(
    message: GenerateSignedLinkResponseResultFieldsEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GenerateSignedLinkResponseResultFieldsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateSignedLinkResponseResultFieldsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GenerateSignedLinkResponseResultFieldsEntry>, I>>(
    base?: I,
  ): GenerateSignedLinkResponseResultFieldsEntry {
    return GenerateSignedLinkResponseResultFieldsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GenerateSignedLinkResponseResultFieldsEntry>, I>>(
    object: I,
  ): GenerateSignedLinkResponseResultFieldsEntry {
    const message = createBaseGenerateSignedLinkResponseResultFieldsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseDeleteFileRequest(): DeleteFileRequest {
  return { key: undefined };
}

export const DeleteFileRequest: MessageFns<DeleteFileRequest> = {
  encode(message: DeleteFileRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteFileRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteFileRequest>, I>>(base?: I): DeleteFileRequest {
    return DeleteFileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFileRequest>, I>>(object: I): DeleteFileRequest {
    const message = createBaseDeleteFileRequest();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    return message;
  },
};

function createBaseDeleteFileRequestByNames(): DeleteFileRequestByNames {
  return { key: undefined, filenames: [] };
}

export const DeleteFileRequestByNames: MessageFns<DeleteFileRequestByNames> = {
  encode(message: DeleteFileRequestByNames, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    for (const v of message.filenames) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeleteFileRequestByNames {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFileRequestByNames();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.filenames.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteFileRequestByNames>, I>>(base?: I): DeleteFileRequestByNames {
    return DeleteFileRequestByNames.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteFileRequestByNames>, I>>(object: I): DeleteFileRequestByNames {
    const message = createBaseDeleteFileRequestByNames();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.filenames = object.filenames?.map((e) => e) || [];
    return message;
  },
};

function createBaseFileSignLinkResponse(): FileSignLinkResponse {
  return { results: [] };
}

export const FileSignLinkResponse: MessageFns<FileSignLinkResponse> = {
  encode(message: FileSignLinkResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.results) {
      FileSignLinkResponseResult.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileSignLinkResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileSignLinkResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.results.push(FileSignLinkResponseResult.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<FileSignLinkResponse>, I>>(base?: I): FileSignLinkResponse {
    return FileSignLinkResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileSignLinkResponse>, I>>(object: I): FileSignLinkResponse {
    const message = createBaseFileSignLinkResponse();
    message.results = object.results?.map((e) => FileSignLinkResponseResult.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFileSignLinkResponseResult(): FileSignLinkResponseResult {
  return { type: 0, signedLink: "", encodedFilename: "" };
}

export const FileSignLinkResponseResult: MessageFns<FileSignLinkResponseResult> = {
  encode(message: FileSignLinkResponseResult, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.signedLink !== "") {
      writer.uint32(18).string(message.signedLink);
    }
    if (message.encodedFilename !== "") {
      writer.uint32(26).string(message.encodedFilename);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): FileSignLinkResponseResult {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileSignLinkResponseResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.signedLink = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.encodedFilename = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<FileSignLinkResponseResult>, I>>(base?: I): FileSignLinkResponseResult {
    return FileSignLinkResponseResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileSignLinkResponseResult>, I>>(object: I): FileSignLinkResponseResult {
    const message = createBaseFileSignLinkResponseResult();
    message.type = object.type ?? 0;
    message.signedLink = object.signedLink ?? "";
    message.encodedFilename = object.encodedFilename ?? "";
    return message;
  },
};

function createBaseCopyBoardFilesResponse(): CopyBoardFilesResponse {
  return { results: [] };
}

export const CopyBoardFilesResponse: MessageFns<CopyBoardFilesResponse> = {
  encode(message: CopyBoardFilesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.results) {
      CreatedFileResponse.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CopyBoardFilesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCopyBoardFilesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.results.push(CreatedFileResponse.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CopyBoardFilesResponse>, I>>(base?: I): CopyBoardFilesResponse {
    return CopyBoardFilesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CopyBoardFilesResponse>, I>>(object: I): CopyBoardFilesResponse {
    const message = createBaseCopyBoardFilesResponse();
    message.results = object.results?.map((e) => CreatedFileResponse.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEmptyResponse(): EmptyResponse {
  return {};
}

export const EmptyResponse: MessageFns<EmptyResponse> = {
  encode(_: EmptyResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): EmptyResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmptyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<EmptyResponse>, I>>(base?: I): EmptyResponse {
    return EmptyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EmptyResponse>, I>>(_: I): EmptyResponse {
    const message = createBaseEmptyResponse();
    return message;
  },
};

function createBaseGetBoardFileRequest(): GetBoardFileRequest {
  return { key: undefined, filename: "" };
}

export const GetBoardFileRequest: MessageFns<GetBoardFileRequest> = {
  encode(message: GetBoardFileRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.filename !== "") {
      writer.uint32(18).string(message.filename);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetBoardFileRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBoardFileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.filename = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetBoardFileRequest>, I>>(base?: I): GetBoardFileRequest {
    return GetBoardFileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBoardFileRequest>, I>>(object: I): GetBoardFileRequest {
    const message = createBaseGetBoardFileRequest();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.filename = object.filename ?? "";
    return message;
  },
};

function createBaseDataChunk(): DataChunk {
  return { data: new Uint8Array(0), size: 0 };
}

export const DataChunk: MessageFns<DataChunk> = {
  encode(message: DataChunk, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.data.length !== 0) {
      writer.uint32(10).bytes(message.data);
    }
    if (message.size !== 0) {
      writer.uint32(16).int32(message.size);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DataChunk {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDataChunk();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.data = reader.bytes();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.size = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DataChunk>, I>>(base?: I): DataChunk {
    return DataChunk.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DataChunk>, I>>(object: I): DataChunk {
    const message = createBaseDataChunk();
    message.data = object.data ?? new Uint8Array(0);
    message.size = object.size ?? 0;
    return message;
  },
};

function createBaseCopyFileRequest(): CopyFileRequest {
  return { srcKey: undefined, srcFilename: "", dstKey: undefined, dstFilename: "", folder: 0 };
}

export const CopyFileRequest: MessageFns<CopyFileRequest> = {
  encode(message: CopyFileRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.srcKey !== undefined) {
      Key.encode(message.srcKey, writer.uint32(10).fork()).join();
    }
    if (message.srcFilename !== "") {
      writer.uint32(18).string(message.srcFilename);
    }
    if (message.dstKey !== undefined) {
      Key.encode(message.dstKey, writer.uint32(26).fork()).join();
    }
    if (message.dstFilename !== "") {
      writer.uint32(34).string(message.dstFilename);
    }
    if (message.folder !== 0) {
      writer.uint32(40).int32(message.folder);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CopyFileRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCopyFileRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.srcKey = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.srcFilename = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.dstKey = Key.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.dstFilename = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.folder = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CopyFileRequest>, I>>(base?: I): CopyFileRequest {
    return CopyFileRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CopyFileRequest>, I>>(object: I): CopyFileRequest {
    const message = createBaseCopyFileRequest();
    message.srcKey = (object.srcKey !== undefined && object.srcKey !== null)
      ? Key.fromPartial(object.srcKey)
      : undefined;
    message.srcFilename = object.srcFilename ?? "";
    message.dstKey = (object.dstKey !== undefined && object.dstKey !== null)
      ? Key.fromPartial(object.dstKey)
      : undefined;
    message.dstFilename = object.dstFilename ?? "";
    message.folder = object.folder ?? 0;
    return message;
  },
};

function createBaseZipAndUploadBundleRequest(): ZipAndUploadBundleRequest {
  return { key: undefined, boardIds: [], bundleName: "", serializedEntities: "", appId: "" };
}

export const ZipAndUploadBundleRequest: MessageFns<ZipAndUploadBundleRequest> = {
  encode(message: ZipAndUploadBundleRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    for (const v of message.boardIds) {
      writer.uint32(18).string(v!);
    }
    if (message.bundleName !== "") {
      writer.uint32(26).string(message.bundleName);
    }
    if (message.serializedEntities !== "") {
      writer.uint32(34).string(message.serializedEntities);
    }
    if (message.appId !== "") {
      writer.uint32(42).string(message.appId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ZipAndUploadBundleRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZipAndUploadBundleRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.boardIds.push(reader.string());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.bundleName = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.serializedEntities = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.appId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ZipAndUploadBundleRequest>, I>>(base?: I): ZipAndUploadBundleRequest {
    return ZipAndUploadBundleRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ZipAndUploadBundleRequest>, I>>(object: I): ZipAndUploadBundleRequest {
    const message = createBaseZipAndUploadBundleRequest();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.boardIds = object.boardIds?.map((e) => e) || [];
    message.bundleName = object.bundleName ?? "";
    message.serializedEntities = object.serializedEntities ?? "";
    message.appId = object.appId ?? "";
    return message;
  },
};

function createBaseZipAndUploadBundleResponse(): ZipAndUploadBundleResponse {
  return { filepath: "" };
}

export const ZipAndUploadBundleResponse: MessageFns<ZipAndUploadBundleResponse> = {
  encode(message: ZipAndUploadBundleResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.filepath !== "") {
      writer.uint32(10).string(message.filepath);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ZipAndUploadBundleResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseZipAndUploadBundleResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.filepath = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ZipAndUploadBundleResponse>, I>>(base?: I): ZipAndUploadBundleResponse {
    return ZipAndUploadBundleResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ZipAndUploadBundleResponse>, I>>(object: I): ZipAndUploadBundleResponse {
    const message = createBaseZipAndUploadBundleResponse();
    message.filepath = object.filepath ?? "";
    return message;
  },
};

function createBaseGetFileFromArchiveRequest(): GetFileFromArchiveRequest {
  return { key: undefined, bundleName: "", fileName: "" };
}

export const GetFileFromArchiveRequest: MessageFns<GetFileFromArchiveRequest> = {
  encode(message: GetFileFromArchiveRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== undefined) {
      Key.encode(message.key, writer.uint32(10).fork()).join();
    }
    if (message.bundleName !== "") {
      writer.uint32(18).string(message.bundleName);
    }
    if (message.fileName !== "") {
      writer.uint32(26).string(message.fileName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetFileFromArchiveRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFileFromArchiveRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.bundleName = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.fileName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetFileFromArchiveRequest>, I>>(base?: I): GetFileFromArchiveRequest {
    return GetFileFromArchiveRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFileFromArchiveRequest>, I>>(object: I): GetFileFromArchiveRequest {
    const message = createBaseGetFileFromArchiveRequest();
    message.key = (object.key !== undefined && object.key !== null) ? Key.fromPartial(object.key) : undefined;
    message.bundleName = object.bundleName ?? "";
    message.fileName = object.fileName ?? "";
    return message;
  },
};

function createBaseImportBundleFilesRequest(): ImportBundleFilesRequest {
  return { newKey: undefined, oldKey: undefined, bundleName: "", cardUuidsMap: {} };
}

export const ImportBundleFilesRequest: MessageFns<ImportBundleFilesRequest> = {
  encode(message: ImportBundleFilesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.newKey !== undefined) {
      Key.encode(message.newKey, writer.uint32(10).fork()).join();
    }
    if (message.oldKey !== undefined) {
      Key.encode(message.oldKey, writer.uint32(18).fork()).join();
    }
    if (message.bundleName !== "") {
      writer.uint32(26).string(message.bundleName);
    }
    Object.entries(message.cardUuidsMap).forEach(([key, value]) => {
      ImportBundleFilesRequestCardUuidsMapEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ImportBundleFilesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImportBundleFilesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.newKey = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.oldKey = Key.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.bundleName = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          const entry4 = ImportBundleFilesRequestCardUuidsMapEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.cardUuidsMap[entry4.key] = entry4.value;
          }
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ImportBundleFilesRequest>, I>>(base?: I): ImportBundleFilesRequest {
    return ImportBundleFilesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImportBundleFilesRequest>, I>>(object: I): ImportBundleFilesRequest {
    const message = createBaseImportBundleFilesRequest();
    message.newKey = (object.newKey !== undefined && object.newKey !== null)
      ? Key.fromPartial(object.newKey)
      : undefined;
    message.oldKey = (object.oldKey !== undefined && object.oldKey !== null)
      ? Key.fromPartial(object.oldKey)
      : undefined;
    message.bundleName = object.bundleName ?? "";
    message.cardUuidsMap = Object.entries(object.cardUuidsMap ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseImportBundleFilesRequestCardUuidsMapEntry(): ImportBundleFilesRequestCardUuidsMapEntry {
  return { key: "", value: "" };
}

export const ImportBundleFilesRequestCardUuidsMapEntry: MessageFns<ImportBundleFilesRequestCardUuidsMapEntry> = {
  encode(message: ImportBundleFilesRequestCardUuidsMapEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ImportBundleFilesRequestCardUuidsMapEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImportBundleFilesRequestCardUuidsMapEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ImportBundleFilesRequestCardUuidsMapEntry>, I>>(
    base?: I,
  ): ImportBundleFilesRequestCardUuidsMapEntry {
    return ImportBundleFilesRequestCardUuidsMapEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ImportBundleFilesRequestCardUuidsMapEntry>, I>>(
    object: I,
  ): ImportBundleFilesRequestCardUuidsMapEntry {
    const message = createBaseImportBundleFilesRequestCardUuidsMapEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseCopyBoardFilesRequest(): CopyBoardFilesRequest {
  return { srcKey: undefined, dstKey: undefined, names: [], cardUuidsMap: {} };
}

export const CopyBoardFilesRequest: MessageFns<CopyBoardFilesRequest> = {
  encode(message: CopyBoardFilesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.srcKey !== undefined) {
      Key.encode(message.srcKey, writer.uint32(10).fork()).join();
    }
    if (message.dstKey !== undefined) {
      Key.encode(message.dstKey, writer.uint32(18).fork()).join();
    }
    for (const v of message.names) {
      writer.uint32(26).string(v!);
    }
    Object.entries(message.cardUuidsMap).forEach(([key, value]) => {
      CopyBoardFilesRequestCardUuidsMapEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CopyBoardFilesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCopyBoardFilesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.srcKey = Key.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.dstKey = Key.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.names.push(reader.string());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          const entry4 = CopyBoardFilesRequestCardUuidsMapEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.cardUuidsMap[entry4.key] = entry4.value;
          }
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CopyBoardFilesRequest>, I>>(base?: I): CopyBoardFilesRequest {
    return CopyBoardFilesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CopyBoardFilesRequest>, I>>(object: I): CopyBoardFilesRequest {
    const message = createBaseCopyBoardFilesRequest();
    message.srcKey = (object.srcKey !== undefined && object.srcKey !== null)
      ? Key.fromPartial(object.srcKey)
      : undefined;
    message.dstKey = (object.dstKey !== undefined && object.dstKey !== null)
      ? Key.fromPartial(object.dstKey)
      : undefined;
    message.names = object.names?.map((e) => e) || [];
    message.cardUuidsMap = Object.entries(object.cardUuidsMap ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseCopyBoardFilesRequestCardUuidsMapEntry(): CopyBoardFilesRequestCardUuidsMapEntry {
  return { key: "", value: "" };
}

export const CopyBoardFilesRequestCardUuidsMapEntry: MessageFns<CopyBoardFilesRequestCardUuidsMapEntry> = {
  encode(message: CopyBoardFilesRequestCardUuidsMapEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CopyBoardFilesRequestCardUuidsMapEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCopyBoardFilesRequestCardUuidsMapEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CopyBoardFilesRequestCardUuidsMapEntry>, I>>(
    base?: I,
  ): CopyBoardFilesRequestCardUuidsMapEntry {
    return CopyBoardFilesRequestCardUuidsMapEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CopyBoardFilesRequestCardUuidsMapEntry>, I>>(
    object: I,
  ): CopyBoardFilesRequestCardUuidsMapEntry {
    const message = createBaseCopyBoardFilesRequestCardUuidsMapEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseGetUserAvatarSignedLinkRequest(): GetUserAvatarSignedLinkRequest {
  return { userId: "" };
}

export const GetUserAvatarSignedLinkRequest: MessageFns<GetUserAvatarSignedLinkRequest> = {
  encode(message: GetUserAvatarSignedLinkRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.userId !== "") {
      writer.uint32(10).string(message.userId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetUserAvatarSignedLinkRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserAvatarSignedLinkRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.userId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetUserAvatarSignedLinkRequest>, I>>(base?: I): GetUserAvatarSignedLinkRequest {
    return GetUserAvatarSignedLinkRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserAvatarSignedLinkRequest>, I>>(
    object: I,
  ): GetUserAvatarSignedLinkRequest {
    const message = createBaseGetUserAvatarSignedLinkRequest();
    message.userId = object.userId ?? "";
    return message;
  },
};

function createBaseGetUserAvatarSignedLinkResponse(): GetUserAvatarSignedLinkResponse {
  return { link: "" };
}

export const GetUserAvatarSignedLinkResponse: MessageFns<GetUserAvatarSignedLinkResponse> = {
  encode(message: GetUserAvatarSignedLinkResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.link !== "") {
      writer.uint32(10).string(message.link);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): GetUserAvatarSignedLinkResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserAvatarSignedLinkResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.link = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetUserAvatarSignedLinkResponse>, I>>(base?: I): GetUserAvatarSignedLinkResponse {
    return GetUserAvatarSignedLinkResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserAvatarSignedLinkResponse>, I>>(
    object: I,
  ): GetUserAvatarSignedLinkResponse {
    const message = createBaseGetUserAvatarSignedLinkResponse();
    message.link = object.link ?? "";
    return message;
  },
};

function createBaseParseTableDataRowsRequest(): ParseTableDataRowsRequest {
  return { filepath: "", batchSize: 0, rowsLimit: 0, step: 0, isError: false };
}

export const ParseTableDataRowsRequest: MessageFns<ParseTableDataRowsRequest> = {
  encode(message: ParseTableDataRowsRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.filepath !== "") {
      writer.uint32(10).string(message.filepath);
    }
    if (message.batchSize !== 0) {
      writer.uint32(16).int32(message.batchSize);
    }
    if (message.rowsLimit !== 0) {
      writer.uint32(24).int32(message.rowsLimit);
    }
    if (message.step !== 0) {
      writer.uint32(32).int32(message.step);
    }
    if (message.isError !== false) {
      writer.uint32(40).bool(message.isError);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ParseTableDataRowsRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseParseTableDataRowsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.filepath = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.batchSize = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.rowsLimit = reader.int32();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.step = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.isError = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ParseTableDataRowsRequest>, I>>(base?: I): ParseTableDataRowsRequest {
    return ParseTableDataRowsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ParseTableDataRowsRequest>, I>>(object: I): ParseTableDataRowsRequest {
    const message = createBaseParseTableDataRowsRequest();
    message.filepath = object.filepath ?? "";
    message.batchSize = object.batchSize ?? 0;
    message.rowsLimit = object.rowsLimit ?? 0;
    message.step = object.step ?? 0;
    message.isError = object.isError ?? false;
    return message;
  },
};

function createBaseTableDataRowsResponse(): TableDataRowsResponse {
  return {};
}

export const TableDataRowsResponse: MessageFns<TableDataRowsResponse> = {
  encode(_: TableDataRowsResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TableDataRowsResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTableDataRowsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TableDataRowsResponse>, I>>(base?: I): TableDataRowsResponse {
    return TableDataRowsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TableDataRowsResponse>, I>>(_: I): TableDataRowsResponse {
    const message = createBaseTableDataRowsResponse();
    return message;
  },
};

function createBaseUploadDatasetRequest(): UploadDatasetRequest {
  return { scope: undefined, info: undefined, data: new Uint8Array(0) };
}

export const UploadDatasetRequest: MessageFns<UploadDatasetRequest> = {
  encode(message: UploadDatasetRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.scope !== undefined) {
      Scope.encode(message.scope, writer.uint32(10).fork()).join();
    }
    if (message.info !== undefined) {
      File.encode(message.info, writer.uint32(18).fork()).join();
    }
    if (message.data.length !== 0) {
      writer.uint32(34).bytes(message.data);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadDatasetRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadDatasetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.scope = Scope.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.info = File.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.data = reader.bytes();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UploadDatasetRequest>, I>>(base?: I): UploadDatasetRequest {
    return UploadDatasetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadDatasetRequest>, I>>(object: I): UploadDatasetRequest {
    const message = createBaseUploadDatasetRequest();
    message.scope = (object.scope !== undefined && object.scope !== null) ? Scope.fromPartial(object.scope) : undefined;
    message.info = (object.info !== undefined && object.info !== null) ? File.fromPartial(object.info) : undefined;
    message.data = object.data ?? new Uint8Array(0);
    return message;
  },
};

function createBaseUploadDatasetResponse(): UploadDatasetResponse {
  return { parseProcessId: "" };
}

export const UploadDatasetResponse: MessageFns<UploadDatasetResponse> = {
  encode(message: UploadDatasetResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parseProcessId !== "") {
      writer.uint32(10).string(message.parseProcessId);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): UploadDatasetResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUploadDatasetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parseProcessId = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UploadDatasetResponse>, I>>(base?: I): UploadDatasetResponse {
    return UploadDatasetResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UploadDatasetResponse>, I>>(object: I): UploadDatasetResponse {
    const message = createBaseUploadDatasetResponse();
    message.parseProcessId = object.parseProcessId ?? "";
    return message;
  },
};

function createBaseErrorDetails(): ErrorDetails {
  return { errorMessage: "" };
}

export const ErrorDetails: MessageFns<ErrorDetails> = {
  encode(message: ErrorDetails, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.errorMessage !== "") {
      writer.uint32(10).string(message.errorMessage);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ErrorDetails {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseErrorDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.errorMessage = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ErrorDetails>, I>>(base?: I): ErrorDetails {
    return ErrorDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ErrorDetails>, I>>(object: I): ErrorDetails {
    const message = createBaseErrorDetails();
    message.errorMessage = object.errorMessage ?? "";
    return message;
  },
};

/** FileUploader service to upload files (app bundle, zip files, images and etc) */
export type FileUploaderDefinition = typeof FileUploaderDefinition;
export const FileUploaderDefinition = {
  name: "FileUploader",
  fullName: "upboard.io.uploader.v1.FileUploader",
  methods: {
    /** AddDefaultBundle implement method to upload default bundle and attach default metadata to it */
    addDefaultBundle: {
      name: "AddDefaultBundle",
      requestType: AddBundleRequest,
      requestStream: true,
      responseType: AddBundleResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              40,
              34,
              35,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              97,
              100,
              100,
              45,
              100,
              101,
              102,
              97,
              117,
              108,
              116,
              45,
              98,
              117,
              110,
              100,
              108,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** UploadDefaultBundleResource upload bundle */
    uploadDefaultBundleResource: {
      name: "UploadDefaultBundleResource",
      requestType: UploadDefaultBundleResourceRequest,
      requestStream: true,
      responseType: CreatedFileResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              52,
              34,
              47,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              117,
              112,
              108,
              111,
              97,
              100,
              45,
              100,
              101,
              102,
              97,
              117,
              108,
              116,
              45,
              98,
              117,
              110,
              100,
              108,
              101,
              45,
              114,
              101,
              115,
              111,
              117,
              114,
              99,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** CopyFile copy file from one tenantID/boardID to another (use files folder to build file path) */
    copyFile: {
      name: "CopyFile",
      requestType: CopyFileRequest,
      requestStream: false,
      responseType: CreatedFileResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              31,
              34,
              26,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              99,
              111,
              112,
              121,
              45,
              102,
              105,
              108,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** GetBundleMetadata implement method to fetch bundle metadata */
    getBundleMetadata: {
      name: "GetBundleMetadata",
      requestType: GetBundleMetadataRequest,
      requestStream: false,
      responseType: BundleMetadataResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              41,
              34,
              36,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              103,
              101,
              116,
              45,
              98,
              117,
              110,
              100,
              108,
              101,
              45,
              109,
              101,
              116,
              97,
              100,
              97,
              116,
              97,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** UpdateBundleMetadata implement method to update bundle metadata */
    updateBundleMetadata: {
      name: "UpdateBundleMetadata",
      requestType: UpdateBundleMetadataRequest,
      requestStream: false,
      responseType: BundleMetadataResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              44,
              34,
              39,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              117,
              112,
              100,
              97,
              116,
              101,
              45,
              98,
              117,
              110,
              100,
              108,
              101,
              45,
              109,
              101,
              116,
              97,
              100,
              97,
              116,
              97,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** UploadFile if boardID in key is passed then Upload ordinary file else upload whole bundle */
    uploadFile: {
      name: "UploadFile",
      requestType: UploadFileRequest,
      requestStream: false,
      responseType: UploadFileResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              33,
              34,
              28,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              117,
              112,
              108,
              111,
              97,
              100,
              45,
              102,
              105,
              108,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /**
     * manageOrganization || for user check token userID и req userID
     * UploadAvatar if boardID in key is passed then Upload ordinary file else upload whole bundle
     */
    uploadAvatar: {
      name: "UploadAvatar",
      requestType: UploadAvatarRequest,
      requestStream: false,
      responseType: CreatedFileResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              35,
              34,
              30,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              117,
              112,
              108,
              111,
              97,
              100,
              45,
              97,
              118,
              97,
              116,
              97,
              114,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** GetUserAvatarSignedLink returns user's avatar signed link */
    getUserAvatarSignedLink: {
      name: "GetUserAvatarSignedLink",
      requestType: GetUserAvatarSignedLinkRequest,
      requestStream: false,
      responseType: GetUserAvatarSignedLinkResponse,
      responseStream: false,
      options: {},
    },
    /**
     * ParseTableDataRows starts publishing messages to the queue with table rows batches.
     * Method is for internal use and may be removed.
     */
    parseTableDataRows: {
      name: "ParseTableDataRows",
      requestType: ParseTableDataRowsRequest,
      requestStream: false,
      responseType: TableDataRowsResponse,
      responseStream: false,
      options: {},
    },
    /** UploadTemplate */
    uploadTemplate: {
      name: "UploadTemplate",
      requestType: UploadTemplateRequest,
      requestStream: false,
      responseType: CreatedFileResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              37,
              34,
              32,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              117,
              112,
              108,
              111,
              97,
              100,
              45,
              116,
              101,
              109,
              112,
              108,
              97,
              116,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** UploadFileByStream if boardID in key is passed then Upload ordinary file else upload whole bundle */
    uploadFileByStream: {
      name: "UploadFileByStream",
      requestType: UploadFileRequestByStream,
      requestStream: true,
      responseType: UploadFileResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              43,
              34,
              38,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              117,
              112,
              108,
              111,
              97,
              100,
              45,
              102,
              105,
              108,
              101,
              45,
              98,
              121,
              45,
              115,
              116,
              114,
              101,
              97,
              109,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** GetBoardFileSignedLink */
    getBoardFileSignedLink: {
      name: "GetBoardFileSignedLink",
      requestType: FileSignLinkRequest,
      requestStream: false,
      responseType: FileSignLinkResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              48,
              34,
              43,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              103,
              101,
              116,
              45,
              98,
              111,
              97,
              114,
              100,
              45,
              102,
              105,
              108,
              101,
              45,
              115,
              105,
              103,
              110,
              101,
              100,
              45,
              108,
              105,
              110,
              107,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** GenerateSignedLinkToUploadFile */
    generateSignedLinkToUploadFile: {
      name: "GenerateSignedLinkToUploadFile",
      requestType: GenerateSignedLinkRequest,
      requestStream: false,
      responseType: GenerateSignedLinkResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              57,
              34,
              52,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              103,
              101,
              110,
              101,
              114,
              97,
              116,
              101,
              45,
              115,
              105,
              103,
              110,
              101,
              100,
              45,
              108,
              105,
              110,
              107,
              45,
              116,
              111,
              45,
              117,
              112,
              108,
              111,
              97,
              100,
              45,
              102,
              105,
              108,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /**
     * DeleteFiles
     * if tenantID and boardID in key is passed - delete by boardID
     * if tenantID in key is passed - delete by tenantID
     */
    deleteFiles: {
      name: "DeleteFiles",
      requestType: DeleteFileRequest,
      requestStream: false,
      responseType: EmptyResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              34,
              34,
              29,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              100,
              101,
              108,
              101,
              116,
              101,
              45,
              102,
              105,
              108,
              101,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /**
     * DeleteFilesByNames
     * if array of names is passed - delete by names
     */
    deleteFilesByNames: {
      name: "DeleteFilesByNames",
      requestType: DeleteFileRequestByNames,
      requestStream: false,
      responseType: EmptyResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              43,
              34,
              38,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              100,
              101,
              108,
              101,
              116,
              101,
              45,
              102,
              105,
              108,
              101,
              115,
              45,
              98,
              121,
              45,
              110,
              97,
              109,
              101,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** GetBoardFile */
    getBoardFile: {
      name: "GetBoardFile",
      requestType: GetBoardFileRequest,
      requestStream: false,
      responseType: DataChunk,
      responseStream: true,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              36,
              34,
              31,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              103,
              101,
              116,
              45,
              98,
              111,
              97,
              114,
              100,
              45,
              102,
              105,
              108,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** GetFileFromArchive */
    getFileFromArchive: {
      name: "GetFileFromArchive",
      requestType: GetFileFromArchiveRequest,
      requestStream: false,
      responseType: DataChunk,
      responseStream: true,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              43,
              34,
              38,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              103,
              101,
              116,
              45,
              102,
              105,
              108,
              101,
              45,
              102,
              114,
              111,
              109,
              45,
              97,
              114,
              99,
              104,
              105,
              118,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** ZipAndUploadBundle */
    zipAndUploadBundle: {
      name: "ZipAndUploadBundle",
      requestType: ZipAndUploadBundleRequest,
      requestStream: false,
      responseType: ZipAndUploadBundleResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              41,
              34,
              36,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              122,
              105,
              112,
              45,
              97,
              110,
              100,
              45,
              117,
              112,
              108,
              111,
              97,
              100,
              45,
              102,
              105,
              108,
              101,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /** ImportBundleFiles */
    importBundleFiles: {
      name: "ImportBundleFiles",
      requestType: ImportBundleFilesRequest,
      requestStream: false,
      responseType: EmptyResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              41,
              34,
              36,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              105,
              109,
              112,
              111,
              114,
              116,
              45,
              98,
              117,
              110,
              100,
              108,
              101,
              45,
              102,
              105,
              108,
              101,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /**
     * readWidget - for src id-s
     * updateWidget - for dst id-s
     * CopyBoardFiles
     */
    copyBoardFiles: {
      name: "CopyBoardFiles",
      requestType: CopyBoardFilesRequest,
      requestStream: false,
      responseType: CopyBoardFilesResponse,
      responseStream: false,
      options: {
        _unknownFields: {
          578365826: [
            new Uint8Array([
              38,
              34,
              33,
              47,
              97,
              112,
              105,
              47,
              117,
              112,
              108,
              111,
              97,
              100,
              101,
              114,
              47,
              118,
              49,
              58,
              99,
              111,
              112,
              121,
              45,
              98,
              111,
              97,
              114,
              100,
              45,
              102,
              105,
              108,
              101,
              115,
              58,
              1,
              42,
            ]),
          ],
        },
      },
    },
    /**
     * UploadDataset uploads Dataset file that represents table data.
     * This rpc assumes that authorization token is located in request metadata.
     */
    uploadDataset: {
      name: "UploadDataset",
      requestType: UploadDatasetRequest,
      requestStream: false,
      responseType: UploadDatasetResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface FileUploaderServiceImplementation<CallContextExt = {}> {
  /** AddDefaultBundle implement method to upload default bundle and attach default metadata to it */
  addDefaultBundle(
    request: AsyncIterable<AddBundleRequest>,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<AddBundleResponse>>;
  /** UploadDefaultBundleResource upload bundle */
  uploadDefaultBundleResource(
    request: AsyncIterable<UploadDefaultBundleResourceRequest>,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<CreatedFileResponse>>;
  /** CopyFile copy file from one tenantID/boardID to another (use files folder to build file path) */
  copyFile(request: CopyFileRequest, context: CallContext & CallContextExt): Promise<DeepPartial<CreatedFileResponse>>;
  /** GetBundleMetadata implement method to fetch bundle metadata */
  getBundleMetadata(
    request: GetBundleMetadataRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<BundleMetadataResponse>>;
  /** UpdateBundleMetadata implement method to update bundle metadata */
  updateBundleMetadata(
    request: UpdateBundleMetadataRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<BundleMetadataResponse>>;
  /** UploadFile if boardID in key is passed then Upload ordinary file else upload whole bundle */
  uploadFile(
    request: UploadFileRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<UploadFileResponse>>;
  /**
   * manageOrganization || for user check token userID и req userID
   * UploadAvatar if boardID in key is passed then Upload ordinary file else upload whole bundle
   */
  uploadAvatar(
    request: UploadAvatarRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<CreatedFileResponse>>;
  /** GetUserAvatarSignedLink returns user's avatar signed link */
  getUserAvatarSignedLink(
    request: GetUserAvatarSignedLinkRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<GetUserAvatarSignedLinkResponse>>;
  /**
   * ParseTableDataRows starts publishing messages to the queue with table rows batches.
   * Method is for internal use and may be removed.
   */
  parseTableDataRows(
    request: ParseTableDataRowsRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<TableDataRowsResponse>>;
  /** UploadTemplate */
  uploadTemplate(
    request: UploadTemplateRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<CreatedFileResponse>>;
  /** UploadFileByStream if boardID in key is passed then Upload ordinary file else upload whole bundle */
  uploadFileByStream(
    request: AsyncIterable<UploadFileRequestByStream>,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<UploadFileResponse>>;
  /** GetBoardFileSignedLink */
  getBoardFileSignedLink(
    request: FileSignLinkRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<FileSignLinkResponse>>;
  /** GenerateSignedLinkToUploadFile */
  generateSignedLinkToUploadFile(
    request: GenerateSignedLinkRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<GenerateSignedLinkResponse>>;
  /**
   * DeleteFiles
   * if tenantID and boardID in key is passed - delete by boardID
   * if tenantID in key is passed - delete by tenantID
   */
  deleteFiles(request: DeleteFileRequest, context: CallContext & CallContextExt): Promise<DeepPartial<EmptyResponse>>;
  /**
   * DeleteFilesByNames
   * if array of names is passed - delete by names
   */
  deleteFilesByNames(
    request: DeleteFileRequestByNames,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<EmptyResponse>>;
  /** GetBoardFile */
  getBoardFile(
    request: GetBoardFileRequest,
    context: CallContext & CallContextExt,
  ): ServerStreamingMethodResult<DeepPartial<DataChunk>>;
  /** GetFileFromArchive */
  getFileFromArchive(
    request: GetFileFromArchiveRequest,
    context: CallContext & CallContextExt,
  ): ServerStreamingMethodResult<DeepPartial<DataChunk>>;
  /** ZipAndUploadBundle */
  zipAndUploadBundle(
    request: ZipAndUploadBundleRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<ZipAndUploadBundleResponse>>;
  /** ImportBundleFiles */
  importBundleFiles(
    request: ImportBundleFilesRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<EmptyResponse>>;
  /**
   * readWidget - for src id-s
   * updateWidget - for dst id-s
   * CopyBoardFiles
   */
  copyBoardFiles(
    request: CopyBoardFilesRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<CopyBoardFilesResponse>>;
  /**
   * UploadDataset uploads Dataset file that represents table data.
   * This rpc assumes that authorization token is located in request metadata.
   */
  uploadDataset(
    request: UploadDatasetRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<UploadDatasetResponse>>;
}

export interface FileUploaderClient<CallOptionsExt = {}> {
  /** AddDefaultBundle implement method to upload default bundle and attach default metadata to it */
  addDefaultBundle(
    request: AsyncIterable<DeepPartial<AddBundleRequest>>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<AddBundleResponse>;
  /** UploadDefaultBundleResource upload bundle */
  uploadDefaultBundleResource(
    request: AsyncIterable<DeepPartial<UploadDefaultBundleResourceRequest>>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<CreatedFileResponse>;
  /** CopyFile copy file from one tenantID/boardID to another (use files folder to build file path) */
  copyFile(request: DeepPartial<CopyFileRequest>, options?: CallOptions & CallOptionsExt): Promise<CreatedFileResponse>;
  /** GetBundleMetadata implement method to fetch bundle metadata */
  getBundleMetadata(
    request: DeepPartial<GetBundleMetadataRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<BundleMetadataResponse>;
  /** UpdateBundleMetadata implement method to update bundle metadata */
  updateBundleMetadata(
    request: DeepPartial<UpdateBundleMetadataRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<BundleMetadataResponse>;
  /** UploadFile if boardID in key is passed then Upload ordinary file else upload whole bundle */
  uploadFile(
    request: DeepPartial<UploadFileRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<UploadFileResponse>;
  /**
   * manageOrganization || for user check token userID и req userID
   * UploadAvatar if boardID in key is passed then Upload ordinary file else upload whole bundle
   */
  uploadAvatar(
    request: DeepPartial<UploadAvatarRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<CreatedFileResponse>;
  /** GetUserAvatarSignedLink returns user's avatar signed link */
  getUserAvatarSignedLink(
    request: DeepPartial<GetUserAvatarSignedLinkRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<GetUserAvatarSignedLinkResponse>;
  /**
   * ParseTableDataRows starts publishing messages to the queue with table rows batches.
   * Method is for internal use and may be removed.
   */
  parseTableDataRows(
    request: DeepPartial<ParseTableDataRowsRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<TableDataRowsResponse>;
  /** UploadTemplate */
  uploadTemplate(
    request: DeepPartial<UploadTemplateRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<CreatedFileResponse>;
  /** UploadFileByStream if boardID in key is passed then Upload ordinary file else upload whole bundle */
  uploadFileByStream(
    request: AsyncIterable<DeepPartial<UploadFileRequestByStream>>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<UploadFileResponse>;
  /** GetBoardFileSignedLink */
  getBoardFileSignedLink(
    request: DeepPartial<FileSignLinkRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<FileSignLinkResponse>;
  /** GenerateSignedLinkToUploadFile */
  generateSignedLinkToUploadFile(
    request: DeepPartial<GenerateSignedLinkRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<GenerateSignedLinkResponse>;
  /**
   * DeleteFiles
   * if tenantID and boardID in key is passed - delete by boardID
   * if tenantID in key is passed - delete by tenantID
   */
  deleteFiles(request: DeepPartial<DeleteFileRequest>, options?: CallOptions & CallOptionsExt): Promise<EmptyResponse>;
  /**
   * DeleteFilesByNames
   * if array of names is passed - delete by names
   */
  deleteFilesByNames(
    request: DeepPartial<DeleteFileRequestByNames>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<EmptyResponse>;
  /** GetBoardFile */
  getBoardFile(
    request: DeepPartial<GetBoardFileRequest>,
    options?: CallOptions & CallOptionsExt,
  ): AsyncIterable<DataChunk>;
  /** GetFileFromArchive */
  getFileFromArchive(
    request: DeepPartial<GetFileFromArchiveRequest>,
    options?: CallOptions & CallOptionsExt,
  ): AsyncIterable<DataChunk>;
  /** ZipAndUploadBundle */
  zipAndUploadBundle(
    request: DeepPartial<ZipAndUploadBundleRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<ZipAndUploadBundleResponse>;
  /** ImportBundleFiles */
  importBundleFiles(
    request: DeepPartial<ImportBundleFilesRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<EmptyResponse>;
  /**
   * readWidget - for src id-s
   * updateWidget - for dst id-s
   * CopyBoardFiles
   */
  copyBoardFiles(
    request: DeepPartial<CopyBoardFilesRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<CopyBoardFilesResponse>;
  /**
   * UploadDataset uploads Dataset file that represents table data.
   * This rpc assumes that authorization token is located in request metadata.
   */
  uploadDataset(
    request: DeepPartial<UploadDatasetRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<UploadDatasetResponse>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(int64: { toString(): string }): number {
  const num = globalThis.Number(int64.toString());
  if (num > globalThis.Number.MAX_SAFE_INTEGER) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (num < globalThis.Number.MIN_SAFE_INTEGER) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return num;
}

export type ServerStreamingMethodResult<Response> = { [Symbol.asyncIterator](): AsyncIterator<Response, void> };

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
