import { useCallback } from 'react'
import { changeWorkflowBlockOutputType } from 'actions'
import { useDispatch } from 'react-redux'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import { findSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import FormField from 'components/common/FormField'
import ReactSelect from 'components/fields/ReactSelect'
import {
  EOutputFormatType,
  formatTypeOptions,
  outputDataTypeOptions
} from 'components/workflowBuilder/widgetDataBlocks/convertWidgetData/convertWidgetData.constants'

type TProps = {
  blockId: string | null
  type: EOutputFormatType
  dataType: OBJECT_TYPES
  updateBlockMeta: (data: Record<string, string>) => void
}

export const ConvertWidgetDataBlockOutputSelector = ({
  type,
  dataType,
  updateBlockMeta,
  blockId
}: TProps) => {
  const dispatch = useDispatch()

  const onChangeDataType = useCallback(
    ({ value }: { value: OBJECT_TYPES }) => {
      if (value !== dataType) {
        updateBlockMeta({
          [EFieldKeys.DATA_TYPE]: value,
          [EFieldKeys.TYPE]: value === OBJECT_TYPES.WIDGET_DATA ? EOutputFormatType.text : ''
        })

        dispatch(
          // @ts-expect-error
          changeWorkflowBlockOutputType({
            blockId,
            defaultPresentationName:
              value === OBJECT_TYPES.WIDGET_DATA
                ? messages.WIDGET_DATA_OUTPUT
                : messages.STRING_DATA_OUTPUT,
            type: value
          })
        )
      }
    },
    [dispatch, updateBlockMeta, dataType, blockId]
  )

  const onChangeType = useCallback(
    ({ value }: { value: EOutputFormatType }) => {
      if (value !== type) {
        updateBlockMeta({
          [EFieldKeys.TYPE]: value
        })
      }
    },
    [updateBlockMeta, type]
  )

  return (
    <FormField
      label={messages.DEFINE_OUTPUT_DATA_TYPE}
      id="convert-output-type"
      className="convert-data-output-type"
    >
      <div className="convert-data-output-type-selectors">
        {/* @ts-expect-error */}
        <ReactSelect
          className="convert-data-type-selector"
          size="large"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value={findSelectedOptionByKey(dataType, outputDataTypeOptions)}
          options={outputDataTypeOptions}
          menuPortalEnabled
          onChange={onChangeDataType}
        />
        {dataType === OBJECT_TYPES.WIDGET_DATA && (
          // @ts-expect-error
          <ReactSelect
            className="convert-format-type-selector"
            size="large"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={findSelectedOptionByKey(type, formatTypeOptions)}
            options={formatTypeOptions}
            menuPortalEnabled
            onChange={onChangeType}
          />
        )}
      </div>
    </FormField>
  )
}
