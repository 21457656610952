import React, { Component } from 'react'

import FindWidgetsBundleBlock, {
  MAX_TOKENS_COUNT
} from '../../../components/workflowBuilder/findBundleBlock/FindWidgetsBundleBlock'

import { getSuggestions } from '../../../api/filtersAPI'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from '../../../constants/workflows'
import { getSuggestionsType } from '../../../helpers/workflowBuilder/workflowBuilderHelpers'

export default class FindWidgetsBundleBlockContainer extends Component {
  constructor(props) {
    super(props)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.changeWidgetsList = this.changeWidgetsList.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.loadSuggestion = this.loadSuggestion.bind(this)
    this.isValidNewOption = this.isValidNewOption.bind(this)
  }

  handleInputChange(fieldName, item) {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset(fieldName) {
    this.handleInputChange(fieldName, { id: null })
  }

  updateWorkflowBlock(data) {
    const { block } = this.props

    const meta = block.meta || {}

    this.props.updateWorkflowBlock({
      id: block.id,
      data: {
        meta: {
          ...meta,
          ...data
        },
        error: {}
      }
    })
  }

  changeWidgetsList(values) {
    if (values.length > MAX_TOKENS_COUNT) {
      return
    }

    this.updateWorkflowBlock({
      [EFieldKeys.NAMES]: values.map(item => item.value)
    })
  }

  loadSuggestion(inputValue) {
    const { tenantId } = this.props

    const minInputLength = 3

    const value = inputValue ? inputValue.trim() : ''

    if (!value || value.length < minInputLength) {
      return null
    }

    return getSuggestions({
      type: getSuggestionsType(OBJECT_TYPES.WIDGET),
      tenantId,
      query: value
    })
      .then(response => response.data.slice(0, 5).map(label => ({ label, value: label })))
      .catch(err => console.error(err))
  }

  isValidNewOption() {
    const {
      block: { meta }
    } = this.props

    return meta[EFieldKeys.NAMES].length < MAX_TOKENS_COUNT
  }

  render() {
    const { block } = this.props

    return (
      <FindWidgetsBundleBlock
        block={block}
        handleReset={this.handleReset}
        loadSuggestion={this.loadSuggestion}
        isValidNewOption={this.isValidNewOption}
        handleInputChange={this.handleInputChange}
        changeWidgetsList={this.changeWidgetsList}
      />
    )
  }
}
