import { useCallback } from 'react'
import { changeWorkflowBlockInput, updateWorkflowBlock } from 'actions'
import { useDispatch } from 'react-redux'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import './convert-widget-data-into-file.scss'

type TProps = {
  block: IWorkflowBlock
}

export const ConvertWidgetDataIntoFile = ({ block: { id, meta, input, error } }: TProps) => {
  const dispatch = useDispatch()

  const updateWorkflowBlockMeta = useCallback(
    (newMeta: Partial<IWorkflowBlock['meta']>) => {
      const updatedMeta = { ...meta, ...newMeta }

      // @ts-expect-error
      dispatch(updateWorkflowBlock({ id, data: { meta: updatedMeta, error: {} } }))
    },
    [dispatch, id, meta]
  )

  const handleInputChange = useCallback(
    (fieldName: string, item: Partial<IWorkflowDropdownValue> | null) => {
      dispatch(
        // @ts-expect-error
        changeWorkflowBlockInput({
          fieldName,
          blockId: id,
          outputId: item ? item.id : null,
          oldOutputId: input[fieldName] || null,
          error
        })
      )
    },
    [dispatch, id, input, error]
  )

  const handleInputSelect = useCallback(
    (item: Partial<IWorkflowDropdownValue> | null) => {
      handleInputChange(EFieldKeys.SOURCE_INPUT, item)
      updateWorkflowBlockMeta({ [EFieldKeys.SOURCE_INPUT]: item })
    },
    [handleInputChange, updateWorkflowBlockMeta]
  )

  const handleInputReset = useCallback(() => handleInputSelect(null), [handleInputSelect])

  const inputFieldLabel = 'Widget Input'
  const inputFieldPlaceholder = 'Select widget output from previous blocks'

  return (
    <div className="workflow-convert-widget-data-into-file-block">
      <BlockInputContainer
        type={OBJECT_TYPES.WIDGET}
        blockId={id}
        value={input[EFieldKeys.SOURCE_INPUT]}
      >
        {({
          selectedItem,
          items,
          searchItems
        }: {
          selectedItem: IWorkflowDropdownValue
          items: IWorkflowDropdownValue[]
          searchItems: (search: string) => void
        }) => (
          <AnyLevelDropdown
            title={inputFieldLabel}
            placeholder={inputFieldPlaceholder}
            type={OBJECT_TYPES.WIDGET}
            items={items}
            value={selectedItem}
            searchItems={searchItems}
            error={error[EFieldKeys.SOURCE_INPUT]}
            supportHistory
            onSelect={handleInputSelect}
            onReset={handleInputReset}
          />
        )}
      </BlockInputContainer>
      <div className="warning-message">
        Only Query Widget type is supported - other types will not generate a file output.
      </div>
    </div>
  )
}
