import React from 'react'

import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'

import GetBlock from './GetBlock'

const GetWidget = props => {
  const { block, tenantId, updateWorkflowBlock } = props

  return (
    <GetBlock
      block={block}
      tenantId={tenantId}
      inputFieldLabel={messages.GET_WIDGET}
      inputFieldPlaceholder={messages.GET_WIDGET_PLACEHOLDER}
      inputType={OBJECT_TYPES.WIDGET}
      updateWorkflowBlock={updateWorkflowBlock}
      noItemsPlaceholder={messages.NO_WIDGETS_IN_TEAM}
      noItemsFoundPlaceholder={messages.NO_WIDGETS_FOUND}
    />
  )
}

export default GetWidget
