import React, { Component } from 'react'

import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'
import AnyLevelDropdown from '../../common/AnyLevelDropdown'
import BlockInputContainer from '../../../containers/workflowBuilder/common/BlockInputContainer'

import { OBJECT_TYPES } from '../../../constants/workflows'

import messages from '../../../constants/messages'
import '../../../scss/workflowBuilder/findBlocks/find-user-block.scss'

class FindUser extends Component {
  constructor(props) {
    super(props)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.onReset = this.onReset.bind(this)
  }

  onReset(fieldName) {
    this.handleInputChange(fieldName, { id: null })
  }

  handleInputChange(fieldName, item) {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  render() {
    const {
      block: { error, id, input }
    } = this.props
    return (
      <div className="workflow-actions-content find-user-block">
        <BlockInputContainer
          type={OBJECT_TYPES.STRING}
          blockId={id}
          value={input[EFieldKeys.STRING_INPUT]}
        >
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              title={messages.STRING_LEVEL_INPUT}
              placeholder={messages.STRING_LEVEL_INPUT_PLACEHOLDER}
              items={items}
              onSelect={this.handleInputChange.bind(null, EFieldKeys.STRING_INPUT)}
              onReset={this.onReset.bind(null, EFieldKeys.STRING_INPUT)}
              value={selectedItem}
              type={OBJECT_TYPES.STRING}
              searchItems={searchItems}
              error={error[EFieldKeys.STRING_INPUT]}
              supportHistory
            />
          )}
        </BlockInputContainer>
        <p className="hint-text"> {messages.FIND_USER_BLOCK_HINT} </p>
      </div>
    )
  }
}

export default FindUser
