import { EHttpMethod } from 'constants/common'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { TokenInputBox } from 'components/tokenInputBox/tokenInputBox'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import { TOKEN_INPUT_BOX_HINT_TEXT } from '../restApiClientBlock.constants'
import './body-section.scss'

const BODY_INPUT_MAX_LENGTH = 50000

type TProps = {
  block: IWorkflowBlock
  updateBlockMeta: (key: EFieldKeys, fieldValue: unknown[]) => void
  handleInputChange: (fieldName: string, item: Partial<IWorkflowDropdownValue>) => void
  handleReset: (fieldName: string) => void
}

export const BodySection = ({ block, updateBlockMeta, handleInputChange, handleReset }: TProps) => {
  const isBodyDisabled = block.meta[EFieldKeys.METHOD_TYPE] === EHttpMethod.GET

  return (
    <div className="wf-rest-api-block-body-section">
      {isBodyDisabled ? (
        <div className="placeholder-message">Body do not used with HTTP method ’GET’</div>
      ) : (
        <TokenInputBox
          block={block}
          fieldMeta={block.meta}
          bodyFieldKey={EFieldKeys.BODY}
          tokenFieldKey={EFieldKeys.STRING_INPUT}
          tokenInputBoxLabel="Body"
          tokenInputPlaceholder="Provide request body"
          error={block.error[EFieldKeys.BODY] as string}
          handleInputChange={handleInputChange}
          handleReset={handleReset}
          updateBlockMeta={updateBlockMeta}
          maxLength={BODY_INPUT_MAX_LENGTH}
          tokenInputBoxHintText={TOKEN_INPUT_BOX_HINT_TEXT}
          isDisabled={isBodyDisabled}
          hasHint
        />
      )}
    </div>
  )
}
