import React from 'react'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

import CopyBlockContainer from '../../../containers/workflowBuilder/сopyBlocks/CopyBlockContainer'

import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'

import '../../../scss/workflowBuilder/copyBlocks/copy-blocks.scss'

const inputSourceAttr = {
  inputSourceType: OBJECT_TYPES.WIDGETS_BUNDLE,
  inputSourceTitle: messages.WIDGETS_BUNDLE_LEVEL_INPUT,
  inputSourcePlaceholder: messages.WIDGETS_BUNDLE_LEVEL_INPUT_PLACEHOLDER
}

const inputTargetAttr = {
  inputTargetType: OBJECT_TYPES.CARD,
  targetFieldKey: EFieldKeys.CARD_INPUT,
  inputTargetTitle: messages.DESTINATION_CARD_FOR_COPY,
  inputTargetPlaceholder: messages.DESTINATION_CARD_FOR_COPY_PLACEHOLDER
}

const CopyWidgetsBundle = props => {
  const { block, updateWorkflowBlock, changeWorkflowBlockInput } = props

  return (
    <CopyBlockContainer
      block={block}
      inputSourceAttr={inputSourceAttr}
      inputTargetAttr={inputTargetAttr}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
    />
  )
}

export default CopyWidgetsBundle
