import { useCallback, useState } from 'react'
import { changeWorkflowBlockInput, updateWorkflowBlock } from 'actions'
import { useDispatch } from 'react-redux'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { CryptoService } from 'services/common/crypto.service'
import { ExpandableSection } from 'components/common/expandableSection/expandableSection'
import { OutputFormatToggle } from 'components/workflowBuilder/common/outputFormatToggle/outputFormatToggle'
import { type IWorkflowBlock, type IWorkflowDropdownValue } from 'features/workflow/workflow.types'
import { AuthorizationSection } from './authorization/authorizationSection'
import { BodySection } from './body/bodySection'
import { CurlModeSection } from './curlMode/curlModeSection'
import { CurlModeSwitcher } from './curlMode/curlModeSwitcher/curlModeSwitcher'
import { HeadersSection } from './headers/headersSection'
import { InformationSection } from './information/informationSection'
import { REST_API_CLIENT_BLOCK_OUTPUTS } from './restApiClientBlock.constants'
import { RetryPolicySection } from './retryPolicy/retryPolicySection'
import './rest-api-client-block.scss'

const isCurlModeEnabled = import.meta.env.VITE_IS_WF_REST_API_CLIENT_CURL_MODE_ENABLED === 'true'

const REST_API_CLIENT_BLOCK_SECTIONS = [
  {
    title: 'Information',
    className: 'information',
    forceOpenedKey: EFieldKeys.URL_TARGET,
    Component: InformationSection
  },
  {
    title: 'Authorization',
    className: 'authorization',
    forceOpenedKey: EFieldKeys.CREDENTIAL_ID,
    unmountOnExit: false,
    Component: AuthorizationSection
  },
  {
    title: 'Headers',
    className: 'headers',
    forceOpenedKey: EFieldKeys.HEADERS,
    Component: HeadersSection
  },
  {
    title: 'Body',
    className: 'body',
    forceOpenedKey: EFieldKeys.BODY,
    Component: BodySection
  },
  {
    title: 'Retry policy',
    className: 'retry-policy',
    unmountOnExit: false,
    forceOpenedKey: EFieldKeys.RETRIES,
    Component: RetryPolicySection
  }
]

type TProps = {
  block: IWorkflowBlock
}

export const RestAPIClientBlock = ({ block }: TProps) => {
  const dispatch = useDispatch()

  // TODO: replace with store value
  const [isCurlMode, setIsCurlMode] = useState(false)

  const updateWorkflowBlockMeta = useCallback(
    (newMeta: Partial<IWorkflowBlock['meta']>) => {
      const meta = { ...block.meta, ...newMeta }

      // @ts-expect-error
      dispatch(updateWorkflowBlock({ id: block.id, data: { meta, error: {} } }))
    },
    [dispatch, block]
  )

  const updateBlockMeta = useCallback(
    (key: EFieldKeys, fieldValue: unknown) => {
      const initialHash = CryptoService.hashObject(block.meta[key])
      const updatedHash = CryptoService.hashObject(fieldValue)

      if (initialHash !== updatedHash) {
        updateWorkflowBlockMeta({ [key]: fieldValue })
      }
    },
    [block.meta, updateWorkflowBlockMeta]
  )

  const handleInputChange = useCallback(
    (fieldName: string, item: Partial<IWorkflowDropdownValue>) => {
      dispatch(
        // @ts-expect-error
        changeWorkflowBlockInput({
          fieldName,
          blockId: block.id,
          outputId: item.id,
          oldOutputId: block.input[fieldName] || null,
          error: block.error
        })
      )
    },
    [dispatch, block.id, block.input, block.error]
  )

  const handleReset = useCallback(
    (fieldName: string) => handleInputChange(fieldName, { id: null }),
    [handleInputChange]
  )

  return (
    <div className="workflow-rest-api-client-block">
      {block.open && isCurlModeEnabled && (
        <CurlModeSwitcher isCurlMode={isCurlMode} setIsCurlMode={setIsCurlMode} />
      )}
      {isCurlMode && isCurlModeEnabled ? (
        <CurlModeSection
          block={block}
          updateBlockMeta={updateBlockMeta}
          handleInputChange={handleInputChange}
          handleReset={handleReset}
        />
      ) : (
        <div className="configuration-sections">
          {REST_API_CLIENT_BLOCK_SECTIONS.map(
            ({ title, className, forceOpenedKey, unmountOnExit, Component }) => (
              <ExpandableSection
                key={title}
                title={title}
                className={className}
                expandArrowPlacement="left"
                expandArrowOrientation="horizontal"
                isExpanded={block.error[forceOpenedKey] ? true : undefined}
                shouldUnmountOnExit={unmountOnExit}
              >
                <Component
                  block={block}
                  updateWorkflowBlockMeta={updateWorkflowBlockMeta}
                  updateBlockMeta={updateBlockMeta}
                  handleInputChange={handleInputChange}
                  handleReset={handleReset}
                />
              </ExpandableSection>
            )
          )}
        </div>
      )}
      <OutputFormatToggle
        block={block}
        outputs={REST_API_CLIENT_BLOCK_OUTPUTS}
        outputFieldKey={EFieldKeys.DATA_TYPE}
      />
    </div>
  )
}
