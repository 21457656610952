import { FormControl, FormHelperText } from '@mui/material'
import classNames from 'classnames'
import { type GroupType, type Props } from 'react-select'
import { type LoadOptions, AsyncPaginate } from 'react-select-async-paginate'
import './async-select.scss'

type TProps = Props & {
  loadOptions: LoadOptions<
    { label: string; value: string },
    GroupType<{ label: string; value: string }>,
    { page: number }
  >
  additional: { page: number }
  error?: boolean
  helperText?: string
  size: string
}

export const AsyncSelect = ({
  styles,
  size,
  className,
  error,
  helperText,
  ...restProps
}: TProps) => (
  <FormControl className={classNames('praxie-select', className)} error={error}>
    <AsyncPaginate
      styles={styles}
      className={classNames('upboard-react-select plain-select', size, className, { error })}
      classNamePrefix="upboard-rs"
      {...restProps}
    />
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
)
