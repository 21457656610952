import { type Dispatch } from 'redux'
import { reloadCardLoadedSections, toggleCardTitleEditing, togglePresentationMode } from 'actions'
import { history } from 'services/common/history.service'
import { getIsTheSameCardPath } from 'helpers/cards/cards.helpers'
import { getDetailedViewRenamingCardUuid } from 'helpers/detailedView/headerHelpers'
import {
  checkRoute,
  getAppViewRoute,
  navigateToBoard,
  navigateToDetailedView,
  navigateToEmbedCard,
  routesList
} from 'helpers/routesHelpers'
import { OPEN_CARD_MODES } from './workflowMetaHelpers'

const cardModeRouteCheckers = [
  {
    mode: OPEN_CARD_MODES.detailed,
    checkRoute: (pathname: string) => checkRoute(pathname, routesList.detailedView)
  },
  {
    mode: OPEN_CARD_MODES.appView,
    checkRoute: (pathname: string) => checkRoute(pathname, routesList.appView)
  },
  {
    mode: OPEN_CARD_MODES.embed,
    checkRoute: (pathname: string) => checkRoute(pathname, routesList.embedView)
  }
]

type TPayload = {
  boardId: string
  cardUuid: string
  tenantId: string
  cardMode: string
}

type TProps = {
  payload: TPayload
  dispatch: Dispatch
  isPresentationModeEnabled?: boolean
}

export const openCardFromWorkflow = ({ payload, dispatch, isPresentationModeEnabled }: TProps) => {
  const { boardId, cardUuid } = payload

  if (!boardId) {
    return
  }

  if (!cardUuid) {
    // @ts-expect-error navigateToBoard is not typed
    navigateToBoard(payload)
    return
  }

  let cardMode = payload.cardMode

  if (cardMode === OPEN_CARD_MODES.sameView) {
    if (getIsTheSameCardPath(payload)) {
      // @ts-expect-error: dispatch expects an action, but we're passing a thunk
      return dispatch(reloadCardLoadedSections(payload))
    }

    const pathname = window.location.pathname

    cardModeRouteCheckers.forEach(({ mode, checkRoute }) => {
      if (checkRoute(pathname)) {
        cardMode = mode
      }
    })

    if (isPresentationModeEnabled && cardMode === OPEN_CARD_MODES.detailed) {
      cardMode = OPEN_CARD_MODES.fullScreen
    }
  }

  switch (cardMode) {
    case OPEN_CARD_MODES.fullScreen:
      navigateToDetailedView({ ...payload, isPresentationMode: true })
      dispatch(toggleCardTitleEditing(getDetailedViewRenamingCardUuid(payload.cardUuid)))

      break
    case OPEN_CARD_MODES.embed:
      navigateToEmbedCard(payload)

      break
    case OPEN_CARD_MODES.appView:
      history.push(getAppViewRoute(payload))

      break
    case OPEN_CARD_MODES.detailed:
      navigateToDetailedView(payload)
      dispatch(toggleCardTitleEditing(getDetailedViewRenamingCardUuid(payload.cardUuid)))

      if (isPresentationModeEnabled) {
        dispatch(togglePresentationMode(false))
      }

      break
    default:
      break
  }

  return
}
