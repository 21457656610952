import { Component, createRef } from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CrossIcon from 'assets/images/icons/cross.svg?react'
import ChevronIcon from 'assets/images/icons/chevron.svg?react'
import DropdownItem from './DropdownItem'
import SelectedItem from './SelectedItem'
import { Menu } from '../menu/menu'
import messages from '../../../constants/messages'
import DotsSpinner from '../../spinners/DotsSpinner'
import 'scss/common/any-level-dropdown/any-level-dropdown.scss'

let timeout = null

class AnyLevelDropdownBase extends Component {
  state = {
    inputValue: '',
    open: false
  }

  menuAnchorEl = createRef()

  handleClose = () => {
    const { open } = this.state

    // to not update component on every click outside
    if (open) {
      this.setState({ open: false, inputValue: '' })
    }
  }

  handleChangeInputValue = event => {
    const { searchItems, minSearchLength } = this.props
    const inputValue = event.target.value

    this.setState({ open: true, inputValue })

    clearTimeout(timeout)

    timeout = setTimeout(() => {
      if (searchItems && inputValue.trim().length >= minSearchLength) {
        searchItems(inputValue)
      }
    }, 300)
  }

  handleToggleClick = () => {
    const { searchItems, disabled } = this.props
    const { open, inputValue } = this.state

    if (!disabled) {
      // request items after dropdown opening
      if (!open && searchItems) {
        this.props.searchItems(inputValue)
      }

      this.setState(state => ({ open: !state.open }))
    }
  }

  handleItemClick = item => {
    const { onSelect } = this.props

    this.setState({ open: false, inputValue: '' })

    // To avoid showing an empty state during the closing animation
    setTimeout(() => onSelect(item), 150)
  }

  render() {
    const {
      inputRef,
      title,
      placeholder,
      value,
      onReset,
      items,
      limit,
      limitWasReached,
      error,
      warn,
      type,
      supportHistory,
      onBlur,
      disabled,
      noItemsPlaceholder,
      isLoading
    } = this.props
    const { inputValue, open } = this.state

    const noItemsFound = !items || !items.length
    const invalid = error || (value && value.error)

    return (
      <div className="any-level-dropdown">
        {title && <h3 className="any-level-dropdown__title">{title}</h3>}
        <div
          ref={this.menuAnchorEl}
          className={classNames('any-level-dropdown__select', {
            active: open,
            invalid,
            disabled
          })}
        >
          {value ? (
            <SelectedItem type={type} value={value} supportHistory={supportHistory} />
          ) : (
            <input
              ref={inputRef}
              type="text"
              className="any-level-dropdown__input"
              value={inputValue}
              placeholder={placeholder}
              onFocus={this.handleToggleClick}
              onChange={this.handleChangeInputValue}
              onBlur={onBlur}
            />
          )}
          {value && (
            <motion.span
              type="button"
              className="reset-button"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              onClick={onReset}
            >
              <CrossIcon />
            </motion.span>
          )}
          <div
            className={classNames(
              'any-level-dropdown__arrow',
              open && 'open',
              disabled && 'disabled'
            )}
            onClick={this.handleToggleClick}
          >
            <ChevronIcon />
          </div>
          <Menu
            id="any-level-dropdown-menu"
            className="any-level-dropdown-menu"
            open={open}
            anchorEl={this.menuAnchorEl.current}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            disableAutoFocus
            onClick={e => e.stopPropagation()}
            onClose={this.handleClose}
          >
            <div className="menu-content">
              {isLoading && (
                <div className="loader">
                  <DotsSpinner color="dark" size="lg" />
                </div>
              )}
              {!isLoading && noItemsFound && (
                <div className="empty-state">{noItemsPlaceholder}</div>
              )}
              {!isLoading && !noItemsFound && (
                <div className="suggestions">
                  {limitWasReached && (
                    <p className="suggestions-title">{messages.FIRST_N_RESULTS(limit)}</p>
                  )}
                  {items.map((item, index) => (
                    <DropdownItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      item={item}
                      type={type}
                      supportHistory={supportHistory}
                      onSelect={this.handleItemClick}
                    />
                  ))}
                </div>
              )}
            </div>
          </Menu>
        </div>
        {error && <p className="field-error">{error}</p>}
        {warn && <p className="field-warn">{warn}</p>}
      </div>
    )
  }
}

AnyLevelDropdownBase.defaultProps = {
  title: '',
  placeholder: '',
  error: '',
  warn: '',
  value: null,
  supportHistory: false,
  disabled: false,
  onBlur: null,
  noItemsPlaceholder: messages.NO_INPUTS_FOUND,
  limit: 0,
  limitWasReached: false,
  minSearchLength: 0
}

/**
 * supportHistory flag used to let the component know if a dropdown value/dropdown list item
 * might be changed automatically. For instance, dropdown might be changed automatically in
 * workflow builder via creating the following blocks chain: "Get Card"->"Move Card to Column".
 * "Move Card to Column" explicitly depends on the selected card in the "Get Card" block,
 * hence dropdown's "Card" value changed automatically right after a user change selection
 * in the"Get Card" block. supportHistory flag requires the following properties in value/items
 * inputs: "index" and block.
 */
AnyLevelDropdownBase.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  value: PropTypes.shape({}),
  onSelect: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  supportHistory: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  warn: PropTypes.string,
  noItemsPlaceholder: PropTypes.string,
  limit: PropTypes.number,
  limitWasReached: PropTypes.bool,
  minSearchLength: PropTypes.number
}

export default AnyLevelDropdownBase
