import React from 'react'
import { OBJECT_TYPES } from '../../../../constants/workflows'
import CommonLevelItem from './CommonLevelItem'
import CardLevelItem from './CardLevelItem'
import WidgetLevelItem from './WidgetLevelItem'
import UserLevelItem from './UserLevelItem'
import '../../../../scss/common/any-level-dropdown/any-level-dropdown-item/any-level-dropdown-item.scss'

const DropdownItem = props => {
  const { supportHistory, type } = props
  if (supportHistory) {
    return <CommonLevelItem {...props} />
  }

  switch (type) {
    case OBJECT_TYPES.BOARD:
      return <CommonLevelItem {...props} />
    case OBJECT_TYPES.CARD:
    case OBJECT_TYPES.COLUMN:
      return <CardLevelItem {...props} />
    case OBJECT_TYPES.WIDGET:
      return <WidgetLevelItem {...props} />
    case OBJECT_TYPES.USER:
      return <UserLevelItem {...props} />
    case OBJECT_TYPES.STRING:
    case OBJECT_TYPES.COMMENT:
      return <CommonLevelItem {...props} />
    default:
      return <div />
  }
}

export default DropdownItem
