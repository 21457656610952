import React from 'react'

import TextButton from '../../buttons/TextButton'

import messages from '../../../constants/messages'

import '../../../scss/workflowBuilder/hideCardSectionBlock/sections-list.scss'

const SectionsList = props => {
  const { isAddSectionShown, isSectionsDisabled, children, addSection } = props

  return (
    <div className="sections-list">
      {children}
      {isAddSectionShown && (
        <TextButton onClick={addSection} disabled={isSectionsDisabled}>
          {messages.ADD_SECTION}
        </TextButton>
      )}
    </div>
  )
}

export default SectionsList
