import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import DefineStringBlock from './DefineStringBlock'
import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'

const DefineStringFromUser = props => {
  const { block, updateWorkflowBlock, changeWorkflowBlockInput } = props
  return (
    <DefineStringBlock
      outputField={EFieldKeys.ATTRIBUTE}
      inputFieldLabel={messages.USER_LEVEL_INPUT}
      inputFieldPlaceholder={messages.USER_LEVEL_INPUT_PLACEHOLDER}
      inputType={OBJECT_TYPES.USER}
      outputFieldPlaceholder={messages.SELECT_USER_ATTRIBUTE}
      block={block}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
    />
  )
}

export default DefineStringFromUser
