import { Component } from 'react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import {
  COLUMN_NUMBER,
  NEXT_COLUMN,
  PREV_COLUMN
} from 'helpers/workflowMoveCardBlock/moveCardBlockHelpers'
import MoveCardBlock from 'components/workflowBuilder/moveCardBlock/MoveCardBlock'

export default class MoveCardBlockContainer extends Component {
  constructor(props) {
    super(props)

    const columnName = props.block.meta[EFieldKeys.NAME]

    const type = props.block.meta[EFieldKeys.TYPE]
    // to support same logic for COLUMN_NUMBER, NEXT_COLUMN and PREV_COLUMN columnNames
    const columnNumber =
      type === COLUMN_NUMBER
        ? props.block.meta[EFieldKeys.COLUMN_NUMBER]
        : props.block.meta[EFieldKeys.RELATIVE_COLUMN_NUMBER]

    // eslint-disable-next-line react/state-in-constructor
    this.state = {
      columnName,
      columnNumber
    }
  }

  changeColumnType = ({ value }) => {
    const {
      block: { meta }
    } = this.props

    this.updateBlockMeta(EFieldKeys.TYPE, value)

    // set appropriate number or relative number when type was changed
    if (value === COLUMN_NUMBER) {
      this.setState({ columnNumber: meta[EFieldKeys.COLUMN_NUMBER] })
    } else if ([PREV_COLUMN, NEXT_COLUMN].includes(value)) {
      this.setState({ columnNumber: meta[EFieldKeys.RELATIVE_COLUMN_NUMBER] })
    }
  }

  changeColumnName = event => {
    const columnName = event.target.value

    this.setState({ columnName })
  }

  blurColumnName = event => {
    const columnName = event.target.value.trim()
    this.updateBlockMeta(EFieldKeys.NAME, columnName)
  }

  changeColumnNumber = columnNumber => {
    const {
      block: { meta }
    } = this.props

    const fieldKey =
      meta[EFieldKeys.TYPE] === COLUMN_NUMBER
        ? EFieldKeys.COLUMN_NUMBER
        : EFieldKeys.RELATIVE_COLUMN_NUMBER

    this.setState({ columnNumber })
    this.updateBlockMeta(fieldKey, columnNumber)
  }

  handleInputChange = (fieldName, item) => {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset = fieldName => {
    this.handleInputChange(fieldName, { id: null })
  }

  updateBlockMeta = (key, fieldValue) => {
    const {
      updateWorkflowBlock,
      block: { id, meta }
    } = this.props

    if (meta[key] !== fieldValue) {
      updateWorkflowBlock({
        id,
        data: {
          meta: { ...meta, [key]: fieldValue },
          error: {}
        }
      })
    }
  }

  render() {
    const { block } = this.props
    const { columnName, columnNumber } = this.state
    const meta = block.meta || {}

    return (
      <MoveCardBlock
        block={block}
        columnName={columnName}
        columnType={meta[EFieldKeys.TYPE]}
        columnNumber={columnNumber}
        handleReset={this.handleReset}
        changeColumnType={this.changeColumnType}
        changeColumnName={this.changeColumnName}
        blurColumnName={this.blurColumnName}
        handleInputChange={this.handleInputChange}
        changeColumnNumber={this.changeColumnNumber}
      />
    )
  }
}
