import React from 'react'

const WidgetLevelItem = ({ item, onSelect }) => (
  <div className="any-level-dropdown-item-block">
    <div className="any-level-dropdown__divider">
      <i className="icon workflow-block-icon icon-board-item" />
      <p className="any-level-dropdown__divider-text">{item.boardName}</p>
    </div>
    {item.widgets &&
      item.widgets.map(widget => (
        <div
          // combined key to avoid cases when widgets from different cards have same uuid
          key={`${widget.cardUuid}${widget.widgetUuid}`}
          className="any-level-dropdown-item widget-item"
          onMouseDown={onSelect.bind(null, widget)}
        >
          <div className="widget-name bold">
            <i className={`icon icon-widget icon-${widget.widgetClassName}`} />
            {widget.widgetTitle}
          </div>
          <div className="any-level-dropdown-item-cardname">{widget.cardName}</div>
        </div>
      ))}
  </div>
)

export default WidgetLevelItem
