import { type CSSProperties } from 'react'
import {
  type TConditionalLayoutCondition,
  type TConditionalLayoutVariable
} from 'components/cardbuilder/conditionalLayout/conditionalLayout.types'
import {
  type ELogicalRuleOperator,
  type ILogicalRule
} from 'features/logicalRule/logicalRule.types'
import { EWFTriggerAttribute } from 'features/workflow/workflow.types'

export interface IWidgetConditionalLayoutRule {
  uuid: string
  conditionsOperator: string
  variables: TConditionalLayoutVariable[]
  conditions: TConditionalLayoutCondition[]
}

export enum EWidgetLayoutActionType {
  LOCK = 'lock',
  HIDE = 'hide'
}

export type TWidgetWFTriggerParams = {
  x: number
  y: number
}

export type TWidgetWF = {
  workflowName: string
  trigger?: {
    attribute: EWFTriggerAttribute
    params: TWidgetWFTriggerParams
  }
  logicalRuleIds?: Array<ILogicalRule['id']>
  logicalRuleOperator?: ELogicalRuleOperator
  isLogicalRulesEnabled?: boolean
}

export type TWidgetWFMeta = {
  workflows: TWidgetWF[]
  isWorkflowMode: boolean
}

export interface IWidget {
  id: string
  uuid: string
  cardId: string
  cardUuid: string
  tenantId: string
  boardId: string
  organizationId: string
  dataLink?: unknown
  providers?: unknown[]
  dataFormatCode?: string
  srcTenantId?: string
  widgetClassName: string
  widgetTitle?: string
  category: Array<Record<string, string>>
  conditionalLockingRules?: IWidgetConditionalLayoutRule[]
  conditionalHidingRules?: IWidgetConditionalLayoutRule[]
  section: number
  height: number
  minHeight?: number
  width: number
  minWidth?: number
  positionX?: number
  positionY?: number
  rotation?: string
  zIndex?: number
  groupIndex?: string
  flags?: Record<string, boolean>
  createdAt: number
  uploadDate: number
  lastUpdate?: number
  updatedAt?: number
  version: number
  workflowMeta?: TWidgetWFMeta
  // It's set to true right after the widget is created in Card Builder. Will be deleted after the first save.
  newlyCreated?: boolean
  shouldHideOnPDF?: boolean
  showOnBoardView?: boolean
  isReactComponent?: boolean
  isLocked?: boolean
  isConditionalLockEnabled?: boolean
  isConditionalHideEnabled?: boolean
  isMuted?: boolean
  cardName?: string
  boundaries: DOMRect
  style: Pick<CSSProperties, 'textAlign' | 'fontFamily'> & {
    isBold?: boolean
    isItalic?: boolean
    textColor?: string
    fontSize?: string
  }
  thumbnailSrc?: string
  imgSrc?: string
  fileLink?: string
  extension?: string
  size?: number
  key?: {
    path: string[]
    boardId: string
  }
  uploadedBy?: string
  menuTitle?: string
  tooltip?: string
}

export type TSystemWidgetInfo = {
  name: string
  folder: string
  file: string
}

export enum EWidgetModes {
  EDIT = 'edit',
  DETAILED = 'detailed',
  PREVIEW = 'preview'
}

export enum EWidgetSelectedStates {
  VIEW = 'view',
  SELECTED = 'selected',
  EDITING = 'editing'
}
