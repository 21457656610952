import React from 'react'

import messages from '../../../constants/messages'
import { OBJECT_TYPES } from '../../../constants/workflows'

import RenameBlockContainer from '../../../containers/workflowBuilder/renameBlocks/RenameBlockContainer'

const RenameBoard = props => {
  const { block, updateWorkflowBlock, changeWorkflowBlockInput } = props

  return (
    <RenameBlockContainer
      block={block}
      sourceInputType={OBJECT_TYPES.BOARD}
      sourceInputFieldLabel={messages.BOARD_LEVEL_INPUT}
      sourceInputFieldPlaceholder={messages.BOARD_LEVEL_INPUT_PLACEHOLDER}
      updateWorkflowBlock={updateWorkflowBlock}
      changeWorkflowBlockInput={changeWorkflowBlockInput}
    />
  )
}

export default RenameBoard
