import { type TTokenInputValue } from './tokenInputBox'

export const isInputToken = (token: TTokenInputValue) =>
  typeof token !== 'string' && token.fieldName

export const getRemovedInputValues = (
  prevInputValues: TTokenInputValue[],
  updatedInputValues: TTokenInputValue[]
) =>
  prevInputValues.filter(prevValue => {
    if (typeof prevValue !== 'string' && prevValue.fieldName) {
      return !updatedInputValues.some(
        newValue => typeof newValue !== 'string' && newValue.fieldName === prevValue.fieldName
      )
    }

    return false
  })

export const isNodeAnyLevelDropdownInput = (node: HTMLElement) =>
  node.classList.value === 'any-level-dropdown__input'

export const isNodePartOfAnyLevelDropdown = (node: HTMLElement) =>
  node.closest && node.closest('[contenteditable=false]')

export const strip = (string: string) => string.replace(/(<([^>]+)>)/gi, '')

export const flatSingle = (arr: string[][]) => ([] as string[]).concat(...arr)

export const getValidForMarkdownParseComments = (inputValues: TTokenInputValue[]) =>
  inputValues.map(inputValue => {
    if (typeof inputValue !== 'string') {
      return inputValue
    }

    return inputValue.replace(/\u200b/g, '')
  })
