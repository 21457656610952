import React, { Component } from 'react'

import NumberFieldContainer from '../../../containers/workflowBuilder/common/NumberFieldContainer'
import RadioField from '../../common/RadioField'

import messages from '../../../constants/messages'

import '../../../scss/workflowBuilder/hideCardSectionBlock/section.scss'

class Section extends Component {
  shouldComponentUpdate(nextProps) {
    const { isDeleteShown, isSectionsDisabled, section } = nextProps

    const isSectionChanged = section !== this.props.section
    const isDisabledChanged = isSectionsDisabled !== this.props.isSectionsDisabled
    const isDeleteShownChanged = isDeleteShown !== this.props.isDeleteShown

    return isSectionChanged || isDisabledChanged || isDeleteShownChanged
  }

  render() {
    const {
      blockId,
      isDeleteShown,
      isSectionsDisabled,
      section: { uuid, index, hide },
      deleteSection,
      changeSectionNumber,
      toggleSectionVisibility
    } = this.props

    return (
      <div className="card-section-block-section">
        <NumberFieldContainer
          value={index}
          onChange={changeSectionNumber.bind(null, uuid)}
          disabled={isSectionsDisabled}
        />
        <div className="visibility-options">
          <RadioField
            id={`${blockId}-${uuid}-hide-section`}
            checked={hide || false}
            label={messages.HIDE_CARD_SECTION}
            onChange={toggleSectionVisibility.bind(null, uuid)}
            disabled={isSectionsDisabled}
          />
          <RadioField
            id={`${blockId}-${uuid}-show-section`}
            checked={!hide}
            label={messages.UNHIDE_CARD_SECTION}
            onChange={toggleSectionVisibility.bind(null, uuid)}
            disabled={isSectionsDisabled}
          />
        </div>
        {isDeleteShown && !isSectionsDisabled && (
          <div className="delete-section" onClick={deleteSection.bind(null, uuid)}>
            <i className="icon icon-close" />
          </div>
        )}
      </div>
    )
  }
}

export default Section
