enum EPages {
  root = 'root',
  admin = 'admin',
  app = 'app',
  appStore = 'appStore',
  appBuilder = 'appBuilder',
  auth = 'auth',
  board = 'board',
  bundle = 'bundle',
  boardAdministration = 'boardAdministration',
  cardBuilder = 'cardBuilder',
  embed = 'embed',
  getStarted = 'getStarted',
  organizationAdministration = 'organizationAdministration',
  sso = 'sso',
  reset = 'reset',
  signIn = 'signIn',
  signUp = 'signUp',
  signUpSuccess = 'signUpSuccess',
  signInWithSso = 'signInWithSso',
  search = 'search',
  invalidInvitation = 'invalidInvitation',
  preparingWorkspace = 'preparingWorkspace',
  teamAdministration = 'teamAdministration',
  tenant = 'tenant',
  homepage = 'homepage',
  myActions = 'myActions',
  subscribedApps = 'subscribedApps',
  verify = 'verify',
  verifyEntitlement = 'verifyEntitlement',
  snapshot = 'snapshot',
  successPayment = 'successPayment',
  cancelledPayment = 'cancelledPayment',
  workflow = 'workflow',
  appImport = 'appImport',
  openApp = 'openApp'
}

type TPath = {
  id: string
  name: string
  url: string
  routerPath: string
  isAuthPage?: boolean
  isMainPage?: boolean
  isEmbeddedPage?: boolean
  isAvailableWithoutSubscription?: boolean
  isVerifyPage?: boolean
  isPayment?: boolean
}

export const PATHS: Record<EPages, TPath> = {
  root: {
    id: 'root',
    name: 'Root',
    url: '/',
    routerPath: '/',
    isEmbeddedPage: true
  },
  admin: {
    id: 'admin',
    name: 'Admin',
    url: '/admin/workspaces-list',
    routerPath: '/admin/workspaces-list'
  },
  app: {
    id: 'app',
    name: 'App',
    url: '/app',
    routerPath: '/app/:tenantId/:boardId/:cardID',
    isEmbeddedPage: true
  },
  appImport: {
    id: 'appImport',
    name: 'AppImport',
    url: '/appImport',
    routerPath: '/appImport/:tenantId/:appID/:appJobId/:appStoreID',
    isEmbeddedPage: true
  },
  appStore: {
    id: 'appStore',
    name: 'App Store',
    url: '/app-store',
    routerPath: '/app-store',
    isAvailableWithoutSubscription: true,
    isEmbeddedPage: true
  },
  appBuilder: {
    id: 'appBuilder',
    name: 'App Builder',
    url: '/app-builder',
    routerPath: '/app-builder/:tenantId/:appId',
    isMainPage: true
  },
  auth: {
    id: 'auth',
    name: 'Auth',
    url: '/auth',
    routerPath: '/auth',
    isAvailableWithoutSubscription: true,
    isAuthPage: true
  },
  board: {
    id: 'board',
    name: 'Board',
    url: '/board',
    routerPath: '/board/:tenantId?/:boardId?/:cardID?',
    isEmbeddedPage: true
  },
  bundle: {
    id: 'bundle',
    name: 'Bundle',
    url: '/bundle',
    routerPath: '/bundle/:appId',
    isAvailableWithoutSubscription: true,
    isEmbeddedPage: true
  },
  boardAdministration: {
    id: 'boardAdministration',
    name: 'Board Administration',
    url: '/board-administration',
    routerPath: '/board-administration/:tenantId/:boardId'
  },
  cardBuilder: {
    id: 'cardBuilder',
    name: 'Card Builder',
    url: '/cardbuilder',
    routerPath: '/cardbuilder/:tenantId/:boardId/:cardID',
    isMainPage: true
  },
  embed: {
    id: 'embed',
    name: 'Embed',
    url: '/embed',
    routerPath: '/embed/:tenantId/:boardId/:cardID',
    isMainPage: true
  },
  getStarted: {
    id: 'getStarted',
    name: 'Get started',
    url: '/get-started',
    routerPath: '/get-started',
    isAvailableWithoutSubscription: true,
    isEmbeddedPage: true
  },
  organizationAdministration: {
    id: 'organizationAdministration',
    name: 'Organization Administration',
    url: '/organization-administration',
    routerPath: '/organization-administration/:organizationId',
    isMainPage: true
  },
  sso: { id: 'sso', name: 'Sso', url: '/sso/board/creating', routerPath: '/sso/board/creating' },
  reset: {
    id: 'reset',
    name: 'Reset',
    url: '/reset',
    routerPath: '/reset/:token',
    isAvailableWithoutSubscription: true,
    isAuthPage: true
  },
  signIn: {
    id: 'signIn',
    name: 'Sign In',
    url: '/signin',
    routerPath: '/signin',
    isAvailableWithoutSubscription: true,
    isAuthPage: true
  },
  signUp: {
    id: 'signUp',
    name: 'Sign Up',
    url: '/signup',
    routerPath: '/signup/:organization?',
    isAvailableWithoutSubscription: true,
    isAuthPage: true
  },
  signUpSuccess: {
    id: 'signUpSuccess',
    name: 'Sign Up Success',
    url: '/signup-success',
    routerPath: '/signup-success',
    isAvailableWithoutSubscription: true,
    isAuthPage: true
  },
  signInWithSso: {
    id: 'signInWithSso',
    name: 'Sign In With Sso',
    url: '/signin-with-sso',
    routerPath: '/signin-with-sso',
    isAvailableWithoutSubscription: true,
    isAuthPage: true
  },
  search: {
    id: 'search',
    name: 'Search',
    url: '/search',
    routerPath: '/search',
    isAvailableWithoutSubscription: true,
    isEmbeddedPage: true
  },
  invalidInvitation: {
    id: 'invalidInvitation',
    name: 'Invalid Invitation',
    url: '/invalid-invitation',
    routerPath: '/invalid-invitation',
    isAvailableWithoutSubscription: true,
    isAuthPage: true
  },
  preparingWorkspace: {
    id: 'preparingWorkspace',
    name: 'Preparing Workspace',
    url: '/preparing-workspace',
    routerPath: '/preparing-workspace',
    isAvailableWithoutSubscription: true,
    isAuthPage: true
  },
  teamAdministration: {
    id: 'teamAdministration',
    name: 'Team Administration',
    url: '/team-administration',
    routerPath: '/team-administration/:tenantId',
    isMainPage: true
  },
  tenant: {
    id: 'tenant',
    name: 'Tenant',
    url: '/tenant',
    routerPath: '/tenant/:tenantId',
    isEmbeddedPage: true
  },
  homepage: {
    id: 'homepage',
    name: 'Homepage',
    url: '/homepage',
    routerPath: '/homepage',
    isAvailableWithoutSubscription: true,
    isEmbeddedPage: true
  },
  subscribedApps: {
    id: 'subscribedApps',
    name: 'Subscribed Apps',
    url: '/subscribed-apps',
    routerPath: '/subscribed-apps',
    isEmbeddedPage: true
  },
  myActions: {
    id: 'myActions',
    name: 'My actions',
    url: '/my-actions',
    routerPath: '/my-actions',
    isEmbeddedPage: true
  },
  verify: {
    id: 'verify',
    name: 'Verify',
    url: '/verify',
    routerPath: '/verify/:token',
    isVerifyPage: true
  },
  verifyEntitlement: {
    id: 'verifyEntitlement',
    name: 'Verify Entitlement',
    url: '/verify/entitlement',
    routerPath: '/verify/entitlement/:uuid',
    isVerifyPage: true
  },
  snapshot: {
    id: 'snapshot',
    name: 'Snapshot',
    url: '/snapshot',
    routerPath: '/snapshot/:organizationId/:tenantId/:boardId/:cardId'
  },
  successPayment: {
    id: 'successPayment',
    name: 'Success Payment',
    url: '/payment/success',
    routerPath: '/payment/success/:sessionId',
    isPayment: true
  },
  cancelledPayment: {
    id: 'cancelledPayment',
    name: 'Cancelled Payment',
    url: '/payment/cancelled',
    routerPath: '/payment/cancelled',
    isPayment: true
  },
  workflow: {
    id: 'workflow',
    name: 'Workflow',
    url: '/workflow',
    routerPath: '/workflow/:tenantId/:boardId/:workflowId',
    isMainPage: true
  },
  openApp: {
    id: 'openApp',
    name: ' Open app',
    url: '/open-app',
    routerPath: '/open-app/:tenantId/:appId',
    isEmbeddedPage: true
  }
} as const

export const PATHS_LIST = Object.values(PATHS)

export const AUTH_PATHS_LIST = PATHS_LIST.filter(path => path.isAuthPage)
export const MAIN_PATHS_LIST = PATHS_LIST.filter(path => path.isMainPage)
export const EMBEDDED_PAGES_PATHS_LIST = PATHS_LIST.filter(path => path.isEmbeddedPage)
export const VERIFY_PATHS_LIST = PATHS_LIST.filter(path => path.isVerifyPage)
export const PAYMENT_PATHS_LIST = PATHS_LIST.filter(path => path.isPayment)

export const INDEX_PATH = '/index.html'
