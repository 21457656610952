export enum ESnapPoint {
  left = 'left',
  right = 'right',
  top = 'top',
  bottom = 'bottom',
  centerX = 'centerX',
  centerY = 'centerY'
}

export type TSnapDirection = 'vertical' | 'horizontal'
export type TActionType = 'move' | 'resize'

export type TWidgetRect = {
  id: string
  section: number
  left: number
  right: number
  top: number
  bottom: number
  centerX: number
  centerY: number
  width: number
  height: number
}

export type TSnappingState = {
  initialCursorPosition: {
    x: number | null
    y: number | null
  }
  snapDelta: {
    x: number | null
    y: number | null
  }
  snappedPoints: Record<ESnapPoint, boolean>
}

export type TInteractEvent = {
  pageX: number
  pageY: number
  edges?: { left?: boolean; right?: boolean; top?: boolean; bottom?: boolean }
}

export type TClosestSnapPoint = null | {
  targetPoint: ESnapPoint
  closestPoint: ESnapPoint
  distance: number
}
