import React, { Component } from 'react'

import messages from '../../../constants/messages'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from '../../../constants/workflows'

import AnyLevelDropdown from '../../common/AnyLevelDropdown'
import DefineColumn from './DefineColumn'
import BlockInputContainer from '../../../containers/workflowBuilder/common/BlockInputContainer'

import '../../../scss/workflowBuilder/moveCardBlock/move-card-block.scss'

export default class MoveCardBlock extends Component {
  shouldComponentUpdate(nextProps) {
    const { block, columnType, columnName, columnNumber } = nextProps

    const isColumnTypeChanged = columnType !== this.props.columnType
    const isColumnNameChanged = columnName !== this.props.columnName
    const isColumnNumberChanged = columnNumber !== this.props.columnNumber
    const isBlockChanged = block !== this.props.block

    return isColumnTypeChanged || isBlockChanged || isColumnNameChanged || isColumnNumberChanged
  }

  render() {
    const {
      block,
      columnType,
      columnName,
      columnNumber,
      handleReset,
      changeColumnType,
      changeColumnName,
      handleInputChange,
      changeColumnNumber,
      blurColumnName
    } = this.props

    const isDefineDisabled = !block.input[EFieldKeys.SOURCE_INPUT]

    return (
      <div className="move-card-block">
        <BlockInputContainer
          type={OBJECT_TYPES.CARD}
          blockId={block.id}
          value={block.input[EFieldKeys.SOURCE_INPUT]}
        >
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              supportHistory
              title={messages.CARD_LEVEL_INPUT}
              placeholder={messages.CARD_LEVEL_INPUT_PLACEHOLDER}
              items={items}
              searchItems={searchItems}
              onSelect={handleInputChange.bind(null, EFieldKeys.SOURCE_INPUT)}
              onReset={handleReset.bind(null, EFieldKeys.SOURCE_INPUT)}
              value={selectedItem}
              type={OBJECT_TYPES.CARD}
              error={block.error[EFieldKeys.SOURCE_INPUT]}
            />
          )}
        </BlockInputContainer>
        <DefineColumn
          isDefineDisabled={isDefineDisabled}
          block={block}
          columnType={columnType}
          columnNumber={columnNumber}
          columnName={columnName}
          handleReset={handleReset}
          changeColumnType={changeColumnType}
          changeColumnName={changeColumnName}
          blurColumnName={blurColumnName}
          changeColumnNumber={changeColumnNumber}
          handleInputChange={handleInputChange}
        />
      </div>
    )
  }
}
