import { type SelectChangeEvent } from '@mui/material/Select/Select'
import { updateWorkflowDefinition, updateWorkflowTriggerBlock } from 'actions'
import { useDispatch } from 'react-redux'
import ReceiveEventIcon from 'assets/images/icons/bell.svg?react'
import CalendarIcon from 'assets/images/icons/calendar.svg?react'
import ClickEventIcon from 'assets/images/icons/hover.svg?react'
import UpdateEventIcon from 'assets/images/icons/workflowBuilder/ic_update_event.svg?react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import {
  TRIGGER_EVENT_OUTPUT_DEFINITIONS,
  TRIGGER_EVENTS,
  TRIGGER_OBJECTS
} from 'constants/workflows'
import { Select } from 'components/common/select/select'
import { type TWorkflowOutput } from 'features/workflow/workflow.types'
import { WF_SCHEDULED_DEFAULT_SETTINGS } from '../scheduleParams/scheduleParams.constants'
import './trigger-block-attributes.scss'

const TRIGGER_EVENT_TYPES = {
  [TRIGGER_EVENTS.CLICK]: {
    label: (
      <span className="triggers-block-select-option">
        <ClickEventIcon className="icon" />
        <span className="option-label">Click button</span>
      </span>
    ),
    value: TRIGGER_EVENTS.CLICK,
    object: TRIGGER_OBJECTS.WIDGET
  },
  [TRIGGER_EVENTS.UPDATE]: {
    label: (
      <span className="triggers-block-select-option">
        <UpdateEventIcon className="icon" />
        <span className="option-label">Update widget</span>
      </span>
    ),
    value: TRIGGER_EVENTS.UPDATE,
    object: TRIGGER_OBJECTS.WIDGET
  },
  [TRIGGER_EVENTS.RECEIVE]: {
    label: (
      <span className="triggers-block-select-option">
        <ReceiveEventIcon className="icon" />
        <span className="option-label">Webhook call</span>
      </span>
    ),
    value: TRIGGER_EVENTS.RECEIVE,
    object: TRIGGER_OBJECTS.WEBHOOK
  },
  [TRIGGER_EVENTS.SCHEDULE]: {
    label: (
      <span className="triggers-block-select-option">
        <CalendarIcon className="icon" />
        <span className="option-label">Schedule</span>
      </span>
    ),
    value: TRIGGER_EVENTS.SCHEDULE,
    object: TRIGGER_OBJECTS.WIDGET
  }
}

const TRIGGER_EVENT_TYPES_LIST = Object.values(TRIGGER_EVENT_TYPES)

type TProps = {
  id: string | null
  output: TWorkflowOutput
  meta: { [key in EFieldKeys]: string }
}

export const TriggerBlockAttributes = ({ id, output, meta }: TProps) => {
  const dispatch = useDispatch()

  const handleChangeTrigger = (fieldValue: string, key: EFieldKeys) => {
    const eventObject = TRIGGER_EVENT_TYPES[fieldValue]?.object
    const outputsDefinitions = TRIGGER_EVENT_OUTPUT_DEFINITIONS[fieldValue] ?? []

    const updatedOutputs = outputsDefinitions.reduce((acc: TWorkflowOutput, currentOutput) => {
      if (!currentOutput) return acc

      const existOutput = output.find(el => el.outputName === currentOutput.outputName)

      acc.push(existOutput ?? currentOutput)

      return acc
    }, [])

    const scheduleSettings =
      fieldValue === TRIGGER_EVENTS.SCHEDULE ? WF_SCHEDULED_DEFAULT_SETTINGS : undefined

    const payload = {
      id,
      data: {
        meta: { ...meta, [key]: fieldValue, eventObject },
        output: updatedOutputs,
        scheduleSettings
      }
    }

    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(updateWorkflowTriggerBlock(payload))
    // @ts-expect-error
    dispatch(updateWorkflowDefinition({ [key]: fieldValue, eventObject }))
  }

  return (
    <div className="workflow-trigger-attributes">
      <Select
        className="triggers-attributes-select"
        MenuProps={{ className: 'triggers-attributes-select-menu' }}
        label="Trigger event"
        value={meta.eventType}
        options={TRIGGER_EVENT_TYPES_LIST}
        onChange={({ target }: SelectChangeEvent<unknown>) =>
          handleChangeTrigger(target.value as string, EFieldKeys.EVENT_TYPE)
        }
      />
    </div>
  )
}
