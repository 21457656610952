import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'
import { showToastMessage } from 'actions'
import RoundIconButton from 'components/buttons/RoundIconButton'
import { Tooltip } from 'components/common/tooltip/tooltip'
import CopyIcon from 'assets/images/icons/copy.svg?react'
import './endpoint-trigger-url.scss'

export const EndpointTriggerUrl = () => {
  const [, copyToClipboard] = useCopyToClipboard()

  const dispatch = useDispatch()

  const { tenantId, boardId, workflowId } = useParams<{
    tenantId: string
    boardId: string
    workflowId: string
  }>()

  const backendUrl = import.meta.env.VITE_BACKEND_URL
  const runWorkflowLink = `${backendUrl}api/tenant/${tenantId}/board/${boardId}/workflow/${workflowId}/run`
  const isNewWorkflowCreation = workflowId === 'new'

  const handleCopyLink = () => {
    copyToClipboard(runWorkflowLink)

    // @ts-ignore
    dispatch(
      showToastMessage({ text: 'Endpoint URL successfully copied to clipboard!', size: 'M' })
    )
  }

  return (
    <section className="workflow-endpoint-trigger-url">
      <div className="title">Endpoint URL</div>
      <div className="content">
        <span className="link" title={runWorkflowLink}>
          {runWorkflowLink}
        </span>
        <Tooltip
          id="endpoint-url-copy-tooltip"
          placement="top"
          title={
            isNewWorkflowCreation ? (
              <center>You will be able to copy Endpoint URL after Saving the Workflow</center>
            ) : (
              'Copy URL'
            )
          }
          shouldWrapChildrenWithDiv
        >
          {/* @ts-ignore */}
          <RoundIconButton
            className="copy-button"
            size="large"
            disabled={isNewWorkflowCreation}
            appearance="light-bg"
            onClick={handleCopyLink}
          >
            <CopyIcon className="icon" />
          </RoundIconButton>
        </Tooltip>
      </div>
    </section>
  )
}
