import React from 'react'
import messages from '../../../constants/messages'
import '../../../scss/workflowBuilder/createBlocks/create-column-default.scss'

const CreateColumnDefault = () => (
  <div className="default-name-section-placeholder">
    {messages.DEFAULT_NAME_PH_ONE}
    <i className="icon workflow-block-icon icon-column-grey" />
    <span className="default-name-section-untitled-column"> {messages.UNTITLED_COLUMN}</span>
    {messages.DEFAULT_NAME_PH_TWO}
  </div>
)

export default CreateColumnDefault
