import { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { validateEmail } from 'helpers/validationHelpers'
import colors from 'scss/colors.module.scss'
import MultiCreatable from './MultiCreatable'

const colourStyles = {
  multiValue: (styles, { data }) => {
    const isInvalid = data.isInvalid

    const invalidStyles = {
      backgroundColor: 'rgba(253, 52, 52, 0.1) !important',
      borderColor: `${colors.red500} !important`
    }

    if (isInvalid) {
      return {
        ...styles,
        ...invalidStyles
      }
    }

    return styles
  }
}

const MAX_EMAILS_COUNT = 20

export default class EmailsMultiCreatable extends Component {
  handleChange = values => {
    this.props.onChange(
      values.map(value => ({
        ...value,
        isInvalid: !!validateEmail(value.value ? value.value.trim() : '')
      }))
    )
  }

  handlePaste = event => {
    event.preventDefault()

    const value = event.clipboardData.getData('text/plain')

    const emailValues = this.props.values.map(email => email.value)

    const pastedEmails = value
      .split(/[\s,;]+/) // Split by comma, semicolon, or space
      .reduce((acc, email) => {
        const trimmedEmail = email.trim()

        if (trimmedEmail) {
          acc.push(trimmedEmail)
        }
        return acc
      }, [])

    for (const email of pastedEmails) {
      if (emailValues.length >= MAX_EMAILS_COUNT) break

      if (!emailValues.includes(email)) {
        emailValues.push(email)
      }
    }

    const mappedEmails = emailValues.map(value => ({
      value,
      label: value,
      isInvalid: !!validateEmail(value)
    }))

    this.props.onChange(mappedEmails)
  }

  render() {
    const {
      isError,
      disabled,
      placeholder,
      className,
      values,
      options,
      isValidNewOption,
      onBlur,
      size,
      isLoading,
      menuOption,
      menuIsOpen,
      createOnBlur
    } = this.props

    return (
      <MultiCreatable
        className={classNames([className], { 'error-state': isError })}
        placeholder={placeholder}
        values={values}
        options={options}
        isValidNewOption={isValidNewOption}
        submitByKeys={[9, 13, 188, 32]} // Tab, enter, comma, space
        disabled={disabled}
        createOnBlur={createOnBlur}
        styles={colourStyles}
        size={size}
        isLoading={isLoading}
        menuOption={menuOption}
        menuIsOpen={menuIsOpen}
        onChange={this.handleChange}
        onBlur={onBlur}
        onPaste={this.handlePaste}
      />
    )
  }
}

EmailsMultiCreatable.propTypes = {
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  createOnBlur: PropTypes.bool,
  menuIsOpen: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired,
  isValidNewOption: PropTypes.func.isRequired,
  onBlur: PropTypes.func
}

EmailsMultiCreatable.defaultProps = {
  disabled: false,
  isError: false,
  isLoading: false,
  createOnBlur: false,
  menuIsOpen: () => false,
  onBlur: null,
  placeholder: '',
  className: '',
  options: []
}
