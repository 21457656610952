import React, { Component } from 'react'

import PublishFeedNotificationBlock from '../../../components/workflowBuilder/systemActions/PublishFeedNotificationBlock'

export default class PublishFeedNotificationContainer extends Component {
  handleInputChange = (fieldName, item) => {
    const { block } = this.props

    this.props.changeWorkflowBlockInput({
      fieldName,
      blockId: block.id,
      outputId: item.id,
      oldOutputId: block.input[fieldName] || null
    })
  }

  handleReset = fieldName => {
    this.handleInputChange(fieldName, { id: null })
  }

  updateBlockMeta = (key, fieldValue) => {
    const {
      updateWorkflowBlock,
      block: { id, meta }
    } = this.props

    if (meta[key] !== fieldValue) {
      updateWorkflowBlock({
        id,
        data: {
          meta: { ...meta, [key]: fieldValue },
          error: {}
        }
      })
    }
  }

  render() {
    const { block, tenantId } = this.props
    return (
      <PublishFeedNotificationBlock
        block={block}
        handleInputChange={this.handleInputChange}
        updateBlockMeta={this.updateBlockMeta}
        handleReset={this.handleReset}
        tenantId={tenantId}
      />
    )
  }
}
