import { getWorkflowExecutionMonitorStatus, triggerClickEvent } from 'api/clickWorkflowAPI'
import { EJobStatuses } from 'features/jobs/jobs.types'
import { navigateToBoard } from 'helpers/routesHelpers'
import {
  sendFinishWorkflowGAAction,
  sendTriggerWorkflowGAAction
} from 'helpers/workflowExecution/workflowGAevents'
import {
  getWorkflowPayloadByWidgetId,
  getWorkflowPayloadErrors
} from 'helpers/workflowExecution/workflowPayloadHelpers'
import { getFinishData } from 'helpers/workflowExecution/workflowMetaHelpers'
import { openCardFromWorkflow } from 'helpers/workflowExecution/workflowFinalStep.helpers'
import {
  TOGGLE_CLICK_WORKFLOW_META_MODAL,
  TOGGLE_CLICK_WORKFLOW_OVERLAY,
  SET_CLICK_WORKFLOW_LOADING,
  SET_CLICK_WORKFLOW_RUNNING,
  SET_CLICK_WORKFLOW_RUNNING_META,
  TOGGLE_CLICK_WORKFLOW_SUCCESS_MESSAGE
} from '../constants/actionTypes'
import messages from '../constants/messages'
import { showToastMessage, toggleWorkflowExecutionIndicator } from './boardActions'
import {
  addNewJob,
  removeJob,
  workflowJobCompleted,
  subscribeToMonitor,
  unsubscribeFromMonitor
} from './socketActions'
import { disableWorkflowSocketFallback, enableWorkflowSocketFallback } from './socketPollingActions'
import { EFinishBlockValues } from 'components/workflowBuilder/finishBlocks/finishBlock.types'

export function toggleClickWorkflowMetaModal(payload) {
  return { type: TOGGLE_CLICK_WORKFLOW_META_MODAL, payload }
}

export function toggleClickWorkflowOverlay(payload) {
  return { type: TOGGLE_CLICK_WORKFLOW_OVERLAY, payload }
}

export function setClickWorkflowLoading(payload) {
  return { type: SET_CLICK_WORKFLOW_LOADING, payload }
}

export function setClickWorkflowRunning(payload) {
  return { type: SET_CLICK_WORKFLOW_RUNNING, payload }
}

export function setClickWorkflowRunningMeta(payload) {
  return { type: SET_CLICK_WORKFLOW_RUNNING_META, payload }
}

export function toggleClickWorkflowSuccessMessage(payload) {
  return { type: TOGGLE_CLICK_WORKFLOW_SUCCESS_MESSAGE, payload }
}

export function performFinalStep(payload) {
  return (dispatch, getState) => {
    if (!payload.openConfiguration) {
      return
    }

    const { type, webLink, boardId, tenantId } = payload.openConfiguration

    switch (type) {
      case EFinishBlockValues.DISPLAY_BOARD:
        navigateToBoard({
          boardId,
          tenantId
        })
        break

      case EFinishBlockValues.DISPLAY_CARD: {
        return openCardFromWorkflow({
          payload: { ...payload.openConfiguration, monitorId: payload.monitorId },
          dispatch,
          isPresentationModeEnabled: getState().detailed.isPresentationModeEnabled
        })
      }

      case EFinishBlockValues.OPEN_LINK:
        if (webLink) {
          window.open(webLink, '_blank')
        }
        break
      default:
    }
  }
}

export function endClickWorkflowExecution(payload) {
  return (dispatch, getState) => {
    const {
      runningWorkflowMeta: { isSuccessMessageEnabled }
    } = getState().clickWorkflow

    dispatch(setClickWorkflowRunning(false))
    dispatch(toggleClickWorkflowOverlay(false))
    dispatch(performFinalStep(payload))

    // if success message is enabled - show it and clean runningWorkflowMeta
    // otherwise just clean runningWorkflowMeta
    if (isSuccessMessageEnabled) {
      dispatch(toggleClickWorkflowSuccessMessage(true))
    } else {
      dispatch(setClickWorkflowRunningMeta({}))
    }
  }
}

export function poleWorkflowExecutionJobs(payload) {
  return (dispatch, getState) => {
    const { subscribedJobs, subscribedMonitor } = getState().socket

    return getWorkflowExecutionMonitorStatus(payload)
      .then(response => {
        const monitor = response.data[0]
        const finishedJobs = monitor.monitors.filter(job => job.status !== EJobStatuses.PENDING)

        finishedJobs.forEach(job => dispatch(workflowJobCompleted(job)))

        const isExecutionFinished = monitor.status !== EJobStatuses.PENDING
        if (isExecutionFinished) {
          disableWorkflowSocketFallback()
          dispatch(unsubscribeFromMonitor(payload))
          dispatch(
            endClickWorkflowExecution({
              ...subscribedMonitor,
              finishData: getFinishData(finishedJobs),
              openConfiguration: monitor.openConfiguration
            })
          )

          sendFinishWorkflowGAAction(monitor.monitors)

          // clear finished jobs through 10 seconds
          setTimeout(() => {
            subscribedJobs.forEach(job => dispatch(removeJob(job)))

            const { subscribedJobs: activeSubscribedJobs } = getState().socket

            // Toggle WF execution toast only if there are no active jobs
            if (!activeSubscribedJobs?.length) {
              dispatch(toggleWorkflowExecutionIndicator(false))
            }
          }, 10 * 1000)
        }
      })
      .catch(err => console.error(err))
  }
}

export function startMonitorWorkflowExecution(payload) {
  return dispatch => {
    const hasJobsToShow = payload.jobs.some(job => job.data.isExecutionShown)

    // if no jobs with isExecutionShown === true - don't show indicator
    if (hasJobsToShow) {
      dispatch(toggleWorkflowExecutionIndicator(true))
    }

    payload.jobs.forEach(job => dispatch(addNewJob(job)))
  }
}

function canExecuteWorkflow(currentBoard) {
  const { boardPermissions = {} } = currentBoard

  return !!boardPermissions.executeWorkflow
}

export function startClickWorkflowExecution(payload) {
  return (dispatch, getState) => {
    if (!canExecuteWorkflow(getState().board.currentBoard)) {
      return null
    }

    dispatch(toggleClickWorkflowOverlay(true))
    dispatch(setClickWorkflowLoading(true))

    return triggerClickEvent(payload)
      .then(response => {
        dispatch(setClickWorkflowLoading(false))

        const jobs = response.data.monitors

        sendTriggerWorkflowGAAction(jobs)

        dispatch(
          subscribeToMonitor({
            ...payload,
            monitorId: response.data.id,
            jobs
          })
        )

        dispatch(setClickWorkflowRunningMeta(payload.workflowMeta))
        dispatch(setClickWorkflowRunning(true))
        dispatch(
          startMonitorWorkflowExecution({
            tenantId: payload.tenantId,
            boardId: payload.boardId,
            cardUuid: payload.cardUuid,
            jobs
          })
        )
        dispatch(enableWorkflowSocketFallback())

        return null
      })
      .catch(err => {
        console.error(err)

        if (err.message && err.errorCode < 500) {
          dispatch(
            showToastMessage({
              text: err.message,
              size: 'M'
            })
          )
        } else {
          dispatch(
            showToastMessage({
              text: messages.TRY_WORKFLOW_AGAIN,
              size: 'M'
            })
          )
        }

        dispatch(toggleClickWorkflowOverlay(false))
        dispatch(setClickWorkflowLoading(false))
      })
  }
}

export function startClickWorkflowByWidgetId(payload) {
  return dispatch => {
    const [error, workflowPayload] = getWorkflowPayloadByWidgetId(payload)

    if (error) {
      dispatch(
        showToastMessage({
          text: getWorkflowPayloadErrors(error),
          size: 'M'
        })
      )

      return Promise.reject(error)
    }

    return dispatch(startClickWorkflowExecution(workflowPayload))
  }
}
