import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import {
  COLUMN_CREATION_OPTIONS,
  COLUMN_CREATION_OPTIONS_LIST,
  PLAIN_STRING,
  STRING_INPUT,
  DEFAULT_NAME
} from 'helpers/workflowCreateColumnBlocks/workflowCreateColumnBlocksHelpers'
import { getSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import FormField from 'components/common/FormField'
import ReactSelect from 'components/fields/ReactSelect'
import PlainInput from 'components/fields/PlainInput'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import CreateColumnDefault from './CreateColumnDefault'

const CreateColumnOptions = ({
  type,
  id,
  error,
  input,
  changeSelectOption,
  isDisabled,
  customName,
  changeCustomName,
  blurCustomName,
  handleInputChange,
  handleReset
}) => (
  <>
    <FormField label="" id="way-of-creation-column">
      <ReactSelect
        className="way-of-creation-column"
        size="large"
        value={getSelectedOptionByKey(type, COLUMN_CREATION_OPTIONS)}
        options={COLUMN_CREATION_OPTIONS_LIST}
        placeholder={messages.ENTER_CUSTOM_TEXT}
        isDisabled={isDisabled}
        menuPortalEnabled
        onChange={option => changeSelectOption(EFieldKeys.TYPE, option)}
      />
    </FormField>
    {type === PLAIN_STRING && (
      <FormField className="" label="" error={error[EFieldKeys.NAME]} id="rename-plain-string">
        <PlainInput
          className="input-field"
          placeholder={messages.CUSTOM_NAME}
          maxLength={50}
          value={customName}
          disabled={isDisabled}
          onChange={changeCustomName}
          onBlur={blurCustomName}
        />
      </FormField>
    )}
    {type === STRING_INPUT && (
      <BlockInputContainer
        type={OBJECT_TYPES.STRING}
        blockId={id}
        value={input[EFieldKeys.STRING_INPUT]}
      >
        {({ selectedItem, items, searchItems }) => (
          <AnyLevelDropdown
            title=""
            placeholder={messages.STRING_LEVEL_INPUT_PLACEHOLDER}
            items={items}
            searchItems={searchItems}
            value={selectedItem}
            type={OBJECT_TYPES.STRING}
            disabled={isDisabled}
            error={error[EFieldKeys.STRING_INPUT]}
            supportHistory
            onSelect={option => handleInputChange(EFieldKeys.STRING_INPUT, option)}
            onReset={() => handleReset(EFieldKeys.STRING_INPUT)}
          />
        )}
      </BlockInputContainer>
    )}
    {type === DEFAULT_NAME && <CreateColumnDefault />}
  </>
)

export default CreateColumnOptions
