import React, { Component } from 'react'
import { EFieldKeys } from '../../../constants/workflowBuilder/blocksFieldsKeys'

import AnyLevelDropdown from '../../common/AnyLevelDropdown'
import GetBlockInputContainer from '../../../containers/workflowBuilder/getBlock/GetBlockInputContainer'

const ITEMS_LIMIT = 20

class GetBlock extends Component {
  constructor(props) {
    super(props)

    this.onOutputChange = this.onOutputChange.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  onOutputChange(entity) {
    const { block } = this.props
    const { id } = block

    this.props.updateWorkflowBlock({
      id,
      data: {
        meta: {
          [EFieldKeys.ENTITY_INPUT]: entity
        },
        error: {}
      }
    })
  }

  handleReset() {
    this.onOutputChange(null)
  }

  render() {
    const {
      tenantId,
      boardId,
      block: { output, meta, error },
      inputType,
      inputFieldLabel,
      inputFieldPlaceholder,
      noItemsPlaceholder,
      noItemsFoundPlaceholder,
      signedInUserId
    } = this.props

    return (
      <div className="workflow-actions-content">
        <GetBlockInputContainer
          type={inputType}
          entity={meta[EFieldKeys.ENTITY_INPUT]}
          tenantId={tenantId}
          signedInUserId={signedInUserId}
          boardId={boardId}
          outputId={output[0].id}
        >
          {({ selectedItem, items, searchItems, isLoading, total, noItems }) => (
            <AnyLevelDropdown
              title={inputFieldLabel}
              placeholder={inputFieldPlaceholder}
              items={items}
              searchItems={searchItems}
              onSelect={this.onOutputChange}
              onReset={this.handleReset}
              value={selectedItem}
              type={inputType}
              error={error && error[EFieldKeys.ENTITY_INPUT]}
              isLoading={isLoading}
              noItemsPlaceholder={noItems ? noItemsPlaceholder : noItemsFoundPlaceholder}
              limit={ITEMS_LIMIT}
              limitWasReached={total > ITEMS_LIMIT}
              minSearchLength={2}
            />
          )}
        </GetBlockInputContainer>
      </div>
    )
  }
}

export default GetBlock
