import React from 'react'

export const TenantHeading = ({ tenant }) => (
  <div className="tenant-title-wrapper bold">
    <i className="icon icon-team" />
    <span className="tenant-title">{tenant.tenantName}</span>
  </div>
)

export const BoardHeading = ({ board }) => (
  <div className="board-title-wrapper bold">
    <i className="icon workflow-block-icon icon-board-item" />
    <span className="board-title">{board.boardName}</span>
  </div>
)

export const SelectedDropdownState = props => (
  <div className="selected-state">
    <p className="card-name bold" title={props.name}>
      {props.name}
    </p>
    <div className="board-info-wrapper">
      <span className="tenant-name bold" title={props.tenantName}>
        {props.tenantName}
      </span>
      {props.boardName && (
        <span className="board-name" title={props.boardName}>
          {`: ${props.boardName}`}
        </span>
      )}
    </div>
  </div>
)

export const Option = ({ name, onSelect }) => (
  <div className="option-wrapper" onClick={onSelect}>
    {name}
  </div>
)

export const CardsList = ({ items, onSelect }) =>
  items.map((tenant, tenantIndex) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={tenantIndex}>
      <TenantHeading tenant={tenant} />
      <ul className="boards-list">
        {tenant.boards.map((board, boardIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={boardIndex} className="board-level">
            <BoardHeading board={board} />
            {board.cards && (
              <ul>
                {board.cards.map(card => (
                  <Option
                    key={card.uuid}
                    name={card.cardName}
                    onSelect={onSelect.bind(null, card)}
                  />
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </React.Fragment>
  ))

export const BoardsList = ({ items, onSelect }) =>
  items.map((tenant, tenantIndex) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={tenantIndex}>
      <TenantHeading tenant={tenant} />
      {tenant.boards.map((board, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Option key={index} name={board.name} onSelect={onSelect.bind(null, board)} />
      ))}
    </React.Fragment>
  ))
