import React from 'react'
import classNames from 'classnames'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'
import { DEFAULT_NAME } from 'helpers/workflowCreateColumnBlocks/workflowCreateColumnBlocksHelpers'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import { Tooltip } from 'components/common/tooltip/tooltip'
import CreateColumnOptions from './CreateColumnOptions'
import 'scss/workflowBuilder/createBlocks/create-column.scss'

const CreateColumn = props => {
  const {
    type,
    block: { id, input, meta, error },
    customName,
    handleReset,
    handleInputChange,
    changeSelectOption,
    changeCustomName,
    blurCustomName
  } = props

  const isDisabled = !input[EFieldKeys.SOURCE_INPUT]
  const isDefaultName = meta[EFieldKeys.TYPE] === DEFAULT_NAME

  return (
    <div className="create-column">
      <BlockInputContainer
        type={OBJECT_TYPES.BOARD}
        blockId={id}
        value={input[EFieldKeys.SOURCE_INPUT]}
      >
        {({ selectedItem, items, searchItems }) => (
          <AnyLevelDropdown
            supportHistory
            title={
              <>
                {messages.CREATE_COLUMN_SOURCE_BOARD_TITLE}
                <Tooltip
                  id="create-column-tooltip"
                  title={messages.CREATE_COLUMN_TOOLTIP}
                  placement="top"
                  className="icon-info-tooltip"
                >
                  <i className="icon workflow-block-icon icon-workflow-info" />
                </Tooltip>
              </>
            }
            placeholder={messages.BOARD_LEVEL_INPUT_PLACEHOLDER}
            items={items}
            searchItems={searchItems}
            onSelect={handleInputChange.bind(null, EFieldKeys.SOURCE_INPUT)}
            onReset={handleReset.bind(null, EFieldKeys.SOURCE_INPUT)}
            value={selectedItem}
            type={OBJECT_TYPES.BOARD}
            error={error[EFieldKeys.SOURCE_INPUT]}
          />
        )}
      </BlockInputContainer>
      <div
        className={classNames('wrapper', {
          'default-name-section': isDefaultName
        })}
      >
        <CreateColumnOptions
          type={type}
          id={id}
          input={input}
          error={error}
          customName={customName}
          blurCustomName={blurCustomName}
          changeCustomName={changeCustomName}
          handleReset={handleReset}
          handleInputChange={handleInputChange}
          changeSelectOption={changeSelectOption}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  )
}

export default CreateColumn
