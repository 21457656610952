import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { EFinishBlockValues } from 'components/workflowBuilder/finishBlocks/finishBlock.types'

export const validateFinishBlock = ({ block }) => {
  const error = {}

  if (
    !block.meta ||
    (block.meta[EFieldKeys.TYPE] === EFinishBlockValues.DISPLAY_CARD &&
      !block.input[EFieldKeys.SOURCE_INPUT]) ||
    (block.meta[EFieldKeys.TYPE] === EFinishBlockValues.DISPLAY_BOARD &&
      !block.input[EFieldKeys.SOURCE_INPUT])
  ) {
    error[EFieldKeys.SOURCE_INPUT] = messages.emptyField
  }

  if (
    block.meta[EFieldKeys.TYPE] === EFinishBlockValues.OPEN_LINK &&
    !block.meta[EFieldKeys.WEB_LINK]
  ) {
    error[EFieldKeys.WEB_LINK] = messages.emptyField
  }

  return { error }
}
