import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import DefineStringBlock from './DefineStringBlock'
import { OBJECT_TYPES } from '../../../constants/workflows'

const DefineStringFromJsonPayload = ({ block, updateWorkflowBlock, changeWorkflowBlockInput }) => (
  <DefineStringBlock
    block={block}
    inputType={OBJECT_TYPES.JSON}
    outputField={EFieldKeys.KEY}
    attributeLabel="Define string from key"
    inputFieldLabel="JSON input"
    inputFieldPlaceholder="Select JSON output from prior step(s) as the input"
    outputFieldPlaceholder="Type JSON key"
    updateWorkflowBlock={updateWorkflowBlock}
    changeWorkflowBlockInput={changeWorkflowBlockInput}
  />
)

export default DefineStringFromJsonPayload
