import { motion } from 'framer-motion'
import { WORKFLOWS_LIST } from 'constants/workflowBlocks'
import { type EWorkflowBlockTypes } from 'constants/workflowBuilder/blocksTypes'
import { BLOCKS_DEFINITION } from 'features/workflow/workflowBuilder/model/workflowBuilder.constants'
import classes from './wf-block-name.module.scss'

type TProps = {
  customName?: string
  blockType: EWorkflowBlockTypes
}

export const WFBlockName = ({ customName, blockType }: TProps) => {
  const blockMeta = WORKFLOWS_LIST[blockType]

  const displayName = customName || BLOCKS_DEFINITION[blockType].name

  return (
    <motion.div
      key={customName}
      className={classes.wfBlockNameWrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div>
        {'Icon' in blockMeta ? (
          <blockMeta.Icon />
        ) : (
          <i
            className={`icon workflow-block-icon icon-${blockMeta.className}`}
            aria-hidden="true"
          />
        )}
      </div>
      <div className={classes.name}>{displayName}</div>
    </motion.div>
  )
}
