const builder = {
  currentWidgetPanelItem: 0,
  isUnsavedChangesShown: false,
  isUnsavedChanges: false,
  isEmptyNameValidationShown: false,
  currentCard: {
    board: {}
  },
  activeSection: 0,
  selectedWidgets: [],
  highlightedWidgets: [],
  highlightedSection: null,
  editingWidget: '',
  createMode: false,
  boardViewAutoMode: false,
  isEditLockedModalShown: false,
  isSaveToBoard: false,

  widgetsBuffer: [],
  chartsModalData: {
    state: false,
    type: '',
    cardID: undefined,
    widgetID: undefined,
    mode: ''
  },
  issueCounterData: {
    state: false,
    cardID: undefined,
    widgetID: undefined,
    mode: ''
  },
  autoSaveID: null,
  popupNotificationData: {
    state: false,
    title: '',
    desc: ''
  },

  dataLinkingModalData: {
    state: false,
    widgetID: undefined
  },

  smartLinkModalData: {
    state: false,
    smartLinkId: '',
    smartLink: {
      link: '',
      mode: '',
      onConfirm: null
    }
  },

  leftMenuCards: [],
  isServerError: false,

  activeWidgetWasUpdated: false,
  bulkWidgetsUpdatePayload: []
}

export default builder
