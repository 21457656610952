import { type JSX } from 'react'
import ChartWidget from 'assets/images/icons/chart.svg?react'
import APIClientWidget from 'assets/images/icons/ic_api_client_widget.svg?react'
import ApiWidget from 'assets/images/icons/ic_api_widget.svg?react'
import BucketWidget from 'assets/images/icons/ic_bucket_widget.svg?react'
import ButtonWidget from 'assets/images/icons/ic_button_widget.svg?react'
import CardDataWidget from 'assets/images/icons/ic_carddata_widget.svg?react'
import CsvWidget from 'assets/images/icons/ic_csv_widget.svg?react'
import DropdownPickerWidget from 'assets/images/icons/ic_dropdown_widget.svg?react'
import GoogleSheetWidget from 'assets/images/icons/ic_google_sheet_widget.svg?react'
import HsTableWidget from 'assets/images/icons/ic_hs_table_widget.svg?react'
import ImageWidget from 'assets/images/icons/ic_image_widget_small.svg?react'
import OfficeSheetWidget from 'assets/images/icons/ic_office_sheet_widget.svg?react'
import ProjectWidget from 'assets/images/icons/ic_project_widget_linking.svg?react'
import QueryBuilderWidget from 'assets/images/icons/ic_query_widget.svg?react'
import SpreadsheetWidget from 'assets/images/icons/ic_spreadsheet_widget.svg?react'
import SQLWidget from 'assets/images/icons/ic_sql_widget_linking.svg?react'
import StageTrackerWidget from 'assets/images/icons/ic_stage_tracker_widget.svg?react'
import TextWidget from 'assets/images/icons/ic_text_widget_linking.svg?react'
import { type TSystemWidgetInfo } from './widgets.types'

export const WIDGETS_IN_SYSTEM: Record<string, TSystemWidgetInfo> = {
  DatasetWidget: {
    name: 'DatasetWidget',
    folder: 'dataset',
    file: 'datasetWidget.tsx'
  },
  GoogleSheetWidget: {
    name: 'GoogleSheetWidget',
    folder: 'cloud-file',
    file: 'cloudFileWidget.tsx'
  },
  OfficeSheetWidget: {
    name: 'OfficeSheetWidget',
    folder: 'cloud-file',
    file: 'cloudFileWidget.tsx'
  },
  SQLWidget: {
    name: 'SQLWidget',
    folder: 'sql',
    file: 'sqlWidget.tsx'
  },
  ProjectRollupTaskWidget: {
    name: 'ProjectRollupTaskWidget',
    folder: 'project-rollup-task',
    file: 'projectRollupTaskWidget.tsx'
  },
  HeatmapChartWidget: {
    name: 'HeatmapChartWidget',
    folder: 'heatmap-chart',
    file: 'heatmapChartWidget.tsx'
  },
  ProjectTaskWidget: {
    name: 'ProjectTaskWidget',
    folder: 'project-task',
    file: 'projectTaskWidget.tsx'
  },
  QueryBuilderWidget: {
    name: 'QueryBuilderWidget',
    folder: 'query-builder',
    file: 'queryBuilderWidget.tsx'
  },
  WaterfallChartWidget: {
    name: 'WaterfallChartWidget',
    folder: 'waterfall-chart',
    file: 'waterfallChartWidget.tsx'
  },
  SpeedometerChartWidget: {
    name: 'SpeedometerChartWidget',
    folder: 'speedometer-chart',
    file: 'speedometerChartWidget.tsx'
  },
  SpreadsheetWidgetAlpha: {
    name: 'SpreadsheetWidgetAlpha',
    folder: 'spreadsheet-widget',
    file: 'spreadsheetWidget.tsx'
  },
  MyTasksWidget: {
    name: 'MyTasksWidget',
    folder: 'my-tasks',
    file: 'myTasksWidget.tsx'
  }
}

export const WIDGET_ICONS: Record<string, JSX.Element> = {
  HsTableWidget: <HsTableWidget />,
  ProjectWidget: <ProjectWidget />,
  ProjectTaskWidget: <ProjectWidget />,
  GoogleSheetWidget: <GoogleSheetWidget />,
  OfficeSheetWidget: <OfficeSheetWidget />,
  CsvWidget: <CsvWidget />,
  ApiWidget: <ApiWidget />,
  QueryBuilderWidget: <QueryBuilderWidget />,
  StageTrackerWidget: <StageTrackerWidget />,
  TextWidget: <TextWidget />,
  ButtonWidget: <ButtonWidget />,
  BarChartWidget: <ChartWidget />,
  HighchartsBubbleChartWidget: <ChartWidget />,
  HighchartsScatterChartWidget: <ChartWidget />,
  LineChartWidget: <ChartWidget />,
  PieChartWidget: <ChartWidget />,
  StackedBarChartWidget: <ChartWidget />,
  ImageWidget: <ImageWidget />,
  SvgWidget: <ImageWidget />,
  APIClientWidget: <APIClientWidget />,
  BucketWidget: <BucketWidget />,
  DropdownPickerWidget: <DropdownPickerWidget />,
  SpreadsheetWidget: <SpreadsheetWidget />,
  CardDataWidget: <CardDataWidget />,
  SQLWidget: <SQLWidget />
}

export const WIDGETS_WITH_LEGACY_LOCKING = ['SpreadsheetWidget', 'TextWidget']

export const WIDGET_RESIZE_THRESHOLD = 24
export const WIDGET_RESIZE_DOTS = [
  'top-resize',
  'top-left-resize',
  'top-right-resize',
  'bottom-left-resize',
  'bottom-right-resize',
  'right-resize',
  'bottom-resize',
  'left-resize'
]
// Resize points available when the width or height of the widget is less than 24px.
export const MINIMAL_WIDGET_RESIZE_DOTS = ['top-left-resize', 'bottom-right-resize']

// Constant for widget classes that should show an outline
export const WIDGET_OUTLINE_CLASSES = [
  'HsTableWidget',
  'QueryBuilderWidget',
  'TextWidget',
  'SpreadsheetWidget'
]
