import { Component, createRef } from 'react'
import interact from 'interactjs'
import classNames from 'classnames'
import messages from 'constants/messages'
import {
  DEFAULT_CARD_HEIGHT,
  updateWidgetPositionWithSection,
  moveWidgetInsideAuthoringArea,
  isWidgetIntersectsWithHiddenSections,
  getWidgetDeltaAuthoringArea
} from 'helpers/builderHelpers'
import { isFileManager } from 'helpers/widget/widgetDataHelpers'
import shallowEqual from 'helpers/shallowEqual'
import { getSelectedWidgetList, getWidgetList } from 'features/widgets/widgets.helpers'
import { getCardAuthoring, getCardScrollableArea } from 'features/cards/cards.helpers'
import Interactive from 'components/common/Interactable'
import { WidgetClickAwayWrapper } from 'components/widgets/clickAwayWrapper/widgetClickAwayWrapper'
import { WidgetSnappingService } from 'components/cardbuilder/widgetSnapping/widgetSnapping.service'
import WidgetItem from 'components/widgets/WidgetItem'
import 'scss/widget.scss'

const backendURL = import.meta.env.VITE_BACKEND_URL

export const url = `${backendURL}api`

const initialMultiSelectDimension = {
  wrapperTop: 0,
  wrapperLeft: 0,
  wrapperWidth: 20,
  wrapperHeight: 20,
  section: 0
}

const restrictLeavingAuthoringArea = ({ targetWidth, targetHeight, x, y, section }) => {
  const cardContent = document.getElementById('card-content-area')
  const cardRect = cardContent.getBoundingClientRect()
  const cardRight = cardRect.x + cardRect.width
  const cardBottom = cardRect.y + cardRect.height
  const cardLeft = cardRect.x
  const cardTop = cardRect.y
  const sectionOffset = section * DEFAULT_CARD_HEIGHT
  return (
    sectionOffset + y < 0 ||
    y + targetHeight > cardBottom - cardTop ||
    x < 0 ||
    x + targetWidth > cardRight - cardLeft
  )
}

class WidgetContainer extends Component {
  constructor(props) {
    super(props)

    this.setupInteractionOptions()
  }

  state = {
    widgets: this.props.widgets,
    isDragging: false,
    initialMultiSelectDimension: {}
  }

  widgetWrapper = createRef()
  renderedWidgets = []
  lastCardScrollTop = 0

  componentDidMount() {
    this.updateDraggableContainer()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!shallowEqual(this.props, nextProps) || !shallowEqual(this.state, nextState)) {
      return !window.isDragging
    }
    return false
  }

  get isMultiSelect() {
    return this.props.selectedWidgets?.length > 1
  }

  get cardScrollTop() {
    return this.scrollContainer.scrollTop ?? 0
  }

  setupInteractionOptions = () => {
    this.draggableOptions = {
      autoScroll: {
        container: null,
        distance: 1,
        interval: 1,
        margin: 20
      },
      onmove: (event, isSnappingEnabled) => {
        const { target } = event

        this.moveMultiSelectRect({ target, event, isSnappingEnabled })
      },
      onend: () => {
        WidgetSnappingService.resetSnappingState()
        this.endMoveMultiSelectRect()
        this.cleanupScrollableArea()
      },
      onstart: event => {
        this.setState({ isDragging: true })
        this.initializeScrollableArea()
        const selectedWidgets = this.isMultiSelect ? getSelectedWidgetList() : [event.target]
        const allWidgets = getWidgetList()

        WidgetSnappingService.initSnappingState({
          selectedWidgets,
          allWidgets,
          actionType: 'move',
          isMultiSelect: this.isMultiSelect
        })
      },
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: '.card-content',
          endOnly: true,
          elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
        })
      ]
    }
  }

  updateDraggableContainer = () => {
    const container = getCardScrollableArea()

    if (container) {
      this.draggableOptions.autoScroll.container = container
    }
  }

  initializeScrollableArea = () => {
    this.scrollContainer = getCardScrollableArea()

    if (!this.scrollContainer) return

    this.lastCardScrollTop = this.cardScrollTop
    this.scrollContainer.addEventListener('scroll', this.handleWidgetAutoscroll)
  }

  cleanupScrollableArea = () => {
    if (!this.scrollContainer) return

    this.scrollContainer.removeEventListener('scroll', this.handleWidgetAutoscroll)
  }

  // To fix: https://leverxeu.atlassian.net/issues/EUPBOARD01-18783
  // Manually fire the onMove event when the container is auto-scrolling.
  handleWidgetAutoscroll = () => {
    if (!this.state.isDragging) {
      this.lastCardScrollTop = this.cardScrollTop
      return
    }

    const cardHeight = this.props.cardHeight || DEFAULT_CARD_HEIGHT
    const target = this.widgetWrapper.current.node

    const wrapperHeight = target.offsetHeight
    const wrapperTop = parseFloat(target.dataset.y)
    const wrapperBottom = wrapperTop + wrapperHeight

    const isWidgetWithinBounds = wrapperBottom >= cardHeight
    if (isWidgetWithinBounds) return

    const currentScroll = this.cardScrollTop
    const dy = currentScroll - this.lastCardScrollTop

    this.lastCardScrollTop = this.cardScrollTop

    const scrollEvent = { dx: 0, dy, delta: { x: 0, y: dy }, shiftKey: false, target }

    this.draggableOptions.onmove(scrollEvent, false)
  }

  startMoveWidgetRect = (selectedWidgets, isMultiSelect) => {
    this.setState({ isDragging: true })
    this.props.startMoveWidgetRect(selectedWidgets, isMultiSelect)
  }

  onMultiSelectMoveStart() {
    // save start multiselect position for constrain movement
    this.setState({
      isDragging: true,
      initialMultiSelectDimension: this.getRectCoords(this.props.selectedWidgets)
    })
  }

  getRectCoords(selectedWidgets) {
    const cardAuthoring = getCardAuthoring()
    const widgetWrapperParams = cardAuthoring.querySelector('.card-content').getBoundingClientRect()
    const widgetsList = selectedWidgets
      .map(id => cardAuthoring.querySelector(`#id_${id}`))
      .filter(item => !!item) // to exclude empty values
    if (!widgetsList.length) {
      return initialMultiSelectDimension
    }
    const cordsXArray = []
    const cordsYArray = []
    for (let i = 0; i < widgetsList.length; i += 1) {
      const selectedWidgetParams = widgetsList[i].getBoundingClientRect()
      cordsXArray.push(selectedWidgetParams.left - widgetWrapperParams.left)
      cordsXArray.push(selectedWidgetParams.right - widgetWrapperParams.left)
      cordsYArray.push(selectedWidgetParams.top - widgetWrapperParams.top)
      cordsYArray.push(selectedWidgetParams.bottom - widgetWrapperParams.top)
    }

    const maxX = cordsXArray.reduce((a, b) => Math.max(a, b))
    const minX = cordsXArray.reduce((a, b) => Math.min(a, b))
    const maxY = cordsYArray.reduce((a, b) => Math.max(a, b))
    const minY = cordsYArray.reduce((a, b) => Math.min(a, b))

    return {
      wrapperTop: minY - 2,
      wrapperLeft: minX - 2,
      wrapperWidth: maxX - minX + 4,
      wrapperHeight: maxY - minY + 4
    }
  }

  updateWidgetsPosition = widgetsData => {
    // method is needed to update section for grouped files
    // is corresonding file manager was moved
    const widgetsToUpdate = widgetsData.reduce((acc, widget) => {
      acc.push(widget)
      if (isFileManager(widget)) {
        const groupedFiles = this.props
          .getGroupedFiles(widget.uuid)
          .map(file => ({ ...file, section: widget.section }))
        acc.push(...groupedFiles)
      }
      return acc
    }, [])
    this.props.updateWidget(widgetsToUpdate)
  }

  endMoveWidgetRect = () => {
    this.setState({ isDragging: false })
    this.props.endMoveWidgetRect()
  }

  endMoveMultiSelectRect() {
    const selectedWidgetList = getSelectedWidgetList()
    const widgetsToSave = []
    let disableMove = false
    for (let k = 0; k < selectedWidgetList.length; k += 1) {
      const widgetItemParams = this.props.getWidgetsDataByIds([
        selectedWidgetList[k].id.split('_')[1]
      ])[0]
      const { y, section } = updateWidgetPositionWithSection({
        origY: parseInt(selectedWidgetList[k].style.top, 10),
        origSection: parseInt(selectedWidgetList[k].getAttribute('data-section'), 10) || 0
      })
      const x = parseInt(selectedWidgetList[k].style.left, 10)
      if (
        this.isWidgetIntersectsWithHiddenSections({
          x,
          y: y + section * DEFAULT_CARD_HEIGHT,
          width: selectedWidgetList[k].offsetWidth,
          height: selectedWidgetList[k].offsetHeight,
          transform: selectedWidgetList[k].style.transform
        })
      ) {
        disableMove = true
        break
      }

      const updatedWidgetParams = moveWidgetInsideAuthoringArea({
        x,
        y,
        section,
        widget: selectedWidgetList[k],
        widgetParams: widgetItemParams
      })

      widgetsToSave.push(updatedWidgetParams)
    }
    if (disableMove) {
      this.returnWidgetsToOriginal(selectedWidgetList)
      this.props.showToastMessage({
        text: messages.ACTION_IS_UNAVAILABLE,
        size: 'M'
      })
    } else {
      this.updateWidgetsPosition(widgetsToSave)
    }

    this.setState({
      isDragging: false,
      initialMultiSelectDimension: {}
    })
  }

  resolveConstrainMovement({ event, widgetData, restrictedDirection }) {
    const { target } = event
    const { direction, dx, dy } = restrictedDirection
    const moveVertically = Math.abs(dy) > Math.abs(dx)
    const moveHorizontally = Math.abs(dx) > Math.abs(dy)
    const { positionX, positionY } = widgetData
    if (moveVertically && !direction) {
      // if shift was pressed in the first time
      // and widget should be moved vertically
      this.props.setWidgetPosition(target, positionX, positionY + dy)
      return 'V'
    }
    if (moveHorizontally && !direction) {
      // if shift was pressed in the first time
      // and widget should be moved horizontally
      this.props.setWidgetPosition(target, positionX + dx, positionY)
      return 'H'
    }
    if (moveVertically && direction === 'H') {
      // widget should be moved vertically
      // and it was moved horizontally before
      this.props.setWidgetPosition(target, positionX, positionY)
      this.props.moveWidgetRect({ target, dx: 0, dy, event })
      return 'V'
    }
    if (moveVertically) {
      // widget should be moved vertically
      this.props.moveWidgetRect({ target, dx: 0, dy: event.dy, event })
      return 'V'
    }
    if (moveHorizontally && direction === 'V') {
      // widget should be moved horizontally
      // and it was moved vertically before
      this.props.setWidgetPosition(target, positionX, positionY)
      this.props.moveWidgetRect({ target, dx, dy: 0, event })
      return 'H'
    }
    if (moveHorizontally) {
      // widget should be moved horizontally
      this.props.moveWidgetRect({ target, dx: event.dx, dy: 0, event })
      return 'H'
    }
    return restrictedDirection.direction
  }

  moveMultiSelectRect({ target, event, dx, dy, restrictedDirection = {}, isSnappingEnabled }) {
    // if mouse dragging or arrows dragging
    if (!event.shiftKey || event.keyCode) {
      const deltaX = !isNaN(dx) ? dx : event.dx
      const deltaY = !isNaN(dy) ? dy : event.dy

      const {
        currentRect: { left, top }
      } = WidgetSnappingService.getSnappingPosition({
        target,
        event,
        deltaX,
        deltaY,
        isSnappingEnabled
      })

      const wrapperDeltaX = left - (parseFloat(target.getAttribute('data-x')) || 0)
      const wrapperDeltaY = top - (parseFloat(target.getAttribute('data-y')) || 0)

      // Update the wrapper's position
      target.style.top = `${top}px`
      target.style.left = `${left}px`

      target.setAttribute('data-x', left)
      target.setAttribute('data-y', top)

      const selectedWidgetList = getSelectedWidgetList()

      // Calculate new position for the widget based on wrapper's delta
      for (let k = 0; k < selectedWidgetList.length; k += 1) {
        const widget = selectedWidgetList[k]

        const widgetX = (parseFloat(widget.getAttribute('data-x')) || 0) + wrapperDeltaX
        const widgetY = (parseFloat(widget.getAttribute('data-y')) || 0) + wrapperDeltaY

        widget.style.top = `${widgetY}px`
        widget.style.left = `${widgetX}px`
        widget.setAttribute('data-x', widgetX)
        widget.setAttribute('data-y', widgetY)
      }
    } else {
      const selectedWidgetList = getSelectedWidgetList()

      let direction = this.resolveConstrainMovement({
        event: {
          dx: event.dx,
          dy: event.dy,
          target
        },
        widgetData: {
          positionX: this.state.initialMultiSelectDimension.wrapperLeft,
          positionY: this.state.initialMultiSelectDimension.wrapperTop
        },
        restrictedDirection
      })
      for (let k = 0; k < selectedWidgetList.length; k += 1) {
        const widgetData = this.props.getWidgetsDataByIds([
          selectedWidgetList[k].id.split('_')[1]
        ])[0]
        direction = this.resolveConstrainMovement({
          event: {
            dx: event.dx,
            dy: event.dy,
            target: selectedWidgetList[k]
          },
          widgetData,
          restrictedDirection
        })
      }
      restrictedDirection.direction = direction
    }
  }

  isWidgetIntersectsWithHiddenSections({ x, y, width, height, transform }) {
    const { hiddenSections } = this.props
    return isWidgetIntersectsWithHiddenSections({
      x,
      y,
      width,
      height,
      transform,
      hiddenSections
    })
  }

  returnWidgetsToOriginal(widgets) {
    widgets.forEach(widget => {
      const widgetItemParams = this.props.getWidgetsDataByIds([widget.id.split('_')[1]])[0]
      this.returnWidgetToOriginal(widget, widgetItemParams)
    })
  }

  returnWidgetToOriginal(target, widgetData) {
    const { positionY, positionX, section, height, width, rotation } = widgetData
    target.style.top = `${positionY}px`
    target.style.left = `${positionX}px`
    target.style.width = `${width}px`
    target.style.height = `${height}px`
    target.style.transform = rotation
    // update the posiion attributes
    target.setAttribute('data-x', positionX)
    target.setAttribute('data-y', positionY)
    target.setAttribute('data-section', section)
  }

  activateWidget(widgetID, isCtrl) {
    this.props.activateWidget(widgetID, isCtrl, this.props.section)
  }

  widgetRendered(widgetID) {
    const { widgets, afterWidgetsRendered } = this.props

    this.renderedWidgets.push(widgetID)
    if (widgets.length === this.renderedWidgets.length) {
      afterWidgetsRendered?.()
    }
  }

  render() {
    const {
      isRenderLazily,
      hasRibbonSupport,
      rotatingWidgetUuid,
      selectedWidgets,
      busy,
      mode,
      isOnMainBoard,
      isEditingDisabledOnDetailed,
      tenantId,
      boardId,
      cardUuid,
      section,
      toggleKeysListening,
      maxZIndex,
      getGroupedFiles,
      createWidgetsAfterFilesUploading,
      deleteWidget,
      widgets,
      isPDFGeneration,
      cardOwners,
      isRenderedOnSettingModal,
      cardData
    } = this.props
    const { isDragging } = this.state

    const isMultiSelect = selectedWidgets && selectedWidgets.length > 1
    let multiSelectDimension = initialMultiSelectDimension
    if (isMultiSelect) {
      multiSelectDimension = this.getRectCoords(selectedWidgets)
    }

    return (
      <div
        className={classNames(
          'widget-list',
          isMultiSelect && 'widget-multiselect',
          busy && 'hidden'
        )}
      >
        {mode === 'edit' && isMultiSelect && section === 0 && (
          <Interactive ref={this.widgetWrapper} draggableOptions={this.draggableOptions} draggable>
            <div
              className="widget-multiselect-wrapper"
              style={{
                top: `${multiSelectDimension.wrapperTop}px`,
                left: `${multiSelectDimension.wrapperLeft}px`,
                width: `${multiSelectDimension.wrapperWidth}px`,
                height: `${multiSelectDimension.wrapperHeight}px`
              }}
              data-x={`${multiSelectDimension.wrapperLeft}px`}
              data-y={`${multiSelectDimension.wrapperTop}px`}
            />
          </Interactive>
        )}
        {widgets.map(widget => (
          // BE doesn't change widget uuid while card/board copying.
          <WidgetClickAwayWrapper
            key={`${widget.cardUuid}-${widget.uuid}`}
            uuid={widget.uuid}
            mode={this.props.mode}
            isThumbnailView={this.props.isThumbnailView}
            isSnapshotPreview={this.props.isSnapshotPreview}
          >
            <WidgetItem
              hasRibbonSupport={hasRibbonSupport}
              setRotatingWidgetUuid={this.props.setRotatingWidgetUuid}
              cardContentMouseMove={this.props.cardContentMouseMove}
              tenantId={tenantId}
              boardId={boardId}
              cardUuid={cardUuid}
              cardOwners={cardOwners}
              widgetData={widget}
              cardData={cardData}
              updateWidget={this.props.updateWidget}
              updateWidgetsPosition={this.updateWidgetsPosition}
              mode={this.props.mode}
              activateWidget={this.activateWidget.bind(this, widget.uuid)}
              rotatingWidgetUuid={rotatingWidgetUuid}
              setGetSettingsComponentCallback={this.props.setGetSettingsComponentCallback}
              isMultiSelect={isMultiSelect}
              onMultiSelectMoveStart={this.onMultiSelectMoveStart.bind(this)}
              moveMultiSelectRect={this.moveMultiSelectRect.bind(this)}
              endMoveMultiSelectRect={this.endMoveMultiSelectRect.bind(this)}
              resolveConstrainMovement={this.resolveConstrainMovement.bind(this)}
              restrictLeavingAuthoringArea={restrictLeavingAuthoringArea}
              isDragging={isDragging}
              isThumbnailView={this.props.isThumbnailView}
              isRenderLazily={isRenderLazily}
              isSnapshotPreview={this.props.isSnapshotPreview}
              isPDFGeneration={isPDFGeneration}
              isOnMainBoard={isOnMainBoard}
              isEditingDisabledOnDetailed={isEditingDisabledOnDetailed}
              moveWidgetRect={this.props.moveWidgetRect}
              endMoveWidgetRect={this.endMoveWidgetRect}
              startMoveWidgetRect={this.startMoveWidgetRect}
              isWidgetIntersectsWithHiddenSections={this.isWidgetIntersectsWithHiddenSections.bind(
                this
              )}
              getWidgetDeltaAuthoringArea={getWidgetDeltaAuthoringArea}
              returnWidgetToOriginal={this.returnWidgetToOriginal.bind(this)}
              toggleKeysListening={toggleKeysListening}
              maxZIndex={maxZIndex}
              getGroupedFiles={getGroupedFiles}
              createWidgetsAfterFilesUploading={createWidgetsAfterFilesUploading}
              deleteWidget={deleteWidget}
              widgetRendered={this.widgetRendered.bind(this)}
              isRenderedOnSettingModal={isRenderedOnSettingModal}
            />
          </WidgetClickAwayWrapper>
        ))}
      </div>
    )
  }
}

export default WidgetContainer
