import { useRef } from 'react'
import { Button } from '@praxie/shared'
import { motion } from 'framer-motion'
import CloseIcon from 'assets/images/icons/cross.svg?react'
import PlusIcon from 'assets/images/icons/plus-rounded.svg?react'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { findSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import { AsyncSelect } from 'components/common/select/asyncSelect/asyncSelect'
import { Tooltip } from 'components/common/tooltip/tooltip'
import { type IWorkflowBlock } from 'features/workflow/workflow.types'
import {
  type TCredentialOption,
  useCredentialOptions
} from 'features/workflow/workflowBuilder/model/useCredentialOptions'
import { MAX_AI_BLOCK_CREDENTIALS } from '../../sendPromptToAIBlock.constants'
import classes from './send-prompt-to-ai-block-credentials.module.scss'

type TProps = {
  block: IWorkflowBlock
  handleCredentialAdd: () => void
  handleCredentialDelete: (index: number) => void
  handleCredentialChange: (index: number, item: string) => void
}

export const SendPromptToAIBlockCredentials = ({
  block,
  handleCredentialAdd,
  handleCredentialDelete,
  handleCredentialChange
}: TProps) => {
  const asyncSelectPortalTarget = useRef(
    document.querySelector<HTMLDivElement>('#portal-container')
  )

  const { credentialOptions, lastLoadedPage, loadMoreOptions } = useCredentialOptions(block)

  const credentials = block.meta[EFieldKeys.CREDENTIAL_IDS] as Array<string | null>
  const isAddButtonDisabled = credentials.length >= MAX_AI_BLOCK_CREDENTIALS
  const isEmptyState = credentials.length === 0

  return (
    <div className={classes.workflowSendPromptToAiCredentials}>
      <span className={classes.title}>Credentials</span>
      {isEmptyState ? (
        <motion.div className={classes.empty} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <span>No credentials yet</span>
          <Button
            appearance="secondary3"
            disabled={isAddButtonDisabled}
            onClick={handleCredentialAdd}
          >
            <PlusIcon />
            Add credentials
          </Button>
        </motion.div>
      ) : (
        <>
          {credentials.map((credential, index) => {
            const errors = block.error[EFieldKeys.CREDENTIAL_IDS] || []
            const selectedCredential = findSelectedOptionByKey(
              credential,
              credentialOptions
            ) as TCredentialOption

            return (
              <motion.div
                key={`credential-item-${String(index)}`}
                className={classes.credentialItem}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                <AsyncSelect
                  size="large"
                  menuPortalTarget={asyncSelectPortalTarget.current}
                  className={classes.credentialSelect}
                  placeholder="Credentials"
                  options={credentialOptions}
                  error={!!errors[index]}
                  helperText={(errors[index] ?? '') as string}
                  value={selectedCredential}
                  loadOptions={loadMoreOptions}
                  additional={{ page: lastLoadedPage + 1 }}
                  isSearchable={false}
                  onChange={value => handleCredentialChange(index, (value as TCredentialOption).id)}
                />
                <Button
                  appearance="secondary3"
                  className={classes.deleteBtn}
                  onClick={() => handleCredentialDelete(index)}
                >
                  <CloseIcon title="Delete" className={classes.deleteIcon} />
                </Button>
              </motion.div>
            )
          })}
          <div className={classes.actions}>
            <Tooltip
              title={isAddButtonDisabled ? 'Max 5 credentials' : ''}
              placement="top"
              shouldWrapChildrenWithDiv
            >
              <Button
                appearance="secondary3"
                disabled={isAddButtonDisabled}
                onClick={handleCredentialAdd}
              >
                <PlusIcon />
                Add credentials
              </Button>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  )
}
