export const getBuilder = state => state.builder

export const editingWidgetSelector = state => state.builder.editingWidget
/**
 * @param {unknown} state
 * @returns {string[]}
 */
export const selectedWidgetsSelector = state => state.builder.selectedWidgets
export const bufferedWidgetsSelector = state => state.builder.widgetsBuffer
export const isUnsavedChangesSelector = state => state.builder.isUnsavedChanges

export const currentCardSelector = state => state.builder.currentCard
export const currentCardWidgetsSelector = state => currentCardSelector(state)?.widgets || []
export const currentCardHeightSelector = state => currentCardSelector(state)?.height

export const bulkWidgetsUpdatePayloadSelector = state => state.builder.bulkWidgetsUpdatePayload
export const getActiveSection = state => state.builder.activeSection
export const getHighlightedSection = state => state.builder.highlightedSection

export const getActiveWidgetWasUpdated = state => state.builder.activeWidgetWasUpdated
