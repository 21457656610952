import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'

import 'scss/workflowBuilder/deleteBlocks/delete-blocks.scss'

const OBJECT_TYPE_BLOCK_CONTENT = {
  [OBJECT_TYPES.CARD]: {
    label: messages.CARD_LEVEL_INPUT,
    placeholder: messages.CARD_LEVEL_INPUT_PLACEHOLDER
  },
  [OBJECT_TYPES.CARD_BUNDLE]: {
    label: messages.CARD_BUNDLE_LEVEL_INPUT,
    placeholder: messages.CARD_BUNDLE_LEVEL_INPUT_PLACEHOLDER
  },
  [OBJECT_TYPES.WIDGET]: {
    label: messages.WIDGET_LEVEL_INPUT,
    placeholder: messages.WIDGET_LEVEL_INPUT_PLACEHOLDER
  },
  [OBJECT_TYPES.WIDGETS_BUNDLE]: {
    label: messages.WIDGETS_BUNDLE_LEVEL_INPUT,
    placeholder: messages.WIDGETS_BUNDLE_LEVEL_INPUT_PLACEHOLDER
  },
  [OBJECT_TYPES.COLUMN]: {
    label: messages.COLUMN_LEVEL_INPUT,
    placeholder: messages.COLUMN_LEVEL_INPUT_PLACEHOLDER
  }
}

const DeleteBlock = props => {
  const {
    block: { id, input, error, inputDefinition },
    handleReset,
    handleInputChange
  } = props

  const inputType = inputDefinition[EFieldKeys.SOURCE_INPUT].type
  const blockContent = OBJECT_TYPE_BLOCK_CONTENT[inputType]

  return (
    <div className="copy-blocks">
      <BlockInputContainer type={inputType} blockId={id} value={input[EFieldKeys.SOURCE_INPUT]}>
        {({ selectedItem, items, searchItems }) => (
          <AnyLevelDropdown
            supportHistory
            title={blockContent.label}
            placeholder={blockContent.placeholder}
            items={items}
            searchItems={searchItems}
            onSelect={handleInputChange}
            onReset={handleReset}
            value={selectedItem}
            type={inputType}
            error={error[EFieldKeys.SOURCE_INPUT]}
          />
        )}
      </BlockInputContainer>
    </div>
  )
}

export default DeleteBlock
