import { SCHEDULED_LAST_DAY_LABEL, SCHEDULED_LAST_DAY_VALUE } from './daysSelect.constants'

export const renderDaySelectValue = (value: unknown) => {
  const updatedValues = [...(value as number[])]
    .sort((a, b) => {
      if (a === SCHEDULED_LAST_DAY_VALUE) return 1
      if (b === SCHEDULED_LAST_DAY_VALUE) return -1
      return a - b
    })
    .map(day => (day === SCHEDULED_LAST_DAY_VALUE ? SCHEDULED_LAST_DAY_LABEL : String(day)))

  return updatedValues.join(', ')
}
