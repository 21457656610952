import UAParser, { DEVICE } from 'ua-parser-js'

export const getDeviceType = () => {
  const parser = new UAParser()

  const { type } = parser.getDevice()

  return type
}

export const getIsDeviceSupportsTouch = () =>
  'ontouchstart' in window || navigator.maxTouchPoints > 0

// Prefer using CSS media queries on `useElementSizeBreakpoints` when possible.
export const IS_MOBILE_DEVICE = getDeviceType() === DEVICE.MOBILE
export const IS_TABLET_DEVICE = getDeviceType() === DEVICE.TABLET
export const IS_SUPPORTS_TOUCH = getIsDeviceSupportsTouch()
