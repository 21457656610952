import { Component } from 'react'
import classNames from 'classnames'
import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { validateEmail } from 'helpers/validationHelpers'
import { getSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import {
  MAX_TOKENS_COUNT,
  USER_EMAIL_INPUTS_COUNT,
  EMAIL_SUBJECT_MAX_LENGTH,
  SEND_EMAIL_BODY_MAX_LENGTH,
  BODY_INPUTS_COUNT
} from 'helpers/workflowSystemActions/sendNotificationBlock'
import {
  SPECIFIC_ROLES_OPTIONS,
  ALL_TEAM_MEMBERS
} from 'helpers/workflowSystemActions/systemActionsHelpers'
import { mapRoles } from 'helpers/workflowSystemActions/systemActionsMappers'
import {
  AUTHOR_FOR_COMMENT_OPTIONS_LIST,
  AUTHOR_FOR_COMMENT_OPTIONS
} from 'helpers/workflowBuilder/workflowBuilderUIHelpers'
import { getFieldNamesItems, getFieldNameIndex } from 'helpers/workflowBuilder/inputOperations'
import FormField from 'components/common/FormField'
import MultiSelect from 'components/fields/MultiSelect'
import ReactSelect from 'components/fields/ReactSelect'
import EmailsMultiCreatable from 'components/fields/EmailsMultiCreatable'
import { TokenInputBox } from 'components/tokenInputBox/tokenInputBox'
import { ExpandableSection } from 'components/common/expandableSection/expandableSection'
import { deriveMemoizedActiveTeamMemberList, sortTeamMemberList } from 'features/team/team.helpers'
import { TeamService } from 'features/team/team.service'
import StringInputsList from '../../common/StringInputsList'
import MenuOption from './MenuOption'
import 'scss/workflowBuilder/systemActions/send-notification-block.scss'

const staticInputsCount = 1

class SendEmailBlock extends Component {
  state = {
    teamMembers: [],
    isLoading: false,
    roles: [],
    isAllTeamMembers: false
  }

  componentDidMount() {
    const {
      block: { meta }
    } = this.props
    this.getTeamMembers()

    if (meta[EFieldKeys.ROLES]) {
      this.getRoles()
    }
  }

  onAddStringRecipient = () => {
    const emailCount = this.getMetaValue(EFieldKeys.COLUMN_NUMBER)

    if (emailCount < USER_EMAIL_INPUTS_COUNT) {
      this.props.updateBlockMeta(EFieldKeys.COLUMN_NUMBER, emailCount + 1)
    }
  }

  onDeleteRecipient = field => {
    const emailCount = this.getMetaValue(EFieldKeys.COLUMN_NUMBER)
    const newEmailCount = emailCount - 1

    /**
     * Reorder recipients if not the last item has been removed
     */
    this.props.updateBlockMeta(EFieldKeys.COLUMN_NUMBER, newEmailCount)
    const feildIndex = getFieldNameIndex(field, EFieldKeys.NAME) + 1
    if (feildIndex < emailCount) {
      this.collapseInputOrdering(field)
    } else {
      this.props.handleReset(field)
    }
  }

  getMetaValue = key => {
    const { meta } = { ...this.props.block }

    return meta[key]
  }

  getTeamMembers = () => {
    this.setState({ isLoading: true })

    TeamService.getTeamMemberList(this.props.tenantId)
      .then(({ data }) => {
        const activeMembers = deriveMemoizedActiveTeamMemberList(data)

        const teamMembers = sortTeamMemberList(
          activeMembers.map(member => ({
            value: member.email,
            label: member.email,
            username: member.username,
            avatarImage: member.avatarImage,
            email: member.email
          })),
          'email'
        )

        this.setState({ teamMembers })
      })
      .catch(() => {
        this.setState({ teamMembers: [], isLoading: false })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  newUserOptionValidator = () => {
    const { meta } = { ...this.props.block }
    const members = meta[EFieldKeys.NAMES]

    return members.length < MAX_TOKENS_COUNT
  }

  onMemberSelectChange = values => {
    if (values.length > MAX_TOKENS_COUNT) {
      return
    }

    this.props.updateBlockMeta(
      EFieldKeys.NAMES,
      values.map(item => item.value)
    )
  }

  changeSender = ({ value }) => {
    this.props.updateBlockMeta(EFieldKeys.TYPE, value)
  }

  getRoles = () => {
    const roles = mapRoles(this.getMetaValue(EFieldKeys.ROLES))
    this.changeRoles(roles)
  }

  changeRoles = selectedRoles => {
    const isAllTeamMembers = selectedRoles.some(role => role.value === ALL_TEAM_MEMBERS)

    this.setState({
      roles: !isAllTeamMembers ? selectedRoles : [SPECIFIC_ROLES_OPTIONS[0]],
      isAllTeamMembers
    })
  }

  updateRoles = () => {
    const { roles } = this.state
    this.props.updateBlockMeta(
      EFieldKeys.ROLES,
      roles.map(role => role.value)
    )
  }

  getSpecificStringsCount = () => {
    const {
      block: { inputDefinition, input }
    } = this.props

    const names = getFieldNamesItems(EFieldKeys.NAME, inputDefinition)

    let count = 0
    for (let i = 0; i < names.length; i += 1) {
      if (input[names[i]]) {
        count += 1
      }
    }

    return count
  }

  collapseInputOrdering = field => {
    const {
      block: { inputDefinition, input }
    } = this.props
    const fieldName = EFieldKeys.NAME
    const fieldsToReorder = getFieldNamesItems(fieldName, inputDefinition).filter(
      name => getFieldNameIndex(name, fieldName) >= getFieldNameIndex(field, fieldName)
    )

    fieldsToReorder.forEach((key, index) => {
      const nextKey = fieldsToReorder[index + 1]
      if (index === fieldsToReorder.length) {
        this.props.handleReset(key)
      } else if (input[key] !== input[nextKey]) {
        this.props.handleInputChange(key, { id: input[nextKey] })
      }
    })
  }

  render() {
    const { block, handleInputChange, updateBlockMeta, handleReset } = this.props
    const { error } = block
    const { isLoading, teamMembers, roles, isAllTeamMembers } = this.state

    const author = this.getMetaValue(EFieldKeys.TYPE)
    const members = this.getMetaValue(EFieldKeys.NAMES).map(name => ({
      isInvalid: !!validateEmail(name),
      label: name,
      value: name
    }))
    const commentBoxHintText = `${
      messages.COMMENT_BOX_NOTIFICATION_HINT_TEXT
    } ${messages.formatString(messages.COMMENT_BOX_NOTIFICATION_LIMIT, {
      limit: BODY_INPUTS_COUNT
    })}`

    const specificEmailsSectionHeader = (
      <>
        Specific emails
        {!!members.length && <div className="counter">({members.length})</div>}
      </>
    )

    const specificStringsCount = this.getSpecificStringsCount()

    const specificStringsSectionHeader = (
      <>
        Specific strings
        {!!specificStringsCount && <span className="counter">({specificStringsCount})</span>}
      </>
    )

    const specificUserRolesSectionHeader = (
      <>
        Specific user roles
        {!!roles.length && <span className="counter">({roles.length})</span>}
      </>
    )

    return (
      <div className="send-notification-block">
        <FormField
          label={messages.SEND_NOTIFICATION_RECIPIENT}
          id="send-email-recipient"
          className="recipients-form-fields"
          error={error[EFieldKeys.NAME]}
        >
          <ExpandableSection
            className="specific-emails recipients-info-block"
            title={specificEmailsSectionHeader}
            expandArrowPlacement="left"
            expandArrowOrientation="horizontal"
            shouldExpandByDefault={!!error[EFieldKeys.NAME]}
          >
            <EmailsMultiCreatable
              className="recipients-rs"
              isError={!!error[EFieldKeys.NAMES] || !!error[EFieldKeys.NAME]}
              placeholder={messages.ENTER_UPBOARD_USER_EMAIL}
              values={members}
              options={teamMembers}
              isValidNewOption={this.newUserOptionValidator}
              size="large"
              isLoading={isLoading}
              menuOption={MenuOption}
              menuIsOpen={() => {}}
              createOnBlur
              onChange={this.onMemberSelectChange}
            />
            <div
              className={classNames('recipients-info-block-item recipients-placeholder-text', {
                error: error[EFieldKeys.NAMES]
              })}
            >
              {error[EFieldKeys.NAMES] || messages.MAX_20_EMAILS}
            </div>
          </ExpandableSection>
          <ExpandableSection
            className="specific-strings"
            title={specificStringsSectionHeader}
            expandArrowPlacement="left"
            expandArrowOrientation="horizontal"
            shouldExpandByDefault={false}
          >
            <div className="recipients-info-block">
              <div className="recipients-info-block-item">
                <StringInputsList
                  block={block}
                  fieldName={EFieldKeys.NAME}
                  inputsCount={this.getMetaValue(EFieldKeys.COLUMN_NUMBER)}
                  maxInputsCount={USER_EMAIL_INPUTS_COUNT}
                  handleInputChange={this.props.handleInputChange}
                  handleReset={this.props.handleReset}
                  inputFieldPlaceholder={messages.STRING_LEVEL_INPUT_PLACEHOLDER}
                  staticInputsCount={staticInputsCount}
                  onAddRecipient={this.onAddStringRecipient}
                  onDeleteRecipient={this.onDeleteRecipient}
                />
              </div>
            </div>
          </ExpandableSection>
          <ExpandableSection
            className="specific-roles-select"
            title={specificUserRolesSectionHeader}
            expandArrowPlacement="left"
            expandArrowOrientation="horizontal"
            shouldExpandByDefault={false}
          >
            <MultiSelect
              size="large"
              className="multi-creatable"
              values={roles}
              isSearchable={false}
              placeholder={messages.SPECIFIC_ROLES_FOR_EMAIL_PLACEHOLDER}
              selectOptions={!isAllTeamMembers ? SPECIFIC_ROLES_OPTIONS : []}
              noOptionsMessage={() => messages.ALL_TEAM_MEMBERS_PLACEHOLDER}
              onChange={this.changeRoles}
              onBlur={this.updateRoles}
            />
          </ExpandableSection>
        </FormField>
        <FormField label={messages.SEND_NOTIFICATION_SENDER} id="add-comment-card-input">
          <ReactSelect
            className="comment-author"
            size="large"
            value={getSelectedOptionByKey(author, AUTHOR_FOR_COMMENT_OPTIONS)}
            options={AUTHOR_FOR_COMMENT_OPTIONS_LIST}
            menuPortalEnabled
            onChange={this.changeSender}
          />
        </FormField>
        <div className="send-notification-subject">
          <TokenInputBox
            block={block}
            fieldMeta={block.meta}
            bodyFieldKey={EFieldKeys.SUBJECT}
            tokenFieldKey={EFieldKeys.SUBJECT_INPUT}
            availableTokenFieldKeys={[EFieldKeys.SUBJECT_INPUT, EFieldKeys.SUBJECT_UBF_CELL]}
            maxLength={EMAIL_SUBJECT_MAX_LENGTH}
            tokenInputBoxLabel={messages.SEND_NOTIFICATION_SUBJECT}
            tokenInputPlaceholder={messages.SEND_EMAIL_SUBJECT_PLACEHOLDER}
            error={error[EFieldKeys.SUBJECT]}
            handleInputChange={handleInputChange}
            handleReset={handleReset}
            updateBlockMeta={updateBlockMeta}
            shouldPreventEnterAction
            isMultiTokenSupported
          />
        </div>
        <div className="send-notification-body">
          <TokenInputBox
            block={block}
            fieldMeta={block.meta}
            bodyFieldKey={EFieldKeys.TEXT}
            tokenFieldKey={EFieldKeys.STRING_INPUT}
            availableTokenFieldKeys={[EFieldKeys.STRING_INPUT, EFieldKeys.UBF_CELL]}
            maxLength={SEND_EMAIL_BODY_MAX_LENGTH}
            tokenInputBoxLabel={messages.SEND_NOTIFICATION_BODY}
            tokenInputPlaceholder={messages.SEND_EMAIL_BODY_PLACEHOLDER}
            error={error[EFieldKeys.TEXT]}
            handleInputChange={handleInputChange}
            handleReset={handleReset}
            updateBlockMeta={updateBlockMeta}
            tokenInputBoxHintText={commentBoxHintText}
            hasHint
            hasRibbon
            isMultiTokenSupported
          />
        </div>
      </div>
    )
  }
}

export default SendEmailBlock
