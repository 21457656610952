import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import messages from 'constants/messages'
import { OBJECT_TYPES } from 'constants/workflows'
import { searchEntities } from 'api/workflowAPI'
import { TeamService } from 'features/team/team.service'
import { deriveMemoizedActiveTeamMemberList, sortTeamMemberList } from 'features/team/team.helpers'

const nestedListMapper = (items, type) => {
  const propNames = {
    [OBJECT_TYPES.CARD]: 'cards',
    [OBJECT_TYPES.COLUMN]: 'cards',
    [OBJECT_TYPES.WIDGET]: 'widgets'
  }

  const propName = propNames[type]

  const nestedMap = items.reduce((acc, item) => {
    if (!acc[item.boardId]) {
      acc[item.boardId] = {
        boardName: item.boardName,
        [propName]: []
      }
    }

    acc[item.boardId][propName].push(item)

    return acc
  }, {})

  return Object.values(nestedMap)
}

const getRequestPayloadMapper = ({ type, query }) => {
  const payload = {}
  if (query) {
    payload.text = query
  }
  if (type === OBJECT_TYPES.COLUMN) {
    return {
      type: OBJECT_TYPES.CARD,
      payload: { ...payload, isCol: true }
    }
  }
  return { type, payload }
}

class GetBlockInputContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      selectedItem: null,
      items: [],
      queryResponse: null,
      total: 0,
      noItems: false // flag to define that there is no items in space at all
    }

    this.getOptions = this.getOptions.bind(this)
  }

  componentDidMount() {
    const { entity, outputName } = this.props

    this.updateSelectedItem({ entity, outputName })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { entity, outputName } = nextProps

    this.updateSelectedItem({ entity, outputName })
  }

  getUsersOptions(query) {
    const { signedInUserId } = this.props
    const queryResponse = this.state.queryResponse

    if (query && queryResponse.length) {
      const items = queryResponse.filter(userData => {
        const email = userData.email.toLowerCase()
        const username = userData.username.toLowerCase()

        return email.indexOf(query.toLowerCase()) > -1 || username.indexOf(query.toLowerCase()) > -1
      })

      this.setState({ items })

      return
    }

    this.toggleLoadingState(true)

    TeamService.getTeamMemberList(this.props.tenantId)
      .then(({ data }) => {
        const activeMembers = deriveMemoizedActiveTeamMemberList(data)

        const items = sortTeamMemberList(
          activeMembers.map(dataItem => {
            const { userId, email } = dataItem
            const emailAlias = userId === signedInUserId ? messages.YOU : email

            return { ...dataItem, emailAlias }
          })
        )

        const noItems = Array.isArray(items) && items.length === 0

        this.setState({ items, queryResponse: items, total: items.length, noItems })
      })
      .catch(() => {
        this.setState({ items: [], total: 0, noItems: false })
      })
      .finally(() => {
        this.toggleLoadingState(false)
      })
  }

  getInputOptions(query) {
    const { tenantId, type } = this.props

    this.toggleLoadingState(true)

    return searchEntities({
      tenantId,
      ...getRequestPayloadMapper({ type, query })
    })
      .then(response => {
        this.toggleLoadingState(false)
        const { result, total } = response.data
        this.setState({
          items: type !== OBJECT_TYPES.BOARD ? nestedListMapper(result, type) : result,
          total,
          noItems: !query && !total
        })
      })
      .catch(() => {
        this.toggleLoadingState(false)
        this.setState({ items: [], total: 0, noItems: false })
      })
  }

  getOptions(query = '') {
    const { type } = this.props
    let itemsGetter

    switch (type) {
      case OBJECT_TYPES.USER:
        itemsGetter = this.getUsersOptions
        break
      default:
        itemsGetter = this.getInputOptions
        break
    }
    itemsGetter.call(this, query)
  }

  updateSelectedItem(props) {
    const { entity, outputName } = props

    if (!entity) {
      this.setState({ selectedItem: null })
      return
    }

    const error = outputName ? outputName.error : null
    const name = outputName ? outputName.name : null

    this.setState({ selectedItem: { ...entity, name, error } })
  }

  toggleLoadingState(state) {
    this.setState({ isLoading: state })
  }

  render() {
    const { children } = this.props
    const { isLoading, items, selectedItem, total, noItems } = this.state

    return children({
      isLoading,
      selectedItem,
      items,
      total,
      searchItems: this.getOptions,
      noItems
    })
  }
}

const mapStateToProps = (state, props) => ({
  outputName: state.workflow.outputsNames[props.outputId]
})

GetBlockInputContainer.propTypes = {
  type: PropTypes.string.isRequired,
  tenantId: PropTypes.string.isRequired,
  signedInUserId: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  outputId: PropTypes.string.isRequired, // used in mapStateToProps
  children: PropTypes.func.isRequired,
  entity: PropTypes.shape({ tenantId: PropTypes.string, boardId: PropTypes.string }),
  outputName: PropTypes.shape({ name: PropTypes.string })
}

GetBlockInputContainer.defaultProps = {
  entity: null,
  outputName: null,
  signedInUserId: ''
}

export default connect(mapStateToProps)(GetBlockInputContainer)
