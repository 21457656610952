import React, { useMemo, useRef, useState } from 'react'
import { Button } from '@praxie/shared'
import classNames from 'classnames'
import {
  BUTTON_OTHER_MODES,
  OPEN_MODES,
  SMART_LINK_MODES
} from 'constants/cardBuilder/widgetsConstants'
import messages from 'constants/messages'
import { validateForNotEmpty } from 'helpers/validationHelpers'
import CheckBoxField from 'components/common/CheckBoxField'
import { Dialog, DialogActions, DialogContent } from 'components/common/dialog/dialog'
import FormField from 'components/common/FormField'
import { MenuItem } from 'components/common/menu/menu'
import RadioField from 'components/common/RadioField'
import { Select } from 'components/common/select/select'
import { Switcher } from 'components/common/switcher/switcher'
import Tabs from 'components/common/Tabs'
import SmartLinkDropdown from 'components/smartLink/SmartLinkDropdown'
import { BoardsList } from 'components/smartLink/SmartLinkDropdownOptions'
import './smart-link-modal.scss'

const CARD_OPEN_MODES = [
  {
    text: messages.CURRENT_CARD_LINK,
    mode: OPEN_MODES.CURRENT_CARD
  },
  {
    text: messages.DETAILED_VIEW_LINK,
    mode: OPEN_MODES.DETAILED_VIEW
  },
  {
    text: messages.PRESENTATION_MODE_LINK,
    mode: OPEN_MODES.PRESENTATION_MODE
  },
  {
    text: messages.FULLSCREEN_MODE_LINK,
    mode: OPEN_MODES.FULLSCREEN_MODE
  },
  {
    text: messages.APP_VIEW_LINK,
    mode: OPEN_MODES.APP_VIEW
  }
]

export const SMART_LINK_SELECTOR = 'smart-link-modal'

type TProps = {
  show: boolean
  mode: (typeof SMART_LINK_MODES)[keyof typeof SMART_LINK_MODES]
  otherMode: (typeof BUTTON_OTHER_MODES)[keyof typeof BUTTON_OTHER_MODES]
  openMode: (typeof OPEN_MODES)[keyof typeof OPEN_MODES]
  tabs: Array<{
    text: string
    mode: (typeof SMART_LINK_MODES)[keyof typeof SMART_LINK_MODES]
    active: boolean
  }>
  items: Array<{
    tenantId: string
    tenantName: string
    boards: Array<{
      boardId: string
      boardName: string
      cards: Array<{
        cardName: string
        tenantName: string
        boardName: string
        boardId: string
        tenantId: string
        cardUuid: string
      }>
    }>
  }>
  link: string
  sectionNumbers: number[]
  shouldShowUnselectedSections?: boolean
  selectedCardData: {
    sectionsCount: number
    hiddenSections: number[]
  }
  customStyles?: string
  selectedLink?: {
    tenantId: string
    boardId: string
    cardUuid: string
    widgetUuid: string
    tenantName: string
    boardName?: string
    cardName: string
    widgetTitle: string
    name?: string
    loading: boolean
  }
  sectionError?: string
  isCardSectionEnabled?: boolean
  isCardSearchRequested?: boolean
  hideCardSections?: boolean
  selectLink: (link: string) => void
  setOpenMode: (mode: (typeof OPEN_MODES)[keyof typeof OPEN_MODES]) => void
  setOtherMode: (mode: (typeof BUTTON_OTHER_MODES)[keyof typeof BUTTON_OTHER_MODES]) => void
  setCardSectionEnabled: (isEnabled: boolean) => void
  setCardSectionNumbers: (sections: number[]) => void
  setShouldShowUnselectedSections: (state: boolean) => void
  onTabClick: (index: string) => void
  onSearch: (search: string) => void
  onModalConfirm: (link?: string) => void
  onModalCancel: () => void
}

type TWebLinkProps = {
  link: TProps['link']
  linkError: string
  onInputFocus: () => void
}

const WebLinkForm = React.forwardRef<HTMLInputElement, TWebLinkProps>(
  ({ link, linkError, onInputFocus }, ref) => (
    <div className="web-link-container">
      <FormField
        ref={ref}
        id="link"
        type="text"
        label={messages.LINK_ADDRESS}
        value={link}
        error={linkError}
        placeholder="https://"
        onFocus={onInputFocus}
      />
    </div>
  )
)

type TCardSectionSettingProps = {
  sectionNumbers: TProps['sectionNumbers']
  selectedCardData: TProps['selectedCardData']
  error: TProps['sectionError']
  isCardSectionEnabled: TProps['isCardSectionEnabled']
  shouldShowUnselectedSections: TProps['shouldShowUnselectedSections']
  onCardSectionEnabledChange: (isEnabled: boolean) => void
  setCardSectionNumbers: (sections: number[]) => void
  setShouldShowUnselectedSections: TProps['setShouldShowUnselectedSections']
}

const CardSectionSetting = ({
  sectionNumbers,
  selectedCardData,
  error,
  isCardSectionEnabled,
  shouldShowUnselectedSections,
  onCardSectionEnabledChange,
  setCardSectionNumbers,
  setShouldShowUnselectedSections
}: TCardSectionSettingProps) => {
  const sectionOptions = useMemo(() => {
    if (!isCardSectionEnabled || !selectedCardData) return null

    return Array.from({ length: selectedCardData.sectionsCount }).map((_, i) => {
      const sectionIndex = i + 1

      return (
        // eslint-disable-next-line react/no-array-index-key
        <MenuItem key={sectionIndex} value={sectionIndex}>
          <CheckBoxField
            id={`select-section-checkbox-${sectionIndex}`}
            label=""
            checked={sectionNumbers.includes(sectionIndex)}
            readOnly
          />
          section {sectionIndex}
          {selectedCardData.hiddenSections.includes(i) && (
            <span className="hidden-section-label">(hidden)</span>
          )}
        </MenuItem>
      )
    })
  }, [isCardSectionEnabled, sectionNumbers, selectedCardData])

  return (
    <div className="card-section-container">
      <div className="open-at-sections-toggle">
        <Switcher
          id="card-section-toggle"
          checked={isCardSectionEnabled}
          onChange={({ target }) => onCardSectionEnabledChange(target.checked)}
        />
        <span className="switcher-title">
          Open at specified section(s) {isCardSectionEnabled ? ':' : ''}
        </span>
        {isCardSectionEnabled && (
          <div className="sections-number-container">
            <Select
              value={sectionNumbers}
              title={sectionNumbers.join(', ')}
              error={!!error}
              helperText={error}
              MenuProps={{
                className: 'sections-number-select-menu'
              }}
              renderValue={value => {
                const selectedSections = value as number[]
                return [...selectedSections].sort((a, b) => a - b).join(', ')
              }}
              multiple
              onChange={e => {
                const value = e.target.value as number[]
                setCardSectionNumbers(value.sort((a, b) => a - b))
              }}
            >
              <li className="sections-select-menu-hint">
                {'Note: Any selected hidden sections will\nbe visible'}
              </li>
              {sectionOptions}
            </Select>
          </div>
        )}
      </div>
      {isCardSectionEnabled && (
        <>
          <div className="divider" />
          <div className="hide-unselected-sections-toggle">
            <Switcher
              id="hide-unselected-sections-toggle"
              checked={!shouldShowUnselectedSections}
              onChange={({ target }) => setShouldShowUnselectedSections(!target.checked)}
            />
            <span className="switcher-title">Hide all unselected sections</span>
          </div>
        </>
      )}
    </div>
  )
}

export const SmartLinkModal = ({
  show,
  mode,
  otherMode,
  openMode,
  tabs,
  items,
  link,
  sectionNumbers,
  shouldShowUnselectedSections,
  selectedCardData,
  customStyles,
  selectedLink,
  sectionError,
  isCardSectionEnabled,
  isCardSearchRequested,
  hideCardSections,
  selectLink,
  setOpenMode,
  setOtherMode,
  setCardSectionEnabled,
  setCardSectionNumbers,
  setShouldShowUnselectedSections,
  onTabClick,
  onSearch,
  onModalConfirm,
  onModalCancel
}: TProps) => {
  const [linkError, setLinkError] = useState('')

  const linkFieldRef = useRef<HTMLInputElement>(null)

  const handleModalCancel = () => {
    onModalCancel()
    setLinkError('')
  }

  const handleModalConfirm = () => {
    if (isCardSectionEnabled && sectionError) return

    if (mode === SMART_LINK_MODES.WEB_LINK) {
      const value = linkFieldRef.current?.value ?? ''
      const _linkError = validateForNotEmpty(value)

      if (_linkError) {
        setLinkError(_linkError)
      } else {
        onModalConfirm(value)
        handleModalCancel()
      }
    } else if (selectedLink || mode === SMART_LINK_MODES.OTHER_MODE) {
      onModalConfirm()
      handleModalCancel()
    }
  }

  return (
    <Dialog
      open={show}
      title={messages.ADD_LINK}
      className={classNames(SMART_LINK_SELECTOR, customStyles)}
      type="default"
      variant="filled"
      disableEnforceFocus
      onClose={handleModalCancel}
    >
      <DialogContent className="content">
        <div className="smart-link-content">
          <Tabs tabs={tabs} className="smart-link-tabs" onTabClick={onTabClick} />
          {mode === SMART_LINK_MODES.WEB_LINK && (
            <WebLinkForm
              ref={linkFieldRef}
              link={link}
              linkError={linkError}
              onInputFocus={() => setLinkError('')}
            />
          )}
          {mode === SMART_LINK_MODES.CARD_LINK && (
            <div className="card-link-container">
              <SmartLinkDropdown
                loading={isCardSearchRequested}
                selectedLink={selectedLink}
                items={items}
                selectLink={selectLink}
                onSearch={onSearch}
              />
              {selectedLink && (
                <div className="open-mode-container">
                  {CARD_OPEN_MODES.map(_mode => (
                    <RadioField
                      key={_mode.mode}
                      className="open-mode-item"
                      id={`${_mode.mode}-id`}
                      checked={_mode.mode === openMode}
                      label={_mode.text}
                      onChange={() => setOpenMode(_mode.mode)}
                    />
                  ))}
                </div>
              )}
              {!hideCardSections && selectedLink && (
                <CardSectionSetting
                  sectionNumbers={sectionNumbers}
                  selectedCardData={selectedCardData}
                  error={sectionError}
                  isCardSectionEnabled={isCardSectionEnabled}
                  shouldShowUnselectedSections={shouldShowUnselectedSections}
                  setCardSectionNumbers={setCardSectionNumbers}
                  setShouldShowUnselectedSections={setShouldShowUnselectedSections}
                  onCardSectionEnabledChange={setCardSectionEnabled}
                />
              )}
            </div>
          )}
          {mode === SMART_LINK_MODES.BOARD_LINK && (
            <div className="card-link-container">
              <SmartLinkDropdown
                selectedLink={selectedLink}
                items={items}
                selectLink={selectLink}
                as={BoardsList}
                placeholder={messages.SELECT_BOARD_TO_LINK}
                noItemsText={messages.NO_BOARDS_FOUND}
                onSearch={onSearch}
              />
            </div>
          )}
          {mode === SMART_LINK_MODES.OTHER_MODE && (
            <div className="other-link-container">
              <RadioField
                className="other-mode-toggle"
                id="back-btn-mode"
                checked={BUTTON_OTHER_MODES.BACK_MODE === otherMode}
                label={messages.BACK_MODE}
                onChange={() => setOtherMode(BUTTON_OTHER_MODES.BACK_MODE)}
              />
              <span className="other-mode-info">{messages.BACK_MODE_DESCRIPTION}</span>
              <RadioField
                className="other-mode-toggle"
                id="invite_btn_mode"
                checked={BUTTON_OTHER_MODES.INVITE_MODE === otherMode}
                label={messages.INVITE_MODE}
                onChange={() => setOtherMode(BUTTON_OTHER_MODES.INVITE_MODE)}
              />
              <span className="other-mode-info">{messages.INVITE_MODE_DESCRIPTION}</span>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions className="footer">
        <Button className="cancel-btn" appearance='secondary' onClick={handleModalCancel}>
          {messages.CANCEL}
        </Button>
        <Button className="confirm-btn" onClick={handleModalConfirm}>
          {messages.ADD_LINK}
        </Button>
      </DialogActions>
    </Dialog>

)
}
