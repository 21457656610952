import { matchPath } from 'react-router-dom'

type TPayload = {
  boardId: string
  cardUuid: string
  tenantId: string
}

export const getIsTheSameCardPath = (payload: TPayload) => {
  const { boardId, cardUuid, tenantId } = payload

  const match = matchPath<{
    viewType: string
    tenantId?: string
    boardId?: string
    cardId?: string
  }>(window.location.pathname, {
    path: '/:viewType/:tenantId?/:boardId?/:cardId?'
  })

  return (
    match?.params.boardId === boardId &&
    match.params.cardId === cardUuid &&
    match.params.tenantId === tenantId
  )
}
