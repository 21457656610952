// @ts-nocheck
/* eslint-disable */

export const getWorkflowState = (state: unknown) => state.workflow as unknown

export const getWorkflowBlocks = (state: unknown) =>
  getWorkflowState(state).workflowBlocks as string[]
export const getWorkflowBlocksMap = (state: unknown) =>
  getWorkflowState(state).workflowBlocksMap as Record<string, unknown>
export const getInvalidBlocksMap = (state: unknown) =>
  getWorkflowState(state).invalidBlocksMap as Record<string, unknown>
export const getBlock = (id: string | null) => (state: any) =>
  id ? (state.workflow.workflowBlocksMap[id] ?? {}) : {}

export const getActiveBlockId = (state: unknown) =>
  getWorkflowState(state).activeBlockId as string | -1

export const isWorkflowLoadingSelector = (state: any) => state.workflow.isWorkflowLoading

export const getWorkflowDefinition = (state: any) => state.workflow.workflowDefinition

export const isExecutionShownSelector = (state: any) =>
  state.workflow.workflowDefinition.isExecutionShown
