import { changeWorkflowBlockOutputType, updateWorkflowBlock } from 'actions'
import { useDispatch } from 'react-redux'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import RadioField from 'components/common/RadioField'
import { type IWorkflowBlock } from 'features/workflow/workflow.types'
import { type TWorkflowBlockOutputFormat } from 'features/workflow/workflowBuilder/model/workflowBuilder.types'
import './output-format-toggle.scss'

type TProps = {
  block: IWorkflowBlock
  outputFieldKey: EFieldKeys
  outputs: Record<string, TWorkflowBlockOutputFormat>
}

export const OutputFormatToggle = ({ block, outputFieldKey, outputs }: TProps) => {
  const dispatch = useDispatch()

  const handleChangeOutput = (outputType: OBJECT_TYPES) => {
    const meta = { ...block.meta, [outputFieldKey]: outputType }

    // @ts-expect-error
    dispatch(updateWorkflowBlock({ id: block.id, data: { meta, error: {} } }))
    dispatch(
      // @ts-expect-error
      changeWorkflowBlockOutputType({
        blockId: block.id,
        defaultPresentationName: outputType,
        type: outputType
      })
    )
  }

  const selectedOutput = block.meta[outputFieldKey] as OBJECT_TYPES

  return (
    <div className="workflow-block-output-format-toggle">
      <div className="title">OUTPUT FORMAT</div>
      <div className="list">
        {Object.values(outputs).map(({ value, title, Icon }) => (
          <RadioField
            key={value}
            id={value}
            className="output-item"
            label={
              <>
                <Icon />
                {title}
              </>
            }
            checked={selectedOutput === value}
            onChange={() => handleChangeOutput(value)}
          />
        ))}
      </div>
    </div>
  )
}
