import { useCallback, useMemo } from 'react'
import { type SelectChangeEvent } from '@mui/material/Select/Select'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { findSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import { AsyncSelect } from 'components/common/select/asyncSelect/asyncSelect'
import { Select } from 'components/common/select/select'
import { type IWorkflowBlock } from 'features/workflow/workflow.types'
import {
  type TCredentialOption,
  useCredentialOptions
} from 'features/workflow/workflowBuilder/model/useCredentialOptions'
import { EAuthorizationType } from '../restApiClientBlock.types'
import './authorization-section.scss'

const AUTHORIZATION_OPTIONS = [
  { label: 'Basic authorization', value: EAuthorizationType.BASIC },
  { label: 'No authorization', value: EAuthorizationType.NOT_SPECIFIED }
]

type TProps = {
  block: IWorkflowBlock
  updateWorkflowBlockMeta: (meta: Partial<IWorkflowBlock['meta']>) => void
}

export const AuthorizationSection = ({ block, updateWorkflowBlockMeta }: TProps) => {
  const { credentialOptions, lastLoadedPage, loadMoreOptions } = useCredentialOptions(block)

  const selectedCredential = useMemo(
    (): TCredentialOption =>
      findSelectedOptionByKey(
        block.meta[EFieldKeys.CREDENTIAL_ID],
        credentialOptions
      ) as TCredentialOption,
    [credentialOptions, block.meta]
  )

  const handleAuthorizationChange = useCallback(
    (value: EAuthorizationType) => {
      const updateData = { [EFieldKeys.AUTHORIZATION_TYPE]: value } as Partial<
        IWorkflowBlock['meta']
      >

      if (value === EAuthorizationType.NOT_SPECIFIED) {
        updateData[EFieldKeys.CREDENTIAL_ID] = ''
      }

      updateWorkflowBlockMeta(updateData)
    },
    [updateWorkflowBlockMeta]
  )

  const handleCredentialsChange = useCallback(
    (value: string) => {
      updateWorkflowBlockMeta({ [EFieldKeys.CREDENTIAL_ID]: value })
    },
    [updateWorkflowBlockMeta]
  )

  const shouldShowCredentialsSelect =
    block.meta[EFieldKeys.AUTHORIZATION_TYPE] === EAuthorizationType.BASIC

  return (
    <div className="wf-rest-api-block-authorization-section">
      <Select
        size={40}
        className="authorization"
        label="Authorization type"
        value={block.meta[EFieldKeys.AUTHORIZATION_TYPE] as EAuthorizationType}
        options={AUTHORIZATION_OPTIONS}
        onChange={({ target }: SelectChangeEvent<unknown>) =>
          handleAuthorizationChange(target.value as EAuthorizationType)
        }
      />
      {shouldShowCredentialsSelect && (
        <AsyncSelect
          size="large"
          className="credentials"
          placeholder="Credentials"
          options={credentialOptions}
          error={!!block.error[EFieldKeys.CREDENTIAL_ID]}
          helperText={(block.error[EFieldKeys.CREDENTIAL_ID] ?? '') as string}
          value={selectedCredential}
          // @ts-expect-error
          loadOptions={loadMoreOptions}
          additional={{ page: lastLoadedPage + 1 }}
          isSearchable={false}
          // @ts-expect-error
          onChange={(value: TCredentialOption) => handleCredentialsChange(value.id)}
        />
      )}
    </div>
  )
}
