import messages from 'constants/messages'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { OBJECT_TYPES } from 'constants/workflows'
import userIcon from 'assets/images/icons/workflowBuilder/ic-user-grey.svg?url'
import { getSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import {
  CARD_OWNER_UNSET,
  SET_CARD_OWNER_OPTIONS,
  SET_CARD_OWNER_OPTIONS_LIST
} from 'helpers/workflowCardOwnerBlocks/workflowCardOwnerBlocksHelpers'
import BlockInputContainer from 'containers/workflowBuilder/common/BlockInputContainer'
import FormField from 'components/common/FormField'
import ReactSelect from 'components/fields/ReactSelect'
import AnyLevelDropdown from 'components/common/AnyLevelDropdown'
import 'scss/workflowBuilder/cardOwnerBlocks/set-card-owner-options.scss'

const SetCardOwnerOptions = ({
  type,
  handleReset,
  handleInputChange,
  changeSelectOption,
  block: { id, error, input }
}) => {
  const isDisabled = !input[EFieldKeys.SOURCE_INPUT]
  const isOwnerUnset = type === CARD_OWNER_UNSET

  return (
    <div className="set-card-owner-options-block">
      <FormField label={messages.SET_CARD_OWNER_ACTION_TYPE} id="way-of-set-card-owner">
        <ReactSelect
          size="large"
          value={getSelectedOptionByKey(type, SET_CARD_OWNER_OPTIONS)}
          options={SET_CARD_OWNER_OPTIONS_LIST}
          isDisabled={isDisabled}
          menuPortalEnabled
          onChange={option => changeSelectOption(EFieldKeys.TYPE, option)}
        />
      </FormField>
      {isOwnerUnset ? (
        <div className="set-card-owner-options-block-unset">
          <img src={userIcon} alt="" className="workflow-builder-output-type-icon" />
          {messages.CARD_OWNER_UNSET_TEXT}
        </div>
      ) : (
        <BlockInputContainer
          type={OBJECT_TYPES.USER}
          blockId={id}
          value={input[EFieldKeys.USER_INPUT]}
        >
          {({ selectedItem, items, searchItems }) => (
            <AnyLevelDropdown
              title={messages.GET_USER}
              placeholder={messages.GET_USER_PLACEHOLDER}
              items={items}
              searchItems={searchItems}
              value={selectedItem}
              type={OBJECT_TYPES.USER}
              disabled={isDisabled}
              error={error[EFieldKeys.USER_INPUT]}
              supportHistory
              onSelect={option => handleInputChange(EFieldKeys.USER_INPUT, option)}
              onReset={option => handleReset(EFieldKeys.USER_INPUT, option)}
            />
          )}
        </BlockInputContainer>
      )}
    </div>
  )
}

export default SetCardOwnerOptions
