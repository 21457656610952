import React from 'react'
import CommonLevelItem from './CommonLevelItem'

const CardLevelItem = ({ item, onSelect, type }) => (
  <div className="any-level-dropdown-item-block">
    <div className="any-level-dropdown-item__divider">
      <i className="icon workflow-block-icon icon-board-item" />
      <p className="any-level-dropdown-item__divider-text">{item.boardName}</p>
    </div>
    {item.cards &&
      item.cards.map(card => (
        <CommonLevelItem key={card.cardUuid} onSelect={onSelect} item={card} type={type} />
      ))}
  </div>
)

export default CardLevelItem
