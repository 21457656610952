import { Component } from 'react'
import classNames from 'classnames'
import messages from 'constants/messages'
import {
  USER_EMAIL_INPUTS_COUNT,
  PUBLISH_FEED_BODY_MAX_LENGTH,
  BODY_INPUTS_COUNT
} from 'helpers/workflowSystemActions/sendNotificationBlock'
import {
  SPECIFIC_ROLES_OPTIONS,
  ALL_TEAM_MEMBERS
} from 'helpers/workflowSystemActions/systemActionsHelpers'
import { mapRoles } from 'helpers/workflowSystemActions/systemActionsMappers'
import {
  AUTHOR_FOR_COMMENT_OPTIONS_LIST,
  AUTHOR_FOR_COMMENT_OPTIONS
} from 'helpers/workflowBuilder/workflowBuilderUIHelpers'
import { getFieldNamesItems, getFieldNameIndex } from 'helpers/workflowBuilder/inputOperations'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { getSelectedOptionByKey } from 'helpers/fields/selectHelpers'
import MultiSelect from 'components/fields/MultiSelect'
import FormField from 'components/common/FormField'
import ReactSelect from 'components/fields/ReactSelect'
import { TokenInputBox } from 'components/tokenInputBox/tokenInputBox'
import { ExpandableSection } from 'components/common/expandableSection/expandableSection'
import StringInputsList from '../../common/StringInputsList'
import 'scss/workflowBuilder/systemActions/send-notification-block.scss'
import 'scss/workflowBuilder/systemActions/publish-feed-notification-block.scss'

class PublishFeedNotificationBlock extends Component {
  state = {
    roles: [],
    isAllTeamMembers: false
  }

  componentDidMount() {
    const {
      block: { meta }
    } = this.props

    if (meta[EFieldKeys.ROLES]) {
      this.getRoles()
    }
  }

  onAddStringRecipient = () => {
    const emailCount = this.getMetaValue(EFieldKeys.COLUMN_NUMBER)

    if (emailCount < USER_EMAIL_INPUTS_COUNT) {
      this.props.updateBlockMeta(EFieldKeys.COLUMN_NUMBER, emailCount + 1)
    }
  }

  onDeleteRecipient = field => {
    const emailCount = this.getMetaValue(EFieldKeys.COLUMN_NUMBER)
    const newEmailCount = emailCount - 1

    /**
     * Reorder recipients if not the last item has been removed
     */
    this.props.updateBlockMeta(EFieldKeys.COLUMN_NUMBER, newEmailCount)
    const feildIndex = getFieldNameIndex(field, EFieldKeys.USER_INPUT) + 1
    if (feildIndex < emailCount) {
      this.collapseInputOrdering(field)
    } else {
      this.props.handleReset(field)
    }
  }

  getMetaValue = key => {
    const { meta } = { ...this.props.block }

    return meta[key]
  }

  changeSender = ({ value }) => {
    this.props.updateBlockMeta(EFieldKeys.TYPE, value)
  }

  getRoles = () => {
    const roles = mapRoles(this.getMetaValue(EFieldKeys.ROLES))
    this.changeRoles(roles)
  }

  changeRoles = selectedRoles => {
    const isAllTeamMembers = selectedRoles.some(role => role.value === ALL_TEAM_MEMBERS)

    this.setState({
      roles: !isAllTeamMembers ? selectedRoles : [SPECIFIC_ROLES_OPTIONS[0]],
      isAllTeamMembers
    })
  }

  updateRoles = () => {
    const { roles } = this.state
    this.props.updateBlockMeta(
      EFieldKeys.ROLES,
      roles.map(role => role.value)
    )
  }

  getSpecificStringsCount = () => {
    const {
      block: { inputDefinition, input }
    } = this.props

    const names = getFieldNamesItems(EFieldKeys.USER_INPUT, inputDefinition)

    let count = 0
    for (let i = 0; i < names.length; i += 1) {
      if (input[names[i]]) {
        count += 1
      }
    }

    return count
  }

  collapseInputOrdering = field => {
    const {
      block: { inputDefinition, input }
    } = this.props
    const fieldName = EFieldKeys.USER_INPUT
    const fieldsToReorder = getFieldNamesItems(fieldName, inputDefinition).filter(
      name => getFieldNameIndex(name, fieldName) >= getFieldNameIndex(field, fieldName)
    )

    fieldsToReorder.forEach((key, index) => {
      const nextKey = fieldsToReorder[index + 1]
      if (index === fieldsToReorder.length) {
        this.props.handleReset(key)
      } else if (input[key] !== input[nextKey]) {
        this.props.handleInputChange(key, { id: input[nextKey] })
      }
    })
  }

  render() {
    const { block, handleInputChange, handleReset, updateBlockMeta } = this.props
    const { error } = block
    const { roles, isAllTeamMembers } = this.state

    const author = this.getMetaValue(EFieldKeys.TYPE)

    const staticInputsCount = 1
    const emailsCount = this.getMetaValue(EFieldKeys.COLUMN_NUMBER)

    const isUserInputError = Object.keys(error).some(key => key.includes(EFieldKeys.USER_INPUT))
    const errorMessage = isUserInputError ? messages.emptyRecipients : ''
    const commentBoxHintText = `${
      messages.COMMENT_BOX_NOTIFICATION_HINT_TEXT
    } ${messages.formatString(messages.COMMENT_BOX_NOTIFICATION_LIMIT, {
      limit: BODY_INPUTS_COUNT
    })}`

    const specificStringsCount = this.getSpecificStringsCount()

    const specificStringsSectionHeader = (
      <>
        Specific strings
        {!!specificStringsCount && <span className="counter">({specificStringsCount})</span>}
      </>
    )

    const specificUserRolesSectionHeader = (
      <>
        Specific user roles
        {!!roles.length && <span className="counter">({roles.length})</span>}
      </>
    )

    return (
      <div className="send-notification-block publish-feed-notification-block">
        <FormField
          label={messages.SEND_NOTIFICATION_RECIPIENT}
          id="send-email-recipient"
          className="recipients-form-fields"
          error={errorMessage}
        >
          <ExpandableSection
            className="specific-strings"
            title={specificStringsSectionHeader}
            expandArrowPlacement="left"
            expandArrowOrientation="horizontal"
            shouldExpandByDefault={isUserInputError}
          >
            <div className="recipients-info-block">
              <div className="recipients-info-block-item">
                <StringInputsList
                  className={classNames({
                    'recipients-info-block__type-static': staticInputsCount === emailsCount
                  })}
                  block={block}
                  fieldName={EFieldKeys.USER_INPUT}
                  addButtonLabel={messages.ADD_USER_STRING}
                  inputsCount={emailsCount}
                  maxInputsCount={USER_EMAIL_INPUTS_COUNT}
                  handleInputChange={handleInputChange}
                  handleReset={handleReset}
                  staticInputsCount={staticInputsCount}
                  onAddRecipient={this.onAddStringRecipient}
                  onDeleteRecipient={this.onDeleteRecipient}
                />
              </div>
            </div>
          </ExpandableSection>
          <ExpandableSection
            className="specific-roles-select"
            title={specificUserRolesSectionHeader}
            expandArrowPlacement="left"
            expandArrowOrientation="horizontal"
            shouldExpandByDefault={false}
          >
            <MultiSelect
              size="large"
              className="multi-creatable"
              values={roles}
              isSearchable={false}
              placeholder={messages.SPECIFIC_ROLES_FOR_NOTIFICATION_PLACEHOLDER}
              selectOptions={!isAllTeamMembers ? SPECIFIC_ROLES_OPTIONS : []}
              noOptionsMessage={() => messages.ALL_TEAM_MEMBERS_PLACEHOLDER}
              onChange={this.changeRoles}
              onBlur={this.updateRoles}
            />
          </ExpandableSection>
        </FormField>
        <FormField label={messages.SEND_NOTIFICATION_SENDER} id="add-comment-card-input">
          <ReactSelect
            className="comment-author"
            size="large"
            value={getSelectedOptionByKey(author, AUTHOR_FOR_COMMENT_OPTIONS)}
            options={AUTHOR_FOR_COMMENT_OPTIONS_LIST}
            menuPortalEnabled
            onChange={this.changeSender}
          />
        </FormField>
        <div className="send-notification-body">
          <TokenInputBox
            block={block}
            fieldMeta={block.meta}
            bodyFieldKey={EFieldKeys.TEXT}
            tokenFieldKey={EFieldKeys.STRING_INPUT}
            availableTokenFieldKeys={[EFieldKeys.STRING_INPUT, EFieldKeys.UBF_CELL]}
            maxLength={PUBLISH_FEED_BODY_MAX_LENGTH}
            tokenInputBoxLabel={messages.SEND_NOTIFICATION_BODY}
            tokenInputPlaceholder={messages.PUBLISH_NOTIFICATION_BODY_PLACEHOLDER}
            error={error[EFieldKeys.TEXT]}
            handleInputChange={handleInputChange}
            handleReset={handleReset}
            updateBlockMeta={updateBlockMeta}
            tokenInputBoxHintText={commentBoxHintText}
            ribbonConfig={{
              bold: true,
              italic: true,
              underline: true,
              link: true
            }}
            hasHint
            hasRibbon
            isMultiTokenSupported
          />
        </div>
      </div>
    )
  }
}

export default PublishFeedNotificationBlock
