import InfoIcon from 'assets/images/icons/info.svg?react'
import { ExpandableSection } from 'components/common/expandableSection/expandableSection'
import './execution-conditions.scss'

const WF_EXECUTION_SCENARIOS = [
  'Dataset widget is selected as Widget to update, .csv or xlsx. file up to 20MB is selected as File',
  'Image widget is selected as Widget to update, .png, .jpeg, .jpg, .svg file up to 50MB is selected as File',
  'File widget is selected as Widget to update, file up to 50 MB is selected as File'
]

export const ExecutionConditions = () => {
  return (
    <ExpandableSection
      className="wf-update-widget-with-file-execution-conditions"
      title={
        <>
          <InfoIcon className="info-icon" />
          <span>Execution conditions</span>
        </>
      }
      expandArrowPlacement="left"
      expandArrowOrientation="horizontal"
      shouldExpandByDefault={false}
    >
      <div className="execution-conditions-wrapper">
        <div>Workflow will be executed in the following scenarios:</div>
        <ul className="conditions-list">
          {WF_EXECUTION_SCENARIOS.map(scenario => (
            <li key={scenario}>{scenario}</li>
          ))}
        </ul>
      </div>
    </ExpandableSection>
  )
}
