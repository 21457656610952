import { QueryClient } from '@tanstack/react-query'

class _QueryClientService {
  constructor() {
    this._queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: this._QUERY_STALE_TIME_MS,
          gcTime: this._GC_TIME_MS,
          networkMode: 'always'
        }
      }
    })
  }

  private readonly _QUERY_STALE_TIME_MS = 300000 // 5 minutes.
  private readonly _GC_TIME_MS = 600000 // 10 minutes.
  private readonly _queryClient: QueryClient

  get queryClient() {
    return this._queryClient
  }
}

export const QueryClientService = new _QueryClientService()
