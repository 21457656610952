import { motion } from 'framer-motion'
import { produce } from 'immer'
import TextButton from 'components/buttons/TextButton'
import CheckBoxField from 'components/common/CheckBoxField'
import { DaysSelect } from './daysSelect/daysSelect'
import {
  WF_SCHEDULED_DEFAULT_MONTHS,
  WF_SCHEDULED_MONTHS_OPTION_LIST
} from './monthsSection.constants'
import './months-section.scss'

type TProps = {
  selectedMonths: number[]
  selectedDays: number[]
  monthsError?: string
  daysError?: string
  setSelectedDays: (selectedDays: number[]) => void
  setSelectedMonths: (selectedMonths: number[]) => void
}

export const MonthsSection = ({
  selectedMonths,
  selectedDays,
  monthsError,
  daysError,
  setSelectedMonths,
  setSelectedDays
}: TProps) => {
  const handleSelectAll = () => {
    setSelectedMonths(WF_SCHEDULED_DEFAULT_MONTHS)
  }

  const handleUnselectAll = () => {
    setSelectedMonths([])
  }

  const handleMonthClick = (month: number) => {
    const updatedMonths = produce(selectedMonths, draft => {
      if (draft.includes(month)) {
        draft.splice(draft.indexOf(month), 1)
      } else {
        draft.push(month)
      }
    })

    setSelectedMonths(updatedMonths)
  }

  return (
    <div className="wf-trigger-months-section">
      <div className="header-wrapper">
        <div className="header-label">Months</div>
        <div className="control-button-wrapper">
          <TextButton appearance="secondary" disabled={!!monthsError} onClick={handleUnselectAll}>
            unselect all
          </TextButton>
          <TextButton onClick={handleSelectAll}>select all</TextButton>
        </div>
      </div>
      <div className="months-list">
        {WF_SCHEDULED_MONTHS_OPTION_LIST.map(([value, label]) => {
          const key = `month-${value}`
          const monthValue = Number(value)

          return (
            <CheckBoxField
              key={key}
              id={key}
              label={label}
              checked={selectedMonths.includes(monthValue)}
              isInvalid={!!monthsError}
              onChange={() => handleMonthClick(monthValue)}
            />
          )
        })}
      </div>
      {!!monthsError && (
        <motion.div
          className="months-error-message"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          {monthsError}
        </motion.div>
      )}
      <DaysSelect selectedDays={selectedDays} setSelectedDays={setSelectedDays} error={daysError} />
    </div>
  )
}
