import FormField from 'components/common/FormField'

type TProps = {
  value: string
  error: string
  onChange: (value: string) => void
}

const webLinkRegex = /^https?:\/\//

export const WebLinkField = ({ value, error, onChange }: TProps) => {
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value.trim()

    const updatedValue = !value || webLinkRegex.test(value) ? value : `https://${value}`

    onChange(updatedValue)
  }

  return (
    <FormField
      // Adding a key forces re-render since the input is uncontrolled.
      key={value}
      type="text"
      label="Link address"
      value={value}
      error={error}
      placeholder="https://"
      onBlur={handleBlur}
    />
  )
}
