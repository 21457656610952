import { useCallback, useRef, useState } from 'react'
import { useMount } from 'react-use'
import { EFieldKeys } from 'constants/workflowBuilder/blocksFieldsKeys'
import { getCredentials as getCredentialsAPI } from 'api/credentialsAPI'
import {
  type ICredentialData,
  type ICredentialsListPayload,
  ECredentialsColumnIds,
  ESortDirections
} from 'components/organizationSettings/credentials/credentialSettings.types'
import { type IWorkflowBlock } from 'features/workflow/workflow.types'

const CREDENTIALS_PER_PAGE = 10

export type TCredentialOption = ICredentialData & { value: string; label: string }

export const useCredentialOptions = (block: IWorkflowBlock) => {
  const [credentialOptions, setCredentialOptions] = useState<TCredentialOption[]>([])
  const [lastLoadedPage, setLastLoadedPage] = useState(0)

  const endCursors = useRef<string[]>([])
  const isInitialLoadRef = useRef(true)

  const getCredentials = useCallback(
    async (pageNumber: number, isInitialRequest?: boolean) => {
      const payload: ICredentialsListPayload = {
        pageCursor: (pageNumber ? endCursors.current[pageNumber - 1] : '') ?? '',
        sortBy: { field: ECredentialsColumnIds.name, order: ESortDirections.asc }
      }

      const { rows, count, pageCursor } = await getCredentialsAPI(payload)

      const parsedCredentials = rows.map(row => ({ ...row, label: row.name, value: row.id }))

      setCredentialOptions(prevCredentials => [...prevCredentials, ...parsedCredentials])
      setLastLoadedPage(pageNumber)
      endCursors.current[pageNumber || 0] = pageCursor

      const areAllCredentialsLoaded = rows.length < CREDENTIALS_PER_PAGE
      const isSelectedOptionMissing = !parsedCredentials.some(el => {
        const credentialId = block.meta[EFieldKeys.CREDENTIAL_ID]
        const credentialIds = block.meta[EFieldKeys.CREDENTIAL_IDS] as string[] | undefined

        return el.id === credentialId || credentialIds?.includes(el.id)
      })

      if (isInitialRequest && isSelectedOptionMissing && !areAllCredentialsLoaded) {
        await getCredentials(pageNumber + 1, true)
      }

      return { parsedCredentials, count }
    },
    [block.meta]
  )

  const loadMoreOptions = useCallback(
    async (_: string, loadedOptions: TCredentialOption[], { page = 1 }: { page?: number } = {}) => {
      try {
        const { parsedCredentials, count } = await getCredentials(page)

        const options = isInitialLoadRef.current
          ? [...credentialOptions, ...parsedCredentials]
          : parsedCredentials

        isInitialLoadRef.current = false

        return {
          options,
          hasMore: loadedOptions.length < count,
          additional: { page: page + 1 }
        }
      } catch {
        return { options: [] }
      }
    },
    [getCredentials, credentialOptions]
  )

  useMount(() => {
    if (!block.meta[EFieldKeys.CREDENTIAL_ID] && !block.meta[EFieldKeys.CREDENTIAL_IDS]) return

    async function fetchData() {
      await getCredentials(lastLoadedPage, true)
    }

    void fetchData()
  })

  return { credentialOptions, lastLoadedPage, loadMoreOptions }
}
