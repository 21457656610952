import React from 'react'
import RadioField from 'components/common/RadioField'
import { Tooltip } from 'components/common/tooltip/tooltip'
import 'scss/workflowBuilder/copyBlocks/radio-fields-block.scss'

const RadioFieldsBlock = props => {
  const {
    id,
    meta,
    radioFieldAttr: { radioFieldKey, radioFieldLeftLabel, radioFieldRightLabel, radioFieldTooltip },
    isRadioDisabled,
    toggleRadioFields
  } = props
  return (
    <div className="wrapper radio-fields-block">
      <RadioField
        id={`${id}-radio-field-0`}
        checked={!meta[radioFieldKey]}
        label={radioFieldLeftLabel}
        onChange={toggleRadioFields}
        disabled={isRadioDisabled}
      />
      <RadioField
        id={`${id}-radio-field-1`}
        checked={meta[radioFieldKey]}
        label={radioFieldRightLabel}
        onChange={toggleRadioFields}
        disabled={isRadioDisabled}
      />
      {radioFieldTooltip && (
        <Tooltip id="radio-fields-block-tooltip" title={radioFieldTooltip} placement="top">
          <i className="icon workflow-block-icon icon-workflow-info" />
        </Tooltip>
      )}
    </div>
  )
}

export default RadioFieldsBlock
