import { useState } from 'react'
import { updateWorkflowTriggerBlock } from 'actions'
import { useDispatch } from 'react-redux'
import { useMount } from 'react-use'
import InfoIcon from 'assets/images/icons/info.svg?react'
import CardIcon from 'assets/images/icons/workflowBuilder/ic-card-color.svg?react'
import ColumnIcon from 'assets/images/icons/workflowBuilder/ic-column-color.svg?react'
import { OBJECT_TYPE_OUTPUT_DEFINITION, OBJECT_TYPES } from 'constants/workflows'
import CheckBoxField from 'components/common/CheckBoxField'
import { Switcher } from 'components/common/switcher/switcher'
import { Tooltip } from 'components/common/tooltip/tooltip'
import { type TWorkflowOutput } from 'features/workflow/workflow.types'
import './additional-trigger-params.scss'

const ADDITIONAL_TRIGGER_PARAMS = {
  [OBJECT_TYPES.COLUMN]: {
    value: OBJECT_TYPES.COLUMN,
    label: (
      <>
        <ColumnIcon className="icon" />
        <span>Trigger’s column</span>
      </>
    )
  },
  [OBJECT_TYPES.CARD]: {
    value: OBJECT_TYPES.CARD,
    label: (
      <>
        <CardIcon className="icon" />
        <span>Trigger’s card</span>
      </>
    )
  }
}
const ADDITIONAL_TRIGGER_PARAMS_LIST = Object.values(ADDITIONAL_TRIGGER_PARAMS)

type TProps = {
  id: string | null
  output: TWorkflowOutput
}

export const AdditionalTriggerParams = ({ id, output }: TProps) => {
  const [isEnabled, setIsEnabled] = useState(false)

  const dispatch = useDispatch()

  useMount(() => {
    const areAdditionalParamsEnabled = output.some(el =>
      Object.keys(ADDITIONAL_TRIGGER_PARAMS).includes(el.type)
    )

    if (areAdditionalParamsEnabled) {
      setIsEnabled(true)
    }
  })

  const toggleAdditionalParams = (state: boolean) => {
    setIsEnabled(state)

    if (!state) {
      const updatedOutputs = output.filter(
        el => !Object.keys(ADDITIONAL_TRIGGER_PARAMS).includes(el.type)
      )

      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      dispatch(updateWorkflowTriggerBlock({ id, data: { output: updatedOutputs } }))
    }
  }

  const handleSelectParam = (paramName: OBJECT_TYPES, isSelected: boolean) => {
    let updatedOutputs

    if (isSelected) {
      const isColumnEnabled = output.some(el => el.type === OBJECT_TYPES.COLUMN)

      if (paramName === OBJECT_TYPES.FILE) {
        updatedOutputs = [...output, OBJECT_TYPE_OUTPUT_DEFINITION[paramName]]
      } else if (paramName === OBJECT_TYPES.CARD && !isColumnEnabled) {
        updatedOutputs = [
          ...output,
          OBJECT_TYPE_OUTPUT_DEFINITION[paramName],
          OBJECT_TYPE_OUTPUT_DEFINITION[OBJECT_TYPES.COLUMN]
        ]
      } else {
        updatedOutputs = [...output, OBJECT_TYPE_OUTPUT_DEFINITION[paramName]]
      }
    } else {
      switch (paramName) {
        case OBJECT_TYPES.COLUMN:
          updatedOutputs = output.filter(
            item => ![OBJECT_TYPES.COLUMN, OBJECT_TYPES.CARD].includes(item.type)
          )
          break

        case OBJECT_TYPES.CARD:
          updatedOutputs = output.filter(item => item.type !== OBJECT_TYPES.CARD)
          break

        case OBJECT_TYPES.FILE:
          updatedOutputs = output.filter(item => item.type !== OBJECT_TYPES.FILE)
          break
        default:
          break
      }
    }

    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(updateWorkflowTriggerBlock({ id, data: { output: updatedOutputs } }))
  }

  return (
    <section className="workflow-additional-trigger-params">
      <div className="params-toggle">
        <Switcher
          id="workflow-trigger-additional-params-switcher"
          className="additional-params-switcher"
          checked={isEnabled}
          onChange={() => toggleAdditionalParams(!isEnabled)}
        />
        Set additional trigger’s parameters
      </div>
      {isEnabled && (
        <div className="params-content">
          <div className="title">
            <span>Trigger’s parameters</span>
            <Tooltip
              id="workflow-trigger-additional-params-tooltip"
              placement="top"
              title="Card requires column parameter"
              shouldWrapChildrenWithDiv
            >
              <InfoIcon className="icon" />
            </Tooltip>
          </div>
          <div className="list">
            {ADDITIONAL_TRIGGER_PARAMS_LIST.map(param => {
              const isChecked = output.some(el => el.type === param.value)

              return (
                <CheckBoxField
                  key={param.value}
                  id={`additional-params-${param.value}`}
                  className="param"
                  label={param.label}
                  checked={isChecked}
                  onChange={() => handleSelectParam(param.value, !isChecked)}
                />
              )
            })}
          </div>
        </div>
      )}
    </section>
  )
}
